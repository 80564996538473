import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const REQUEST_CLIENTS = 'REQUEST_CLIENTS';
export const REJECT_CLIENTS = 'REJECT_CLIENTS';
export const RECEIVE_CLIENTS = 'RECEIVE_CLIENTS';

export const REQUEST_SAVE_CLIENT = 'REQUEST_SAVE_CLIENT';
export const REJECT_SAVE_CLIENT = 'REJECT_SAVE_CLIENT';
export const RECEIVE_SAVE_CLIENT = 'RECEIVE_SAVE_CLIENT';

export const fetchClients = (
  params = {
    page: {
      page: 0,
      page_size: 500,
    },
  },
) =>
  apiFetch(
    'clients',
    {
      request: REQUEST_CLIENTS,
      receive: RECEIVE_CLIENTS,
      reject: REJECT_CLIENTS,
    },
    params,
  );

export const editClient = (
  data = {
    name: '',
  },
) =>
  apiFetch(
    `clients/${data.id}`,
    {
      request: {
        type: REQUEST_SAVE_CLIENT,
        payload: data,
      },
      receive: RECEIVE_SAVE_CLIENT,
      reject: REJECT_SAVE_CLIENT,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'client',
          attributes: {
            name: data.name,
            avatar: data.avatar,
            locked: data.locked,
          },
        },
      }),
    },
    {
      method: 'PATCH',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const saveClient = (
  data = {
    name: '',
    avatar: null,
  },
) =>
  apiFetch(
    'clients',
    {
      request: {
        type: REQUEST_SAVE_CLIENT,
        payload: data,
      },
      receive: RECEIVE_SAVE_CLIENT,
      reject: REJECT_SAVE_CLIENT,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'client',
          attributes: {
            name: data.name,
            avatar: data.avatar,
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
