import React, { memo, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';

import ICONavbarVerticalMenuItem from './ICONavbarVerticalMenuItem';
import AppContext from '../../../context/Context';
import { getCurrentProjectId } from '../../../selectors/forms';

const getHr = (name) => {
  if (name === 'Reports' || name === 'Super Search') {
    return (
      <div className="navbar-vertical-divider">
        <hr className="my-2 bg-secondary mt-3" />
      </div>
    );
  }
};

const ICONavbarVerticalMenu = ({ routes, location, currentProjectId, user }) => {
  const [openedIndex, setOpenedIndex] = useState(null);
  const { setShowBurgerMenu } = useContext(AppContext);

  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
  }, [routes, location.pathname]);

  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  const childRoutesWithProjectId = routes.map((route) => {
    if (route.children) {
      const processedRoutes = route.children.map((childRoute) => ({
        ...childRoute,
        to: childRoute.to.replace(':projectId', currentProjectId),
      }));
      return { ...route, children: processedRoutes };
    } else {
      return { ...route, to: route.to.replace(':projectId', currentProjectId) };
    }
  });

  return childRoutesWithProjectId.map((route, index) => {
    if (route.name === 'FAQ') {
      return (
        <NavItem key={index}>
          <div className="nav-link cursor-pointer" onClick={() => window.open(route.to, '_blank')}>
            <ICONavbarVerticalMenuItem route={route} />
          </div>
        </NavItem>
      );
    }

    if (!route.children) {
      return (
        <NavItem key={index}>
          <NavLink className="nav-link" {...route} onClick={() => setShowBurgerMenu(false)}>
            <ICONavbarVerticalMenuItem route={route} />
            {getHr(route.name, user)}
          </NavLink>
        </NavItem>
      );
    }

    return (
      <NavItem key={index}>
        <BootstrapNavLink
          onClick={(e) => toggleOpened(e, index)}
          className="dropdown-indicator cursor-pointer"
          aria-expanded={openedIndex === index}
        >
          <ICONavbarVerticalMenuItem route={route} />
        </BootstrapNavLink>
        <Collapse isOpen={openedIndex === index}>
          <Nav>
            <ICONavbarVerticalMenu routes={route.children} location={location} />
          </Nav>
          {getHr(route.name, user)}
        </Collapse>
      </NavItem>
    );
  });
};

ICONavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentProjectId: getCurrentProjectId(state),
});

export default memo(connect(mapStateToProps)(withRouter(ICONavbarVerticalMenu)));
