import React from 'react';

import { Row } from 'reactstrap';

import UpgradeSubscriptionProduct from './UpgradeSubscriptionProduct';
import UpgradeSubscriptionProductAgency from './UpgradeSubscriptionProductAgency';
import {
  agencyComingSoon,
  agencyDefaultFeatures,
  agencyFeaturesShowMore,
  proComingSoon,
  proDefaultFeatures,
  proFeaturesShowMore,
  starterDefaultFeatures,
  starterFeaturesShowMore,
} from './upgradeSubscriptionProductFeaturesStrings';
import UpgradeSubscriptionShowMore from './UpgradeSubscriptionShowMore';
import translate from '../../../../helpers/translate';

const UpgradeSubscriptionTiers = ({ showMore, handleShowMore, starterBasicPlan, proBasicPlan }) => {
  const starterFeatures = showMore ? [...starterDefaultFeatures, ...starterFeaturesShowMore] : starterDefaultFeatures;
  const proFeatures = showMore ? [...proDefaultFeatures, ...proFeaturesShowMore] : proDefaultFeatures;
  const agencyFeatures = showMore ? [...agencyDefaultFeatures, ...agencyFeaturesShowMore] : agencyDefaultFeatures;

  return (
    <>
      <Row className="d-flex flex-row justify-content-between">
        <UpgradeSubscriptionProduct
          icon="https://ico-files.s3.eu-west-2.amazonaws.com/images/Icon-Starter-Sq.svg"
          title="starter.title"
          features={starterFeatures}
          productColor="starter"
          monthlyPrice={translate('modals.upgrade_subscription.starter.monthly_price')}
          annualPrice={translate('modals.upgrade_subscription.starter.yearly_price')}
          monthlyProductId={starterBasicPlan?.monthly_price_id}
          annualProductId={starterBasicPlan?.annual_price_id}
        />
        <UpgradeSubscriptionProduct
          icon="https://ico-files.s3.eu-west-2.amazonaws.com/images/Icon-Pro-Sq.svg"
          title="pro.title"
          features={proFeatures}
          productColor="pro"
          monthlyPrice={translate('modals.upgrade_subscription.pro.monthly_price')}
          annualPrice={translate('modals.upgrade_subscription.pro.yearly_price')}
          monthlyProductId={proBasicPlan?.monthly_price_id}
          annualProductId={proBasicPlan?.annual_price_id}
          comingSoonFeatures={showMore ? proComingSoon : []}
        />
        <UpgradeSubscriptionProductAgency
          features={agencyFeatures}
          title="agency.title"
          productColor="agency"
          comingSoonFeatures={showMore ? agencyComingSoon : []}
          isAgency
        />
      </Row>
      <UpgradeSubscriptionShowMore aria-label="show-more" showMore={showMore} onClick={handleShowMore} />
    </>
  );
};

export default UpgradeSubscriptionTiers;
