import { PRODUCTS_FETCH_PRODUCTS, API_CALL } from './actionTypes';

export const fetchProducts = () => ({
  type: API_CALL,
  meta: {
    type: PRODUCTS_FETCH_PRODUCTS,
    endpoint: 'tiers',
    method: 'GET',
  },
});
