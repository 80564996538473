import React from 'react';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';

import ButtonIcon from '../../../components/common/ButtonIcon';
import { useExport } from '../hooks/useSupersearch';

const SupersearchExport = ({ data }) => {
  const { isLoading, mutate } = useExport();
  const handleClick = () => {
    mutate(data);
  };

  const displayText = isLoading ? 'Loading...' : 'Export';

  return (
    <ButtonIcon
      disabled={isLoading}
      aria-label="export-button"
      className="ml-3 text-uppercase fp-button"
      onClick={handleClick}
      icon={faExternalLinkAlt}
      transform="shrink-3 down-2"
      color="secondary"
      size="sm"
    >
      {displayText}
    </ButtonIcon>
  );
};
export default SupersearchExport;
