import {
  SAVED_VIDEOS_FETCH_VIDEOS,
  SAVED_VIDEOS_FETCH_VIDEOS_FAILURE,
  SAVED_VIDEOS_FETCH_VIDEOS_SUCCESS,
  SAVED_VIDEOS_FETCH_VIDEOS_ERROR,
  SAVED_VIDEOS_CLEAN,
} from '../../actions/actionTypes';

const defaultState = {
  isLoading: false,
  response: [],
  error: null,
};

const data = (state = defaultState, action) => {
  switch (action.type) {
    case SAVED_VIDEOS_FETCH_VIDEOS:
      return { ...state, isLoading: true };
    case SAVED_VIDEOS_FETCH_VIDEOS_SUCCESS:
      return { ...state, isLoading: false, response: action.payload };
    case SAVED_VIDEOS_FETCH_VIDEOS_FAILURE:
    case SAVED_VIDEOS_FETCH_VIDEOS_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case SAVED_VIDEOS_CLEAN:
      return defaultState;
    default:
      return state;
  }
};

export default data;
