import {
  REQUEST_EDIT_ARTICLE,
  RECEIVE_EDIT_ARTICLE,
  REJECT_EDIT_ARTICLE,
  REQUEST_ARTICLES,
  RECEIVE_ARTICLES,
  REJECT_ARTICLES,
  RECEIVE_DELETED_ARTICLE,
  REQUEST_ARTICLES_PREVIEW,
  RECEIVE_ARTICLES_PREVIEW,
  RECEIVE_SAVE_ARTICLE,
} from '../../actions/articles';
import { apiReceiveDeleted, apiReceive, apiReceiveSaved, apiRequest, apiReject } from '../../config/api-service';

const initialState = {
  items: {},
  itemsStatus: {},
  lists: {},
  listsStatus: {},
  stats: {},
};

const base = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ARTICLES:
      return apiRequest(state, action);
    case REQUEST_EDIT_ARTICLE:
      return apiRequest(state, action);
    case REQUEST_ARTICLES_PREVIEW:
      return apiRequest(state, action);
    case RECEIVE_EDIT_ARTICLE:
      return apiReceiveSaved(state, action);
    case RECEIVE_SAVE_ARTICLE:
      return apiReceiveSaved(state, action);
    case RECEIVE_ARTICLES:
      return apiReceive(state, action);
    case RECEIVE_ARTICLES_PREVIEW: {
      const id = action.meta.request.params.id;
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            html: action.payload.items[id].html,
          },
        },
      };
    }
    case RECEIVE_DELETED_ARTICLE:
      return apiReceiveDeleted(state, action);
    case REJECT_ARTICLES:
      return apiReject(state, action);
    case REJECT_EDIT_ARTICLE:
      return apiReject(state, action);
    default:
      return state;
  }
};

export default base;
