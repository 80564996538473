import { keys, values } from 'lodash';
import { toast } from 'react-toastify';

import { bulkDeleteArticlesFeed, fetchArticles, importArticles } from '../../../../actions/articles';
import { fetchCountries } from '../../../../actions/countries';
import { fetchRssArticlesAlert } from '../../../../actions/feeds';
import { changeFeedForm, tableActions } from '../../../../actions/forms';
import { fetchLanguages } from '../../../../actions/languages';
import { fetchProjects } from '../../../../actions/projects';
import { fetchRegions } from '../../../../actions/regions';
import { fetchTags } from '../../../../actions/tags';
import { fetchCurrentUser } from '../../../../actions/users';
import { fetchWebsites } from '../../../../actions/websites';
import { STATUS } from '../../../../config/api-service';

export const fetchAction = {
  articlesTable: (p) => fetchArticles(p),
  articlesFeedTable: (p, alertId) => fetchRssArticlesAlert(alertId, p),
  websitesTable: (p) => fetchWebsites(p),
};

export const onLoad = (stateProps, dispatchProps) => ({
  websitesTable: () => {
    const { dispatch } = dispatchProps;
    dispatch(
      fetchWebsites({
        page: {
          page: 1,
          page_size: 25,
        },
        sort: '-muv',
      }),
    );
    dispatch(fetchLanguages());
  },
  articlesTable: () => {
    const { dispatch } = dispatchProps;
    const { pagination } = stateProps;
    dispatch(
      fetchArticles({
        page: {
          page: 1,
          page_size: pagination.page_size,
        },
        filter: {
          project_id: stateProps.currentGlobalProject.id,
        },
      }),
    );
    dispatch(fetchCurrentUser());
    dispatch(fetchTags());
    dispatch(fetchProjects());
    dispatch(fetchLanguages());
  },
  articlesFeedTable: () => {
    const { pagination, currentGlobalProject } = stateProps;
    const { dispatch } = dispatchProps;
    dispatch(fetchLanguages());
    dispatch(fetchCurrentUser());
    dispatch(fetchProjects());
    dispatch(fetchRegions());
    dispatch(fetchCountries());
    dispatch(
      fetchRssArticlesAlert(currentGlobalProject['alert-id'], {
        page: {
          page: pagination.current_page,
          page_size: pagination.page_size,
        },
      }),
    );
  },
});

export const RowToId = ({ id }) => id;

const treatBulkFeedResult = (result) => {
  let artId = keys(result)[0];
  let resultStatus;
  if (result[artId] === 'already exists') {
    resultStatus = STATUS.KO;
  } else if (result[artId] === 'OK' || result[artId] === 201) {
    resultStatus = STATUS.SAVED;
  }
  return { id: artId, status: resultStatus, message: result[artId] };
};

export const onBulkDeleteApply = (stateProps, dispatchProps, ownProps) => ({
  articlesFeedTable: () => {
    const { dispatch } = dispatchProps;
    const { nonFiltersFields, alertId } = stateProps;
    dispatch(changeFeedForm({ field: 'status', value: STATUS.LOADING }));
    dispatch(changeFeedForm({ field: 'importIdsCount', value: nonFiltersFields.checkedRows.length }));
    dispatch(tableActions[ownProps.tableId].changeForm({ field: 'status', value: STATUS.LOADING }));
    dispatch(
      tableActions[ownProps.tableId].changeForm({
        field: 'checkedRows',
        value: nonFiltersFields.checkedRows.map(({ id }) => ({ id, status: STATUS.LOADING })),
      }),
    );
    dispatch(bulkDeleteArticlesFeed({ ids: nonFiltersFields.checkedRows.map(RowToId), alertId })).then((res) => {
      let resData, newCheckedIds, countFails, countOK, message;
      switch (res.meta.result.status) {
        case 422:
          resData = res.payload.response.errors.result_data;
          break;
        case 201:
        default:
          countOK = res.payload.items[''].success;
          countFails = res.payload.items[''].failed;
          resData = res.payload.items[''].result;
          break;
      }

      let newStatus = countFails === 0 && countOK > 0 ? STATUS.SAVED : STATUS.KO;
      newCheckedIds = resData.map(treatBulkFeedResult);

      dispatch(changeFeedForm({ field: 'status', value: newStatus }));
      dispatch(changeFeedForm({ field: 'importIdsFail', value: countFails }));
      dispatch(changeFeedForm({ field: 'importIdsSuccess', value: countOK }));
      dispatch(tableActions[ownProps.tableId].changeForm({ field: 'checkedRows', value: newCheckedIds }));
      dispatch(tableActions[ownProps.tableId].changeForm({ field: 'status', value: newStatus }));
      if (countFails > 0) {
        toast.error(countFails + ' articles failed: ' + message);
      }
      if (countOK > 0) toast.success(countOK + ' articles deleted.');
    });
  },
});

export const onBulkApply = (stateProps, dispatchProps, ownProps) => ({
  articlesTable: () => {
    const { dispatch } = dispatchProps;
    const { nonFiltersFields, feedActionForm, alertId } = stateProps;
    dispatch(
      tableActions[ownProps.tableId].changeForm({
        field: 'importIdsCount',
        value: nonFiltersFields.checkedRows.length,
      }),
    );
    dispatch(tableActions[ownProps.tableId].changeForm({ field: 'status', value: STATUS.LOADING }));
    dispatch(importArticles({ ids: nonFiltersFields.checkedRows.map(RowToId), tagId: feedActionForm.tagId, alertId }));
  },
  articlesFeedTable: () => {
    const { dispatch } = dispatchProps;
    const { nonFiltersFields, feedActionForm, alertId } = stateProps;
    dispatch(changeFeedForm({ field: 'status', value: STATUS.LOADING }));
    dispatch(changeFeedForm({ field: 'importIdsCount', value: nonFiltersFields.checkedRows.length }));
    dispatch(tableActions[ownProps.tableId].changeForm({ field: 'status', value: STATUS.LOADING }));
    dispatch(
      tableActions[ownProps.tableId].changeForm({
        field: 'checkedRows',
        value: nonFiltersFields.checkedRows.map(({ id }) => ({ id, status: STATUS.LOADING })),
      }),
    );
    dispatch(
      importArticles({ ids: nonFiltersFields.checkedRows.map(RowToId), tagId: feedActionForm.tagId, alertId }),
    ).then((res) => {
      let resData, newCheckedIds, idsSuccess, idsFail, countFails, message;
      switch (res.meta.result.status) {
        case 422:
          resData = res.payload.response.errors.result_data;
          break;
        case 201:
        default:
          resData = res.payload.items[''].result;
          break;
      }
      newCheckedIds = resData.map(treatBulkFeedResult);
      idsSuccess = newCheckedIds.filter(({ status }) => status === STATUS.SAVED);
      idsFail = newCheckedIds.filter(({ status }) => status === STATUS.KO);
      countFails = idsFail.length;
      let countOK = idsSuccess.length;
      let newStatus = countFails === 0 && idsSuccess.length > 0 ? STATUS.SAVED : STATUS.KO;
      message = values(resData[0])[0];
      dispatch(changeFeedForm({ field: 'status', value: newStatus }));
      dispatch(changeFeedForm({ field: 'importIdsFail', value: countFails }));
      dispatch(changeFeedForm({ field: 'importIdsSuccess', value: idsSuccess.length }));
      dispatch(tableActions[ownProps.tableId].changeForm({ field: 'checkedRows', value: newCheckedIds }));
      dispatch(tableActions[ownProps.tableId].changeForm({ field: 'status', value: newStatus }));
      if (countFails > 0) {
        toast.error(countFails + ' articles failed: ' + message);
      }
      if (countOK > 0) toast.success(countOK + ' articles imported.');
    });
  },
});
