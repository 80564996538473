import {
  SAVED_VIDEOS_FETCH_VIDEOS_SUCCESS,
  SAVED_VIDEOS_SET_PAGE_SIZE,
  SAVED_VIDEOS_CLEAN,
} from '../../actions/actionTypes';

const initialState = 10;

const pageSize = (state = initialState, action) => {
  switch (action.type) {
    case SAVED_VIDEOS_SET_PAGE_SIZE:
      return action.payload;
    case SAVED_VIDEOS_FETCH_VIDEOS_SUCCESS:
      return action.payload.pagination.page_size;
    case SAVED_VIDEOS_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default pageSize;
