import React from 'react';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { connect } from 'react-redux';

import ButtonIcon from '../../../../components/common/ButtonIcon';
import { COVERAGE_API_ROOT } from '../../../../config/global-config';
import urlSearchParamsBuilder from '../../../../helpers/urlSearchParamsBuilder';
import { getArticlesFeedTableFilters } from '../../../../selectors/feeds/articlesFeed';
import { getCurrentProject } from '../../../../selectors/projects';
import { useExportArticles } from '../hooks/useArticlesFeed';

const ArticlesFeedTableExportButton = ({ filters, currentProject }) => {
  const endpoint = `${COVERAGE_API_ROOT}/csv/articles_feed/${currentProject['alert-id']}`;

  const url = urlSearchParamsBuilder({
    filters,
    pagination: {
      page: 0,
      pageSize: 1000000,
    },
    endpoint,
  });

  const { isLoading, refetch } = useExportArticles(url, currentProject);

  const displayText = isLoading ? 'Loading...' : 'Export';

  const handleClick = () => {
    refetch();
  };

  return (
    <ButtonIcon
      disabled={isLoading}
      aria-label="export-button"
      className="ml-3 text-uppercase fp-button"
      onClick={handleClick}
      icon={faExternalLinkAlt}
      transform="shrink-3 down-2"
      color="secondary"
      size="sm"
    >
      {displayText}
    </ButtonIcon>
  );
};

const mapStateToProps = (state) => ({
  filters: getArticlesFeedTableFilters(state),
  currentProject: getCurrentProject(state),
});

export default connect(mapStateToProps, null)(ArticlesFeedTableExportButton);
