import {
  API_CALL,
  ARTICLES_FEED_CLEAN,
  ARTICLES_FEED_FETCH_FEED,
  ARTICLES_FEED_FILTERS_CLEAR,
  ARTICLES_FEED_FILTERS_SET_END_DATE,
  ARTICLES_FEED_FILTERS_SET_LANGUAGES,
  ARTICLES_FEED_FILTERS_SET_MAX_MEDIA_SCORE,
  ARTICLES_FEED_FILTERS_SET_METACRITIC,
  ARTICLES_FEED_FILTERS_SET_MIN_MEDIA_SCORE,
  ARTICLES_FEED_FILTERS_SET_START_DATE,
  ARTICLES_FEED_SET_SORT_FIELD,
} from './actionTypes';
import urlSearchParamsBuilder from '../helpers/urlSearchParamsBuilder';

export const fetchArticlesFeed = ({ alert_id, filters, pagination }) => {
  let endpoint = `articles_feed/${alert_id}`;
  const articlesFeedUrl = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: ARTICLES_FEED_FETCH_FEED,
      endpoint: articlesFeedUrl,
      method: 'GET',
    },
  };
};

export const articlesFeedTableFiltersSetStartDate = (value) => ({
  type: ARTICLES_FEED_FILTERS_SET_START_DATE,
  payload: value,
});

export const articlesFeedTableFiltersSetEndDate = (value) => ({
  type: ARTICLES_FEED_FILTERS_SET_END_DATE,
  payload: value,
});

export const articlesFeedTableFiltersSetLanguages = (newLanguages) => ({
  type: ARTICLES_FEED_FILTERS_SET_LANGUAGES,
  payload: newLanguages,
});

export const articlesFeedTableFiltersSetMinScore = (minScore) => ({
  type: ARTICLES_FEED_FILTERS_SET_MIN_MEDIA_SCORE,
  payload: minScore,
});

export const articlesFeedTableFiltersSetMaxScore = (maxScore) => ({
  type: ARTICLES_FEED_FILTERS_SET_MAX_MEDIA_SCORE,
  payload: maxScore,
});

export const articlesFeedTableFiltersSetMetacritic = (metacritic) => ({
  type: ARTICLES_FEED_FILTERS_SET_METACRITIC,
  payload: metacritic,
});

export const articlesFeedClean = () => ({
  type: ARTICLES_FEED_CLEAN,
});

export const articlesFeedTableFiltersClean = () => ({
  type: ARTICLES_FEED_FILTERS_CLEAR,
});

export const articlesFeedSetSortField = (sortField) => ({
  type: ARTICLES_FEED_SET_SORT_FIELD,
  payload: sortField,
});
