import {
  SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS,
  SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_SUCCESS,
  SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_FAILURE,
  SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_ERROR,
  SETTINGS_CLEAR,
} from '../../actions/actionTypes';

const defaultState = {
  isLoading: false,
  response: null,
  error: null,
};

const customerOrganizations = (state = defaultState, action) => {
  switch (action.type) {
    case SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS:
      return { ...state, isLoading: true };
    case SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_SUCCESS:
      return { ...state, isLoading: false, response: action.payload };
    case SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_FAILURE:
    case SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case SETTINGS_CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export default customerOrganizations;
