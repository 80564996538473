import React from 'react';

import { PropTypes } from 'prop-types';

export const ConfirmKickAction = ({ username }) => (
  <span>Are you sure you want to remove {username} from your Organization?</span>
);

ConfirmKickAction.propTypes = {
  username: PropTypes.string,
};
