import React from 'react';

import PropTypes from 'prop-types';
import { CardDeck } from 'reactstrap';

import ArticleMetric from '../../../components/ICO/articles/ArticleMetric';
import ScoreMetric from '../../../components/ICO/articles/ScoreMetric';
import StreamMetric from '../../../components/ICO/articles/StreamMetric.jsx';
import VideoMetric from '../../../components/ICO/articles/VideoMetric.jsx';
import WebsiteMetric from '../../../components/ICO/articles/WebsiteMetric';

const ReportDetailsKeyMetrics = ({ currentReport, reportId }) => (
  <>
    <CardDeck>
      <ArticleMetric reportId={reportId} />
      {currentReport['reports-videos']?.length ? (
        <VideoMetric savedVideos={currentReport['reports-videos']?.length ?? 0} />
      ) : null}
      {currentReport['reports-streams']?.length ? (
        <StreamMetric savedStreams={currentReport['reports-streams']?.length ?? 0} />
      ) : null}
      <WebsiteMetric reportId={reportId} />
      <ScoreMetric reportId={reportId} />
    </CardDeck>
  </>
);

ReportDetailsKeyMetrics.propTypes = {
  reportId: PropTypes.string,
};

export default ReportDetailsKeyMetrics;
