import React from 'react';

import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';

import '../../../../assets/scss/theme/Footprints/components/upgrade-subscription/_upgrade-subscription.scss';

import translate from '../../../../helpers/translate';

const UpgradeSubscriptionShowMore = ({ onClick, showMore }) => (
  <Container className="mt-4 text-primary" onClick={onClick} aria-label="container">
    <Row>
      <Col className="d-flex flex-center justify-content-center" xs={12}>
        <div className="arrow-container mt-2">
          <FontAwesomeIcon className="arrow" aria-label="icon" icon={showMore ? faChevronUp : faChevronDown} />
        </div>
      </Col>
    </Row>
    <Row>
      <Col>{showMore ? translate('common.see_less') : translate('common.see_more')}</Col>
    </Row>
  </Container>
);

export default UpgradeSubscriptionShowMore;

UpgradeSubscriptionShowMore.propTypes = { onClick: PropTypes.func };
