import { YOUTUBE_FEED_FILTERS_CLEAR, YOUTUBE_FEED_FILTERS_SET_MAX_VIEWS } from '../../../actions/actionTypes';

const initialState = null;

const maxViews = (state = initialState, action) => {
  switch (action.type) {
    case YOUTUBE_FEED_FILTERS_SET_MAX_VIEWS:
      return action.payload;
    case YOUTUBE_FEED_FILTERS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default maxViews;
