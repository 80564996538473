import React, { useState, useContext, useEffect } from 'react';

import debounce from 'lodash/debounce';
import { useQueryClient } from 'react-query';
import { Col, Row } from 'reactstrap';

import WizardInput from '../../../components/auth/wizard/WizardInput';
import { AuthWizardContext } from '../../../context/Context';
import translate from '../../../helpers/translate';
import { useGetTwitchPreview } from '../hooks/useSignupFreeUser';

const brandInputRegisterConfig = {
  required: translate('signup.free_user.brand.errors.required'),
  minLength: {
    value: 3,
    message: translate('signup.free_user.brand.errors.min_length'),
  },
  maxLength: {
    value: 50,
    message: translate('signup.free_user.brand.errors.max_length'),
  },
};

const projectInputRegisterConfig = {
  validate: (value) => {
    if (!value || (!value.label && !value.value)) {
      return translate('signup.free_user.project.errors.required');
    }
    if (value.label && value.label.length < 3) {
      return translate('signup.free_user.project.errors.min_length');
    }
    if (value.label && value.label.length > 50) {
      return translate('signup.free_user.project.errors.max_length');
    }
    return true;
  },
};

const SignUpFreeUserBrandProject = ({ register, errors, setValue, trigger }) => {
  const queryClient = useQueryClient();
  const [inputValue, setInputValue] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const { handleInputChange: contextHandleInputChange } = useContext(AuthWizardContext);
  const { data, isLoading } = useGetTwitchPreview(inputValue);

  const clearQueryCache = () => {
    queryClient.removeQueries(['get_twitch_games_preview']);
  };

  const debouncedSearch = debounce((value) => {
    setInputValue(value);
    setHasSearched(true);
    if (value === '') {
      clearQueryCache();
    }
  }, 300);

  const handleInputChange = (newValue, action) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setInputValue(newValue);
      debouncedSearch(newValue);
      setHasSearched(false);
    }
    return newValue;
  };

  const handleChangeExtra = (newValue) => {
    let value = null;

    if (newValue) {
      value = {
        name: newValue.label,
        id: newValue.__isNew__ ? null : newValue.value,
      };
    }

    contextHandleInputChange({
      name: 'project',
      value,
    });

    setValue('project_name', newValue);
    trigger('project_name');

    clearQueryCache();
    setInputValue('');
    setHasSearched(false);
  };

  const noOptionsMessage = ({ inputValue }) => {
    if (isLoading) return 'Loading...';
    if (!hasSearched || inputValue.length < 3) return 'Type to search...';
    if (hasSearched && data && data.data && data.data.length === 0) return null;
  };

  const options = data && data.data ? data.data.map((s) => ({ value: s.id, label: s.name })) : [];

  useEffect(() => {
    register('project_name', projectInputRegisterConfig);
  }, [register]);

  return (
    <>
      <Row className="mb-2">
        <Col>
          <p className="fs-2 text-center">{translate('signup.free_user.project.copy_coverage')}</p>
          <p className="fs-2 text-center">{translate('signup.free_user.project.copy_project')}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <WizardInput
            aria-label="brand-input"
            label={translate('signup.free_user.brand.label')}
            placeholder={translate('signup.free_user.brand.placeholder')}
            name="brand_name"
            id="brand_name"
            innerRef={register(brandInputRegisterConfig)}
            errors={errors}
          />
          <WizardInput
            type="creatable-select"
            label={translate('signup.free_user.project.label')}
            placeholder={translate('signup.free_user.project.placeholder')}
            name="project_name"
            id="project_name"
            options={options}
            onInputChange={handleInputChange}
            noOptionsMessage={noOptionsMessage}
            isLoading={isLoading}
            onChangeExtra={handleChangeExtra}
            isValidNewOption={(inputValue) =>
              hasSearched && inputValue.length >= 3 && (!data || !data.data || data.data.length === 0)
            }
            formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
            errors={errors}
          />
        </Col>
      </Row>
    </>
  );
};

export default SignUpFreeUserBrandProject;
