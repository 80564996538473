import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchClients } from '../actions/clients';
import { changeModule } from '../actions/modules';
import { fetchProducts } from '../actions/products';
import { fetchProjects } from '../actions/projects';
import { fetchCurrentUser } from '../actions/users';
import { getUsersCurrentUser } from '../selectors/users';

const LoadingCallback = ({ onLoad }) => {
  let urlParams = useParams();

  useEffect(() => {
    onLoad();
  }, [onLoad, urlParams]);

  return <></>;
};

const mapStateToProps = (state) => ({
  user: getUsersCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    dispatch(
      changeModule({
        moduleId: 'loadingModule',
        value: {
          isLoading: true,
          fetchStarted: true,
          fetchCompleted: false,
        },
      }),
    );
    Promise.all([
      dispatch(fetchProjects()),
      dispatch(fetchClients()),
      dispatch(fetchCurrentUser()),
      dispatch(fetchProducts()),
    ]).then((data) => {
      if (data && data[2] && data[2].payload.items) {
        dispatch(
          changeModule({
            moduleId: 'loadingModule',
            value: {
              isLoading: false,
              fetchStarted: true,
              fetchCompleted: true,
            },
          }),
        );
      }
    });
  },
});

LoadingCallback.propTypes = {
  onLoad: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingCallback);
