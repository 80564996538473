import React from 'react';

import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import YoutubeSavedVideosTableVideoPreviewModalBody from './YoutubeSavedVideosTableVideoPreviewModalBody.jsx';
import YoutubeSavedVideosTableVideoPreviewModalFooter from './YoutubeSavedVideosTableVideoPreviewModalFooter.jsx';

const YoutubeSavedVideosVideoPreviewModal = ({ isOpen, onClose, videoData, withSave }) => (
  <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
    <ModalBody className="fp-modal-body p-4">
      <YoutubeSavedVideosTableVideoPreviewModalBody videoData={videoData} />
    </ModalBody>
    <ModalFooter>
      <YoutubeSavedVideosTableVideoPreviewModalFooter videoData={videoData} onClose={onClose} withSave={withSave} />
    </ModalFooter>
  </Modal>
);

export default YoutubeSavedVideosVideoPreviewModal;
