import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';

import { changeReportName, setReportData } from '../../../actions/reports';

const RenameReport = ({ contentData, currentReportName, setReportData, changeReportName }) => {
  useEffect(() => {
    setReportData(contentData);
  }, [setReportData, contentData]);

  const handleOnChange = (event) => {
    changeReportName(event.target.value);
  };

  return (
    <FormGroup>
      <Row>
        <Col md={12} xs={12}>
          <input type="text" placeholder={currentReportName} className="form-control" onChange={handleOnChange} />
        </Col>
      </Row>
    </FormGroup>
  );
};

RenameReport.propTypes = {
  currentReportName: PropTypes.string,
  setReportData: PropTypes.func,
  changeReportName: PropTypes.func,
};

RenameReport.defaultProps = {
  currentReportName: '',
};

export default connect(null, { setReportData, changeReportName })(RenameReport);
