import React from 'react';

import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { savedVideosTableFiltersSetTags } from '../../../../actions/savedVideos';
import SingleSelect from '../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../helpers/translate';
import { getSavedVideosFiltersTags } from '../../../../selectors/savedCoverage/savedVideos';
import { getNotLockedTags } from '../../../../selectors/tags';

const SavedVideosFiltersModalBodyTags = ({ tags, selectedTags, savedVideosTableFiltersSetTags }) => {
  const handleOnChange = (tags) => {
    if (tags) {
      const tagIds = tags.map(({ value }) => value);
      savedVideosTableFiltersSetTags(tagIds);
    } else {
      savedVideosTableFiltersSetTags(null);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <FormGroup>
          <Label for="tags">{translate('common.tags')}</Label>
          <SingleSelect
            name="tags"
            isMulti
            aria-label="tag-select"
            items={tags}
            value={selectedTags ?? []}
            className="sm-select"
            placeholder={translate('common.all_tags')}
            onChange={handleOnChange}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  tags: getNotLockedTags(state),
  selectedTags: getSavedVideosFiltersTags(state),
});

export default connect(mapStateToProps, { savedVideosTableFiltersSetTags })(SavedVideosFiltersModalBodyTags);
