import React, { Fragment, useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Media } from 'reactstrap';

import { saveAvatar } from '../../../actions/files';
import cloudUpload from '../../../assets/img/icons/cloud-upload.svg';
import { AuthWizardContext } from '../../../context/Context';
import translate from '../../../helpers/translate';
import { isIterableArray } from '../../../helpers/utils';
import Avatar from '../../common/Avatar';
import ICODropzone from '../uploads/ICODropzone';

const RegisterPictureForm = ({ onSelectImage }) => {
  const { user } = useContext(AuthWizardContext);
  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: 'https://ico-files.s3.eu-west-2.amazonaws.com/images/footprints-logo-avatar.svg' },
  ]);
  const { handleInputChange } = useContext(AuthWizardContext);
  const avatarSrc = isIterableArray(avatar) ? avatar[0].preview || avatar[0].src : '';

  return (
    <Fragment>
      <Media className="flex-column flex-center pb-3 d-block d-md-flex text-center mb-2">
        <Avatar size="4xl" className="mb-2" src={avatarSrc} />
        <Media body>
          <ICODropzone
            files={avatar}
            onChange={(files, binaryFiles) => {
              setAvatar(files);
              onSelectImage(binaryFiles);
              handleInputChange({ name: 'picture', value: files });
            }}
            multiple={false}
            accept="image/*"
            placeholder={
              <Fragment>
                <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                  <img src={cloudUpload} alt="" width={25} />
                </Media>
                <p className="mb-0 w-75 mx-auto text-500">{translate('avatar_image_uploader')}</p>
              </Fragment>
            }
          />
        </Media>
      </Media>
    </Fragment>
  );
};

RegisterPictureForm.propTypes = {
  onSelectImage: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onSelectImage: (binaryFiles) => {
    dispatch(saveAvatar({ images: binaryFiles, scope: '0;usr' }));
  },
});

export default connect(null, mapDispatchToProps)(RegisterPictureForm);
