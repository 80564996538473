import React, { useEffect, useState } from 'react';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';

import NewReportFiltersForm from './NewReportFiltersForm';
import NewReportSubmitButton from './NewReportSubmitButton';
import { fetchLanguages } from '../../actions/languages';
import { fetchProjects } from '../../actions/projects';
import { newReportSetSelectedLanguages, newReportSetSelectedTags, reportsClean } from '../../actions/reports';
import { fetchTags } from '../../actions/tags';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { getCurrentProjectId } from '../../selectors/forms';
import { getLanguagesValueAndLabel } from '../../selectors/languages';
import { getNewReportFormStatus } from '../../selectors/savedCoverage/articles';
import StepIcons from '../Signup/StepIcons';

export const parseStepFromFormStatus = (formStatus) => parseInt(formStatus.substring(5, 6));

const NewReportForm = ({ onLoad, formStatus, cleanState, loadedArticlesStep2, projectId }) => {
  useEffect(() => {
    onLoad(projectId);
  }, [onLoad, projectId]);

  useEffect(() => () => cleanState(), [cleanState]);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  if (formStatus === 'OKkk') {
    return (
      <form action="#" className="form-horizontal">
        <Row className="text-right">
          <Col md={12} xs={12}>
            <NewReportSubmitButton />
          </Col>
        </Row>
      </form>
    );
  }
  const step = parseStepFromFormStatus(formStatus);
  const handleBackStep = (targetStep) => {
    if (step !== 3) {
      if (targetStep < step) {
        //setStep(targetStep);
      }
    } else {
      toggle();
    }
  };
  return (
    <Row>
      <Col md={12} xs={12}>
        <Card className="theme-wizard">
          <FalconCardHeader title={'Create a new report'} light={false} className="mt-2 mb-2" />
          <CardBody>
            <Nav className="justify-content-center">
              <NavItem>
                <NavLink
                  className={classNames('font-weight-semi-bold', {
                    'done  cursor-pointer': step > 0,
                    active: step === 0,
                  })}
                  onClick={() => handleBackStep(0)}
                >
                  <StepIcons icon={faCalendar} text="Date range" className="fp-new-report-date" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames('font-weight-semi-bold', {
                    'done cursor-pointer': step > 1,
                    active: step === 1,
                  })}
                  onClick={() => handleBackStep(1)}
                >
                  <StepIcons icon={faFilter} text="Filters" className="fp-new-report-filters" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames('font-weight-semi-bold', {
                    'done cursor-pointer': step > 2,
                    active: step === 2,
                  })}
                  onClick={() => handleBackStep(2)}
                >
                  <StepIcons icon={faEye} text="Preview" className="fp-new-report-preview" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames('font-weight-semi-bold', {
                    'done  cursor-pointer': step >= 3,
                  })}
                >
                  <StepIcons icon={faCheck} text="Publish" className="fp-new-report-publish" />
                </NavLink>
              </NavItem>
            </Nav>
            <NewReportFiltersForm formStatus={formStatus} step={step} loadedArticlesStep2={loadedArticlesStep2} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

NewReportForm.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  btnLabel: PropTypes.string,
  btnClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  projectId: getCurrentProjectId(state),
  formStatus: getNewReportFormStatus(state),
  languages: getLanguagesValueAndLabel(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (projectId) => {
    dispatch(fetchProjects());
    dispatch(fetchLanguages());
    dispatch(fetchTags(projectId));
  },
  setSelectedTags: (tags) => dispatch(newReportSetSelectedTags(tags)),
  setSelectedLanguages: (languages) => dispatch(newReportSetSelectedLanguages(languages)),
  cleanState: () => dispatch(reportsClean()),
});

NewReportForm.propTypes = {
  onLoad: PropTypes.func,
  formStatus: PropTypes.string,
  loadedArticlesStep1: PropTypes.array,
  loadedArticlesStep2: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewReportForm);
