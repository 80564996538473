import React, { createRef, useEffect, useState } from 'react';

import fileDownload from 'js-file-download';
import { isEqual } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, CustomInput, InputGroup, Row } from 'reactstrap';

import SaveWebsiteButton from './AddWebsiteButton/AddWebsiteButton';
import { ArticlesSaveButton } from './ArticlesSaveButton';
import { CustomSearchBar } from './CustomSearchBar';
import ExportCSVButton from './ExportCSVButton/ExportCSVButton';
import { onBulkApply, onLoad } from './helpers/actions';
import { applySort, rowsFormatter } from './helpers/formatters';
import { getRowsStatsFiltered, getRowsStatusFiltered, tableRows } from './helpers/selectors';
import { fetchCSVRows, loadFilters, onChangeFilterTable, pageClick } from '../../../actions/articles';
import {
  changeFeedForm,
  resetCheckedCount,
  showModal,
  STATUS_ENABLED,
  STATUS_LOADING,
  tableActions,
} from '../../../actions/forms';
import { changePageSize } from '../../../actions/modules';
import { buildFiltersQuery, STATUS } from '../../../config/api-service';
import { ADMIN_ROLE_ID } from '../../../constants';
import { getFormattedTime } from '../../../helpers/ICOutils';
import {
  getTableFiltersFormFieldsById,
  getTableFiltersStatusFormFieldsById,
  getTableFormFieldsById,
} from '../../../selectors/forms';
import { getModule } from '../../../selectors/modules';
import { getCurrentProject } from '../../../selectors/projects';
import { getUsersCurrentUser } from '../../../selectors/users';
import ButtonIcon from '../../common/ButtonIcon';
import FalconCardHeader from '../../common/FalconCardHeader';
import getToastedNotification from '../helpers/toaster.helper';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import '../../../assets/scss/theme/Footprints/components/_table.scss';
import '../../../assets/scss/theme/Footprints/components/_button.scss';

export const dateRangeFilter = (dateRange) => (row) => {
  if (dateRange.length === 0) {
    return true;
  }
  const startDate = moment(dateRange[0]);
  const endDate = moment(dateRange[1]);
  const insertedAt = moment(row['inserted-at']);
  return insertedAt.isSameOrAfter(startDate, 'day') && insertedAt.isSameOrBefore(endDate, 'day');
};

export const scoreFilter = (scoreRange) => (row) => {
  if (scoreRange.length === 0) {
    return true;
  }
  return row.reach >= scoreRange[0] && row.reach <= scoreRange[1];
};

export const languagesFilter = (language) => (row) => {
  if (language < 0) return true;
  return row.language === language;
};

export const tagFilter = (tag) => (row) => {
  if (tag < 0) return true;
  return row.tag === tag;
};

export const applyFiltersToRows = (rows, filters) =>
  rows
    .filter(dateRangeFilter(filters.date_range))
    .filter(languagesFilter(filters.languages[0]))
    .filter(scoreFilter(filters.score_range))
    .filter(tagFilter(filters.tagId));

const SelectRowInput = ({ indeterminate, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      ref={(input) => {
        if (input) {
          input.indeterminate = indeterminate;
        }
      }}
      {...rest}
    />
    <label className="custom-control-label" />
  </div>
);

const DynamicDataTable = ({
  title,
  filters,
  urlParams,
  checkedRows,
  formStatus,
  rowsStatus,
  totalRows,
  onExportCSV,
  canSaveArticles,
  onFilterOpen,
  onSelect,
  onTableChange,
  onClearFilters,
  onClearSelect,
  rowsSelectable = true,
  rows,
  rowsSelectedCount,
  onLoad,
  columns,
  onBulkOpen,
  onBulkDeleteOpen,
  pagination,
  rowsPerPageList,
  onPageSizeChange,
  fieldsStatus,
  canSaveWebsite,
  internalUser,
  subtitle,
}) => {
  let table = createRef();
  const options = {
    custom: true,
    sizePerPage: pagination.page_size,
    page: pagination.current_page,
    totalSize: totalRows,
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onLoad, [urlParams.projectId || null]);

  const [getInitialFilters] = useState(filters);
  const [getBulkActionSelect, setBulkActionSelect] = useState('Move');
  const isInitialFiltersValue = () => isEqual(filters, getInitialFilters);
  const unselectAll = () => {
    table.current.selectionContext.selected = [];
  };

  const onSelectLocal = () => {
    //Wait till next tick so selection is accurate as the select is only enabled next tick
    // eslint-disable-next-line no-undef
    setImmediate(() => {
      onSelect(table.current.selectionContext.selected);
    });
  };

  const selectRow = (onSelect) => ({
    mode: 'checkbox',
    clickToSelect: false,
    selectionHeaderRenderer: ({ ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
    selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
    headerColumnStyle: { border: 0, verticalAlign: 'middle' },
    selectColumnStyle: { border: 0, verticalAlign: 'middle' },
    classes: (row) => {
      let currentRow = checkedRows.filter(({ id }) => row.id === id);
      if (currentRow.length) {
        let selectedRow = currentRow[0];
        if (selectedRow.status === STATUS.OK) return 'checked';
        if (selectedRow.status === STATUS.SAVED) return 'checked-success';
        if (selectedRow.status === STATUS.LOADING) return 'checked-loading';
        if (selectedRow.status === STATUS.KO) return 'checked-error';
        return 'checked';
      }
    },
    onSelect: onSelect,
    onSelectAll: onSelect,
  });

  const handleClear = () => {
    unselectAll();
    onClearSelect();
    onClearFilters();
  };

  const userIsAdmin = internalUser['role-id'] === ADMIN_ROLE_ID;

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = paginationProps.page * paginationProps.sizePerPage;
        return (
          <Card className="mt-3 mb-3">
            <div className="table-responsive fp-table">
              <ToolkitProvider keyField="id" data={rows} columns={columns} bootstrap4 bordered={false} search exportCSV>
                {(props) => (
                  <>
                    <FalconCardHeader title={title} subtitle={subtitle} light={false} className="mt-2 mb-2">
                      {rowsSelectedCount > 0 ? (
                        <InputGroup size="sm" className="input-group input-group-sm">
                          {formStatus === STATUS.KO && (
                            <ButtonIcon size={'sm'} color={'warning'}>
                              Error during bulk action.
                            </ButtonIcon>
                          )}
                          <ButtonIcon
                            icon="times"
                            transform="shrink-3 down-2"
                            size="sm"
                            color="secondary"
                            className="mx-2 text-uppercase fp-button-disabled"
                            onClick={handleClear}
                          >
                            Clear
                          </ButtonIcon>
                          <ButtonIcon size={'sm'} color={'info'} className="ml-2">
                            {rowsSelectedCount} row{rowsSelectedCount > 1 && 's'} selected.
                          </ButtonIcon>
                          <CustomInput
                            type="select"
                            id="bulk-select"
                            className="ml-2 text-uppercase"
                            value={getBulkActionSelect}
                            onChange={(e) => setBulkActionSelect(e.target.value)}
                          >
                            <option value="Move">Move to Articles</option>
                            <option value="Export">Export</option>
                            <option value="Delete">Delete</option>
                          </CustomInput>
                          <Button
                            onClick={() => {
                              if (getBulkActionSelect === 'Export') {
                                onExportCSV();
                              } else if (getBulkActionSelect === 'Move') {
                                onBulkOpen();
                              } else {
                                onBulkDeleteOpen();
                              }
                            }}
                            color="secondary"
                            size="sm"
                            className="ml-2"
                          >
                            Apply
                          </Button>
                        </InputGroup>
                      ) : (
                        <Row className="mt-3 mt-md-0">
                          <Col xs={12}>
                            {canSaveWebsite && userIsAdmin && <SaveWebsiteButton />}
                            {canSaveArticles ? <ArticlesSaveButton onChange={() => false} /> : undefined}
                            <CustomSearchBar
                              onChange={onTableChange}
                              filterSearch={filters.search}
                              {...props.searchProps}
                            />
                            <ButtonIcon
                              icon="times"
                              disabled={isInitialFiltersValue()}
                              color="secondary"
                              transform="shrink-3 down-2"
                              size="sm"
                              className="mx-2 d-none d-md-inline-block text-uppercase fp-button-disabled"
                              onClick={onClearFilters}
                            >
                              Clear
                            </ButtonIcon>
                            <ButtonIcon
                              icon="filter"
                              active={!isInitialFiltersValue()}
                              transform="shrink-3 down-2"
                              color="secondary"
                              size="sm"
                              className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
                              onClick={onFilterOpen}
                            >
                              Filter
                            </ButtonIcon>
                            <ExportCSVButton status={fieldsStatus.exportButton} onExport={onExportCSV} rows={rows} />
                          </Col>
                        </Row>
                      )}
                    </FalconCardHeader>
                    <CardBody className="p-0">
                      {rowsStatus === STATUS.LOADING && <Loading pageSize={pagination.page_size} isTable={true} />}
                      <BootstrapTable
                        selectRow={rowsSelectable ? selectRow(onSelectLocal) : undefined}
                        classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                        rowClasses="btn-reveal-trigger rows-vh-fixed"
                        headerClasses="bg-200 text-900"
                        ref={table}
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true,
                          cellEdit: false,
                        }}
                        noDataIndication={() => (
                          <div className="alert alert-info alert-styled-left table-info">
                            No matching data was found.
                          </div>
                        )}
                        ignoreSinglePage
                        onTableChange={onTableChange}
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                    </CardBody>
                  </>
                )}
              </ToolkitProvider>
            </div>
            <Pagination
              lastIndex={lastIndex}
              onChange={onPageSizeChange}
              paginationProps={paginationProps}
              rows={rows}
              rowsPerPage={pagination.page_size}
              rowsPerPageList={rowsPerPageList}
              dropdownType="dynamic"
            />
          </Card>
        );
      }}
    </PaginationProvider>
  );
};

const mapStateToProps = (state, ownProps) => {
  const module = getModule(state, ownProps.tableId);
  const currentGlobalProject = getCurrentProject(state);
  const nonFiltersFields = getTableFormFieldsById(state, ownProps.tableId);
  const rowsStatus = getRowsStatusFiltered(state, ownProps.rowsType, ownProps.tableId, currentGlobalProject);
  const totalRows = getRowsStatsFiltered(
    state,
    ownProps.rowsType,
    ownProps.tableId,
    currentGlobalProject,
  ).total_entries;
  const totalPages =
    Math.ceil(totalRows / module.pagination.page_size) > 0 ? Math.ceil(totalRows / module.pagination.page_size) : 1;
  const filters = getTableFiltersFormFieldsById(ownProps.tableId)(state);
  let rows;
  if (filters.sort) {
    rows = applySort(
      rowsFormatter(
        state,
        tableRows(state)[ownProps.tableId](),
        rowsStatus,
        module.pagination.page_size,
        nonFiltersFields.checkedRows,
      )[ownProps.tableId](),
      filters.sort,
    );
  } else {
    rows = rowsFormatter(
      state,
      tableRows(state)[ownProps.tableId](),
      rowsStatus,
      module.pagination.page_size,
      nonFiltersFields.checkedRows,
    )[ownProps.tableId]();
  }
  const internalUser = getUsersCurrentUser(state);

  return {
    state,
    currentGlobalProject,
    alertId: currentGlobalProject['alert-id'] || -1,
    filters,
    fieldsStatus: getTableFiltersStatusFormFieldsById(state, ownProps.tableId),
    nonFiltersFields,
    rows,
    totalRows: totalRows,
    totalPages: totalPages,
    rowsPerPageList: [12, 25, 50, 100],
    rowsStatus,
    formStatus: nonFiltersFields.status,
    checkedRows: nonFiltersFields.checkedRows,
    rowsSelectedCount: nonFiltersFields.checkedRows.length,
    module,
    pagination: module ? module.pagination : {},
    ownProps,
    internalUser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onClearFilters: () => {
    dispatch(tableActions[ownProps.tableId].resetFilterForm());
  },
  onBulkDeleteOpen: () => {
    dispatch(showModal({ content: ownProps.tableId + 'BulkDelete' }));
    dispatch(changeFeedForm({ field: 'status', value: STATUS.OK }));
  },
  onBulkOpen: () => {
    dispatch(showModal({ content: ownProps.tableId + 'Bulk' }));
  },
  onFilterOpen: () => {
    dispatch(showModal({ content: ownProps.tableId + 'Filters' }));
  },
  onClearSelect: () => {
    dispatch(tableActions[ownProps.tableId].changeForm({ field: 'checkedRows', value: [] }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onLoad: () => {
    onLoad(stateProps, dispatchProps, ownProps)[ownProps.tableId]();
  },
  onSelect: (artIds) => {
    const { dispatch } = dispatchProps;
    const { checkedRows } = stateProps;
    dispatch(resetCheckedCount());
    dispatch(changeFeedForm({ field: 'status', value: STATUS.OK }));
    dispatch(
      tableActions[ownProps.tableId].changeForm({
        field: 'checkedRows',
        value: artIds.map((id) => {
          let previousRow = checkedRows.find((row) => id === row.id);
          if (previousRow) {
            return previousRow;
          }
          return { id, status: STATUS.OK };
        }),
      }),
    );
  },
  onPageSizeChange: (value) => {
    const { dispatch } = dispatchProps;
    const { pagination, filters } = stateProps;
    const newPageSize = value ?? 10;
    dispatch(changePageSize(ownProps.tableId, newPageSize));
    pageClick(
      { page: pagination.current_page, page_size: newPageSize },
      dispatch,
      ownProps.tableId,
      filters,
      stateProps.currentGlobalProject,
    );
  },
  onBulkApply: () => {
    onBulkApply(stateProps, dispatchProps, ownProps)[ownProps.tableId]();
  },
  onExportCSV: () => {
    const { dispatch } = dispatchProps;
    const { checkedRows } = stateProps;
    dispatch(tableActions[ownProps.tableId].changeStatusForm({ field: 'exportButton', value: STATUS_LOADING }));

    const { filters, currentGlobalProject } = stateProps;
    let fetchParams;

    if (checkedRows.length > 0) {
      fetchParams = {
        filter: {
          ids_list: checkedRows
            .filter(({ status }) => status === STATUS.OK)
            .map(({ id }) => id)
            .join(','),
        },
        page: {
          page: 0,
          page_size: 10000,
        },
      };
    } else {
      fetchParams = {
        filter: buildFiltersQuery(filters),
        page: {
          page: 0,
          page_size: 1000000,
        },
      };
      if (filters.sort) {
        let sort = filters.sort;
        fetchParams = { ...fetchParams, sort };
      }
      if (ownProps.reportId) {
        fetchParams = {
          ...fetchParams,
          filter: { ...fetchParams.filter, report_id: ownProps.reportId },
        };
      }
    }
    let csvPath;
    if (ownProps.rowsType === 'rssArticles') {
      let alertId = currentGlobalProject['alert-id'];
      csvPath = 'external_articles_alert/' + alertId;
    } else if (ownProps.rowsType === 'websites') {
      csvPath = 'websites';
    } else {
      csvPath = 'articles';
    }
    fetchCSVRows(csvPath, fetchParams).then(
      function (response) {
        response.text().then((res) => {
          const currentTime = getFormattedTime();
          const fileName =
            ownProps.rowsType === 'websites'
              ? `websites_${currentTime}.csv`
              : `${currentGlobalProject.name || 'export'}_${currentTime}.csv`;
          fileDownload(res, fileName);
          dispatch(tableActions[ownProps.tableId].changeStatusForm({ field: 'exportButton', value: STATUS_ENABLED }));
          const customMessage = { type: 'success', message: 'Export ready. Starting download...' };
          getToastedNotification({ customMessage });
        });
      },
      function (error) {
        getToastedNotification({ customMessage: 'error', message: `Error ${error.message}` });
        return error.message; //=> String
      },
    );
  },
  onTableChange: (type, newState) => {
    const { filters, pagination, currentGlobalProject } = stateProps;
    const { dispatch } = dispatchProps;
    if (type === 'pagination') {
      pageClick(
        { page: newState.page, page_size: pagination.page_size },
        dispatch,
        ownProps.tableId,
        stateProps.filters,
        stateProps.currentGlobalProject,
      );
    } else if (type === 'search') {
      const newSearch = newState === '' ? [] : newState;
      const { filters, pagination } = stateProps;
      const { dispatch } = dispatchProps;
      const newPagination = {
        enabled: true,
        table: ownProps.tableId,
        pagination: {
          page: 1,
          page_size: pagination.page_size,
        },
        alertId: currentGlobalProject['alert-id'] || 0,
      };
      return onChangeFilterTable(filters, dispatch, newSearch, 'search', newPagination);
    } else if (type === 'sort') {
      let columnSort = newState.sortField;
      if (columnSort === filters.sort) {
        columnSort = filters.sort[0] === '-' ? columnSort : `-${columnSort}`;
      }
      return loadFilters(dispatch, ['sort', columnSort], filters, columnSort, {
        enabled: true,
        table: ownProps.tableId,
        pagination: {
          page: pagination.current_page,
          page_size: pagination.page_size,
        },
        alertId: currentGlobalProject['alert-id'] || 0,
      });
    }
  },
});

DynamicDataTable.propTypes = {
  title: PropTypes.string,
  filters: PropTypes.shape({}),
  urlParams: PropTypes.shape({}),
  checkedRows: PropTypes.array,
  formStatus: PropTypes.string,
  rowsStatus: PropTypes.string,
  totalRows: PropTypes.number,
  onExportCSV: PropTypes.func,
  canSaveArticles: PropTypes.bool,
  onFilterOpen: PropTypes.func,
  onSelect: PropTypes.func,
  onTableChange: PropTypes.func,
  onClearFilters: PropTypes.func,
  onClearSelect: PropTypes.func,
  rowsSelectable: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  rowsSelectedCount: PropTypes.number,
  onLoad: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  onBulkOpen: PropTypes.func,
  onBulkDeleteOpen: PropTypes.func,
  pagination: PropTypes.shape({}),
  rowsPerPageList: PropTypes.arrayOf(PropTypes.number),
  onPageSizeChange: PropTypes.func,
  fieldsStatus: PropTypes.shape({
    exportButton: PropTypes.node,
  }),
  canSaveWebsite: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DynamicDataTable);
