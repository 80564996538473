import { createSelector } from 'reselect';

import { getFeedTableFiltersForm } from './forms';
import { getModules } from './modules';
import { getCurrentProject } from './projects';
import { buildFiltersQuery, genGetParamsWithId, STATUS } from '../config/api-service';

export const getRssArticles = (state) => state.feeds.rssArticles.items;

export const getRssArticlesLists = (state) => state.feeds.rssArticles.lists;

export const getExternalArticlesStatus = (state) => state.feeds.rssArticles.itemsStatus;

export const getFeedTableKey = createSelector([getModules, getFeedTableFiltersForm], (modules, tableFilters) => {
  let filtersResult = {
    page: {
      page: modules.articlesFeedTable.pagination.current_page,
      page_size: modules.articlesFeedTable.pagination.page_size,
    },
    filter: buildFiltersQuery(tableFilters.fields),
  };
  if (tableFilters.fields.sort) {
    filtersResult = { ...filtersResult, sort: tableFilters.fields.sort };
  }
  return filtersResult;
});

export const getRssArticlesTable = createSelector(
  [getRssArticles, getExternalArticlesStatus, getRssArticlesLists, getFeedTableKey, getCurrentProject],
  (rssArticles, rssArticlesStatus, idsList, tableKey, currentProject) => {
    const articleIds = idsList[genGetParamsWithId(currentProject['alert-id'], tableKey)];
    if (articleIds) {
      return articleIds.reduce((res, id) => {
        if (
          rssArticlesStatus[id] === STATUS.SAVED ||
          rssArticlesStatus[id] === STATUS.OK ||
          rssArticlesStatus[id] === STATUS.LOADING
        ) {
          return { ...res, [id]: rssArticles[id] };
        }
        return { ...res };
      }, {});
    }
    return [];
  },
);
