import { useMutation, useQuery } from 'react-query';

import { COVERAGE_API_ROOT } from '../../../config/global-config';

export const useCreateAccount = () =>
  useMutation({
    mutationKey: ['/p/free_user'],
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/p/free_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.status === 'success') {
        return result;
      } else {
        throw new Error(result.message || 'An error occurred while creating the account');
      }
    },
  });

export const useGetTwitchPreview = (searchTerm) =>
  useQuery({
    queryKey: ['get_twitch_games_preview', searchTerm],
    queryFn: async (params) => {
      const url = `${COVERAGE_API_ROOT}/p/free_user/preview?searchTerm=${encodeURIComponent(params.queryKey[1])}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    enabled: !!searchTerm && searchTerm.length > 2,
    retry: false,
    skip: true,
    refetchOnWindowFocus: true,
  });
