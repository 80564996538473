import React from 'react';

import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getReport } from '../../../selectors/reports';

const ReportDescription = ({ reportId }) => {
  const descriptionHTML = useSelector((state) => getReport(state, reportId)['descriptionHTML']);

  return (
    <div
      id="report-description"
      className="report-description"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(descriptionHTML) }}
    />
  );
};

ReportDescription.propTypes = {
  reportId: PropTypes.number,
};

export default ReportDescription;
