import React, { memo, useState } from 'react';

import { uniq } from 'lodash';
import { connect } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { changeNewReportForm } from '../../../actions/forms';
import { newReportSetSelectedLanguages } from '../../../actions/reports';
import MultiSelect from '../../../components/ICO/forms/MultiSelect';
import { getLanguagesValueAndLabel } from '../../../selectors/languages';
import { getArticles } from '../../../selectors/savedCoverage/articles';
import { getSavedStreams } from '../../../selectors/savedCoverage/savedStreams';
import { getSavedVideos } from '../../../selectors/savedCoverage/savedVideos';

const NewReportFilterLanguages = ({
  changeNewReportForm,
  newReportSetSelectedLanguages,
  articles,
  savedVideos,
  savedStreams,
  languages,
  selectedLanguages,
}) => {
  const [filteredLanguages, setFilteredLanguages] = useState([]);

  useDeepCompareEffect(() => {
    const articlesLanguages = articles?.map((article) => article?.language) ?? [];
    const videosLanguages = savedVideos?.map((video) => video?.channel_language?.toString().toLowerCase()) ?? [];
    const streamLanguages = savedStreams?.map((stream) => stream?.languages?.toString().toLowerCase()) ?? [];
    const reportLanguages = uniq([...articlesLanguages, ...videosLanguages, ...streamLanguages]);
    const languagesToSet = languages.filter((language) => reportLanguages?.some((rl) => language.value === rl));
    setFilteredLanguages(languagesToSet);

    newReportSetSelectedLanguages(languagesToSet);
  }, [savedVideos, newReportSetSelectedLanguages]);

  const handleChange = (selectedLanguage) => {
    const unselectedLanguages = filteredLanguages.filter(
      (language) => !selectedLanguage.some((selectedLang) => language.value === selectedLang.value),
    );

    changeNewReportForm({
      field: 'languages',
      value: unselectedLanguages,
    });

    newReportSetSelectedLanguages(selectedLanguage);
  };

  return (
    <MultiSelect
      aria-label="lang-select"
      items={filteredLanguages}
      value={selectedLanguages}
      placeholder="No languages"
      name="selected-language"
      onChange={handleChange}
    />
  );
};

const mapStateToProps = (state) => ({
  articles: Object.values(getArticles(state)),
  savedVideos: getSavedVideos(state),
  savedStreams: getSavedStreams(state),
  languages: getLanguagesValueAndLabel(state),
  selectedLanguages: state.reports.selectedLanguages,
});

export default memo(
  connect(mapStateToProps, { newReportSetSelectedLanguages, changeNewReportForm })(NewReportFilterLanguages),
);
