import React from 'react';

import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import reactStringReplace from 'react-string-replace';
import { CardHeader, ListGroup, ListGroupItem } from 'reactstrap';

const CardsSimpleListTable = ({ rows, template = '' }) => {
  const copyFormatted = (html) => {
    // Create container for the HTML
    // [1]
    var container = document.createElement('div');
    container.innerHTML = html;

    // Hide element
    // [2]
    container.style.position = 'fixed';
    container.style.pointerEvents = 'none';
    container.style.opacity = 0;

    // Detect all style sheets of the page
    var activeSheets = Array.prototype.slice.call(document.styleSheets).filter(function (sheet) {
      return !sheet.disabled;
    });

    // Mount the container to the DOM to make `contentWindow` available
    // [3]
    document.body.appendChild(container);

    // Copy to clipboard
    // [4]
    window.getSelection().removeAllRanges();

    var range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range);

    // [5.1]
    document.execCommand('copy');

    // [5.2]
    for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;

    // [5.3]
    document.execCommand('copy');

    // [5.4]
    for (var j = 0; j < activeSheets.length; j++) activeSheets[j].disabled = false;

    // Remove the container
    // [6]
    document.body.removeChild(container);
  };
  const renderTemplateRow = (row) =>
    reactStringReplace(renderTemplateRawText(row), '{{url}}', () => <a href={row.fullUrl}>{row.title}</a>);
  const renderTemplateRawText = (row) => {
    if (template) {
      return template
        .replaceAll('{{date}}', row.date || '')
        .replaceAll('{{domain}}', row.media || '')
        .replaceAll('{{full_url}}', row.fullUrl || '')
        .replaceAll('{{score}}', row.score || '')
        .replaceAll('{{tag_name}}', row.tag || '')
        .replaceAll('{{title}}', row.title || '')
        .replaceAll('{{website_visits}}', row.muv || '0');
    }
    return '';
  };
  const rowsItems = rows.map((row, index) => <ListGroupItem key={index}>{renderTemplateRow(row)}</ListGroupItem>);
  const rowsItemsCopy = rows.map((row) => (
    <>
      {renderTemplateRow(row)}
      <br />
    </>
  ));
  //const rowsItemsCopy = rows.map((row) => renderTemplateRawText(row).replaceAll("{{url}}", (<a href={row.url}>{row.title}</a>)));
  const onCopyToClipboard = () => {
    let link = ReactDOMServer.renderToStaticMarkup(rowsItemsCopy);
    copyFormatted(link);
  };

  return (
    <>
      <div onClick={onCopyToClipboard}>
        <CardHeader>
          <FontAwesomeIcon icon={faClipboard} />
          Copy for mail format
        </CardHeader>
      </div>
      <ListGroup>{rowsItems}</ListGroup>
    </>
  );
};

CardsSimpleListTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  template: PropTypes.string,
};

export default CardsSimpleListTable;
