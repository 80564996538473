import React, { memo, useCallback } from 'react';

import { connect } from 'react-redux';

import { reverseQueryToObject } from '../../../../../helpers/queryBuilderFormatter';
import translate from '../../../../../helpers/translate';
import { getOrganizationIsSlackEnabled, getOrganizationSlackChannels } from '../../../../../selectors/organizations';
import { getOrganizationPermissionsHasSlack } from '../../../../../selectors/permissions';
import QueryBuilderProjects from '../../../../Projects/QueryBuilderProjects/QueryBuilderProjects.jsx';
import AdvancedQueryInput from '../FeedConfiguration/AdvancedQueryInput.jsx';
import SlackTracking from '../FeedConfiguration/SlackTracking.jsx';

const ArticlesFeedModalsEditFeedBody = ({
  alertConfig,
  organizationHasSlack,
  organizationSlackEnabled,
  organizationSlackChannels,
  setAlertConfig,
  projectAlert,
}) => {
  const translatedKeyword = reverseQueryToObject(projectAlert?.keywords);

  const slackConfig = useCallback(() => {
    if (organizationHasSlack) {
      if (organizationSlackEnabled) {
        return (
          <SlackTracking
            setAlertConfig={setAlertConfig}
            alertConfig={alertConfig}
            organizationSlackChannels={organizationSlackChannels}
            slackChannelValue={organizationSlackChannels}
            projectAlert={projectAlert}
          />
        );
      }

      return <div>{translate('modals.alert.enable_slack')}</div>;
    }
  }, [
    alertConfig,
    organizationHasSlack,
    organizationSlackChannels,
    organizationSlackEnabled,
    setAlertConfig,
    projectAlert,
  ]);

  const onChange = useCallback(
    (value) => {
      setAlertConfig({ ...alertConfig, keywords: value });
    },
    [setAlertConfig, alertConfig],
  );

  return (
    <>
      <QueryBuilderProjects
        stateAction={onChange}
        currentInput={<AdvancedQueryInput stateAction={onChange} value={alertConfig?.keywords} />}
        translatedKeyword={translatedKeyword}
      />
      {slackConfig()}
    </>
  );
};

const mapStateToProps = (state) => ({
  organizationSlackChannels: getOrganizationSlackChannels(state),
  organizationHasSlack: getOrganizationPermissionsHasSlack(state),
  organizationSlackEnabled: getOrganizationIsSlackEnabled(state),
});

export default connect(mapStateToProps)(memo(ArticlesFeedModalsEditFeedBody));
