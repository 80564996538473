import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import { fetchTags } from '../../../actions/tags';
import { STATUS } from '../../../config/api-service';
import translate from '../../../helpers/translate';
import { InputSelect } from '../forms/InputSelect';

const ImportFeed = ({ formStatus, onChangeTag, form, tagOptions, toImportIds, fetchTags }) => {
  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  if (formStatus === STATUS.LOADING) {
    return (
      <div className="alert alert-warning col-md-12">
        <FontAwesomeIcon icon={'spinner2'} />
        {`${translate('importBulkModal.importing')} ${form.importIdsCount} ${translate('importBulkModal.articles')}`}
      </div>
    );
  }

  if (formStatus === STATUS.KO) {
    return (
      <>
        <Alert color="warning" className="col-md-12">
          <FontAwesomeIcon glyph={'folder-check'} />
          {`${translate('importBulkModal.error')} ${form.importIdsFail} ${translate(
            'importBulkModal.articles_not_moved',
          )}`}
        </Alert>
        {form.importIdsSuccess > 0 && (
          <Alert color="success" className="col-md-12">
            <FontAwesomeIcon glyph={'folder-check'} />
            {`${form.importIdsSuccess} ${translate('importBulkModal.articles_successfully_moved')}`}
          </Alert>
        )}
      </>
    );
  }

  if (formStatus === STATUS.SAVED) {
    return (
      <div className="alert alert-success col-md-12">
        <FontAwesomeIcon glyph={'folder-check'} />{' '}
        {`${form.importIdsSuccess} ${translate('importBulkModal.articles_successfully_moved')}`}
      </div>
    );
  }
  return (
    <div>
      Please select a tag that will be applied to the {toImportIds.length} moved articles. <br />
      The import may take some time.
      <InputSelect
        name={'tag-select'}
        onChange={onChangeTag}
        value={form.tagId !== -1 ? { value: form.tagId, label: form.tagLabel } : 0}
        items={tagOptions}
        placeholder={'Select or type new Tag...'}
        disabled={false}
      />
    </div>
  );
};

ImportFeed.propTypes = {
  formStatus: PropTypes.string,
  onChangeTag: PropTypes.func,
  form: PropTypes.shape({
    importIdsCount: PropTypes.number,
    importIdsFail: PropTypes.number,
    importIdsSuccess: PropTypes.number,
    tagLabel: PropTypes.string,
    tagId: PropTypes.string,
  }),
  tagOptions: PropTypes.string,
  toImportIds: PropTypes.string,
};

export default connect(null, { fetchTags })(ImportFeed);
