import values from 'lodash/values';
import { createSelector } from 'reselect';

import { STATUS } from '../config/api-service';
import { APP_ROOT, PUBLIC_PATH } from '../config/global-config';

export const getReports = (state) => state.reports.items;
export const getReportsStatus = (state) => state.reports.itemsStatus;
export const getReport = (state, reportId) => {
  const reports = getReports(state);
  return reports[reportId] ? reports[reportId] : {};
};
export const getReportPublicLink = (reportId) => APP_ROOT + PUBLIC_PATH + 'r/' + reportId;

export const getReportsTable = createSelector([getReports, getReportsStatus], (reports, reportsStatus) =>
  values(reports).filter((report) => {
    const id = report.id;
    return reportsStatus[id] === STATUS.OK;
  }),
);

export const getReportsTableListStatusIsLoading = (state) => Object.values(state.reports.listsStatus)[0] === 'LOADING';
export const getReportsHighlightedArticles = (state) => state.reports.highlightedArticles;

export const getHighlightedArticlesSortedByReach = (state, highlightedArticles) => {
  const articles = state?.articles?.articles?.items;

  if (!articles || !highlightedArticles) return [];

  return highlightedArticles
    .slice()
    .sort((a, b) => (articles[b.article_id]?.reach || 0) - (articles[a.article_id]?.reach || 0));
};

export const getReportVideosTotalItems = (state) =>
  state.reports.reportVideos?.response?.pagination?.total_entries ?? 0;
export const getReportVideosData = (state) => state.reports.reportVideos?.response?.videos;

export const getReportStreamsData = (state) => state.reports.reportStreams?.response?.streams;

export const getReportVideosIsLoading = (state) => state.reports.reportVideos?.isLoading;

export const getReportStreamsIsLoading = (state) => state.reports.reportStreams?.isLoading;

export const getReportStreamsPagination = (state) => {
  if (state.reports.reportStreams?.response?.pagination) {
    return {
      totalItems: state.reports.reportStreams.response.pagination.total_entries,
      lastPage: state.reports.reportStreams.response.pagination.last_page,
      page: state.reports.reportStreams.response.pagination.page_number,
    };
  }

  return [];
};

export const getReportVideosPagination = (state) => {
  if (state.reports.reportVideos?.response?.pagination) {
    return {
      totalItems: state.reports.reportVideos.response.pagination.total_entries,
      lastPage: state.reports.reportVideos.response.pagination.last_page,
      page: state.reports.reportVideos.response.pagination.page_number,
    };
  }

  return [];
};

export const getReportSelectedTags = (state) => state?.reports?.selectedTags ?? [];
export const getReportSelectedLanguages = (state) => state.reports.selectedLanguages ?? [];
