import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

import { useEditVideoContext } from './EditVideoContext';
import { InputSelect } from '../../../../components/ICO/forms/InputSelect';
import SimpleDatePicker from '../../../../components/ICO/forms/SimpleDatePicker';
import SingleSelect from '../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../helpers/translate';
import { getLanguagesValueAndLabel } from '../../../../selectors/languages';
import { getNotLockedTags } from '../../../../selectors/tags';

const SavedVideosModalsEditVideoBody = ({ tags, languages, videoData }) => {
  const {
    selectedTag,
    setSelectedTag,
    selectedLanguage,
    setSelectedLanguage,
    title,
    setNewTitle,
    description,
    setNewDescription,
    views,
    setNewViews,
    subscribers,
    setNewSubscribers,
    country,
    setNewCountry,
    publishedAt,
    setPublishedAt,
  } = useEditVideoContext();

  useEffect(
    () => () => {
      setNewDescription(null);
      setNewTitle(null);
      setNewViews(null);
      setNewSubscribers(null);
      setSelectedLanguage(null);
      setSelectedTag(null);
      setNewCountry(null);
      setPublishedAt(null);
    },
    [
      setSelectedLanguage,
      setSelectedTag,
      setNewSubscribers,
      setNewDescription,
      setNewViews,
      setNewTitle,
      setNewCountry,
      setPublishedAt,
    ],
  );

  const handleOnChangeLanguage = (selectedLanguage) => {
    setSelectedLanguage(selectedLanguage.value);
  };

  const selectedTagValue = selectedTag
    ? { value: selectedTag.value, label: selectedTag.label }
    : tags.filter((tag) => tag.value === videoData.tag_id.toString()) ?? null;

  const selectedLanguageValue = selectedLanguage ? selectedLanguage : videoData.channel_language ?? null;

  const sanitizedText = () => {
    const parser = new DOMParser();
    return parser.parseFromString(`<!doctype html><body>${videoData.title}`, 'text/html').body.textContent;
  };

  return (
    <>
      <Row>
        <Col>
          <Label for="tag-select">{translate('common.tag')}</Label>
          <InputSelect
            id="tag-select"
            name="tag-select"
            items={tags}
            placeholder="Select a tag"
            disabled={false}
            value={selectedTagValue}
            onChange={(tag) => setSelectedTag(tag)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <FormGroup>
              <Label for="titleInput">{translate('pages.saved_videos.table.edit_video.form_labels.title')}</Label>
              <Input
                id="titleInput"
                placeholder={sanitizedText()}
                onChange={(e) => setNewTitle(e.target.value)}
                value={title}
              />
            </FormGroup>
            <FormGroup>
              <Label for="descriptionTextArea">
                {translate('pages.saved_videos.table.edit_video.form_labels.description')}
              </Label>
              <Input
                type="textarea"
                id="descriptionTextArea"
                placeholder={videoData.description}
                onChange={(e) => setNewDescription(e.target.value)}
                value={description}
              />
            </FormGroup>
            <FormGroup row title="Stats">
              <Col xs={4}>
                <Label for="views">{translate('pages.saved_videos.table.edit_video.form_labels.views')}</Label>
                <Input
                  id="views"
                  placeholder={videoData.views}
                  onChange={(e) => setNewViews(e.target.value)}
                  value={views}
                  type="number"
                />
              </Col>
              <Col xs={4}>
                <Label for="subscribers">
                  {translate('pages.saved_videos.table.edit_video.form_labels.subscribers')}
                </Label>
                <Input
                  id="subscribers"
                  placeholder={videoData.channel_subscribers}
                  onChange={(e) => setNewSubscribers(e.target.value)}
                  value={subscribers}
                  type="number"
                />
              </Col>
              <Col xs={4}>
                <Label id="publisheatAt">
                  {translate('pages.saved_videos.table.edit_video.form_labels.published_at')}
                </Label>
                <SimpleDatePicker value={publishedAt} onChange={(e) => setPublishedAt(e)} id="publishedAt" />
              </Col>
            </FormGroup>
            <FormGroup row title="Stats">
              <Col xs={6}>
                <Label for="languages">{translate('pages.saved_videos.table.edit_video.form_labels.language')}</Label>
                <SingleSelect
                  id="languages"
                  name="languages"
                  items={languages}
                  value={selectedLanguageValue ?? []}
                  onChange={handleOnChangeLanguage}
                  className="sm-select"
                  placeholder={translate('pages.saved_videos.table.filters.all_languages')}
                  aria-label="single-select"
                />
              </Col>
              <Col xs={6}>
                <Label id="country">{translate('pages.saved_videos.table.edit_video.form_labels.country')}</Label>
                <Input
                  id="country"
                  placeholder={videoData.channel_country}
                  value={country}
                  onChange={(e) => setNewCountry(e.target.value)}
                />
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  tags: getNotLockedTags(state),
  languages: getLanguagesValueAndLabel(state),
});

export default connect(mapStateToProps, null)(SavedVideosModalsEditVideoBody);
