import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import NewProjectModalBody from './NewProjectModalBody.jsx';
import { saveAvatar } from '../../../actions/files';
import { changeProjectForm } from '../../../actions/forms';
import translate from '../../../helpers/translate';
import { getClients } from '../../../selectors/clients';
import { getNewProjectForm } from '../../../selectors/forms';
import useCreateUpdateProject from '../hooks/useCreateUpdateProject';

const NewProjectModal = ({ isOpen, onClose, clients, changeProjectForm, formFields, saveAvatar }) => {
  const mutation = useCreateUpdateProject({ method: 'POST', onClose });
  const [projectName, setProjectName] = useState(null);
  const [client, setClient] = useState(null);
  const [avatar, setAvatar] = useState(null);

  const isSaveButtonDisabled = !projectName?.length || !client;

  const handleClickSave = () => {
    const data = {
      data: {
        type: 'project',
        attributes: {
          name: projectName,
          avatar: avatar && avatar.length ? avatar[0].name : '',
        },
        relationships: {
          client: {
            data: {
              type: 'client',
              id: client.value,
            },
          },
        },
      },
    };

    mutation.mutate(data);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} onClosed={onClose} className="fp-modal">
      <ModalHeader className="fp-modal-header--small">
        <Row>
          <Col md={12}>
            <h5 className="text-bold">{translate('pages.projects.modals.new_project.title')}</h5>
          </Col>
        </Row>
      </ModalHeader>
      <Form onSubmit={(e) => e.preventDefault()}>
        <ModalBody className="fp-modal-body">
          <NewProjectModalBody
            setProjectName={setProjectName}
            setAvatar={setAvatar}
            setClient={setClient}
            clients={clients}
            changeProjectForm={changeProjectForm}
            formFields={formFields}
            saveAvatar={saveAvatar}
            avatar={avatar}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={onClose} className="fp-button-close">
            {translate('common.close')}
          </Button>
          <Button size="sm" type="submit" onClick={handleClickSave} color="primary" disabled={isSaveButtonDisabled}>
            {translate('common.save')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  clients: getClients(state),
  formFields: getNewProjectForm(state),
});

export default connect(mapStateToProps, {
  changeProjectForm,
  saveAvatar,
})(NewProjectModal);
