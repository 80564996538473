import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ModalFooterSaveClose = ({ handleOnClose, onSave, isDisabled }) => (
  <>
    <Button aria-label="close-button" size="sm" onClick={handleOnClose} color="secondary" className="fp-button">
      Close
    </Button>
    <Button aria-label="save-button" size="sm" type="submit" onClick={onSave} color="primary" disabled={isDisabled}>
      Save
    </Button>
  </>
);

ModalFooterSaveClose.propTypes = {
  handleOnClose: PropTypes.func,
  onSave: PropTypes.func,
  isDisabled: PropTypes.bool,
};

ModalFooterSaveClose.defaultProps = {
  handleOnClose: () => {},
  onSave: () => {},
  isDisabled: true,
};

export default ModalFooterSaveClose;
