import React from 'react';

import { connect } from 'react-redux';

import { fetchProjects } from '../../actions/projects';
import ReportTableActions from '../../components/ICO/actions/ReportTableActions';
import { reportsTableColumns } from '../../components/ICO/tables/helpers/columns';
import StaticDataTable from '../../components/ICO/tables/StaticDataTable';
import { getCurrentProjectId } from '../../selectors/forms';
import {
  getOrganizationPermissionsHasReports,
  getOrganizationPermissionsMaxReports,
  getOrganizationPermissionsHasYoutubeFeed,
} from '../../selectors/permissions';
import { getProject } from '../../selectors/projects';
import { getReportsTable, getReportsTableListStatusIsLoading } from '../../selectors/reports';

const mapStateToProps = (state, ownProps) => {
  const listStatusLoading = getReportsTableListStatusIsLoading(state);
  const currentProjectId = getCurrentProjectId(state);
  const userHasReports = getOrganizationPermissionsHasReports(state);
  const userMaxReports = getOrganizationPermissionsMaxReports(state);
  const activeReports = getReportsTable(state).filter((report) => report.project_id === currentProjectId).length;
  const userHasYoutubeFeed = getOrganizationPermissionsHasYoutubeFeed(state);

  let isNewButtonDisabled = !userHasReports;

  if (userHasReports && userMaxReports) {
    isNewButtonDisabled = activeReports >= userMaxReports;
  }

  const reports = getReportsTable(state)
    .map((report) => ({
      ...report,
      actions: <ReportTableActions report={report} />,
      project: getProject(state, report.project_id),
    }))
    .filter((report) => report.project.id === currentProjectId);

  return {
    columns: reportsTableColumns(userHasYoutubeFeed),
    rows: reports,
    ownProps: ownProps,
    isTableLoading: listStatusLoading && !reports.length,
    newReport: true,
    currentProjectId: currentProjectId,
    isNewButtonDisabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    dispatch(fetchProjects());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StaticDataTable);
