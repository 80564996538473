import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import CirclesBackground from '../../components/ICO/CirclesBackground/CirclesBackground';
import { IS_MOBILE } from '../../constants';
import useDeviceScreen from '../../hooks/useDeviceScreen';
import '../../assets/scss/theme/Footprints/screens/_welcome.scss';

const Welcome = () => {
  const deviceScreen = useDeviceScreen();
  const { loginWithRedirect } = useAuth0();

  const logo =
    deviceScreen.screenWidth <= IS_MOBILE
      ? 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Web-Footprints-Logo-Mobile-DarkTheme.svg'
      : 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Web-Footprints-Logo-Strapline-DarkTheme.svg';
  const logoWidth = deviceScreen.screenWidth <= IS_MOBILE ? 200 : 300;

  const handleClick = () => {
    loginWithRedirect();
  };

  return (
    <CirclesBackground>
      <Row noGutters className="min-vh-100 flex-center">
        <Col lg={3} md={8} xs={12} className="d-flex align-items-center flex-column">
          <Row lg={12} md={8} xs={12} className="mb-7">
            <img src={logo} width={logoWidth} alt="Footprints Logo" />
          </Row>
          <Row lg={12}>
            <Link to="/">
              <button
                aria-label="login-button"
                className="btn btn-primary welcome__loginButton text-size-medium-small"
                onClick={handleClick}
              >
                Log in
              </button>
            </Link>
          </Row>
        </Col>
        <Row lg={3} className="mb-5 fixed-bottom flex-center">
          <Col lg={3} md={8} xs={12} className="d-flex align-items-center flex-column">
            <Row>
              <span className="text-uppercase welcome__footer__alpha">Alpha</span>
            </Row>
            <Row>
              <span className="welcome__footer__bugs">May contain bugs</span>
            </Row>
          </Col>
        </Row>
      </Row>
    </CirclesBackground>
  );
};

export default Welcome;
