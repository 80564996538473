import React, { useState } from 'react';

import { Button, Col, Row } from 'reactstrap';

import { langFormatter, videosViewFormatter } from '../../../../../components/ICO/tables/helpers/formatters';
import translate from '../../../../../helpers/translate';
import TwitchFeedModalsSaveStreams from '../../../Modals/SaveStreams/TwitchFeedModalsSaveStreams.jsx';

const TwitchFeedTableVideoPreviewModalFooter = ({ videoData, onClose, withSave }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const closeButtonStyle = withSave ? 'fp-button-close mr-3' : 'fp-button-close';

  return (
    <Row className="d-flex flex-row w-100 justify-content-around">
      <Col xs={3} className="d-flex flex-row">
        {videoData?.video?.channel?.followers > 0 ? (
          <span className="fs-0">{videosViewFormatter(videoData.video.channel.followers)}</span>
        ) : null}
        <span className="ml-2 fs-0">{langFormatter(videoData.video.language)}</span>
      </Col>
      <Col xs={9} className="justify-content-end d-flex">
        <Button size="sm" onClick={onClose} className={closeButtonStyle}>
          {translate('common.close')}
        </Button>
        {withSave ? (
          <Button size="sm" onClick={() => setModalOpen(true)} className="fp-button" color="primary">
            {translate('common.save')}
          </Button>
        ) : null}
      </Col>
      <TwitchFeedModalsSaveStreams
        selectedRows={[videoData]}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Row>
  );
};

export default TwitchFeedTableVideoPreviewModalFooter;
