import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';

import SuperSearchFilters from './SuperSearchFilters/SuperSearchFilters';
import SuperSearchVolumeChart from './SuperSearchVolumeChart.jsx';
import SuperSearchTable from './Table/SuperSearchTable.jsx';
import { fetchLanguages } from '../../actions/languages';
import { superSearchClear } from '../../actions/superSearch';
import { fetchTags } from '../../actions/tags';
import getToastedNotification from '../../components/ICO/helpers/toaster.helper';
import Loading from '../../components/ICO/Loading/Loading';
import translate from '../../helpers/translate';
import { getCurrentProjectId } from '../../selectors/forms';
import {
  getSuperSearchVolumeResponse,
  getSuperSearchIsLoading,
  getSuperSearchError,
  getSuperSearchData,
} from '../../selectors/superSearch';
import '../../assets/scss/theme/Footprints/screens/_supersearch.scss';

const SuperSearch = ({
  data,
  fetchLanguages,
  superSearchClear,
  isLoading,
  volumeData,
  error,
  fetchTags,
  projectId,
}) => {
  useEffect(() => {
    fetchLanguages();
    fetchTags(projectId);
  }, [fetchLanguages, projectId, fetchTags]);

  useEffect(
    () => () => {
      superSearchClear();
    },
    [superSearchClear],
  );

  useEffect(() => {
    if (error) {
      getToastedNotification({ responseStatusCode: error });
    }
  }, [error]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card className="mt-3 mb-3 fp-supersearch">
            <CardHeader>
              <h3>{translate('pages.supersearch.title')}</h3>
            </CardHeader>
            <CardBody>
              <SuperSearchFilters />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isLoading && <Loading isTable={false} pageSize={25} />}
      {volumeData && volumeData.length ? <SuperSearchVolumeChart /> : null}
      {data && data.length ? <SuperSearchTable /> : null}
    </>
  );
};

SuperSearch.propTypes = {
  superSearchClear: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  error: PropTypes.number,
  volumeData: PropTypes.arrayOf(PropTypes.number),
  status: PropTypes.string,
};

const mapStateToProps = (state) => ({
  data: getSuperSearchData(state),
  isLoading: getSuperSearchIsLoading(state),
  volumeData: getSuperSearchVolumeResponse(state),
  error: getSuperSearchError(state),
  projectId: getCurrentProjectId(state),
});

export default connect(mapStateToProps, { superSearchClear, fetchLanguages, fetchTags })(SuperSearch);
