import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { useMutation, useQuery } from 'react-query';

import getToastedNotification from '../../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../../config/global-config';
import { getToken } from '../../../../helpers/getToken';

const keys = {
  save_alert: 'save_alert',
  delete_article: 'delete_article',
  export_feed: 'export_feed',
  save_articles: 'save_articles',
  edit_alert: 'edit_alert',
};

export const useSaveArticles = (onSaveSuccess) =>
  useMutation({
    mutationKey: keys.save_articles,
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/bulk/articles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const { status } = response;
        if (status === 422) {
          //eslint-disable-next-line
          throw new Error("Articles couldn't be saved - They already exist on Saved Coverage");
        } else {
          throw new Error('Something went wrong');
        }
      } else {
        return await response.json();
      }
    },

    onSuccess: (result) => {
      const { attributes } = result.data;
      if (attributes.failed) {
        getToastedNotification({
          customMessage: {
            type: 'warning',
            message: `${attributes.success} article(s) saved.\n ${attributes.failed} couldn't be saved as they already exist on Saved Coverage.`,
          },
        });
      } else {
        getToastedNotification({
          customMessage: {
            type: 'success',
            message: `${attributes.success} article(s) saved.`,
          },
        });
      }

      onSaveSuccess();
    },
    onError: (error) => {
      const customMessage = {
        type: 'error',
        message: error.message,
      };
      getToastedNotification({ customMessage });
    },
  });

export const useExportArticles = (url, currentProject) =>
  useQuery({
    queryKey: 'export_feed',
    enabled: false,
    queryFn: async () => {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/csv',
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      }
      return await response.text();
    },
    onSuccess: async (response) => {
      const customMessage = {
        type: 'success',
        message: 'Your export is ready. Downloading...',
      };
      getToastedNotification({ customMessage });
      fileDownload(response, `${currentProject.name}_${dayjs().format('YYYY-MM-DD')}.csv`);
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: 'There was an issue generating the CSV. Please, try again or contact support',
      };
      getToastedNotification({ customMessage });
    },
  });

export const useDeleteArticles = (onDeleteSuccess) =>
  useMutation({
    mutationKey: keys.delete_article,
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/bulk/articles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: (result) => {
      const { attributes } = result.data;

      if (attributes.failed) {
        getToastedNotification({
          customMessage: {
            type: 'warning',
            message: `${attributes.success} article(s) deleted.\n ${attributes.failed} couldn't be deleted.`,
          },
        });
      } else {
        getToastedNotification({
          customMessage: {
            type: 'success',
            message: `${attributes.success} article(s) deleted.`,
          },
        });
      }
      onDeleteSuccess();
    },
    onError: (error) => {
      const customMessage = {
        type: 'error',
        message: error.message,
      };
      getToastedNotification({ customMessage });
    },
  });

export const useSaveArticlesFeedAlert = (onSuccess) =>
  useMutation({
    mutationKey: keys.save_alert,
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/alerts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: 'Feed created correctly!',
      };

      getToastedNotification({ customMessage });
      onSuccess();
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: 'There was an error creating the feed. Please, try again or contact support',
      };

      getToastedNotification({ customMessage });
    },
  });

export const useEditArticlesFeedAlert = (onSuccess) =>
  useMutation({
    mutationKey: keys.edit_alert,
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/alerts/${data.alert_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: 'Your feed has been updated correctly!',
      };

      getToastedNotification({ customMessage });
      onSuccess();
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: 'There was an error updating the feed. Please, try again or contact support',
      };

      getToastedNotification({ customMessage });
    },
  });
