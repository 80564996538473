import { API_CALL, MEDIATYPES_FETCH_MEDIATYPES } from './actionTypes';

export const fetchMediaTypes = (page = 0, page_size = 150) => ({
  type: API_CALL,
  meta: {
    type: MEDIATYPES_FETCH_MEDIATYPES,
    endpoint: `mediatypes?page[page]=${page}&page[page_size]=${page_size}`,
    method: 'GET',
  },
});
