const TIER_MAP = {
  1: 'Admin',
  2: 'User',
  3: 'Free',
  4: 'Starter',
  5: 'Pro',
  6: 'Agency',
};

const getUserTierName = (tier_id = 3) => TIER_MAP[tier_id];
export default getUserTierName;
