import React, { memo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import LoadingCallback from '../auth/LoadingCallback';
import LoginCallback from '../auth/LoginCallback';
import Logo from '../components/ICO/Logo/Logo';
import getRandomGamingFact from '../helpers/getRandomGamingFact';
import translate from '../helpers/translate';

const LoadingLayout = (params) => {
  const { isAuthenticated } = useAuth0();
  return (
    <Row noGutters className="min-vh-100 flex-center">
      <Col lg={3} md={8} xs={12} className="d-flex align-items-center flex-column">
        <Logo />
      </Col>
      <Switch>
        <Route path={'/loading/callback'} component={LoginCallback} />
        <Route path={'/*'} component={LoadingCallback} isAuthenticated={isAuthenticated} params={params} />
      </Switch>
      <Row noGutters className="mb-5 fixed-bottom flex-center">
        <Col xs={8} className="d-flex align-items-center flex-column">
          <Row>
            <p className="text-center text-warning mt-0 mb-0">{translate('common.fact')}</p>
          </Row>
          <Row>
            <p className="text-center text-white mt-0 mb-0">{getRandomGamingFact()}</p>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default memo(LoadingLayout);
