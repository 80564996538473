import React, { useEffect } from 'react';

import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';

import YoutubeFeedKeywordModalBodyNotifications from './YoutubeFeedKeywordModalBodyNotifications.jsx';
import YoutubeFeedKeywordModalBodyPreview from './YoutubeFeedKeywordModalBodyPreview.jsx';
import translate from '../../../../helpers/translate';
import { getCurrentProjectId } from '../../../../selectors/forms';
import { getProject } from '../../../../selectors/projects';
import usePreview from '../../hooks/useGetPreview';

const YoutubeFeedKeywordModalBody = ({ keyword, setKeyword }) => {
  const mutation = usePreview();

  useEffect(
    () => () => {
      setKeyword(null);
    },
    [setKeyword],
  );

  const onChange = (value) => {
    setKeyword(value);
    mutation.mutate(value);
  };

  const debounceInput = debounce(onChange, 700);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup>
              <Label for="keywordInput">{translate('pages.youtube_feed.keyword_input.label')}</Label>
              <Input
                placeholder={translate('pages.youtube_feed.keyword_input.placeholder')}
                name="keywordInput"
                onChange={(event) => debounceInput(event.target.value)}
                invalid={keyword && keyword.length < 3}
              />
              <FormFeedback>{translate('pages.youtube_feed.keyword_input.feedback')}</FormFeedback>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <YoutubeFeedKeywordModalBodyNotifications />
      {mutation.isError ? 'Something went wrong with your preview. Please try again or contact support.' : null}
      {mutation.isLoading ? (
        <div className="d-flex flex-center">
          <Spinner />
        </div>
      ) : null}
      {mutation?.data ? (
        <>
          <Row className="d-flex flex-column mb-3">
            <Col>
              <span className="text-gray"> Here&apos;s what you can expect from your search term.</span>
            </Col>
          </Row>
          <YoutubeFeedKeywordModalBodyPreview previewData={mutation.data} />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const currentProjectId = getCurrentProjectId(state);
  return {
    project: getProject(state, currentProjectId),
  };
};

export default connect(mapStateToProps, null)(YoutubeFeedKeywordModalBody);
