import { CHANGE_REPORT_NAME, SET_REPORT_DATA } from '../../actions/reports';

const initialState = {
  'date-end': null,
  'date-start': null,
  descriptionHTML: null,
  id: null,
  'inserted-at': null,
  name: null,
  project_id: null,
  'section-subtitle-1': null,
  'section-subtitle-2': null,
  'section-title-1': null,
  'section-title-2': null,
  status: 0,
  'table-template': null,
  'table-type': null,
  tags_id: null,
  'total-articles': null,
  'total-reach': null,
};

const renameReport = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_DATA:
      return action.payload;
    case CHANGE_REPORT_NAME:
      return { ...state, name: action.payload };
    default:
      return state;
  }
};

export default renameReport;
