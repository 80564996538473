import React, { useState } from 'react';

import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import ArticlesFeedModalsPreviewBody from './ArticlesFeedModalsPreviewBody.jsx';
import translate from '../../../../../helpers/translate';
import ArticlesFeedSaveArticleModal from '../SaveArticle/ArticlesFeedSaveArticleModal.jsx';

const ArticlesFeedModalsPreview = ({ isOpen, onClose, articleData, withSave = false }) => {
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const closeButtonStyle = withSave ? 'fp-button-close mr-3' : 'fp-button-close';

  return (
    <>
      <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
        <ModalHeader className="fp-modal-header d-flex align-items-center">{articleData.title}</ModalHeader>
        <ModalBody className="fp-modal-body">
          <ArticlesFeedModalsPreviewBody articleData={articleData} />
        </ModalBody>
        <ModalFooter>
          <Row className="d-flex flex-row w-100 d-flex ">
            <Col xs={12} className="justify-content-end d-flex">
              <Button size="sm" onClick={onClose} className={closeButtonStyle}>
                {translate('common.close')}
              </Button>
              <Button
                size="sm"
                onClick={() => window.open(articleData.url, '_blank')}
                className="fp-button-prev-button mr-3"
              >
                Open
              </Button>
              <Button size="sm" onClick={() => setSaveModalOpen(true)} color="primary">
                {translate('common.save')}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
      <ArticlesFeedSaveArticleModal
        onClose={() => setSaveModalOpen(false)}
        isOpen={isSaveModalOpen}
        selectedRows={[articleData]}
      />
    </>
  );
};

export default ArticlesFeedModalsPreview;
