import React, { useEffect } from 'react';
import UpgradeSubscription from '../ICO/modals/UpgradeSubscription/UpgradeSubscription';
import { connect } from 'react-redux';
import { fetchProducts } from '../../actions/products';
import { getProducts } from '../../selectors/products';
import { getUsersCurrentUserIsAdmin } from '../../selectors/users';

const ErrorEndOfTrial = ({ userIsAdmin, fetchProducts, products }) => {
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  if (products) {
    if (userIsAdmin) {
      return <UpgradeSubscription isOpen={true} isEndOfTrial />;
    }
    return <UpgradeSubscription isOpen={true} buttonsDisabled={true} isEndOfTrial />;
  }

  return <></>;
};

const mapStateToProps = state => ({
  products: getProducts(state),
  userIsAdmin: getUsersCurrentUserIsAdmin(state)
});

export default connect(
  mapStateToProps,
  { fetchProducts }
)(ErrorEndOfTrial);
