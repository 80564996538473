import React, { useState } from 'react';

import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';

import { fetchArticlePreview } from '../../../actions/articles';
import { showModal } from '../../../actions/forms';
import { getDisplayTitleValue } from '../tables/helpers/formatters';

const ArticleTableTitle = ({ article, onClick, source }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTwitter, setIsOpenTwitter] = useState(false);
  if (source === 1) {
    return (
      <>
        <div className="cursor-pointer" id={'art-tw-' + article.id} onClick={onClick}>
          <FontAwesomeIcon icon={faTwitter} transform="shrink-4 down-1" className="mr-1" />
        </div>
        <div className="cursor-pointer" id={'art-' + article.id} onClick={onClick}>
          {getDisplayTitleValue(article)}
        </div>
        <Tooltip
          delay={{
            show: 0,
            hide: 0,
          }}
          placement="top"
          isOpen={isOpenTwitter}
          target={'art-tw-' + article.id}
          toggle={() => setIsOpenTwitter(!isOpenTwitter)}
        >
          {'This article was fetched from this media\'s Twitter account.'}
        </Tooltip>
        <Tooltip
          delay={{
            show: 0,
            hide: 0,
          }}
          placement="top"
          isOpen={isOpen}
          target={'art-' + article.id}
          toggle={() => setIsOpen(!isOpen)}
        >
          {article.url.length > 75 ? `${article.url.substr(0, 75)}...` : article.url}
        </Tooltip>
      </>
    );
  }
  return (
    <>
      <div className="cursor-pointer" id={'art-' + article.id} onClick={onClick}>
        {getDisplayTitleValue(article)}
      </div>
      <Tooltip
        delay={{
          show: 0,
          hide: 0,
        }}
        placement="top"
        isOpen={isOpen}
        target={'art-' + article.id}
        toggle={() => setIsOpen(!isOpen)}
      >
        {article.url.length > 75 ? `${article.url.substr(0, 75)}...` : article.url}
      </Tooltip>
    </>
  );
};

const mapStateToProps = (state) => ({
  state,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onClick: () => {
    const { dispatch } = dispatchProps;
    if (!ownProps.isFeed) {
      dispatch(fetchArticlePreview({ url: ownProps.article.fullUrl, id: ownProps.article.id })).then(() => {
        dispatch(
          showModal({
            content: 'previewArticle',
            contentId: ownProps.article.id,
            contentData: { isFeed: ownProps.isFeed, ...ownProps.article },
          }),
        );
      });
    } else {
      dispatch(
        showModal({
          content: 'previewArticle',
          contentId: ownProps.article.id,
          contentData: { ...ownProps.article, isFeed: ownProps.isFeed },
        }),
      );
    }
  },
});

ArticleTableTitle.propTypes = {
  article: PropTypes.shape({ id: PropTypes.string, url: PropTypes.string }),
  onClick: PropTypes.func,
  source: PropTypes.number,
};

export default connect(mapStateToProps, null, mergeProps)(ArticleTableTitle);
