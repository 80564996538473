import { RECEIVE_SAVE_AVATAR } from '../actions/files';
import { STATUS_ERROR, STATUS_LOADED, STATUS_LOADING } from '../actions/forms';
import {
  RECEIVE_INVITE,
  RECEIVE_POST_INVITE,
  RECEIVE_POST_USER,
  RECEIVE_USER_LOGIN,
  RECEIVE_USER_LOGOUT,
  RECEIVE_USER_PROFILE,
  REJECT_INVITE,
  REJECT_POST_INVITE,
  REJECT_POST_USER,
  REJECT_USER_LOGIN,
  REQUEST_INVITE,
  REQUEST_POST_INVITE,
  REQUEST_POST_USER,
  REQUEST_USER_LOGIN,
  REQUEST_USER_LOGOUT,
  REQUEST_USER_PROFILE,
  REQUEST_USER_DEFAULT_PROJECT,
  RECEIVE_USER_DEFAULT_PROJECT,
  REJECT_USER_DEFAULT_PROJECT,
} from '../actions/users';
import { apiRequestOne, STATUS } from '../config/api-service';

export const displayChangesetError = (errors) =>
  Object.keys(errors)
    .map((errorKey) => {
      const message = errors[errorKey];
      return errorKey + ': ' + message;
    })
    .join('<br />');

const initialState = {
  user: {},
  profile: {},
  invite: {},
  signup: {},
  isLoggedIn: false,
  isFetching: false,
  didInvalidate: false,
  lastUpdated: null,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SAVE_AVATAR:
      return {
        ...state,
        signup: {
          ...state.signup,
          picture: action.payload.items.url.fullUrl,
        },
      };
    case REQUEST_POST_USER:
      return {
        ...state,
        signup: {
          status: STATUS_LOADING,
        },
      };
    case RECEIVE_POST_USER:
      return {
        ...state,
        signup: {
          status: STATUS_LOADED,
        },
      };
    case REJECT_POST_USER:
      return {
        ...state,
        signup: {
          status: STATUS_ERROR,
          errorCode: action.payload.status,
          error: action.payload.response.errors.detail || displayChangesetError(action.payload.response.errors),
        },
      };
    case REQUEST_INVITE:
      return {
        ...state,
        invite: {
          status: STATUS_LOADING,
        },
      };
    case RECEIVE_INVITE: {
      let itemsRec = action.payload.items;
      const inviteRes = itemsRec[Object.keys(itemsRec)[0]];
      return {
        ...state,
        invite: {
          status: STATUS_LOADED,
          organization: inviteRes.organization,
          key: inviteRes['invite-key'],
          expires: inviteRes.expires,
        },
      };
    }
    case REJECT_INVITE:
      return {
        ...state,
        invite: {
          status: STATUS.KO,
          error: action.payload.error,
        },
      };

    case REQUEST_POST_INVITE:
      return {
        ...state,
        invite: {
          status: STATUS.LOADING,
        },
      };
    case RECEIVE_POST_INVITE: {
      let items = action.payload.items;
      let inviteResPost = items[Object.keys(items)[0]];
      return {
        ...state,
        invite: {
          status: STATUS.SAVED,
          organization: inviteResPost.organization,
          key: inviteResPost['invite-key'],
          expires: inviteResPost.expires,
        },
      };
    }
    case REJECT_POST_INVITE:
      return {
        ...state,
        invite: {
          status: STATUS_ERROR,
          error: action.payload.error,
        },
      };
    case REQUEST_USER_LOGOUT:
      return Object.assign(
        { ...state },
        {
          user: {},
          profile: {},
          isLoggedIn: false,
        },
      );
    case RECEIVE_USER_LOGOUT:
      return {
        ...state,
        user: {
          name: '',
          avatar: '',
          accessToken: false,
        },
        profile: {},
        isLoggedIn: false,
      };
    case REQUEST_USER_LOGIN:
      return Object.assign(
        { ...state },
        {
          isFetching: true,
        },
      );
    case REJECT_USER_LOGIN:
      return Object.assign(
        { ...state },
        {
          isFetching: false,
        },
      );
    case RECEIVE_USER_LOGIN:
      return Object.assign(
        { ...state },
        {
          user: action.user,
          isLoggedIn: true,
          isFetching: false,
          lastUpdated: Date.now(),
        },
      );
    case REQUEST_USER_DEFAULT_PROJECT:
    case REQUEST_USER_PROFILE:
      return apiRequestOne(state, action);
    case RECEIVE_USER_PROFILE:
      return { ...state, user: { ...state.user, ...Object.values(action.payload.items)[0] }, userStatus: STATUS.OK };
    case RECEIVE_USER_DEFAULT_PROJECT: {
      return {
        ...state,
        user: { ...state.user, 'default-project-id': Object.values(action.payload.items)[0]['default-project-id'] },
      };
    }

    case REJECT_USER_DEFAULT_PROJECT:
      return { ...state, userStatus: STATUS.KO };
    default:
      return state;
  }
};

export default users;
