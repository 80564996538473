import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

class Module extends Component {
  componentDidMount() {
    if (this.props.init) {
      this.props.init();
    }
  }
  renderStyle(height = 0) {
    if (height > 0) {
      return {
        height: height + 'px',
        overflow: 'hidden',
      };
    } else return {};
  }
  panelClass() {
    let res = 'panel';
    if (!this.props.title) {
      res += ' panel-body ';
    }
    if (this.props.bgClass) {
      res += ' ' + this.props.bgClass;
    }
    return res;
  }
  render() {
    const panelClass = this.panelClass();
    const panelStyle = this.renderStyle(this.props.height);
    //Two possible displays, depending on header or not
    if (this.props.title) {
      return (
        <Col md={this.props.md} xs={this.props.xs}>
          <div className={panelClass} key={this.props.id} style={panelStyle}>
            <div className="panel-heading">
              <h5 className="panel-title">{this.props.title}</h5>
            </div>
            <div className="panel-body">{this.props.children}</div>
          </div>
        </Col>
      );
    } else {
      return (
        <Col md={this.props.md} xs={this.props.xs}>
          <div className={panelClass} key={this.props.id} style={panelStyle}>
            {this.props.children}
          </div>
        </Col>
      );
    }
  }
}

Module.propTypes = {
  init: PropTypes.func,
  xs: PropTypes.number, //Boostrap grid
  md: PropTypes.number, //Boostrap grid
  height: PropTypes.number, //Height in pixels
  header: PropTypes.bool, //Display the header or not
  title: PropTypes.string,
  bgClass: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.number,
};

export default Module;
