import { ADMIN_ROLE_ID } from '../constants';

export const getUsersCurrentUser = (state) => state.users.user;
export const getUsersUserStatus = (state) => state.users.userStatus;
export const getOrganizationUsersList = (state) => state.organizations.currentOrg.users;
export const getUsersCurrentUserIsAdmin = (state) => getUsersCurrentUser(state)['role-id'] === ADMIN_ROLE_ID;
export const getUsersCurrentUserAvatar = (state) => getUsersCurrentUser(state).avatar;
export const getUsersCurrentUserId = (state) => getUsersCurrentUser(state).id;
export const getUsersCurrentUserDefaultProjectId = (state) => getUsersCurrentUser(state)['default-project-id'];
export const getUsersCurrentUserAvatarFiltered = (state) => {
  const splitString = '/users/';
  const avatar = getUsersCurrentUser(state).avatar;
  if (avatar) {
    return avatar.split(splitString)[1];
  }
};

export const getUsersCurrentUserTierId = (state) => getUsersCurrentUser(state)['tier-id'];
export const getUserOrganizationCustomerId = (state) => {
  const stripeCustomerId = getUsersCurrentUser(state)['organization']?.stripe_customer_id;

  if (!stripeCustomerId) {
    return null;
  } else {
    return stripeCustomerId;
  }
};
export const getSignupResultUser = (state) => state.users.signup;
