import { RECEIVE_SAVE_FEED, REJECT_SAVE_FEED } from '../../actions/feeds';
import {
  WEBSITES_ADD_WEBSITE_DOMAIN,
  WEBSITES_ADD_WEBSITE_FAILED,
  WEBSITES_ADD_WEBSITE_FEED,
  WEBSITES_ADD_WEBSITE_LANGUAGE,
  WEBSITES_ADD_WEBSITE_REACH,
  WEBSITES_ADD_WEBSITE_RECEIVED,
  WEBSITES_ADD_WEBSITE,
  WEBSITES_CLOSE_MODAL,
  WEBSITES_OPEN_MODAL,
} from '../../actions/websites';
import { getIsValidDomain } from '../../helpers/getIsValidDomain';

const initialState = {
  domain: null,
  feed: null,
  reach: null,
  language: null,
  isOpen: false,
  websiteStatus: null,
  isDomainValid: null,
  isFeedValid: null,
  data: [],
  feedStatus: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WEBSITES_ADD_WEBSITE_DOMAIN:
      return { ...state, domain: action.payload, isDomainValid: getIsValidDomain(action.payload) };
    case WEBSITES_ADD_WEBSITE_FEED:
      return { ...state, feed: action.payload, isFeedValid: getIsValidDomain(action.payload) };
    case WEBSITES_ADD_WEBSITE_REACH:
      return { ...state, reach: action.payload };
    case WEBSITES_ADD_WEBSITE_LANGUAGE:
      return { ...state, language: action.payload };
    case WEBSITES_ADD_WEBSITE:
      return { ...state, isLoading: true };
    case WEBSITES_ADD_WEBSITE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        data: Object.values(action.payload.items),
        websiteStatus: action.meta.result.status,
      };
    case WEBSITES_ADD_WEBSITE_FAILED:
      return {
        ...state,
        isLoading: false,
        websiteStatus: action.meta.result.status,
      };
    case REJECT_SAVE_FEED:
    case RECEIVE_SAVE_FEED: {
      return { ...state, feedStatus: action.meta.result.status };
    }
    case WEBSITES_OPEN_MODAL:
      return { ...state, isOpen: true };
    case WEBSITES_CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
