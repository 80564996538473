import React, { useState } from 'react';

import { Col, Row } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

import QueryBuilderDropdown from './QueryBuilderDropdown.jsx';
import { queryBuilderFormatter } from '../../../helpers/queryBuilderFormatter';

const QueryBuilderIncludeInput = ({ searchQuery, setSearchQuery }) => {
  const [includeQuery, setIncludeQuery] = useState(null);
  const [queryType, setQueryType] = useState('all');

  const handleChangeIncludeInput = (event) => {
    if (event.target.value) {
      setIncludeQuery(event.target.value);
      const formattedQuery = queryBuilderFormatter(event.target.value, queryType);
      setSearchQuery({ ...searchQuery, includeQuery: formattedQuery });
    } else {
      setSearchQuery({ includeQuery: '' });
    }
  };

  const handleChangeDropdown = (value) => {
    setSearchQuery({ ...searchQuery, includeQuery: value });
  };

  return (
    <>
      <Label for="searchInput" className="mr-sm-2">
        Terms to include
      </Label>
      <Row form>
        <Col md={2} xs={3}>
          <QueryBuilderDropdown
            queryType={queryType}
            setQueryType={setQueryType}
            searchQuery={includeQuery}
            handleStateOnDropdownChange={handleChangeDropdown}
          />
        </Col>
        <Col md={10} xs={9}>
          <Input
            id="searchInput"
            onChange={handleChangeIncludeInput}
            defaultValue={searchQuery.includeQuery}
            placeholder="Your terms separated by commas"
            aria-label="query-builder-include"
          />
        </Col>
      </Row>
    </>
  );
};

export default QueryBuilderIncludeInput;
