import React, { useState } from 'react';

import { connect } from 'react-redux';

import TwitchFeedTableFiltersModal from './TwitchFeedTableFiltersModal.jsx';
import { fetchTwitchFeed, twitchFeedTableFiltersClean } from '../../../../actions/twitchFeed';
import ButtonIcon from '../../../../components/common/ButtonIcon';
import translate from '../../../../helpers/translate';
import { getTwitchFeedTableFilters } from '../../../../selectors/feeds/twitchFeed';
import { getCurrentProject } from '../../../../selectors/projects';

const TwitchFeedTableFilters = ({ twitchFeedTableFiltersClean, fetchTwitchFeed, project, filters }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClearFilters = () => {
    twitchFeedTableFiltersClean();
    fetchTwitchFeed({
      twitch_search_id: project['twitch-search-id'],
      pagination: { page: 1, sortField: 'published_at' },
    });
  };

  const isClearButtonVisible = Object.values(filters).filter(Boolean);

  return (
    <>
      {isClearButtonVisible.length ? (
        <ButtonIcon
          icon="clear"
          transform="shrink-3 down-2"
          color="secondary"
          size="sm"
          className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
          onClick={handleClearFilters}
        >
          Clear filters
        </ButtonIcon>
      ) : null}
      <ButtonIcon
        icon="filter"
        transform="shrink-3 down-2"
        color="secondary"
        size="sm"
        className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
        onClick={() => setModalOpen(true)}
      >
        {translate('common.filters')}
      </ButtonIcon>
      <TwitchFeedTableFiltersModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getTwitchFeedTableFilters(state),
  project: getCurrentProject(state),
});

export default connect(mapStateToProps, { twitchFeedTableFiltersClean, fetchTwitchFeed })(TwitchFeedTableFilters);
