import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';

import translate from '../../../helpers/translate';
import CirclesBackground from '../CirclesBackground/CirclesBackground';
import FootprintsLogo from '../Logo/FootprintsLogo';

const SubscriptionUpgradeSuccess = () => (
  <CirclesBackground>
    <Row className="d-flex flex-center min-vh-100 pb-6">
      <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5 d-flex flex-column flex-center">
        <FootprintsLogo className="mb-4" />
        <Card className="text-center h-10">
          <CardBody className="p-5">
            <div className="display-1 text-200 fs-subscription-upgrade-success">
              {translate('subscription_upgrade_success.level_up')}
            </div>
            <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
              {translate('subscription_upgrade_success.successful_upgrade')}
            </p>
            <hr />
            <p>
              {translate('subscription_upgrade_success.dont_hesitate')}
              <a href="mailto:support@footprints.com" className="ml-1">
                {translate('common.contact_support')}
              </a>
              .
            </p>
            <Link className="btn btn-primary btn-sm mt-3" to="/">
              <FontAwesomeIcon icon="home" className="mr-2" />
              {translate('common.take_me_home')}
            </Link>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </CirclesBackground>
);

export default SubscriptionUpgradeSuccess;
