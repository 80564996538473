import React from 'react';

import { Col, FormGroup, Row } from 'reactstrap';

import translate from '../../../helpers/translate';
import { useGetArticlesCount } from '../QueryBuilderProjects/useGetArticlesCount';

const AlertFormKeywords = ({ formFields, onChange }) => {
  const mutation = useGetArticlesCount();

  const handleTextInputChange = (field, event) => {
    onChange({ field, value: event.target.value });
    if (field === 'keywords' || formFields.keywords.length) {
      mutation.mutate(event.target.value);
    }
  };

  return (
    <FormGroup>
      <Row>
        <Col xs={12}>
          <label>{translate('modals.alert.keywords')}</label>
          <input
            aria-label="alert-input"
            type="text"
            onChange={(e) => handleTextInputChange('keywords', e)}
            placeholder="project name +company ..."
            value={formFields?.keywords}
            className="form-control"
          />

          <span className="text-gray fs--1">
            {mutation?.data?.data ? (
              <>
                Based on our data from the last two months, you can expect to find approximately{' '}
                <strong>{mutation.data.data.count} articles</strong> related to your search.
              </>
            ) : null}
          </span>
          <input
            type="hidden"
            onChange={(e) => handleTextInputChange('id', e)}
            value={formFields?.id ? formFields.id : ''}
          />
          <input
            type="hidden"
            onChange={(e) => handleTextInputChange('projectId', e)}
            value={formFields?.projectId ? formFields.projectId : ''}
          />
          <input
            type="hidden"
            onChange={(e) => handleTextInputChange('projectName', e)}
            value={formFields?.projectName ? formFields.projectName : ''}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default AlertFormKeywords;
