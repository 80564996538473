import {
  SAVED_STREAMS_FILTERS_SET_START_DATE,
  SAVED_STREAMS_CLEAN,
  SAVED_STREAMS_FILTERS_CLEAN,
} from '../../../actions/actionTypes';

const defaultState = null;

const startDate = (state = defaultState, action) => {
  switch (action.type) {
    case SAVED_STREAMS_FILTERS_SET_START_DATE:
      return action.payload;
    case SAVED_STREAMS_FILTERS_CLEAN:
    case SAVED_STREAMS_CLEAN:
      return defaultState;
    default:
      return state;
  }
};

export default startDate;
