import React, { useState } from 'react';

import { faDatabase, faNewspaper, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { resetForm, showModal } from '../../../actions/forms';
import translate from '../../../helpers/translate';

export const ArticlesSaveButtonComp = ({ onNewForm, onNewBulkForm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const styling = {
    top: '5px',
    right: '550px',
  };
  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      inNavbar={true}
      className="position-absolute d-none d-md-inline-block"
      style={styling}
    >
      <DropdownToggle caret size="sm" className="ml-2 mr-3 btn btn-primary text-uppercase" color="primary">
        <FontAwesomeIcon icon={faPlusCircle} /> {translate('articles.add_article_btn')}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => onNewForm()}>
          <FontAwesomeIcon icon={faNewspaper} /> {translate('articles.save_article')}
        </DropdownItem>
        <DropdownItem onClick={() => onNewBulkForm()}>
          <FontAwesomeIcon icon={faDatabase} /> {translate('articles.upload_articles')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

ArticlesSaveButtonComp.propTypes = {
  onNewForm: PropTypes.func,
  onNewBulkForm: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onNewForm: () => {
    dispatch(resetForm());
    dispatch(showModal({ content: 'newArticle' }));
  },
  onNewBulkForm: () => {
    dispatch(showModal({ content: 'newArticleBulk' }));
  },
});

export const ArticlesSaveButton = connect(null, mapDispatchToProps)(ArticlesSaveButtonComp);
