import React from 'react';

import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import ArticlesFeedFiltersDate from './ArticlesFeedFiltersDate.jsx';
import ArticlesFeedFiltersLanguages from './ArticlesFeedFiltersLanguages.jsx';
import ArticlesFeedFiltersMetacritic from './ArticlesFeedFiltersMetacritic.jsx';
import {
  articlesFeedTableFiltersSetMaxScore,
  articlesFeedTableFiltersSetMinScore,
  fetchArticlesFeed,
} from '../../../../../actions/articlesFeed';
import MaxScoreFilter from '../../../../../components/ICO/MediaScoreFilters/MaxScoreFilter.jsx';
import MinScoreFilter from '../../../../../components/ICO/MediaScoreFilters/MinScoreFilter.jsx';
import translate from '../../../../../helpers/translate';
import { getArticlesFeedTableFilters } from '../../../../../selectors/feeds/articlesFeed';
import { getCurrentProject } from '../../../../../selectors/projects';

const ArticlesFeedFiltersModal = ({
  isOpen,
  onClose,
  filters,
  fetchArticlesFeed,
  project,
  articlesFeedTableFiltersSetMinScore,
  articlesFeedTableFiltersSetMaxScore,
}) => {
  const onClick = () => {
    fetchArticlesFeed({
      alert_id: project['alert-id'],
      filters,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
      <ModalHeader className="fp-modal-header--small">{translate('pages.twitch_feed.table.filters.title')}</ModalHeader>
      <ModalBody className="fp-modal-body">
        <Row>
          <Col>
            <ArticlesFeedFiltersLanguages />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <MinScoreFilter
              filters={filters}
              stateAction={articlesFeedTableFiltersSetMinScore}
              labelText="Min. Score Filter"
            />
          </Col>
          <Col xs={6}>
            <MaxScoreFilter
              filters={filters}
              stateAction={articlesFeedTableFiltersSetMaxScore}
              labelText="Max. Score Filter"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={6}>
            <ArticlesFeedFiltersMetacritic />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <ArticlesFeedFiltersDate />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close" aria-label="closeFiltersButton">
          {translate('common.close')}
        </Button>
        <Button
          size="sm"
          type="submit"
          onClick={onClick}
          color="primary"
          name="applyFiltersButton"
          aria-label="applyFiltersButton"
        >
          {translate('common.apply')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  filters: getArticlesFeedTableFilters(state),
  project: getCurrentProject(state),
});

export default connect(mapStateToProps, {
  fetchArticlesFeed,
  articlesFeedTableFiltersSetMinScore,
  articlesFeedTableFiltersSetMaxScore,
})(ArticlesFeedFiltersModal);
