import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { hideModal } from '../../../actions/forms';
import { STATUS } from '../../../config/api-service';
import { getTableFormFieldsById } from '../../../selectors/forms';
import { getCurrentProject } from '../../../selectors/projects';
import { onBulkDeleteApply } from '../tables/helpers/actions';

const tableId = 'articlesFeedTable';

const BulkDeleteFeedFooter = ({ onClose, onSubmit, formStatus }) => (
  <Fragment>
    <Button onClick={onClose}>Close</Button>
    <Button
      disabled={formStatus === STATUS.LOADING || formStatus === STATUS.SAVED || formStatus === STATUS.KO}
      onClick={onSubmit}
      color="primary"
    >
      Confirm
    </Button>
  </Fragment>
);

const mapStateToProps = (state) => {
  const currentGlobalProject = getCurrentProject(state);
  return {
    formStatus: state.forms.feedActionForm.fields.status,
    isOpen: state.forms.modal.isVisible,
    contentId: state.forms.modal.contentId,
    content: state.forms.modal.content,
    alertId: currentGlobalProject['alert-id'] || -1,
    nonFiltersFields: getTableFormFieldsById(state, tableId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(hideModal());
  },
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: () => {
    onBulkDeleteApply(stateProps, dispatchProps, { tableId })[tableId]();
  },
});

BulkDeleteFeedFooter.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  formStatus: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BulkDeleteFeedFooter);
