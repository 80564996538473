import React, { useState } from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { Card, CardBody, Col, Row } from 'reactstrap';

import ArticlesFeedModalCreateKeyword from './Modals/FeedConfiguration/ArticlesFeedModalCreateKeyword.jsx';
import ButtonIcon from '../../../components/common/ButtonIcon';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import translate from '../../../helpers/translate';

const ArticlesCreateFeed = ({ project }) => {
  const [isModalOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card className="mt-3 mb-3">
            <FalconCardHeader title={`${project.name} Articles`} light={false} className="mt-2 mb-2" />
            <CardBody className="d-flex flex-center flex-column">
              <Row>
                <Col>
                  {translate('pages.articles_feed.feature_description_first')} {project.name}{' '}
                  {translate('pages.articles_feed.feature_description_second')}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <ButtonIcon
                    name="createFeedButton"
                    icon={faPlusCircle}
                    color="primary"
                    onClick={() => setModalIsOpen(!isModalOpen)}
                  >
                    {translate('pages.articles_feed.create_feed')}
                  </ButtonIcon>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ArticlesFeedModalCreateKeyword isOpen={isModalOpen} onClose={() => setModalIsOpen(false)} project={project} />
    </>
  );
};

export default ArticlesCreateFeed;
