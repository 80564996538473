import React from 'react';

import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { twitchFeedSetNotificationStatus } from '../../../../actions/twitchFeed';
import CheckboxSwitch from '../../../../components/ICO/forms/CheckboxSwitch';
import translate from '../../../../helpers/translate';
import { getTwitchFeedAreNotificationsEnabled } from '../../../../selectors/feeds/twitchFeed';
import {
  getOrganizationPermissionsHasDiscord,
  getOrganizationPermissionsHasSlack,
} from '../../../../selectors/permissions';
import DiscordNotificationForm from '../../Notifications/DiscordNotificationsForm.jsx';
import SlackNotificationsForm from '../../Notifications/SlackNotificationsForm.jsx';

const TwitchFeedModalsKeywordNotifications = ({
  userHasSlack,
  userHasDiscord,
  twitchFeedSetNotificationStatus,
  areNotificationsEnabled,
}) => {
  const handleChangeNotificationStatus = () => {
    twitchFeedSetNotificationStatus(!areNotificationsEnabled);
  };

  return userHasSlack || userHasDiscord ? (
    <>
      <Row>
        <Col>
          <div htmlFor="feed-tracking-switch" className="align-items-center d-flex mt-2 mb-2">
            <CheckboxSwitch
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={15}
              uncheckedIcon={false}
              checkedIcon={false}
              checked={areNotificationsEnabled}
              onChange={handleChangeNotificationStatus}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={15}
              width={32}
              className="yt-feed-switch"
              id="yt-feed-slack-switch"
            />
            <span className="text-soft-gray ml-2">{translate('modals.alert.notifications')}</span>
          </div>
        </Col>
      </Row>
      {areNotificationsEnabled ? (
        <>
          {userHasSlack ? <SlackNotificationsForm /> : null}
          {userHasDiscord ? <DiscordNotificationForm /> : null}
        </>
      ) : null}
    </>
  ) : null;
};

const mapStateToProps = (state) => ({
  userHasSlack: getOrganizationPermissionsHasSlack(state),
  userHasDiscord: getOrganizationPermissionsHasDiscord(state),
  areNotificationsEnabled: getTwitchFeedAreNotificationsEnabled(state),
});

export default connect(mapStateToProps, { twitchFeedSetNotificationStatus })(TwitchFeedModalsKeywordNotifications);
