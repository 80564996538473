import { useMutation } from 'react-query';

import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';

export const useDeleteStreams = (onDeleteSuccess) =>
  useMutation({
    mutationKey: 'delete_stream',
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/saved_streams/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ stream_ids: data }),
      });
      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.text();
      }
    },
    onSuccess: () => {
      getToastedNotification({
        customMessage: {
          type: 'success',
          message: translate('pages.saved_streams.notifications.delete_stream_success'),
        },
      });
      onDeleteSuccess();
    },
    onError: (error) => {
      const customMessage = {
        type: 'error',
        message: error.message,
      };
      getToastedNotification({ customMessage });
    },
  });
