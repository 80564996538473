import React from 'react';

import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import YoutubeFeedTableVideoPreviewModalBody from './YoutubeFeedTableVideoPreviewModalBody.jsx';
import YoutubeFeedTableVideoPreviewModalFooter from './YoutubeFeedTableVideoPreviewModalFooter.jsx';

const YoutubeFeedTableVideoPreviewModal = ({ isOpen, onClose, videoData, withSave }) => (
  <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
    <ModalBody className="fp-modal-body p-4">
      <YoutubeFeedTableVideoPreviewModalBody videoData={videoData} />
    </ModalBody>
    <ModalFooter>
      <YoutubeFeedTableVideoPreviewModalFooter videoData={videoData} onClose={onClose} withSave={withSave} />
    </ModalFooter>
  </Modal>
);

export default YoutubeFeedTableVideoPreviewModal;
