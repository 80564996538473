import React from 'react';

import { Col, Form, Row } from 'reactstrap';

import TwitchFeedTableFiltersModalBodyDateFilter from './TwitchFeedTableFiltersModalBodyDateFilter.jsx';
import TwitchFeedTableFiltersModalBodyMaxViews from './TwitchFeedTableFiltersModalBodyMaxFollowers.jsx';
import TwitchFeedTableFiltersModalBodyMinViews from './TwitchFeedTableFiltersModalBodyMinFollowers.jsx';

const TwitchFeedTableFiltersModalBody = () => (
  <Form onSubmit={(e) => e.preventDefault()}>
    <Row className="mb-3">
      <Col>
        <TwitchFeedTableFiltersModalBodyMinViews />
      </Col>
      <Col>
        <TwitchFeedTableFiltersModalBodyMaxViews />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <TwitchFeedTableFiltersModalBodyDateFilter />
      </Col>
    </Row>
  </Form>
);

export default TwitchFeedTableFiltersModalBody;
