import React from 'react';

import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { Col, CustomInput, Row } from 'reactstrap';

import WizardInput from '../../components/auth/wizard/WizardInput';
import { RECAPTCHA_KEY } from '../../config/global-config';
import translate from '../../helpers/translate';

const checkbox_label = (
  <>
    {' '}
    {translate('signup.common.user_profile.terms_and_privacy_policy.accept')}
    <a rel="noreferrer" href="src/pages/Signup/SignUpUserProfile" target="_blank">
      {translate('signup.common.user_profile.terms_and_privacy_policy.terms')}
    </a>
    {translate('signup.common.user_profile.terms_and_privacy_policy.and')}
    <a rel="noreferrer" target="_blank" href="src/pages/Signup/SignUpUserProfile">
      {translate('signup.common.user_profile.terms_and_privacy_policy.privacy_policy')}
    </a>
    {'*'}
  </>
);

const nameInputRegisterConfig = {
  required: translate('signup.common.user_profile.errors.name_required'),
  minLength: {
    value: 2,
    message: translate('signup.common.user_profile.errors.name_min_length'),
  },
  maxLength: {
    value: 60,
    message: translate('signup.common.user_profile.errors.name_max_length'),
  },
};

const passwordInputRegisterConfig = {
  required: translate('signup.common.user_profile.errors.password_required'),
  minLength: {
    value: 8,
    message: translate('signup.common.user_profile.errors.password_length'),
  },
};

const emailInputRegisterConfig = {
  required: 'Email is required',
};

const checkboxInputRegisterConfig = {
  required: translate('signup.common.user_profile.errors.agree_to_terms'),
};

const SignUpUserProfile = ({ register, errors, watch, handleCaptcha, withCaptcha }) => {
  const confirmPasswordInputRegisterConfig = {
    required: translate('signup.common.user_profile.errors.confirm_password_required'),
    validate: (value) =>
      value === watch('user_password') || translate('signup.common.user_profile.errors.password_match'),
  };

  return (
    <>
      <Row>
        <Col>
          <p className="fs-2 text-center">{translate('signup.common.user_profile_copy')}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <WizardInput
            aria-label="name-input"
            label={translate('signup.common.user_profile.name')}
            type="name"
            placeholder={translate('signup.common.user_profile.name_placeholder')}
            name="user_username"
            id="user_username"
            innerRef={register(nameInputRegisterConfig)}
            errors={errors}
          />
          <WizardInput
            aria-label="email-input"
            label={translate('signup.common.user_profile.email')}
            type="email"
            placeholder={translate('signup.common.user_profile.email_placeholder')}
            id="user_email"
            name="user_email"
            innerRef={register(emailInputRegisterConfig)}
            errors={errors}
          />
          <Row form>
            <Col>
              <WizardInput
                aria-label="password-input"
                label={translate('signup.common.user_profile.password')}
                type="password"
                placeholder={translate('signup.common.user_profile.password_placeholder')}
                id="user_password"
                name="user_password"
                innerRef={register(passwordInputRegisterConfig)}
                errors={errors}
              />
            </Col>
            <Col>
              <WizardInput
                aria-label="confirm-password-input"
                label={translate('signup.common.user_profile.confirm_password')}
                type="password"
                placeholder={translate('signup.common.user_profile.confirm_password_placeholder')}
                id="confirm_user_password"
                name="confirm_user_password"
                innerRef={register(confirmPasswordInputRegisterConfig)}
                errors={errors}
              />
            </Col>
          </Row>
          <WizardInput
            aria-label="terms-and-privacy-policy-input"
            label={checkbox_label}
            type="checkbox"
            id="user_checkbox"
            name="user_checkbox"
            tag={CustomInput}
            innerRef={register(checkboxInputRegisterConfig)}
            errors={errors}
          />
        </Col>
      </Row>
      {withCaptcha ? (
        <Row>
          <Col xs={12}>
            <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={handleCaptcha} />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

SignUpUserProfile.propTypes = {
  register: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.string,
};

export default SignUpUserProfile;
