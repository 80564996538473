import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import ButtonIconWithTooltip from './Buttons/ButtonIconWithTooltip/ButtonIconWithTooltip';
import translate from '../../helpers/translate';

const castNumber = (value) => Number(value) / 100;

const AddonProduct = ({ checkoutCreateSession, customerId, isLoading, product, isAnnualPlan, type }) => {
  const handleCheckout = () => {
    const selectedProductId = isAnnualPlan ? product?.annual_price_id : product?.monthly_price_id;
    checkoutCreateSession({ selectedProductId, customerId });
  };

  const priceToRender = isAnnualPlan ? castNumber(product?.annual_price) : castNumber(product?.monthly_price);
  const imageToRender =
    type === 'starter'
      ? 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Icon-Starter-Sq.svg'
      : 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Icon-Pro-Sq.svg';

  const priceString = isAnnualPlan
    ? translate('modals.upgrade_subscription.addons.per_year')
    : translate('modals.upgrade_subscription.addons.per_month');
  const isButtonDisabled = isLoading || !customerId;
  const buttonText = isLoading
    ? translate('common.loading')
    : translate('modals.upgrade_subscription.addons.switch_plan');
  const disabledText = customerId === null ? 'modals.upgrade_subscription.disabled_checkout' : '';

  return (
    <>
      <Row key={uuidv4()} className="text-left">
        <Col xs={1}>
          <img src={imageToRender} width="50px" height="50px" alt={`${type} product icon`} />
        </Col>
        <Col xs={8}>
          <span>{product.name}</span>
          <Row>
            <Col>
              <span className="text-lighter text-gray">
                ${priceToRender} {priceString}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={3} className="d-flex justify-content-end align-items-center">
          <ButtonIconWithTooltip
            disabled={isButtonDisabled}
            onClick={handleCheckout}
            id="checkoutButton"
            aria-label="checkoutButton"
            text={buttonText}
            disabledText={disabledText}
          />
        </Col>
      </Row>
      <hr />
    </>
  );
};

AddonProduct.propTypes = {
  checkoutCreateSession: PropTypes.func,
  isLoading: PropTypes.bool,
  customerId: PropTypes.string,
  product: PropTypes.shape({
    name: PropTypes.string,
    monthly_price_id: PropTypes.string,
    annual_price_id: PropTypes.string,
    annual_price: PropTypes.number,
    monthly_price: PropTypes.number,
  }),
};

export default AddonProduct;
