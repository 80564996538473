import {
  SETTINGS_ADD_ORGANISATION,
  SETTINGS_ADD_ORGANIZATION_SUCCESS,
  SETTINGS_ADD_ORGANIZATION_ERROR,
  SETTINGS_ADD_ORGANIZATION_FAILURE,
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: null,
  error: null,
};

const addOrganisationModal = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_ADD_ORGANISATION:
      return { ...state, isLoading: true };
    case SETTINGS_ADD_ORGANIZATION_SUCCESS:
      return { ...state, isLoading: false, response: action.payload };
    case SETTINGS_ADD_ORGANIZATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SETTINGS_ADD_ORGANIZATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default addOrganisationModal;
