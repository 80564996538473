import { createAction } from 'redux-actions';

export const CHANGE_MODULE = 'CHANGE_MODULE';
export const CHANGE_MODULE_STATUS = 'CHANGE_MODULE_STATUS';
export const CREATE_MODULE = 'CREATE_MODULE';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE';
export const CHANGE_CHART = 'CHANGE_CHART';

export const changeModule = createAction(CHANGE_MODULE);
export const changeChart = createAction(CHANGE_CHART, (module, newValues) => ({
  module,
  newValues,
}));

export const changePage = createAction(CHANGE_PAGE, (module, newPage) => ({
  module,
  newPage,
}));

export const changePageSize = createAction(CHANGE_PAGE_SIZE, (module, newPageSize) => ({
  module,
  newPageSize,
}));
