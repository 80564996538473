import { useMutation } from 'react-query';

import getToastedNotification from '../../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../../config/global-config';
import { getToken } from '../../../../helpers/getToken';
import translate from '../../../../helpers/translate';

const useUpdateKeyword = (onClose) =>
  useMutation({
    mutationKey: 'update_keyword',
    mutationFn: async ({
      keyword,
      youtube_search_id,
      slack_webhook_url,
      slack_min_subs_notification,
      discord_min_subs_notification,
      discord_webhook_url,
      is_enabled,
    }) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/youtube_feed/${youtube_search_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          keyword,
          youtube_search_id,
          slack_webhook_url,
          slack_min_subs_notification,
          discord_min_subs_notification,
          discord_webhook_url,
          is_enabled,
        }),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: translate('pages.youtube_feed.notifications.keyword_updated'),
      };
      getToastedNotification({ customMessage });
      onClose();
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: translate('pages.youtube_feed.notifications.keyword_updated_error'),
      };
      getToastedNotification({ customMessage });
    },
  });

export default useUpdateKeyword;
