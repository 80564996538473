import React, { useState } from 'react';

import { isEqual } from 'lodash/lang';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';

import OrganizationDetailsFormLogoModal from './OrganizationDetailsFormLogoModal';
import { saveOrganizationLogo } from '../../actions/organizationDetails';
import Avatar from '../../components/common/Avatar';
import { IMAGES_ROOT } from '../../config/global-config';
import {
  getOrganizationDetailsLogo,
  getOrganizationDetailsOrganizationId,
  getOrganizationDetailsFormFields,
} from '../../selectors/organizationDetails';

const OrganizationDetailsFormLogo = ({
  formFields,
  organizationId,
  organizationLogo,
  saveOrganizationLogo,
  userIsAdmin,
}) => {
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [changeAvatarData, setChangeAvatarData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => {
    setIsOpen(!isOpen);
    setSaveButtonDisabled(true);
  };

  const handleChangeAvatar = (data) => {
    const isSameLogo = isEqual(data[0].name, organizationLogo);
    setSaveButtonDisabled(isSameLogo);
    setChangeAvatarData(data);
  };

  const handleSave = () => {
    saveOrganizationLogo({
      images: changeAvatarData,
      scope: `${organizationId};org`,
    });
    setIsOpen(!isOpen);
    setSaveButtonDisabled(true);
  };

  const avatarSrc = `${IMAGES_ROOT}${organizationLogo}`;

  return (
    <FormGroup>
      <h6 className="mt-4">Logo</h6>
      <Avatar src={avatarSrc} rounded="circle" />
      {userIsAdmin && (
        <div className="align-middle">
          <div style={{ color: '#5ca6bd' }} className="fs--1 cursor-pointer" onClick={() => setIsOpen(true)}>
            Change organization logo
          </div>
          <OrganizationDetailsFormLogoModal
            imageUploaderData={formFields}
            isOpen={isOpen}
            onChange={handleChangeAvatar}
            onClose={handleCloseModal}
            onSave={handleSave}
            isSaveButtonDisabled={isSaveButtonDisabled}
          />
        </div>
      )}
    </FormGroup>
  );
};

OrganizationDetailsFormLogo.propTypes = {
  formFields: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  userIsAdmin: PropTypes.bool,
  organizationId: PropTypes.string,
  organizationLogo: PropTypes.string,
  saveOrganizationLogo: PropTypes.func,
};

OrganizationDetailsFormLogo.defaultProps = {
  userIsAdmin: false,
  organizationId: 0,
  organizationLogo: '',
  formFields: {
    name: '',
    avatar: '',
  },
  saveOrganizationLogo: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formFields: getOrganizationDetailsFormFields(state),
  organizationId: getOrganizationDetailsOrganizationId(state),
  organizationLogo: getOrganizationDetailsLogo(state),
});

export default connect(mapStateToProps, { saveOrganizationLogo })(OrganizationDetailsFormLogo);
