import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const StepIcons = ({ icon, text, className }) => (
  <>
    <span className="nav-item-circle-parent">
      <span className={`nav-item-circle ${className}`}>
        <FontAwesomeIcon icon={icon} />
      </span>
    </span>
    <span className={`d-none d-md-block mt-1 fs--1 ${className}`}>{text}</span>
  </>
);

StepIcons.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default StepIcons;
