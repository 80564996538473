import React, { memo, useEffect } from 'react';

import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, Row, Col } from 'reactstrap';

import ReportChart from './ReportDetailsChart';
import ReportDetailsKeyMetrics from './ReportDetailsKeyMetrics';
import ReportDetailsStreams from './ReportDetailsStreams.jsx';
import ReportDetailsVideos from './ReportDetailsVideos.jsx';
import { fetchArticles, fetchArticlesGroups } from '../../../actions/articles.js';
import { resetTableFilters } from '../../../actions/forms.js';
import { fetchLanguages } from '../../../actions/languages.js';
import { fetchProjects } from '../../../actions/projects.js';
import { fetchReport } from '../../../actions/reports.js';
import { fetchTags } from '../../../actions/tags.js';
import { fetchWebsites } from '../../../actions/websites';
import Avatar from '../../../components/common/Avatar';
import ReportTableDisplay from '../../../components/ICO/forms/ReportTableDisplay.js';
import HighlightedArticlesSection from '../../../components/ICO/reports/HighlightedArticlesSection';
import ReportDescription from '../../../components/ICO/reports/ReportDescription';
import useGetScreenshots from '../../../components/ICO/reports/useGetScreenshot';
import { IMAGES_ROOT } from '../../../config/global-config';
import translate from '../../../helpers/translate';
import { isIterableArray } from '../../../helpers/utils';
import {
  getReportParams,
  getReportParamsByDate,
  getReportParamsByLang,
  getReportParamsByTag,
  getReportParamsByWebsite,
  getTableFiltersFormFields,
} from '../../../selectors/forms.js';
import { getCurrentProject } from '../../../selectors/projects';
import { getReport, getReportVideosTotalItems } from '../../../selectors/reports.js';
import { getUsersCurrentUser } from '../../../selectors/users';
import '../../../assets/scss/theme/Footprints/components/_report_details.scss';

const pagination = {
  page: 1,
  page_size: 5000,
};

const ReportDetails = ({ onLoad, tableFilters, reportId, currentReport, isPublicReport, currentProject }) => {
  const [isLoading, response, error] = useGetScreenshots(currentReport['reports-images']);

  useEffect(() => {
    onLoad(reportId, tableFilters, isPublicReport);
  }, [isPublicReport, onLoad, reportId, tableFilters]);

  const urlParams = useParams();
  const date = dayjs(currentReport['date-end']).format('dddd MMMM D, YYYY');
  const avatarSrc = isIterableArray(currentProject.avatar)
    ? currentProject.avatar
    : IMAGES_ROOT + currentProject.avatar;

  function handleLogoClick() {
    window.open('https://footprints.gg/', '_blank', 'noopener');
  }

  return (
    <div className="report-details">
      <Row>
        <Col className="mb-4">
          {isPublicReport ? (
            <div className="mb-1 cursor-pointer">
              <a onClick={handleLogoClick} className="d-flex align-items-center justify-content-center">
                <img
                  src="https://ico-files.s3.eu-west-2.amazonaws.com/images/Footprints-logo-256x256.png"
                  alt={translate('reports.footprints_logo')}
                  className="centered-logo-image"
                />
                <p className="centered-logo-text">{translate('common.footprints')}</p>
              </a>
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12} md={12} className="mb-3">
          <Card>
            <CardBody className="h-13 d-flex flex-column">
              <Row className="mt-3 align-items-center">
                <Col className="d-flex flex-row align-items-center">
                  <Avatar src={avatarSrc} rounded="circle" size="2xl" className="mr-2" />
                  <h1 className="text-regular text-size-big mb-0">{currentReport.name}</h1>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <span className="text-gray">{date}</span>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mb-3 mt-3">
        <Col>
          <ReportDetailsKeyMetrics currentReport={currentReport} reportId={reportId} />
        </Col>
      </Row>
      {currentReport['reports-images'] && currentReport['reports-images'].length ? (
        <Row className="mb-2">
          <Col>
            <Card className="mb-3">
              <CardBody>
                <HighlightedArticlesSection
                  isLoading={isLoading}
                  error={error}
                  highlightedArticles={currentReport['reports-images']}
                  screenshots={response}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
      {currentReport.descriptionHTML && (
        <Row className="mb-2">
          <Col>
            <Card className="mb-3">
              <CardBody>
                <ReportDescription reportId={reportId} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row className="mb-3 mt-3">
        <Col>
          <ReportChart currentReport={currentReport} reportId={reportId} />
        </Col>
      </Row>
      <Row className="mb-3 mt-3">
        <Col>
          <ReportTableDisplay urlParams={urlParams} reportId={reportId} currentReport={currentReport} />
        </Col>
      </Row>
      {currentReport['reports-videos'] && currentReport['reports-videos'].length ? (
        <Row className="mb-3 mt-3">
          <Col>
            <ReportDetailsVideos currentReport={currentReport} isPublicReport={isPublicReport} />
          </Col>
        </Row>
      ) : null}
      {currentReport['reports-streams'] && currentReport['reports-streams'].length ? (
        <Row className="mb-3 mt-3">
          <Col>
            <ReportDetailsStreams currentReport={currentReport} isPublicReport={isPublicReport} />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  reportId: ownProps.reportId,
  tableFilters: getTableFiltersFormFields(state),
  currentReport: getReport(state, ownProps.reportId),
  isPublicReport: ownProps.isPublicReport,
  currentProject: getCurrentProject(state),
  user: getUsersCurrentUser(state),
  reportVideosTotalItems: getReportVideosTotalItems(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (reportId, filters, isPublicReport) => {
    dispatch(resetTableFilters());
    dispatch(fetchLanguages());
    dispatch(fetchTags());
    dispatch(fetchReport(reportId));
    const reqParamsGroupByWebsite = getReportParamsByWebsite(reportId);
    const reqParamsGroupByLang = getReportParamsByLang(reportId);
    const reqParamsGroupByTag = getReportParamsByTag(reportId);
    const reqParamsGroupByDate = getReportParamsByDate(reportId);
    const reqParamsGroup = getReportParams({ reportId, filters, pagination });
    dispatch(fetchArticlesGroups(reqParamsGroupByWebsite));
    dispatch(fetchArticlesGroups(reqParamsGroupByLang));
    dispatch(fetchArticlesGroups(reqParamsGroupByTag));
    dispatch(fetchArticlesGroups(reqParamsGroupByDate));
    dispatch(fetchArticles(reqParamsGroup));
    if (!isPublicReport) {
      dispatch(fetchWebsites(reqParamsGroup));
      dispatch(fetchProjects());
    }
  },
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(ReportDetails));
