import React from 'react';

import { faUserAlt, faCheck, faCopyright, faUserPlus, faGamepad } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

import StepIcons from '../StepIcons';

const SignUpFreeUserSteps = ({ step, handleBackStep }) => (
  <Nav className="justify-content-center">
    <NavItem>
      <NavLink
        className={classNames('font-weight-semi-bold', {
          'done cursor-pointer': step > 1,
          active: step === 1,
        })}
        color="red"
        aria-label="first-step"
        onClick={() => handleBackStep(1)}
      >
        <StepIcons icon={faUserPlus} text="Sign Up" className="fp-signup-invite" />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames('font-weight-semi-bold', {
          'done cursor-pointer': step > 2,
          active: step === 2,
        })}
        aria-label="second-step"
        onClick={() => handleBackStep(2)}
      >
        <StepIcons icon={faUserAlt} text="Account" className="fp-signup-account" />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames('font-weight-semi-bold', {
          'done  cursor-pointer': step > 3,
          active: step === 3,
        })}
        aria-label="third-step"
        onClick={() => handleBackStep(3)}
      >
        <StepIcons icon={faCopyright} text="Organisation" className="fp-signup-avatar" />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames('font-weight-semi-bold', {
          'done  cursor-pointer': step > 4,
          active: step === 4,
        })}
        aria-label="forth-step"
        onClick={() => handleBackStep(4)}
      >
        <StepIcons icon={faGamepad} text="Project Set-up" className="fp-signup-project" />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        aria-label="five-step"
        className={classNames('font-weight-semi-bold', {
          'done  cursor-pointer': step > 4,
        })}
      >
        <StepIcons icon={faCheck} text="Done" className="fp-signup-done" />
      </NavLink>
    </NavItem>
  </Nav>
);

SignUpFreeUserSteps.propTypes = {
  step: PropTypes.number,
  handleBackStep: PropTypes.func,
};

export default SignUpFreeUserSteps;
