import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import SavedStreamsTable from './Table/SavedStreamsTable.jsx';
import { fetchLanguages } from '../../actions/languages';
import { savedStreamsClean } from '../../actions/savedStreams';
import { fetchTags } from '../../actions/tags';
import { getCurrentProjectId } from '../../selectors/forms';

const SavedStreams = ({ fetchTags, fetchLanguages, projectId, savedStreamsClean }) => {
  useEffect(() => {
    fetchTags(projectId);
    fetchLanguages();
  }, [fetchLanguages, fetchTags, projectId]);

  useEffect(
    () => () => {
      savedStreamsClean();
    },
    [savedStreamsClean],
  );

  return <SavedStreamsTable />;
};

const mapStateToProps = (state) => ({
  projectId: getCurrentProjectId(state),
});
export default connect(mapStateToProps, { fetchTags, fetchLanguages, savedStreamsClean })(SavedStreams);
