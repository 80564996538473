import React from 'react';

import { connect } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

import { articlesFeedTableFiltersSetMetacritic } from '../../../../../actions/articlesFeed';
import { getArticlesFeedTableFilters } from '../../../../../selectors/feeds/articlesFeed';

const ArticlesFeedFiltersMetacritic = ({ filters, articlesFeedTableFiltersSetMetacritic }) => {
  const handleChange = (e) => {
    articlesFeedTableFiltersSetMetacritic(e.target.checked);
  };

  return (
    <FormGroup check inline>
      <Input type="checkbox" onChange={handleChange} checked={filters.metacritic} />
      <Label check>Show only Metacritic websites</Label>
    </FormGroup>
  );
};

const mapStateToProps = (state) => ({
  filters: getArticlesFeedTableFilters(state),
});

export default connect(mapStateToProps, { articlesFeedTableFiltersSetMetacritic })(ArticlesFeedFiltersMetacritic);
