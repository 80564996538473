import { connect } from 'react-redux';

import RangeDatePicker from './RangeDatePicker';
import { changeNewReportForm, STATUS_DISABLED } from '../../../actions/forms';

const mapDispatchToProps = (dispatch) => {
  const onChange = (field, date) => {
    dispatch(
      changeNewReportForm({
        field: field,
        value: date,
      }),
    );
  };

  const onChangeTo = (to) => onChange('to', to);

  const onChangeFrom = (from) => onChange('from', from);

  return {
    onChangeFrom,
    onChangeTo,
  };
};

const mapStateToProps = (state, ownProps) => {
  let lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  return {
    from: state.forms.newReportForm.fields.from,
    to: state.forms.newReportForm.fields.to,
    disabled:
      state.forms.newReportForm.fieldsStatus.tags === STATUS_DISABLED ||
      (ownProps.formStatus !== 'STEP 0' && ownProps.formStatus !== 'STEP 0 LOADED'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RangeDatePicker);
