import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { showModal } from '../../../actions/forms';

const ReportArticleTableActions = ({ article, onClick }) => (
  <UncontrolledDropdown>
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right positionFixed className="border py-2">
      <DropdownItem onClick={onClick}>Preview</DropdownItem>
      <DropdownItem divider />
      <a href={article.fullUrl || '#'} target={'_new'}>
        <DropdownItem>Open</DropdownItem>
      </a>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(
      showModal({
        content: 'previewArticle',
        contentId: ownProps.article.id,
        contentData: { ...ownProps.article, isFeed: true },
      }),
    );
  },
});

ReportArticleTableActions.propTypes = {
  article: PropTypes.shape({
    fullUrl: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ReportArticleTableActions);
