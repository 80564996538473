import { combineReducers } from 'redux';

import endDate from './endDate';
import languages from './languages';
import maxFollowers from './maxFollowers';
import minFollowers from './minFollowers';
import startDate from './startDate';
import tags from './tags';

const filters = combineReducers({
  endDate,
  languages,
  maxFollowers,
  minFollowers,
  startDate,
  tags,
});

export default filters;
