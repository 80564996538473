import React, { useState } from 'react';

import values from 'lodash/values';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';

import { changeTagForm, hideModal } from '../../../actions/forms';
import { INPUT_TEXT_MIN_LENGTH } from '../../../constants';
import { getCurrentProjectId } from '../../../selectors/forms';
import { getProjects } from '../../../selectors/projects';
import CheckboxSwitch from '../forms/CheckboxSwitch';
import SimpleDatePicker from '../forms/SimpleDatePicker';

const getSelectValues = (rows) => {
  let options = [];
  values(rows).map((row) => options.push({ value: row.id, label: row.name }));
  return options;
};

const TagForm = ({ onChange, formFields, setSaveButtonDisabled }) => {
  const [isDateCheckSelected, setSelectedDateCheck] = useState(formFields['has-highlight']);

  const handleTextInputChange = (event, name) => {
    onChange({ field: name, value: event.target.value });
    setSaveButtonDisabled(event.target.value.length < INPUT_TEXT_MIN_LENGTH);
  };

  const handleChangeSwitch = (field, newValue) => {
    onChange({ field, value: newValue });
    setSelectedDateCheck(!isDateCheckSelected);

    if (!newValue) {
      onChange({ field: 'highlight-date', value: null });
    }
  };

  const handleChangeDate = (value) => {
    onChange({ field: 'highlight-date', value });
  };

  return (
    <FormGroup>
      <Row className="mb-4">
        <Col md={12} xs={12}>
          <input
            type="hidden"
            onChange={(e) => handleTextInputChange(e, 'id')}
            value={formFields.id ? formFields.id : ''}
          />
          <input
            aria-label="text-input"
            type="text"
            onChange={(e) => handleTextInputChange(e, 'name')}
            placeholder="Tag Name"
            value={formFields.name}
            className="form-control"
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <div htmlFor="tags-disabled-switch" className="align-items-center d-flex mb-3">
            <CheckboxSwitch
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={15}
              uncheckedIcon={false}
              checkedIcon={false}
              checked={formFields['has-highlight'] || false}
              onChange={(e) => handleChangeSwitch('has-highlight', e)}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={15}
              width={32}
              className="feed-switch"
              id="feed-disabled-switch"
            />
            <span className="text-soft-gray ml-2"> Display Highlight Date on Report Graph</span>
          </div>
          {isDateCheckSelected && (
            <SimpleDatePicker onChange={(e) => handleChangeDate(e)} value={formFields['highlight-date']} />
          )}
        </Col>
      </Row>
    </FormGroup>
  );
};

const mapStateToProps = (state) => ({
  currentProjectId: getCurrentProjectId(state),
  formTitle: state.forms.newTagForm ? state.forms.newTagForm.title : '',
  formFields: state.forms.newTagForm ? state.forms.newTagForm.fields : {},
  projectOptions: getSelectValues(getProjects(state)),
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  onChange: (data) => dispatch(changeTagForm(data)),
});

TagForm.propTypes = {
  onChange: PropTypes.func,
  formFields: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    'highlight-date': PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    'has-highlight': PropTypes.bool,
  }),
  setSaveButtonDisabled: PropTypes.func,
};

TagForm.defaultProps = {
  onChange: () => {},
  formFields: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
