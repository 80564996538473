import { SAVED_STREAMS_FILTERS_CLEAN, SAVED_STREAMS_FILTERS_SET_MIN_FOLLOWERS } from '../../../actions/actionTypes';

const initialState = null;

const minFollowers = (state = initialState, action) => {
  switch (action.type) {
    case SAVED_STREAMS_FILTERS_SET_MIN_FOLLOWERS:
      return action.payload;
    case SAVED_STREAMS_FILTERS_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default minFollowers;
