import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Logo = ({ at, className }) => (
  <div
    className={classNames(
      'd-flex',
      {
        'align-items-center py-3': at === 'navbar-vertical',
        'align-items-center': at === 'navbar-top',
        'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth',
      },
      className,
    )}
  >
    <img src="https://ico-files.s3.eu-west-2.amazonaws.com/images/Footprints-Loading-Icon.gif" alt="Footprints logo" />
  </div>
);

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
