import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Row,
  Label,
  Col,
  Input,
  Container,
} from 'reactstrap';

import translate from '../../helpers/translate';

import '../../assets/scss/theme/Footprints/components/_modal.scss';

const SettingsAddOrganisationModal = ({ onClose, onSave, isOpen, onChange, isSaveButtonDisabled }) => (
  <Modal isOpen={isOpen} toggle={onClose} size="md" className="fp-modal">
    <ModalHeader className="fp-modal-header--small d-flex align-items-center">
      <div className="text-bold">{translate('settings.add_new_organization')}</div>
    </ModalHeader>
    <Form onSubmit={(e) => e.preventDefault()}>
      <ModalBody>
        <FormGroup className="d-flex align-items-center">
          <Container>
            <Row xs={12}>
              <Col>
                <Label>{translate('settings.organization_name')}</Label>
                <Input name="name" type="text" aria-label="name-input" onChange={onChange} />
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <>
          <Button aria-label="close-button" size="sm" onClick={onClose} color="secondary" className="fp-button">
            {translate('common.close')}
          </Button>
          <Button
            aria-label="save-button"
            size="sm"
            onClick={onSave}
            type="submit"
            color="primary"
            className="fp-button"
            disabled={isSaveButtonDisabled}
          >
            {translate('common.save')}
          </Button>
        </>
      </ModalFooter>
    </Form>
  </Modal>
);

SettingsAddOrganisationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  isSaveButtonDisabled: PropTypes.bool,
};

export default SettingsAddOrganisationModal;
