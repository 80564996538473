import React from 'react';

import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody } from 'reactstrap';

import translate from '../../../helpers/translate';

const NewReportHighlightSection = () => (
  <Card className="mb-3">
    <CardBody>
      <h2 className="mb-3 title-style">{translate('reports.highlights')}</h2>
      <div className="highlights-description">
        <span>
          {translate('reports.tap_icon')}
          <FontAwesomeIcon icon={faHeart} className="mr-2 ml-2 fs-0 heart-icon" />
          {translate('reports.highlight_text')}
        </span>
      </div>
    </CardBody>
  </Card>
);

export default NewReportHighlightSection;
