import React from 'react';

import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';

import { twitchFeedTableFiltersSetMaxFollowers } from '../../../../actions/twitchFeed';
import translate from '../../../../helpers/translate';
import { getTwitchFeedTableFilters } from '../../../../selectors/feeds/twitchFeed';

const TwitchFeedTableFiltersModalBodyMaxFollowers = ({ twitchFeedTableFiltersSetMaxFollowers, filters }) => (
  <>
    <Label for="maxFollowers">{translate('pages.twitch_feed.table.filters.max_followers')}</Label>
    <Input
      type="number"
      onChange={(e) => twitchFeedTableFiltersSetMaxFollowers(e.target.value)}
      name="maxFollowers"
      id="maxFollowers"
      placeholder="10,000"
      value={filters.maxFollowers ?? ''}
    />
  </>
);
const mapStateToProps = (state) => ({
  filters: getTwitchFeedTableFilters(state),
});
export default connect(mapStateToProps, { twitchFeedTableFiltersSetMaxFollowers })(
  TwitchFeedTableFiltersModalBodyMaxFollowers,
);
