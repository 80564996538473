import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import columns from './columns.jsx';
import SavedStreamsTableActions from './SavedStreamsTableBulkActions.jsx';
import SavedStreamsTableFilters from './SavedStreamsTableFilters.jsx';
import {
  savedStreamsClean,
  savedStreamsFetch,
  savedStreamsSetPageSize,
  savedStreamsTableFiltersClean,
} from '../../../actions/savedStreams';
import Table from '../../../components/ICO/Table/Table.jsx';
import translate from '../../../helpers/translate';
import { getCurrentProjectId } from '../../../selectors/forms';
import {
  getSavedStreams,
  getSavedStreamsFilters,
  getSavedStreamsIsLoading,
  getSavedStreamsPageSize,
  getSavedStreamsPagination,
} from '../../../selectors/savedCoverage/savedStreams';

const SavedStreamsTable = ({
  data = [],
  isLoading,
  savedStreamsFetch,
  projectId,
  pagination,
  filters,
  savedStreamsClean,
  savedStreamsSetPageSize,
  sizePerPage = 10,
}) => {
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    savedStreamsFetch({ project_id: projectId, pagination: { sizePerPage, page: 1, sortField: 'published_at' } });
  }, [savedStreamsFetch, projectId, sizePerPage]);

  const onTableChange = (type, { searchText, sizePerPage, page, sortField, sortOrder }) => {
    const sortFieldEnabled = sortField ?? 'published_at';

    if (type === 'search' && searchText) {
      savedStreamsClean();
      savedStreamsFetch({
        project_id: projectId,
        filters: {
          ...filters,
          searchTerm: searchText,
        },
        pagination: {
          page,
          sizePerPage,
          sortField: sortFieldEnabled,
          sortOrder,
        },
      });
    } else if (searchText) {
      savedStreamsFetch({
        project_id: projectId,
        filters: {
          ...filters,
          searchTerm: searchText,
        },
        pagination: {
          page,
          sizePerPage,
          sortField: sortFieldEnabled,
          sortOrder,
        },
      });
    } else {
      savedStreamsFetch({
        project_id: projectId,
        filters,
        pagination: {
          page,
          sizePerPage,
          sortField: sortFieldEnabled,
          sortOrder,
        },
      });
    }
  };

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    savedStreamsSetPageSize(newSize);
    savedStreamsFetch({
      project_id: projectId,
      filters,
      pagination: {
        page: pagination.page,
        sizePerPage: newSize,
        sortField: 'published_at',
      },
    });
  };

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  return (
    <Table
      data={data}
      withRowSelection
      isLoading={isLoading}
      title={translate('pages.saved_streams.table.title')}
      columns={columns}
      onTableChange={onTableChange}
      pagination={paginationWithExtra}
      rowActions={<SavedStreamsTableActions />}
      filters={<SavedStreamsTableFilters />}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getSavedStreams(state),
  isLoading: getSavedStreamsIsLoading(state),
  projectId: getCurrentProjectId(state),
  pagination: getSavedStreamsPagination(state),
  filters: getSavedStreamsFilters(state),
  sizePerPage: getSavedStreamsPageSize(state),
});
export default connect(mapStateToProps, {
  savedStreamsFetch,
  savedStreamsClean,
  savedStreamsTableFiltersClean,
  savedStreamsSetPageSize,
})(SavedStreamsTable);
