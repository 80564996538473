import React, { useContext, useEffect, useRef } from 'react';

import classNames from 'classnames';
import is from 'is_js';
import PropTypes from 'prop-types';
import { Collapse, Nav, Navbar } from 'reactstrap';

import ICONavbarVerticalMenu from './ICONavbarVerticalMenu';
import SelectProjectLink from './SelectProjectLink';
import bgNavbarImg from '../../../assets/img/generic/bg-navbar.png';
import { navbarBreakPoint } from '../../../config';
import AppContext from '../../../context/Context';
import getPermissionsBasedMenu from '../../../helpers/getPermissionsBasedMenu';
import Flex from '../../common/Flex';

const ICONavbarVertical = ({ navbarStyle, user }) => {
  const navBarRef = useRef(null);
  const { showBurgerMenu, isNavbarVerticalCollapsed } = useContext(AppContext);
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  const menu = getPermissionsBasedMenu();

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical navbar-glass', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent',
      })}
      light
    >
      <Flex align="center">
        <SelectProjectLink />
      </Flex>
      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
        style={
          navbarStyle === 'vibrant' && {
            backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`,
          }
        }
      >
        <Nav navbar vertical>
          <ICONavbarVerticalMenu routes={menu} user={user} />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

ICONavbarVertical.propTypes = {
  navbarStyle: PropTypes.string,
  user: PropTypes.shape({
    organization: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

ICONavbarVertical.defaultProps = {
  navbarStyle: 'transparent',
};

export default ICONavbarVertical;
