import React, { useContext, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { STATUS_ERROR, STATUS_LOADED } from '../../actions/forms';
import { postNewUser } from '../../actions/users';
import { AuthWizardContext } from '../../context/Context';
import translate from '../../helpers/translate';
import { getSignupResultUser } from '../../selectors/users';

const SignupResult = ({ postNewUser, resultUser, ...props }) => {
  const { setStep, setUser, user } = useContext(AuthWizardContext);
  const hasLoaded = useRef(false); //To prevent useEffect to loop

  const emptyData = () => {
    setStep(1);
    setUser({});
  };

  useEffect(() => {
    if (!hasLoaded.current) {
      postNewUser({
        name: user.user_username,
        email: user.user_email,
        confirmPassword: user.user_password,
        inviteKey: props.invite.key,
        picture: resultUser.picture,
      });
      hasLoaded.current = true;
    }
  }, [props, postNewUser, user, resultUser]);

  if (resultUser?.status === STATUS_ERROR) {
    return (
      <>
        <Row>
          <Col className="text-center">
            <h4 className="mb-1">{translate('signup.common.errors.copy')}</h4>
            <p className="fs-0">
              {translate('signup.common.errors.account_creation')}
              {resultUser.errorCode}
            </p>
            <Button color="primary" className="px-5 my-3 text-white" onClick={emptyData} aria-label="startOverButton">
              Start Over
            </Button>
          </Col>
        </Row>
      </>
    );
  }
  if (resultUser?.status === STATUS_LOADED) {
    return (
      <>
        <Row className="d-flex px-md-6 pb-3">
          <Col className="align-content-center text-center">
            <Row className="justify-content-center">
              <h2>{translate('signup.result.title')}</h2>
            </Row>
            <Row className="mt-4 mb-4">
              <p className="fs-2 ">{translate('signup.result.copy')}</p>
            </Row>
            <Row className="justify-content-center">
              <NavLink to="/">
                <Button
                  color="primary"
                  className="px-5"
                  type="submit"
                  transform="down-1 shrink-4"
                  onClick={emptyData}
                  aria-label="startButton"
                >
                  {translate('signup.result.button')}
                </Button>
              </NavLink>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col className="text-center">
          <h4 className="mb-1">Creating account...</h4>
          <p className="fs-0">This should only take a few seconds...</p>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  resultUser: getSignupResultUser(state),
});

SignupResult.propTypes = {
  submitUser: PropTypes.func,
  resultUser: PropTypes.shape({
    status: PropTypes.string,
    errorCode: PropTypes.string,
    error: PropTypes.string,
  }),
};

export default connect(mapStateToProps, {
  postNewUser,
})(SignupResult);
