import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';

import { changeNewFeedForm } from '../../../actions/forms';
import CheckboxSwitch from '../forms/CheckboxSwitch';

const FeedFormComp = ({ onChange, fields, mode }) => {
  const handleTextInputChange = (event, field) => {
    onChange({ field, value: event.target.value });
  };
  const handleChangeSwitch = (field, newValue) => {
    onChange({ field: 'disabled', value: newValue });
  };

  if (mode === 'edit') {
    return (
      <>
        <FormGroup>
          <Row>
            <Col md={12} xs={12}>
              <input
                type="text"
                onChange={(e) => handleTextInputChange(e, 'feed')}
                placeholder="Feed link"
                value={fields.feed ? fields.feed : ''}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <div htmlFor="feed-disabled-switch" className="align-items-center d-flex mb-3 mt-4">
                <CheckboxSwitch
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={fields.disabled}
                  onChange={(e) => handleChangeSwitch('disabled', e)}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={32}
                  className="feed-switch"
                  id="feed-disabled-switch"
                />
                <span className="text-soft-gray ml-2">Disable Feed</span>
              </div>
            </Col>
          </Row>
          <input type="hidden" onChange={(e) => handleTextInputChange(e, 'id')} value={fields.id ? fields.id : ''} />
        </FormGroup>
      </>
    );
  }

  return (
    <>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <input
              type="text"
              onChange={(e) => handleTextInputChange(e, 'feed')}
              placeholder="Feed link"
              value={fields.feed ? fields.feed : ''}
              className="form-control"
            />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  formTitle: state.forms.newFeedForm ? state.forms.newFeedForm.title : '',
  fields: state.forms.newFeedForm ? state.forms.newFeedForm.fields : {},
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (data) => dispatch(changeNewFeedForm(data)),
});

FeedFormComp.propTypes = {
  onChange: PropTypes.func,
  fields: PropTypes.shape({
    feed: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  mode: PropTypes.string,
};

export const FeedForm = connect(mapStateToProps, mapDispatchToProps)(FeedFormComp);
