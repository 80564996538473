import { combineReducers } from 'redux';

import endDate from './endDate';
import maxFollowers from './maxFollowers';
import minFollowers from './minFollowers';
import startDate from './startDate';

const filters = combineReducers({ startDate, endDate, minFollowers, maxFollowers });

export default filters;
