import {
  SAVED_STREAMS_CLEAN,
  SAVED_STREAMS_FILTERS_CLEAN,
  SAVED_STREAMS_FILTERS_SET_LANGUAGES,
} from '../../../actions/actionTypes';

const defaultState = null;

const languages = (state = defaultState, action) => {
  switch (action.type) {
    case SAVED_STREAMS_FILTERS_SET_LANGUAGES:
      return action.payload;
    case SAVED_STREAMS_FILTERS_CLEAN:
    case SAVED_STREAMS_CLEAN:
      return defaultState;
    default:
      return state;
  }
};

export default languages;
