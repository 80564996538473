import { ARTICLES_FEED_FILTERS_CLEAR, ARTICLES_FEED_FILTERS_SET_METACRITIC } from '../../../actions/actionTypes';

const initialState = null;

const metacritic = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLES_FEED_FILTERS_SET_METACRITIC:
      return action.payload;
    case ARTICLES_FEED_FILTERS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default metacritic;
