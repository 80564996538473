import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { savedVideosDeleteVideos } from '../../../actions/savedVideos';
import YoutubeSavedVideosVideoPreviewModal from '../../SavedVideos/Modals/Preview/YoutubeSavedVideosTableVideoPreviewModal.jsx';
import SavedVideosModalsEditVideo from '../Modals/EditVideo/SavedVideosModalsEditVideo.jsx';

const SavedVideosTableActionColumn = ({ savedVideosDeleteVideos, videoData }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditVideoOpen, setEditVideoModalOpen] = useState(false);

  return (
    <>
      <UncontrolledDropdown className="fp-action">
        <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
          <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
        </DropdownToggle>
        <DropdownMenu right positionFixed className="border py-2">
          <DropdownItem onClick={() => setModalOpen(true)}>View video</DropdownItem>
          <DropdownItem onClick={() => setEditVideoModalOpen(true)}>Edit video</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => savedVideosDeleteVideos([videoData.id])} className="text-danger">
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <YoutubeSavedVideosVideoPreviewModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        videoData={videoData}
      />
      <SavedVideosModalsEditVideo
        isOpen={isEditVideoOpen}
        onClose={() => setEditVideoModalOpen(false)}
        videoData={videoData}
      />
    </>
  );
};

export default connect(null, { savedVideosDeleteVideos })(SavedVideosTableActionColumn);
