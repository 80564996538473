import React from 'react';

import SavedVideosTableActionColumn from './SavedVideosTableActionColumn.jsx';
import { tagFormatter } from '../../../components/ICO/tables/ArticlesFeedTable';
import {
  dateFormatter,
  langFormatter,
  videosViewFormatter,
  youtubeChannelFormatter,
} from '../../../components/ICO/tables/helpers/formatters';
import translate from '../../../helpers/translate';
import YoutubeSavedVideosTableVideoPreview from '../../SavedVideos/YoutubeSavedVideosTableVideoPreview.jsx';
import { EditVideoProvider } from '../Modals/EditVideo/EditVideoContext';

const columns = [
  {
    dataField: 'thumbnail',
    text: translate('pages.youtube_feed.table.columns.thumbnail'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
    formatter: (row, data) => <img src={data.thumbnail} width="150" height="80" alt="Video thumbnail" />,
  },
  {
    dataField: 'channel_title',
    formatter: (data, row) => youtubeChannelFormatter(row.channel_title, row.channel_thumbnail, row.channel_custom_url),
    text: translate('pages.youtube_feed.table.columns.channel'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'title',
    formatter: (data, videoData) => <YoutubeSavedVideosTableVideoPreview videoData={videoData} />,
    text: translate('pages.saved_videos.table.columns.title'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'views',
    formatter: videosViewFormatter,
    text: translate('pages.saved_videos.table.columns.views'),
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'tag.name',
    formatter: tagFormatter,
    text: translate('pages.saved_videos.table.columns.tag'),
    classes: 'border-0 align-middle fs-0',
    headerClasses: 'border-0',
    sort: true,
    csvExport: true,
  },
  {
    dataField: 'channel_language',
    formatter: langFormatter,
    text: translate('pages.saved_videos.table.columns.language'),
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'channel_country',
    formatter: langFormatter,
    text: 'Country',
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'published_at',
    formatter: dateFormatter,
    text: translate('pages.saved_videos.table.columns.date'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
  },
  {
    text: translate('pages.saved_videos.table.columns.actions'),
    formatter: (row, rowData) => (
      <EditVideoProvider>
        <SavedVideosTableActionColumn videoData={rowData} />
      </EditVideoProvider>
    ),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
];

export default columns;
