import React from 'react';

import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import Section from '../components/common/Section';
import Error404 from '../components/errors/Error404';
import Error500 from '../components/errors/Error500';
import ModalRoot from '../components/ICO/modals/ModalRoot';
import ReportApp from '../components/ICO/pages/ReportApp';
import ReportContainer from '../pages/Reports/ReportDetails/ReportDetails';

const PublicLayout = ({ match: { url } }) => (
  <Section className="py-0">
    <ModalRoot />
    <Row className="flex-center min-vh-100 py-3">
      <Col sm={12} md={12} lg={12} xl={12} className="col-xxl-12">
        <Switch>
          <Route
            path="/p"
            render={() => (
              <ReportApp>
                <Route
                  exact
                  path="/p/r/:reportId"
                  render={(routeProps) => (
                    <ReportContainer isPublicReport={true} reportId={routeProps.match.params.reportId} />
                  )}
                />
              </ReportApp>
            )}
          />
          <Route path={`${url}/404`} component={Error404} />
          <Route path={`${url}/500`} component={Error500} />

          {/*Redirect*/}
          <Redirect to={`${url}/404`} />
        </Switch>
      </Col>
    </Row>
  </Section>
);

PublicLayout.propTypes = { match: PropTypes.object };

export default PublicLayout;
