import React from 'react';

import { faFolderMinus, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';

const LockActions = ({ lockLabel, locked, id, onUnlock, type, onLock }) => {
  let labelLock, labelUnlock;
  let iconLock, iconUnlock;
  if (lockLabel === 'archive') {
    labelLock = 'Archive';
    labelUnlock = 'Re-activate';
    iconLock = faFolderMinus;
    iconUnlock = faFolderPlus;
  } else {
    labelLock = 'Archive';
    labelUnlock = 'Re-activate';
    iconLock = faFolderMinus;
    iconUnlock = faFolderPlus;
  }
  if (locked) {
    return (
      <Link id={id} onClick={onUnlock} to="#">
        <DropdownItem>
          <FontAwesomeIcon icon={iconUnlock} /> {labelUnlock} {type}
        </DropdownItem>
      </Link>
    );
  } else {
    return (
      <Link id={id} onClick={onLock} to="#">
        <DropdownItem>
          <FontAwesomeIcon icon={iconLock} /> {labelLock} {type}
        </DropdownItem>
      </Link>
    );
  }
};

LockActions.propTypes = {
  locked: PropTypes.bool,
  type: PropTypes.string,
  lockLabel: PropTypes.string,
};

export default LockActions;
