import React from 'react';

import { Button, Col, Row } from 'reactstrap';

import { tagFormatter } from '../../../../components/ICO/tables/ArticlesFeedTable';
import { langFormatter, videosViewFormatter } from '../../../../components/ICO/tables/helpers/formatters';
import translate from '../../../../helpers/translate';

const SavedStreamsTablePreviewStreamModalFooter = ({ videoData, onClose }) => (
  <Row className="d-flex flex-row w-100 justify-content-around">
    <Col xs={3} className="d-flex flex-row">
      {videoData?.followers > 0 ? <span className="fs-0">{videosViewFormatter(videoData.followers)}</span> : null}
      {videoData?.language ? <span className="ml-2 fs-0">{langFormatter(videoData.language)}</span> : null}
      {videoData?.tag ? <span className="ml-2 fs-0">{tagFormatter(videoData.tag.name)}</span> : null}
    </Col>
    <Col xs={9} className="justify-content-end d-flex">
      <Button size="sm" onClick={onClose} color="secondary" className="fp-button-close">
        {translate('common.close')}
      </Button>
    </Col>
  </Row>
);

export default SavedStreamsTablePreviewStreamModalFooter;
