import React, { useState } from 'react';

import dayjs from 'dayjs';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

import {
  articlesFeedTableFiltersSetEndDate,
  articlesFeedTableFiltersSetStartDate,
} from '../../../../../actions/articlesFeed';
import RangeDateFilter from '../../../../../components/ICO/forms/RangeDateFilter';
import translate from '../../../../../helpers/translate';
import { getArticlesFeedTableFilters } from '../../../../../selectors/feeds/articlesFeed';

const allTimeDateTimeId = 1;
const specificDateTimeId = 2;

const ArticlesFeedFiltersDate = ({
  filters,
  articlesFeedTableFiltersSetEndDate,
  articlesFeedTableFiltersSetStartDate,
}) => {
  const { startDate, endDate } = filters;
  const defaultOptionChecked = endDate || startDate ? specificDateTimeId : allTimeDateTimeId;

  const [checkedOption, setCheckedOption] = useState(defaultOptionChecked);
  const defaultDateRange = startDate || endDate ? [startDate, endDate] : [moment().startOf('month'), moment()];
  const [localDateRange, setLocalDateRange] = useState(defaultDateRange);

  const onChangeDate = (datePickerData) => {
    const endDate = dayjs(datePickerData.endDate).format('YYYY-MM-DD');
    const startDate = dayjs(datePickerData.startDate).format('YYYY-MM-DD');
    setLocalDateRange([startDate, endDate]);
    articlesFeedTableFiltersSetStartDate(startDate);
    articlesFeedTableFiltersSetEndDate(endDate);
  };

  const handleAllTime = () => {
    setLocalDateRange([]);
    setCheckedOption(allTimeDateTimeId);
    articlesFeedTableFiltersSetStartDate(null);
    articlesFeedTableFiltersSetEndDate(null);
  };

  return (
    <>
      <Label for="keywordInput">{translate('pages.youtube_feed.table.filters.dates.title')}</Label>
      <FormGroup className="form-check">
        <Input
          type="radio"
          name="radio1"
          onClick={handleAllTime}
          checked={checkedOption === allTimeDateTimeId}
          readOnly
        />
        <Label check>{translate('pages.youtube_feed.table.filters.dates.all_time')}</Label>
      </FormGroup>
      <FormGroup className="form-check">
        <Input
          type="radio"
          name="radio2"
          onClick={() => setCheckedOption(specificDateTimeId)}
          checked={checkedOption === specificDateTimeId}
          readOnly
        />
        <Label check>{translate('pages.youtube_feed.table.filters.dates.specific')}</Label>
        <RangeDateFilter
          className="daterange-predefined"
          disabled={checkedOption !== 2}
          onChange={(e, datePickerData) => onChangeDate(datePickerData)}
          start={localDateRange[0]}
          end={localDateRange[1]}
        />
      </FormGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getArticlesFeedTableFilters(state),
});

export default connect(mapStateToProps, {
  articlesFeedTableFiltersSetEndDate,
  articlesFeedTableFiltersSetStartDate,
})(ArticlesFeedFiltersDate);
