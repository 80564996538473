import React from 'react';

import translate from './translate';

const projectsPageSteps = [
  {
    target: 'body',
    content: (
      <div>
        <h2>{translate('user_tour.projects.first_step')}</h2>
      </div>
    ),
    placement: 'center',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: '.text-600.btn-reveal.btn.btn-link.btn-sm',
    content: <p>{translate('user_tour.projects.second_step')}</p>,
    placement: 'bottom',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: '.fp-modal-body.modal-body > .form-group > .row:nth-child(2)',
    content: (
      <div style={{ textAlign: 'left' }}>
        <p>
          Our search query builder is designed to help you find exactly what you need. Here&apos;s a quick guide to
          using it:
        </p>
        <ul>
          <li>
            <strong>Terms to include:</strong>
            Start by typing in the keywords related to what you&apos;re searching for, separated by commas.
          </li>
          <li>
            <strong> Terms to exclude: </strong>
            If there are certain words or phrases you do not want to appear in your search results, list them here.
            Choose &apos;ALL&apos; to exclude all listed terms, helping you to avoid irrelevant results.
          </li>
          <li>
            <strong>Advanced query mode: </strong>
            For more complex searches, toggle on the advanced mode. This lets you construct detailed queries using
            operators.
          </li>
        </ul>
      </div>
    ),
    placement: 'bottom',
    styles: {
      options: {
        zIndex: 10000,
        width: 900,
      },
    },
  },
  {
    target: '.modal-footer > .btn.btn-primary.btn-sm',
    content: <p>{translate('user_tour.projects.forth_step')}</p>,
    placement: 'bottom',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];

const mediaFeedPageSteps = [
  {
    target: 'body',
    content: (
      <div>
        <h2>{translate('user_tour.media_feed.first_step.title')}</h2>
        <p>{translate('user_tour.media_feed.first_step.subtitle')}</p>
      </div>
    ),
    placement: 'center',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target:
      '#main > div.container-fluid > div > div > div.table-responsive.fp-table > div.mt-2.mb-2.card-header > div > div.text-right.col-auto > div > div > button.mx-2.d-none.d-md-inline-block.text-uppercase.fp-button.btn.btn-secondary.btn-sm',

    content: (
      <div style={{ textAlign: 'left' }}>
        <p>{translate('user_tour.media_feed.second_step.title')}</p>

        <ul>
          <li>{translate('user_tour.media_feed.second_step.language_filter')}</li>
          <li>{translate('user_tour.media_feed.second_step.media_score_filter')}</li>
          <li>{translate('user_tour.media_feed.second_step.time_range_filter')}</li>
        </ul>
      </div>
    ),
  },
  {
    target:
      '#main > div.container-fluid > div > div > div.table-responsive.fp-table > div.p-0.card-body > div > table > thead > tr > th.selection-cell-header',
    content: (
      <div style={{ textAlign: 'left' }}>
        <p>{translate('user_tour.media_feed.third_step.title')}</p>
        <ul>
          <li>{translate('user_tour.media_feed.third_step.move_action')}</li>
          <li>{translate('user_tour.media_feed.third_step.export_action')}</li>
          <li>{translate('user_tour.media_feed.third_step.delete_action')}</li>
        </ul>
      </div>
    ),
    placement: 'center',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target:
      '#main > div.container-fluid > div > div > div.table-responsive.fp-table > div.p-0.card-body > div > table > thead > tr > th.selection-cell-header',
    content: <p style={{ textAlign: 'left' }}>{translate('user_tour.side_menu.first_step')}</p>,
    placement: 'center',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target:
      '#main > div.container-fluid > nav > div.scrollbar.collapse.navbar-collapse > ul > li:nth-child(2) > a > div',
    content: <p>{translate('user_tour.side_menu.second_step')}</p>,
    placement: 'left',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: '#main > div.container-fluid > nav > div.scrollbar.collapse.navbar-collapse > ul > li:nth-child(3) > a',
    content: <p>{translate('user_tour.side_menu.third_step')}</p>,
    placement: 'left',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: '#main > div.container-fluid > nav > div.scrollbar.collapse.navbar-collapse > ul > li:nth-child(4) > a',
    content: <p>{translate('user_tour.side_menu.forth_step')}</p>,
    placement: 'left',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: '#main > div.container-fluid > nav > div.scrollbar.collapse.navbar-collapse > ul > li:nth-child(6) > a',
    content: <p>{translate('user_tour.side_menu.fifth_step')}</p>,
    placement: 'left',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    target: '#main > div.container-fluid > nav > div.scrollbar.collapse.navbar-collapse > ul > li:nth-child(7) > a',
    content: <p>{translate('user_tour.side_menu.sixth_step')}</p>,
    placement: 'left',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];

const style = {
  options: {
    primaryColor: '#5ca6bd',
  },
};

export { projectsPageSteps, mediaFeedPageSteps, style };
