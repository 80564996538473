import { useMutation } from 'react-query';

import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';

export const useGetArticlesCount = () =>
  useMutation({
    mutationKey: '/analytics/articles/count',
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/analytics/articles/count`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ query: data }),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
  });
