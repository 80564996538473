import {
  YOUTUBE_FEED_CREATE_KEYWORD,
  YOUTUBE_FEED_CREATE_KEYWORD_ERROR,
  YOUTUBE_FEED_CREATE_KEYWORD_SUCCESS,
  YOUTUBE_FEED_CREATE_KEYWORD_FAILURE,
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: [],
  error: null,
};

const keyword = (state = initialState, action) => {
  switch (action.type) {
    case YOUTUBE_FEED_CREATE_KEYWORD:
      return { ...state, isLoading: true };
    case YOUTUBE_FEED_CREATE_KEYWORD_SUCCESS:
      return { ...state, response: action.payload, isLoading: false };
    case YOUTUBE_FEED_CREATE_KEYWORD_ERROR:
    case YOUTUBE_FEED_CREATE_KEYWORD_FAILURE:
      return {
        ...state,
        error: { ...state.error, status: action.payload.status, message: action.payload.message },
        isLoading: false,
      };
    default:
      return state;
  }
};
export default keyword;
