import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { NewReportContext } from '../../../context/Context';

const NewReportProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [tagsExclude, setTagsExclude] = useState({});
  const [step, setStep] = useState(1);

  const handleInputChange = ({ value, name }) => setUser({ ...user, [name]: value });
  const handleInputTags = ({ value, name }) => setTagsExclude({ ...tagsExclude, [name]: value });

  const value = { user, setUser, step, setStep, handleInputChange, handleInputTags };
  return <NewReportContext.Provider value={value}>{children}</NewReportContext.Provider>;
};

NewReportProvider.propTypes = {
  children: PropTypes.node,
};

export default NewReportProvider;
