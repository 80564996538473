import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { savedStreamsFetch } from '../../../actions/savedStreams';
import { getCurrentProjectId } from '../../../selectors/forms';
import { getSavedStreamsFilters, getSavedStreamsPagination } from '../../../selectors/savedCoverage/savedStreams';
import { useDeleteStreams } from '../hooks/useDeleteStreams';
import SavedStreamsModalsEditStream from '../Modals/EditStream/SavedStreamsModalsEditStream.jsx';
import SavedStreamsTablePreviewStreamModal from '../Modals/Preview/SavedStreamsTablePreviewStreamModal.jsx';

const SavedStreamsTableActionColumn = ({ videoData, savedStreamsFetch, projectId, filters, pagination }) => {
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const onDeleteSuccess = () => {
    savedStreamsFetch({
      project_id: projectId,
      pagination: { sizePerPage: pagination.pageSize, page: pagination.page, sortField: 'published_at' },
      filters,
    });
  };

  const { mutate } = useDeleteStreams(onDeleteSuccess);

  return (
    <>
      <UncontrolledDropdown className="fp-action">
        <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
          <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
        </DropdownToggle>
        <DropdownMenu right positionFixed className="border py-2">
          <DropdownItem onClick={() => setPreviewModalOpen(true)}>View stream</DropdownItem>
          <DropdownItem onClick={() => setEditModalOpen(true)}>Edit stream</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => mutate([videoData.id])} className="text-danger">
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <SavedStreamsTablePreviewStreamModal
        videoData={videoData}
        isOpen={isPreviewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
      />
      <SavedStreamsModalsEditStream
        videoData={videoData}
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getSavedStreamsFilters(state),
  projectId: getCurrentProjectId(state),
  pagination: getSavedStreamsPagination(state),
});

export default connect(mapStateToProps, { savedStreamsFetch })(SavedStreamsTableActionColumn);
