import { genGetParams, genGetParamsWithId } from '../../../../config/api-service';
import { removeProtocolFromUrl } from '../../../../config/url-utils';
import { getClientsLocked } from '../../../../selectors/clients';
import { getRssArticlesTable } from '../../../../selectors/feeds';
import { getAlerts, getProjectsLocked } from '../../../../selectors/projects';
import {
  articlesTableRows,
  getArticlesNewReportPreview,
  getArticlesNewReportStep0,
  getArticlesReport,
  getArticlesWebsite,
  getTableFiltersKey,
} from '../../../../selectors/savedCoverage/articles';
import { getTagsLocked } from '../../../../selectors/tags';
import { getOrganizationUsersList } from '../../../../selectors/users';
import { getWebsitesByTableFilters } from '../../../../selectors/websites';

export const getEntityDataByKey = (state, entity, data, key) => {
  let emptyValue = data === 'listsStatus' ? 'KO' : {};
  let parentEntity = entity === 'rssArticles' ? 'feeds' : entity;
  if (state[parentEntity][entity]) {
    return state[parentEntity][entity][data][key] ? state[parentEntity][entity][data][key] : emptyValue;
  } else if (state[entity]) {
    return state[entity][data][key] ? state[entity][data][key] : emptyValue;
  } else return emptyValue;
};

export const getDisplayTitleValue = (art) => {
  let title = art.title || removeProtocolFromUrl(art.url ? art.url : '');
  title = title.length > 85 ? `${title.substr(0, 85)}...` : title;
  return title;
};

export const getRowsStatsFiltered = (state, rowsType = 'articles', tableId, currentGlobalProject) => {
  let key;
  if (rowsType === 'rssArticles') {
    key = genGetParamsWithId(currentGlobalProject['alert-id'], getTableFiltersKey(tableId)(state));
  } else {
    key = genGetParams(getTableFiltersKey(tableId)(state));
  }
  return getEntityDataByKey(state, rowsType, 'stats', key);
};

export const getRowsStatusFiltered = (state, rowsType = 'articles', tableId, currentGlobalProject) => {
  let key;
  if (rowsType === 'rssArticles') {
    key = genGetParamsWithId(currentGlobalProject['alert-id'], getTableFiltersKey(tableId)(state));
  } else {
    key = genGetParams(getTableFiltersKey(tableId)(state));
  }
  return getEntityDataByKey(state, rowsType, 'listsStatus', key);
};

export const tableRows = (state, props) => ({
  alertsTable: () => getAlerts(state),
  projectsTable: () => getProjectsLocked(state, props.locked),
  tagsTable: () => getTagsLocked(state, props.locked),
  clientsTable: () => getClientsLocked(state, props.locked),
  articlesTable: () => articlesTableRows(state),
  articlesNewReportTable: () => getArticlesNewReportPreview(state),
  articlesNewReportTableStep0: () => getArticlesNewReportStep0(state),
  articlesWebsiteTable: () => getArticlesWebsite(state, props),
  articlesReportTable: () => getArticlesReport(state, props),
  articlesFeedTable: () => getRssArticlesTable(state),
  usersTable: () => getOrganizationUsersList(state),
  websitesTable: () => getWebsitesByTableFilters(state),
  reportsTable: () => getArticlesReport(state),
});
