import React from 'react';

import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import Error404 from '../components/errors/Error404';
import Error500 from '../components/errors/Error500';
import ErrorEndOfTrial from '../components/errors/ErrorEndOfTrial';
import ErrorNoCustomer from '../components/errors/ErrorNoCustomer';
import ErrorNoOrg from '../components/errors/ErrorNoOrg';
import CirclesBackground from '../components/ICO/CirclesBackground/CirclesBackground';
import FootprintsLogo from '../components/ICO/Logo/FootprintsLogo';

const ErrorLayout = ({ match: { url } }) => (
  <Switch>
    <CirclesBackground>
      <Row className="d-flex flex-center min-vh-100 pb-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5 d-flex flex-column flex-center">
          <FootprintsLogo className="mb-4" />
          <Switch>
            <Route path={`${url}/404`} component={Error404} />
            <Route path={`${url}/500`} component={Error500} />
            <Route path={`${url}/no-customer`} exact component={ErrorNoCustomer} />
            <Route path={`${url}/end-of-trial`} exact component={ErrorEndOfTrial} />
            <Route path={`${url}/no-org`} exact component={ErrorNoOrg} />
          </Switch>
        </Col>
      </Row>
    </CirclesBackground>
  </Switch>
);

ErrorLayout.propTypes = { match: PropTypes.object };

export default ErrorLayout;
