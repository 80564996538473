import { API_CALL, FETCH_COUNTRIES } from './actionTypes';

export const fetchCountries = () => {
  const endpoint = 'countries?page[page]=0&page[page_size]=250';
  return {
    type: API_CALL,
    meta: {
      type: FETCH_COUNTRIES,
      endpoint: endpoint,
      method: 'GET',
    },
  };
};
