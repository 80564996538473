import React, { createContext, useContext, useState } from 'react';

const EditVideoContext = createContext({});

export const EditVideoProvider = ({ children }) => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [title, setNewTitle] = useState(null);
  const [description, setNewDescription] = useState(null);
  const [views, setNewViews] = useState(null);
  const [subscribers, setNewSubscribers] = useState(null);
  const [country, setNewCountry] = useState(null);
  const [publishedAt, setPublishedAt] = useState(null);

  return (
    <EditVideoContext.Provider
      value={{
        selectedTag,
        setSelectedTag,
        selectedLanguage,
        setSelectedLanguage,
        title,
        setNewTitle,
        description,
        setNewDescription,
        views,
        setNewViews,
        subscribers,
        setNewSubscribers,
        country,
        setNewCountry,
        publishedAt,
        setPublishedAt,
      }}
    >
      {children}
    </EditVideoContext.Provider>
  );
};

export const useEditVideoContext = () => useContext(EditVideoContext);
