import React from 'react';

import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';

import { youtubeFeedTableFiltersSetMaxViews } from '../../../actions/youtubeFeed';
import translate from '../../../helpers/translate';
import { getYoutubeFeedTableFilters } from '../../../selectors/feeds/youtubeFeed';

const YoutubeFeedTableFiltersModalBodyMaxViews = ({ youtubeFeedTableFiltersSetMaxViews, filters }) => (
  <>
    <Label for="minViews">{translate('pages.youtube_feed.table.filters.max_views')}</Label>
    <Input
      type="number"
      onChange={(e) => youtubeFeedTableFiltersSetMaxViews(e.target.value)}
      name="maxViews"
      id="maxViews"
      placeholder="10.000"
      value={filters.maxViews ?? ''}
    />
  </>
);

const mapStateToProps = (state) => ({
  filters: getYoutubeFeedTableFilters(state),
});

export default connect(mapStateToProps, { youtubeFeedTableFiltersSetMaxViews })(
  YoutubeFeedTableFiltersModalBodyMaxViews,
);
