import React from 'react';

import { Col, FormGroup, Row } from 'reactstrap';
import Label from 'reactstrap/lib/Label';

import CheckboxSwitch from '../../../../../components/ICO/forms/CheckboxSwitch';
import { InputSelect } from '../../../../../components/ICO/forms/InputSelect';
import SingleSelect from '../../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../../helpers/translate';

const REACH = [0.1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const reachOptions = REACH.map((reach) => ({
  value: reach,
  label: reach,
}));

const SlackTracking = ({ alertConfig, setAlertConfig, organizationSlackChannels }) => {
  const handleInputChange = (field, value) => {
    setAlertConfig({ ...alertConfig, [field]: value });
  };

  const handleChangeSelect = (data) => {
    setAlertConfig({ ...alertConfig, slack_channel_name: data?.label, slack_channel_id: data?.value });
  };

  const slackChannelValue = { value: alertConfig?.slack_channel_id, label: alertConfig?.slack_channel_name };

  const options = organizationSlackChannels;
  const minReachValue = parseFloat(alertConfig?.min_reach);

  const reachValue = reachOptions.find((option) => option.value === minReachValue) || {
    value: minReachValue,
    label: `${minReachValue}`,
  };

  return (
    <>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <div htmlFor="feed-tracking-switch" className="align-items-center d-flex mt-2 mb-2">
              <CheckboxSwitch
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={15}
                uncheckedIcon={false}
                checkedIcon={false}
                checked={alertConfig?.slack_tracking}
                onChange={(e) => handleInputChange('slack_tracking', e)}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={32}
                className="feed-switch"
                aria-label="slack-switch"
                id="feed-tracking-switch"
              />
              <span className="text-soft-gray ml-2">{translate('modals.alert.slack_switch')}</span>
            </div>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className={!alertConfig?.slack_tracking && 'd-none'}>
        <Row>
          <Col lg={12}>
            <Label>{translate('modals.alert.slack_channel')}</Label>
            <InputSelect
              name="slack-channel"
              onChange={handleChangeSelect}
              value={slackChannelValue}
              items={options}
              disabled={!alertConfig?.slack_tracking}
              defaultValue={'channel name'}
              placeholder={'#channel-name'}
              aria-label="slack-channel-select"
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className={!alertConfig?.slack_tracking && 'd-none'}>
        <Row>
          <Col md={12} xs={12}>
            <Label>{translate('modals.alert.media_score')}</Label>
            <SingleSelect
              name="reach"
              value={reachValue.value}
              items={reachOptions}
              disabled={!alertConfig?.slack_tracking}
              onChange={(e) => handleInputChange('min_reach', e.value)}
            />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

export default SlackTracking;
