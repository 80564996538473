import React, { useState } from 'react';

import { EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const OneLineEditor = ({ onChangeHTML, placeholder, wrapperClassName, editorClassName }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const MAX_LENGTH = 128;

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    let text = newEditorState.getCurrentContent().getPlainText();
    //Convert html to plain text for one liner
    onChangeHTML(text);
  };
  const checkInputLength = (input) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    if (currentContentLength + input.length > MAX_LENGTH - 1) {
      return 'handled';
    }
  };
  return (
    <Editor
      placeholder={placeholder}
      editorState={editorState}
      toolbarHidden={true}
      wrapperClassName={wrapperClassName}
      editorClassName={editorClassName}
      onEditorStateChange={onEditorStateChange}
      handleReturn={() => true}
      handleBeforeInput={checkInputLength}
      handlePastedText={checkInputLength}
    />
  );
};

OneLineEditor.propTypes = {
  onChangeHTML: PropTypes.func,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  editorClassName: PropTypes.string,
};

export default OneLineEditor;
