import {
  API_CALL,
  SAVED_STREAMS_CLEAN,
  SAVED_STREAMS_FETCH_STREAMS,
  SAVED_STREAMS_FILTERS_CLEAN,
  SAVED_STREAMS_FILTERS_SET_END_DATE,
  SAVED_STREAMS_FILTERS_SET_LANGUAGES,
  SAVED_STREAMS_FILTERS_SET_MAX_FOLLOWERS,
  SAVED_STREAMS_FILTERS_SET_MIN_FOLLOWERS,
  SAVED_STREAMS_FILTERS_SET_START_DATE,
  SAVED_STREAMS_FILTERS_SET_TAGS,
  SAVED_STREAMS_SET_PAGE_SIZE,
} from './actionTypes';
import urlSearchParamsBuilder from '../helpers/urlSearchParamsBuilder';

export const savedStreamsFetch = ({ project_id, filters, pagination, isPublicRoute = false, report_id }) => {
  let endpoint = isPublicRoute ? `p/saved_streams?report_id=${report_id}` : `saved_streams?project_id=${project_id}`;
  const savedStreamsUrl = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: SAVED_STREAMS_FETCH_STREAMS,
      endpoint: savedStreamsUrl,
      method: 'GET',
    },
  };
};

export const savedStreamsTableFiltersSetTags = (tags) => ({
  type: SAVED_STREAMS_FILTERS_SET_TAGS,
  payload: tags,
});

export const savedStreamsTableFiltersSetLanguages = (languages) => ({
  type: SAVED_STREAMS_FILTERS_SET_LANGUAGES,
  payload: languages,
});

export const savedStreamsTableFiltersSetStartDate = (startDate) => ({
  type: SAVED_STREAMS_FILTERS_SET_START_DATE,
  payload: startDate,
});

export const savedStreamsTableFiltersSetEndDate = (endDate) => ({
  type: SAVED_STREAMS_FILTERS_SET_END_DATE,
  payload: endDate,
});

export const savedStreamsTableFiltersSetMinFollowers = (minSubscribers) => ({
  type: SAVED_STREAMS_FILTERS_SET_MIN_FOLLOWERS,
  payload: minSubscribers,
});

export const savedStreamsTableFiltersSetMinSubscribers = (maxSubscribers) => ({
  type: SAVED_STREAMS_FILTERS_SET_MAX_FOLLOWERS,
  payload: maxSubscribers,
});

export const savedStreamsClean = () => ({
  type: SAVED_STREAMS_CLEAN,
});

export const savedStreamsTableFiltersClean = () => ({
  type: SAVED_STREAMS_FILTERS_CLEAN,
});

export const savedStreamsSetPageSize = (newSize) => ({
  type: SAVED_STREAMS_SET_PAGE_SIZE,
  payload: newSize,
});
