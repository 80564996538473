import { createAction } from 'redux-actions';

import { REQUEST_SAVE_FEED, RECEIVE_SAVE_FEED, REJECT_SAVE_FEED } from './feeds';
import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const REQUEST_WEBSITES = 'REQUEST_WEBSITES';
export const REJECT_WEBSITES = 'REJECT_WEBSITES';
export const RECEIVE_WEBSITES = 'RECEIVE_WEBSITES';
export const WEBSITES_ADD_WEBSITE_DOMAIN = 'WEBSITES@ADD_WEBSITE_DOMAIN';
export const WEBSITES_ADD_WEBSITE_REACH = 'WEBSITES@ADD_REACH';
export const WEBSITES_ADD_WEBSITE_LANGUAGE = 'WEBSITES@ADD_LANGUAGE';
export const WEBSITES_ADD_WEBSITE = 'WEBSITES@ADD_WEBSITE';
export const WEBSITES_ADD_WEBSITE_RECEIVED = 'WEBSITES@ADD_WEBSITE_RECEIVED';
export const WEBSITES_ADD_WEBSITE_FAILED = 'WEBSITESW@ADD_WEBSITE_FAILED';
export const WEBSITES_OPEN_MODAL = 'WEBSITES@OPEN_MODAL';
export const WEBSITES_CLOSE_MODAL = 'WEBSITES@CLOSE_MODAL';
export const WEBSITES_ADD_WEBSITE_FEED = 'WEBSITES@ADD_WEBSITE_FEED';

export const websitesOpenModal = createAction(WEBSITES_OPEN_MODAL);
export const websitesCloseModal = createAction(WEBSITES_CLOSE_MODAL);
export const websitesAddDomain = createAction(WEBSITES_ADD_WEBSITE_DOMAIN);
export const websitesAddReach = createAction(WEBSITES_ADD_WEBSITE_REACH);
export const websitesAddLanguage = createAction(WEBSITES_ADD_WEBSITE_LANGUAGE);
export const websitesAddDomainFeed = createAction(WEBSITES_ADD_WEBSITE_FEED);

export const websitesAddWebsite = ({ domain, language, reach }) => {
  const endpoint = 'websites';
  const requestParams = {
    body: JSON.stringify({ data: { type: 'website', attributes: { domain, reach, language } } }),
  };
  const options = {
    method: 'POST',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  const actions = {
    request: { type: WEBSITES_ADD_WEBSITE, payload: { data: { domain, reach, language } } },
    receive: WEBSITES_ADD_WEBSITE_RECEIVED,
    reject: WEBSITES_ADD_WEBSITE_FAILED,
  };

  return apiFetch(endpoint, actions, requestParams, options);
};

export const websitesAddWebsiteFeed = ({ id, feed, reach, language }) => {
  const endpoint = 'feeds';
  const requestParams = {
    body: JSON.stringify({
      data: {
        type: 'feed',
        attributes: {
          feed,
          language,
          reach,
          website_id: id,
          type: 0,
        },
      },
    }),
  };

  const actions = {
    request: {
      type: REQUEST_SAVE_FEED,
      payload: {
        feed,
        language,
        reach,
        website_id: id,
      },
    },
    receive: RECEIVE_SAVE_FEED,
    reject: REJECT_SAVE_FEED,
  };

  const options = {
    method: 'POST',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  return apiFetch(endpoint, actions, requestParams, options);
};

export const fetchWebsites = (
  params = {
    page: {
      page: 0,
      pageSize: 50,
    },
  },
) =>
  apiFetch(
    'websites',
    {
      request: {
        type: REQUEST_WEBSITES,
        payload: {},
      },
      receive: RECEIVE_WEBSITES,
      reject: REJECT_WEBSITES,
    },
    params,
    {
      method: 'GET',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const fetchWebsite = (websiteId) =>
  apiFetch(
    `websites/${websiteId}`,
    {
      request: {
        type: REQUEST_WEBSITES,
        payload: {},
      },
      receive: RECEIVE_WEBSITES,
      reject: REJECT_WEBSITES,
    },
    {},
    {
      method: 'GET',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
