import React, { useState } from 'react';

import { connect } from 'react-redux';

import { articlesFeedTableFiltersClean, fetchArticlesFeed } from '../../../../actions/articlesFeed';
import ButtonIcon from '../../../../components/common/ButtonIcon';
import translate from '../../../../helpers/translate';
import { getArticlesFeedTableFilters } from '../../../../selectors/feeds/articlesFeed';
import { getCurrentProject } from '../../../../selectors/projects';
import ArticlesFeedFiltersModal from '../Modals/Filters/ArticlesFeedFiltersModal.jsx';

const ArticlesFeedTableFilters = ({ articlesFeedTableFiltersClean, fetchArticlesFeed, project, filters }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClearFilters = () => {
    articlesFeedTableFiltersClean();
    fetchArticlesFeed({
      alert_id: project['alert-id'],
      pagination: { page: 1, sortField: 'pub_date' },
    });
  };

  const isClearButtonVisible = Object.values(filters).filter(Boolean);

  return (
    <>
      {isClearButtonVisible.length ? (
        <ButtonIcon
          icon="clear"
          transform="shrink-3 down-2"
          color="secondary"
          size="sm"
          className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
          onClick={handleClearFilters}
        >
          Clear filters
        </ButtonIcon>
      ) : null}
      <ButtonIcon
        icon="filter"
        transform="shrink-3 down-2"
        color="secondary"
        size="sm"
        className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
        onClick={() => setModalOpen(true)}
      >
        {translate('common.filters')}
      </ButtonIcon>
      <ArticlesFeedFiltersModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getArticlesFeedTableFilters(state),
  project: getCurrentProject(state),
});

export default connect(mapStateToProps, { fetchArticlesFeed, articlesFeedTableFiltersClean })(ArticlesFeedTableFilters);
