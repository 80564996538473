import React from 'react';

import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';

import { savedStreamsTableFiltersSetMinSubscribers } from '../../../../actions/savedStreams';
import translate from '../../../../helpers/translate';

const SavedStreamsFiltersModalBodyMaxFollowers = ({ savedStreamsTableFiltersSetMaxSubscribers }) => (
  <>
    <Label for="minViews">{translate('pages.saved_streams.table.filters.max_followers')}</Label>
    <Input
      type="number"
      onChange={(e) => savedStreamsTableFiltersSetMaxSubscribers(e.target.value)}
      name="maxViews"
      id="maxViews"
      placeholder="10,000"
    />
  </>
);

export default connect(null, { savedStreamsTableFiltersSetMaxSubscribers: savedStreamsTableFiltersSetMinSubscribers })(
  SavedStreamsFiltersModalBodyMaxFollowers,
);
