import { TWITCH_FEED_FILTERS_CLEAR, TWITCH_FEED_FILTERS_SET_END_DATE } from '../../../actions/actionTypes';

const initialState = null;

const endDate = (state = initialState, action) => {
  switch (action.type) {
    case TWITCH_FEED_FILTERS_SET_END_DATE:
      return action.payload;
    case TWITCH_FEED_FILTERS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default endDate;
