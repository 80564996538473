import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import translate from '../../../../helpers/translate';
import ButtonIconWithTooltip from '../../Buttons/ButtonIconWithTooltip/ButtonIconWithTooltip';

const UpgradeSubscriptionFooter = ({
  areAddonsVisible,
  setShowAddons,
  buttonsDisabled,
  checkoutCreateSession,
  customerId,
  isLoading,
  selectedProduct,
}) => (
  <>
    <Row className="d-flex w-100 flex-center m-0">
      <Col xs={6}>
        <ButtonIconWithTooltip
          id="addonsButton"
          aria-label="addonsButton"
          onClick={() => setShowAddons(!areAddonsVisible)}
          className="mt-4 mb-1 upgrade_button"
          text={
            areAddonsVisible
              ? translate('modals.upgrade_subscription.addons.addons_button.addons_visible')
              : translate('modals.upgrade_subscription.addons.addons_button.addons_not_visible')
          }
        />
      </Col>
    </Row>
    {areAddonsVisible ? null : (
      <Row className="d-flex w-100 flex-center m-0">
        <ButtonIconWithTooltip
          id="upgrade-button"
          aria-label="upgradeButton"
          onClick={() => checkoutCreateSession({ selectedProductId: selectedProduct, customerId })}
          className="mt-4 mb-1 upgrade_button"
          disabled={buttonsDisabled || !selectedProduct}
          text={isLoading ? translate('common.loading') : translate('common.upgrade')}
          disabledText="modals.upgrade_subscription.disabled_upgrade"
        />
      </Row>
    )}
  </>
);

UpgradeSubscriptionFooter.propTypes = {
  areAddonsVisible: PropTypes.bool,
  checkoutCreateSession: PropTypes.func,
  selectedProduct: PropTypes.string,
  customerId: PropTypes.string,
  isLoading: PropTypes.bool,
  buttonsDisabled: PropTypes.bool,
};

export default UpgradeSubscriptionFooter;
