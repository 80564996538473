import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';

import UpgradeSubscriptionAddons from './UpgradeSubscriptionAddons';
import UpgradeSubscriptionFooter from './UpgradeSubscriptionFooter';
import UpgradeSubscriptionTiers from './UpgradeSubscriptionTiers';
import { checkoutCreateSession } from '../../../../actions/checkout';
import translate from '../../../../helpers/translate';
import '../../../../assets/scss/theme/Footprints/components/upgrade-subscription/_upgrade-subscription.scss';
import { getProductsProPlan, getProductsStarterPlan } from '../../../../selectors/products';
import {
  getUpgradeSubscriptionSelectedProduct,
  getUpgradeSubscriptionStripeCheckoutError,
  getUpgradeSubscriptionStripeCheckoutIsLoading,
  getUpgradeSubscriptionStripeCheckoutResponse,
} from '../../../../selectors/upgradeSubscription';
import { getUserOrganizationCustomerId } from '../../../../selectors/users';
import getToastedNotification from '../../helpers/toaster.helper';

const UpgradeSubscription = ({
  isOpen,
  data,
  error,
  selectedProduct,
  isEndOfTrial = false,
  buttonsDisabled,
  checkoutCreateSession,
  isLoading,
  onClose,
  customerId,
  starterProducts,
  proProducts,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [areAddonsVisible, setShowAddons] = useState(false);

  useEffect(() => {
    if (data && data.url) {
      window.location.href = data.url;
    }
  }, [data]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  if (error) {
    getToastedNotification({ responseStatusCode: error });
  }

  const onExit = isEndOfTrial ? null : onClose;

  const modalTitle = isEndOfTrial
    ? translate('modals.upgrade_subscription.end_of_trial')
    : translate('modals.upgrade_subscription.level_up');

  const modalCopy = isEndOfTrial
    ? translate('modals.upgrade_subscription.upgrade_end_of_trial')
    : translate('modals.upgrade_subscription.upgrade_level_up');

  const starterBasicPlan = starterProducts.find((item) => item.monthly_price === 24900);
  const proBasicPlan = proProducts.find((item) => item.monthly_price === 49900);

  const getModalBody = areAddonsVisible ? (
    <UpgradeSubscriptionAddons
      customerId={customerId}
      checkoutCreateSession={checkoutCreateSession}
      starterProducts={starterProducts}
      proProducts={proProducts}
      isLoading={isLoading}
    />
  ) : (
    <UpgradeSubscriptionTiers
      customerId={customerId}
      handleShowMore={handleShowMore}
      isEndOfTrial={isEndOfTrial}
      modalCopy={modalCopy}
      modalTitle={modalTitle}
      showMore={showMore}
      onClose={onClose}
      starterBasicPlan={starterBasicPlan}
      proBasicPlan={proBasicPlan}
    />
  );

  return (
    <>
      <Modal isOpen={isOpen} className="fp-modal upgrade-subscription-modal" size="xl" onExit={onExit} toggle={onClose}>
        <ModalBody className="upgrade-subscription">
          <Container className="rounded text-center">
            {!isEndOfTrial ? (
              <div className="d-flex justify-content-end text-gray fs-2 cursor-pointer" onClick={onClose}>
                <span className="text-bold fs-1">X</span>
              </div>
            ) : null}
            <h2 className="mt-3 title">{modalTitle}</h2>
            <Row className="d-flex flex-center">
              <Col sm={6}>
                <div className="text-lighter text-size-medium">
                  {modalCopy}
                  {translate('modals.upgrade_subscription.upgrade_message')}
                </div>
              </Col>
            </Row>
            {getModalBody}
            <UpgradeSubscriptionFooter
              isLoading={isLoading}
              checkoutCreateSession={checkoutCreateSession}
              setShowAddons={setShowAddons}
              customerId={customerId}
              selectedProduct={selectedProduct}
              buttonsDisabled={buttonsDisabled}
              areAddonsVisible={areAddonsVisible}
            />
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: getUpgradeSubscriptionStripeCheckoutResponse(state),
  error: getUpgradeSubscriptionStripeCheckoutError(state),
  isLoading: getUpgradeSubscriptionStripeCheckoutIsLoading(state),
  selectedProduct: getUpgradeSubscriptionSelectedProduct(state),
  customerId: getUserOrganizationCustomerId(state),
  starterProducts: getProductsStarterPlan(state),
  proProducts: getProductsProPlan(state),
});

export default connect(mapStateToProps, { checkoutCreateSession })(UpgradeSubscription);
