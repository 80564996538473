import React from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useEditVideoContext } from './EditVideoContext';
import SavedVideosModalsEditVideoBody from './SavedVideosModalsEditVideoBody.jsx';
import useEditVideo from './useEditVideo';
import { savedVideosFetch } from '../../../../actions/savedVideos';
import translate from '../../../../helpers/translate';
import { getCurrentProjectId } from '../../../../selectors/forms';
import { getSavedVideosFilters, getSavedVideosPagination } from '../../../../selectors/savedCoverage/savedVideos';

const SavedVideosModalsEditVideo = ({
  isOpen,
  onClose,
  videoData,
  projectId,
  savedVideosFetch,
  filters,
  pagination,
}) => {
  const { selectedTag, selectedLanguage, title, description, views, subscribers, country, publishedAt } =
    useEditVideoContext();

  const onEdit = () => {
    onClose();
    savedVideosFetch({
      project_id: projectId,
      filters,
      pagination: { page: pagination.page, sizePerPage: pagination.pageSize, sortField: 'published_at' },
    });
  };

  const mutation = useEditVideo(onEdit);

  const handleMutation = () => {
    const body = { id: videoData.id };

    const fieldsToUpdate = {
      tag_id: selectedTag?.value,
      channel_language: selectedLanguage,
      title,
      description,
      channel_subscribers: subscribers,
      views,
      channel_country: country,
      published_at: publishedAt,
    };

    for (const field in fieldsToUpdate) {
      if (field && fieldsToUpdate[field]) {
        body[field] = fieldsToUpdate[field];
      }
    }

    mutation.mutate(body);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
      <ModalHeader className="fp-modal-header--small">
        {translate('pages.saved_videos.table.edit_video.title')}
      </ModalHeader>
      <ModalBody className="fp-modal-body">
        <SavedVideosModalsEditVideoBody videoData={videoData} />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button size="sm" type="submit" color="primary" name="saveButton" onClick={handleMutation}>
          {mutation.isLoading ? translate('common.loading') : translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  projectId: getCurrentProjectId(state),
  filters: getSavedVideosFilters(state),
  pagination: getSavedVideosPagination(state),
});

export default connect(mapStateToProps, { savedVideosFetch })(SavedVideosModalsEditVideo);
