import { createContext } from 'react';

import { settings } from '../config';

const AppContext = createContext(settings);

export const ProductContext = createContext({ products: [] });

export const AuthWizardContext = createContext({ user: {} });

export const NewReportContext = createContext({ tagsExclude: {} });

export const FootprintsContext = createContext({});

export default AppContext;
