import {
  SUPERSEARCH_CLEAR,
  SUPERSEARCH_CLEAR_TABLE_DATA,
  SUPERSEARCH_FETCH_DATA_PAGINATED,
  SUPERSEARCH_FETCH_DATA_PAGINATED_ERROR,
  SUPERSEARCH_FETCH_DATA_PAGINATED_FAILURE,
  SUPERSEARCH_FETCH_DATA_PAGINATED_SUCCESS
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: [],
  error: null
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case SUPERSEARCH_FETCH_DATA_PAGINATED:
      return { ...state, isLoading: true };
    case SUPERSEARCH_FETCH_DATA_PAGINATED_SUCCESS:
      return { ...state, isLoading: false, response: action.payload };
    case SUPERSEARCH_FETCH_DATA_PAGINATED_ERROR:
    case SUPERSEARCH_FETCH_DATA_PAGINATED_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case SUPERSEARCH_CLEAR_TABLE_DATA:
    case SUPERSEARCH_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default data;
