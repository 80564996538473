import React, { useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import TwitchFeedModalsKeywordBody from './TwitchFeedModalsKeywordBody.jsx';
import TwitchFeedModalsKeywordFooter from './TwitchFeedModalsKeywordFooter.jsx';
import translate from '../../../../helpers/translate';

const TwitchFeedModalsKeyword = ({ isOpen, onClose, project }) => {
  const [keyword, setKeyword] = useState(null);
  const [gameId, setGameId] = useState(null);

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
      <ModalHeader className="fp-modal-header--small">
        {project?.['twitch-search-id']
          ? translate('pages.twitch_feed.edit_feed')
          : translate('pages.twitch_feed.create_feed')}
      </ModalHeader>
      <ModalBody className="fp-modal-body">
        <TwitchFeedModalsKeywordBody keyword={keyword} setKeyword={setKeyword} setGameId={setGameId} />
      </ModalBody>
      <ModalFooter>
        <TwitchFeedModalsKeywordFooter onClose={onClose} keyword={keyword} gameId={gameId} />
      </ModalFooter>
    </Modal>
  );
};

export default TwitchFeedModalsKeyword;
