import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import SavedStreamsModalsEditModalBody from './SavedStreamsModalsEditModalBody.jsx';
import useEditStream from './useEditStream';
import { savedStreamsFetch } from '../../../../actions/savedStreams';
import translate from '../../../../helpers/translate';
import { getCurrentProjectId } from '../../../../selectors/forms';
import { getSavedStreamsFilters, getSavedStreamsPagination } from '../../../../selectors/savedCoverage/savedStreams';

const SavedStreamsModalsEditStream = ({
  isOpen,
  onClose,
  videoData,
  projectId,
  savedStreamsFetch,
  filters,
  pagination,
}) => {
  const [selectedFields, setSelectedFields] = useState({
    tag: videoData.tag,
    language: videoData.language,
    title: videoData.title,
    description: videoData.description,
    followers: videoData.followers,
    published_at: videoData.published_at,
    view_count: videoData.view_count,
  });

  const onEdit = () => {
    onClose();
    savedStreamsFetch({
      project_id: projectId,
      filters,
      pagination: { page: pagination.page, sizePerPage: pagination.pageSize, sortField: 'published_at' },
    });
  };

  const mutation = useEditStream(onEdit);

  const handleMutation = () => {
    const body = { id: videoData.id };

    const fieldsToUpdate = {
      tag_id: selectedFields?.tag?.value,
      language: selectedFields?.language?.value,
      title: selectedFields?.title,
      description: selectedFields?.description,
      followers: selectedFields?.followers,
      view_count: selectedFields?.view_count,
      published_at: selectedFields?.published_at,
    };

    for (const field in fieldsToUpdate) {
      if (field && fieldsToUpdate[field]) {
        body[field] = fieldsToUpdate[field];
      }
    }

    mutation.mutate(body);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
      <ModalHeader className="fp-modal-header--small">
        {translate('pages.saved_streams.modals.edit_stream.title')}
      </ModalHeader>
      <ModalBody className="fp-modal-body">
        <SavedStreamsModalsEditModalBody
          videoData={videoData}
          setSelectedFields={setSelectedFields}
          selectedFields={selectedFields}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button size="sm" type="submit" color="primary" name="saveButton" onClick={handleMutation}>
          {mutation.isLoading ? translate('common.loading') : translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  projectId: getCurrentProjectId(state),
  filters: getSavedStreamsFilters(state),
  pagination: getSavedStreamsPagination(state),
});

export default connect(mapStateToProps, { savedStreamsFetch })(SavedStreamsModalsEditStream);
