import {
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_ERROR,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_SUCCESS,
} from '../actions/actionTypes';

const defaultState = {
  isLoading: false,
  response: [],
  error: null,
};

const countries = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES:
      return { ...state, isLoading: true };
    case FETCH_COUNTRIES_SUCCESS:
      return { ...state, isLoading: false, response: action.payload.data };
    case FETCH_COUNTRIES_ERROR:
    case FETCH_COUNTRIES_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default countries;
