import { clamp } from 'lodash';
import values from 'lodash/values';
import moment from 'moment';
import { connect } from 'react-redux';

import { onChangeFilterTable } from '../../actions/articles';
import TableFilters from '../../components/ICO/tables/TableFilters';
import { convertItemsForSelect } from '../../config/api-service';
import { getCountries } from '../../selectors/countries';
import { getTableFiltersFormFieldsById } from '../../selectors/forms';
import { getLanguages } from '../../selectors/languages';
import { getMediaTypesList } from '../../selectors/mediaTypes';
import { getModule } from '../../selectors/modules';
import { getCurrentProject } from '../../selectors/projects';
import { getRegions } from '../../selectors/regions';
import { getArticlesReportLangList, getArticlesReportTagList } from '../../selectors/savedCoverage/articles';
import { getTagOptions } from '../../selectors/tags';

const userInElectronicArtsOrganization = (userOrganisation) => userOrganisation.id === 17;

const getTagsList = (state, ownProps) => {
  let tags;
  if (ownProps.reportId) {
    tags = getArticlesReportTagList(state, ownProps);
  } else {
    tags = values(getTagOptions(state));
  }
  return convertItemsForSelect([{ id: -1, name: 'All tags' }, ...tags]);
};

const getLangsList = (state, ownProps) => {
  let langs;
  if (ownProps.reportId) {
    langs = getArticlesReportLangList(state, ownProps);
  } else {
    langs = values(getLanguages(state));
  }
  return convertItemsForSelect([...langs]);
};

const getRegionsList = (state) => {
  let regions = getRegions(state);
  return convertItemsForSelect([...regions]);
};

const mapStateToProps = (state, ownProps) => {
  const targetTableId = ownProps.targetTableId;
  const module = getModule(state, ownProps.targetTableId);
  const filters = getTableFiltersFormFieldsById(targetTableId)(state);

  const getCountriesList = (state) => {
    let countries = getCountries(state)
      .filter((country) => filters.regions.includes(country.region))
      .map((country) => ({
        ...country,
        id: country['iso-code'] ? country['iso-code'].toLowerCase() : -1,
      }));
    return convertItemsForSelect([...countries]);
  };

  const tags = getTagsList(state, ownProps);
  const isUserInElectronicArtsOrganization = userInElectronicArtsOrganization(state.users.user.organization);
  const mediatypes = getMediaTypesList(state);

  return {
    allTimes: filters.date_range.length === 0,
    tags,
    mediatypes,
    languages: getLangsList(state, ownProps),
    regions: getRegionsList(state),
    countries: getCountriesList(state),
    dateFiltersEnabled: ownProps.dateFiltersEnabled || true,
    langFiltersEnabled: ownProps.langFiltersEnabled || true,
    regionFiltersEnabled: isUserInElectronicArtsOrganization,
    eaFiltersEnabled: isUserInElectronicArtsOrganization,
    tagFiltersEnabled: ownProps.tagFiltersEnabled || true,
    mtFiltersEnabled: ownProps.mtFiltersEnabled || false,
    filters,
    targetTableId,
    currentGlobalProject: getCurrentProject(state),
    pagination: module ? module.pagination : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onChangeFilter: (newFilter, idSelect) => {
    const { filters, targetTableId, pagination, currentGlobalProject } = stateProps;
    const { dispatch } = dispatchProps;
    const newPagination = {
      enabled: true,
      table: targetTableId,
      pagination: {
        page: 1,
        page_size: pagination.page_size,
      },
      alertId: currentGlobalProject['alert-id'] || 0,
    };
    const newFilterFinalValue = newFilter.value === -1 ? [] : [newFilter.value];
    return onChangeFilterTable(filters, dispatch, newFilterFinalValue, idSelect, newPagination);
  },
  onChangeCheckbox: (newFilter) => {
    const { filters, targetTableId, pagination, currentGlobalProject } = stateProps;
    const { dispatch } = dispatchProps;
    if (!newFilter) newFilter = false;
    const newPagination = {
      enabled: true,
      table: targetTableId,
      pagination: {
        page: 1,
        page_size: pagination.page_size,
      },
      alertId: currentGlobalProject['alert-id'] || 0,
    };
    const newFilterFinalValue = newFilter.target.checked;
    return onChangeFilterTable(filters, dispatch, newFilterFinalValue, 'ea_only', newPagination);
  },
  onChangeMultiFilter: (newFilter, idSelect) => {
    const { filters, targetTableId, pagination, currentGlobalProject } = stateProps;
    const { dispatch } = dispatchProps;
    if (!newFilter) newFilter = [];
    const newPagination = {
      enabled: true,
      table: targetTableId,
      pagination: {
        page: 1,
        page_size: pagination.page_size,
      },
      alertId: currentGlobalProject['alert-id'] || 0,
    };
    const newFilterFinalValue = newFilter.value === -1 ? [] : newFilter.map(({ value }) => value);
    return onChangeFilterTable(filters, dispatch, newFilterFinalValue, idSelect, newPagination);
  },
  onChangeSliderFilter: ([scoreMin, scoreMax]) => {
    const { filters, targetTableId, pagination, currentGlobalProject } = stateProps;
    const { dispatch } = dispatchProps;
    const clampedScoreMin = clamp(scoreMin, 0, 10);
    const clampedScoreMax = clamp(scoreMax, 0, 10);
    const newPagination = {
      enabled: true,
      table: targetTableId,
      pagination: {
        page: 1,
        page_size: pagination.page_size,
      },
      alertId: currentGlobalProject['alert-id'] || 0,
    };
    const newScoresValue = clampedScoreMin === 0 && clampedScoreMax === 10 ? [] : [clampedScoreMin, clampedScoreMax];
    return onChangeFilterTable(filters, dispatch, newScoresValue, 'score_range', newPagination);
  },
  onChangeDateFilter: (_, picker) => {
    const { filters, targetTableId, pagination, currentGlobalProject } = stateProps;
    const { dispatch } = dispatchProps;
    const newPagination = {
      enabled: true,
      table: targetTableId,
      pagination: {
        page: 1,
        page_size: pagination.page_size,
      },
      alertId: currentGlobalProject['alert-id'] || 0,
    };
    const newRangeFilter =
      !picker.startDate || !picker.endDate ? [] : [moment(picker.startDate).format(), moment(picker.endDate).format()];
    return onChangeFilterTable(filters, dispatch, newRangeFilter, 'date_range', newPagination);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TableFilters);
