import { createAction } from 'redux-actions';

import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const CHANGE_ORGANIZATION_NAME = 'CHANGE_ORGANIZATION_NAME';
export const REQUEST_POST_ORG = 'REQUEST_POST_ORG';
export const RECEIVE_POST_ORG = 'RECEIVE_POST_ORG';
export const REJECT_POST_ORG = 'REJECT_POST_ORG';
export const REQUEST_SAVE_LOGO = 'REQUEST_SAVE_LOGO';
export const REJECT_SAVE_LOGO = 'REJECT_SAVE_LOGO';
export const RECEIVE_SAVE_LOGO = 'RECEIVE_SAVE_LOGO';

export const changeOrganizationName = createAction(CHANGE_ORGANIZATION_NAME);

export const editOrganizationPermission = ({ id, action, organizationId }) => {
  const users = [{ id, action }];
  const endpoint = `organizations/${organizationId}`;

  return apiFetch(
    endpoint,
    {
      request: {
        type: REQUEST_POST_ORG,
        payload: users,
      },
      receive: RECEIVE_POST_ORG,
      reject: REJECT_POST_ORG,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'organizationPermission',
          attributes: {
            users,
          },
        },
      }),
    },
    {
      method: 'PATCH',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
};

export const saveOrganizationLogo = ({ images, scope }) => {
  const data = new FormData();
  data.append('image', images[0]);
  data.append('scope', scope);
  data.append('description', 'description || ');

  return apiFetch(
    'f/av',
    {
      request: {
        type: REQUEST_SAVE_LOGO,
        payload: data,
      },
      receive: RECEIVE_SAVE_LOGO,
      reject: REJECT_SAVE_LOGO,
    },
    {
      body: data,
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      privatePath: true,
    },
  );
};
