import React, { useContext } from 'react';

import classNames from 'classnames';
import { Navbar } from 'reactstrap';

import ICOTopNavRightSideNavItem from './ICOTopNavRightSideNavItem';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../../config';
import AppContext from '../../../context/Context';

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu, isTopNav, isVertical, isCombo, navbarCollapsed, setNavbarCollapsed } =
    useContext(AppContext);
  const handleBurgerMenu = () => {
    isTopNav && !isCombo && setNavbarCollapsed(!navbarCollapsed);
    (isCombo || isVertical) && setShowBurgerMenu(!showBurgerMenu);
  };

  return (
    <Navbar
      light
      expand={isTopNav && topNavbarBreakpoint}
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
    >
      <div
        className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
          'd-lg-none': isTopNav && !isCombo,
          [`d-${navbarBreakPoint}-none`]: isVertical || isCombo,
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <ICOTopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
