import React from 'react';

import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

import { extractDomain, extractFavicon } from '../../../config/url-utils';
import translate from '../../../helpers/translate';
import { getOrganizationPermissionsHasSimilarWeb } from '../../../selectors/permissions';
import { getArticles } from '../../../selectors/savedCoverage/articles';
import { langFormatter } from '../tables/ArticlesFeedTable';
import { ScoreFormatter } from '../tables/ArticlesReportTable';
import { MUVFormatter } from '../tables/helpers/formatters';

const PreviewModal = ({ article, isFeed, userHasSimilarWeb }) => {
  const { description, reach, language, url, fullUrl, source, html, muv } = article;

  const date = dayjs(article['pub-date']).format('YYYY-MM-DD') ?? dayjs(article['date']).format('YYYY-MM-DD');
  const renderPreviewFeedContent = () => {
    if (description) {
      return <div className="fp-modal-body-preview text-gray">{description}</div>;
    } else {
      return (
        <Col className="col-md-offset-4 margin-top-10">
          <div className="fp-modal-body-not-found alert alert-info col-md-12 text-center text-medium">
            No preview was found for this article.
            <br />
          </div>
        </Col>
      );
    }
  };
  const renderPreviewCoverageContent = () => {
    if (html) {
      return <p className="preview" dangerouslySetInnerHTML={{ __html: html }} />;
    } else {
      return (
        <Col className="col-md-offset-4">
          <div className="fp-modal-body-not-found alert col-md-12 text-center">
            No preview was found for this article.
            <br />
          </div>
        </Col>
      );
    }
  };

  return (
    <div>
      <Row>
        <Col md={10} className="font-weight-bold text-left">
          <img style={{ marginRight: '10px' }} alt={''} src={extractFavicon(url)} />
          {source === 1 && <FontAwesomeIcon icon={faTwitter} className="mr-1" />}
          <a className="fs--1" target="_blank" rel="noopener noreferrer" id="articleLink" href={fullUrl || url}>
            <span className="fs--1">{extractDomain(url)}</span>
            <FontAwesomeIcon icon="link" className="ml-1" />
          </a>
          <UncontrolledTooltip placement="top" target="articleLink">
            {translate('modals.articles.view_original_article')}
          </UncontrolledTooltip>
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <span className="fs--1 label label-striped border-left-grey text-gray">{date ?? 'Date not found'}</span>
        </Col>
      </Row>
      <Row>
        <Col md={10} className="d-flex flex-row">
          {userHasSimilarWeb ? muv && <div className="mr-1">{MUVFormatter(muv)}</div> : null}
          {reach && <div>{ScoreFormatter(reach)}</div>}
          {language && <div className="ml-1">{langFormatter(language)}</div>}
        </Col>
      </Row>
      <hr className="divider" />
      <Row>{isFeed ? renderPreviewFeedContent() : renderPreviewCoverageContent()}</Row>
    </div>
  );
};

PreviewModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  date: PropTypes.string,
  language: PropTypes.string,
  reach: PropTypes.number,
  url: PropTypes.string,
  isFeed: PropTypes.bool,
  article: PropTypes.shape({
    html: PropTypes.string,
    'inserted-at': PropTypes.string,
    description: PropTypes.string,
    reach: PropTypes.number,
    language: PropTypes.string,
    url: PropTypes.string,
    fullUrl: PropTypes.string,
    source: PropTypes.string,
  }),
};

PreviewModal.defaultProps = { title: '[Title]' };

const mapStateToProps = (state, ownProps) => {
  const userHasSimilarWeb = getOrganizationPermissionsHasSimilarWeb(state);
  let article = ownProps.article;
  if (!ownProps.isFeed) {
    const articles = getArticles(state);
    article = articles[ownProps.contentId] || {};
  }
  return {
    article,
    isFeed: ownProps.isFeed,
    userHasSimilarWeb,
  };
};

export default connect(mapStateToProps)(PreviewModal);
