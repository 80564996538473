import { apiFetch, defaultRequestOptions, requestNoTokenOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

/***************************************************************************
 * USER PROFILE
 ***************************************************************************/
export const REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE';
export const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE';
export const REJECT_USER_PROFILE = 'REJECT_USER_PROFILE';

/***************************************************************************
 * INVITES ORGS
 ***************************************************************************/
export const REQUEST_INVITE = 'REQUEST_INVITE';
export const RECEIVE_INVITE = 'RECEIVE_INVITE';
export const REJECT_INVITE = 'REJECT_INVITE';

export const REQUEST_POST_INVITE = 'REQUEST_POST_INVITE';
export const RECEIVE_POST_INVITE = 'RECEIVE_POST_INVITE';
export const REJECT_POST_INVITE = 'REJECT_POST_INVITE';

export const REQUEST_POST_USER = 'REQUEST_POST_USER';
export const RECEIVE_POST_USER = 'RECEIVE_POST_USER';
export const REJECT_POST_USER = 'REJECT_POST_USER';

export const REQUEST_EDIT_USER = 'REQUEST_EDIT_USER';
export const RECEIVE_EDIT_USER = 'RECEIVE_EDIT_USER';
export const REJECT_EDIT_USER = 'REJECT_EDIT_USER';

/***************************************************************************
 * USER LOGOUT
 ***************************************************************************/
export const REQUEST_USER_LOGOUT = 'REQUEST_USER_LOGOUT';
export const RECEIVE_USER_LOGOUT = 'RECEIVE_USER_LOGOUT';

/***************************************************************************
 * USER LOGIN
 ***************************************************************************/
export const REQUEST_USER_LOGIN = 'REQUEST_USER_LOGIN';
export const requestUserLogin = () => ({
  type: REQUEST_USER_LOGIN,
});

export const REJECT_USER_LOGIN = 'REJECT_USER_LOGIN';
export const RECEIVE_USER_LOGIN = 'RECEIVE_USER_LOGIN';

export const REQUEST_SLACK_AUTH = 'REQUEST_SLACK_AUTH';
export const RECEIVE_SLACK_AUTH = 'RECEIVE_SLACK_AUTH';
export const REJECT_SLACK_AUTH = 'REJECT_SLACK_AUTH';

export const REQUEST_USER_DEFAULT_PROJECT = 'REQUEST_USER_DEFAULT_PROJECT';
export const RECEIVE_USER_DEFAULT_PROJECT = 'RECEIVE_USER_DEFAULT_PROJECT';
export const REJECT_USER_DEFAULT_PROJECT = 'REJECT_USER_DEFAULT_PROJECT';

export const receiveUserLogin = (user) => ({
  type: RECEIVE_USER_LOGIN,
  user,
  receivedAt: Date.now(),
});

export const fetchCurrentUser = () =>
  apiFetch('user', {
    request: REQUEST_USER_PROFILE,
    receive: RECEIVE_USER_PROFILE,
    reject: REJECT_USER_PROFILE,
  });

export const fetchInvite = (inviteId = -1) =>
  apiFetch(
    'ivs/' + inviteId, //We will automatically use public url
    {
      request: REQUEST_INVITE,
      receive: RECEIVE_INVITE,
      reject: REJECT_INVITE,
    },
  );

export const postInvite = () =>
  apiFetch(
    'invite', //We will automatically use public url
    {
      request: REQUEST_POST_INVITE,
      receive: RECEIVE_POST_INVITE,
      reject: REJECT_POST_INVITE,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'invite',
          attributes: {},
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const postNewUser = (
  data = {
    name: 'user_name',
    inviteKey: 'key',
    picture: 'pictureurl',
  },
) =>
  apiFetch(
    'user', //We will automatically use public url
    {
      request: REQUEST_POST_USER,
      receive: RECEIVE_POST_USER,
      reject: REJECT_POST_USER,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'new_user',
          attributes: {
            invite_key: data.inviteKey,
            name: data.name,
            picture: data.picture || '',
            password: data.confirmPassword,
            email: data.email,
            roles: null,
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: requestNoTokenOptions().headers,
    },
  );

export const saveUserSettings = ({ id, userData }) => {
  const requestURL = `user/${id}`;
  const requestBody = JSON.stringify({
    data: {
      type: 'user',
      attributes: {
        name: userData.name,
        avatar: userData.avatar,
      },
    },
  });

  const requestHeader = {
    method: 'PATCH',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  return apiFetch(
    requestURL,
    {
      request: {
        type: REQUEST_EDIT_USER,
        payload: { name: userData.name, avatar: userData.avatar },
      },
      receive: RECEIVE_EDIT_USER,
      reject: REJECT_EDIT_USER,
    },
    {
      body: requestBody,
    },
    requestHeader,
  );
};

export const setUserDefaultProject = ({ userId, projectId }) => {
  const requestURL = `user/${userId}`;
  const requestBody = JSON.stringify({
    data: {
      type: 'new_user',
      relationships: {
        project: {
          data: {
            type: 'project',
            id: projectId,
          },
        },
      },
    },
  });

  const requestHeader = {
    method: 'PATCH',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  return apiFetch(
    requestURL,
    {
      request: {
        type: REQUEST_USER_DEFAULT_PROJECT,
        payload: { defaultProject: projectId },
      },
      receive: RECEIVE_USER_DEFAULT_PROJECT,
      reject: REJECT_USER_DEFAULT_PROJECT,
    },
    {
      body: requestBody,
    },
    requestHeader,
  );
};
