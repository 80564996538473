import React, { Fragment, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Input, Spinner, Tooltip } from 'reactstrap';

import { postInvite } from '../../../actions/users';
import { STATUS } from '../../../config/api-service';
import { FP_APP_ROOT } from '../../../config/global-config';

const InviteOrg = ({ inviteLink = '', inviteStatus, postInvite }) => {
  const [isOpenTop, setIsOpenTop] = useState(false);
  const [tooltip, setTooltip] = useState('Click to copy');
  const hasLoaded = useRef(false); //To prevent useEffect to loop
  useEffect(() => {
    if (!hasLoaded.current) {
      postInvite();
      hasLoaded.current = true;
    }
  }, [postInvite]);

  if (inviteStatus === STATUS.KO) {
    return (
      <Fragment>
        <Alert color="warning">
          <FontAwesomeIcon glyph={'folder-error'} />
          Error: Failed to fetch invitation link
        </Alert>
      </Fragment>
    );
  }
  if (inviteStatus === STATUS.SAVED) {
    return (
      <div className="alert col-md-12">
        Please share this link for new users to join your organization (click to copy) : <br />
        <br />
        <Input
          bsSize="sm"
          id="linkCopy"
          value={inviteLink}
          onClick={(e) => {
            let copyText = e.target;
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand('copy');
            setTooltip('Copied to clipboard!');
            copyText.setSelectionRange(0, 0);
          }}
          onChange={() => false}
        />
        <Tooltip placement="top" isOpen={isOpenTop} target="linkCopy" toggle={() => setIsOpenTop(!isOpenTop)}>
          {tooltip}
        </Tooltip>
      </div>
    );
  }
  return (
    <div className="alert alert-warning col-md-12">
      <Spinner color={'warning'} /> Fetching invitation link...
    </div>
  );
};

const mapStateToProps = (state) => ({
  inviteLink: FP_APP_ROOT + '/signup/' + state.users.invite.key,
  inviteStatus: state.users.invite.status,
});

InviteOrg.propTypes = {
  inviteLink: PropTypes.string,
  inviteStatus: PropTypes.string,
  postInvite: PropTypes.func,
};

export default connect(mapStateToProps, { postInvite })(InviteOrg);
