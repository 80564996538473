import { SUPERSEARCH_CLEAR, SUPERSEARCH_SET_MAX_SCORE } from '../../../actions/actionTypes';

const defaultState = null;

const minScore = (state = defaultState, action) => {
  switch (action.type) {
    case SUPERSEARCH_SET_MAX_SCORE:
      return action.payload;
    case SUPERSEARCH_CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export default minScore;
