import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const REQUEST_TAGS = 'REQUEST_TAGS';
export const REJECT_TAGS = 'REJECT_TAGS';
export const RECEIVE_TAGS = 'RECEIVE_TAGS';
export const REQUEST_SAVE_TAG = 'REQUEST_SAVE_TAG';
export const REJECT_SAVE_TAG = 'REJECT_SAVE_TAG';
export const RECEIVE_SAVE_TAG = 'RECEIVE_SAVE_TAG';

export const fetchTags = (project_id) => {
  let params = {};
  if (project_id) {
    params = {
      filter: {
        project_id,
      },
    };
  }
  return apiFetch(
    'tags',
    {
      request: REQUEST_TAGS,
      receive: RECEIVE_TAGS,
      reject: REJECT_TAGS,
    },
    params,
  );
};

export const editTag = (
  data = {
    name: 'Test Tag',
    projectId: 2,
    'highlight-date': null,
    locked: false,
    'has-highlight': true,
  },
) => {
  let bodyData = {
    type: 'tag',
    attributes: {
      ...(data.name && { name: data.name }),
      ...(data.locked != null && { locked: data.locked }),
      ...{ highlight_date: data['highlight-date'] },
      ...(data['has_highlight'] !== null && { has_highlight: data['has-highlight'] }),
    },
  };
  if (data.projectId) {
    bodyData.relationships = {
      project: {
        data: {
          type: 'project',
          id: data.projectId,
          highlight_date: data['highlight-date'],
          has_highlight: data['has-highlight'],
        },
      },
    };
  }
  return apiFetch(
    `tags/${data.id}`,
    {
      request: {
        type: REQUEST_SAVE_TAG,
        payload: data,
      },
      receive: RECEIVE_SAVE_TAG,
      reject: REJECT_SAVE_TAG,
    },
    {
      body: JSON.stringify({
        data: bodyData,
      }),
    },
    {
      method: 'PATCH',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
};

export const saveTag = (
  data = {
    name: 'Test Tag',
    projectId: 2,
    highlight_date: null,
    has_highlight: false,
  },
) =>
  apiFetch(
    'projects/1/tags',
    {
      request: {
        type: REQUEST_SAVE_TAG,
        payload: data,
      },
      receive: RECEIVE_SAVE_TAG,
      reject: REJECT_SAVE_TAG,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'tag',
          attributes: {
            name: data.name,
            highlight_date: data['highlight-date'] || null,
            has_highlight: data['has-highlight'],
          },
          relationships: {
            project: {
              data: {
                type: 'project',
                id: data.projectId,
              },
            },
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
