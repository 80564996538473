import React from 'react';

import YoutubeFeedTableActionsColumns from './YoutubeFeedTableActionsColumns.jsx';
import {
  dateFormatter,
  langFormatter,
  youtubeChannelFormatter,
  videosViewFormatter,
} from '../../../components/ICO/tables/helpers/formatters';
import translate from '../../../helpers/translate';
import YoutubeFeedTableVideoPreview from '../YoutubeFeedTableVideoPreview.jsx';

const youtubeFeedTableColumns = [
  {
    dataField: 'thumbnail',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
    formatter: (row, data) => <img src={data.thumbnail} width="150" height="80" alt="Video thumbnail" loading="lazy" />,
  },
  {
    dataField: 'channel.title',
    formatter: (data, row) =>
      youtubeChannelFormatter(row.channel?.title, row.channel?.thumbnail, row.channel?.custom_url),
    text: translate('pages.youtube_feed.table.columns.channel'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'title',
    formatter: (data, videoData) => <YoutubeFeedTableVideoPreview videoData={videoData} withSave={true} />,
    text: translate('pages.youtube_feed.table.columns.title'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'stats[0].views',
    formatter: videosViewFormatter,
    text: translate('pages.youtube_feed.table.columns.views'),
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'channel.language',
    formatter: langFormatter,
    text: 'Language',
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'channel.country',
    formatter: langFormatter,
    text: 'Country',
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'published_at',
    formatter: dateFormatter,
    text: translate('pages.youtube_feed.table.columns.date'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'actions',
    text: translate('pages.youtube_feed.table.columns.actions'),
    formatter: (row, rowData) => <YoutubeFeedTableActionsColumns videoData={rowData} />,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
];

export default youtubeFeedTableColumns;
