import isEqual from 'lodash/isEqual';

const getOrganizationDetailsOriginal = (state) => state.organizationDetails.original.currentOrg;
export const getOrganizationDetailsModified = (state) => state.organizationDetails.modified.currentOrg;

export const getOrganizationDetailsLogo = (state) => getOrganizationDetailsModified(state).avatar;
export const getOrganizationDetailsOrganizationId = (state) => getOrganizationDetailsOriginal(state).id;
export const getOrganizationDetailsIsSlackEnabled = (state) => getOrganizationDetailsOriginal(state).slack_enabled;
export const getOrganizationDetailsModifiedName = (state) => getOrganizationDetailsModified(state).name;
export const getOrganizationDetailsName = (state) => getOrganizationDetailsOriginal(state).name;

export const getOrganizationDetailsIsLoading = (state) =>
  state.organizationDetails.original.currentOrgStatus === 'LOADING';

export const getOrganizationDetailsSaveIsDisabled = (state) => {
  const originalOrg = getOrganizationDetailsOriginal(state);
  const modifiedOrg = getOrganizationDetailsModified(state);
  const dataLoading = getOrganizationDetailsIsLoading(state);
  return dataLoading || !modifiedOrg.name.length || isEqual(originalOrg, modifiedOrg);
};

export const getOrganizationDetailsFormFields = (state) => {
  const name = state.organizationDetails.modified.currentOrg.name;
  const avatar = state.organizationDetails.modified.currentOrg.avatar;

  return {
    name,
    avatar,
  };
};
