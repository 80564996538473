import React from 'react';

import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import SuperSearchFiltersApplyButton from './SuperSearchFiltersApplyButton';
import SuperSearchFiltersDateFrom from './SuperSearchFiltersDateFrom';
import SuperSearchFiltersDateTo from './SuperSearchFiltersDateTo';
import SuperSearchFiltersLanguages from './SuperSearchFiltersLanguages.jsx';
import SuperSearchFiltersMaxScore from './SuperSearchFiltersMaxScore.jsx';
import SuperSearchFiltersMinScore from './SuperSearchFiltersMinScore.jsx';
import SuperSearchFiltersSearchterm from './SuperSearchFiltersSearchterm';
import { superSearchSetSearchTerm } from '../../../actions/superSearch';
import QueryBuilder from '../../../components/ICO/QueryBuilder/QueryBuilder.jsx';

const SuperSearchFilters = ({ superSearchSetSearchTerm }) => (
  <>
    <Row>
      <Col md={8}>
        <QueryBuilder currentInput={<SuperSearchFiltersSearchterm />} stateAction={superSearchSetSearchTerm} />
      </Col>
      <Col md={2}>
        <SuperSearchFiltersDateFrom />
      </Col>
      <Col md={2}>
        <SuperSearchFiltersDateTo />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col md={6}>
        <SuperSearchFiltersLanguages />
      </Col>
      <Col md={3}>
        <SuperSearchFiltersMinScore />
      </Col>
      <Col md={3}>
        <SuperSearchFiltersMaxScore />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col md={12} className="justify-content-end d-flex align-items-end">
        <SuperSearchFiltersApplyButton />
      </Col>
    </Row>
  </>
);

export default connect(null, { superSearchSetSearchTerm })(SuperSearchFilters);
