import React from 'react';

import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';

import { savedStreamsTableFiltersSetMinFollowers } from '../../../../actions/savedStreams';
import translate from '../../../../helpers/translate';

const SavedStreamsFiltersModalBodyMinFollowers = ({ savedStreamsTableFiltersSetMinSubscribers }) => (
  <>
    <Label for="minViews">{translate('pages.saved_streams.table.filters.min_followers')}</Label>
    <Input
      type="number"
      onChange={(e) => savedStreamsTableFiltersSetMinSubscribers(e.target.value)}
      name="minViews"
      id="minViews"
      placeholder="0"
    />
  </>
);

export default connect(null, { savedStreamsTableFiltersSetMinSubscribers: savedStreamsTableFiltersSetMinFollowers })(
  SavedStreamsFiltersModalBodyMinFollowers,
);
