import { TWITCH_FEED_FETCH_FEED_SUCCESS, TWITCH_FEED_CLEAN } from '../../../actions/actionTypes';

const initialState = {
  keyword: null,
  webhook: null,
};

const original = (state = initialState, action) => {
  switch (action.type) {
    case TWITCH_FEED_FETCH_FEED_SUCCESS:
      return { ...state, keyword: action.payload.data.keyword, webhook: action?.payload?.data?.webhook };
    case TWITCH_FEED_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default original;
