import React from 'react';

import { faBan, faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { showModal } from '../../actions/forms';
import { editOrganizationPermission } from '../../actions/organizationDetails';
import { getUsersCurrentUserIsAdmin } from '../../selectors/users';

const DetailsUsersActions = ({ onKick, onPromote, userRole, userIsAdmin }) => (
  <UncontrolledDropdown className="fp-action">
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right positionFixed className="border py-2">
      {userRole !== 'kicked' && (
        <DropdownItem onClick={onKick}>
          <FontAwesomeIcon icon={faBan} /> Remove from Organization
        </DropdownItem>
      )}
      {userIsAdmin && (
        <DropdownItem onClick={onPromote}>
          <FontAwesomeIcon icon={faMedal} /> Promote to Admin
        </DropdownItem>
      )}
    </DropdownMenu>
  </UncontrolledDropdown>
);

DetailsUsersActions.propTypes = {
  user: PropTypes.shape(),
  onPromote: PropTypes.func,
  onKick: PropTypes.func,
  userRole: PropTypes.string,
  userIsAdmin: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  type: 'user',
  canLock: false,
  userRole: ownProps.user.roles,
  userIsAdmin: getUsersCurrentUserIsAdmin(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { user } = ownProps;
  return {
    onKick: () => {
      dispatch(
        showModal({
          content: 'confirmKickAction',
          contentId: user.id,
          contentData: {
            resource: 'user',
            data: user,
            action: editOrganizationPermission({ id: user.id, action: 'kick', organizationId: user.organizationId }),
          },
        }),
      );
    },
    onPromote: () => {
      dispatch(
        showModal({
          content: 'confirmPromoteAction',
          contentId: user.id,
          contentData: {
            resource: 'user',
            data: user,
            action: editOrganizationPermission({ id: user.id, action: 'promote', organizationId: user.organizationId }),
          },
        }),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsUsersActions);
