import React, { useState } from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import YoutubeFeedTableVideoPreviewModal from './Modals/Preview/YoutubeFeedTableVideoPreviewModal.jsx';
import { formatHTMLSymbols } from '../../components/ICO/tables/helpers/formatters';

const YoutubeFeedTableVideoPreview = ({ videoData, withSave = false }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const tooltipId = `tooltip_${videoData.id}`;

  return (
    <>
      <div className="cursor-pointer text-truncate" onClick={() => setModalOpen(true)} style={{ width: '25vw' }}>
        <span id={tooltipId}>{formatHTMLSymbols(videoData.title)}</span>
      </div>
      <UncontrolledTooltip placement="bottom" target={tooltipId}>
        {videoData.title}
      </UncontrolledTooltip>
      <YoutubeFeedTableVideoPreviewModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        videoData={videoData}
        withSave={withSave}
      />
    </>
  );
};

export default YoutubeFeedTableVideoPreview;
