import React from 'react';

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { showModal } from '../../../actions/forms';

const FeedTableActions = ({ article, showModal }) => {
  const handleClick = () => {
    showModal({
      content: 'previewArticle',
      contentId: article.id,
      contentData: { ...article, isFeed: true },
    });
  };

  return (
    <UncontrolledDropdown className="fp-action">
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon={faEllipsisH} className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right className="border py-2">
        <DropdownItem aria-label="preview-dropdown" onClick={handleClick}>
          Preview
        </DropdownItem>
        <a href={article.url || '#'} target={'_new'}>
          <DropdownItem>Open</DropdownItem>
        </a>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

FeedTableActions.propTypes = {
  showModal: PropTypes.func,
  article: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string,
  }),
};

export default connect(null, { showModal })(FeedTableActions);
