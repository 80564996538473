import React, { memo, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { connect } from 'react-redux';

import { newReportGetSavedStreams } from '../../actions/reports';
import { savedStreamsFetch } from '../../actions/savedStreams';
import Table from '../../components/ICO/Table/Table.jsx';
import translate from '../../helpers/translate';
import columns from '../../pages/SavedStreams/Table/columns.jsx';
import {
  getCurrentProjectId,
  getFormsNewReportFormFieldsFrom,
  getFormsNewReportFormFieldsTo,
} from '../../selectors/forms';
import { getReportSelectedLanguages, getReportSelectedTags } from '../../selectors/reports';
import {
  getSavedStreams,
  getSavedStreamsIsLoading,
  getSavedStreamsPagination,
} from '../../selectors/savedCoverage/savedStreams';

const NewReportSavedStreams = ({
  data = [],
  isLoading,
  savedStreamsFetch,
  projectId,
  pagination,
  selectedTags,
  fromDate,
  toDate,
  newReportGetSavedStreams,
  selectedLanguages,
}) => {
  const newReportColumns = columns.filter((columns) => columns.text !== 'Actions');
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    newReportGetSavedStreams({
      projectId,
      filters: {
        tags: selectedTags.map((tag) => tag.value),
        languages: selectedLanguages.map((lang) => lang.value),
        startDate: dayjs(fromDate).format('YYYY-MM-DD'),
        endDate: dayjs(toDate).format('YYYY-MM-DD'),
      },
      pagination: {
        page: 1,
        sizePerPage: 5000,
        sortField: 'published_at',
      },
    });
  }, [newReportGetSavedStreams, selectedTags, projectId, fromDate, toDate, selectedLanguages]);

  const onTableChange = (type, { searchText, sizePerPage, page, sortField, sortOrder }) => {
    if (type === 'search' && searchText) {
      savedStreamsFetch({
        project_id: projectId,
        filters: {
          tags: selectedTags.map((tag) => tag.value),
          languages: selectedLanguages.map((lang) => lang.value),
          searchTerm: searchText,
          startDate: dayjs(fromDate).format('YYYY-MM-DD'),
          endDate: dayjs(toDate).format('YYYY-MM-DD'),
        },
        pagination: {
          page,
          sizePerPage,
          sortField,
          sortOrder,
        },
      });
    } else if (searchText) {
      savedStreamsFetch({
        project_id: projectId,
        filters: {
          tags: selectedTags.map((tag) => tag.value),
          languages: selectedLanguages.map((lang) => lang.value),
          startDate: dayjs(fromDate).format('YYYY-MM-DD'),
          endDate: dayjs(toDate).format('YYYY-MM-DD'),
          searchTerm: searchText,
        },
        pagination: {
          page,
          sizePerPage,
          sortField,
          sortOrder,
        },
      });
    } else {
      savedStreamsFetch({
        project_id: projectId,
        filters: {
          tags: selectedTags.map((tag) => tag.value),
          languages: selectedLanguages.map((lang) => lang.value),
          startDate: dayjs(fromDate).format('YYYY-MM-DD'),
          endDate: dayjs(toDate).format('YYYY-MM-DD'),
          searchTerm: searchText,
        },
        pagination: {
          page,
          sizePerPage,
          sortField,
          sortOrder,
        },
      });
    }
  };

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    savedStreamsFetch({
      project_id: projectId,
      filters: {
        tags: selectedTags.map((tag) => tag.value),
        languages: selectedLanguages.map((lang) => lang.value),
        startDate: dayjs(fromDate).format('YYYY-MM-DD'),
        endDate: dayjs(toDate).format('YYYY-MM-DD'),
      },
      pagination: {
        page: pagination.page,
        sizePerPage: newSize,
      },
    });
  };

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  return (
    <Table
      data={data}
      isLoading={isLoading}
      title={translate('reports.streams')}
      columns={newReportColumns}
      onTableChange={onTableChange}
      pagination={paginationWithExtra}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getSavedStreams(state),
  isLoading: getSavedStreamsIsLoading(state),
  projectId: getCurrentProjectId(state),
  pagination: getSavedStreamsPagination(state),
  selectedTags: getReportSelectedTags(state),
  selectedLanguages: getReportSelectedLanguages(state),
  fromDate: getFormsNewReportFormFieldsFrom(state),
  toDate: getFormsNewReportFormFieldsTo(state),
});
export default memo(connect(mapStateToProps, { savedStreamsFetch, newReportGetSavedStreams })(NewReportSavedStreams));
