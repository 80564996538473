/* eslint-disable no-undef*/
const env = process.env.NODE_ENV;

module.exports = {
  ENV: env,
  APP_NAME: process.env.REACT_APP_APP_NAME, //TODO use template here
  ROOT_APP_SELECTOR: process.env.REACT_APP_ROOT_APP_SELECTOR,
  FP_APP_ROOT: process.env.REACT_APP_FP_APP_ROOT,
  APP_ROOT: process.env.REACT_APP_APP_ROOT,
  PUBLIC_PATH: process.env.REACT_APP_PUBLIC_PATH,
  COVERAGE_API_ROOT: process.env.REACT_APP_COVERAGE_API_ROOT,
  AI_ROOT: process.env.REACT_APP_AI_ROOT,
  WEBSITES_API_ROOT: process.env.REACT_APP_WEBSITES_API_ROOT,
  DIFFBOT_CLIENT_API_ROOT: process.env.REACT_APP_DIFFBOT_CLIENT_API_ROOT,
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
  IMAGES_ROOT: process.env.REACT_APP_PROJECTS_IMAGES_ROOT,
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
};
