import { combineReducers } from 'redux';

import data from './data';
import filters from './filters';
import volume from './volume';

const superSearch = combineReducers({
  filters,
  data,
  volume
});

export default superSearch;
