import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import TwitchFeedTableVideoPreviewModal from './Modals/Preview/TwitchFeedTableVideoPreviewModal.jsx';
import { fetchTwitchFeed } from '../../../actions/twitchFeed';
import { getTwitchFeedPagination, getTwitchFeedTableFilters } from '../../../selectors/feeds/twitchFeed';
import { getCurrentProject } from '../../../selectors/projects';
import { getNotLockedTags } from '../../../selectors/tags';
import useDeleteVideo from '../hooks/useDeleteVideo';
import TwitchFeedModalsSaveStreams from '../Modals/SaveStreams/TwitchFeedModalsSaveStreams.jsx';

const TwitchFeedTableActionColumns = ({ videoData, project, fetchTwitchFeed, pagination }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const onDeleteSuccess = () => {
    fetchTwitchFeed({ twitch_search_id: project['twitch-search-id'], pagination: { page: pagination.page } });
  };

  const mutationDelete = useDeleteVideo(onDeleteSuccess);
  const handleClickDeleteVideo = () => {
    mutationDelete.mutate({ videoData: [videoData.video.id], twitchSearchId: project['twitch-search-id'].toString() });
  };

  return (
    <>
      <UncontrolledDropdown className="fp-action">
        <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
          <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
        </DropdownToggle>
        <DropdownMenu right positionFixed className="border py-2">
          <DropdownItem onClick={() => setModalOpen(true)}>Preview stream</DropdownItem>
          <DropdownItem onClick={() => setSaveModalOpen(true)}>Save stream</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={handleClickDeleteVideo} className="text-danger">
            Delete video
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <TwitchFeedTableVideoPreviewModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        videoData={videoData}
        withSave={true}
      />
      <TwitchFeedModalsSaveStreams
        selectedRows={[videoData]}
        isOpen={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getTwitchFeedTableFilters(state),
  project: getCurrentProject(state),
  tags: getNotLockedTags(state),
  pagination: getTwitchFeedPagination(state),
});

export default connect(mapStateToProps, { fetchTwitchFeed })(TwitchFeedTableActionColumns);
