import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const CustomDropDownTotalStatic = (paginationProps) => {
  const { onSizePerPageChange, totalSize, sizePerPage, sizePerPageList, page } = paginationProps;

  const [isOpen, setIsOpen] = useState(false);
  const renderDropdowns = () =>
    sizePerPageList.map((value) => (
      <DropdownItem
        key={value}
        id={value}
        onClick={() => onSizePerPageChange(value, page)}
        active={sizePerPage === value}
      >
        {value}
      </DropdownItem>
    ));

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      Showing
      <DropdownToggle caret size="sm" className="ml-2 mr-3 btn btn-falcon-default">
        {sizePerPage}
      </DropdownToggle>
      of <strong>{totalSize}</strong>
      <DropdownMenu>{renderDropdowns()}</DropdownMenu>
    </Dropdown>
  );
};

CustomDropDownTotalStatic.propTypes = {
  onChange: PropTypes.func,
  totalSize: PropTypes.number,
  sizePerPage: PropTypes.number,
  sizePerPageList: PropTypes.array,
};

CustomDropDownTotalStatic.defaultProps = {
  totalSize: 0,
  sizePerPage: 12,
  sizePerPageList: [12, 25, 50, 100],
};
