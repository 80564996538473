import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import TwitchFeedTable from './Table/TwitchFeedTable.jsx';
import TwitchFeedCreateFeed from './TwitchFeedCreateFeed.jsx';
import { fetchTags } from '../../actions/tags';
import { getCurrentProject } from '../../selectors/projects';

const TwitchFeed = ({ project, fetchTags }) => {
  useEffect(() => {
    fetchTags(project.id);
  }, [fetchTags, project]);

  return !project['twitch-search-id'] ? (
    <TwitchFeedCreateFeed project={project} />
  ) : (
    <TwitchFeedTable project={project} />
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
});

export default connect(mapStateToProps, { fetchTags })(TwitchFeed);
