import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Col, Row } from 'reactstrap';

import BrandsTable from './BrandsTable';
import { fetchClients } from '../../actions/clients';
import { resetForm, showModal } from '../../actions/forms';
import { fetchProjects } from '../../actions/projects';

const Brands = ({ fetchClients, fetchProjects, resetForm, showModal }) => {
  useEffect(() => {
    fetchClients();
    fetchProjects();
  }, [fetchClients, fetchProjects]);

  const urlParams = useParams();

  const handleClickNewForm = () => {
    showModal({ content: 'newClient' });
    resetForm();
  };

  return (
    <Fragment>
      <Row noGutters>
        <Col lg={12} className="mb-3 pr-lg-2 mb-3">
          <BrandsTable
            urlParams={urlParams}
            title="Brands"
            tableId="clientsTable"
            rowsType="clients"
            newButton={true}
            newButtonClick={handleClickNewForm}
            archiveSelection={true}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

Brands.propTypes = {
  fetchProjects: PropTypes.func,
  fetchClients: PropTypes.func,
  resetForm: PropTypes.func,
  showModal: PropTypes.func,
};

export default connect(null, { fetchProjects, fetchClients, resetForm, showModal })(Brands);
