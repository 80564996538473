import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { fetchYoutubeFeed, youtubeFeedSaveVideosShowModal } from '../../../actions/youtubeFeed';
import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';
import { getYoutubeFeedPagination } from '../../../selectors/feeds/youtubeFeed';
import { getOrganizationId } from '../../../selectors/organizations';
import { getCurrentProject } from '../../../selectors/projects';
import YoutubeFeedTableVideoPreviewModal from '../../YoutubeFeed/Modals/Preview/YoutubeFeedTableVideoPreviewModal.jsx';
import YoutubeFeedSaveVideo from '../Modals/BulkActions/YoutubeFeedSaveVideo.jsx';

const YoutubeFeedTableActionsColumns = ({ videoData, project, fetchYoutubeFeed, userOrganizationId, pagination }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);

  const handleClickDeleteVideo = async () => {
    const url = `${COVERAGE_API_ROOT}/youtube_feed`;
    const requestConfig = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        videos: [videoData.id],
        keyword_id: project['youtube-search-id'].toString(),
      }),
    };

    const request = await fetch(url, requestConfig);

    if (!request.ok) {
      getToastedNotification({ responseStatusCode: request.status });
    } else {
      const customMessage = {
        type: 'success',
        message: translate('pages.youtube_feed.notifications.delete_videos_success'),
      };
      getToastedNotification({ customMessage });
      fetchYoutubeFeed({
        youtube_search_id: project['youtube-search-id'],
        pagination: { page: pagination.page, sizePerPage: 10 },
        filters: {
          organization_id: userOrganizationId,
        },
      });
    }
  };

  return (
    <>
      <UncontrolledDropdown className="fp-action">
        <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
          <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
        </DropdownToggle>
        <DropdownMenu right positionFixed className="border py-2">
          <DropdownItem onClick={() => setModalOpen(true)}>View video</DropdownItem>
          <DropdownItem onClick={() => setSaveModalOpen(true)} className="text-primary">
            Save video
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={handleClickDeleteVideo} className="text-danger">
            Delete video
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <YoutubeFeedTableVideoPreviewModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        videoData={videoData}
        withSave={true}
      />
      <YoutubeFeedSaveVideo
        selectedRows={[videoData.id]}
        isOpen={isSaveModalOpen}
        onClose={() => setSaveModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  userOrganizationId: getOrganizationId(state),
  pagination: getYoutubeFeedPagination(state),
});

export default connect(mapStateToProps, { fetchYoutubeFeed, youtubeFeedSaveVideosShowModal })(
  YoutubeFeedTableActionsColumns,
);
