import React, { useState } from 'react';

import { Button, Col, Row } from 'reactstrap';

import { langFormatter, videosViewFormatter } from '../../../../components/ICO/tables/helpers/formatters';
import translate from '../../../../helpers/translate';
import YoutubeFeedSaveVideo from '../../../YoutubeFeed/Modals/BulkActions/YoutubeFeedSaveVideo.jsx';

const YoutubeSavedVideosTableVideoPreviewModalFooter = ({ videoData, onClose, withSave }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const closeButtonStyle = withSave ? 'fp-button-close mr-3' : 'fp-button-close';

  const renderVideoStats = () => {
    if (videoData) {
      if (videoData.stats) {
        return videosViewFormatter(videoData.stats[0].views);
      }

      if (videoData.views) {
        return videosViewFormatter(videoData.views);
      }
    }

    return null;
  };

  return (
    <Row className="d-flex flex-row w-100 justify-content-around">
      <Col xs={3} className="d-flex flex-row">
        <span className="fs-0">{renderVideoStats()}</span>
        <span className="ml-2 fs-0">{langFormatter(videoData.channel_country)}</span>
      </Col>
      <Col xs={9} className="justify-content-end d-flex">
        <Button size="sm" onClick={onClose} className={closeButtonStyle}>
          {translate('common.close')}
        </Button>
        {withSave ? (
          <Button size="sm" onClick={() => setModalOpen(true)} className="fp-button" color="primary">
            {translate('common.save')}
          </Button>
        ) : null}
      </Col>
      <YoutubeFeedSaveVideo selectedRows={[videoData.id]} isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </Row>
  );
};

export default YoutubeSavedVideosTableVideoPreviewModalFooter;
