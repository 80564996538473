import React from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { fetchProjects } from '../../../../actions/projects';
import translate from '../../../../helpers/translate';
import {
  getYoutubeFeedSlackWebhookMinSubscribers,
  getYoutubeFeedSlackWebhookURL,
  getYoutubeFeedDiscordWebhookURL,
  getYoutubeFeedDiscordWebhookMinSubscribers,
} from '../../../../selectors/feeds/youtubeFeed';
import { getCurrentProjectId } from '../../../../selectors/forms';
import { getProject } from '../../../../selectors/projects';
import useCreateKeyword from '../../hooks/useCreateKeyword';

const YoutubeFeedKeywordModalFooter = ({
  onClose,
  keyword,
  project,
  slackWebhookUrl,
  slackMinSubsNotification,
  discordWebhookUrl,
  discordMinSubsNotification,
  fetchProjects,
}) => {
  const isButtonDisabled = keyword?.length < 3;
  const projectHasKeyword = project['youtube-search-id'];

  const onCreateSuccess = () => {
    onClose();
    fetchProjects();
  };

  const mutation = useCreateKeyword(onCreateSuccess);
  const onClick = () => {
    let params = { keyword, projectId: project.id };

    if (slackWebhookUrl) {
      params = { ...params, slack_webhook_url: slackWebhookUrl };
    }

    if (slackMinSubsNotification) {
      params = { ...params, slack_min_subs_notification: slackMinSubsNotification };
    }

    if (discordWebhookUrl) {
      params = { ...params, discord_webhook_url: discordWebhookUrl };
    }

    if (discordMinSubsNotification) {
      params = { ...params, discord_min_subs_notification: discordMinSubsNotification };
    }

    mutation.mutate(params);
    onClose();
  };

  return (
    <>
      <Button size="sm" onClick={onClose} className="fp-button-close">
        {translate('common.close')}
      </Button>
      <Button
        size="sm"
        type="submit"
        onClick={onClick}
        aria-label="save-button"
        color="primary"
        name="saveButton"
        disabled={isButtonDisabled}
      >
        {projectHasKeyword ? translate('common.update') : translate('common.save')}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => {
  const currentProjectId = getCurrentProjectId(state);
  return {
    project: getProject(state, currentProjectId),
    slackWebhookUrl: getYoutubeFeedSlackWebhookURL(state),
    slackMinSubsNotification: getYoutubeFeedSlackWebhookMinSubscribers(state),
    discordWebhookUrl: getYoutubeFeedDiscordWebhookURL(state),
    discordMinSubsNotification: getYoutubeFeedDiscordWebhookMinSubscribers(state),
  };
};

export default connect(mapStateToProps, { fetchProjects })(YoutubeFeedKeywordModalFooter);
