import React, { useState } from 'react';

import { connect } from 'react-redux';

import { savedVideosFetch, savedVideosTableFiltersClean } from '../../../../actions/savedVideos';
import ButtonIcon from '../../../../components/common/ButtonIcon';
import translate from '../../../../helpers/translate';
import { getCurrentProject } from '../../../../selectors/projects';
import { getSavedVideosFilters, getSavedVideosPageSize } from '../../../../selectors/savedCoverage/savedVideos';
import SavedVideosFiltersModal from '../../Modals/Filters/SavedVideosFiltersModal.jsx';
import SavedVideosAddVideoButton from '../SavedVideosAddVideoButton.jsx';

const SavedVideosTableFilters = ({
  savedVideosTableFiltersClean,
  savedVideosFetch,
  project,
  filters,
  sizePerPage = 10,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClearFilters = () => {
    savedVideosTableFiltersClean();
    savedVideosFetch({ project_id: project.id, pagination: { sizePerPage, page: 1, sortField: 'published_at' } });
  };

  const isClearButtonVisible = Object.values(filters).filter(Boolean);

  return (
    <>
      <SavedVideosAddVideoButton />
      {isClearButtonVisible.length ? (
        <ButtonIcon
          icon="clear"
          transform="shrink-3 down-2"
          color="secondary"
          size="sm"
          className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
          onClick={handleClearFilters}
        >
          Clear filters
        </ButtonIcon>
      ) : null}
      <ButtonIcon
        icon="filter"
        transform="shrink-3 down-2"
        color="secondary"
        size="sm"
        className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
        onClick={() => setModalOpen(true)}
      >
        {translate('common.filters')}
      </ButtonIcon>
      <SavedVideosFiltersModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getSavedVideosFilters(state),
  project: getCurrentProject(state),
  sizePerPage: getSavedVideosPageSize(state),
});

export default connect(mapStateToProps, { savedVideosFetch, savedVideosTableFiltersClean })(SavedVideosTableFilters);
