import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { Media } from 'reactstrap';

import SidePanelProjectActions from './SidePanelProjectsActions';
import { IMAGES_ROOT } from '../../../config/global-config';
import Avatar from '../../common/Avatar';

const ChangeProjectButton = ({ project, isActive, onClick, isDefaultProject }) => {
  const location = useLocation();
  const firstPath = location.pathname.split('/')[1];
  const pathsWithoutProjectId = ['websites', 'settings', 'organization-details', ''];
  const changeProjectIdInURL = !pathsWithoutProjectId.includes(firstPath);

  const renderProjectData = (
    <>
      <h5 className="fs-0">{project.name}</h5>
      <p className="mb-0">{project.client.name}</p>
    </>
  );

  return (
    <>
      <Media
        onClick={() => onClick(project.id)}
        className={classNames('cursor-pointer project-select hover-actions-trigger', {
          active: isActive,
        })}
      >
        {project.avatar ? (
          <Avatar size="2xl" width={20} className="mr-3" src={IMAGES_ROOT + project.avatar} />
        ) : (
          <div className="avatar avatar-2xl mr-3">
            <div className="avatar-name rounded-circle bg-soft-primary">
              <span className="fs-0 text-primary">{project.name[0]}</span>
            </div>
          </div>
        )}
        <Media body>
          {changeProjectIdInURL ? (
            <NavLink
              style={{ textDecoration: 'none' }}
              {...{ to: changeProjectIdInURL ? '/' + firstPath + '/' + project.id : location.pathname }}
            >
              {renderProjectData}
            </NavLink>
          ) : (
            <>{renderProjectData}</>
          )}
        </Media>
      </Media>
      <SidePanelProjectActions isDefaultProject={isDefaultProject} isActive={isActive} project={project} />
    </>
  );
};

ChangeProjectButton.propTypes = {
  project: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  isDefaultProject: PropTypes.bool,
};

export default ChangeProjectButton;
