import React from 'react';

import uuid from 'uuid/v1';

import translate from '../translate';

export const getTranslation = (text) => translate(`modals.upgrade_subscription.${text}`);

export const featureFormatter = (setOfFeatures) => (
  <ul className="feature-list" key={uuid()}>
    {setOfFeatures.map((feature) => (
      <li className="mt-4 text-secondary" key={uuid()}>
        {getTranslation(feature)}
      </li>
    ))}
  </ul>
);

export const comingSoonFeaturesFormatter = (setOfFeatures) => (
  <ul className="feature-list" key={uuid()}>
    {setOfFeatures.map((feature) => [
      <li className="mt-3 mb-1 text-secondary" key={uuid()}>
        {getTranslation(feature)}
      </li>,
      <li className="mt-1 text-info" key={uuid()}>
        {getTranslation('agency.coming_soon')}
      </li>,
    ])}
  </ul>
);
