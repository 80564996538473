import React from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

import { DEBOUNCE_TIME } from '../../../constants';
import translate from '../../../helpers/translate';

const SearchBar = ({ onSearch }) => {
  const handleChange = debounce((value) => onSearch(value), DEBOUNCE_TIME);

  return (
    <Label for="search-bar" className="search-label">
      <Input
        onChange={(event) => handleChange(event.target.value)}
        id="search-bar"
        name="search-bar"
        type="text"
        className="form-control rounded-pill"
        placeholder={translate('components.table.searchbar.placeholder')}
      />
    </Label>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func,
};

export default SearchBar;
