import React, { useState } from 'react';

import { faCheck, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import translate from '../../../helpers/translate';

export const ArchiveFilterSelect = ({ onChange, useArchive }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      inNavbar={true}
      className="position-relative d-none d-md-inline-block"
    >
      <DropdownToggle caret size="sm" className="ml-2 btn text-uppercase fp-button" color="secondary">
        {useArchive ? (
          <>
            <FontAwesomeIcon icon={faFolder} /> {translate('dropdowns.archived')}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCheck} /> {translate('dropdowns.active')}
          </>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onChange(false)} active={!useArchive}>
          <FontAwesomeIcon icon={faCheck} /> {translate('dropdowns.active')}
        </DropdownItem>
        <DropdownItem onClick={() => onChange(true)} active={useArchive}>
          <FontAwesomeIcon icon={faFolder} /> {translate('dropdowns.archived')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

ArchiveFilterSelect.propTypes = {
  onChange: PropTypes.func,
  useArchive: PropTypes.bool,
};
