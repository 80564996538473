import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getReport } from '../../../selectors/reports';
import { getArticlesFilteredStats } from '../../../selectors/savedCoverage/articles';
import SingleMetric from '../text-display/SingleMetric.js';

const ArticleMetric = ({ reportId }) => {
  const count = useSelector((state) =>
    reportId ? getReport(state, reportId)['total-articles'] : getArticlesFilteredStats(state).total_entries,
  );
  return <SingleMetric count={count} icon={'magazine'} color="articles" title={'Total Articles'} />;
};

ArticleMetric.propTypes = {
  reportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ArticleMetric;
