import { useMutation } from 'react-query';

import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';

const useSaveStream = (onSuccess) =>
  useMutation({
    mutationKey: 'save_stream',
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/twitch_feed/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.text();
      }
    },
    onSuccess: (data) => {
      const customMessage = {
        type: 'success',
        message: data,
      };
      getToastedNotification({ customMessage });
      onSuccess();
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: translate('pages.twitch_feed.notifications.save_stream_error'),
      };
      getToastedNotification({ customMessage });
    },
  });

export default useSaveStream;
