import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import YoutubeFeedTableFiltersModalBody from './YoutubeFeedTableFiltersModalBody.jsx';
import YoutubeFeedTableFiltersModalFooter from './YoutubeFeedTableFiltersModalFooter.jsx';
import translate from '../../../helpers/translate';

const YoutubeFeedTableFiltersModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
    <ModalHeader className="fp-modal-header--small">{translate('pages.youtube_feed.table.filters.title')}</ModalHeader>
    <ModalBody className="fp-modal-body">
      <YoutubeFeedTableFiltersModalBody />
    </ModalBody>
    <ModalFooter>
      <YoutubeFeedTableFiltersModalFooter onClose={onClose} />
    </ModalFooter>
  </Modal>
);
export default YoutubeFeedTableFiltersModal;
