import React from 'react';

import { connect } from 'react-redux';
import Label from 'reactstrap/lib/Label';

import { superSearchSetLanguages } from '../../../actions/superSearch';
import MultiSelect from '../../../components/ICO/forms/MultiSelect';
import { getLanguagesValueAndLabel } from '../../../selectors/languages';

const SuperSearchFiltersLanguages = ({ languages, superSearchSetLanguages }) => {
  const handleChange = (selectedLanguage) => {
    const languagesToSet = selectedLanguage?.map((item) => item.value) ?? [];
    superSearchSetLanguages(languagesToSet);
  };

  return (
    <>
      <Label>Languages</Label>
      <MultiSelect
        aria-label="lang-select"
        items={languages}
        placeholder="No languages"
        name="selected-language"
        onChange={handleChange}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  languages: getLanguagesValueAndLabel(state),
});

export default connect(mapStateToProps, { superSearchSetLanguages })(SuperSearchFiltersLanguages);
