import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import columns from './columns.jsx';
import SavedVideosTableFilters from './Filters/SavedVideosTableFilters.jsx';
import SavedVideosTableActions from './SavedVideosTableActions.jsx';
import {
  savedVideosFetch,
  savedVideosClean,
  savedVideosDeleteClean,
  savedVideosTableFiltersClean,
  savedVideosSetPageSize,
} from '../../../actions/savedVideos';
import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import Table from '../../../components/ICO/Table/Table.jsx';
import translate from '../../../helpers/translate';
import { getCurrentProjectId } from '../../../selectors/forms';
import {
  getSavedVideos,
  getSavedVideosDeleteActionError,
  getSavedVideosDeleteActionResponse,
  getSavedVideosFilters,
  getSavedVideosIsLoading,
  getSavedVideosPageSize,
  getSavedVideosPagination,
} from '../../../selectors/savedCoverage/savedVideos';

const SavedVideosTable = ({
  data = [],
  isLoading,
  savedVideosFetch,
  projectId,
  pagination,
  filters,
  savedVideosClean,
  deleteHasError,
  deleteResponse,
  savedVideosDeleteClean,
  sizePerPage,
  savedVideosSetPageSize,
}) => {
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    savedVideosFetch({ project_id: projectId, pagination: { sizePerPage, page: 1, sortField: 'published_at' } });
  }, [savedVideosFetch, projectId, sizePerPage]);

  useEffect(
    () => () => {
      savedVideosClean();
    },
    [savedVideosClean],
  );

  useEffect(() => {
    if (deleteHasError) {
      const customMessage = { type: 'error', message: 'Something went wrong.' };
      getToastedNotification({ customMessage });
    }

    if (deleteResponse) {
      const customMessage = { type: 'success', message: deleteResponse };
      getToastedNotification({ customMessage });
      savedVideosFetch({
        project_id: projectId,
        pagination: { sizePerPage, page: pagination.page, sortField: 'published_at' },
      });
    }
    savedVideosDeleteClean();
  }, [
    projectId,
    pagination.page,
    pageSize,
    savedVideosFetch,
    deleteHasError,
    deleteResponse,
    savedVideosDeleteClean,
    sizePerPage,
  ]);

  const onTableChange = (type, { searchText, sizePerPage, page, sortField, sortOrder }) => {
    const sortFieldEnabled = sortField ?? 'published_at';

    if (type === 'search' && searchText) {
      savedVideosClean();
      savedVideosFetch({
        project_id: projectId,
        filters: {
          ...filters,
          searchTerm: searchText,
        },
        pagination: {
          page,
          sizePerPage,
          sortField: sortFieldEnabled,
          sortOrder,
        },
      });
    } else if (searchText) {
      savedVideosFetch({
        project_id: projectId,
        filters: {
          ...filters,
          searchTerm: searchText,
        },
        pagination: {
          page,
          sizePerPage,
          sortField: sortFieldEnabled,
          sortOrder,
        },
      });
    } else {
      savedVideosFetch({
        project_id: projectId,
        filters,
        pagination: {
          page,
          sizePerPage,
          sortField: sortFieldEnabled,
          sortOrder,
        },
      });
    }
  };

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    savedVideosSetPageSize(newSize);
    savedVideosFetch({
      project_id: projectId,
      filters,
      pagination: {
        page: pagination.page,
        sizePerPage: newSize,
        sortField: 'published_at',
      },
    });
  };

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  return (
    <Table
      data={data}
      withRowSelection
      isLoading={isLoading}
      title={translate('pages.saved_videos.title')}
      columns={columns}
      onTableChange={onTableChange}
      filters={<SavedVideosTableFilters />}
      pagination={paginationWithExtra}
      rowActions={<SavedVideosTableActions />}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getSavedVideos(state),
  isLoading: getSavedVideosIsLoading(state),
  projectId: getCurrentProjectId(state),
  pagination: getSavedVideosPagination(state),
  filters: getSavedVideosFilters(state),
  deleteHasError: getSavedVideosDeleteActionError(state),
  deleteResponse: getSavedVideosDeleteActionResponse(state),
  sizePerPage: getSavedVideosPageSize(state),
});
export default connect(mapStateToProps, {
  savedVideosFetch,
  savedVideosClean,
  savedVideosDeleteClean,
  savedVideosTableFiltersClean,
  savedVideosSetPageSize,
})(SavedVideosTable);
