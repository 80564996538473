import React from 'react';

import { connect } from 'react-redux';

import useCreateCustomerPortal from './hooks/useCreateCustomerPortal';
import ButtonIcon from '../../components/common/ButtonIcon';
import translate from '../../helpers/translate';
import { getUserOrganizationCustomerId } from '../../selectors/users';

const OrganizationDetailsSubscriptionButton = ({ isLoading, customerId }) => {
  const { data: customerPortalData, isLoading: isCustomerPortalLoading, mutate } = useCreateCustomerPortal();

  if (customerPortalData && customerPortalData.data) {
    window.location.href = customerPortalData.data.attributes.url;
  }

  const handleButtonClick = () => {
    const customerPortalData = {
      data: {
        type: 'customer_create_portal',
        attributes: {
          customer_id: customerId,
        },
      },
    };

    mutate(customerPortalData);
  };

  const buttonText = isCustomerPortalLoading ? 'common.loading' : 'organization_details.manage_subscription';

  return (
    <div className="d-flex justify-content-end">
      <ButtonIcon
        color="primary"
        size="sm"
        onClick={handleButtonClick}
        disabled={isLoading}
        aria-label="manage_subscription_button"
      >
        {translate(buttonText)}
      </ButtonIcon>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerId: getUserOrganizationCustomerId(state),
});

export default connect(mapStateToProps)(OrganizationDetailsSubscriptionButton);
