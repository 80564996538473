import React from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'reactstrap';

import translate from '../../../../helpers/translate';

const productColumnClassName = 'justify-content-center d-flex flex-center';

const UpgradeSubscriptionProductPriceBox = ({
  productColor,
  price,
  onClick,
  isAnnualPrice = false,
  selectedProduct,
  productId,
}) => {
  const isProductSelected =
    selectedProduct === productId ? `price-box-${productColor}-selected` : `price-box-${productColor}`;

  const boldClassName = `${productColor} text-bold`;
  const productClassName = `w-100 price-box flex-center justify-content-around ${isProductSelected} d-flex flex-column`;
  const checkIcon = `${productColor} price-box-check-icon`;
  const intervalText = isAnnualPrice ? 'a year' : 'a month';

  return (
    <Col className={productColumnClassName} onClick={onClick}>
      <div className={productClassName}>
        <div className="d-flex flex-column">
          <span className={`${boldClassName} fs-3`}>{price}</span>
          <span className={`${boldClassName} fs-1 text-regular price-box-interval`}>{intervalText}</span>
          {isAnnualPrice && (
            <span className="fs--1 text-gray">{translate('modals.upgrade_subscription.discount')}</span>
          )}
        </div>
        <FontAwesomeIcon icon={faCheckCircle} className={checkIcon} />
      </div>
    </Col>
  );
};

export default UpgradeSubscriptionProductPriceBox;
