import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import SupersearchExport from './SupersearchExport.jsx';
import { superSearchFetchDataPaginated } from '../../../actions/superSearch';
import Table from '../../../components/ICO/Table/Table.jsx';
import { getOrganizationId } from '../../../selectors/organizations';
import {
  getSuperSearchData,
  getSuperSearchDataPagination,
  getSuperSearchFiltersLanguages,
  getSuperSearchFiltersMaxScore,
  getSuperSearchFiltersMinScore,
  getSuperSearchFromDate,
  getSuperSearchSearchTerm,
  getSuperSearchToDate,
} from '../../../selectors/superSearch';
import { getUsersCurrentUser } from '../../../selectors/users';
import columns from '../columns.jsx';

const SuperSearchTable = ({
  data = [],
  pagination,
  superSearchFetchDataPaginated,
  searchTerm,
  fromDate,
  toDate,
  organizationId,
  languages,
  minScore,
  maxScore,
  user,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationHistory, setPaginationHistory] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [stateSortField, setStateSortField] = useState('');
  const [stateSortOrder, setStateSortOrder] = useState('');

  useEffect(() => {
    if (data && pagination && !paginationHistory.length) {
      setPaginationHistory([{ page: 0, searchAfter: pagination.nextPage }]);
    }
  }, [data, pagination, paginationHistory]);

  const onTableChange = (type, { page, sortField, sortOrder }) => {
    const params = {
      searchTerm,
      fromDate: fromDate,
      toDate: toDate,
      organizationId,
      pageSize,
      languages,
      mediaScore: [minScore ?? 0, maxScore ?? 10],
    };

    if (type === 'pagination') {
      const historyItem = paginationHistory.find((history) => history.page === page) || { searchAfter: undefined };

      if (page < currentPage) {
        params.searchAfter = historyItem.searchAfter;
      } else if (page > currentPage && pagination.nextPage) {
        const newHistory = [...paginationHistory, { page, searchAfter: pagination.nextPage }];
        setPaginationHistory(newHistory);
        params.searchAfter = pagination.nextPage;
      }

      if (stateSortField) {
        params.sortFields = [stateSortField, 'inserted_at'];
        params.sortOrder = stateSortOrder;
      }

      setCurrentPage(page);
    }

    if (type === 'sort') {
      setStateSortField(sortField);
      setStateSortOrder(sortOrder);

      setCurrentPage(1);
      setPaginationHistory([{ page: 0, searchAfter: undefined }]);

      params.sortFields = [sortField, 'inserted_at'];
      params.sortOrder = sortOrder;
    }

    superSearchFetchDataPaginated(params);
  };

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1);
    setPaginationHistory([{ page: 0, searchAfter: undefined }]);

    const params = {
      searchTerm,
      fromDate: fromDate,
      toDate: toDate,
      organizationId,
      pageSize: newSize,
      languages,
      mediaScore: [minScore ?? 0, maxScore ?? 10],
    };

    if (stateSortField) {
      params.sortFields = [stateSortField, 'inserted_at'];
      params.sortOrder = stateSortOrder;
    }

    superSearchFetchDataPaginated(params);
  };

  const paginationWithExtra = {
    ...pagination,
    page: currentPage,
    pageSize,
    onSizeChange,
  };

  const exportData = {
    organization_id: organizationId,
    search: searchTerm,
    from_date: fromDate,
    to_date: toDate,
    to_email: user.email,
    languages: languages,
    media_score: [minScore ?? 0, maxScore ?? 10],
  };

  return (
    <Table
      columns={columns}
      data={data}
      title={`${pagination?.totalItems} articles`}
      pagination={paginationWithExtra}
      onTableChange={onTableChange}
      className="fp-supersearch-table"
      exportBtn={<SupersearchExport data={exportData} />}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getSuperSearchData(state),
  pagination: getSuperSearchDataPagination(state),
  searchTerm: getSuperSearchSearchTerm(state),
  fromDate: getSuperSearchFromDate(state),
  toDate: getSuperSearchToDate(state),
  organizationId: getOrganizationId(state),
  languages: getSuperSearchFiltersLanguages(state),
  minScore: getSuperSearchFiltersMinScore(state),
  maxScore: getSuperSearchFiltersMaxScore(state),
  user: getUsersCurrentUser(state),
});

export default connect(mapStateToProps, {
  superSearchFetchDataPaginated,
})(SuperSearchTable);
