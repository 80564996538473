import { combineReducers } from 'redux';

import endDate from './endDate';
import languages from './languages';
import startDate from './startDate';
import tags from './tags';

const filters = combineReducers({
  endDate,
  languages,
  startDate,
  tags,
});

export default filters;
