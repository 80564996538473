import React, { useState } from 'react';

import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const textEditorEmojis = [
  '📰',
  '📈',
  '📉',
  '🗠',
  '📊',
  '📣',
  '🎮',
  '🕹️',
  '🎲',
  '🖥️',
  '🔥',
  '🎄',
  '🎈',
  '🎉',
  '🎊',
  '🎁',
  '🎗',
  '🏀',
  '🏈',
  '🎲',
  '🔇',
  '🔈',
  '📣',
  '🔔',
  '🎵',
  '🎷',
  '💰',
  '🖊',
  '📅',
  '✅',
  '❎',
  '💯',
  '😀',
  '😁',
  '😂',
  '😃',
  '😉',
  '😋',
  '😎',
  '😍',
  '😗',
  '🤗',
  '🤔',
  '😣',
  '😫',
  '😴',
  '😌',
  '🤓',
  '😛',
  '😜',
  '😠',
  '😇',
  '😷',
  '😈',
  '👻',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '🙈',
  '🙉',
  '🙊',
  '👼',
  '👮',
  '🕵',
  '💂',
  '👳',
  '🎅',
  '👸',
  '👰',
  '👲',
  '🙍',
  '🙇',
  '🚶',
  '🏃',
  '💃',
  '⛷',
  '🏂',
  '🏌',
  '🏄',
  '🚣',
  '🏊',
  '⛹',
  '🏋',
  '🚴',
  '👫',
  '💪',
  '👈',
  '👉',
  '👉',
  '👆',
  '🖕',
  '👇',
  '🖖',
  '🤘',
  '🖐',
  '👌',
  '👍',
  '👎',
  '✊',
  '👊',
  '👏',
  '🙌',
  '🙏',
  '🐵',
  '🐶',
  '🐇',
  '🐥',
  '🐸',
  '🐌',
  '🐛',
  '🐜',
  '🐝',
  '🍉',
  '🍄',
  '🍔',
  '🍤',
  '🍨',
  '🍪',
  '🎂',
  '🍰',
  '🍾',
  '🍷',
  '🍸',
  '🍺',
  '🌍',
  '🚑',
  '⏰',
  '🌙',
  '🌝',
  '🌞',
  '⭐',
  '🌟',
  '🌠',
  '🌨',
  '🌩',
  '⛄',
];

const TextEditor = ({ onChangeHTML, placeholder }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'header-one') {
      return 'header-one';
    }
    if (type === 'header-two') {
      return 'header-two';
    }
    return type;
  };
  const onEditorStateChange = (newEditorState) => {
    let html = stateToHTML(newEditorState.getCurrentContent());
    setEditorState(newEditorState);
    onChangeHTML(html);
  };

  return (
    <Editor
      placeholder={placeholder}
      toolbar={{
        options: ['inline', 'list', 'link', 'blockType', 'emoji', 'history'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
        blockType: {
          inDropdown: true,
          options: ['Normal', 'H1', 'H2'],
          className: 'etstdsfdsfdsf',
          component: undefined,
          dropdownClassName: undefined,
        },
        list: {
          inDropdown: false,
          options: ['unordered', 'ordered', 'indent', 'outdent'],
        },
        emoji: {
          emojis: textEditorEmojis,
        },
      }}
      editorState={editorState}
      wrapperClassName="wysiwyg-editor-wrapper"
      editorClassName="wysiwyg-editor"
      onEditorStateChange={onEditorStateChange}
      blockStyleFn={myBlockStyleFn}
    />
  );
};

TextEditor.propTypes = {
  onChangeHTML: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextEditor;
