import React, { useContext, useEffect, memo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ICORoutes from './ICORoutes';
import LoadingLayout from './LoadingLayout';
import { changeGlobalForm } from '../actions/forms';
import ICOFooter from '../components/ICO/footer/Footer';
import FootprintsProvider from '../components/ICO/layouts/FootprintsProvider';
import ICONavbarVertical from '../components/ICO/nav/ICONavbarVertical';
import NavbarTop from '../components/ICO/nav/NavbarTop';
import SidePanelModalRoot from '../components/ICO/SidePanel/SidePanelModalRoot';
import AppContext from '../context/Context';
import { getCurrentProjectId } from '../selectors/forms';
import { getLoadingModule } from '../selectors/modules';
import { getProjects } from '../selectors/projects';
import { getUsersCurrentUserDefaultProjectId } from '../selectors/users';

const DashboardLayout = ({ user, location, changeGlobalForm, projects, currentProjectId, loadingModule }) => {
  const { isVertical, navbarStyle } = useContext(AppContext);

  useDeepCompareEffect(() => {
    const firstProject = projects[Object.keys(projects)[0]];
    if ((currentProjectId === 0 || currentProjectId === '0') && firstProject) {
      changeGlobalForm({ projectId: firstProject.id });
    } else {
      changeGlobalForm({ projectId: currentProjectId });
    }
  }, [changeGlobalForm, projects, currentProjectId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (!loadingModule.fetchStarted || !loadingModule.fetchCompleted) {
    return <LoadingLayout />;
  }

  return (
    <div className="container-fluid">
      <FootprintsProvider user={user}>
        {isVertical && <ICONavbarVertical navbarStyle={navbarStyle} user={user} />}
        <div className="content">
          <NavbarTop />
          <ICORoutes user={user} />
          <ICOFooter />
        </div>
        <SidePanelModalRoot path={location.pathname} />
      </FootprintsProvider>
    </div>
  );
};

DashboardLayout.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.shape({}),
  loadingModule: PropTypes.shape({
    fetchStarted: PropTypes.bool,
    fetchCompleted: PropTypes.bool,
  }),
  changeGlobalForm: PropTypes.func,
  currentProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  projects: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  loadingModule: getLoadingModule(state),
  currentProjectId: getCurrentProjectId(state) ?? getUsersCurrentUserDefaultProjectId(state),
  projects: getProjects(state),
});

export default memo(connect(mapStateToProps, { changeGlobalForm })(DashboardLayout));
