import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

class ModuleLine extends Component {
  render() {
    return <Row key={this.props.id}>{this.props.children}</Row>;
  }
}

ModuleLine.propTypes = {
  id: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ModuleLine;
