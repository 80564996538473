import React from 'react';

import numeral from 'numeral';
import PropTypes from 'prop-types';

import CardSummary from '../CardSummary/CardSummary';

/**
 * Displays a single metric in a widget
 */
const SingleMetric = ({ count, title, color, rate, tooltip }) => {
  const numberFormat = (number = 0) => numeral(number).format('0,0');
  return (
    <CardSummary rate={rate} title={title} color={color} hasLinkText={false} tooltip={tooltip}>
      {typeof count === 'number' && numberFormat(count)}
      {typeof count === 'string' && count}
    </CardSummary>
  );
};

SingleMetric.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  color: PropTypes.string,
  reversed: PropTypes.bool,
  rate: PropTypes.number,
  icon: PropTypes.string,
  tooltip: PropTypes.shape({
    visible: PropTypes.bool,
    icon: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.string,
    delay: PropTypes.shape({
      show: PropTypes.number,
      delay: PropTypes.number,
    }),
  }),
};

export default SingleMetric;
