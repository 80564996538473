import 'bootstrap-daterangepicker/daterangepicker.css';

import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import DateRangePicker from 'react-bootstrap-daterangepicker';

const formatRangePickerDate = (date) => moment(date).format('MM/DD/YYYY');

export const formatDisplayDate = (date) => moment(date).format('MMM Do, YYYY');

const RangeDateFilter = ({ ranges, start, end, className, onChange, disabled }) => {
  let currentStart = formatRangePickerDate(start);
  let currentEnd = formatRangePickerDate(end);
  let currentDisplayStart = formatDisplayDate(start);
  let currentDisplayEnd = formatDisplayDate(end);
  ranges = ranges
    ? ranges
    : {
      'Last Week': [moment().subtract(6, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last 6 Month': [moment().subtract(6, 'month').startOf('month'), moment()],
      'Last 18 Month': [moment().subtract(18, 'month').startOf('month'), moment()],
    };
  return (
    <div className={className}>
      <DateRangePicker
        initialSettings={{
          linkedCalendars: false,
          showDropdowns: true,
          startDate: currentStart,
          endDate: currentEnd,
          ranges: ranges,
        }}
        disabled={disabled}
        startDate={currentStart}
        endDate={currentEnd}
        onApply={onChange}
      >
        <button disabled={disabled} type="button" className="btn btn-primary btn-sm dropdown-toggle">
          <i className="icon-calendar22 mr-2" />
          <span>
            &nbsp; {currentDisplayStart} &nbsp; - &nbsp; {currentDisplayEnd}
          </span>
        </button>
      </DateRangePicker>
    </div>
  );
};
RangeDateFilter.defaultProps = {
  start: moment().startOf('month'),
  end: moment(),
  allTimes: true,
  disabled: false,
};

RangeDateFilter.propTypes = {
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), //Moment date or string
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  ranges: PropTypes.func,
  disabled: PropTypes.bool,
  allTimes: PropTypes.bool,
};

export default RangeDateFilter;
