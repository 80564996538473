import {
  UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT,
  UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_SUCCESS,
  UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_ERROR,
  UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_FAILURE,
  UPGRADE_SUBSCRIPTION_CLEAR,
} from '../../actions/actionTypes';

const defaultState = {
  isLoading: false,
  response: null,
  error: null,
};

const stripeCheckout = (state = defaultState, action) => {
  switch (action.type) {
    case UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT:
      return { ...state, isLoading: true };
    case UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_SUCCESS:
      return { ...state, isLoading: false, response: action.payload.data.attributes };
    case UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_FAILURE:
    case UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case UPGRADE_SUBSCRIPTION_CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export default stripeCheckout;
