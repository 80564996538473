import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';

import { superSearchSetFromDate } from '../../../actions/superSearch';
import translate from '../../../helpers/translate';
import { getOrganizationPermissionsMaxData } from '../../../selectors/permissions';

const SuperSearchFiltersDateFrom = ({ superSearchSetFromDate, userMaxData }) => {
  let minDate = '2017-01-01T00:00:00+00:00';

  const initialFromDate = dayjs().subtract(1, 'week').format();

  if (userMaxData !== null) {
    const userMaxHistoricalData = dayjs().subtract(userMaxData, 'month');
    const userMaxHistoricalDataUnix = userMaxHistoricalData.valueOf();
    const maxHistoricalDataUnix = dayjs(minDate).valueOf();
    const selectEarliestDate = Math.max(maxHistoricalDataUnix, userMaxHistoricalDataUnix);
    minDate = dayjs(selectEarliestDate).format();
  }

  const maxDate = dayjs().format();

  useEffect(() => {
    superSearchSetFromDate(initialFromDate);
  }, [initialFromDate, superSearchSetFromDate]);

  const initialSettings = {
    locale: { format: 'YYYY-MM-DD HH:mm' },
    showDropdowns: true,
    singleDatePicker: true,
    startDate: initialFromDate,
    minDate,
    maxDate,
    timePicker: true,
  };

  const handleApplyDates = (event) => {
    superSearchSetFromDate(dayjs(event.target.value).format());
  };

  return (
    <>
      <Label>{translate('pages.supersearch.filters.date_from')}</Label>
      <DateRangePicker onApply={handleApplyDates} initialSettings={initialSettings} timePicker={true}>
        <input type="text" className="form-control" />
      </DateRangePicker>
    </>
  );
};

SuperSearchFiltersDateFrom.propTypes = {
  superSearchSetFromDate: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userMaxData: getOrganizationPermissionsMaxData(state),
});

export default connect(mapStateToProps, {
  superSearchSetFromDate,
})(SuperSearchFiltersDateFrom);
