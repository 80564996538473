import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Input, FormFeedback, Label } from 'reactstrap';

import { changeOrganizationName } from '../../actions/organizationDetails';
import { getOrganizationDetailsModifiedName } from '../../selectors/organizationDetails';

const OrganizationDetailsFormName = ({ userIsAdmin, changeOrganizationName, organizationName }) => {
  const handleChangeOrganizationName = (event) => {
    changeOrganizationName(event.target.value);
  };

  const userRoleComponentToRender = userIsAdmin ? (
    <>
      <Input
        aria-label="name-input"
        placeholder={organizationName}
        onChange={handleChangeOrganizationName}
        value={organizationName}
        type="text"
        invalid={!organizationName}
      />
      {!organizationName && <FormFeedback>Organization name cannot be empty</FormFeedback>}
    </>
  ) : (
    <Label className="fs-1">{organizationName}</Label>
  );

  return (
    <FormGroup>
      <h6 className="mt-4">Name</h6>
      {userRoleComponentToRender}
    </FormGroup>
  );
};

const mapStateToProps = (state) => ({
  organizationName: getOrganizationDetailsModifiedName(state),
});

OrganizationDetailsFormName.propTypes = {
  userIsAdmin: PropTypes.bool,
  organizationName: PropTypes.string,
  changeOrganizationName: PropTypes.func,
};

OrganizationDetailsFormName.defaultProps = {
  userRole: false,
  organizationName: '',
  changeOrganizationName: () => {},
};

export default connect(mapStateToProps, { changeOrganizationName })(OrganizationDetailsFormName);
