import React, { useState } from 'react';

import FsLightbox from 'fslightbox-react';

import translate from '../../../helpers/translate';

const ImagePreviewer = ({ image, isBlob = false, ...props }) => {
  const [toggler, setToggler] = useState(false);
  let blobImage = null;

  if (isBlob) {
    blobImage = `data:image/bmp;base64,${image}`;
  }

  return (
    <>
      <div
        aria-label="preview"
        title={translate('common.open_preview')}
        className="cursor-pointer"
        onClick={() => setToggler(!toggler)}
      >
        {props.children}
      </div>
      <FsLightbox toggler={toggler} sources={[blobImage ?? image]} />
    </>
  );
};

export default ImagePreviewer;
