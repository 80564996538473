import {
  PRODUCTS_FETCH_PRODUCTS,
  PRODUCTS_FETCH_PRODUCTS_ERROR,
  PRODUCTS_FETCH_PRODUCTS_FAILURE,
  PRODUCTS_FETCH_PRODUCTS_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: null,
  error: null,
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH_PRODUCTS:
      return { ...state, isLoading: true };
    case PRODUCTS_FETCH_PRODUCTS_SUCCESS:
      return { ...state, isLoading: false, response: action.payload };
    case PRODUCTS_FETCH_PRODUCTS_ERROR:
    case PRODUCTS_FETCH_PRODUCTS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default products;
