import React from 'react';

import { values } from 'lodash';
import { connect } from 'react-redux';

import TagActions from '../../components/ICO/actions/TagActions';
import { tagsTableColumns } from '../../components/ICO/tables/helpers/columns';
import { tableRows } from '../../components/ICO/tables/helpers/selectors';
import StaticDataTable from '../../components/ICO/tables/StaticDataTable';
import { getTagsTableListStatusIsLoading } from '../../selectors/tags';

const mapStateToProps = (state, ownProps) => {
  const listStatusLoading = getTagsTableListStatusIsLoading(state);
  const tagsTableFormatter = (rows) =>
    values(rows).map((tag) => ({
      id: tag.id,
      name: tag.name || 'No Name #' + tag.id,
      actions: <TagActions id={tag.id} tag={tag} />,
      date: tag['inserted-at'],
      updated_at: tag['updated-at'],
    }));

  const rowsData = tableRows(state, { locked: false })[ownProps.tableId]();

  return {
    state,
    rowsSelectable: false,
    viewAllBtn: false,
    columns: tagsTableColumns,
    rows: tagsTableFormatter(tableRows(state, { locked: false })[ownProps.tableId]()),
    rowsArchived: tagsTableFormatter(tableRows(state, { locked: true })[ownProps.tableId]()),
    ownProps: ownProps,
    isTableLoading: listStatusLoading && !rowsData.length,
  };
};

export default connect(mapStateToProps)(StaticDataTable);
