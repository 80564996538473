import React, { useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import '../../../assets/scss/theme/Footprints/components/_rc-slider.scss';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import EAMediaFeedFilters from './EAMediaFeedFilters';
import RangeDateFilter from '../forms/RangeDateFilter';
import SingleSelect from '../forms/SingleSelect';

const TableFilters = ({
  filters,
  allTimes,
  tags,
  tagFiltersEnabled,
  eaFiltersEnabled,
  onChangeCheckbox,
  onChangeMultiFilter,
  onChangeDateFilter,
  mediatypes,
  mtFiltersEnabled,
  languages,
  langFiltersEnabled,
  regions,
  countries,
  regionFiltersEnabled,
  onChangeSliderFilter,
  dateFiltersEnabled,
}) => {
  const defaultDateRange =
    filters.date_range.length > 0
      ? [filters.date_range[0], filters.date_range[1]]
      : [moment().startOf('month'), moment()];
  const [minMediaScore, setMinMediaScore] = useState(0);
  const [maxMediaScore, setMaxMediaScore] = useState(10);
  const [getLocalDateRange, setLocalDateRange] = useState(defaultDateRange);
  const [getPeriodActive, setPeriodActive] = useState(filters.date_range.length === 2);

  const handleChangeMinMediaScoreInput = (value) => {
    onChangeSliderFilter([value, maxMediaScore]);
    setMinMediaScore(value);
  };

  const handleChangeMaxMediaScoreInput = (value) => {
    onChangeSliderFilter([minMediaScore, value]);
    setMaxMediaScore(value);
  };

  return (
    <Col md={12}>
      {tags && tagFiltersEnabled && (
        <FormGroup>
          <Label for="tags">Tags</Label>
          <SingleSelect
            name={'tags'}
            isMulti={true}
            items={tags}
            value={filters.tags || []}
            onChange={onChangeMultiFilter}
            className={'sm-select'}
            placeholder={'All tags'}
          />
        </FormGroup>
      )}
      {languages && langFiltersEnabled && (
        <FormGroup>
          <Label for="languages">Languages</Label>
          <SingleSelect
            name={'languages'}
            isMulti={true}
            items={languages}
            value={filters.languages || []}
            onChange={onChangeMultiFilter}
            className={'sm-select'}
            placeholder={'All languages'}
          />
        </FormGroup>
      )}
      {mediatypes && mtFiltersEnabled && (
        <FormGroup>
          <Label for="mediatypes">Media Types</Label>
          <SingleSelect
            name={'mediatypes'}
            isMulti={true}
            items={mediatypes}
            value={filters.mediatypes || []}
            onChange={onChangeMultiFilter}
            className={'sm-select'}
            placeholder={'All Media Types'}
          />
        </FormGroup>
      )}
      {eaFiltersEnabled && (
        <EAMediaFeedFilters
          filters={filters}
          countries={countries}
          regions={regions}
          onChangeCheckbox={onChangeCheckbox}
          onChangeMultiFilter={onChangeMultiFilter}
          regionFiltersEnabled={regionFiltersEnabled}
        />
      )}
      <FormGroup className="text-left score-slider-wrapper">
        <Label for="score">Media Score Range</Label>
        <Row>
          <Col xs="6">
            <Label for="min_media_score">Min. Media Score</Label>
            <Input
              id="min_media_score"
              type="number"
              min={0}
              max={10}
              onChange={(e) => handleChangeMinMediaScoreInput(e.target.value)}
              value={minMediaScore}
              placeholder="0"
            />
          </Col>
          <Col xs="6">
            <Label for="max_media_score">Max. Media Score</Label>
            <Input
              id="max_media_score"
              type="number"
              min={0}
              max={10}
              onChange={(e) => handleChangeMaxMediaScoreInput(e.target.value)}
              value={maxMediaScore}
              placeholder="10"
            />
          </Col>
        </Row>
      </FormGroup>
      {dateFiltersEnabled && (
        <>
          <FormGroup className="form-check">
            <Input
              type="radio"
              name="radio1"
              onChange={() => {
                onChangeDateFilter(null, []);
                setPeriodActive(false);
              }}
              checked={filters.date_range.length === 0}
            />
            <Label check>All time</Label>
          </FormGroup>
          <FormGroup className="form-check">
            <Input
              type="radio"
              name="radio1"
              checked={filters.date_range.length > 0}
              onChange={() => {
                onChangeDateFilter(null, { startDate: getLocalDateRange[0], endDate: getLocalDateRange[1] });
                setPeriodActive(true);
              }}
            />
            <Label check>Specific Period</Label>
            <RangeDateFilter
              disabled={!getPeriodActive}
              allTimes={allTimes}
              start={getLocalDateRange[0]}
              end={getLocalDateRange[1]}
              className={'daterange-predefined'}
              onChange={(e, p) => {
                setLocalDateRange([p.startDate, p.endDate]);
                onChangeDateFilter(e, p);
              }}
            />
          </FormGroup>
        </>
      )}
    </Col>
  );
};

TableFilters.propTypes = {
  allTimes: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.string),
  dateFiltersEnabled: PropTypes.bool,
  eaFiltersEnabled: PropTypes.bool,
  filters: PropTypes.shape({
    score_range: PropTypes.arrayOf(PropTypes.number),
    date_range: PropTypes.arrayOf(PropTypes.number),
    tags: PropTypes.arrayOf(PropTypes.string),
    ea_only: PropTypes.number,
    countries: PropTypes.arrayOf(PropTypes.string),
    regions: PropTypes.arrayOf(PropTypes.string),
    mediatypes: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.arrayOf(PropTypes.string),
  }),
  langFiltersEnabled: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  mediatypes: PropTypes.arrayOf(PropTypes.shape({})),
  mtFiltersEnabled: PropTypes.bool,
  onChangeCheckbox: PropTypes.func,
  onChangeDateFilter: PropTypes.func,
  onChangeFilter: PropTypes.func,
  onChangeMultiFilter: PropTypes.func,
  onChangeSliderFilter: PropTypes.func,
  regionFiltersEnabled: PropTypes.bool,
  regions: PropTypes.arrayOf(PropTypes.string),
  tagFiltersEnabled: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TableFilters;
