import { ARTICLES_FEED_FILTERS_CLEAR, ARTICLES_FEED_FILTERS_SET_MIN_MEDIA_SCORE } from '../../../actions/actionTypes';

const defaultState = null;

const minReach = (state = defaultState, action) => {
  switch (action.type) {
    case ARTICLES_FEED_FILTERS_SET_MIN_MEDIA_SCORE:
      return action.payload;
    case ARTICLES_FEED_FILTERS_CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export default minReach;
