import React, { Fragment, useState } from 'react';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

const RowResultActionsKO = ({ id, message }) => {
  const [isOpenTop, setIsOpenTop] = useState(false);
  return (
    <Fragment>
      <Tooltip placement="top" isOpen={isOpenTop} target={'icon_tooltip_' + id} toggle={() => setIsOpenTop(!isOpenTop)}>
        {message}
      </Tooltip>
      <FontAwesomeIcon id={'icon_tooltip_' + id} icon={faExclamationTriangle} className="text-danger fs-2" />
    </Fragment>
  );
};

RowResultActionsKO.propTypes = {
  id: PropTypes.number,
  message: PropTypes.string,
};

export default RowResultActionsKO;
