import React from 'react';

import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';

const App = () => (
  // eslint-disable-next-line no-undef
  <Router basename={process.env.PUBLIC_URL}>
    <Layout />
  </Router>
);

export default App;
