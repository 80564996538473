import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Button } from 'reactstrap';

import useUpdateOrganization from './hooks/useUpdateOrganization';
import {
  getOrganizationDetailsSaveIsDisabled,
  getOrganizationDetailsOrganizationId,
  getOrganizationDetailsModified,
  getOrganizationDetailsIsLoading,
} from '../../selectors/organizationDetails';

const OrganizationDetailsFormSave = ({ isLoading, isSaveDisabled, modifiedData, organizationId }) => {
  const mutation = useUpdateOrganization();
  const handleClickSave = () => {
    mutation.mutate({
      id: organizationId,
      data: {
        data: {
          type: 'organizations',
          attributes: {
            name: modifiedData.name,
            avatar: modifiedData.avatar,
          },
        },
      },
    });
  };

  const buttonText = isLoading ? 'Saving...' : 'Save';

  return (
    <Col xs="auto" className="d-flex order-md-1">
      <Button aria-label="save-button" color="primary" icon="check" onClick={handleClickSave} disabled={isSaveDisabled}>
        {buttonText}
      </Button>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  isLoading: getOrganizationDetailsIsLoading(state),
  isSaveDisabled: getOrganizationDetailsSaveIsDisabled(state),
  modifiedData: getOrganizationDetailsModified(state),
  organizationId: getOrganizationDetailsOrganizationId(state),
});

OrganizationDetailsFormSave.propTypes = {
  isLoading: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  modifiedData: PropTypes.shape({}),
  organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default connect(mapStateToProps, null)(OrganizationDetailsFormSave);
