import { useMutation } from 'react-query';

import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';

const useUpdateOrganization = () =>
  useMutation({
    mutationKey: 'update_organization',
    mutationFn: async ({ id, data }) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/organizations/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: translate('pages.organization_details.notifications.update_organization_success'),
      };

      getToastedNotification({ customMessage });
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: translate('pages.organization_details.notifications.update_organization_error'),
      };

      getToastedNotification({ customMessage });
    },
  });

export default useUpdateOrganization;
