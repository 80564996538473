import React from 'react';

import { connect } from 'react-redux';
import Label from 'reactstrap/lib/Label';

import { superSearchSetMinScore } from '../../../actions/superSearch';
import SingleSelect from '../../../components/ICO/forms/SingleSelect';
import { getSuperSearchFiltersMaxScore, getSuperSearchFiltersMinScore } from '../../../selectors/superSearch';

const scoreRange = [
  { value: 0.1, label: '0.1' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const SuperSearchFiltersMinScore = ({ superSearchSetMinScore, selectedMinScore, selectedMaxScore }) => {
  const filteredItems = scoreRange.filter((item) => item.value < selectedMaxScore);

  return (
    <>
      <Label>Min. Media Score</Label>
      <SingleSelect
        aria-label="single-select"
        items={filteredItems}
        value={selectedMinScore ?? []}
        onChange={({ value }) => superSearchSetMinScore(value)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedMinScore: getSuperSearchFiltersMinScore(state),
  selectedMaxScore: getSuperSearchFiltersMaxScore(state) ?? 10,
});
export default connect(mapStateToProps, { superSearchSetMinScore })(SuperSearchFiltersMinScore);
