import moment from 'moment';
import numeral from 'numeral';

export const removeProtocolFromUrl = (url = '') => {
  if (url) {
    if (url.indexOf('http://') === 0) {
      return url.substr(7);
    }
    if (url.indexOf('https://') === 0) {
      return url.substr(8);
    }
    if (url.indexOf('www.') === 0) {
      return url.substr(4);
    }
    return url;
  }
  return '';
};

export const extractDomain = (url = '') => removeProtocolFromUrl(url).split('/')[0];

export const extractFavicon = (url = '') => 'http://www.google.com/s2/favicons?domain=' + extractDomain(url);

export const colorScore = (score = 0) => {
  switch (true) {
    case score >= 0 && score < 1:
      return 'media-score-min';
    case score >= 1 && score < 3:
      return 'media-score-light';
    case score >= 3 && score < 6:
      return 'media-score-medium';
    case score >= 6 && score < 9:
      return 'media-score-high';
    case score >= 9:
      return 'media-score-max';
    default:
      return 'indigo';
  }
};

export const colorMUV = (muv = 0) => {
  switch (true) {
    case muv >= 0 && muv < 500000:
      return 'muv-score-min';
    case muv >= 500000 && muv < 1000000:
      return 'muv-score-light';
    case muv >= 1000000 && muv < 5000000:
      return 'muv-score-medium';
    case muv >= 5000000 && muv < 9000000:
      return 'muv-score-high';
    case muv >= 9000000 && muv < 10000000:
      return 'muv-score-vhigh';
    case muv >= 10000000:
      return 'muv-score-max';
    default:
      return 'indigo';
  }
};

export const largeNumberFormat = (number = 0) => {
  if (number === '') return '';
  if (number === '-' || number === 0) return '-';
  return numeral(number).format('0,00a');
};

export const formatDate = (date) => moment(date).format('MMMM DD, YYYY');

export const mediumNumberFormat = (number = 0) => {
  if (number === '') return '';
  if (number === '-' || number === 0) return '-';
  return numeral(number).format('0,0000');
};
