const MODAL_TITLE_HASH_MAP = {
  articlesFeedTableBulk: 'Import',
  articlesFeedTableBulkDelete: 'Delete',
  articlesFeedTableFilters: 'Media Feed Filters',
  articlesTableFilters: 'Articles Filters',
  confirmAction: 'Please confirm...',
  editArticle: 'Edit article',
  editAlert: 'Set search query',
  editClient: 'Edit Brand',
  editFeed: 'Edit Feed',
  editProject: 'Edit Project',
  editTag: 'Edit Tag',
  inviteOrg: 'Invite',
  newArticle: 'New article',
  newArticleBulk: 'New articles',
  newClient: 'New Brand',
  newFeed: 'New Feed',
  newProject: 'New Project',
  newTag: 'New Tag',
  previewArticle: 'Preview',
  websitesTableFilters: 'Filters',
  renameReport: 'Rename your report',
  newWebsite: 'Add new website',
};

export const getModalTitle = (content, contentData = {}) =>
  contentData.title ? contentData.title : MODAL_TITLE_HASH_MAP[content];
