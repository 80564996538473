import React, { useState } from 'react';

import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

export const ArticleSource = ({ article, source }) => {
  const [isOpenTwitter, setIsOpenTwitter] = useState(false);
  if (source === 1) {
    return (
      <>
        <div className="cursor-pointer" id={'art-tw-' + article.id}>
          <FontAwesomeIcon icon={faTwitter} transform="shrink-4 down-1" className="mr-1" />
        </div>
        <Tooltip
          delay={{
            show: 0,
            hide: 0,
          }}
          placement="top"
          isOpen={isOpenTwitter}
          target={'art-tw-' + article.id}
          toggle={() => setIsOpenTwitter(!isOpenTwitter)}
        >
          {'This article was fetched from this media\'s Twitter account.'}
        </Tooltip>
      </>
    );
  }
  return <></>;
};

ArticleSource.propTypes = {
  article: PropTypes.shape({ id: PropTypes.number, url: PropTypes.string }),
  source: PropTypes.number,
};
