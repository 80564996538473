import { FETCH_ALERTS, FETCH_ALERTS_SUCCESS, FETCH_ALERTS_ERROR, FETCH_ALERTS_FAILURE } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: null,
  error: null,
};

const alerts = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALERTS:
      return { ...state, isLoading: true };
    case FETCH_ALERTS_SUCCESS:
      return { ...state, isLoading: false, response: action.payload.data };
    case FETCH_ALERTS_ERROR:
    case FETCH_ALERTS_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default alerts;
