import { COVERAGE_API_ROOT } from './config/global-config';
import { getToken } from './helpers/getToken';

const apiCallsMiddleware = (store) => (next) => async (action) => {
  if (action) {
    if (action.type !== 'API_CALL') {
      return next(action);
    }

    if (action.meta) {
      const {
        meta: { type, payload, endpoint, method },
      } = action;

      let apiURL = `${COVERAGE_API_ROOT}/${endpoint}`;

      let requestConfig = {
        method,
        urlRoot: COVERAGE_API_ROOT,
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
      };

      if (method === 'GET' && payload) {
        apiURL = endpoint;
      }

      if (method === 'POST' || method === 'PATCH' || method === 'DELETE') {
        const body = JSON.stringify(payload);
        requestConfig = { ...requestConfig, body };
      }

      try {
        store.dispatch({
          type,
          payload,
        });
        let response;
        let data;
        try {
          response = await fetch(apiURL, requestConfig);
          const contentType = response.headers.get('content-type');
          const apiContentTypes =
            contentType.indexOf('application/json') !== -1 || contentType.indexOf('application/vnd.api+json') !== -1;

          if (contentType && apiContentTypes) {
            data = await response.json();
          } else {
            data = await response.text();
          }

          if (response.status === 200 || response.status === 201) {
            if (data) {
              store.dispatch({
                type: `${type}/SUCCESS`,
                payload: data,
              });
            }
          } else {
            store.dispatch({
              type: `${type}/ERROR`,
              payload: {
                status: response.status,
              },
            });
          }
        } catch (requestError) {
          store.dispatch({
            type: `${type}/FAILURE`,
            payload: {
              status: response.status,
              message: requestError.toString(),
            },
          });
        }
      } catch (error) {
        store.dispatch({
          type: `${type}/ERROR`,
          payload: error.toString(),
        });
      }
    }
  }
};

export default apiCallsMiddleware;
