import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { fetchYoutubeFeed } from '../../../../actions/youtubeFeed';
import { InputSelect } from '../../../../components/ICO/forms/InputSelect';
import translate from '../../../../helpers/translate';
import { getYoutubeFeedPagination, getYoutubeFeedTableFilters } from '../../../../selectors/feeds/youtubeFeed';
import { getOrganizationId } from '../../../../selectors/organizations';
import { getCurrentProject } from '../../../../selectors/projects';
import { getNotLockedTags } from '../../../../selectors/tags';
import useSaveVideos from '../../hooks/useSaveVideos';

const YoutubeFeedSaveVideo = ({
  isOpen,
  onClose,
  selectedRows = null,
  tags,
  fetchYoutubeFeed,
  userOrganizationId,
  project,
  pagination,
}) => {
  const youtube_search_id = project['youtube-search-id'];
  const onSuccess = () => {
    fetchYoutubeFeed({
      youtube_search_id,
      pagination: { page: pagination.page },
      filters: {
        organization_id: userOrganizationId,
      },
    });

    onClose();
  };

  const [selectedTag, setSelectedTag] = useState(null);
  const mutation = useSaveVideos(onSuccess, onClose);

  const onClickImport = () => {
    const videosWithTag = selectedRows.map((video) => ({
      video_id: video,
      tag_id: selectedTag.value,
    }));

    mutation.mutate({ youtube_videos: videosWithTag });
    onClose();
  };

  const selectValue = selectedTag ? { value: selectedTag.value, label: selectedTag.label } : null;

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
      <ModalHeader className="fp-modal-header--small">Save {selectedRows.length} videos</ModalHeader>
      <ModalBody className="fp-modal-body">
        <p>{translate('pages.youtube_feed.modals.save_videos')}</p>
        <div className="mt-2">
          <InputSelect
            name="tag-select"
            onChange={(tag) => setSelectedTag(tag)}
            value={selectValue}
            items={tags}
            placeholder="Select a tag"
            disabled={false}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button
          size="sm"
          type="submit"
          onClick={onClickImport}
          color="primary"
          name="saveButton"
          disabled={!selectedTag}
        >
          {translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  tags: getNotLockedTags(state),
  userOrganizationId: getOrganizationId(state),
  pagination: getYoutubeFeedPagination(state),
  filters: getYoutubeFeedTableFilters(state),
  project: getCurrentProject(state),
});

export default connect(mapStateToProps, {
  fetchYoutubeFeed,
})(YoutubeFeedSaveVideo);
