import 'bootstrap-daterangepicker/daterangepicker.css';
import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import DateRangePicker from 'react-bootstrap-daterangepicker';

const SimpleDatePicker = ({ onChange, value }) => {
  const defaultDate = value ? moment(value).format('MM/DD/YYYY') : undefined;
  const initialSettings = value
    ? {
      startDate: defaultDate,
      singleDatePicker: true,
      showDropdowns: true,
    }
    : {
      singleDatePicker: true,
      showDropdowns: true,
    };

  return (
    <DateRangePicker
      initialSettings={initialSettings}
      onCallback={(event, picker) => {
        onChange(picker.format());
      }}
      onApply={(e, picker) => {
        onChange(picker.startDate.format());
      }}
    >
      <input type="text" className="form-control" name="datepicker" placeholder="Start date" />
    </DateRangePicker>
  );
};

SimpleDatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

export default SimpleDatePicker;
