import { REQUEST_ARTICLES_GROUPS, RECEIVE_ARTICLES_GROUPS, REJECT_ARTICLES_GROUPS } from '../../actions/articles';
import { apiRequest, apiReceive, apiReject } from '../../config/api-service';

const initialState = {
  items: {},
  itemsStatus: {},
  lists: {},
  listsStatus: {},
  stats: {},
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ARTICLES_GROUPS:
      return apiRequest(state, action);
    case RECEIVE_ARTICLES_GROUPS:
      return apiReceive(state, action);
    case REJECT_ARTICLES_GROUPS:
      return apiReject(state, action);
    default:
      return Object.assign({}, state);
  }
};

export default groups;
