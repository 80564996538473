import React, { useState } from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import { formatHTMLSymbols } from '../../../components/ICO/tables/helpers/formatters';
import SavedStreamsTablePreviewStreamModal from '../Modals/Preview/SavedStreamsTablePreviewStreamModal.jsx';

const SavedStreamsTablePreviewStream = ({ videoData }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const tooltipId = `tooltip_${videoData.id}`;

  return (
    <>
      <div className="cursor-pointer text-truncate" onClick={() => setModalOpen(true)} style={{ width: '25vw' }}>
        <span id={tooltipId}>{formatHTMLSymbols(videoData.title)}</span>
      </div>
      <UncontrolledTooltip placement="bottom" target={tooltipId}>
        {videoData.title}
      </UncontrolledTooltip>
      <SavedStreamsTablePreviewStreamModal
        videoData={videoData}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default SavedStreamsTablePreviewStream;
