import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Row } from 'reactstrap';

import { changeArticleForm } from '../../../actions/forms';
import { convertItemsForSelect } from '../../../config/api-service';
import { getIsValidDomain } from '../../../helpers/getIsValidDomain';
import { getLanguages } from '../../../selectors/languages';
import { getTagOptions } from '../../../selectors/tags';
import SimpleDatePicker from '../forms/SimpleDatePicker';
import SingleSelect from '../forms/SingleSelect';

const ArticleForm = ({ langOptions, onChange, mode, fields, tagOptions, setSaveButtonDisabled }) => {
  const [isArticleURLValid, setArticleURLValid] = useState(false);

  const handleTextInputChange = (event, field) => {
    onChange({ field, value: event.target.value });
    if (getIsValidDomain(event.target.value)) {
      setSaveButtonDisabled(false);
      setArticleURLValid(true);
    } else {
      setSaveButtonDisabled(true);
      setArticleURLValid(false);
    }
  };

  const handleDateChange = (value, field) => {
    onChange({ field, value });
  };

  const handleChange = (value, field) => {
    onChange({ field, value: value.value });
    if (isArticleURLValid) {
      setSaveButtonDisabled(false);
    }
  };

  if (mode === 'new') {
    return (
      <>
        <FormGroup>
          <Row>
            <Col md={12} xs={12}>
              <SingleSelect
                name="tag-select"
                items={tagOptions}
                value={fields.tagId ?? ''}
                placeholder="Tag"
                onChange={(e) => handleChange(e, 'tagId')}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={12} xs={12}>
              <Input
                type="text"
                onChange={(e) => handleTextInputChange(e, 'url')}
                placeholder="Article link"
                value={fields.url ?? ''}
                className="form-control"
                invalid={fields.url && !isArticleURLValid}
                valid={isArticleURLValid}
              />
            </Col>
          </Row>
        </FormGroup>
      </>
    );
  } else if (mode === 'edit') {
    return (
      <>
        <FormGroup>
          <Row>
            <Col md={12} xs={12}>
              <input type="hidden" onChange={(e) => handleTextInputChange(e, 'id')} value={fields.id ?? ''} />
              <input
                type="hidden"
                onChange={(e) => handleTextInputChange(e, 'projectId')}
                value={fields.projectId ?? ''}
              />
              <input
                type="text"
                onChange={(e) => handleTextInputChange(e, 'title')}
                placeholder="Title"
                value={fields.title ?? ''}
                className="form-control"
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={12} xs={12}>
              <SingleSelect
                name="tag-select"
                items={tagOptions}
                value={fields.tagId ?? ''}
                placeholder="Tag"
                onChange={(e) => handleChange(e, 'tagId')}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={6} xs={6}>
              <input
                type="text"
                onChange={(e) => handleTextInputChange(e, 'reach')}
                placeholder="Media Score"
                value={fields.reach ?? ''}
                className="form-control"
              />
            </Col>
            <Col md={6} xs={6}>
              <div className="input-group">
                <span className="input-group-addon">
                  <i className="icon-calendar22" />
                </span>
                <SimpleDatePicker value={fields.date ?? ''} onChange={(e) => handleDateChange(e, 'date')} />
              </div>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={12} xs={12}>
              <SingleSelect
                name="language-select"
                items={langOptions}
                value={fields.langId ?? ''}
                placeholder="Language"
                onChange={(e) => handleChange(e, 'langId')}
              />
            </Col>
          </Row>
        </FormGroup>
      </>
    );
  }
};

ArticleForm.propTypes = {
  langOptions: PropTypes.array,
  tagOptions: PropTypes.array,
  fields: PropTypes.object,
  mode: PropTypes.string,
  onChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formTitle: state.forms.newArticleForm ? state.forms.newArticleForm.title : '',
  fields: state.forms.newArticleForm ? state.forms.newArticleForm.fields : {},
  tagOptions: state.forms.newArticleForm ? convertItemsForSelect(getTagOptions(state)) : [],
  langOptions: convertItemsForSelect(getLanguages(state)),
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (data) => dispatch(changeArticleForm(data)),
});

export const ArticlesFormContainer = connect(mapStateToProps, mapDispatchToProps)(ArticleForm);
