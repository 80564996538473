import React from 'react';

import PropTypes from 'prop-types';

import { IS_MOBILE } from '../../../constants';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import Section from '../../common/Section';
import './_style.scss';

const CirclesBackground = ({ children }) => {
  const deviceScreen = useDeviceScreen();

  const backgroundImage =
    deviceScreen.screenWidth <= IS_MOBILE
      ? 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Web-Circles-TopBottom-Mobile.png'
      : 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Web-Circles-TopBottom.png';

  return (
    <Section className="py-0" image={backgroundImage} fluid>
      {children}
    </Section>
  );
};

CirclesBackground.propTypes = {
  children: PropTypes.node,
};

export default CirclesBackground;
