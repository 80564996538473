import React from 'react';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';

import Avatar from '../../common/Avatar';

const ListElement = ({ name, avatar, role, disabled, externalLink, editButton, onEdit, to = '#' }) => (
  <Media className="align-items-center mb-3">
    {avatar ? <Avatar {...avatar} className={`status-${avatar.status}`} /> : null}
    <Media body>
      <h6 className="mb-0 font-weight-light">
        {externalLink ? (
          <>
            <a target="_new" href={to}>
              {disabled ? <del>{name}</del> : <>{name}</>}
            </a>
            <Link className="text-900 ml-1" to="#">
              {editButton ? <FontAwesomeIcon onClick={onEdit} icon={faPencilAlt} /> : null}
            </Link>
          </>
        ) : (
          <Link className="text-900" to={to}>
            {name}
            {editButton ? <FontAwesomeIcon onClick={onEdit} icon={faPencilAlt} /> : null}
          </Link>
        )}
      </h6>
      <p className="text-500 fs--2 mb-0">{role}</p>
    </Media>
  </Media>
);

ListElement.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.shape(Avatar.propTypes),
  role: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  externalLink: PropTypes.string,
  editButton: PropTypes.node,
  onEdit: PropTypes.func,
  to: PropTypes.string,
};

export default ListElement;
