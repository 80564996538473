import React from 'react';

import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { articlesFeedTableFiltersSetLanguages } from '../../../../../actions/articlesFeed';
import SingleSelect from '../../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../../helpers/translate';
import { getArticlesFeedTableFilters } from '../../../../../selectors/feeds/articlesFeed';
import { getLanguagesValueAndLabel } from '../../../../../selectors/languages';

const ArticlesFeedFiltersLanguages = ({ languages, filters, articlesFeedTableFiltersSetLanguages }) => {
  const handleOnChange = (languages) => {
    if (languages) {
      const languageValues = languages.map(({ value }) => value);
      articlesFeedTableFiltersSetLanguages(languageValues);
    } else {
      articlesFeedTableFiltersSetLanguages(null);
    }
  };

  return (
    <Row>
      <Col>
        <FormGroup>
          <Label for="languages">{translate('pages.articles_feed.filters.languages')}</Label>
          <SingleSelect
            name="languages"
            isMulti
            aria-label="lang-select"
            items={languages}
            value={filters.languages ?? []}
            onChange={handleOnChange}
            className="sm-select"
            placeholder={translate('pages.articles_feed.filters.all_languages')}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  languages: getLanguagesValueAndLabel(state),
  filters: getArticlesFeedTableFilters(state),
});

export default connect(mapStateToProps, { articlesFeedTableFiltersSetLanguages })(ArticlesFeedFiltersLanguages);
