import {
  API_CALL,
  SUPERSEARCH_SET_SEARCTHERM,
  SUPERSEARCH_SET_FROMDATE,
  SUPERSEARCH_SET_TODATE,
  SUPERSEARCH_CLEAR,
  SUPERSEARCH_TABLE_CHANGE_PAGE,
  SUPERSEARCH_TABLE_CHANGE_PAGE_SIZE,
  SUPERSEARCH_FETCH_DATA_PAGINATED,
  SUPERSEARCH_TABLE_FIRST_PAGE,
  SUPERSEARCH_FETCH_VOLUME,
  SUPERSEARCH_CLEAR_VOLUME_DATA,
  SUPERSEARCH_SET_LANGUAGES,
  SUPERSEARCH_SET_MIN_SCORE,
  SUPERSEARCH_SET_MAX_SCORE,
  SUPERSEARCH_CLEAR_TABLE_DATA,
} from './actionTypes';

export const superSearchFetchDataPaginated = ({
  searchTerm,
  fromDate,
  toDate,
  pageSize = 25,
  searchAfter,
  organizationId,
  languages,
  mediaScore,
  sortFields,
  sortOrder
}) => ({
  type: API_CALL,
  meta: {
    type: SUPERSEARCH_FETCH_DATA_PAGINATED,
    endpoint: 'supersearch',
    method: 'POST',
    payload: {
      data: {
        type: 'supersearch',
        attributes: {
          search: searchTerm,
          from_date: fromDate,
          to_date: toDate,
          page_size: pageSize,
          search_after: searchAfter,
          organization_id: organizationId,
          languages,
          media_score: mediaScore,
          sort_fields: sortFields,
          sort_order: sortOrder
        },
      },
    },
  },
});

export const superSearchSetSearchTerm = (searchTerm) => ({
  type: SUPERSEARCH_SET_SEARCTHERM,
  payload: searchTerm,
});

export const superSearchSetFromDate = (fromDate) => ({
  type: SUPERSEARCH_SET_FROMDATE,
  payload: fromDate,
});

export const superSearchSetToDate = (toDate) => ({
  type: SUPERSEARCH_SET_TODATE,
  payload: toDate,
});

export const superSearchSetLanguages = (languages) => ({
  type: SUPERSEARCH_SET_LANGUAGES,
  payload: languages,
});

export const superSearchSetMinScore = (score) => ({
  type: SUPERSEARCH_SET_MIN_SCORE,
  payload: score,
});
export const superSearchSetMaxScore = (score) => ({
  type: SUPERSEARCH_SET_MAX_SCORE,
  payload: score,
});

export const superSearchClear = () => ({
  type: SUPERSEARCH_CLEAR,
});

export const superSearchTableChangePage = (pageNumber) => ({
  type: SUPERSEARCH_TABLE_CHANGE_PAGE,
  payload: pageNumber,
});

export const superSearchTableFirstPage = () => ({
  type: SUPERSEARCH_TABLE_FIRST_PAGE,
});

export const superSearchTableChangePageSize = (pageSize) => ({
  type: SUPERSEARCH_TABLE_CHANGE_PAGE_SIZE,
  payload: pageSize,
});

export const superSearchFetchVolume = ({ searchTerm, fromDate, toDate, organizationId, languages, mediaScore }) => ({
  type: API_CALL,
  meta: {
    type: SUPERSEARCH_FETCH_VOLUME,
    endpoint: 'supersearch/volume',
    method: 'POST',
    payload: {
      data: {
        type: 'supersearch/volume',
        attributes: {
          search: searchTerm,
          from_date: fromDate,
          to_date: toDate,
          organization_id: organizationId,
          languages,
          media_score: mediaScore,
        },
      },
    },
  },
});

export const superSearchClearVolumeData = () => ({
  type: SUPERSEARCH_CLEAR_VOLUME_DATA,
});

export const superSearchClearTableData = () => ({
  type: SUPERSEARCH_CLEAR_TABLE_DATA,
});
