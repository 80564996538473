import React from 'react';

import PropTypes from 'prop-types';

import '../../../assets/scss/theme/Footprints/components/_loading-cell.scss';

const Loading = ({ pageSize, isTable = false }) => {
  let pageSizePosition = 12;

  if (pageSize > 12 && pageSize < 20) {
    pageSizePosition = 15;
  }

  if (pageSize < 25 && pageSize > 20) {
    pageSizePosition = 25;
  }

  if (pageSize > 25 && pageSize < 50) {
    pageSizePosition = 50;
  }

  const src = isTable
    ? 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Footprints-Loading-Icon-70px.gif'
    : 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Footprints-Loading-Icon.gif';

  return (
    <div className={`loading-cell loading-cell-position-${pageSizePosition}`}>
      <img width="70" src={src} alt="Loading" />
    </div>
  );
};

Loading.propTypes = {
  pageSize: PropTypes.number,
  isTable: PropTypes.bool,
};

Loading.defaultProps = {
  pageSize: 12,
};

export default Loading;
