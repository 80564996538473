import React from 'react';

import { Col, Form, Row } from 'reactstrap';

import YoutubeFeedTableFiltersModalBodyDateFilter from './YoutubeFeedTableFiltersModalBodyDateFilter.jsx';
import YoutubeFeedTableFiltersModalBodyMaxViews from './YoutubeFeedTableFiltersModalBodyMaxViews.jsx';
import YoutubeFeedTableFiltersModalBodyMinViews from './YoutubeFeedTableFiltersModalBodyMinViews.jsx';

const YoutubeFeedTableFiltersModalBody = () => (
  <Form onSubmit={(e) => e.preventDefault()}>
    <Row className="mb-3">
      <Col>
        <YoutubeFeedTableFiltersModalBodyMinViews />
      </Col>
      <Col>
        <YoutubeFeedTableFiltersModalBodyMaxViews />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <YoutubeFeedTableFiltersModalBodyDateFilter />
      </Col>
    </Row>
  </Form>
);

export default YoutubeFeedTableFiltersModalBody;
