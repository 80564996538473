export const getMediaTypesList = (state) => {
  if (state.mediaTypes.response && state.mediaTypes.response.length) {
    return state.mediaTypes.response.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  } else {
    return [];
  }
};
