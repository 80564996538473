import React, { memo, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { connect } from 'react-redux';

import { newReportGetSavedVideos } from '../../actions/reports';
import { savedVideosFetch } from '../../actions/savedVideos';
import Table from '../../components/ICO/Table/Table.jsx';
import translate from '../../helpers/translate';
import columns from '../../pages/SavedVideos/Table/columns.jsx';
import { getCurrentProjectId } from '../../selectors/forms';
import {
  getSavedVideos,
  getSavedVideosIsLoading,
  getSavedVideosPagination,
} from '../../selectors/savedCoverage/savedVideos';

const NewReportSavedVideos = ({
  data = [],
  isLoading,
  savedVideosFetch,
  projectId,
  pagination,
  selectedTags,
  selectedLanguages,
  fromDate,
  toDate,
  newReportGetSavedVideos,
}) => {
  const newReportColumns = columns.filter((columns) => columns.text !== 'Actions');
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    newReportGetSavedVideos({
      projectId,
      filters: {
        tags: selectedTags.map((tag) => tag.value),
        languages: selectedLanguages.map((lang) => lang.value),
        startDate: dayjs(fromDate).format('YYYY-MM-DD'),
        endDate: dayjs(toDate).format('YYYY-MM-DD'),
      },
      pagination: {
        sizePerPage: 5000,
        sortField: 'published_at',
      },
    });
  }, [newReportGetSavedVideos, selectedTags, projectId, fromDate, toDate, selectedLanguages]);

  const onTableChange = (type, { searchText, sizePerPage, page, sortField, sortOrder }) => {
    if (type === 'search' && searchText) {
      savedVideosFetch({
        project_id: projectId,
        filters: {
          tags: selectedTags.map((tag) => tag.value),
          languages: selectedLanguages.map((lang) => lang.value),
          searchTerm: searchText,
          startDate: dayjs(fromDate).format('YYYY-MM-DD'),
          endDate: dayjs(toDate).format('YYYY-MM-DD'),
        },
        pagination: {
          page,
          sizePerPage,
          sortField,
          sortOrder,
        },
      });
    } else if (searchText) {
      savedVideosFetch({
        project_id: projectId,
        filters: {
          tags: selectedTags.map((tag) => tag.value),
          languages: selectedLanguages.map((lang) => lang.value),
          startDate: dayjs(fromDate).format('YYYY-MM-DD'),
          endDate: dayjs(toDate).format('YYYY-MM-DD'),
          searchTerm: searchText,
        },
        pagination: {
          page,
          sizePerPage,
          sortField,
          sortOrder,
        },
      });
    } else {
      savedVideosFetch({
        project_id: projectId,
        filters: {
          tags: selectedTags.map((tag) => tag.value),
          languages: selectedLanguages.map((lang) => lang.value),
          startDate: dayjs(fromDate).format('YYYY-MM-DD'),
          endDate: dayjs(toDate).format('YYYY-MM-DD'),
          searchTerm: searchText,
        },
        pagination: {
          page,
          sizePerPage,
          sortField,
          sortOrder,
        },
      });
    }
  };

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    savedVideosFetch({
      project_id: projectId,
      filters: {
        tags: selectedTags.map((tag) => tag.value),
        languages: selectedLanguages.map((lang) => lang.value),
        startDate: dayjs(fromDate).format('YYYY-MM-DD'),
        endDate: dayjs(toDate).format('YYYY-MM-DD'),
      },
      pagination: {
        page: pagination.page,
        sizePerPage: newSize,
      },
    });
  };

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  return (
    <Table
      data={data || []}
      isLoading={isLoading}
      title={translate('reports.videos')}
      columns={newReportColumns}
      onTableChange={onTableChange}
      pagination={paginationWithExtra}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getSavedVideos(state),
  isLoading: getSavedVideosIsLoading(state),
  projectId: getCurrentProjectId(state),
  pagination: getSavedVideosPagination(state),
  selectedTags: state.reports.selectedTags,
  selectedLanguages: state.reports.selectedLanguages,
  fromDate: state.forms.newReportForm.fields.from,
  toDate: state.forms.newReportForm.fields.to,
});
export default memo(connect(mapStateToProps, { savedVideosFetch, newReportGetSavedVideos })(NewReportSavedVideos));
