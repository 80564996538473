import { connect } from 'react-redux';

import EditActions from './EditActions';
import { editClient } from '../../../actions/clients';
import { changeClientForm, showModal } from '../../../actions/forms';
import { fetchProjects } from '../../../actions/projects';
import { getClient } from '../../../selectors/clients';
import { toastifyResult } from '../modals/ModalRoot';

const mapStateToProps = (state, ownProps) => {
  const currentClient = getClient(state, ownProps.id);
  return {
    id: ownProps.id,
    data: currentClient,
    canLock: true,
    lockLabel: 'archive',
    type: 'brand',
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export const onClientEdit = (dispatch, data) => {
  dispatch(showModal({ content: 'editClient' }));
  dispatch(changeClientForm({ field: 'id', value: data.id }));
  dispatch(changeClientForm({ field: 'name', value: data.name }));
  dispatch(changeClientForm({ field: 'avatar', value: data.avatar }));
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onEdit: () => {
    const { dispatch } = dispatchProps;
    const { data } = stateProps;
    onClientEdit(dispatch, data);
  },
  onLock: () => {
    const { dispatch } = dispatchProps;
    dispatch(
      editClient({
        id: ownProps.id,
        locked: true,
      }),
    ).then((res) => {
      dispatch(fetchProjects());
      toastifyResult(res, 'Brand has been archived. Bye bye!');
    });
  },
  onUnlock: () => {
    const { dispatch } = dispatchProps;
    dispatch(
      editClient({
        id: ownProps.id,
        locked: false,
      }),
    ).then((res) => {
      dispatch(fetchProjects());
      toastifyResult(res, 'Brand has been activated!');
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditActions);
