import React from 'react';

import clone from 'lodash/clone';
import Label from 'reactstrap/lib/Label';

import SingleSelect from '../forms/SingleSelect';

const scoreRange = [
  { value: 0.1, label: '0.1' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const MinScoreFilter = ({ filters = {}, stateAction = () => {}, labelText = 'Min. score filter' }) => {
  const copyScore = clone(scoreRange);
  const filteredItems = copyScore.filter((item) => item.value < filters.maxScore);

  return (
    <>
      <Label>{labelText}</Label>
      <SingleSelect
        aria-label="single-select"
        items={filteredItems.length ? filteredItems : scoreRange}
        value={filters.minReach ?? []}
        onChange={({ value }) => stateAction(value)}
      />
    </>
  );
};

export default MinScoreFilter;
