import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { reportDetailsFetchStreams, reportsStreamsClean } from '../../../actions/reports';
import Table from '../../../components/ICO/Table/Table.jsx';
import translate from '../../../helpers/translate';
import {
  getReportStreamsData,
  getReportStreamsIsLoading,
  getReportStreamsPagination,
} from '../../../selectors/reports';
import columns from '../../SavedStreams/Table/columns.jsx';

const ReportDetailsStreams = ({
  data = [],
  isLoading,
  pagination,
  currentReport,
  isPublicReport,
  reportDetailsFetchStreams,
  reportsStreamsClean,
}) => {
  const reportStreamsColumns = columns.filter((columns) => columns.text !== 'Actions');
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (currentReport['reports-streams']) {
      reportDetailsFetchStreams({
        report_id: currentReport.id,
        pagination: { sizePerPage: 10, page: 1, sortField: 'published_at' },
        isPublicRoute: isPublicReport,
      });
    }
    return () => {
      reportsStreamsClean();
    };
  }, [isPublicReport, currentReport, reportDetailsFetchStreams, reportsStreamsClean]);

  const onTableChange = (type, { searchText, sizePerPage, page, sortField, sortOrder }) => {
    const sortFieldExists = sortField ?? 'published_at';

    if (type === 'search' && searchText) {
      reportsStreamsClean();
      reportDetailsFetchStreams({
        report_id: currentReport.id,
        filters: {
          searchTerm: searchText,
        },
        isPublicRoute: isPublicReport,
      });
    } else {
      reportDetailsFetchStreams({
        report_id: currentReport.id,
        pagination: {
          page,
          sizePerPage,
          sortField: sortFieldExists,
          sortOrder,
        },
        isPublicRoute: isPublicReport,
      });
    }
  };

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    reportDetailsFetchStreams({
      report_id: currentReport.id,
      pagination: {
        page: pagination.page,
        sizePerPage: newSize,
        sortField: 'published_at',
      },
      isPublicRoute: isPublicReport,
    });
  };

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  return (
    <Table
      data={data}
      isLoading={isLoading}
      title={translate('reports.streams')}
      columns={reportStreamsColumns}
      onTableChange={onTableChange}
      pagination={paginationWithExtra}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getReportStreamsData(state),
  isLoading: getReportStreamsIsLoading(state),
  pagination: getReportStreamsPagination(state),
});

export default connect(mapStateToProps, { reportDetailsFetchStreams, reportsStreamsClean })(ReportDetailsStreams);
