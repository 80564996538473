import React from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { savedStreamsFetch } from '../../../../actions/savedStreams';
import translate from '../../../../helpers/translate';
import { getCurrentProjectId } from '../../../../selectors/forms';
import { getSavedStreamsFilters, getSavedStreamsPageSize } from '../../../../selectors/savedCoverage/savedStreams';

const SavedStreamsFiltersModalFooter = ({ onClose, savedStreamsFetch, filters, projectId, sizePerPage = 10 }) => {
  const onClick = () => {
    savedStreamsFetch({
      project_id: projectId,
      filters,
      pagination: { page: 1, sizePerPage, sortField: 'published_at' },
    });
    onClose();
  };

  return (
    <>
      <Button size="sm" onClick={onClose} className="fp-button-close">
        {translate('common.close')}
      </Button>
      <Button size="sm" type="submit" onClick={onClick} color="primary" name="saveButton">
        {translate('common.apply')}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  projectId: getCurrentProjectId(state),
  filters: getSavedStreamsFilters(state),
  sizePerPage: getSavedStreamsPageSize(state),
});

export default connect(mapStateToProps, { savedStreamsFetch })(SavedStreamsFiltersModalFooter);
