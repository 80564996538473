import React from 'react';

import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';

import translate from '../../helpers/translate';

const SignupInvite = ({ invite }) => (
  <>
    <Row className="d-flex px-md-6 pb-3">
      <Col className="align-content-center text-center">
        <Row className="justify-content-center">
          <h2>{translate('signup.common.title')}</h2>
        </Row>
        <Row className="mt-4 mb-4">
          <p className="fs-2 ">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            You've been invited to join {invite.organization} on Footprints. Create your account to get started.
          </p>
        </Row>
        <Row className="justify-content-center">
          <Button color="primary" className="px-5" type="submit" transform="down-1 shrink-4">
            {translate('signup.common.set_up_account')}
          </Button>
        </Row>
      </Col>
    </Row>
  </>
);

SignupInvite.propTypes = {
  invite: PropTypes.shape({
    status: PropTypes.string,
    organization: PropTypes.string,
    key: PropTypes.string,
    expires: PropTypes.string,
  }),
};
export default SignupInvite;
