import React from 'react';

import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { savedVideosTableFiltersSetLanguages } from '../../../../actions/savedVideos';
import SingleSelect from '../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../helpers/translate';
import { getLanguagesValueAndLabel } from '../../../../selectors/languages';
import { getSavedVideosFiltersLanguages } from '../../../../selectors/savedCoverage/savedVideos';

const SavedVideosFiltersLanguages = ({ languages, selectedLanguages, savedVideosTableFiltersSetLanguages }) => {
  const handleOnChange = (languages) => {
    if (languages) {
      const languageValues = languages.map(({ value }) => value);
      savedVideosTableFiltersSetLanguages(languageValues);
    } else {
      savedVideosTableFiltersSetLanguages(null);
    }
  };

  return (
    <Row>
      <Col>
        <FormGroup>
          <Label for="languages">{translate('pages.saved_videos.table.filters.languages')}</Label>
          <SingleSelect
            name="languages"
            isMulti
            aria-label="lang-select"
            items={languages}
            value={selectedLanguages ?? []}
            onChange={handleOnChange}
            className="sm-select"
            placeholder={translate('pages.saved_videos.table.filters.all_languages')}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  languages: getLanguagesValueAndLabel(state),
  selectedLanguages: getSavedVideosFiltersLanguages(state),
});

export default connect(mapStateToProps, { savedVideosTableFiltersSetLanguages })(SavedVideosFiltersLanguages);
