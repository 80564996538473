import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, CardDeck } from 'reactstrap';

import ArticlesChart from '../../../components/ICO/charts/ArticlesChart';
import ArticlesLangChart from '../../../components/ICO/charts/ArticlesLangChart';
import ArticlesTagChart from '../../../components/ICO/charts/ArticlesTagChart';

const ReportDetailsChart = ({ reportId }) => (
  <>
    <Card className="mb-3 mt-3">
      <CardBody>
        <ArticlesChart reportId={reportId} />
      </CardBody>
    </Card>
    <CardDeck>
      <Card className="mb-3 mt-3" xs={12}>
        <CardBody>
          <ArticlesLangChart reportId={reportId} />
        </CardBody>
      </Card>
      <Card className="mb-3 mt-3" xs={12}>
        <CardBody>
          <ArticlesTagChart reportId={reportId} />
        </CardBody>
      </Card>
    </CardDeck>
  </>
);

ReportDetailsChart.propTypes = {
  reportId: PropTypes.string,
};

export default memo(ReportDetailsChart);
