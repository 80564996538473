import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Card, CardBody, CardFooter, CardHeader, Form, Button } from 'reactstrap';

import SignUpFreeUserBrandProject from './SignUpFreeUserBrandProject';
import SignUpFreeUserOrganization from './SignUpFreeUserOrganization';
import SignUpFreeUserResult from './SignUpFreeUserResult';
import SignUpFreeUserStart from './SignUpFreeUserStart';
import SignUpFreeUserSteps from './SignUpFreeUserSteps';
import WizardModal from '../../../components/auth/wizard/WizardModal';
import ButtonIcon from '../../../components/common/ButtonIcon';
import AppContext, { AuthWizardContext } from '../../../context/Context';
import translate from '../../../helpers/translate';
import SignUpUserProfile from '../SignUpUserProfile';

const SignUpFreeUser = () => {
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const { register, handleSubmit, errors, watch, triggerValidation, setValue } = useForm();
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);

  const onSubmitData = (data) => {
    setUser({ ...user, ...data });
    setStep(step + 1);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = (targetStep) => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  const handleCaptcha = (value) => {
    if (value) {
      setNextButtonDisabled(true);
    }
  };

  return (
    <>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard fp-signup">
        <CardHeader>
          <SignUpFreeUserSteps step={step} handleBackStep={handleBackStep} />
        </CardHeader>
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3 min-vh-25 fp-signup__card-body">
          {step === 1 && <SignUpFreeUserStart />}
          {step === 2 && (
            <SignUpUserProfile
              register={register}
              errors={errors}
              watch={watch}
              handleCaptcha={handleCaptcha}
              withCaptcha
            />
          )}
          {step === 3 && <SignUpFreeUserOrganization register={register} errors={errors} watch={watch} />}
          {step === 4 && (
            <SignUpFreeUserBrandProject
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              trigger={() => triggerValidation()}
            />
          )}
          {step === 5 && <SignUpFreeUserResult />}
        </CardBody>
        <CardFooter className={classNames('px-md-6', { 'd-none': step === 5, ' d-flex': step < 5 })}>
          <ButtonIcon
            color="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 font-weight-semi-bold', { 'd-none': step === 1 })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            {translate('signup.common.prev')}
          </ButtonIcon>
          {step !== 1 && (
            <Button color="primary" className="ml-auto px-5" type="submit" disabled={!isNextButtonDisabled}>
              {translate('signup.common.next')}
            </Button>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

export default SignUpFreeUser;
