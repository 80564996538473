import React from 'react';

import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#dfdfdf',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.3125rem 1rem',
    fontSize: '0.875rem',
    minHeight: 'calc(1.5em + .625rem + 2px)',
  }),
  menuList: (provided) => ({
    ...provided,
    color: '#40404A',
  }),
};

export const InputSelect = ({ onChange, name, items, disabled, value, placeholder, ...props }) => {
  const handleChange = (newValue) => {
    onChange(newValue, name);
  };

  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#5ca6bd',
      primary25: '#f2ce88',
      primary50: '#5ca6bd',
      neutral80: '#999999',
    },
  });

  return (
    <CreatableSelect
      isClearable
      onChange={handleChange}
      options={items}
      isDisabled={disabled}
      value={value}
      placeholder={placeholder}
      styles={customStyles}
      theme={theme}
      {...props}
    />
  );
};

InputSelect.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
