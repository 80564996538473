import React, { useState } from 'react';

import ButtonIcon from '../../../../components/common/ButtonIcon';
import translate from '../../../../helpers/translate';
import ArticlesFeedModalsEditFeed from '../Modals/EditFeed/ArticlesFeedModalsEditFeed.jsx';

const ArticlesFeedTableEditFeed = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ButtonIcon
        icon="pencil"
        transform="shrink-3 down-2"
        color="primary"
        size="sm"
        className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
        onClick={() => setModalOpen(true)}
      >
        {translate('pages.articles_feed.edit_feed')}
      </ButtonIcon>
      <ArticlesFeedModalsEditFeed isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default ArticlesFeedTableEditFeed;
