import React, { useCallback, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, Button, CardHeader, CardFooter } from 'reactstrap';

import SettingsAddOrganisationModal from './SettingsAddOrganizationModal';
import SettingsAvatarModal from './SettingsAvatarModal';
import { saveAvatar } from '../../actions/files';
import { showModal, changeUserForm } from '../../actions/forms';
import { settingsAddOrganisation } from '../../actions/settings';
import { fetchCurrentUser, saveUserSettings } from '../../actions/users';
import getToastedNotification from '../../components/ICO/helpers/toaster.helper';
import AvatarTitle from '../../components/ICO/text-display/AvatarTitle';
import { STATUS } from '../../config/api-service';
import { APP_ROOT, IMAGES_ROOT } from '../../config/global-config';
import getUserTierName from '../../helpers/getUserTierName';
import translate from '../../helpers/translate';
import { getFormsUserForm } from '../../selectors/forms';
import {
  getOrganizationAvatar,
  getOrganizationName,
  getOrganizationIsSlackEnabled,
  getOrganizationId,
} from '../../selectors/organizations';
import { getOrganizationPermissionsHasSlack } from '../../selectors/permissions';
import {
  getSettingsAddOrganisationIsLoading,
  getSettingsAddOrganisationResponse,
  getSettingsAddOrganisationError,
} from '../../selectors/settings';
import {
  getUsersCurrentUser,
  getUsersCurrentUserAvatarFiltered,
  getUsersCurrentUserIsAdmin,
  getUsersUserStatus,
} from '../../selectors/users';

import '../../assets/scss/theme/Footprints/screens/_settings.scss';

export const slackRedirectUri = APP_ROOT + '/slack_callback';

const Settings = ({
  changeUserForm,
  fetchCurrentUser,
  formFields,
  internalUser,
  internalUserAvatar,
  organizationAvatar,
  organizationId,
  organizationName,
  organizationSlackEnabled,
  saveAvatar,
  saveUserSettings,
  showModal,
  userStatus,
  userIsAdmin,
  organizationHasSlack,
  settingsAddOrganisation,
  addOrganisationError,
  addOrganisationResponse,
}) => {
  const userTierName = getUserTierName(internalUser['tier-id']);
  const { user } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [isAddOrganisationModalOpen, setIsAddOrganisationModalOpen] = useState(false);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [newOrganizationName, setNewOrganizationName] = useState(null);
  const [saveOrganisationButtonDisabled, setSaveOrganisationButtonDisabled] = useState(true);

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  useEffect(() => {
    if (addOrganisationResponse) {
      const customMessage = {
        type: 'success',
        message: translate('settings.add_organizaton_modal.add_organisation_success'),
      };
      getToastedNotification({ customMessage });
    }
  }, [addOrganisationResponse]);

  useEffect(() => {
    if (addOrganisationError) {
      const customMessage = {
        type: 'error',
        message: translate('settings.add_organizaton_modal.add_organisation_error'),
      };
      getToastedNotification({ customMessage });
    }
  }, [addOrganisationError]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(!isOpen);
    setSaveButtonDisabled(true);
  }, [isOpen]);
  const handleClickInvite = () => {
    showModal({ content: 'inviteOrg' });
  };

  const handleChangeAvatar = useCallback(
    (data) => {
      const isSameAvatar = isEqual(data[0].name, internalUserAvatar);
      setSaveButtonDisabled(isSameAvatar);

      if (data) {
        changeUserForm({ name: user.name, value: data[0].name });
        saveAvatar({
          images: data,
          scope: `${organizationId};usr`,
        });
      }
    },
    [internalUserAvatar, changeUserForm, saveAvatar, organizationId, user.name],
  );

  const handleSaveUser = useCallback(() => {
    const id = internalUser.id;
    const userData = formFields;
    saveUserSettings({ id, userData });
    fetchCurrentUser();

    if (userStatus === STATUS.OK) {
      const customMessage = { type: 'success', message: 'User settings updated correctly' };
      getToastedNotification({ customMessage });
    }

    if (userStatus === STATUS.KO) {
      const customMessage = { type: 'error', message: 'Something went wrong' };
      getToastedNotification({ customMessage });
    }

    setIsOpen(!isOpen);
    setSaveButtonDisabled(true);
  }, [isOpen, userStatus, internalUser.id, formFields, fetchCurrentUser, saveUserSettings]);

  const handleCloseAddOrganisationModal = () => {
    setNewOrganizationName(null);
    setIsAddOrganisationModalOpen(!isAddOrganisationModalOpen);
    setSaveOrganisationButtonDisabled(true);
  };

  const handleOrganizationFormChange = (element) => {
    setNewOrganizationName(element.target.value);

    if (element.target.value) {
      setSaveOrganisationButtonDisabled(false);
    }

    if (!element.target.value) {
      setSaveOrganisationButtonDisabled(true);
    }
  };

  const handleSaveOrganization = () => {
    settingsAddOrganisation(newOrganizationName);
    setIsAddOrganisationModalOpen(!isAddOrganisationModalOpen);
    setSaveOrganisationButtonDisabled(true);
    setNewOrganizationName(null);
  };

  const renderInviteButton = () => (
    <Button aria-label="invite-button" color="primary" size="sm" onClick={handleClickInvite}>
      Invitation Link
    </Button>
  );

  const renderSlackButton = () => {
    if (organizationSlackEnabled) {
      return <span className="text-soft-gray">Slack is enabled for this organization.</span>;
    } else {
      const linkUrl =
        'https://slack.com/oauth/v2/authorize?client_id=2347408312.1185888133025&scope=channels:join,channels:manage,channels:read,chat:write,chat:write.customize,groups:read,im:history&redirect_uri=' +
        slackRedirectUri +
        '&user_scope=';

      return (
        <a href={linkUrl} aria-label="slack-link">
          <img
            alt="Add to Slack"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      );
    }
  };

  const avatarSrc = `${IMAGES_ROOT}${organizationAvatar}`;

  const renderAdminButtons = () => {
    if (userIsAdmin) {
      return (
        <>
          {organizationHasSlack ? renderSlackButton() : null}
          <br />
          <br />
          {renderInviteButton()}
        </>
      );
    }
  };

  return (
    <Card className="fp-settings">
      <CardHeader>
        <h3>Settings</h3>
      </CardHeader>
      <CardBody>
        <h5>Account</h5>
        <div className="align-middle">
          <AvatarTitle imgSrc={internalUser.avatar} title={user.name} />
          <div className="fs--1 cursor-pointer wedgewood" onClick={() => setIsOpen(true)}>
            Change profile picture
          </div>
          <SettingsAvatarModal
            currentImage={internalUser.avatar}
            isOpen={isOpen}
            imageUploaderData={formFields}
            onChange={handleChangeAvatar}
            onClose={handleCloseModal}
            onSave={handleSaveUser}
            isSaveButtonDisabled={isSaveButtonDisabled}
          />
        </div>
        <h5 className="mt-4">Organization</h5>
        <div className="mb-0 align-middle">
          <AvatarTitle imgSrc={avatarSrc} title={organizationName} />
          <h6>
            {translate('settings.organization_plan')} <strong>{userTierName}</strong>
          </h6>
        </div>
        {renderAdminButtons()}
        <SettingsAddOrganisationModal
          isOpen={isAddOrganisationModalOpen}
          onClose={handleCloseAddOrganisationModal}
          onSave={handleSaveOrganization}
          onChange={handleOrganizationFormChange}
          isSaveButtonDisabled={saveOrganisationButtonDisabled}
        />
        <hr />
        <CardFooter className="fs--1">
          {translate('settings.alpha_contact')}
          <a href={`mailto: ${translate('common.footprints_email')}`}>{translate('common.footprints_email')}</a>.
        </CardFooter>
      </CardBody>
    </Card>
  );
};

Settings.propTypes = {
  showModal: PropTypes.func,
  fetchCurrentUser: PropTypes.func,
  organizationAvatar: PropTypes.string,
  organizationName: PropTypes.string,
  organizationId: PropTypes.number,
  organizationSlackEnabled: PropTypes.bool,
  internalUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    roles: PropTypes.string,
    avatar: PropTypes.string,
    'tier-id': PropTypes.number,
  }),
  changeUserForm: PropTypes.func,
  saveAvatar: PropTypes.func,
  formFields: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  saveUserSettings: PropTypes.func,
  userStatus: PropTypes.string,
  internalUserAvatar: PropTypes.string,
  organizationHasSlack: PropTypes.bool,
  settingsAddOrganisation: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formFields: getFormsUserForm(state),
  internalUser: getUsersCurrentUser(state),
  internalUserAvatar: getUsersCurrentUserAvatarFiltered(state),
  organizationAvatar: getOrganizationAvatar(state),
  organizationId: getOrganizationId(state),
  organizationName: getOrganizationName(state),
  organizationSlackEnabled: getOrganizationIsSlackEnabled(state),
  userStatus: getUsersUserStatus(state),
  userIsAdmin: getUsersCurrentUserIsAdmin(state),
  organizationHasSlack: getOrganizationPermissionsHasSlack(state),
  addOrganisationIsLoading: getSettingsAddOrganisationIsLoading(state),
  addOrganisationResponse: getSettingsAddOrganisationResponse(state),
  addOrganisationError: getSettingsAddOrganisationError(state),
});

export default connect(mapStateToProps, {
  saveUserSettings,
  changeUserForm,
  fetchCurrentUser,
  saveAvatar,
  showModal,
  settingsAddOrganisation,
})(Settings);
