import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import ReportsTable from './ReportsTable';
import { fetchReports } from '../../actions/reports';
import { getCurrentProjectId } from '../../selectors/forms';

const Reports = ({ fetchReports, currentProjectId }) => {
  useEffect(() => {
    fetchReports(currentProjectId);
  }, [fetchReports, currentProjectId]);

  return (
    <Row noGutters>
      <Col lg={12} className="mb-3 pr-lg-2 mb-3">
        <ReportsTable title="Reports" tableId="reportsTable" rowsType="reports" />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  currentProjectId: getCurrentProjectId(state),
});

export default connect(mapStateToProps, { fetchReports })(Reports);
