import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';

import OrganizationDetailsForm from './OrganizationDetailsForm';
import OrganizationDetailsUsersTable from './OrganizationDetailsUsersTable';
import { getOrganizationDetailsName } from '../../selectors/organizationDetails';

import '../../assets/scss/theme/Footprints/screens/_organization-details.scss';

const OrganizationDetails = ({ organizationName }) => (
  <>
    <Card className="mb-3 fp-organization-details">
      <CardHeader>
        <h3>{organizationName} details</h3>
      </CardHeader>
      <CardBody>
        <OrganizationDetailsForm />
      </CardBody>
    </Card>
    <OrganizationDetailsUsersTable
      urlParams={{}}
      title="Members"
      tableId="usersTable"
      rowsType="users"
      newButton={false}
      archiveSelection={false}
    />
  </>
);

const mapStateToProps = (state) => ({
  organizationName: getOrganizationDetailsName(state),
});

OrganizationDetails.propTypes = {
  organizationName: PropTypes.string,
};

OrganizationDetails.defaultProps = {
  organizationName: '',
};

export default connect(mapStateToProps)(OrganizationDetails);
