import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ArticlesFeedSaveArticleModalBody from './ArticlesFeedSaveArticleModalBody.jsx';
import { fetchArticlesFeed } from '../../../../../actions/articlesFeed';
import translate from '../../../../../helpers/translate';
import {
  getArticlesFeed,
  getArticlesFeedPagination,
  getArticlesFeedTableFilters,
} from '../../../../../selectors/feeds/articlesFeed';
import { getCurrentProject } from '../../../../../selectors/projects';
import { getNotLockedTags } from '../../../../../selectors/tags';
import { useSaveArticles } from '../../hooks/useArticlesFeed';

const ArticlesFeedSaveArticleModal = ({
  isOpen,
  onClose,
  tags,
  selectedRows,
  fetchArticlesFeed,
  project,
  filters,
  pagination,
  sortField,
}) => {
  const [selectedTag, setSelectedTag] = useState(null);

  const onSaveSuccess = () => {
    fetchArticlesFeed({
      alert_id: project['alert-id'],
      pagination: { sizePerPage: pagination.sizePerPage, page: pagination.page, sortField },
      filters,
    });
  };

  const { mutate } = useSaveArticles(onSaveSuccess);

  const onClickSave = () => {
    const rows = selectedRows[0]?.id ? [selectedRows[0]?.id] : selectedRows;

    mutate({
      data: {
        type: 'articles_rss_ids',
        attributes: {
          ids: rows,
          tag_id: selectedTag.value,
          alert_id: project['alert-id'],
        },
      },
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
      <ModalHeader className="fp-modal-header--small">Save {selectedRows.length} article(s)</ModalHeader>
      <ModalBody className="fp-modal-body">
        <ArticlesFeedSaveArticleModalBody setSelectedTag={setSelectedTag} selectedTag={selectedTag} tags={tags} />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button size="sm" type="submit" onClick={onClickSave} color="primary" name="saveButton" disabled={!selectedTag}>
          {translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  filters: getArticlesFeedTableFilters(state),
  project: getCurrentProject(state),
  tags: getNotLockedTags(state),
  pagination: getArticlesFeedPagination(state),
  data: getArticlesFeed(state),
  sortField: state.articlesFeed.sortField,
});

export default connect(mapStateToProps, { fetchArticlesFeed })(ArticlesFeedSaveArticleModal);
