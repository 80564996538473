import { fetchRssArticlesAlert } from './feeds';
import { tableActions } from './forms';
import { changePage } from './modules';
import { fetchWebsites } from './websites';
import { fetchAction } from '../components/ICO/tables/helpers/actions';
import {
  apiFetch,
  buildFiltersQuery,
  defaultCSVRequestOptions,
  defaultRequestOptions,
  genURL,
} from '../config/api-service';
import { COVERAGE_API_ROOT, DIFFBOT_CLIENT_API_ROOT } from '../config/global-config';
import 'isomorphic-fetch';

export const REQUEST_ARTICLES = 'REQUEST_ARTICLES';
export const RECEIVE_ARTICLES = 'RECEIVE_ARTICLES';
export const REJECT_ARTICLES = 'REJECT_ARTICLES';
export const REQUEST_ARTICLES_PREVIEW = 'REQUEST_ARTICLES_PREVIEW';
export const RECEIVE_ARTICLES_PREVIEW = 'RECEIVE_ARTICLES_PREVIEW';
export const REJECT_ARTICLES_PREVIEW = 'REJECT_ARTICLES_PREVIEW';
export const RECEIVE_DELETED_ARTICLE = 'RECEIVE_DELETED_ARTICLE';
export const REQUEST_ARTICLES_GROUPS = 'REQUEST_ARTICLES_GROUPS';
export const RECEIVE_ARTICLES_GROUPS = 'RECEIVE_ARTICLES_GROUPS';
export const REJECT_ARTICLES_GROUPS = 'REJECT_ARTICLES_GROUPS';
export const REQUEST_EDIT_ARTICLE = 'REQUEST_EDIT_ARTICLE';
export const REJECT_EDIT_ARTICLE = 'REJECT_EDIT_ARTICLE';
export const RECEIVE_EDIT_ARTICLE = 'RECEIVE_EDIT_ARTICLE';
export const REQUEST_SAVE_ARTICLE = 'REQUEST_SAVE_ARTICLE';
export const REJECT_SAVE_ARTICLE = 'REJECT_SAVE_ARTICLE';
export const RECEIVE_SAVE_ARTICLE = 'RECEIVE_SAVE_ARTICLE';
export const REQUEST_SAVE_ARTICLE_BULK = 'REQUEST_SAVE_ARTICLE_BULK';
export const REJECT_SAVE_ARTICLE_BULK = 'REJECT_SAVE_ARTICLE_BULK';
export const RECEIVE_SAVE_ARTICLE_BULK = 'RECEIVE_SAVE_ARTICLE_BULK';

export const fetchArticles = (
  params = {
    page: {
      page: 0,
      page_size: 25,
    },
  },
) =>
  apiFetch(
    'articles',
    {
      request: {
        type: REQUEST_ARTICLES,
        payload: {},
      },
      receive: RECEIVE_ARTICLES,
      reject: REJECT_ARTICLES,
    },
    params,
  );

export const fetchArticlesFiltered = (params) =>
  apiFetch(
    'articles',
    {
      request: {
        type: REQUEST_ARTICLES,
        payload: {},
      },
      receive: RECEIVE_ARTICLES,
      reject: REJECT_ARTICLES,
    },
    params,
  );
export const fetchArticlesGroups = (params) =>
  apiFetch(
    'articles',
    {
      request: {
        type: REQUEST_ARTICLES_GROUPS,
        payload: {},
      },
      receive: RECEIVE_ARTICLES_GROUPS,
      reject: REJECT_ARTICLES_GROUPS,
    },
    params,
  );

export const fetchArticlePreview = (params = {}) => {
  const finalUrl = encodeURIComponent(params.url);
  return apiFetch(
    'articles',
    {
      request: {
        type: REQUEST_ARTICLES_PREVIEW,
        payload: {},
      },
      receive: RECEIVE_ARTICLES_PREVIEW,
      reject: REJECT_ARTICLES_PREVIEW,
    },
    { id: params.id, url: finalUrl },
    {
      method: 'GET',
      urlRoot: DIFFBOT_CLIENT_API_ROOT,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  );
};

export const saveArticle = ({
  url = 'test',
  fullUrl = 'https://test.com',
  title = '',
  language: langId = 0,
  reach = 0,
  tagId = 0,
  websiteId = 0,
  date,
}) =>
  apiFetch(
    'articles',
    {
      request: {
        type: REQUEST_SAVE_ARTICLE,
        payload: {
          url: url,
          fullUrl: fullUrl,
          title: title,
          language: langId,
          reach: reach,
          date: date,
          website_id: websiteId,
        },
      },
      receive: RECEIVE_SAVE_ARTICLE,
      reject: REJECT_SAVE_ARTICLE,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'articles',
          attributes: {
            url: url,
            title: title,
            fullUrl: fullUrl,
            language: langId,
            reach: reach,
            date: date,
            website_id: websiteId,
          },
          relationships: {
            tag: {
              data: {
                type: 'tag',
                id: tagId,
              },
            },
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const editArticle = (
  data = {
    id: -1,
    title: 'Test Project Test',
    langId: 'unknown',
    reach: 0.1,
    tagId: 0,
    date: '2010-01-01',
  },
) =>
  apiFetch(
    `articles/${data.id}`,
    {
      request: {
        type: REQUEST_EDIT_ARTICLE,
        payload: data,
      },
      receive: RECEIVE_EDIT_ARTICLE,
      reject: REJECT_EDIT_ARTICLE,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'articles',
          attributes: {
            title: data.title,
            language: data.langId,
            reach: data.reach,
            date: data.date,
          },
          relationships: {
            tag: {
              data: {
                type: 'tag',
                id: data.tagId,
              },
            },
          },
        },
      }),
    },
    {
      method: 'PATCH',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const saveArticlesBulk = (
  data = {
    tagId: 0,
    linksUrls: [],
  },
) =>
  apiFetch(
    'bulk/articles',
    {
      request: {
        type: REQUEST_SAVE_ARTICLE_BULK,
        payload: data,
      },
      receive: RECEIVE_SAVE_ARTICLE_BULK,
      reject: REJECT_SAVE_ARTICLE_BULK,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'articles',
          attributes: {
            urls: data.linksUrls,
          },
          relationships: {
            tag: {
              data: {
                type: 'tag',
                id: data.tagId,
              },
            },
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const bulkDeleteArticlesFeed = ({ ids = [], alertId }) =>
  apiFetch(
    'bulk/articles',
    {
      request: {
        type: REQUEST_SAVE_ARTICLE_BULK,
        payload: {
          ids,
          alert: alertId,
        },
      },
      receive: RECEIVE_SAVE_ARTICLE_BULK,
      reject: REJECT_SAVE_ARTICLE_BULK,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'delete_articles_rss_ids',
          attributes: {
            ids,
          },
          relationships: {
            alert: {
              data: {
                type: 'alert',
                id: alertId,
              },
            },
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const deleteArticle = (articleId = -1) => {
  const requestOptions = {
    method: 'DELETE',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };
  return apiFetch(
    'articles/' + articleId,
    {
      request: REQUEST_ARTICLES,
      receive: RECEIVE_DELETED_ARTICLE,
      reject: REJECT_ARTICLES,
    },
    { id: articleId },
    requestOptions,
  );
};

export const importArticles = ({ ids = [], tagId, alertId }) =>
  apiFetch(
    'bulk/articles',
    {
      request: {
        type: REQUEST_SAVE_ARTICLE_BULK,
        payload: {
          ids,
          tag: tagId,
          alert: alertId,
        },
      },
      receive: RECEIVE_SAVE_ARTICLE_BULK,
      reject: REJECT_SAVE_ARTICLE_BULK,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'articles_rss_ids',
          attributes: {
            ids,
          },
          relationships: {
            tag: {
              data: {
                type: 'tag',
                id: tagId,
              },
            },
            alert: {
              data: {
                type: 'alert',
                id: alertId,
              },
            },
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const cleanArticleFormData = (data) => {
  if (!data.url.includes('http')) return -1;
  return {
    url: data.url.replace('http://', '').replace('https://', ''),
    fullUrl: data.url,
    title: data.title,
    language: data.language,
    reach: data.reach,
    tagId: data.tagId,
    websiteId: data.websiteId,
  };
};

export const pageClick = (pagination, dispatch, tableId, filters, currentGlobalProject) => {
  dispatch(changePage(tableId, pagination.page));
  let fetchParams = {
    page: pagination,
    filter: buildFiltersQuery(filters),
  };
  if (filters.sort) {
    fetchParams = {
      ...fetchParams,
      sort: filters.sort,
    };
  }
  dispatch(fetchAction[tableId](fetchParams, currentGlobalProject['alert-id']));
};

export const loadFilters = (
  dispatch,
  [field, value],
  filters,
  sort,
  params = { enabled: true, pagination: { page: 1, page_size: 25 }, table: 'articlesTable' },
) => {
  if (params.enabled) {
    //If enabled, a filter has been changed, so we need to change page of the table to 1. Not used for sorting.
    dispatch(changePage(params.table, params.pagination.page));
  }
  let fetchParams = {
    page: params.pagination,
    filter: buildFiltersQuery(filters),
  };
  if (sort) {
    fetchParams = { ...fetchParams, sort };
  }
  if (params.table === 'websitesTable') {
    dispatch(fetchWebsites(fetchParams));
  } else if (params.table === 'articlesWebsiteTable' || params.table === 'articlesTable') {
    dispatch(fetchArticles(fetchParams));
  } else if (params.table === 'articlesFeedTable') {
    dispatch(fetchRssArticlesAlert(params.alertId, fetchParams));
  }
  dispatch(tableActions[params.table].changeFilterForm({ field, value }));
};

export const onChangeFilterTable = (
  filters,
  dispatch,
  newFilter,
  idSelect,
  params = { enabled: true, pagination: { page: 1, page_size: 25 }, table: 'articlesTable' },
) => {
  const onChanges = {
    search: () => {
      loadFilters(dispatch, ['search', newFilter], { ...filters, search: newFilter }, filters.sort, params);
    },
    tags: () => {
      loadFilters(dispatch, ['tags', newFilter], { ...filters, tags: newFilter }, filters.sort, params);
    },
    project: () => {
      loadFilters(dispatch, ['project_id', newFilter], { ...filters, project_id: newFilter }, filters.sort, params);
    },
    mediatypes: () => {
      loadFilters(dispatch, ['mediatypes', newFilter], { ...filters, mediatypes: newFilter }, filters.sort, params);
    },
    languages: () => {
      loadFilters(dispatch, ['languages', newFilter], { ...filters, languages: newFilter }, filters.sort, params);
    },
    ea_only: () => {
      loadFilters(dispatch, ['ea_only', newFilter], { ...filters, ea_only: newFilter }, filters.sort, params);
    },
    regions: () => {
      loadFilters(dispatch, ['regions', newFilter], { ...filters, regions: newFilter }, filters.sort, params);
    },
    countries: () => {
      loadFilters(dispatch, ['countries', newFilter], { ...filters, countries: newFilter }, filters.sort, params);
    },
    date_range: () => {
      loadFilters(dispatch, ['date_range', newFilter], { ...filters, date_range: newFilter }, filters.sort, params);
    },
    score_range: () => {
      loadFilters(dispatch, ['score_range', newFilter], { ...filters, score_range: newFilter }, filters.sort, params);
    },
  };
  return onChanges[idSelect]();
};

export const fetchCSVRows = (typeRows = 'articles', params = {}) => {
  const endpoint = COVERAGE_API_ROOT + '/csv/' + typeRows;
  let endURL = genURL(endpoint, params);
  return fetch(endURL, {
    method: 'GET',
    headers: defaultCSVRequestOptions().headers,
    credentials: 'same-origin',
  });
};
