import React from 'react';

import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import SingleSelect from '../forms/SingleSelect';

const EAMediaFeedFilters = ({
  countries,
  filters,
  onChangeCheckbox,
  onChangeMultiFilter,
  regionFiltersEnabled,
  regions,
}) => {
  const isRegionsVisible = regions && regionFiltersEnabled;
  const isCountriesVisible = countries && regionFiltersEnabled;
  const isEAFilterChecked = filters.ea_only === -1 ? false : filters.ea_only;

  return (
    <>
      {isRegionsVisible && (
        <FormGroup>
          <Label for="regions">Regions</Label>
          <SingleSelect
            name="regions"
            isMulti={true}
            items={regions}
            value={filters.regions || []}
            onChange={onChangeMultiFilter}
            className="sm-select"
            placeholder="All regions"
          />
        </FormGroup>
      )}
      {isCountriesVisible && (
        <FormGroup>
          <Label for="countries">Countries</Label>
          <SingleSelect
            name="countries"
            isMulti={true}
            items={countries}
            noOptionsMessage="Select a region first to filter by countries"
            value={filters.countries || []}
            onChange={onChangeMultiFilter}
            className="sm-select"
            placeholder="All countries"
          />
        </FormGroup>
      )}
      <FormGroup className="form-check">
        <Input
          aria-label="ea-input"
          type="checkbox"
          name="eaOnly"
          id="eaOnly"
          checked={isEAFilterChecked}
          onChange={onChangeCheckbox}
        />
        <Label for="eaOnly" check>
          EA specified outlets
        </Label>
      </FormGroup>
    </>
  );
};

EAMediaFeedFilters.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onChangeCheckbox: PropTypes.func,
  oncChangeMultiFilter: PropTypes.func,
  filters: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    ea_only: PropTypes.number,
  }),
  onChangeMultiFilter: PropTypes.func,
  regionFiltersEnabled: PropTypes.bool,
};

export default EAMediaFeedFilters;
