import React from 'react';

import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';

import { twitchFeedTableFiltersSetMinFollowers } from '../../../../actions/twitchFeed';
import translate from '../../../../helpers/translate';
import { getTwitchFeedTableFilters } from '../../../../selectors/feeds/twitchFeed';

const TwitchFeedTableFiltersModalBodyMinFollowers = ({ twitchFeedTableFiltersSetMinFollowers, filters }) => (
  <>
    <Label for="minFollowers">{translate('pages.twitch_feed.table.filters.min_followers')}</Label>
    <Input
      type="number"
      onChange={(e) => twitchFeedTableFiltersSetMinFollowers(e.target.value)}
      name="minFollowers"
      id="minFollowers"
      placeholder="0"
      value={filters.minFollowers ?? ''}
    />
  </>
);

const mapStateToProps = (state) => ({
  filters: getTwitchFeedTableFilters(state),
});
export default connect(mapStateToProps, { twitchFeedTableFiltersSetMinFollowers })(
  TwitchFeedTableFiltersModalBodyMinFollowers,
);
