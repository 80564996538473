import {
  SAVED_VIDEOS_DELETE_CLEAN,
  SAVED_VIDEOS_DELETE_VIDEOS,
  SAVED_VIDEOS_DELETE_VIDEOS_ERROR,
  SAVED_VIDEOS_DELETE_VIDEOS_FAILURE,
  SAVED_VIDEOS_DELETE_VIDEOS_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: null,
  error: null,
};

const deleteVideos = (state = initialState, action) => {
  switch (action.type) {
    case SAVED_VIDEOS_DELETE_VIDEOS:
      return { ...state, isLoading: true };
    case SAVED_VIDEOS_DELETE_VIDEOS_SUCCESS:
      return { ...state, isLoading: false, response: action.payload };
    case SAVED_VIDEOS_DELETE_VIDEOS_FAILURE:
    case SAVED_VIDEOS_DELETE_VIDEOS_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case SAVED_VIDEOS_DELETE_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default deleteVideos;
