import React from 'react';

import '../../../../assets/scss/theme/Footprints/components/upgrade-subscription/_upgrade-subscription-price-box.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import UpgradeSubscriptionProductPriceBox from './UpgradeSubscriptionProductPriceBox';
import { upgradeSubscriptionSetProductId } from '../../../../actions/checkout';
import {
  comingSoonFeaturesFormatter,
  featureFormatter,
  getTranslation,
} from '../../../../helpers/formatters/upgradeSubscriptionFormatters';
import { getUpgradeSubscriptionSelectedProduct } from '../../../../selectors/upgradeSubscription';

const UpgradeSubscriptionProduct = ({
  productColor,
  title,
  icon,
  selectedProduct,
  monthlyProductId,
  annualProductId,
  monthlyPrice,
  annualPrice,
  features,
  comingSoonFeatures,
  upgradeSubscriptionSetProductId,
}) => {
  const boldClassName = `${productColor} text-bold`;

  return (
    <Col sm={12} md={4}>
      <div className="d-flex flex-center flex-column">
        <img src={icon} className="svg mt-4" alt="product icon" />
        <h2 className={`${boldClassName} no_border mt-3`}>{getTranslation(title)}</h2>
        <Row className="d-flex justify-content-between price-box-container">
          <UpgradeSubscriptionProductPriceBox
            aria-label="monthlyPriceBox"
            onClick={() => upgradeSubscriptionSetProductId(monthlyProductId)}
            price={monthlyPrice}
            productColor={productColor}
            selectedProduct={selectedProduct}
            productId={monthlyProductId}
          />
          <UpgradeSubscriptionProductPriceBox
            aria-label="annualPriceBox"
            isAnnualPrice
            onClick={() => upgradeSubscriptionSetProductId(annualProductId)}
            price={annualPrice}
            productColor={productColor}
            selectedProduct={selectedProduct}
            productId={annualProductId}
          />
        </Row>
        {comingSoonFeatures && comingSoonFeatures.length
          ? [featureFormatter(features), comingSoonFeaturesFormatter(comingSoonFeatures)]
          : featureFormatter(features)}
      </div>
    </Col>
  );
};

UpgradeSubscriptionProduct.propTypes = {
  icon: PropTypes.string,
  productColor: PropTypes.string,
  title: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  comingSoonFeaturesFormatter: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.array]),
};

const mapStateToProps = (state) => ({
  selectedProduct: getUpgradeSubscriptionSelectedProduct(state),
});

export default connect(mapStateToProps, {
  upgradeSubscriptionSetProductId,
})(UpgradeSubscriptionProduct);
