import React from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#dfdfdf',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.3125rem 1rem',
  }),
  menuList: (provided) => ({
    ...provided,
    color: '#40404A',
  }),
};

const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#5ca6bd',
    primary25: '#FDF9F9',
    primary50: '#5ca6bd',
    neutral80: '#999999',
  },
});

const MultiSelect = ({ items, onChange, name, value, placeholder, disabled, ...props }) => {
  const options = items.map((item) => ({
    value: item.value,
    label: item.label,
  }));

  return (
    <Select
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isMulti={true}
      isDisabled={disabled}
      styles={customStyles}
      theme={theme}
      {...props}
    />
  );
};

MultiSelect.propTypes = {
  items: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MultiSelect;
