import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import translate from '../../../helpers/translate';
import { getReport } from '../../../selectors/reports.js';
import { getArticlesFilteredStats } from '../../../selectors/savedCoverage/articles.js';
import SingleMetric from '../text-display/SingleMetric.js';

const TOOLTIP_CONFIG = {
  visible: true,
  text: translate('common.media_score_explained'),
  icon: 'question-circle',
  id: 'media_score',
  delay: {
    show: 0,
    delay: 0,
  },
};

const ScoreMetric = ({ reportId }) => {
  const count = useSelector((state) =>
    reportId ? getReport(state, reportId)['total-reach'] : getArticlesFilteredStats(state).total_reach,
  );

  return (
    <SingleMetric
      count={count}
      icon="station"
      color="media-score"
      title={translate('common.total_media_score')}
      tooltip={TOOLTIP_CONFIG}
    />
  );
};

ScoreMetric.propTypes = {
  reportId: PropTypes.string,
};

ScoreMetric.defaultProps = {
  reportId: '',
};

export default ScoreMetric;
