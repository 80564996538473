import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Switch from 'react-switch';

const CheckboxSwitch = (props) => {
  const [checked, setChecked] = useState(props.checked || false);

  const handleChange = (checked) => {
    setChecked(checked);
  };

  return (
    <Switch
      onChange={handleChange}
      checked={checked}
      {...props}
      offColor="#dfdfdf"
      onColor="#dfdfdf"
      onHandleColor="#E08A32"
      boxShadow="none"
    />
  );
};

CheckboxSwitch.propTypes = {
  checked: PropTypes.bool,
};

CheckboxSwitch.defaultProps = {
  checked: false,
};

export default CheckboxSwitch;
