import {
  UPGRADE_SUBSCRIPTION_SET_PRODUCT_ID,
  UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT,
  API_CALL,
  UPGRADE_SUBSCRIPTION_CLEAR,
} from './actionTypes';

export const checkoutCreateSession = ({ selectedProductId, customerId }) => ({
  type: API_CALL,
  meta: {
    type: UPGRADE_SUBSCRIPTION_STRIPE_CHECKOUT,
    endpoint: 'checkout',
    method: 'POST',
    payload: {
      data: {
        type: 'checkout',
        attributes: {
          items: [
            {
              price: selectedProductId,
              quantity: 1,
            },
          ],
          customer_id: customerId,
        },
      },
    },
  },
});

export const upgradeSubscriptionSetProductId = (productId) => ({
  type: UPGRADE_SUBSCRIPTION_SET_PRODUCT_ID,
  payload: productId,
});

export const upgradeSubscriptionClear = () => ({
  type: UPGRADE_SUBSCRIPTION_CLEAR,
});
