import { connect } from 'react-redux';

import EditActions from './EditActions';
import { deleteArticle } from '../../../actions/articles';
import { changeArticleForm, showModal } from '../../../actions/forms';
import { getTagProjectId } from '../../../selectors/projects';

const mapStateToProps = (state, ownProps) => {
  const currentArticle = ownProps.article;
  return {
    id: currentArticle.id,
    article: currentArticle,
    projectId: getTagProjectId(state, currentArticle.tag_id),
    type: 'article',
    canDelete: true,
    canAnalyze: true,
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onDeleteClick: () => {
    const { dispatch } = dispatchProps;
    const { article } = stateProps;
    dispatch(
      showModal({
        content: 'confirmAction',
        contentId: article.id,
        contentData: { resource: 'article', data: article.title, action: deleteArticle(article.id) },
      }),
    );
  },
  onPreview: () => {
    const { dispatch } = dispatchProps;
    dispatch(showModal({ content: 'preview' }));
  },
  onEdit: () => {
    const { dispatch } = dispatchProps;
    const { article, projectId } = stateProps;
    dispatch(showModal({ content: 'editArticle' }));
    dispatch(changeArticleForm({ field: 'id', value: article.id }));
    dispatch(changeArticleForm({ field: 'projectId', value: projectId }));
    dispatch(changeArticleForm({ field: 'title', value: article.title }));
    dispatch(changeArticleForm({ field: 'tagId', value: article.tag_id }));
    dispatch(changeArticleForm({ field: 'reach', value: article.reach }));
    dispatch(changeArticleForm({ field: 'langId', value: article.language }));
    dispatch(changeArticleForm({ field: 'date', value: article.date }));
  },
});

const ArticleActions = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditActions);

export default ArticleActions;
