import React, { useState } from 'react';

import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

import translate from '../../../helpers/translate';
import ButtonIcon from '../../common/ButtonIcon';
import '../../../assets/scss/theme/Footprints/components/_pagination.scss';

const Pagination = ({ paginationProps, rows, viewAll, rowsPerPageList, rowsPerPage, onSizeChange }) => {
  const [currentPageSize, setCurrentPageSize] = useState(rowsPerPage);
  const [isOpen, setIsOpen] = useState(false);

  const handleFirstPage =
    ({ onPageChange }) =>
      () => {
        onPageChange(1);
      };

  const handleLastPage =
    ({ totalSize, sizePerPage, onPageChange }) =>
      () => {
        const lastPage = Math.ceil(totalSize / sizePerPage);
        onPageChange(lastPage);
      };

  const handleNextPage =
    ({ page, onPageChange }) =>
      () => {
        onPageChange(page + 1);
      };

  const handlePrevPage =
    ({ page, onPageChange }) =>
      () => {
        onPageChange(page - 1);
      };

  const handleViewAll = ({ onSizePerPageChange }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, 1);
  };

  const handleChangePageSize = (value) => {
    setCurrentPageSize(value);
    onSizeChange(value);
  };

  const isButtonDisabled =
    currentPageSize >= paginationProps.totalSize ||
    paginationProps.dataSize <= paginationProps.page * paginationProps.sizePerPage;

  const renderDropdowns = () =>
    rowsPerPageList.map((value, id) => (
      <DropdownItem key={id} id={id} onClick={() => handleChangePageSize(value)} active={rowsPerPage === value}>
        {value}
      </DropdownItem>
    ));

  return (
    <Row noGutters className="px-1 py-3 fp-pagination">
      <Col className="pl-3 fs--1">
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} aria-label="dropdown-dynamic">
          Showing
          <DropdownToggle
            aria-label="change-size-dropdown"
            caret
            size="sm"
            className="ml-2 mr-3 btn btn-falcon-default"
          >
            {currentPageSize}
          </DropdownToggle>
          of <strong>{paginationProps.totalSize}</strong>
          <DropdownMenu>{renderDropdowns()}</DropdownMenu>
        </Dropdown>
        {viewAll && (
          <>
            —{' '}
            <ButtonIcon
              aria-label="view-all-button"
              color="link"
              size="sm"
              icon="chevron-right"
              iconAlign="right"
              transform="down-1 shrink-4"
              className="px-0 font-weight-semi-bold"
              onClick={() => handleViewAll(paginationProps, rows.length)}
            >
              {translate('components.table.pagination.view_all')}
            </ButtonIcon>
          </>
        )}
      </Col>
      <Col xs="auto" className="pr-3">
        <Button
          aria-label="first-page-button"
          color="secondary"
          size="sm"
          onClick={handleFirstPage(paginationProps)}
          disabled={paginationProps.page === 1}
          className="fp-pagination-first-page"
        >
          {translate('components.table.pagination.first_page')}
        </Button>
        {paginationProps.page === 1 ? (
          <></>
        ) : (
          <Button
            aria-label="prev-page-button"
            color="primary"
            size="sm"
            onClick={handlePrevPage(paginationProps)}
            className="fp-pagination-prev-button ml-2"
          >
            {paginationProps.page - 1}
          </Button>
        )}
        <Button color="primary" size="sm" disabled={false} className="fp-pagination-active-button ml-2">
          {paginationProps.page}
        </Button>
        {paginationProps.page === paginationProps.totalSize ? (
          <></>
        ) : (
          <Button
            aria-label="next-page-button"
            size="sm"
            color="primary"
            onClick={handleNextPage(paginationProps)}
            disabled={isButtonDisabled}
            className="fp-pagination-next-page ml-2"
          >
            {paginationProps.page + 1}
          </Button>
        )}
        <Button
          aria-label="last-page-button"
          color="secondary"
          size="sm"
          onClick={handleLastPage(paginationProps)}
          disabled={isButtonDisabled}
          className="fp-pagination-last-page px-4 ml-2"
        >
          {translate('components.table.pagination.last_page')}
        </Button>
      </Col>
    </Row>
  );
};
export default Pagination;
