import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';
import { Media } from 'reactstrap';

import ICODropzone from './ICODropzone';
import cloudUpload from '../../../assets/img/icons/cloud-upload.svg';
import translate from '../../../helpers/translate';
import { isIterableArray } from '../../../helpers/utils';
import Avatar from '../../common/Avatar';

const ImageUploader = ({ data, urlRoot, onSelectImage, message }) => {
  const [avatar, setAvatar] = useState(
    data.avatar
      ? data.avatar
      : [{ src: 'https://ico-files.s3.eu-west-2.amazonaws.com/images/footprints-logo-avatar.svg' }],
  );
  const avatarSrc = isIterableArray(avatar) ? avatar[0].preview || avatar[0].src : urlRoot + data.avatar;

  return (
    <Fragment>
      <Media className="flex-column flex-center pb-3 d-block d-md-flex text-center mb-2">
        <Avatar size="4xl" className="mb-3" src={avatarSrc} />
        <Media body className="ml-md-12">
          <ICODropzone
            files={avatar}
            onChange={(files, binaryFiles) => {
              setAvatar(files);
              onSelectImage(binaryFiles);
            }}
            multiple={false}
            accept="image/*"
            placeholder={
              <Fragment>
                <Media className="fs-0 mx-auto d-inline-flex align-items-center">
                  <img src={cloudUpload} alt="" width={25} />
                  <Media>
                    <p className="fs-0 mb-0 text-700">{message}</p>
                  </Media>
                </Media>
                <p className="mb-0 w-75 mx-auto text-500">{translate('image_uploader')}</p>
              </Fragment>
            }
          />
        </Media>
      </Media>
    </Fragment>
  );
};

ImageUploader.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
  }),
  onSelectImage: PropTypes.func,
  height: PropTypes.string,
  avatarWidth: PropTypes.string,
  avatarHeight: PropTypes.string,
  message: PropTypes.string,
  urlRoot: PropTypes.string,
};

ImageUploader.defaultProps = {
  avatarHeight: '150px',
  avatarWidth: '150px',
  height: '170px',
};

export default ImageUploader;
