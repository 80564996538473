import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, CustomInput, InputGroup } from 'reactstrap';

import { fetchArticlesFeed } from '../../../../actions/articlesFeed';
import { COVERAGE_API_ROOT } from '../../../../config/global-config';
import translate from '../../../../helpers/translate';
import urlSearchParamsBuilder from '../../../../helpers/urlSearchParamsBuilder';
import { getArticlesFeedPagination, getArticlesFeedTableFilters } from '../../../../selectors/feeds/articlesFeed';
import { getCurrentProject } from '../../../../selectors/projects';
import { useDeleteArticles, useExportArticles } from '../hooks/useArticlesFeed';
import ArticlesFeedDeleteArticlesModal from '../Modals/DeleteArticles/ArticlesFeedDeleteArticlesModal.jsx';
import ArticlesFeedSaveArticleModal from '../Modals/SaveArticle/ArticlesFeedSaveArticleModal.jsx';

const ArticlesFeedTableBulkActions = ({
  selectedRows,
  setSelectedRows,
  fetchArticlesFeed,
  filters,
  project,
  pagination,
  sortField,
}) => {
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const endpoint = `${COVERAGE_API_ROOT}/csv/articles_feed/${project['alert-id']}`;
  const url = urlSearchParamsBuilder({
    filters: {
      ...filters,
      ids: selectedRows,
    },
    endpoint,
  });
  const onDeleteSuccess = () => {
    fetchArticlesFeed({
      alert_id: project['alert-id'],
      pagination: { sizePerPage: pagination.sizePerPage, page: pagination.page, sortField },
      filters,
    });
    setSelectedRows([]);
  };

  const { mutate } = useDeleteArticles(onDeleteSuccess);
  const { refetch } = useExportArticles(url, project);

  const handleApplyButtonClick = async () => {
    const selectedOption = document.getElementById('bulk-select').value;
    if (selectedOption === 'save') {
      setSaveModalOpen(true);
    }

    if (selectedOption === 'delete') {
      setDeleteModalOpen(true);
    }

    if (selectedOption === 'export') {
      refetch();
    }
  };

  const onClickDelete = () => {
    mutate({
      data: {
        type: 'delete_articles_rss_ids',
        attributes: {
          ids: selectedRows,
        },
        relationships: {
          alert: {
            data: {
              type: 'alert',
              id: project['alert-id'],
            },
          },
        },
      },
    });
  };

  return (
    <>
      <InputGroup size="md" className="input-group flex-center justify-content-around">
        <span className="mr-3">
          {selectedRows.length} {translate('common.rows_selected')}
        </span>
        <CustomInput type="select" id="bulk-select" className="mr-2 text-uppercase" aria-label="select">
          <option value="save">{translate('pages.articles_feed.bulk_actions.save')}</option>
          <option value="export">Export</option>
          <option value="delete">{translate('pages.articles_feed.bulk_actions.delete')}</option>
        </CustomInput>
        <Button color="primary" onClick={handleApplyButtonClick}>
          {translate('common.apply')}
        </Button>
      </InputGroup>
      <ArticlesFeedSaveArticleModal
        selectedRows={selectedRows}
        isOpen={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
      />
      <ArticlesFeedDeleteArticlesModal
        selectedRows={selectedRows}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onClickDelete={onClickDelete}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getArticlesFeedTableFilters(state),
  project: getCurrentProject(state),
  pagination: getArticlesFeedPagination(state),
  sortField: state.articlesFeed.sortField,
});

export default connect(mapStateToProps, { fetchArticlesFeed })(ArticlesFeedTableBulkActions);
