import React from 'react';

import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { values } from 'lodash';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidV4 } from 'uuid';

import { STATUS } from '../../../../config/api-service';
import {
  colorMUV,
  colorScore,
  extractDomain,
  extractFavicon,
  largeNumberFormat,
  removeProtocolFromUrl,
} from '../../../../config/url-utils';
import { getTag } from '../../../../selectors/tags';
import ArticleActions from '../../actions/ArticleActions';
import { ArticleSource } from '../../actions/ArticleSource';
import ArticleTableTitle from '../../actions/ArticleTableTitle';
import FeedTableActions from '../../actions/FeedTableActions';
import RowResultActionsKO from '../RowResultActionsKO';

export const getDisplayTitleValue = (art) => {
  let title = art.title || removeProtocolFromUrl(art.url ? art.url : '');
  title = title.length > 85 ? `${title.substr(0, 85)}...` : title;
  return title;
};

export const MUVFormatter = (muv) => (
  <Badge color={colorMUV(muv)} className="rounded-capsule" id="umv-badge">
    <img
      src="https://ico-files.s3.eu-west-2.amazonaws.com/images/FP-UMV-Icon.svg"
      width={10}
      height={10}
      alt="UMV"
      style={{ marginBottom: '6%' }}
    />{' '}
    {largeNumberFormat(muv)}
    <UncontrolledTooltip placement="bottom" target="umv-badge" autohide={false}>
      UMVs is the sum of unique visitors for a given site in the last 30 days. This data is sourced from{' '}
      <a href="https://www.similarweb.com/" target="_blank" rel="noopener noreferrer">
        SimilarWeb
      </a>
    </UncontrolledTooltip>
  </Badge>
);

export const dateFormatter = (date = '') => (date ? dayjs(date).format('YYYY-MM-DD') : '');

export const langFormatter = (lang = '') => (
  <Badge color="lang-badge" className="rounded-capsule">
    {lang ? lang.toUpperCase() : ''}
  </Badge>
);

export const applySort = (table, sort = 'asc') => {
  let order = sort[0] === '-' ? 'desc' : 'asc';
  if (sort.includes('title') || sort.includes('media') || sort.includes('url') || sort.includes('language')) {
    let propName = sort.replace('-', '');
    return table.sort((a, b) => {
      if (order === 'desc') {
        return ('' + a[propName]).localeCompare(b[propName]);
      }
      return ('' + b[propName]).localeCompare(a[propName]); // desc
    });
  }
  if (sort.includes('muv')) {
    table.sort((a, b) => {
      if (order === 'desc') {
        return a.muv < b.muv ? 1 : -1;
      }
      return a.muv > b.muv ? -1 : 1;
    });
  }
  if (sort.includes('reach')) {
    table.sort((a, b) => {
      if (order === 'desc') {
        return a.reach < b.reach ? 1 : -1;
      }
      return a.reach > b.reach ? -1 : 1;
    });
  }
  return table;
};

export const rowsFormatter = (state, rowsRaw, rowsStatus, pageSize, checkedRows) => ({
  websitesTable: () => {
    if (rowsRaw.length === 0 && rowsStatus === STATUS.LOADING) {
      let filled = new Array(pageSize).fill({}).map((_, i) => ({
        id: i,
        domain: '',
        favIcon: '',
        articlesCount: '',
        mediatype: '',
        muv: '',
        reach: '',
        language: '',
        date: '',
        fullUrl: '',
      }));
      return filled;
    }
    return values(rowsRaw).map((website) => ({
      id: website.id,
      domain: website.domain,
      favIcon: extractFavicon(website.domain),
      articlesCount: website['articles-count'],
      mediatype: website['mediatype-name'],
      muv: website.muv,
      reach: website.reach,
      language: website.language,
      date: website['inserted-at'],
      fullUrl: website.fullUrl,
    }));
  },
  articlesTable: () => {
    if (rowsRaw.length === 0 && rowsStatus === STATUS.LOADING) {
      let filled = new Array(pageSize).fill({}).map((_, i) => ({
        id: i,
        title: '',
        url: '',
        favIcon: '',
        muv: '',
        tag: '',
        reach: '',
        language: '',
        date: '',
        action: '',
        fullUrl: '',
      }));
      return filled;
    }
    return values(rowsRaw).map((article) => {
      const { id, tag_id, url, reach, language, date, fullUrl, title } = article;
      const loadedTag = getTag(state, tag_id) || { name: '' };
      const isSomethingMissing = !title || !language || !date;

      return {
        isSomethingMissing,
        id,
        title: <ArticleTableTitle article={article} isFeed={false} />,
        url: removeProtocolFromUrl(url),
        favIcon: extractFavicon(removeProtocolFromUrl(url)),
        muv: article['website-visits'],
        reach,
        language,
        tag: loadedTag.name,
        date,
        action: <ArticleActions article={article} />,
        fullUrl,
      };
    });
  },
  articlesFeedTable: () => {
    if (rowsRaw.length === 0 && rowsStatus === STATUS.LOADING) {
      let filled = new Array(pageSize).fill({}).map((_, i) => ({
        id: i,
        title: '',
        url: '',
        favIcon: '',
        muv: '',
        reach: '',
        language: '',
        date: '',
        action: '',
        fullUrl: '',
      }));
      return filled;
    }
    return values(rowsRaw).map((art) => {
      const renderRowStatus = (row) => {
        if (row.status === STATUS.KO && row.message) {
          return <RowResultActionsKO id={row.id} message={row.message} />;
        } else if (row.status === STATUS.OK) {
          return <FeedTableActions article={art} />;
        }
      };
      let currentRow = checkedRows.filter(({ id }) => art.id === id);
      let actions = currentRow.length ? renderRowStatus(currentRow[0]) : <FeedTableActions article={art} />;

      return {
        id: art.id,
        title: <ArticleTableTitle article={art} isFeed={true} />,
        source: <ArticleSource article={art} source={art.source} />,
        media: removeProtocolFromUrl(art.url),
        favIcon: extractFavicon(removeProtocolFromUrl(art.url)),
        muv: art['muv'],
        reach: art.reach,
        language: art.language,
        date: art['pub-date'],
        action: actions,
        fullUrl: art.fullUrl,
      };
    });
  },
});

export const renderWarning = () => (
  <>
    <FontAwesomeIcon id="warning-icon" icon={faExclamationCircle} className="text-warning" />
    <UncontrolledTooltip target="warning-icon" placement="top">
      Title, language or date is missing in this article
    </UncontrolledTooltip>
  </>
);

export const formatPercentage = (value) => `${Math.round(value * 100)}%`;

export const ScoreFormatter = (score) => (
  <Badge color={colorScore(score)} className="rounded-capsule">
    <FontAwesomeIcon icon={faTrophy} /> {score}
  </Badge>
);

export const mediaFormatter = (url) => (
  <a className="fs--1" target="_blank" rel="noopener noreferrer" id="articleLink" href={url}>
    <span>{extractDomain(url)}</span>
  </a>
);

export const videosViewFormatter = (views) =>
  views ? (
    <Badge color="muv-score-max" className="rounded-capsule">
      <span>{largeNumberFormat(views)}</span>
    </Badge>
  ) : null;

export const youtubeChannelFormatter = (title, thumbnail, customUrl) => {
  if ((title, thumbnail, customUrl)) {
    return (
      <div className="d-flex flex-row align-items-center">
        {thumbnail && (
          <img
            src={thumbnail}
            alt={`${title} thumbnail`}
            width={25}
            height={25}
            className="mr-2 rounded-circle"
            loading="lazy"
          />
        )}
        <a
          href={customUrl ? `https://www.youtube.com/${customUrl}` : `https://www.youtube.com/channel/${customUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="d-flex align-items-center"
        >
          <span className="mx-auto">{title}</span>
        </a>
      </div>
    );
  }
  return null;
};

export const formatHTMLSymbols = (text, isBold = false) => (
  <span className={isBold ? 'text-bold' : null} dangerouslySetInnerHTML={{ __html: text }} />
);

export const youtubeVideoTitleFormatter = (title) => {
  const tooltipId = uuidV4();

  return (
    <div style={{ display: 'inline-block', width: '25vw', overflow: 'hidden' }}>
      <div className="cursor-pointer text-truncate" id={`titleTooltip_${tooltipId}`}>
        <span id={`titleTooltip_${tooltipId}`}>{formatHTMLSymbols(title)}</span>
      </div>
      <UncontrolledTooltip placement="bottom" target={`titleTooltip_${tooltipId}`} autohide={false}>
        {title}
      </UncontrolledTooltip>
    </div>
  );
};
