import {
  API_CALL,
  SAVED_VIDEOS_CLEAN,
  SAVED_VIDEOS_DELETE_CLEAN,
  SAVED_VIDEOS_DELETE_VIDEOS,
  SAVED_VIDEOS_FETCH_VIDEOS,
  SAVED_VIDEOS_FILTERS_CLEAN,
  SAVED_VIDEOS_FILTERS_SET_END_DATE,
  SAVED_VIDEOS_FILTERS_SET_LANGUAGES,
  SAVED_VIDEOS_FILTERS_SET_START_DATE,
  SAVED_VIDEOS_FILTERS_SET_TAGS,
  SAVED_VIDEOS_SET_PAGE_SIZE,
} from './actionTypes';
import urlSearchParamsBuilder from '../helpers/urlSearchParamsBuilder';

export const savedVideosFetch = ({ project_id, filters, pagination, isPublicRoute = false, report_id }) => {
  let endpoint = isPublicRoute ? `p/saved_videos?report_id=${report_id}` : `saved_videos?project_id=${project_id}`;
  const savedVideosUrl = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: SAVED_VIDEOS_FETCH_VIDEOS,
      endpoint: savedVideosUrl,
      method: 'GET',
    },
  };
};

export const savedVideosDeleteVideos = (selectedVideos) => ({
  type: API_CALL,
  meta: {
    type: SAVED_VIDEOS_DELETE_VIDEOS,
    endpoint: 'saved_videos',
    method: 'DELETE',
    payload: { video_ids: selectedVideos },
  },
});

export const savedVideosTableFiltersSetTags = (tags) => ({
  type: SAVED_VIDEOS_FILTERS_SET_TAGS,
  payload: tags,
});

export const savedVideosTableFiltersSetLanguages = (languages) => ({
  type: SAVED_VIDEOS_FILTERS_SET_LANGUAGES,
  payload: languages,
});

export const savedVideosTableFiltersSetStartDate = (startDate) => ({
  type: SAVED_VIDEOS_FILTERS_SET_START_DATE,
  payload: startDate,
});

export const savedVideosTableFiltersSetEndDate = (endDate) => ({
  type: SAVED_VIDEOS_FILTERS_SET_END_DATE,
  payload: endDate,
});

export const savedVideosClean = () => ({
  type: SAVED_VIDEOS_CLEAN,
});

export const savedVideosTableFiltersClean = () => ({
  type: SAVED_VIDEOS_FILTERS_CLEAN,
});

export const savedVideosDeleteClean = () => ({
  type: SAVED_VIDEOS_DELETE_CLEAN,
});

export const savedVideosSetPageSize = (newSize) => ({
  type: SAVED_VIDEOS_SET_PAGE_SIZE,
  payload: newSize,
});
