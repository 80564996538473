import values from 'lodash/values';
import { createSelector } from 'reselect';

import { getWebsitesTableFiltersForm } from './forms';
import { getModules } from './modules';
import { getEntityByTableFilters } from './savedCoverage/articles';
import { getEntityDataByKey } from '../components/ICO/tables/helpers/selectors';
import { genGetParams, buildFiltersQuery } from '../config/api-service';

export const getWebsites = (state) => state.websites.items;
export const getWebsitesStatus = (state) => state.websites.itemsStatus;
export const getWebsitesList = (state) => state.websites.lists;
export const getWebsiteByDomain = (state, domain) => {
  let search = values(state.websites.items).filter((website) => website.domain === domain);
  return search[0] ? search[0] : {};
};

export const getWebsitesTableFiltersKey = createSelector(
  [getModules, getWebsitesTableFiltersForm],
  (modules, tableFilters) => {
    let filtersResult = {
      page: {
        page: modules.websitesTable.pagination.current_page,
        page_size: modules.websitesTable.pagination.page_size,
      },
      filter: buildFiltersQuery(tableFilters.fields),
    };
    if (tableFilters.fields.sort) {
      filtersResult = { ...filtersResult, sort: tableFilters.fields.sort };
    }
    return filtersResult;
  },
);

export const getWebsitesByTableFilters = createSelector(
  [getWebsites, getWebsitesStatus, getWebsitesList, getWebsitesTableFiltersKey],
  (websites, websitesStatus, ids, tableFilterKey) =>
    getEntityByTableFilters(websites, websitesStatus, ids, tableFilterKey),
);

export const getWebsitesStatsFiltered = (state) => {
  const key = genGetParams(getWebsitesTableFiltersKey(state));
  return getEntityDataByKey(state, 'websites', 'stats', key);
};

export const getWebsitesStatusFiltered = (state) => {
  const key = genGetParams(getWebsitesTableFiltersKey(state));
  return getEntityDataByKey(state, 'websites', 'listsStatus', key);
};

export const getWebsitesAddWebsiteReach = (state) => state.forms.addWebsite.reach;
export const getWebsitesAddWebsiteLanguages = (state) => {
  const languages = state.languages.languages.items;
  return Object.values(languages).map((language) => ({ value: language.id, label: language.name }));
};
export const getWebsitesAddWebsiteSelectedLanguage = (state) => state.forms.addWebsite.language;
export const getWebsitesAddWebsiteModalIsOpen = (state) => state.forms.addWebsite.isOpen;
export const getWebsitesAddWebsiteModalDomain = (state) => state.forms.addWebsite.domain;
export const getWebsitesAddWebsiteStatus = (state) => state.forms.addWebsite.websiteStatus;
export const getWebsitesAddWebsiteFeedStatus = (state) => state.forms.addWebsite.feedStatus;

export const getWebsitesAddWebsiteDomainIsValid = (state) => state.forms.addWebsite.isDomainValid;
export const getWebsitesAddWebsiteFeedIsValid = (state) => state.forms.addWebsite.isFeedValid;
export const getWebsitesAddWebsiteModalFeed = (state) => state.forms.addWebsite.feed;
export const getWebsitesAddWebsiteData = (state) => state.forms.addWebsite.data;

export const getWebsitesAddWebsiteIsSaveDisabled = (state) => {
  const reach = getWebsitesAddWebsiteReach(state);
  const language = getWebsitesAddWebsiteSelectedLanguage(state);
  const domain = getWebsitesAddWebsiteModalDomain(state);
  const isDomainValid = getWebsitesAddWebsiteDomainIsValid(state);
  const isFeedValid = getWebsitesAddWebsiteFeedIsValid(state);
  const feed = getWebsitesAddWebsiteModalFeed(state);

  return !reach || !language || !domain || !isDomainValid || !feed || !isFeedValid;
};
