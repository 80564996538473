import React from 'react';

import { Col, Row } from 'reactstrap';
import '../../../assets/scss/theme/Footprints/components/_footer.scss';

const AlphaFooter = () => (
  <footer className="footer fp-footer mt-7">
    <Row lg={3} className="flex-center">
      <Col lg={3} md={8} xs={12} className="d-flex align-items-center flex-column">
        <Row>
          <span className="text-uppercase fp-footer__alpha">Alpha</span>
        </Row>
        <Row>
          <span className="fp-footer__bugs">May contain bugs</span>
        </Row>
      </Col>
    </Row>
  </footer>
);

export default AlphaFooter;
