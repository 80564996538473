import React from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { Card, CardBody, CardHeader, Col, Row, CardFooter } from 'reactstrap';

import ButtonIcon from '../../../components/common/ButtonIcon';
import ListElement from '../../../components/ICO/text-display/ListElement';
import translate from '../../../helpers/translate';
import { isIterableArray } from '../../../helpers/utils';

const renderAuthors = (authors) => {
  if (isIterableArray(authors)) {
    return (
      <>
        {authors.map(({ id, author }) => (
          <ListElement name={author} key={id} />
        ))}
      </>
    );
  }

  return <div className="alert alert-info alert-styled-left col-md-12">{translate('websites.details.no_author')}</div>;
};

const renderTwitterAccounts = (accounts) => {
  if (isIterableArray(accounts)) {
    return (
      <>
        {accounts.map(({ id, username }) => (
          <ListElement name={`@${username}`} key={id} externalLink={true} to={`https://twitter.com/${username}`} />
        ))}
      </>
    );
  }

  return (
    <div className="alert alert-info alert-styled-left col-md-12">
      {translate('websites.details.no_twitter_account')}
    </div>
  );
};

const WebsiteDetailsSocialData = ({ onNewFeed, onEditFeed, authors, twitterAccounts, feeds }) => {
  const renderFeeds = (feeds) => {
    if (isIterableArray(feeds)) {
      feeds = feeds.filter((feed) => feed.type !== 1);
      return (
        <>
          {feeds.map(({ id, feed, disabled }) => (
            <>
              <ListElement
                name={feed}
                disabled={disabled}
                key={id}
                to={feed}
                externalLink={true}
                editButton={true}
                onEdit={() => onEditFeed(id, feed, disabled)}
              />
            </>
          ))}
        </>
      );
    }

    return (
      <div className="alert alert-info alert-styled-left col-md-12">{translate('websites.details.no_rss_feed')}</div>
    );
  };

  return (
    <Row>
      <Col sm={12} xl={4} className="mb-3 mt-3">
        <Card className="min-vh-35 max-vh-35 min-w-12 overflow-auto">
          <CardHeader>
            <h3 className="text-regular"> {translate('websites.details.twitter')}</h3>
          </CardHeader>
          <CardBody className="py-2">{renderTwitterAccounts(twitterAccounts)}</CardBody>
        </Card>
      </Col>
      <Col sm={12} xl={4} className="mb-3 mt-3">
        <Card className="min-vh-35 max-vh-35 min-w-12 overflow-auto">
          <CardHeader>
            <h3 className="text-regular"> {translate('websites.details.feeds')}</h3>
          </CardHeader>
          <CardBody className="py-2 overflow-auto">{renderFeeds(feeds)}</CardBody>
          <CardFooter className="d-flex flex-row justify-content-center align-items-center bt-soft-gray ">
            <ButtonIcon
              aria-label="addFeed"
              icon={faPlusCircle}
              onClick={onNewFeed}
              color="primary"
              className="btn btn-primary"
            >
              {translate('websites.details.add_feed')}
            </ButtonIcon>
          </CardFooter>
        </Card>
      </Col>
      <Col sm={12} xl={4} className="mb-3 mt-3">
        <Card className="min-vh-35 max-vh-35 min-w-12 overflow-auto">
          <CardHeader>
            <h3 className="text-regular"> {translate('websites.details.authors')}</h3>
          </CardHeader>
          <CardBody className="py-2 overflow-auto">{renderAuthors(authors)}</CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default WebsiteDetailsSocialData;
