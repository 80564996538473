import React from 'react';

import { Button, UncontrolledTooltip } from 'reactstrap';
import '../../../../assets/scss/theme/Footprints/components/_button_with_tooltip.scss';

const ButtonWithTooltip = ({ onClick, label, tooltipText, isDisabled }) => (
  <div id="publishButton">
    <Button
      color="success"
      onClick={onClick}
      disabled={isDisabled}
      className={`mr-2 mb-2 ${isDisabled ? 'disabled-button ' : ''}`}
    >
      {label}
    </Button>

    {isDisabled ? (
      <UncontrolledTooltip target="publishButton" placement="bottom">
        {tooltipText}
      </UncontrolledTooltip>
    ) : null}
  </div>
);

export default ButtonWithTooltip;
