import React, { useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Progress, ListGroup, ListGroupItem, Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap';

import { AuthWizardContext } from '../../../context/Context';
import { queryBuilderFormatter } from '../../../helpers/queryBuilderFormatter';
import translate from '../../../helpers/translate';
import { useCreateAccount } from '../hooks/useSignupFreeUser';

const SignUpFreeUserResult = () => {
  const { loginWithRedirect } = useAuth0();

  const { user, setUser, setStep } = useContext(AuthWizardContext);
  const { mutate: signupFreeUser, isLoading, isError, isSuccess, data, error } = useCreateAccount();
  const [progress, setProgress] = useState(0);

  const handleStartOver = () => {
    setUser(null);
    setStep(1);
  };

  useEffect(() => {
    if (user) {
      const userData = {
        name: user?.user_username,
        email: user?.user_email,
        password: user?.user_password,
        organization_name: user?.organization_name,
        client_name: user?.brand_name,
        project_name: user?.project?.name,
        article_alert: {
          name: user?.project?.name,
          keywords: queryBuilderFormatter(user?.project?.name.replace(/,/g, '.'), 'all'),
        },
        youtube_alert: { keyword: user?.project?.name },
        twitch_alert: { keyword: user?.project?.name, game_id: parseInt(user?.project?.id) },
      };

      signupFreeUser(userData);
    }
  }, [user, signupFreeUser]);

  useEffect(() => {
    let intervalId;
    if (isLoading) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 90) {
            clearInterval(intervalId);
            return 90;
          }
          return prevProgress + 10;
        });
      }, 500);
    } else if (isSuccess) {
      setProgress(100);
    } else if (isError) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isLoading, isSuccess, isError]);

  const renderFeedStatus = (feedType, status, reason) => {
    let color, notice;

    if (status === 'ok') {
      if (feedType.toLowerCase() === 'articles') {
        color = 'warning';
        notice = 'Setup complete. Feed may take up to 15 minutes to populate with coverage';
      } else if (feedType.toLowerCase() === 'videos' || feedType.toLowerCase() === 'streams') {
        color = 'success';
        notice = 'Setup complete. Coverage available now';
      } else {
        color = 'success';
        notice = '';
      }
    } else {
      color = 'danger';
      notice = reason;
    }

    return (
      <>
        <ListGroupItem key={feedType} className="d-flex justify-content-between align-items-center">
          <span className="text-capitalize">{feedType}:</span>
          <span className={`badge bg-${color} rounded-pill text-white`}>{status === 'ok' ? 'OK' : 'Error'}</span>
        </ListGroupItem>
        {notice && (
          <ListGroupItem>
            <span className={`text-${color} ms-2`}>{notice}</span>
          </ListGroupItem>
        )}
      </>
    );
  };

  const renderErrorMessage = () => {
    if (!error) return null;

    return (
      <>
        <Alert color="danger">
          <h4 className="alert-heading">{translate('signup.free_user.last_step.error')}</h4>
          <p>{error.message}</p>
        </Alert>
        <div className="text-center">
          <Button color="primary" onClick={handleStartOver}>
            Start Over
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Row>
          <Col className="text-center">
            <h4 className="mb-3">{translate('signup.free_user.last_step.creating_account')}</h4>
            <p className="mb-4">{translate('signup.free_user.last_step.wait_copy')}</p>
            <Progress value={progress} className="mb-3" />
            <p>
              {progress}% {isSuccess ? 'Complete' : 'complete...'}
            </p>
          </Col>
        </Row>
      ) : null}

      {isError ? renderErrorMessage() : null}

      {isSuccess ? (
        <>
          <Alert color={data.data.user.status === 'ok' ? 'success' : 'danger'}>
            <h5 className="alert-heading">
              {data.data.user.status === 'ok' ? 'Your account was created successfully' : 'Account creation failed.'}
            </h5>
          </Alert>

          <Card className="mb-4">
            <CardHeader>
              <h5 className="mb-0">Coverage Collection Status</h5>
            </CardHeader>
            <CardBody>
              <ListGroup flush>
                {['articles', 'videos', 'streams'].map((feedType) => {
                  const feedData = data.data.feeds[feedType];
                  return feedData ? renderFeedStatus(feedType, feedData.status, feedData.reason) : null;
                })}
              </ListGroup>
            </CardBody>
          </Card>
          <div className="text-center">
            <Button color="primary" onClick={() => loginWithRedirect()}>
              Login
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SignUpFreeUserResult;
