import React, { useEffect, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  superSearchClearTableData,
  superSearchClearVolumeData,
  superSearchFetchDataPaginated,
  superSearchFetchVolume
} from '../../../actions/superSearch';
import ButtonIcon from '../../../components/common/ButtonIcon';
import { getOrganizationId } from '../../../selectors/organizations';
import {
  getSuperSearchFiltersLanguages,
  getSuperSearchFiltersMaxScore,
  getSuperSearchFiltersMinScore,
  getSuperSearchFromDate,
  getSuperSearchSearchButtonIsDisabled,
  getSuperSearchSearchTerm,
  getSuperSearchToDate
} from '../../../selectors/superSearch';

const SuperSearchFiltersApplyButton = ({
  isSearchButtonDisabled,
  superSearchFetchDataPaginated,
  superSearchClearTableData,
  superSearchFetchVolume,
  superSearchClearVolumeData,
  searchTerm,
  fromDate,
  toDate,
  languages,
  minScore,
  maxScore,
  organizationId
}) => {
  let params = useMemo(
    () => ({
      searchTerm,
      fromDate,
      toDate,
      organizationId
    }),
    [searchTerm, fromDate, toDate, organizationId]
  );

  if (languages?.length) {
    params.languages = languages;
  }

  if (minScore && !maxScore) {
    params.mediaScore = [minScore, 10];
  }

  if (minScore && maxScore) {
    params.mediaScore = [minScore, maxScore];
  }

  const searchOnEnter = useCallback(
    event => {
      if (event.key === 'Enter') {
        superSearchClearVolumeData();
        superSearchClearTableData();
        superSearchFetchDataPaginated(params);
        superSearchFetchVolume(params);
      }
    },
    [
      params,
      superSearchClearVolumeData,
      superSearchFetchVolume,
      superSearchFetchDataPaginated,
      superSearchClearTableData
    ]
  );

  useEffect(() => {
    if (searchTerm) {
      document.addEventListener('keypress', searchOnEnter, false);
    }

    return () => {
      document.removeEventListener('keypress', searchOnEnter, false);
    };
  }, [searchTerm, searchOnEnter]);

  const handleButtonClick = () => {
    superSearchClearVolumeData();
    superSearchClearTableData();
    superSearchFetchVolume(params);
    superSearchFetchDataPaginated(params);
  };

  return (
    <>
      <ButtonIcon
        icon="fa-search"
        color="primary"
        size="small"
        aria-label="search-button"
        disabled={isSearchButtonDisabled}
        onClick={handleButtonClick}
      >
        Search
      </ButtonIcon>
    </>
  );
};

SuperSearchFiltersApplyButton.propTypes = {
  isSearchButtonDisabled: PropTypes.bool,
  superSearchFetchDataPaginated: PropTypes.func,
  superSearchFetchVolume: PropTypes.func,
  superSearchClearTableVolumeData: PropTypes.func,
  searchTerm: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  userTierId: PropTypes.number
};

const mapStateToProps = state => ({
  isSearchButtonDisabled: getSuperSearchSearchButtonIsDisabled(state),
  searchTerm: getSuperSearchSearchTerm(state),
  fromDate: getSuperSearchFromDate(state),
  toDate: getSuperSearchToDate(state),
  organizationId: getOrganizationId(state),
  languages: getSuperSearchFiltersLanguages(state),
  minScore: getSuperSearchFiltersMinScore(state),
  maxScore: getSuperSearchFiltersMaxScore(state)
});

export default connect(
  mapStateToProps,
  {
    superSearchClearVolumeData,
    superSearchFetchDataPaginated,
    superSearchFetchVolume,
    superSearchClearTableData
  }
)(SuperSearchFiltersApplyButton);
