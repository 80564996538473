import {
  SAVED_STREAMS_FETCH_STREAMS_SUCCESS,
  SAVED_STREAMS_SET_PAGE_SIZE,
  SAVED_STREAMS_CLEAN,
} from '../../actions/actionTypes';

const initialState = 10;

const pageSize = (state = initialState, action) => {
  switch (action.type) {
    case SAVED_STREAMS_SET_PAGE_SIZE:
      return action.payload;
    case SAVED_STREAMS_FETCH_STREAMS_SUCCESS:
      return action.payload.pagination.page_size;
    case SAVED_STREAMS_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default pageSize;
