import { CHANGE_TAG_FORM, STATUS_ENABLED, RESET_FORM } from '../../actions/forms';

const initialState = {
  title: 'New Tag',
  fields: {
    name: '',
    projectId: 0,
    'highlight-date': null,
    'has-highlight': false,
  },
  fieldsStatus: {
    project: STATUS_ENABLED,
  },
};

const newTagForm = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TAG_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case RESET_FORM:
      return { ...state, fields: { name: '', projectId: 0, 'highlight-date': null, 'has-highlight': false } };
    default:
      return state;
  }
};

export default newTagForm;
