import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import { addHighlightedArticle, removeHighlightedArticle } from '../../../actions/reports';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';
import '../../../assets/scss/theme/Footprints/components/_highlight-article-icon.scss';
import {
  getOrganizationPermissionsCanTakeScreenshot,
  getOrganizationPermissionsMaxScreenshots,
} from '../../../selectors/permissions';
import { getReportsHighlightedArticles } from '../../../selectors/reports';
import getToastedNotification from '../helpers/toaster.helper';

const HighlightArticleIcon = ({
  article_id,
  article_url,
  addHighlightedArticle,
  removeHighlightedArticle,
  userCanTakeScreenshot,
  highlightedArticles,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [articleHasBeenHighlighted, setArticleHasBeenHighlighted] = useState(false);

  const handleNotification = useCallback((customMessage) => {
    getToastedNotification({ customMessage });
  }, []);

  useEffect(() => {
    if (highlightedArticles && highlightedArticles.includes(article_id)) {
      setArticleHasBeenHighlighted(true);
    }
  }, [highlightedArticles, article_id]);

  const handleClick = useCallback(async () => {
    addHighlightedArticle(article_id);

    if (!articleHasBeenHighlighted) {
      const url = `${COVERAGE_API_ROOT}/screenshots`;
      const config = {
        method: 'POST',
        urlRoot: COVERAGE_API_ROOT,
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ data: { article_id, article_url } }),
      };

      try {
        setIsLoading(true);
        const request = await fetch(url, config);
        const data = await request.json();

        if (data && !data.errors) {
          setArticleHasBeenHighlighted(true);
          setIsLoading(false);
          handleNotification({
            type: 'success',
            message: 'Highlight saved!',
          });
        } else {
          setArticleHasBeenHighlighted(false);
          removeHighlightedArticle(article_id);
          setIsLoading(false);
          handleNotification({
            type: 'error',
            message: 'Something went wrong. Please try again.',
          });
        }
      } catch (error) {
        removeHighlightedArticle(article_id);
        setIsLoading(false);
      }
    } else {
      setArticleHasBeenHighlighted(false);
      removeHighlightedArticle(article_id);
      handleNotification({
        type: 'success',
        message: 'Highlight removed!',
      });
    }
  }, [
    addHighlightedArticle,
    articleHasBeenHighlighted,
    article_id,
    article_url,
    handleNotification,
    removeHighlightedArticle,
  ]);

  const icon = articleHasBeenHighlighted
    ? 'fa-solid fa-heart heart-icon-highlighted cursor-pointer'
    : 'fa-regular fa-heart cursor-pointer';

  const getIconClass = () => {
    if (articleHasBeenHighlighted) {
      return 'fa-heart heart-icon-highlighted';
    }

    if (userCanTakeScreenshot && !articleHasBeenHighlighted) {
      return 'fa-regular fa-heart cursor-pointer';
    }

    if (!userCanTakeScreenshot && !articleHasBeenHighlighted) {
      return 'fa-heart heart-icon--disabled';
    }
  };

  const getButtonClass = () => {
    if (userCanTakeScreenshot || articleHasBeenHighlighted) {
      return 'highlight-article-icon';
    }

    if (!userCanTakeScreenshot && !articleHasBeenHighlighted) {
      return 'highlight-article-icon--disabled';
    }
  };

  const isButtonDisabled = (!userCanTakeScreenshot && !articleHasBeenHighlighted) || isLoading;

  return (
    <button
      className={getButtonClass()}
      title={translate('pages.reports.articles_table.highlight_article')}
      onClick={handleClick}
      disabled={isButtonDisabled}
    >
      {isLoading ? (
        <Spinner animation="border" role="status" className="mr-1 mt-1 loading-spinner" aria-label="spinner">
          <span className="visually-hidden">{translate('pages.reports.loading')}</span>
        </Spinner>
      ) : (
        <FontAwesomeIcon icon={icon} className={getIconClass()} aria-label="heartIcon" />
      )}
    </button>
  );
};

const mapStateToProps = (state) => ({
  highlightedArticles: getReportsHighlightedArticles(state),
  maxScreenshots: getOrganizationPermissionsMaxScreenshots(state),
  userCanTakeScreenshot: getOrganizationPermissionsCanTakeScreenshot(state),
});

export default connect(mapStateToProps, { addHighlightedArticle, removeHighlightedArticle })(HighlightArticleIcon);
