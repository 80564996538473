import React from 'react';

import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';

import {
  changeArticleBulkForm,
  changeArticleBulkFormStatus,
  STATUS_ERROR,
  STATUS_LOADED,
  STATUS_LOADING,
} from '../../../actions/forms';
import { convertItemsForSelect, isValidUrl, prependHTTP } from '../../../config/api-service';
import { getLanguages } from '../../../selectors/languages';
import { getTagOptions } from '../../../selectors/tags';
import SingleSelect from '../forms/SingleSelect';
import './articleBulkForm.css';

const ArticlesBulkFormComp = ({ onLinksChange, onChange, fieldsStatus, fields, tagOptions, setSaveButtonDisabled }) => {
  const handleTextInputChange = (event, field) => {
    onLinksChange({ field, value: event.target.value });
  };

  const handleChange = (value, field) => {
    onChange({ field, value: value.value });
    setSaveButtonDisabled(false);
  };

  const renderParseResult = () => {
    if (fieldsStatus.linksResult === STATUS_LOADED) {
      return (
        <div className="alert alert-success article-alert-message">
          <FontAwesomeIcon icon={faCheckDouble} />
          {fields.linksResult}
        </div>
      );
    } else if (fieldsStatus.linksResult === STATUS_LOADING) {
      return (
        <div className="alert alert-info article-alert-message">
          <FontAwesomeIcon icon={'spinner2'} /> {fields.linksResult}
        </div>
      );
    } else if (fieldsStatus.linksResult === STATUS_ERROR) {
      return (
        <div className="alert alert-warning article-alert-message">
          <FontAwesomeIcon icon={'warning'} /> {fields.linksResult}
        </div>
      );
    }
    return <div />;
  };
  const renderTagSelectResult = () => {
    if (fieldsStatus.tagId === STATUS_LOADED) {
      return null;
    }
    return <div />;
  };
  return (
    <>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <SingleSelect
              name="tag-select"
              items={tagOptions}
              value={fields.tagId}
              placeholder="Tag"
              onChange={(e) => handleChange(e, 'tagId')}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <textarea
              type="text"
              rows="16"
              onChange={(e) => handleTextInputChange(e, 'links')}
              placeholder="Links separated by line breaks"
              value={fields.links || ''}
              className="form-control"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            {renderParseResult()}
          </Col>
          <Col md={12} xs={12}>
            {renderTagSelectResult()}
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

ArticlesBulkFormComp.propTypes = {
  langOptions: PropTypes.array,
  tagOptions: PropTypes.array,
  fields: PropTypes.object,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  onLinksChange: PropTypes.func,
  fieldsStatus: PropTypes.shape({
    linksResult: PropTypes.string,
    tagId: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  formTitle: state.forms.newArticleBulkForm ? state.forms.newArticleBulkForm.title : '',
  fields: state.forms.newArticleBulkForm ? state.forms.newArticleBulkForm.fields : {},
  fieldsStatus: state.forms.newArticleBulkForm ? state.forms.newArticleBulkForm.fieldsStatus : {},
  tagOptions: state.forms.newArticleBulkForm ? convertItemsForSelect(getTagOptions(state)) : [],
  langOptions: convertItemsForSelect(getLanguages(state)),
});

const mapDispatchToProps = (dispatch) => ({
  onLinksChange: (inputText) => {
    dispatch(changeArticleBulkForm({ field: 'linksResult', value: 'Loading...' }));
    dispatch(changeArticleBulkFormStatus({ field: 'linksResult', value: STATUS_LOADING }));
    dispatch(changeArticleBulkForm(inputText));
    Papa.parse(inputText.value, {
      header: false,
      delimiter: '\t',
      complete: (results) => {
        let flatInput = results.data.flat();
        const valid = flatInput.filter(isValidUrl).map(prependHTTP);
        const validSize = valid.length;
        const maxValidSize = 1000;
        if (validSize > 0 && validSize < maxValidSize) {
          const linkPlural = validSize === 1 ? 'link is' : 'links are';
          const message = ` ${validSize} ${linkPlural} ready to import.`;
          dispatch(changeArticleBulkForm({ field: 'linksResult', value: message }));
          dispatch(changeArticleBulkForm({ field: 'linksUrls', value: valid }));
          dispatch(changeArticleBulkFormStatus({ field: 'linksResult', value: STATUS_LOADED }));
        } else if (validSize > maxValidSize) {
          const message = `Importing articles is limited to a maximum of ${maxValidSize}.`;
          dispatch(changeArticleBulkForm({ field: 'linksResult', value: message }));
          dispatch(changeArticleBulkFormStatus({ field: 'linksResult', value: STATUS_ERROR }));
        } else {
          const message = 'No links were found.';
          dispatch(changeArticleBulkForm({ field: 'linksResult', value: message }));
          dispatch(changeArticleBulkFormStatus({ field: 'linksResult', value: STATUS_ERROR }));
        }
      },
    });
  },
  onChange: (data) => dispatch(changeArticleBulkForm(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesBulkFormComp);
