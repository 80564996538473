import { connect } from 'react-redux';

import ImportFeed from './ImportFeed';
import { changeFeedForm, resetForm } from '../../../actions/forms';
import { convertItemsForSelect } from '../../../config/api-service';
import { getRssArticles } from '../../../selectors/feeds';
import { getTableFormFieldsById } from '../../../selectors/forms';
import { getTagOptions } from '../../../selectors/tags';

const mapStateToProps = (state) => {
  const nonFiltersFields = getTableFormFieldsById(state, 'articlesFeedTable');
  const checkedIds = nonFiltersFields.checkedRows;
  return {
    checkedIds,
    form: state.forms.feedActionForm.fields,
    nonFiltersFields: getTableFormFieldsById(state, 'articlesFeedTable'),
    toImportIds: Object.keys(checkedIds).filter((artId) => checkedIds[artId]),
    allArticles: getRssArticles(state),
    formStatus: state.forms.feedActionForm.fields.status,
    tagOptions: convertItemsForSelect(getTagOptions(state)),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeTag: ({ value: newTag, label: newTagLabel }) => {
    dispatch(
      changeFeedForm({
        value: newTag,
        field: 'tagId',
      }),
    );
    dispatch(
      changeFeedForm({
        value: newTagLabel,
        field: 'tagLabel',
      }),
    );
  },
  onSubmit: () => {
    dispatch(resetForm());
  },
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ImportFeed);
