import React from 'react';

import { faFileAlt, faUserAlt, faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

import StepIcons from './StepIcons';

const SignupSteps = ({ step, handleBackStep }) => (
  <Nav className="justify-content-center">
    <NavItem>
      <NavLink
        className={classNames('font-weight-semi-bold', {
          'done  cursor-pointer': step > 1,
          active: step === 1,
        })}
        color="red"
        onClick={() => handleBackStep(1)}
      >
        <StepIcons icon={faFileAlt} text="Invite" className="fp-signup-invite" />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames('font-weight-semi-bold', {
          'done cursor-pointer': step > 2,
          active: step === 2,
        })}
        onClick={() => handleBackStep(2)}
      >
        <StepIcons icon={faArrowRight} text="Account" className="fp-signup-account" />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames('font-weight-semi-bold', {
          'done  cursor-pointer': step > 3,
          active: step === 3,
        })}
        onClick={() => handleBackStep(3)}
      >
        <StepIcons icon={faUserAlt} text="Avatar" className="fp-signup-avatar" />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames('font-weight-semi-bold', {
          'done  cursor-pointer': step > 3,
        })}
      >
        <StepIcons icon={faCheck} text="Done" className="fp-signup-done" />
      </NavLink>
    </NavItem>
  </Nav>
);

SignupSteps.propTypes = {
  step: PropTypes.number,
  handleBackStep: PropTypes.func,
};

export default SignupSteps;
