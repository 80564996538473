import {
  SUPERSEARCH_CLEAR,
  SUPERSEARCH_FETCH_VOLUME,
  SUPERSEARCH_FETCH_VOLUME_ERROR,
  SUPERSEARCH_FETCH_VOLUME_SUCCESS,
  SUPERSEARCH_CLEAR_VOLUME_DATA
} from '../../actions/actionTypes';

const defaultState = {
  isLoading: false,
  response: [],
  error: null
};

const volume = (state = defaultState, action) => {
  switch (action.type) {
    case SUPERSEARCH_FETCH_VOLUME:
      return { ...state, isLoading: true };
    case SUPERSEARCH_FETCH_VOLUME_SUCCESS:
      return { ...state, response: action.payload, isLoading: false };
    case SUPERSEARCH_FETCH_VOLUME_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case SUPERSEARCH_CLEAR:
    case SUPERSEARCH_CLEAR_VOLUME_DATA:
      return defaultState;
    default:
      return state;
  }
};

export default volume;
