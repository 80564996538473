import React, { useContext, useState } from 'react';

import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Nav, NavItem } from 'reactstrap';

import ICOProfileDropdown from './ICOProfileDropdown';
import { upgradeSubscriptionClear } from '../../../actions/checkout';
import { navbarBreakPoint } from '../../../config';
import AppContext from '../../../context/Context';
import translate from '../../../helpers/translate';
import { getUsersCurrentUser, getUsersCurrentUserIsAdmin } from '../../../selectors/users';
import ButtonIcon from '../../common/ButtonIcon';
import UpgradeSubscription from '../modals/UpgradeSubscription/UpgradeSubscription';

const ICOTopNavRightSideNavItem = ({ upgradeSubscriptionClear, userIsAdmin, user }) => {
  const { isTopNav, isCombo } = useContext(AppContext);
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);

  const handleClickClose = () => {
    setUpgradeModalOpen(!isUpgradeModalOpen);
    upgradeSubscriptionClear();
  };

  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      <NavItem />
      {(isCombo || isTopNav) && (
        <NavItem className={classNames('p-2 px-lg-0 cursor-pointer', { [`d-${navbarBreakPoint}-none`]: isCombo })} />
      )}
      {userIsAdmin && user.organization.id !== 1 ? (
        <ButtonIcon color="primary" size="sm" icon={faArrowAltCircleUp} onClick={handleClickClose}>
          {translate('plans.upgrade')}
        </ButtonIcon>
      ) : null}
      <ICOProfileDropdown />
      <UpgradeSubscription isOpen={isUpgradeModalOpen} onClose={handleClickClose} />
    </Nav>
  );
};

const mapStateToProps = (state) => ({
  userIsAdmin: getUsersCurrentUserIsAdmin(state),
  user: getUsersCurrentUser(state),
});

export default connect(mapStateToProps, { upgradeSubscriptionClear })(ICOTopNavRightSideNavItem);
