import React, { useEffect } from 'react';

import { InputSelect } from '../../../../components/ICO/forms/InputSelect';
import translate from '../../../../helpers/translate';

const TwitchFeedModalsSaveStreamsBody = ({ setSelectedTag, tags, selectedTag }) => {
  const selectValue = selectedTag ? { value: selectedTag.value, label: selectedTag.label } : null;

  useEffect(() => () => setSelectedTag(null), [setSelectedTag]);

  return (
    <>
      <p>{translate('pages.twitch_feed.modals.save_stream.text')}</p>
      <div className="mt-2">
        <InputSelect
          aria-label="tag-input"
          name="tag-select"
          onChange={(tag) => setSelectedTag(tag)}
          value={selectValue}
          items={tags}
          placeholder={translate('common.select_tag')}
          disabled={false}
        />
      </div>
    </>
  );
};

export default TwitchFeedModalsSaveStreamsBody;
