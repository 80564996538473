import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { Col, Label, Row } from 'reactstrap';

import SimpleDatePicker from './SimpleDatePicker';

const RangeDatePicker = ({ onChangeFrom, onChangeTo, from, to }) => (
  <Row>
    <Col md={6}>
      <Label for="select-dates">Start date</Label>
      <SimpleDatePicker onChange={onChangeFrom} value={from} />
    </Col>
    <Col md={6}>
      <Label for="select-dates">End date</Label>
      <SimpleDatePicker onChange={onChangeTo} value={to} />
    </Col>
  </Row>
);

RangeDatePicker.defaultProps = {
  from: moment().subtract(1, 'month').format('YYYY-DD-MM'),
  to: moment().format('YYYY-DD-MM'),
};

RangeDatePicker.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RangeDatePicker;
