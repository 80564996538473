import debounce from 'lodash/debounce';
import { connect } from 'react-redux';

import TextEditor from './TextEditor';
import { changeNewReportForm, STATUS_DISABLED } from '../../actions/forms';

const mapDispatchToProps = (dispatch) => {
  const onChange = (field, value) => {
    dispatch(
      changeNewReportForm({
        field,
        value,
      }),
    );
  };

  let onChangeHTML = (HTML) => onChange('descriptionHTML', HTML);
  onChangeHTML = debounce(onChangeHTML, 350); //Delay after pressing the key to dispatch action to prevent lag

  return {
    onChangeHTML,
  };
};

const mapStateToProps = (state, ownProps) => ({
  placeholder: 'Enter description for this report...',
  disabled:
    state.forms.newReportForm.fieldsStatus.tags === STATUS_DISABLED ||
    (ownProps.formStatus !== 'STEP 0' && ownProps.formStatus !== 'STEP 0 LOADED'),
});

const NewReportDescriptionEditor = connect(mapStateToProps, mapDispatchToProps)(TextEditor);

export default NewReportDescriptionEditor;
