import {
  SAVED_STREAMS_FETCH_STREAMS,
  SAVED_STREAMS_FETCH_STREAMS_ERROR,
  SAVED_STREAMS_FETCH_STREAMS_SUCCESS,
  SAVED_STREAMS_FETCH_STREAMS_FAILURE,
  SAVED_STREAMS_CLEAN,
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: [],
  error: null,
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case SAVED_STREAMS_FETCH_STREAMS:
      return { ...state, isLoading: true };
    case SAVED_STREAMS_FETCH_STREAMS_SUCCESS:
      return { ...state, isLoading: false, response: action.payload };
    case SAVED_STREAMS_FETCH_STREAMS_FAILURE:
    case SAVED_STREAMS_FETCH_STREAMS_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case SAVED_STREAMS_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default data;
