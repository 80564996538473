/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const CustomSearchBar = ({ onChange, filterSearch }) => {
  const [value, setValue] = useState('');
  const prevValue = usePrevious(value);
  const searchLength = filterSearch.length;

  useEffect(() => {
    if (typeof value !== 'undefined') {
      if (!searchLength) {
        onChange('search', value);
      } else {
        const timeOutId = setTimeout(() => {
          onChange('search', value);
        }, 150);
        return () => clearTimeout(timeOutId);
      }
    }
  }, [value]);

  return (
    <Label for="search-bar" className="search-label">
      <Input
        onChange={(event) => setValue(event.target.value)}
        value={!filterSearch.length && prevValue !== '' ? '' : value}
        id="search-bar"
        name="search-bar"
        type="text"
        className="form-control rounded-pill"
        placeholder="Search..."
      />
    </Label>
  );
};

CustomSearchBar.propTypes = {
  onChange: PropTypes.func,
  filterSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
