import React, { memo } from 'react';

import '../../../assets/scss/theme/Footprints/components/_highlighted-article.scss';
import { connect } from 'react-redux';
import { Card, CardTitle, CardBody, CardSubtitle, Spinner } from 'reactstrap';

import HighlightedArticleImage from './HighlightedArticleImage';
import { extractFavicon, extractDomain } from '../../../config/url-utils';
import translate from '../../../helpers/translate';
import { getArticleByKey } from '../../../selectors/savedCoverage/articles';

const formatDate = (articleDate) => articleDate.split('-').reverse().join('/');

const HighlightedArticle = ({ articleData, screenshots, isLoading }) => {
  if (isLoading) {
    return (
      <div className="d-flex flex-center justify-content-center">
        <Spinner animation="border" role="status" className="loading-spinner" aria-label="spinner">
          <span className="visually-hidden">{translate('pages.reports.loading')}</span>
        </Spinner>
      </div>
    );
  }

  if (screenshots?.length) {
    const screenshotBlob = screenshots.find((item) => item?.article_id === parseInt(articleData?.id));

    return (
      <div className="margin-wrapper">
        <Card className="highlighted-article mt-3" aria-label="highlighted-article">
          <HighlightedArticleImage screenshot={screenshotBlob?.screenshot} articleData={articleData} />

          <CardBody className="p-2">
            <a
              href={articleData?.fullUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="card-link d-inline-block overflow-hidden text-truncate"
            >
              {extractFavicon(articleData.fullUrl) ? (
                <img className="card-favicon" src={extractFavicon(articleData.fullUrl)} alt="Avatar" />
              ) : null}
              {articleData.fullUrl ? extractDomain(articleData.fullUrl) : null}
            </a>

            <CardTitle className="card-title text-truncate mb-1 text-dark font-weight-normal">
              {articleData.title}
            </CardTitle>
            <CardSubtitle tag="h6" className="card-subtitle pb-2 text-secondary">
              {articleData.date ? formatDate(articleData.date) : null}
            </CardSubtitle>
          </CardBody>
        </Card>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state, { highlightedArticle }) => ({
  articleData: getArticleByKey(state, highlightedArticle.article_id),
});

export default memo(connect(mapStateToProps)(HighlightedArticle));
