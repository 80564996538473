import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import { FOOTPRINTS_CONTACT_URL } from '../../../../constants';
import {
  featureFormatter,
  comingSoonFeaturesFormatter,
  getTranslation,
} from '../../../../helpers/formatters/upgradeSubscriptionFormatters';

const UpgradeSubscriptionProductAgency = ({ title, comingSoonFeatures, features }) => {
  const handleClickAgency = () => {
    window.open(FOOTPRINTS_CONTACT_URL, '_blank');
  };

  return (
    <Col sm={12} md={4}>
      <div className="d-flex flex-center flex-column">
        <img
          src="https://ico-files.s3.eu-west-2.amazonaws.com/images/Icon-Agency-Sq.svg"
          className="svg mt-4"
          alt="product icon"
        />
        <h2 className="agency text-bold no_border mt-3">{getTranslation(title)}</h2>
        <Row className="w-100 d-flex justify-content-between">
          <Col className="flex-center d-flex">
            <div
              className="w-75 price-box price-box-agency d-flex flex-column justify-content-center"
              aria-label="agencyProduct"
              onClick={handleClickAgency}
            >
              <span className="text-uppercase text-bold fs-3 agency">{getTranslation('agency.contact_us')}</span>
              <span className="text-bold agency">{getTranslation('agency.personalised_pricing')}</span>
            </div>
          </Col>
        </Row>
        {comingSoonFeatures && comingSoonFeatures.length
          ? [featureFormatter(features), comingSoonFeaturesFormatter(comingSoonFeatures)]
          : featureFormatter(features)}
      </div>
    </Col>
  );
};

UpgradeSubscriptionProductAgency.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  comingSoonFeatures: PropTypes.arrayOf(PropTypes.string),
  features: PropTypes.arrayOf(PropTypes.string),
};

export default UpgradeSubscriptionProductAgency;
