import React, { memo, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ArticlesFeedModalsEditFeedBody from './ArticlesFeedModalsEditFeedBody.jsx';
import { fetchAlerts } from '../../../../../actions/alerts_new';
import { fetchProjects } from '../../../../../actions/projects';
import translate from '../../../../../helpers/translate';
import { getAlert, getCurrentProject } from '../../../../../selectors/projects';
import { useEditArticlesFeedAlert } from '../../hooks/useArticlesFeed';

const ArticlesFeedModalsEditFeed = ({ isOpen, onClose, project, projectAlert, fetchProjects, fetchAlerts }) => {
  const [alertConfig, setAlertConfig] = useState(() => ({
    name: project.name,
    slack_tracking: projectAlert?.['slack-tracking'] ?? false,
    slack_channel_id: projectAlert?.['slack-channel'] ?? '',
    slack_channel_name: projectAlert?.['slack-channel-name'] ?? '',
    min_reach: projectAlert?.['min-reach'] ?? 0,
    keywords: projectAlert?.keywords ?? '',
  }));

  useEffect(() => {
    if (projectAlert) {
      setAlertConfig({
        name: project.name,
        slack_tracking: projectAlert['slack-tracking'],
        slack_channel_id: projectAlert['slack-channel'],
        slack_channel_name: projectAlert['slack-channel-name'],
        min_reach: projectAlert['min-reach'],
        keywords: projectAlert.keywords,
      });
    }
  }, [projectAlert, project]);

  const onSuccess = () => {
    onClose();
    fetchAlerts();
    fetchProjects();
  };

  const { mutate } = useEditArticlesFeedAlert(onSuccess);

  const onClickSave = () => {
    const {
      slack_tracking = projectAlert['slack-tracking'],
      slack_channel_id = projectAlert['slack-channel'],
      slack_channel_name = projectAlert['slack-channel-name'],
      min_reach = projectAlert['min-reach'],
      keywords = projectAlert.keywords,
    } = alertConfig;

    mutate({
      alert_id: project['alert-id'],
      data: {
        type: 'alert',
        attributes: {
          name: project.name,
          slack_tracking,
          slack_channel_id,
          slack_channel_name,
          min_reach,
          keywords,
        },
      },
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
      <ModalHeader className="fp-modal-header--small">{translate('pages.articles_feed.edit_feed')}</ModalHeader>
      <ModalBody className="fp-modal-body">
        <ArticlesFeedModalsEditFeedBody
          alertConfig={alertConfig}
          projectAlert={projectAlert}
          setAlertConfig={setAlertConfig}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button
          size="sm"
          type="submit"
          aria-label="save-button"
          color="primary"
          name="saveButton"
          onClick={onClickSave}
        >
          {translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  projectAlert: getAlert(state, getCurrentProject(state)['alert-id']),
});

export default connect(mapStateToProps, { fetchProjects, fetchAlerts })(memo(ArticlesFeedModalsEditFeed));
