import { combineReducers } from 'redux';

import areNotificationsEnabled from './areNotificationsEnabled';
import data from './data';
import discord from './discord';
import filters from './filters/index';
import keyword from './keyword';
import saveVideos from './saveVideos/index';
import slack from './slack';

const youtubeFeed = combineReducers({
  data,
  keyword,
  filters,
  saveVideos,
  slack,
  discord,
  areNotificationsEnabled,
});

export default youtubeFeed;
