import $ from 'jquery';
import values from 'lodash/values';
import { getJSON, RSAA } from 'redux-api-middleware';

import { COVERAGE_API_ROOT } from './global-config';
/**
 * Detects if JSON API Response is a collection of items or a single item
 */
const isResultCollection = (jsonResult) => Array.isArray(jsonResult.data);

const getToken = () => localStorage.getItem('access_token');

const flattenRelationships = (relationships) =>
  Object.keys(relationships)
    .map((relation) => {
      if (relationships[relation].data) {
        return [relation + '_id', relationships[relation].data.id];
      }
      return [relation + '_id', '0'];
    })
    .reduce(
      (acc, item) => ({
        ...acc,
        [item[0]]: item[1],
      }),
      {},
    );

export const isValidUrl = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const prependHTTP = (s) => {
  if (!s.match(/^[a-zA-Z]+:\/\//)) {
    s = 'http://' + s;
  }
  return s;
};

export const buildFiltersQuery = (
  filters = {
    project_id: 1,
    tags: [1],
  },
  unfiltered = ['sort'],
) => {
  let resultFilters = {};
  Object.keys(filters)
    .filter((key) => !unfiltered.includes(key))
    .map((key) => {
      const filterValue = filters[key];
      if (filterValue[0] !== -1 && filterValue !== -1) {
        resultFilters[key] = filterValue;
      }
      return key;
    });
  return resultFilters; //Returns original object, filtered
};

const flattenJSONData =
  (key = 'id') =>
    (otherData, currentData) => {
      let tag = key !== 'id' ? currentData.attributes[key] : currentData[key];
      const relationshipsNew = flattenRelationships({ ...currentData.relationships });
      const flattened = {
        [tag]: {
          ...currentData.attributes,
          ...relationshipsNew,
          id: currentData.id,
        },
      };
      return {
        ...otherData,
        ...flattened,
      };
    };

const flattenJSONApiResponse = (json, key) => {
  const flattenedResult = json.reduce(flattenJSONData(key), {});
  return flattenedResult;
};

const clearDataResponse = (json, key) => flattenJSONApiResponse(json, key);

export const processCollectionDefault =
  (key = 'id') =>
    (json) => ({
      items: clearDataResponse(json.data, key),
      pagination: json.meta,
    });

export const processSingleDefault =
  (key = 'id') =>
    (json) => ({
      items: clearDataResponse(new Array(json.data), key),
    });

export const genMetaRequest = (params) => Object.assign({}, { request: params });

export const genMetaResult = (params, action, state, res) => {
  if (res) {
    return Object.assign(genMetaRequest(params), {
      result: {
        status: res.status,
        statusText: res.statusText,
      },
    });
  }
  return Object.assign(genMetaRequest(params), {
    result: {
      status: -1,
      statusText: 'Network request fail',
    },
  });
};

export const genGetParams = (params) => decodeURIComponent($.param(params));

export const genGetParamsWithId = (id, params) => decodeURIComponent(id + '?' + $.param(params));

export const genURL = (url = 'articles', params = []) => {
  const getParams = genGetParams(params);
  if (getParams) {
    return url + '?' + getParams;
  }
  return url;
};

export const defaultCSVRequestOptions = () => {
  const token = getToken();
  if (token) {
    return {
      method: 'GET',
      urlRoot: COVERAGE_API_ROOT,
      headers: {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
        Authorization: 'Bearer ' + getToken(),
      },
    };
  }
  return {
    method: 'GET',
    urlRoot: COVERAGE_API_ROOT,
    headers: {
      'Content-Type': 'text/csv',
      Accept: 'text/csv',
    },
  };
};

export const defaultRequestOptions = () => {
  const token = getToken();
  if (token && !isCurrentURLPublic()) {
    return {
      method: 'GET',
      urlRoot: COVERAGE_API_ROOT,
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: 'Bearer ' + getToken(),
      },
    };
  }
  return {
    method: 'GET',
    urlRoot: COVERAGE_API_ROOT,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
  };
};

export const requestNoTokenOptions = () => ({
  method: 'GET',
  urlRoot: COVERAGE_API_ROOT,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
});

export const isCurrentURLPublic = () => {
  let url = new URL(window.location.href);
  return url.pathname.slice(0, 5) === '/p/r/' || url.pathname.slice(0, 7) === '/signup';
};

export const apiFetch = (
  endpoint = 'articles',
  actions = {
    request: 'REQUEST',
    receive: 'RECEIVE',
    reject: 'REJECT',
  },
  requestParams = {},
  options = defaultRequestOptions(),
) => {
  endpoint = options.privatePath || (getToken() && !isCurrentURLPublic()) ? endpoint : 'p/' + endpoint;
  let opts = Object.assign({ ...options }, { params: requestParams });
  let endURL = endpoint;
  if (opts.method === 'GET') {
    endURL = genURL(endpoint, opts.params);
  }
  const receiveItemsKey = 'id';
  const params = Object.assign(
    { ...opts },
    {
      endpoint,
      url: endURL,
      paramsUrl: endURL.split('/'),
    },
  );
  let output = {
    [RSAA]: {
      types: [
        {
          type: typeof actions.request === 'string' ? actions.request : actions.request.type,
          payload: () => actions.request.payload,
          meta: (action, state) => genMetaRequest(params, action, state, []),
        },
        {
          type: actions.receive,
          payload: (action, state, res) =>
            getJSON(res).then((json) => {
              if (json) {
                let result = isResultCollection(json)
                  ? processCollectionDefault(receiveItemsKey)(json)
                  : processSingleDefault(receiveItemsKey)(json);
                return result;
              }
              return {};
            }),
          meta: (action, state, res) => genMetaResult(params, action, state, res),
        },
        {
          type: actions.reject,
          meta: (action, state, res) => genMetaResult(params, action, state, res),
        },
      ],
      endpoint: options.urlRoot + '/' + endURL,
      method: opts.method,
    },
  };
  if (requestParams.body) output[RSAA].body = requestParams.body;
  if (opts.headers) output[RSAA].headers = opts.headers;
  return output;
};

export const STATUS = {
  NOT_FOUND: 'NOT_FOUND',
  OK: 'OK',
  KO: 'KO',
  SAVED: 'SAVED',
  DELETING: 'DELETING',
  LOADING: 'LOADING',
  DELETED: 'DELETED',
};

export const setItemsStatus = (status = STATUS.LOADING, items = {}) =>
  Object.keys(items).reduce((acc, key) => {
    acc[key] = status;
    return acc;
  }, {});

export const apiRequest = (state, action) => {
  const listKey = genGetParams(action.meta.request.params);
  return Object.assign({}, state, {
    listsStatus: Object.assign({}, state.listsStatus, { [listKey]: STATUS.LOADING }),
  });
};

export const apiRequestId = (state, action) => {
  const entityId = action.meta.request.endpoint.split('/')[1];
  const listKey = genGetParamsWithId(entityId, action.meta.request.params);
  return Object.assign({}, state, {
    listsStatus: Object.assign({}, state.listsStatus, { [listKey]: STATUS.LOADING }),
  });
};

export const apiRequestOne = (state, action, id) => {
  id = id ? id : action.meta.request.paramsUrl[1];
  return Object.assign({}, state, {
    itemsStatus: { ...state.itemsStatus, [id]: STATUS.LOADING },
  });
};

export const apiRequestSave = (state, action) =>
  Object.assign({}, state, {
    items: Object.assign({}, state.items, action.payload.items),
    listsStatus: Object.assign({}, state.itemsStatus, { saving: STATUS.LOADING }),
  });

export const apiReceiveOne = (state, action, status = STATUS.OK, id = null) => {
  id = id ? id : action.meta.request.paramsUrl[1];
  return Object.assign({}, state, {
    items: Object.assign({}, state.items, action.payload.items),
    itemsStatus: Object.assign({}, state.itemsStatus, { [id]: status }),
  });
};

export const apiReceiveOneGroup = (state, action, status = STATUS.OK, id = null) => {
  id = id ? id : action.meta.request.endpoint.split('/')[1];
  const listKey = genGetParams(action.meta.request.params);
  return Object.assign({}, state, {
    items: { [id]: action.payload.items, ...state.items },
    itemsStatus: Object.assign({}, state.itemsStatus, { [id]: status }),
    lists: Object.assign({}, state.lists, {
      [listKey]: Object.keys(action.payload.items).map((itemId) => itemId),
    }),
    listsStatus: Object.assign({}, state.listsStatus, { [listKey]: status }),
    stats: Object.assign({}, state.stats, { [listKey]: action.payload.pagination }),
  });
};

export const apiReceiveDeleted = (state, action, status = STATUS.DELETED) => {
  const paramsId = action.meta.request.params.id;
  return Object.assign({}, state, {
    itemsStatus: Object.assign({}, state.itemsStatus, { [paramsId]: status }),
  });
};

export const apiReceiveSaved = (state, action) =>
  Object.assign({}, state, {
    items: Object.assign({}, state.items, action.payload.items),
    itemsStatus: Object.assign({}, state.itemsStatus, setItemsStatus(STATUS.OK, action.payload.items)),
    listsStatus: {
      ...state.listsStatus,
      saving: STATUS.OK,
    },
  });

export const apiReceive = (state, action, status = STATUS.OK) => {
  const listKey = genGetParams(action.meta.request.params);
  return Object.assign({}, state, {
    items: Object.assign({}, state.items, action.payload.items),
    itemsStatus: Object.assign({}, state.itemsStatus, setItemsStatus(status, action.payload.items)),
    lists: Object.assign({}, state.lists, {
      [listKey]: Object.keys(action.payload.items).map((itemId) => itemId),
    }),
    listsStatus: Object.assign({}, state.listsStatus, { [listKey]: status }),
    stats: Object.assign({}, state.stats, { [listKey]: action.payload.pagination }),
  });
};

export const apiReceiveId = (state, action, status = STATUS.OK) => {
  const entityId = action.meta.request.endpoint.split('/')[1];
  const listKey = genGetParamsWithId(entityId, action.meta.request.params);
  return Object.assign({}, state, {
    items: Object.assign({}, state.items, action.payload.items),
    itemsStatus: Object.assign({}, state.itemsStatus, setItemsStatus(status, action.payload.items)),
    lists: Object.assign({}, state.lists, {
      [listKey]: Object.keys(action.payload.items).map((itemId) => itemId),
    }),
    listsStatus: Object.assign({}, state.listsStatus, { [listKey]: status }),
    stats: Object.assign({}, state.stats, { [listKey]: action.payload.pagination }),
  });
};

export const apiReject = (state, action) => {
  const listKey = genGetParams(action.meta.request.params);
  return Object.assign({}, state, {
    listsStatus: Object.assign({}, state.listsStatus, { [listKey]: STATUS.KO }),
  });
};

export const apiRejectOne = (state, action, status = STATUS.KO) => {
  const id = action.meta.request.paramsUrl[1];
  return Object.assign({}, state, {
    itemsStatus: Object.assign({}, state.itemsStatus, { [id]: status }),
  });
};

export const convertItemsForSelect = (rows) => {
  const items = values(rows);
  return items.map((row) => ({
    value: row.id,
    label: row.name,
  }));
};
