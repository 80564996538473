import { combineReducers } from 'redux';

import endDate from './endDate';
import languages from './languages';
import maxReach from './maxReach';
import metacritic from './metacritic';
import minReach from './minReach';
import startDate from './startDate';

const filters = combineReducers({ startDate, endDate, languages, minReach, maxReach, metacritic });

export default filters;
