import { useState, useCallback, useEffect } from 'react';

import { defaultRequestOptions, isCurrentURLPublic } from '../../../config/api-service';
import { COVERAGE_API_ROOT } from '../../../config/global-config';

const useGetScreenshot = (highlightedArticles = []) => {
  const [base64Screenshots, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchScreenshots = useCallback(async () => {
    setIsLoading(true);

    const screenshotPromises = highlightedArticles.map(async ({ article_id }) => {
      const url = isCurrentURLPublic()
        ? `${COVERAGE_API_ROOT}/p/screenshots/${article_id}`
        : `${COVERAGE_API_ROOT}/screenshots/${article_id}`;

      try {
        const response = await fetch(url, defaultRequestOptions());

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const { data } = await response.json();
        return { article_id, screenshot: data };
      } catch (error) {
        setError(error);
        return null;
      }
    });

    const screenshotData = (await Promise.all(screenshotPromises)).filter(Boolean);

    setIsLoading(false);

    if (screenshotData.length) {
      setResponse(screenshotData);
    }
  }, [highlightedArticles]);

  useEffect(() => {
    if (highlightedArticles && highlightedArticles.length) {
      fetchScreenshots();
    }
  }, [fetchScreenshots, highlightedArticles]);

  return [isLoading, base64Screenshots, error];
};

export default useGetScreenshot;
