import React from 'react';

import HighCharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';

import Loading from '../../components/ICO/Loading/Loading';
import translate from '../../helpers/translate';
import {
  getSuperSearchVolumeIsLoading,
  getSuperSearchVolumeResponse,
  getSuperSearchVolumeSeriesName,
} from '../../selectors/superSearch';

const SuperSearchVolumeChart = ({ data, isLoading, seriesNames }) => {
  const config = {
    chart: {
      type: 'column',
    },
    colors: ['#519F67'],
    title: {
      align: 'left',
      text: translate('pages.supersearch.volume_chart.title'),
      x: 70,
      style: {
        fontSize: '1.563rem',
      },
    },
    subtitle: translate('pages.supersearch.volume_chart.subtitle'),
    xAxis: {
      categories: seriesNames,
      crosshair: true,
    },
    yAxis: {
      title: {
        text: translate('pages.supersearch.volume_chart.n_articles'),
        style: {
          fontFamily: 'Jost Medium',
          fontWeight: '600',
          fontSize: '0.75rem',
        },
      },
    },
    tooltip: {
      style: {
        color: 'white',
      },
    },
    series: [
      {
        name: translate('pages.supersearch.volume_chart.series_name'),
        data,
      },
    ],
  };

  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardBody>
            {isLoading && <Loading isTable={false} pageSize={12} />}
            <HighchartsReact highcharts={HighCharts} options={config} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

SuperSearchVolumeChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  seriesNames: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: getSuperSearchVolumeIsLoading(state),
  data: getSuperSearchVolumeResponse(state),
  seriesNames: getSuperSearchVolumeSeriesName(state),
});

export default connect(mapStateToProps, null)(SuperSearchVolumeChart);
