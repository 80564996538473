import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import SavedVideosTable from './Table/SavedVideosTable.jsx';
import { fetchLanguages } from '../../actions/languages.js';
import { fetchTags } from '../../actions/tags';
import getToastedNotification from '../../components/ICO/helpers/toaster.helper';
import translate from '../../helpers/translate';
import { getCurrentProjectId } from '../../selectors/forms';
import { getSavedVideosError } from '../../selectors/savedCoverage/savedVideos';

const SavedVideos = ({ errors, fetchTags, projectId, fetchLanguages }) => {
  useEffect(() => {
    fetchTags(projectId);
    fetchLanguages();
  }, [fetchLanguages, fetchTags, projectId]);

  useEffect(() => {
    if (errors) {
      const customMessage = {
        type: 'error',
        message: translate('pages.saved_videos.notifications.fetch_error'),
      };
      getToastedNotification({ customMessage });
    }
  }, [errors]);

  return <SavedVideosTable />;
};

const mapStateToProps = (state) => ({
  errors: getSavedVideosError(state),
  projectId: getCurrentProjectId(state),
});

export default connect(mapStateToProps, { fetchTags, fetchLanguages })(SavedVideos);
