import React from 'react';
import translate from '../../helpers/translate';
import { useAuth0 } from '@auth0/auth0-react';
import { setIsFirstLogin } from '../../helpers/localStorage';

import { Card, CardBody, Button } from 'reactstrap';

const ErrorNoCustomer = () => {
  const { user, logout } = useAuth0();
  const handleLogout = () => {
    setIsFirstLogin(false);
    logout({
      returnTo: window.location.protocol + '//' + window.location.host + '/authentication/basic/logout'
    });
  };

  return (
    <Card className="text-center">
      <CardBody className="p-5">
        <div className="display-1 text-200 fs-error">401</div>
        <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
          {translate('errors.no_customer.customer_not_found')}
        </p>
        <hr />

        <p>
          {translate('errors.no_customer.please')}
          <a href="mailto:support@footprints.gg" className="ml-1">
            {translate('errors.contact_support')}
          </a>
          .
        </p>

        <Button color="primary" onClick={handleLogout}>
          {translate('common.logout')}
        </Button>
      </CardBody>
    </Card>
  );
};

export default ErrorNoCustomer;
