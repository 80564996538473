import React from 'react';

import PropTypes from 'prop-types';

import { IS_MOBILE } from '../../../constants';
import useDeviceScreen from '../../../hooks/useDeviceScreen';

import './_style.scss';

const FootprintsLogo = ({ className }) => {
  const deviceScreen = useDeviceScreen();
  const logo =
    deviceScreen.screenWidth <= IS_MOBILE
      ? 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Web-Footprints-Logo-Mobile-DarkTheme.svg'
      : 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Web-Footprints-Logo-Strapline-DarkTheme.svg';

  return <img src={logo} className={`logo image-fluid ${className}`} alt="Footprints Logo" />;
};

FootprintsLogo.propTypes = {
  className: PropTypes.string,
};

export default FootprintsLogo;
