import { combineReducers } from 'redux';

import account from './account';
import client from './client';
import customer from './customer';
import project from './project';

const signupFreeUser = combineReducers({
  client,
  customer,
  account,
  project,
});

export default signupFreeUser;
