import React from 'react';

import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import TwitchFeedTableVideoPreviewModalBody from './TwitchFeedTableVideoPreviewModalBody.jsx';
import TwitchFeedTableVideoPreviewModalFooter from './TwitchFeedTableVideoPreviewModalFooter.jsx';

const TwitchFeedTableVideoPreviewModal = ({ isOpen, onClose, videoData }) => (
  <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
    <ModalBody className="fp-modal-body p-4">
      <TwitchFeedTableVideoPreviewModalBody videoData={videoData} />
    </ModalBody>
    <ModalFooter>
      <TwitchFeedTableVideoPreviewModalFooter videoData={videoData} onClose={onClose} withSave={true} />
    </ModalFooter>
  </Modal>
);

export default TwitchFeedTableVideoPreviewModal;
