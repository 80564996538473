const factsArray = [
  'Did you know? Assassin\'s Creed was initially a Prince of Persia spinoff.',
  'Comedians Andy Richter and Conan O\'Brien both have cameo\'s in Halo 4.',
  'The Game Boy version of Tetris was the first game played in space.',
  'During the 2008 presidential election, Barack Obama purchased ad space in Burnout Paradise, Skate, Madden, and more.',
  'The first mockup of the Xbox was called the "DirectX Box", a metal X with a PC board inside.',
  'FIFA 11 was the first FIFA game to let you play as the goalkeeper.',
  'Did you know? Leonard Nimoy is the narrator in Civilization IV.',
  'The game manual for Super Mario Kart actually encouraged screen watching while playing multiplayer.',
  'Spiderman was an unlockable character in Tony Hawk\'s Pro Skater 2.',
  'A piece of cheese is hidden in every level of Perfect Dark on the N64.',
  'Pac-Man was invented when Toru Iwatani, it\'s designer was eating pizza.',
  'In Arkham Asylum, Batman\'s cape won\'t open if the game detects you are playing a pirated copy, meaning you can\'t complete the story.',
  'Alan Wake on PC would give the main character a jolly roger eye patch if it was a pirated version of the game.',
  'Rockstar Games controversially paid for negative reviews on GTA to build hype and create more attention.',
  'Studies indicate that surgeons who regularly play video games make 37% fewer mistakes and operate 27% faster than their peers.',
  'In Pokemon Red & Blue, the three renowned birds Arcticuno, Zapdos and Moltres actually means "One Two Three" in Spanish.',
  'Guitar Hero: Aerosmith made more money for Aerosmith than any of their previous albums.',
  'A gene in the human body called SHH is named after Sonic the Hedgehog. It ensures all our limbs and organs grow where they should.',
  'South Korea\'s \'Cinderella law\' forbids gamers under- the age of 16 to play online video games after midnight.',
  'The "ding" that rings out when a Fat Man nuke is launched in Fallout 3 is the lunch bell from Bethesda Softworks\' cafeteria.',
  'In 2005, a group called The Rare Witch Project cracked GoldenEye\'s code and found models for Roger Moore, Timothy Dalton and Sean Connery.',
  'The U.K. versions of FIFA 2001 shipped with scratch and sniff discs. If you scratched it, you would "smell the pitch".',
];

const getRandomGamingFact = () => factsArray[Math.floor(Math.random() * factsArray.length)];

export default getRandomGamingFact;
