import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { reportDetailsFetchVideos, reportsVideosClean } from '../../../actions/reports';
import Table from '../../../components/ICO/Table/Table.jsx';
import translate from '../../../helpers/translate';
import { getReportVideosData, getReportVideosIsLoading, getReportVideosPagination } from '../../../selectors/reports';
import columns from '../../SavedVideos/Table/columns.jsx';

const ReportDetailsVideos = ({
  data = [],
  isLoading,
  pagination,
  currentReport,
  isPublicReport,
  reportDetailsFetchVideos,
  reportsVideosClean,
}) => {
  const reportDetailsColumns = columns.filter((columns) => columns.text !== 'Actions');
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (currentReport['reports-videos']) {
      reportDetailsFetchVideos({
        report_id: currentReport.id,
        pagination: { sizePerPage: 10, page: 1, sortField: 'published_at' },
        isPublicRoute: isPublicReport,
      });
    }
    return () => {
      reportsVideosClean();
    };
  }, [isPublicReport, currentReport, reportDetailsFetchVideos, reportsVideosClean]);

  const onTableChange = (type, { searchText, sizePerPage, page, sortField, sortOrder }) => {
    const sortFieldExists = sortField ?? 'published_at';

    if (type === 'search' && searchText) {
      reportsVideosClean();
      reportDetailsFetchVideos({
        report_id: currentReport.id,
        filters: {
          searchTerm: searchText,
        },
        isPublicRoute: isPublicReport,
      });
    } else {
      reportDetailsFetchVideos({
        report_id: currentReport.id,
        pagination: {
          page,
          sizePerPage,
          sortField: sortFieldExists,
          sortOrder,
        },
        isPublicRoute: isPublicReport,
      });
    }
  };

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    reportDetailsFetchVideos({
      report_id: currentReport.id,
      pagination: {
        page: pagination.page,
        sizePerPage: newSize,
        sortField: 'published_at',
      },
      isPublicRoute: isPublicReport,
    });
  };

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  return (
    <Table
      data={data}
      isLoading={isLoading}
      title={translate('reports.videos')}
      columns={reportDetailsColumns}
      onTableChange={onTableChange}
      pagination={paginationWithExtra}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getReportVideosData(state),
  isLoading: getReportVideosIsLoading(state),
  pagination: getReportVideosPagination(state),
});

export default connect(mapStateToProps, { reportDetailsFetchVideos, reportsVideosClean })(ReportDetailsVideos);
