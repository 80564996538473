import { combineReducers } from 'redux';

import {
  RECEIVE_DELETE_RSS_ARTICLE,
  RECEIVE_RSS_ARTICLES,
  RECEIVE_RSS_ARTICLE_GROUPS,
  RECEIVE_SAVE_RSS_ARTICLE,
  REJECT_RSS_ARTICLES,
  REJECT_RSS_ARTICLE_GROUPS,
  REQUEST_RSS_ARTICLES,
  REQUEST_RSS_ARTICLE_GROUPS,
  REQUEST_SAVE_RSS_ARTICLE,
} from '../actions/feeds';
import {
  STATUS,
  apiReceiveOne,
  apiReject,
  apiRequest,
  apiRequestOne,
  apiReceiveOneGroup,
  apiReceiveId,
  apiRequestId,
} from '../config/api-service';

const rssArticles = (
  state = {
    items: {},
    itemsStatus: {},
    lists: {},
    listsStatus: {},
    stats: {},
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_SAVE_RSS_ARTICLE:
      return apiRequestOne(state, action, action.payload.id);
    case RECEIVE_SAVE_RSS_ARTICLE:
      return apiReceiveOne(state, action, STATUS.SAVED, action.payload.id);
    case RECEIVE_DELETE_RSS_ARTICLE:
      return apiReceiveOne(state, action, STATUS.DELETED, action.payload.id);
    case REQUEST_RSS_ARTICLES:
      return apiRequestId(state, action);
    case RECEIVE_RSS_ARTICLES:
      return apiReceiveId(state, action);
    case REJECT_RSS_ARTICLES:
      return apiReject(state, action);
    default:
      return Object.assign({}, state);
  }
};

const groups = (
  state = {
    items: {},
    itemsStatus: {},
    lists: {},
    listsStatus: {},
    stats: {},
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_RSS_ARTICLE_GROUPS:
      return apiRequest(state, action);
    case RECEIVE_RSS_ARTICLE_GROUPS:
      return apiReceiveOneGroup(state, action);
    case REJECT_RSS_ARTICLE_GROUPS:
      return apiReject(state, action);
    default:
      return Object.assign({}, state);
  }
};

export default combineReducers({
  rssArticles,
  groups,
});
