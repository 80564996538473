import React from 'react';

import PropTypes from 'prop-types';

const DetachedContent = ({ children }) => (
  <div className="container-detached">
    <div className="content-detached">{children}</div>
  </div>
);

DetachedContent.propTypes = {
  children: PropTypes.node,
};

export default DetachedContent;
