import { RECEIVE_SAVE_AVATAR } from '../../actions/files';
import { CHANGE_USER_FORM, RESET_FORM } from '../../actions/forms';
import { RECEIVE_USER_PROFILE } from '../../actions/users';

const initialState = {
  name: '',
  avatar: '',
};

const userForm = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_USER_PROFILE: {
      return { ...state, avatar: Object.values(action.payload.items)[0].avatar };
    }
    case CHANGE_USER_FORM:
      return {
        ...state,
        name: action.payload.name,
        avatar: action.payload.value,
      };

    case RECEIVE_SAVE_AVATAR:
      return { ...state, avatar: action.payload.items.url.fullUrl };
    case RESET_FORM: {
      return { ...state, name: '', avatar: '' };
    }
    default:
      return state;
  }
};

export default userForm;
