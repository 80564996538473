import React from 'react';

import { faPencilAlt, faRss, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import LockActions from './LockActions.js';
import '../../../assets/scss/theme/Footprints/components/_action.scss';
import translate from '../../../helpers/translate.js';

const EditActions = ({
  article,
  canAnalyze,
  canDelete,
  canLock,
  canManageAlert,
  data,
  id,
  lockLabel,
  onAlertEdit,
  onDeleteClick,
  onEdit,
  onLock,
  onUnlock,
  type,
}) => {
  const deleteLink = canDelete && (
    <DropdownItem onClick={onDeleteClick} className="text-danger">
      <FontAwesomeIcon icon={faTrash} /> Delete
    </DropdownItem>
  );

  const isArticleMissingData = article
    ? article.language === 'XX' || article.language === 'UNKNOWN' || !article.title || !article.date
    : false;

  const analyzeLink = canAnalyze && isArticleMissingData && (
    <DropdownItem>
      <a target="_new" href={`https://api.icomedia.eu/voreux/analyze?url=${article.fullUrl}&id=${article.id}`}>
        <i className="icon-lab" />
        {translate('articles.options.request_analyse')}
      </a>
    </DropdownItem>
  );

  const lockLink = canLock && (
    <LockActions locked={data.locked} type={type} onLock={onLock} onUnlock={onUnlock} lockLabel={lockLabel} />
  );

  const feedLink = canManageAlert && (
    <>
      <DropdownItem divider />
      <DropdownItem id="edit-alert-button" onClick={onAlertEdit}>
        <FontAwesomeIcon icon={faRss} /> Change search query
      </DropdownItem>
    </>
  );

  return (
    <UncontrolledDropdown className="fp-action">
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right positionFixed className="border py-2">
        <Link id={id} onClick={onEdit} to="#">
          <DropdownItem>
            <FontAwesomeIcon icon={faPencilAlt} /> Edit {type}
          </DropdownItem>
        </Link>
        <DropdownItem divider />
        {deleteLink}
        {lockLink}
        {analyzeLink}
        {feedLink}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

EditActions.propTypes = {
  article: PropTypes.shape(),
  canAnalyze: PropTypes.bool,
  canDelete: PropTypes.bool,
  canLock: PropTypes.bool,
  canManageAlert: PropTypes.bool,
  data: PropTypes.shape(),
  id: PropTypes.string,
  lockLabel: PropTypes.string,
  onAlertEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEdit: PropTypes.func,
  onLock: PropTypes.func,
  onUnlock: PropTypes.func,
  type: PropTypes.string,
};

export default EditActions;
