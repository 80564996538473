import React from 'react';

import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { savedStreamsTableFiltersSetLanguages } from '../../../../actions/savedStreams';
import SingleSelect from '../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../helpers/translate';
import { getLanguagesValueAndLabel } from '../../../../selectors/languages';
import { getSavedStreamsFiltersLanguages } from '../../../../selectors/savedCoverage/savedStreams';

const SavedStreamsFiltersModalBodyLanguages = ({
  languages,
  selectedLanguages,
  savedStreamsTableFiltersSetLanguages,
}) => {
  const handleOnChange = (languages) => {
    if (languages) {
      const languageValues = languages.map(({ value }) => value);
      savedStreamsTableFiltersSetLanguages(languageValues);
    } else {
      savedStreamsTableFiltersSetLanguages(null);
    }
  };

  return (
    <Row>
      <Col>
        <FormGroup>
          <Label for="languages">{translate('pages.saved_streams.table.filters.languages')}</Label>
          <SingleSelect
            aria-label="lang-select"
            name="languages"
            isMulti
            items={languages}
            value={selectedLanguages ?? []}
            onChange={handleOnChange}
            className="sm-select"
            placeholder={translate('pages.saved_streams.table.filters.all_languages')}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  languages: getLanguagesValueAndLabel(state),
  selectedLanguages: getSavedStreamsFiltersLanguages(state),
});

export default connect(mapStateToProps, { savedStreamsTableFiltersSetLanguages })(
  SavedStreamsFiltersModalBodyLanguages,
);
