import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const getValue = (opts, val, isMulti) => {
  if (isMulti) {
    return opts.filter((opt) => val.includes(opt.value));
  } else {
    return opts.find((o) => o.value === val);
  }
};
const NoOptionsMessage = (props) => (
  <components.NoOptionsMessage {...props}>
    Please select a region first to filter by countries
  </components.NoOptionsMessage>
);

const SingleSelect = ({
  items,
  placeholder,
  value,
  onChange,
  name,
  className,
  noOptionsMessage,
  isMulti = false,
  ...props
}) => {
  const initialValue = value.length === 0 ? '' : getValue(items, value, isMulti);

  const [getOption, setOption] = useState(initialValue);

  const handleSelectChange = (selectedOption) => {
    setOption(selectedOption);
    onChange(selectedOption, name);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#dfdfdf',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0.3125rem 1rem',
      fontSize: '0.875rem',
      minHeight: 'calc(1.5em + .625rem + 2px)',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#40404A',
    }),
  };

  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#5ca6bd',
      primary25: '#f2ce88',
      primary50: '#5ca6bd',
      neutral80: '#999999',
    },
  });

  return (
    <Select
      name={name}
      value={getOption ? getOption : getValue(items, value, isMulti)}
      components={noOptionsMessage ? { NoOptionsMessage } : null}
      options={items}
      placeholder={placeholder}
      isSearchable={true}
      isMulti={isMulti}
      onChange={handleSelectChange}
      clearable={false}
      className={className ? className : 'singleselect'}
      styles={customStyles}
      theme={theme}
      {...props}
    />
  );
};

SingleSelect.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array, // If multi values
  ]),
  className: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default SingleSelect;
