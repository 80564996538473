import { combineReducers } from 'redux';

import addWebsite from './forms/addWebsite';
import newTagForm from './forms/newTagForm';
import renameReport from './forms/renameReport';
import userForm from './forms/userForm';
import { REPORTS_CLEAN, PROJECTS_SET_QUERY } from '../actions/actionTypes';
import { RECEIVE_SAVE_AVATAR } from '../actions/files';
import {
  camelCaseToActionCase,
  CHANGE_ALERT_FORM,
  CHANGE_ALERT_FORM_STATUS,
  CHANGE_ARTICLES_WEBSITE_TABLE_FILTER_FORM,
  CHANGE_ARTICLE_BULK_FORM,
  CHANGE_ARTICLE_BULK_FORM_STATUS,
  CHANGE_ARTICLE_FORM,
  CHANGE_CLIENT_FORM,
  CHANGE_FEED_FORM,
  CHANGE_GLOBAL_FORM,
  CHANGE_NEW_ALERT_FORM,
  CHANGE_NEW_FEED_FORM,
  CHANGE_NEW_REPORT_FORM,
  CHANGE_PROJECT_FORM,
  CHECK_RSS_ARTICLE,
  ENABLE_ALERT_FORM,
  ENABLE_FORM,
  HIDE_MODAL,
  PREVIOUS_REPORT_FORM,
  RESET_CHECKED_COUNT,
  RESET_FEED_FORM,
  RESET_FORM,
  RESET_TABLE_FILTERS,
  SHOW_MODAL,
  SHOW_SIDE_MODAL,
  STATUS_DISABLED,
  STATUS_ENABLED,
  SUBMIT_FORM,
} from '../actions/forms';
import { STATUS } from '../config/api-service';

let lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);

const newReportFormDefaultState = {
  fields: {
    tags: [],
    project: '',
    languages: [],
    titleHTML: '',
    tableType: 0,
    customTableTemplate: '{{title}} | {{score}}',
    section1TitleHTML: '',
    section1SubtitleHTML: '',
    section2TitleHTML: '',
    section2SubtitleHTML: '',
    descriptionHTML: '',
    from: lastMonth.toISOString(),
    to: new Date().toISOString(),
  },
  status: 'STEP 0',
  fieldsStatus: {
    tags: STATUS_DISABLED,
    project: STATUS_ENABLED,
    languages: STATUS_DISABLED,
    from: STATUS_DISABLED,
    to: STATUS_DISABLED,
    submitbtn: STATUS_DISABLED,
  },
};

const newReportForm = (state = newReportFormDefaultState, action) => {
  switch (action.type) {
    case CHANGE_NEW_REPORT_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case SUBMIT_FORM:
      return Object.assign({}, state, {
        status: action.payload.status,
      });
    case PREVIOUS_REPORT_FORM:
      return { ...state, status: 'STEP ' + action.payload };
    case ENABLE_FORM:
      return Object.assign({}, state, {
        fieldsStatus: {
          tags: STATUS_ENABLED,
          project: STATUS_ENABLED,
          languages: STATUS_ENABLED,
          from: STATUS_ENABLED,
          to: STATUS_ENABLED,
          submitbtn: STATUS_ENABLED,
        },
      });
    case REPORTS_CLEAN:
      return newReportFormDefaultState;
    default:
      return state;
  }
};

const newAlertForm = (
  state = {
    title: 'New Alert',
    fields: {
      projectId: '',
      projectName: '',
      name: '',
      keywords: '',
      minReach: 0.1,
      slackChannel: '',
      slackChannelLabel: '',
      slackTracking: false,
    },
    fieldsStatus: {
      name: STATUS_ENABLED,
    },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_NEW_ALERT_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case PROJECTS_SET_QUERY:
      return {
        ...state,
        fields: {
          ...state.fields,
          keywords: action.payload,
        },
      };

    case RESET_FORM:
      return {
        ...state,
        fields: {
          projectId: '',
          projectName: '',
          name: '',
          keywords: '',
          minReach: 0.1,
          slackChannel: '',
          slackTracking: false,
        },
      };
    default:
      return state;
  }
};

const alertsFiltersForm = (
  state = {
    fields: {
      alertId: -1,
      languages: [],
      from: lastMonth.toISOString(),
      to: new Date().toISOString(),
    },
    status: 'STEP 0',
    fieldsStatus: {
      languages: STATUS_DISABLED,
      from: STATUS_DISABLED,
      to: STATUS_DISABLED,
      submitbtn: STATUS_DISABLED,
    },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_ALERT_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case CHANGE_ALERT_FORM_STATUS:
      return Object.assign({}, state, {
        status: action.payload.status,
      });
    case ENABLE_ALERT_FORM:
      return Object.assign({}, state, {
        fieldsStatus: {
          languages: STATUS_ENABLED,
          from: STATUS_ENABLED,
          to: STATUS_ENABLED,
          submitbtn: STATUS_ENABLED,
        },
      });
    default:
      return state;
  }
};

const feedActionForm = (
  state = {
    fields: {
      actionId: 0,
      tagId: -1,
      tagLabel: null,
      importIdsCount: 0,
      importIdsSuccess: 0,
      checkedIds: {},
      status: 'OK',
    },
  },
  action,
) => {
  switch (action.type) {
    case RESET_CHECKED_COUNT:
      return {
        ...state,
        fields: {
          ...state.fields,
          importIdsCount: 0,
          importIdsSuccess: 0,
        },
      };
    case RESET_FEED_FORM:
      return {
        ...state,
        fields: {
          ...state.fields,
          importIdsCount: 0,
          importIdsSuccess: 0,
          checkedIds: {},
        },
      };
    case CHANGE_FEED_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case CHECK_RSS_ARTICLE:
      return {
        ...state,
        fields: {
          ...state.fields,
          checkedIds: {
            ...state.fields.checkedIds,
            [action.payload.id]: action.payload.value,
          },
        },
      };
    default:
      return state;
  }
};

export const globalForm = (
  state = {
    fields: {
      currentProject: null,
    },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_GLOBAL_FORM: {
      const { projectId, defaultProjectId } = action.payload || {};
      const selectedProject = projectId !== undefined ? projectId : defaultProjectId;

      return {
        ...state,
        fields: {
          currentProject: selectedProject,
        },
      };
    }
    default:
      return state;
  }
};

const defaultTableFiltersFormState = {
  fields: {
    tags: [],
    tagId: -1,
    ea_only: -1,
    languages: [],
    regions: [],
    countries: [],
    date_range: [],
    score_range: [],
    mediatypes: [],
    sort: [],
    search: [],
  },
  nonFilterFields: {
    checkedRows: [],
    status: STATUS.OK,
  },
  fieldsStatus: {
    language: STATUS_ENABLED,
    regions: STATUS_ENABLED,
    countries: STATUS_ENABLED,
    tag: STATUS_ENABLED,
    ea_only: STATUS_ENABLED,
    date_range: STATUS_ENABLED,
    score_range: STATUS_ENABLED,
    checkedRows: STATUS_ENABLED,
    exportButton: STATUS_ENABLED,
  },
};

export const createTableFiltersForm =
  (id) =>
    (state = defaultTableFiltersFormState, action) => {
      const NEW_CHANGE_TABLE_FILTER_FORM = `CHANGE_${camelCaseToActionCase(id)}_FILTER_FORM`;
      const NEW_CHANGE_TABLE_FORM = `CHANGE_${camelCaseToActionCase(id)}_FORM`;
      const NEW_CHANGE_TABLE_STATUS_FORM = `CHANGE_${camelCaseToActionCase(id)}_STATUS_FORM`;
      const NEW_RESET_FILTERS = `RESET_${camelCaseToActionCase(id)}_FILTER_FORM`;
      const STATE_CLEAR = `CLEAR_${camelCaseToActionCase(id)}_STATE`;

      switch (action.type) {
        case NEW_CHANGE_TABLE_FORM: {
          const newFields = { ...state.nonFilterFields };
          newFields[action.payload.field] = action.payload.value;
          return { ...state, nonFilterFields: newFields };
        }
        case NEW_CHANGE_TABLE_FILTER_FORM: {
          const newFields = { ...state.fields };
          newFields[action.payload.field] = action.payload.value;
          return { ...state, fields: newFields };
        }
        case NEW_CHANGE_TABLE_STATUS_FORM: {
          const newStatus = { ...state.fieldsStatus };
          newStatus[action.payload.field] = action.payload.value;
          return { ...state, fieldsStatus: newStatus };
        }
        case NEW_RESET_FILTERS:
          return {
            ...state,
            fields: {
              ...state.fields,
              tags: defaultTableFiltersFormState.fields.tags,
              ea_only: defaultTableFiltersFormState.fields.ea_only,
              languages: defaultTableFiltersFormState.fields.languages,
              mediatypes: defaultTableFiltersFormState.fields.mediatypes,
              regions: defaultTableFiltersFormState.fields.regions,
              countries: defaultTableFiltersFormState.fields.countries,
              date_range: defaultTableFiltersFormState.fields.date_range,
              score_range: defaultTableFiltersFormState.fields.score_range,
              sort: defaultTableFiltersFormState.fields.sort,
              search: defaultTableFiltersFormState.fields.search,
            },
          };

        case STATE_CLEAR:
          return defaultTableFiltersFormState;

        default:
          return state;
      }
    };

const newProjectForm = (
  state = {
    title: 'New Project',
    fields: {
      name: '',
      clientId: 0,
      avatar: '',
    },
    fieldsStatus: {
      name: STATUS_ENABLED,
    },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_PROJECT_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case RESET_FORM:
      return { ...state, fields: { name: '', clientId: 0, avatar: '' } };
    case RECEIVE_SAVE_AVATAR:
      return { ...state, fields: { ...state.fields, avatar: action.payload.items.url.url } };
    default:
      return state;
  }
};

const newClientForm = (
  state = {
    title: 'New Brand',
    fields: {
      name: '',
      avatar: '',
    },
    fieldsStatus: {
      client: STATUS_ENABLED,
    },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_CLIENT_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case RESET_FORM: {
      return { ...state, fields: { name: '', avatar: '' } };
    }
    default:
      return state;
  }
};

const modal = (
  state = {
    isVisible: false,
    content: 'edit',
    contentData: {},
    contentId: 0,
  },
  action,
) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isVisible: true,
        content: action.payload.content,
        contentData: action.payload.contentData,
        contentId: action.payload.contentId,
      };
    case HIDE_MODAL:
      return { ...state, isVisible: false };
    default:
      return state;
  }
};

const sidePanelModal = (
  state = {
    content: 'settings',
  },
  action,
) => {
  switch (action.type) {
    case SHOW_SIDE_MODAL:
      return {
        ...state,
        content: action.payload.content,
      };
    default:
      return state;
  }
};

const newFeedForm = (
  state = {
    title: 'Feed',
    fields: {
      feed: '',
      disabled: false,
    },
    fieldsStatus: {
      feed: STATUS_ENABLED,
    },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_NEW_FEED_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case RESET_FORM:
      return {
        ...state,
        fields: {
          feed: '',
        },
      };
    default:
      return state;
  }
};

const newArticleForm = (
  state = {
    title: 'Article',
    fields: {
      url: '',
      title: '',
      language: 'unknown',
      langId: 0,
      reach: 0.1,
      tagId: 0,
      projectId: 0,
      date: '2010-01-01',
    },
    fieldsStatus: {
      url: STATUS_ENABLED,
      title: STATUS_ENABLED,
      language: STATUS_ENABLED,
      reach: STATUS_ENABLED,
      tagId: STATUS_ENABLED,
      langId: STATUS_ENABLED,
    },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_ARTICLE_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case HIDE_MODAL:
    case RESET_FORM:
      return {
        ...state,
        fields: {
          url: '',
          title: '',
          language: 'unknown',
          langId: 0,
          reach: 0.1,
          tagId: 0,
          projectId: 0,
          date: '2010-01-01',
        },
      };
    default:
      return state;
  }
};

const newArticleBulkForm = (
  state = {
    title: 'ArticleBulk',
    fields: {
      links: '',
      linksResult: '',
      linksUrls: [],
      tagId: 0,
    },
    fieldsStatus: {
      links: STATUS_ENABLED,
      linksResult: STATUS_ENABLED,
      tagId: STATUS_ENABLED,
    },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_ARTICLE_BULK_FORM: {
      let newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case CHANGE_ARTICLE_BULK_FORM_STATUS: {
      let newFieldStatus = { ...state.fieldsStatus };
      newFieldStatus[action.payload.field] = action.payload.value;
      return { ...state, fieldsStatus: newFieldStatus };
    }
    case HIDE_MODAL:
    case RESET_FORM:
      return {
        ...state,
        fields: {
          links: '',
          linksResult: '',
          linksUrls: [],
          tagId: 0,
        },
        fieldsStatus: {
          links: STATUS_ENABLED,
          linksResult: STATUS_ENABLED,
          tagId: STATUS_ENABLED,
        },
      };
    default:
      return state;
  }
};

const defaultArticlesWebsiteTableFiltersFormState = {
  fields: {
    search: [],
    date_range: [],
    score_range: [],
  },
  fieldsStatus: {
    search: STATUS_ENABLED,
    date_range: STATUS_ENABLED,
    score_range: STATUS_ENABLED,
  },
};

const articlesWebsiteTableFiltersForm = (state = defaultArticlesWebsiteTableFiltersFormState, action) => {
  switch (action.type) {
    case CHANGE_ARTICLES_WEBSITE_TABLE_FILTER_FORM: {
      const newFields = { ...state.fields };
      newFields[action.payload.field] = action.payload.value;
      return { ...state, fields: newFields };
    }
    case RESET_TABLE_FILTERS:
      return {
        ...state,
        fields: {
          ...state.fields,
          date_range: defaultArticlesWebsiteTableFiltersFormState.fields.date_range,
          score_range: defaultArticlesWebsiteTableFiltersFormState.fields.score_range,
          sort: defaultArticlesWebsiteTableFiltersFormState.fields.sort,
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  modal,
  sidePanelModal,
  feedActionForm,
  newAlertForm,
  alertsFiltersForm,
  newArticleForm,
  newFeedForm,
  newArticleBulkForm,
  newProjectForm,
  newTagForm,
  newReportForm,
  newClientForm,
  articlesWebsiteTableFiltersForm,
  articlesTable: createTableFiltersForm('articlesTable'),
  articlesFeedTable: createTableFiltersForm('articlesFeedTable'),
  websitesTable: createTableFiltersForm('websitesTable'),
  globalForm,
  userForm,
  renameReport,
  addWebsite,
});
