import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import YoutubeFeedTable from './Table/YoutubeFeedTable.jsx';
import YoutubeFeedCreateFeed from './YoutubeFeedCreateFeed.jsx';
import { fetchTags } from '../../actions/tags';
import { getCurrentProjectId } from '../../selectors/forms';
import { getProject } from '../../selectors/projects';

const YoutubeFeed = ({ project, fetchTags }) => {
  useEffect(() => {
    fetchTags(project.id);
  }, [fetchTags, project]);

  return !project['youtube-search-id'] ? (
    <YoutubeFeedCreateFeed project={project} />
  ) : (
    <>
      <YoutubeFeedTable project={project} />
    </>
  );
};

const mapStateToProps = (state) => {
  const currentProjectId = getCurrentProjectId(state);
  return {
    project: getProject(state, currentProjectId),
  };
};

export default connect(mapStateToProps, { fetchTags })(YoutubeFeed);
