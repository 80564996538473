import React from 'react';

import PropTypes from 'prop-types';

import DetachedContent from './DetachedContent.js';

const ReportApp = ({ children }) => (
  <div className="react-app-wrapper">
    <div className="page-container">
      <div className="page-content">
        <div className="content">
          <DetachedContent>{children}</DetachedContent>
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy; Footprints
        </div>
      </div>
    </div>
  </div>
);

ReportApp.propTypes = {
  children: PropTypes.node,
};

export default ReportApp;
