import { combineReducers } from 'redux';

import data from './data';
import deleteVideos from './deleteVideos';
import filters from './filters';
import pageSize from './pageSize';

const savedVideos = combineReducers({ data, filters, deleteVideos, pageSize });

export default savedVideos;
