import React from 'react';

import PropTypes from 'prop-types';

import { formatPercentage } from './helpers/formatters';
import StaticDataTable from './StaticDataTable';

const CATEGORIES_COLUMNS = [
  {
    dataField: 'category',
    text: 'Name',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'month',
    text: 'Attribution Date',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'score',
    text: 'Score',
    formatter: formatPercentage,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    align: 'right',
    headerAlign: 'right',
    sort: true,
  },
];

const CategoriesWebsiteTable = ({ categories }) => (
  <StaticDataTable columns={CATEGORIES_COLUMNS} rows={categories} title="Categories" />
);

CategoriesWebsiteTable.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      score: PropTypes.number,
      month: PropTypes.string,
      id: PropTypes.number,
      category: PropTypes.string,
    }),
  ).isRequired,
};

export default CategoriesWebsiteTable;
