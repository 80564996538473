import React from 'react';

import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

import { ScoreFormatter } from '../../../../../components/ICO/tables/ArticlesReportTable';
import { langFormatter, MUVFormatter } from '../../../../../components/ICO/tables/helpers/formatters';
import { extractDomain, extractFavicon } from '../../../../../config/url-utils';
import translate from '../../../../../helpers/translate';
import { getOrganizationPermissionsHasSimilarWeb } from '../../../../../selectors/permissions';

const ArticlesFeedModalsPreviewBody = ({ articleData, userHasSimilarWeb }) => {
  const date = dayjs(articleData['pub-date']).format('YYYY-MM-DD') ?? dayjs(articleData['date']).format('YYYY-MM-DD');

  return (
    <>
      <Row>
        <Col md={12} className="font-weight-bold text-left">
          <img style={{ marginRight: '10px' }} alt={''} src={extractFavicon(articleData.url)} />
          {articleData.source === 1 && <FontAwesomeIcon icon={faTwitter} className="mr-1" />}
          <a
            className="fs--1"
            target="_blank"
            rel="noopener noreferrer"
            id="articleLink"
            href={articleData.fullUrl || articleData.url}
          >
            <span className="fs--1">{extractDomain(articleData.url)}</span>
            <FontAwesomeIcon icon="link" className="ml-1" />
          </a>
          <UncontrolledTooltip placement="top" target="articleLink">
            {translate('modals.articles.view_original_article')}
          </UncontrolledTooltip>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <span className="fs--1 label label-striped border-left-grey text-gray">{date ?? 'Date not found'}</span>
        </Col>
      </Row>
      <Row>
        <Col md={10} className="d-flex flex-row">
          {userHasSimilarWeb ? articleData.muv && <div className="mr-1">{MUVFormatter(articleData.muv)}</div> : null}
          {articleData.reach && <div>{ScoreFormatter(articleData.reach)}</div>}
          {articleData.language && <div className="ml-1">{langFormatter(articleData.language)}</div>}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          {articleData.description ? (
            <div className="fp-modal-body-preview text-gray">{articleData.description}</div>
          ) : (
            <Col className="col-md-offset-4 margin-top-10">
              <div className="fp-modal-body-not-found alert alert-info col-md-12 text-center text-medium">
                No preview was found for this article.
                <br />
              </div>
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  userHasSimilarWeb: getOrganizationPermissionsHasSimilarWeb(state),
});

export default connect(mapStateToProps)(ArticlesFeedModalsPreviewBody);
