import {
  TOUR_SET_NEXT_STEP,
  TOUR_SET_IS_RUNNING,
  TOUR_SET_HAS_COMPLETED_PROJECTS,
  TOUR_CLEAR,
  TOUR_SET_HAS_SKIPPED,
} from './actionTypes';

export const tourSetTourIsRunning = (isRunning) => ({
  type: TOUR_SET_IS_RUNNING,
  payload: isRunning,
});

export const tourSetTourNextStep = (tourStep) => ({
  type: TOUR_SET_NEXT_STEP,
  payload: tourStep,
});

export const tourSetCompletedProjectsSteps = () => ({
  type: TOUR_SET_HAS_COMPLETED_PROJECTS,
});

export const tourSetHasSkipped = () => ({
  type: TOUR_SET_HAS_SKIPPED,
});

export const tourClear = () => ({
  type: TOUR_CLEAR,
});
