import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getArticlesStatsByWebsite, getArticlesReportStatsByWebsite } from '../../../selectors/savedCoverage/articles';
import SingleMetric from '../text-display/SingleMetric.js';

const WebsiteMetric = ({ reportId }) => {
  const count = useSelector((state) =>
    reportId
      ? getArticlesReportStatsByWebsite(state, reportId).total_entries
      : getArticlesStatsByWebsite(state).total_entries,
  );
  return <SingleMetric count={count} icon={'chart'} color="websites" title={'Total Websites'} />;
};

WebsiteMetric.propTypes = {
  reportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WebsiteMetric;
