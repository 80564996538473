import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import Button from 'reactstrap/es/Button';

import SignupInvite from './SignupInvite';
import SignupResult from './SignupResult';
import SignupSteps from './SignupSteps';
import SignUpUserProfile from './SignUpUserProfile';
import WizardModal from '../../components/auth/wizard/WizardModal';
import ButtonIcon from '../../components/common/ButtonIcon';
import RegisterPictureForm from '../../components/ICO/wizard/RegisterPictureForm';
import AppContext, { AuthWizardContext } from '../../context/Context';
import translate from '../../helpers/translate';

const SignupUserForm = ({ invite }) => {
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const { register, handleSubmit, errors, watch } = useForm();

  const onSubmitData = (data) => {
    setUser({ ...user, ...data });
    setStep(step + 1);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = (targetStep) => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard fp-signup">
        <CardHeader>
          <SignupSteps step={step} handleBackStep={handleBackStep} />
        </CardHeader>
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3 min-vh-25 fp-signup__card-body">
          {step === 1 && <SignupInvite invite={invite} />}
          {step === 2 && <SignUpUserProfile register={register} errors={errors} watch={watch} />}
          {step === 3 && <RegisterPictureForm register={register} errors={errors} />}
          {step === 4 && <SignupResult invite={invite} />}
        </CardBody>
        <CardFooter className={classNames('px-md-6', { 'd-none': step === 4, ' d-flex': step < 4 })}>
          <ButtonIcon
            color="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 font-weight-semi-bold', { 'd-none': step === 1 })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            {translate('signup.common.prev')}
          </ButtonIcon>
          {step !== 1 && (
            <Button color="primary" className="ml-auto px-5" type="submit">
              {translate('signup.common.next')}
            </Button>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

SignupUserForm.propTypes = {
  invite: PropTypes.shape({
    status: PropTypes.string,
    organization: PropTypes.string,
    key: PropTypes.string,
    expires: PropTypes.string,
  }),
};

export default SignupUserForm;
