import React from 'react';

import SavedVideosFiltersDates from '../../Table/Filters/SavedVideosFiltersDates.jsx';
import SavedVideosFiltersLanguages from '../../Table/Filters/SavedVideosFiltersLanguages.jsx';
import SavedVideosFiltersTags from '../../Table/Filters/SavedVideosFiltersTags.jsx';

const SavedVideosFiltersModalBody = () => (
  <>
    <SavedVideosFiltersLanguages />
    <SavedVideosFiltersTags />
    <SavedVideosFiltersDates />
  </>
);

export default SavedVideosFiltersModalBody;
