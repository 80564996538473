import React from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { InputSelect } from '../../../components/ICO/forms/InputSelect';
import translate from '../../../helpers/translate';
import { getNotLockedTags } from '../../../selectors/tags';

const SuperSearchTableActionTagModal = ({
  setSelectedTag,
  isOpen,
  onClose,
  onClickSave,
  selectedTag,
  tags,
  isLoading,
}) => (
  <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
    <ModalHeader className="fp-modal-header--small">Save article</ModalHeader>
    <ModalBody className="fp-modal-body">
      <p>Please, select the tag where you want to save the article. </p>
      <div className="mt-2">
        <InputSelect
          name="tag-select"
          onChange={(tag) => setSelectedTag(tag)}
          value={selectedTag ? { value: selectedTag.value, label: selectedTag.label } : null}
          items={tags}
          placeholder="Select a tag"
          disabled={false}
        />
      </div>
    </ModalBody>
    <ModalFooter>
      <Button size="sm" onClick={onClose} className="fp-button-close">
        {translate('common.close')}
      </Button>
      <Button
        size="sm"
        type="submit"
        onClick={onClickSave}
        color="primary"
        name="saveButton"
        disabled={!selectedTag || isLoading}
      >
        {isLoading ? translate('common.loading') : translate('common.save')}
      </Button>
    </ModalFooter>
  </Modal>
);

const mapStateToProps = (state) => ({
  tags: getNotLockedTags(state),
});

export default connect(mapStateToProps, null)(SuperSearchTableActionTagModal);
