import React from 'react';

import { Col, Row } from 'reactstrap';

import { dateFormatter } from '../../../../components/ICO/tables/helpers/formatters';
import { largeNumberFormat } from '../../../../config/url-utils';
import translate from '../../../../helpers/translate';

// eslint-disable-next-line no-undef
const parentSrc = process.env.NODE_ENV === 'production' ? 'app.footprints.gg' : 'localhost';

const TwitchFeedTableVideoPreviewModalBody = ({ videoData }) => (
  <>
    <Row>
      <Col>
        <iframe
          width="100%"
          height="450"
          src={`https://player.twitch.tv/?video=${videoData?.twitch_video_id}&parent=${parentSrc}`}
          allowFullScreen
          title="Embedded Twitch"
          className="border-0"
        />
      </Col>
    </Row>
    <Row>
      <Col className="mt-2">
        <h5 className="text-bold">{videoData?.title}</h5>
      </Col>
    </Row>
    <Row>
      <Col className="text-gray">
        <span>{dateFormatter(videoData?.published_at)}</span>
      </Col>
    </Row>
    <Row>
      <Col className="mt-3">
        {videoData?.broadcaster_name ? (
          <div className="d-flex flex-row">
            <div className="mr-2">
              <a href={`https://www.twitch.tv/${videoData?.broadcaster_name}`}>{videoData?.broadcaster_name}</a>
            </div>
            {videoData?.followers > 0 ? (
              <span className="text-gray">{largeNumberFormat(videoData?.followers)} followers</span>
            ) : null}
          </div>
        ) : (
          translate('pages.saved_streams.modals.preview.information_not_found')
        )}
      </Col>
    </Row>
  </>
);

export default TwitchFeedTableVideoPreviewModalBody;
