export const getSavedVideos = (state) => state.savedVideos.data.response.videos;
export const getSavedVideosIsLoading = (state) => state.savedVideos.data.isLoading;
export const getSavedVideosError = (state) => state.savedVideos.data.error?.length || !state.savedVideos.data.response;
export const getSavedVideosPagination = (state) => {
  if (state.savedVideos.data.response?.pagination) {
    return {
      totalItems: state.savedVideos.data.response.pagination.total_entries,
      lastPage: state.savedVideos.data.response.pagination.last_page,
      page: state.savedVideos.data.response.pagination.page_number,
      pageSize: state.savedVideos.data.response.pagination.page_size,
    };
  }

  return [];
};
export const getSavedVideosFiltersStartDate = (state) => state.savedVideos.filters.startDate;
export const getSavedVideosFiltersEndDate = (state) => state.savedVideos.filters.endDate;
export const getSavedVideosFiltersTags = (state) => state.savedVideos.filters.tags;
export const getSavedVideosFiltersLanguages = (state) => state.savedVideos.filters.languages;
export const getSavedVideosFilters = (state) => ({
  languages: getSavedVideosFiltersLanguages(state),
  startDate: getSavedVideosFiltersStartDate(state),
  endDate: getSavedVideosFiltersEndDate(state),
  tags: getSavedVideosFiltersTags(state),
});

export const getSavedVideosDeleteActionResponse = (state) => state.savedVideos.deleteVideos.response;
export const getSavedVideosDeleteActionError = (state) => state.savedVideos.deleteVideos.error;
export const getSavedVideosPageSize = (state) => state.savedVideos.pageSize ?? 10;
