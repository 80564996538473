import React from 'react';

import { values } from 'lodash';
import { connect } from 'react-redux';

import ProjectActions from '../../components/ICO/actions/ProjectActions';
import { projectsTableColumns } from '../../components/ICO/tables/helpers/columns';
import { tableRows } from '../../components/ICO/tables/helpers/selectors';
import StaticDataTable from '../../components/ICO/tables/StaticDataTable';
import {
  getOrganizationPermissionsHasProjects,
  getOrganizationPermissionsMaxProjects,
} from '../../selectors/permissions';
import { getProjectsListStatusIsLoading } from '../../selectors/projects';

const mapStateToProps = (state, ownProps) => {
  const listStatusLoading = getProjectsListStatusIsLoading(state);
  const activeProjectsLength = tableRows(state, { locked: false })[ownProps.tableId]().length;
  const userMaxProjects = getOrganizationPermissionsMaxProjects(state);
  const userHasProjects = getOrganizationPermissionsHasProjects(state);
  let isNewButtonDisabled = !userHasProjects;
  if (userHasProjects && userMaxProjects) {
    isNewButtonDisabled = activeProjectsLength >= userMaxProjects;
  }

  const projectsTableFormatter = (rows) =>
    values(rows).map((project) => ({
      id: project.id,
      clientName: project.client.name,
      image: project.image || project.avatar,
      name: project.name || 'No Name #' + project.id,
      actions: <ProjectActions id={project.id} project={project} />,
      date: project['inserted-at'],
    }));

  const rowsData = tableRows(state, { locked: false })[ownProps.tableId]();

  return {
    state,
    rowsSelectable: false,
    viewAllBtn: false,
    columns: projectsTableColumns,
    rows: projectsTableFormatter(tableRows(state, { locked: false })[ownProps.tableId]()),
    rowsArchived: projectsTableFormatter(tableRows(state, { locked: true })[ownProps.tableId]()),
    ownProps: ownProps,
    isTableLoading: listStatusLoading && !rowsData.length,
    isNewButtonDisabled,
  };
};

const mapDispatchToProps = () => ({
  onLoad: () => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(StaticDataTable);
