import { useMutation } from 'react-query';

import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';

const useSaveVideos = (onSuccess) =>
  useMutation({
    mutationKey: 'save_videos',
    mutationFn: async (videos) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/youtube_feed/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(videos),
      });

      if (!response.ok) {
        const { errors } = await response.json();
        throw new Error(errors.detail);
      } else {
        return await response.text();
      }
    },
    onSuccess: async (data) => {
      getToastedNotification({ customMessage: { type: 'success', message: data } });
      onSuccess();
    },
    onError: async (error) => {
      const errorMessage = `${error.message}.`;
      getToastedNotification({ customMessage: { type: 'error', message: errorMessage } });
    },
  });

export default useSaveVideos;
