import React, { useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';

import SignUpFreeUser from './SignUpFreeUser/SignUpFreeUser';
import SignupUserForm from './SignupUserForm';
import { STATUS_ERROR, STATUS_LOADED } from '../../actions/forms';
import { fetchInvite } from '../../actions/users';
import AuthWizardProvider from '../../components/auth/wizard/AuthWizardProvider';
import CirclesBackground from '../../components/ICO/CirclesBackground/CirclesBackground';
import AlphaFooter from '../../components/ICO/Footers/AlphaFooter';
import FootprintsLogo from '../../components/ICO/Logo/FootprintsLogo';

import '../../assets/scss/theme/Footprints/screens/_signup.scss';

const Signup = ({ onLoad, currentInvite }) => {
  const { inviteId } = useParams();
  const hasLoaded = useRef(false); //To prevent useEffect to loop

  useEffect(() => {
    if (!hasLoaded.current) {
      if (inviteId) {
        onLoad(inviteId);
      }
      hasLoaded.current = true;
    }
  }, [onLoad, inviteId]);

  if (inviteId) {
    if (currentInvite.status === STATUS_LOADED) {
      return (
        <CirclesBackground>
          <Row className="min-vh-100 d-flex flex-column flex-between-center">
            <Col sm={10} lg={7}>
              <Row className="min-vh-25 flex-center mt-2">
                <FootprintsLogo />
              </Row>
              <Row className="min-vh-50 flex-center">
                <AuthWizardProvider>
                  <SignupUserForm invite={currentInvite} />
                </AuthWizardProvider>
              </Row>
              <AlphaFooter />
            </Col>
          </Row>
        </CirclesBackground>
      );
    }
    if (currentInvite.status === STATUS_ERROR) {
      return (
        <CirclesBackground>
          <Row className="flex-center min-vh-100 py-6">
            <Col sm={10} lg={7} className="col-xxl-5">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Error</CardTitle>
                  <CardText>Invitation link is not valid or expired.</CardText>
                  <p className="text-muted">
                    <small className="text-muted"> ICO Team</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CirclesBackground>
      );
    }
    return <></>;
  } else {
    return (
      <CirclesBackground>
        <Row className="min-vh-100 d-flex flex-column flex-between-center">
          <Col sm={10} lg={7} md={10}>
            <Row className="min-vh-25 flex-center mt-2">
              <FootprintsLogo />
            </Row>
            <Row className="min-vh-50 flex-center">
              <AuthWizardProvider>
                <SignUpFreeUser />
              </AuthWizardProvider>
            </Row>
            <AlphaFooter />
          </Col>
        </Row>
      </CirclesBackground>
    );
  }
};

const mapStateToProps = (state) => ({
  currentInvite: state.users.invite,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (inviteId) => {
    dispatch(fetchInvite(inviteId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
