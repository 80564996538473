import React from 'react';

import ArticlesFeedTableActionsColumn from './ArticlesFeedTableActionsColumn.jsx';
import ArticlesFeedTablePreview from './ArticlesFeedTablePreview.jsx';
import {
  favIconFormatter,
  langFormatter,
  mediaFormatter,
  metaCriticFormatter,
  ScoreFormatter,
} from '../../../../components/ICO/tables/ArticlesFeedTable';
import { dateFormatter, MUVFormatter } from '../../../../components/ICO/tables/helpers/formatters';
import { extractFavicon, removeProtocolFromUrl } from '../../../../config/url-utils';
import translate from '../../../../helpers/translate';

const columns = (userHasSimilarWeb) => [
  {
    dataField: 'media',
    formatter: (row, rowData) => favIconFormatter(extractFavicon(removeProtocolFromUrl(rowData.url))),
    text: '',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
  },
  {
    dataField: 'url',
    formatter: mediaFormatter,
    text: 'Media',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'title',
    text: 'Title',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b);
    },
    formatter: (data, row) => <ArticlesFeedTablePreview row={row} articleData={data} />,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'muv',
    text: translate('common.UMV'),
    formatter: MUVFormatter,
    classes: userHasSimilarWeb ? 'border-0 align-middle fs-0 text-light' : 'd-none',
    headerClasses: userHasSimilarWeb ? 'border-0' : 'd-none',
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'reach',
    text: translate('common.media_score'),
    formatter: ScoreFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'is_metacritic',
    text: 'Metacritic',
    formatter: metaCriticFormatter,
    sort: true,
    classes: 'border-0 align-middle fs-0 text-light',
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'language',
    text: translate('common.language'),
    formatter: langFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'pub_date',
    text: translate('common.date'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    text: translate('common.actions'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
    formatter: (row, rowData) => <ArticlesFeedTableActionsColumn articleData={rowData} />,
  },
];

export default columns;
