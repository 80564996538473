import { connect } from 'react-redux';

import EditActions from './EditActions';
import { changeTagForm, showModal } from '../../../actions/forms';
import { editTag } from '../../../actions/tags';
import { getTagByKey } from '../../../selectors/tags';
import { toastifyResult } from '../modals/ModalRoot';

const mapStateToProps = (state, ownProps) => {
  const currentTag = getTagByKey(state, ownProps.id);
  return {
    id: ownProps.id,
    data: currentTag,
    type: 'tag',
    canLock: true,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export const onTagEdit = (dispatch, data) => {
  dispatch(showModal({ content: 'editTag' }));
  dispatch(changeTagForm({ field: 'id', value: data.id }));
  dispatch(changeTagForm({ field: 'projectId', value: data.project_id }));
  dispatch(changeTagForm({ field: 'name', value: data.name }));
  dispatch(changeTagForm({ field: 'locked', value: data.locked }));
  dispatch(changeTagForm({ field: 'has-highlight', value: data['has-highlight'] }));
  dispatch(changeTagForm({ field: 'highlight-date', value: data['highlight-date'] }));
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onLock: () => {
    const { dispatch } = dispatchProps;
    dispatch(
      editTag({
        id: ownProps.id,
        locked: true,
      }),
    ).then((res) => {
      toastifyResult(res, 'Tag has been archived.');
    });
  },
  onUnlock: () => {
    const { dispatch } = dispatchProps;
    dispatch(
      editTag({
        id: ownProps.id,
        locked: false,
      }),
    ).then((res) => {
      toastifyResult(res, 'Tag has been re-activated.');
    });
  },
  onEdit: () => {
    const { dispatch } = dispatchProps;
    const { data } = stateProps;
    onTagEdit(dispatch, data);
  },
});

const TagActions = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditActions);

export default TagActions;
