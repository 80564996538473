import React from 'react';

import { connect } from 'react-redux';
import { Button, CustomInput, InputGroup } from 'reactstrap';

import { savedStreamsFetch } from '../../../actions/savedStreams';
import translate from '../../../helpers/translate';
import { getCurrentProjectId } from '../../../selectors/forms';
import { getSavedStreamsFilters, getSavedStreamsPageSize } from '../../../selectors/savedCoverage/savedStreams';
import { useDeleteStreams } from '../hooks/useDeleteStreams';

const SavedStreamsTableBulkActions = ({
  selectedRows,
  setSelectedRows,
  sizePerPage,
  savedStreamsFetch,
  filters,
  projectId,
}) => {
  const onDeleteSuccess = () => {
    savedStreamsFetch({
      project_id: projectId,
      pagination: { sizePerPage, page: 1, sortField: 'published_at' },
      filters,
    });
    setSelectedRows([]);
  };

  const { mutate } = useDeleteStreams(onDeleteSuccess);

  const handleClickDelete = () => {
    mutate(selectedRows);
    setSelectedRows([]);
  };

  return (
    <InputGroup size="md" className="input-group flex-center justify-content-around">
      <span className="mr-3">
        {selectedRows.length} {translate('pages.saved_streams.table.actions.rows_selected')}
      </span>
      <CustomInput type="select" id="bulk-select" className="mr-2 text-uppercase">
        <option value="delete">{translate('pages.saved_streams.table.actions.delete_videos')}</option>
      </CustomInput>
      <Button color="primary" onClick={handleClickDelete}>
        {translate('common.apply')}
      </Button>
    </InputGroup>
  );
};

const mapStateToProps = (state) => ({
  filters: getSavedStreamsFilters(state),
  projectId: getCurrentProjectId(state),
  sizePerPage: getSavedStreamsPageSize(state),
});

export default connect(mapStateToProps, { savedStreamsFetch })(SavedStreamsTableBulkActions);
