import { changePage } from './modules';
import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const REQUEST_RSS_ARTICLES = 'REQUEST_RSS_ARTICLES';
export const REJECT_RSS_ARTICLES = 'REJECT_RSS_ARTICLES';
export const RECEIVE_RSS_ARTICLES = 'RECEIVE_RSS_ARTICLES';
export const REQUEST_SAVE_RSS_ARTICLE = 'REQUEST_SAVE_RSS_ARTICLE';
export const RECEIVE_SAVE_RSS_ARTICLE = 'RECEIVE_SAVE_RSS_ARTICLE';
export const REJECT_SAVE_RSS_ARTICLE = 'REJECT_SAVE_RSS_ARTICLE';
export const REQUEST_SAVE_FEED = 'REQUEST_SAVE_FEED';
export const RECEIVE_SAVE_FEED = 'RECEIVE_SAVE_FEED';
export const REJECT_SAVE_FEED = 'REJECT_SAVE_FEED';
export const REQUEST_RSS_ARTICLE_GROUPS = 'REQUEST_RSS_ARTICLE_GROUPS';
export const RECEIVE_RSS_ARTICLE_GROUPS = 'RECEIVE_RSS_ARTICLE_GROUPS';
export const REJECT_RSS_ARTICLE_GROUPS = 'REJECT_RSS_ARTICLE_GROUPS';
export const RECEIVE_DELETE_RSS_ARTICLE = 'RECEIVE_DELETE_RSS_ARTICLE';

export const fetchRssArticlesAlert = (
  alertId,
  params = {
    page: {
      page: 0,
      page_size: 35,
    },
  },
) =>
  apiFetch(
    'external_articles_alert/' + alertId,
    {
      request: REQUEST_RSS_ARTICLES,
      receive: RECEIVE_RSS_ARTICLES,
      reject: REJECT_RSS_ARTICLES,
    },
    params,
  );

export const fetchRssArticlesGroups = (
  alertId,
  params = {
    page: {
      page: 0,
      page_size: 100,
    },
  },
) =>
  apiFetch(
    'external_articles_alert/' + alertId,
    {
      request: {
        type: REQUEST_RSS_ARTICLE_GROUPS,
        payload: {},
      },
      receive: RECEIVE_RSS_ARTICLE_GROUPS,
      reject: REJECT_RSS_ARTICLE_GROUPS,
    },
    params,
  );

export const feedPageClick = (alertId, newPage, dispatch, pagination) => {
  dispatch(changePage('articlesFeedTable', newPage));
  let fetchParams = {
    page: { page: newPage, page_size: pagination.page.page_size },
  };
  dispatch(fetchRssArticlesAlert(alertId, fetchParams));
};

export const editRssArticleAlert = (
  data = {
    isNew: 0,
    articleId: 0,
    alertId: 0,
  },
) => {
  let bodyData = {
    type: 'article',
    attributes: {
      id: data.articleId,
      is_new: data.isNew,
    },
  };
  bodyData.relationships = {
    alert: {
      data: {
        type: 'alert',
        id: data.alertId,
      },
    },
  };
  return apiFetch(
    `external_articles/${data.articleId}`,
    {
      request: {
        type: REQUEST_SAVE_RSS_ARTICLE,
        payload: data,
      },
      receive: RECEIVE_SAVE_RSS_ARTICLE,
      reject: REJECT_SAVE_RSS_ARTICLE,
    },
    {
      body: JSON.stringify({
        data: bodyData,
      }),
    },
    {
      method: 'PATCH',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
};

export const editFeed = (
  data = {
    feed: '',
    disabled: true,
  },
) =>
  apiFetch(
    `feeds/${data.id}`,
    {
      request: {
        type: REQUEST_SAVE_FEED,
        payload: data,
      },
      receive: RECEIVE_SAVE_FEED,
      reject: REJECT_SAVE_FEED,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'feed',
          attributes: {
            feed: data.feed,
            disabled: data.disabled,
          },
        },
      }),
    },
    {
      method: 'PATCH',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const saveFeed = (
  data = {
    feed: 'https://defaultfeed.front',
    websiteId: -1,
  },
) =>
  apiFetch(
    'feeds',
    {
      request: {
        type: REQUEST_SAVE_FEED,
        payload: data,
      },
      receive: RECEIVE_SAVE_FEED,
      reject: REJECT_SAVE_FEED,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'feed',
          attributes: {
            feed: data.feed,
            website_id: data.websiteId,
            reach: data.reach,
            muv: data.muv,
            language: data.language,
            region: data.region,
            type: 0,
            last_count: 0,
            disabled: false,
            followers: 0,
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
