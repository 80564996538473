import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';

import { superSearchSetToDate } from '../../../actions/superSearch';
import translate from '../../../helpers/translate';
import { getOrganizationPermissionsMaxData } from '../../../selectors/permissions';

const SuperSearchFiltersDateTo = ({ superSearchSetToDate, userMaxData }) => {
  const initialToDate = dayjs().format();

  const minDate = dayjs().subtract(userMaxData, 'month').format();

  useEffect(() => {
    superSearchSetToDate(initialToDate);
  }, [initialToDate, superSearchSetToDate]);

  const initialSettings = {
    locale: { format: 'YYYY-MM-DD HH:mm' },
    showDropdowns: true,
    minDate: userMaxData ? minDate : undefined,
    maxDate: initialToDate,
    singleDatePicker: true,
    startDate: initialToDate,
    timePicker: true,
  };

  const handleApplyDates = (event) => {
    superSearchSetToDate(dayjs(event.target.value).format());
  };

  return (
    <>
      <Label>{translate('pages.supersearch.filters.date_to')}</Label>
      <DateRangePicker onApply={handleApplyDates} initialSettings={initialSettings} timePicker={true}>
        <input type="text" className="form-control" />
      </DateRangePicker>
    </>
  );
};

const mapStateToProps = (state) => ({
  userMaxData: getOrganizationPermissionsMaxData(state),
});

SuperSearchFiltersDateTo.propTypes = {
  superSearchSetToDate: PropTypes.func,
};

export default connect(mapStateToProps, {
  superSearchSetToDate,
})(SuperSearchFiltersDateTo);
