import React, { useState } from 'react';

import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SuperSearchTableActionTagModal from './SuperSearchTableActionTagModal.jsx';
import { useSaveSupersearchArticle } from '../hooks/useSupersearch';

const SuperSearchTableActions = ({ rowData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState();
  const onSuccess = () => {
    setIsModalOpen(false);
  };

  const { mutate, isLoading } = useSaveSupersearchArticle(onSuccess);

  const handleClickSave = () => {
    mutate({ article: rowData, tag_id: selectedTag.value });
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faSave}
        onClick={() => setIsModalOpen(true)}
        className="text-primary cursor-pointer"
        title="Save article"
        aria-label="save-article"
      />
      <SuperSearchTableActionTagModal
        setSelectedTag={setSelectedTag}
        selectedTag={selectedTag}
        onClickSave={handleClickSave}
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        isLoading={isLoading}
      />
    </>
  );
};

export default SuperSearchTableActions;
