import React from 'react';

import PropTypes from 'prop-types';

import Module from './Module.js';
import ModuleLine from './ModuleLine.js';
import Loading from '../Loading/Loading';

const ModulesWrapper = ({ loaderStyle, children, style, id }) => (
  <div>
    <div className={loaderStyle}>
      <ModuleLine>
        <Module md={12} xs={12}>
          <div className="d-flex flex-center">
            <Loading />
          </div>
        </Module>
      </ModuleLine>
    </div>
    <div className={style} key={id}>
      {children}
    </div>
  </div>
);

ModulesWrapper.propTypes = {
  id: PropTypes.number,
  loaderStyle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  style: PropTypes.string,
};

export default ModulesWrapper;
