import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Card, CardBody, CardDeck, Col, Row } from 'reactstrap';

import NewReportDescriptionEditor from './NewReportDescriptionEditor';
import NewReportForm from './NewReportForm';
import { NewReportTableSelectorContainer } from './NewReportTableSelector';
import ReportTitleEditor from './ReportTitleEditor';
import { clearHighlightedArticles } from '../../actions/reports';
import ArticleMetric from '../../components/ICO/articles/ArticleMetric';
import ScoreMetric from '../../components/ICO/articles/ScoreMetric';
import StreamMetric from '../../components/ICO/articles/StreamMetric.jsx';
import VideoMetric from '../../components/ICO/articles/VideoMetric.jsx';
import WebsiteMetric from '../../components/ICO/articles/WebsiteMetric';
import ArticlesChart from '../../components/ICO/charts/ArticlesChart';
import ArticlesLangChart from '../../components/ICO/charts/ArticlesLangChart';
import ArticlesTagChart from '../../components/ICO/charts/ArticlesTagChart';
import ModuleGrid from '../../components/ICO/layouts/ModuleGrid';
import ReportWrapper from '../../components/ICO/reports/ReportWrapper';
import NewReportProvider from '../../components/ICO/wizard/NewReportProvider';
import '../../assets/scss/theme/Footprints/screens/_new-report.scss';
import {
  getOrganizationPermissionsHasTwitchFeed,
  getOrganizationPermissionsHasYoutubeFeed,
} from '../../selectors/permissions';
import { getSavedStreamsPagination } from '../../selectors/savedCoverage/savedStreams';
import { getSavedVideosPagination } from '../../selectors/savedCoverage/savedVideos';

const NewReport = ({
  clearHighlightedArticles,
  userHasYoutubeFeed,
  savedVideosPagination,
  userHasTwitchFeed,
  savedStreamsPagination,
  ...props
}) => {
  useEffect(
    () => () => {
      clearHighlightedArticles();
    },
    [clearHighlightedArticles],
  );

  return (
    <Row className="fp-new-report mt-3">
      <Col md={12} sm={12}>
        <ModuleGrid id="last-report-main-dashboard">
          <NewReportProvider>
            <NewReportForm {...props} />
          </NewReportProvider>
          <ReportWrapper>
            <hr />
            <Card className="mb-3">
              <CardBody>
                <ReportTitleEditor
                  placeholder=" What name to give this beautiful report?"
                  titleField="titleHTML"
                  wrapperClassName="fs-5 mb-4"
                  editorClassName="text-sans-serif text-center font-weight-extra-bold  report-title-editor"
                />
              </CardBody>
            </Card>
            <CardDeck>
              <ArticleMetric />
              {userHasYoutubeFeed && savedVideosPagination.totalItems > 0 ? (
                <VideoMetric savedVideos={savedVideosPagination.totalItems} />
              ) : null}
              {userHasTwitchFeed && savedStreamsPagination.totalItems > 0 ? (
                <StreamMetric savedStreams={savedStreamsPagination.totalItems} />
              ) : null}
              <WebsiteMetric />
              <ScoreMetric />
            </CardDeck>
            <Card className="mb-3">
              <CardBody>
                <NewReportDescriptionEditor />
              </CardBody>
            </Card>
            <Card className="mb-3">
              <CardBody>
                <ArticlesChart />
              </CardBody>
            </Card>
            <CardDeck>
              <Card className="mb-3">
                <CardBody>
                  <ArticlesLangChart />
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardBody>
                  <ArticlesTagChart />
                </CardBody>
              </Card>
            </CardDeck>
            <NewReportTableSelectorContainer />
          </ReportWrapper>
        </ModuleGrid>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  userHasYoutubeFeed: getOrganizationPermissionsHasYoutubeFeed(state),
  savedVideosPagination: getSavedVideosPagination(state),
  userHasTwitchFeed: getOrganizationPermissionsHasTwitchFeed(state),
  savedStreamsPagination: getSavedStreamsPagination(state),
});

export default connect(mapStateToProps, { clearHighlightedArticles })(NewReport);
