import _ from 'lodash';
import { createSelector } from 'reselect';

import { buildFiltersQuery } from '../config/api-service';

export const getGlobalFormFields = (state) => state.forms.globalForm.fields;

export const getForms = (state) => state.forms;

export const getTableFiltersForm = (state) => state.forms.articlesTable;
export const getTableFiltersFormFields = (state) => state.forms.articlesTable.fields;

export const getFeedTableFiltersForm = (state) => state.forms.articlesFeedTable;

export const getArticlesWebsiteForm = (state) => state.forms.articlesWebsiteTableFiltersForm;

export const getWebsitesTableFiltersForm = (state) => state.forms.websitesTable;

export const getCurrentProjectId = createSelector(
  getGlobalFormFields,
  (globalFormFields) => globalFormFields?.currentProject,
);

export const getTableFiltersFormFieldsById = (tableId) =>
  createSelector([getCurrentProjectId, getForms], (currentProjectId, forms) => {
    if (tableId === 'articlesTable') {
      return {
        project_id: currentProjectId || 0,
        ...forms[tableId].fields,
      };
    }
    return forms[tableId].fields;
  });

export const getTableFiltersStatusFormFieldsById = (state, tableId) => state.forms[tableId].fieldsStatus;
export const getTableFormFieldsById = (state, tableId) => state.forms[tableId].nonFilterFields;

export const getNewReportForm = (state) => state.forms.newReportForm;
export const getNewReportFormFields = (state) => state.forms.newReportForm.fields;

export const getNewReportStep0FormFilters = (newReportForm) => ({
  project_id: newReportForm.project,
  date_range: [newReportForm.from, newReportForm.to],
});

export const getNewReportStep0Params = (
  newReportForm,
  pagination = {
    page: 1,
    page_size: 5000,
  },
) => {
  const filter = getNewReportStep0FormFilters(newReportForm);
  return {
    page: pagination,
    filter,
  };
};

export const getNewReportFormFilters = (newReportForm) => ({
  tags_not: _.values(newReportForm.tags).map((t) => t.value),
  languages_not: _.values(newReportForm.languages).map((l) => l.value),
  project_id: newReportForm.project,
  languages: [], //newReportForm.languages.map(lang => lang.value),
  //For now, languages filter is just by NOT (excluding) values
  date_range: [newReportForm.from, newReportForm.to],
});

export const getNewReportParams = (
  newReportForm,
  pagination = {
    page: 1,
    page_size: 5000,
  },
) => {
  const filter = getNewReportFormFilters(newReportForm);
  return {
    page: pagination,
    filter,
  };
};

export const getReportParams = ({
  reportId,
  filters,
  pagination = {
    page: 1,
    page_size: 25,
  },
}) => {
  if (filters && filters.project_id) delete filters.project_id;
  const filter = {
    report_id: reportId,
    ...buildFiltersQuery(filters),
  };
  let result = {
    page: pagination,
    filter,
  };
  if (filters.sort) {
    result.sort = filters.sort;
  }
  return result;
};

export const getWebsiteParams = (
  { websiteId, filters },
  pagination = {
    page: 1,
    page_size: 25,
  },
) => {
  const filter = {
    website_id: websiteId,
    ...buildFiltersQuery(filters),
  };
  let result = {
    page: pagination,
    filter,
  };
  if (filters.sort) {
    result.sort = filters.sort;
  }
  return result;
};

export const getNewReportParamsGroupBy = (newReportForm, groupBy) => {
  const filter = getNewReportFormFilters(newReportForm);
  return Object.assign(
    {
      page: {
        page: 1,
        page_size: 550,
      },
      filter,
    },
    {
      group_by: groupBy,
    },
  );
};

export const getReportParamsGroupBy = (reportId, groupBy) => {
  const filter = {
    report_id: reportId,
  };
  return Object.assign(
    {
      page: {
        page: 1,
        page_size: 550,
      },
      filter,
    },
    {
      group_by: groupBy,
    },
  );
};

export const getNewReportTitle = (state) => state.forms?.newReportForm?.fields?.titleHTML ?? '';

export const getNewReportParamsByLang = (newReportForm) => getNewReportParamsGroupBy(newReportForm, 'language');

export const getNewReportParamsByTag = (newReportForm) => getNewReportParamsGroupBy(newReportForm, 'tag');

export const getNewReportParamsByWebsite = (newReportForm) => getNewReportParamsGroupBy(newReportForm, 'website');

export const getNewReportParamsByDate = (newReportForm) => getNewReportParamsGroupBy(newReportForm, 'date');

export const getReportParamsByLang = (reportId) => getReportParamsGroupBy(reportId, 'language');

export const getReportParamsByTag = (reportId) => getReportParamsGroupBy(reportId, 'tag');

export const getReportParamsByWebsite = (reportId) => getReportParamsGroupBy(reportId, 'website');

export const getReportParamsByDate = (reportId) => getReportParamsGroupBy(reportId, 'date');

export const getFormsUserForm = (state) => state.forms.userForm;

export const getFormNewReportFormFieldTags = (state) => state.forms.newReportForm.fields.tags;
export const getFormNewReportFormFieldLanguages = (state) => state.forms.newReportForm.fields.languages;

export const getNewProjectForm = (state) => (state.forms.newProjectForm ? state.forms.newProjectForm.fields : {});

export const getFormsNewReportFormFieldsFrom = (state) => state.forms.newReportForm.fields.from;
export const getFormsNewReportFormFieldsTo = (state) => state.forms.newReportForm.fields.to;
