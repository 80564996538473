import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import TwitchFeedModalsSaveStreamsBody from './TwitchFeedModalsSaveStreamsBody.jsx';
import { fetchTwitchFeed } from '../../../../actions/twitchFeed';
import translate from '../../../../helpers/translate';
import {
  getTwitchFeed,
  getTwitchFeedPagination,
  getTwitchFeedTableFilters,
} from '../../../../selectors/feeds/twitchFeed';
import { getCurrentProject } from '../../../../selectors/projects';
import { getNotLockedTags } from '../../../../selectors/tags';
import useSaveStream from '../../hooks/useSaveStream';

const TwitchFeedModalsSaveStreams = ({
  isOpen,
  onClose,
  filters,
  project,
  tags,
  pagination,
  fetchTwitchFeed,
  selectedRows,
  data,
}) => {
  const [selectedTag, setSelectedTag] = useState(null);

  const twitchSearchId = project['twitch-search-id'];
  const onSuccess = () => {
    fetchTwitchFeed({
      twitch_search_id: twitchSearchId,
      pagination,
      filters,
    });

    onClose();
  };

  const mutation = useSaveStream(onSuccess);

  const onClickSave = () => {
    let streams = selectedRows.map((videoData) => {
      if (videoData.video) {
        return {
          id: videoData.video.id,
          twitch_video_id: videoData.video?.twitch_video_id,
          twitch_stream_id: videoData.video?.twitch_stream_id,
          title: videoData.video?.title,
          description: videoData.video?.description,
          published_at: videoData.video?.published_at,
          url: videoData.video?.url,
          thumbnail_url: videoData.video?.thumbnail_url,
          view_count: videoData.video?.view_count,
          language: videoData.video?.language,
          duration: videoData.video?.duration,
          user_name: videoData.video?.channel?.broadcaster_name,
          followers: videoData.video?.channel?.followers,
          broadcaster_id: videoData.video?.channel?.broadcaster_id,
          broadcaster_name: videoData.video?.channel?.broadcaster_name,
          broadcaster_language: videoData.video?.channel?.broadcaster_language,
        };
      } else {
        const row = data.find((dataItem) => dataItem.id === videoData);
        return {
          id: row.video.id,
          twitch_video_id: row.video?.twitch_video_id,
          twitch_stream_id: row.video?.twitch_stream_id,
          title: row.video?.title,
          description: row.video?.description,
          published_at: row.video?.published_at,
          url: row.video?.url,
          thumbnail_url: row.video?.thumbnail_url,
          view_count: row.video?.view_count,
          language: row.video?.language,
          duration: row.video?.duration,
          user_name: row.video?.channel?.broadcaster_name,
          followers: row.video?.channel?.followers,
          broadcaster_id: row.video?.channel?.broadcaster_id,
          broadcaster_name: row.video?.channel?.broadcaster_name,
          broadcaster_language: row.video?.channel?.broadcaster_language,
        };
      }
    });

    mutation.mutate({ streams, tag_id: selectedTag.value, keyword_id: twitchSearchId.toString() });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
      <ModalHeader className="fp-modal-header--small">Save {selectedRows.length} stream(s)</ModalHeader>
      <ModalBody className="fp-modal-body">
        <TwitchFeedModalsSaveStreamsBody setSelectedTag={setSelectedTag} selectedTag={selectedTag} tags={tags} />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button size="sm" type="submit" onClick={onClickSave} color="primary" name="saveButton" disabled={!selectedTag}>
          {translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  filters: getTwitchFeedTableFilters(state),
  project: getCurrentProject(state),
  tags: getNotLockedTags(state),
  pagination: getTwitchFeedPagination(state),
  data: getTwitchFeed(state),
});

export default connect(mapStateToProps, { fetchTwitchFeed })(TwitchFeedModalsSaveStreams);
