import React from 'react';

import { Button, Col, Row } from 'reactstrap';

import translate from '../../../helpers/translate';

const SignUpFreeUserStart = () => (
  <Row className="d-flex px-md-6 pb-3">
    <Col className="align-content-center text-center">
      <Row className="justify-content-center">
        <h2>{translate('signup.common.title')}</h2>
      </Row>
      <Row className="mt-4 mb-4">
        <Col xs={12}>
          <p className="fs-2 ">{translate('signup.free_user.copy')}</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Button color="primary" className="px-5" type="submit" transform="down-1 shrink-4">
          {translate('signup.common.set_up_account')}
        </Button>
      </Row>
    </Col>
  </Row>
);

export default SignUpFreeUserStart;
