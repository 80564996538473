import { combineReducers } from 'redux';

import { RECEIVE_LANGUAGES, REQUEST_LANGUAGES, REJECT_LANGUAGES } from '../actions/languages';
import { apiReceive, apiRequest, apiReject } from '../config/api-service';

const newLanguages = (
  state = {
    items: {},
    itemsStatus: {},
    lists: {},
    listsStatus: {},
    stats: {},
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_LANGUAGES:
      return apiRequest(state, action);
    case RECEIVE_LANGUAGES:
      return apiReceive(state, action);
    case REJECT_LANGUAGES:
      return apiReject(state, action);
    default:
      return Object.assign({}, state);
  }
};

export default combineReducers({
  languages: newLanguages,
});
