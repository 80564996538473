export const getSavedStreams = (state) => state.savedStreams.data?.response?.streams ?? [];
export const getSavedStreamsIsLoading = (state) => state.savedStreams.data.isLoading;
export const getSavedStreamsError = (state) =>
  state.savedStreams.data.error?.length || !state.savedStreams.data.response;
export const getSavedStreamsPagination = (state) => {
  if (state.savedStreams.data.response?.pagination) {
    return {
      totalItems: state.savedStreams.data.response.pagination.total_entries,
      lastPage: state.savedStreams.data.response.pagination.last_page,
      page: state.savedStreams.data.response.pagination.page_number,
      pageSize: state.savedStreams.data.response.pagination.page_size,
    };
  }

  return [];
};
export const getSavedStreamsFiltersStartDate = (state) => state.savedStreams.filters.startDate;
export const getSavedStreamsFiltersEndDate = (state) => state.savedStreams.filters.endDate;
export const getSavedStreamsFiltersTags = (state) => state.savedStreams.filters.tags;
export const getSavedStreamsFiltersLanguages = (state) => state.savedStreams.filters.languages;
export const getSavedStreamsFiltersMinFollowers = (state) => state.savedStreams.filters.minFollowers;
export const getSavedStreamsFiltersMaxFollower = (state) => state.savedStreams.filters.maxFollowers;
export const getSavedStreamsFilters = (state) => ({
  languages: getSavedStreamsFiltersLanguages(state),
  startDate: getSavedStreamsFiltersStartDate(state),
  endDate: getSavedStreamsFiltersEndDate(state),
  tags: getSavedStreamsFiltersTags(state),
  minFollowers: getSavedStreamsFiltersMinFollowers(state),
  maxFollowers: getSavedStreamsFiltersMaxFollower(state),
});

export const getSavedStreamsPageSize = (state) => state.savedStreams.pageSize;
