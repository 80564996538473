import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TagsTable from './TagsTable';
import { resetForm, showModal } from '../../actions/forms';
import { fetchProjects } from '../../actions/projects';
import { fetchTags } from '../../actions/tags';
import { getCurrentProjectId } from '../../selectors/forms';
import {
  getTagsAddTagModalFormFields,
  getTagsAddTagModalFormTitle,
  getTagsAddTagModalIsVisible,
} from '../../selectors/tags';

const Tags = ({ fetchProjects, fetchTags, showModal, resetForm, currentProjectId }) => {
  useEffect(() => {
    fetchProjects();
    fetchTags(currentProjectId);
  }, [fetchTags, fetchProjects, currentProjectId]);

  const handleNewButtonClick = () => {
    showModal({ content: 'newTag' });
    resetForm();
  };

  return (
    <>
      <TagsTable
        title="Tags"
        tableId="tagsTable"
        rowsType="tags"
        newButton={true}
        newButtonClick={handleNewButtonClick}
        archiveSelection={true}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  currentProjectId: getCurrentProjectId(state),
  formTitle: getTagsAddTagModalFormTitle(state),
  formFields: getTagsAddTagModalFormFields(state),
  showModal: getTagsAddTagModalIsVisible(state),
});

Tags.propTypes = {
  newForm: PropTypes.func,
  onLoad: PropTypes.func,
};

export default connect(mapStateToProps, {
  showModal,
  resetForm,
  fetchTags,
  fetchProjects,
})(Tags);
