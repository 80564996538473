import React, { useState } from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons/faThumbtack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';

import { setUserDefaultProject } from '../../../actions/users';
import { getUsersCurrentUserId } from '../../../selectors/users';

const SidePanelProjectActions = ({ project, isActive, isDefaultProject, setUserDefaultProject, userId }) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleClickDefaultProject = () => {
    setUserDefaultProject({ userId, projectId: project.id });
  };

  const tooltipTarget = `default-project-${project.id}`;
  const tooltipText = isDefaultProject
    ? `${project.name} is your default project`
    : `Set ${project.name} as default project`;

  return (
    <div className=" icons-container" id={tooltipTarget}>
      <FontAwesomeIcon
        aria-label="default-project"
        icon={faThumbtack}
        transform="shrink-3"
        className={classNames({
          'default-project-icon': !isDefaultProject,
          'disabled-icon': isDefaultProject,
          'text-400': false,
        })}
        onClick={handleClickDefaultProject}
      />
      <Tooltip
        target={tooltipTarget}
        placement="top"
        isOpen={isTooltipOpen}
        toggle={() => setTooltipOpen(!isTooltipOpen)}
      >
        {tooltipText}
      </Tooltip>
      {isActive && (
        <FontAwesomeIcon
          aria-label="check"
          icon={faCheck}
          transform="shrink-5 down-4"
          className={classNames({
            'text-success': isActive,
            'text-400': false,
          })}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: getUsersCurrentUserId(state),
});

SidePanelProjectActions.propTypes = {
  project: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  isActive: PropTypes.bool,
  isDefaultProject: PropTypes.bool,
  setUserDefaultProject: PropTypes.func,
  userId: PropTypes.string,
};

export default connect(mapStateToProps, { setUserDefaultProject })(SidePanelProjectActions);
