import {
  TWITCH_FEED_CLEAN,
  TWITCH_FEED_SET_SLACK_MIN_SUBSCRIBERS,
  TWITCH_FEED_SET_DISCORD_WEBHOOK,
  TWITCH_FEED_SET_DISCORD_MIN_SUBSCRIBERS,
  TWITCH_FEED_SET_NOTIFICATION_STATUS,
  TWITCH_FEED_SET_SLACK_WEBHOOK,
  TWITCH_FEED_FETCH_FEED_SUCCESS,
  TWITCH_FEED_SET_KEYWORD,
} from '../../../actions/actionTypes';

const initialState = {
  keyword: null,
  webhook: null,
};

const modified = (state = initialState, action) => {
  switch (action.type) {
    case TWITCH_FEED_FETCH_FEED_SUCCESS:
      return { ...state, keyword: action.payload.data.keyword, webhook: action?.payload?.data?.webhook };
    case TWITCH_FEED_SET_DISCORD_WEBHOOK:
      return { ...state, webhook: { ...state.webhook, discord_webhook: action.payload } };
    case TWITCH_FEED_SET_SLACK_WEBHOOK:
      return { ...state, webhook: { ...state.webhook, slack_webhook: action.payload } };
    case TWITCH_FEED_SET_SLACK_MIN_SUBSCRIBERS:
      return { ...state, webhook: { ...state.webhook, slack_min_subs_notification: action.payload } };
    case TWITCH_FEED_SET_DISCORD_MIN_SUBSCRIBERS:
      return { ...state, webhook: { ...state.webhook, discord_min_subs_notification: action.payload } };
    case TWITCH_FEED_SET_NOTIFICATION_STATUS:
      return { ...state, webhook: { ...state.webhook, is_enabled: action.payload } };
    case TWITCH_FEED_SET_KEYWORD:
      return { ...state, keyword: action.payload };
    case TWITCH_FEED_CLEAN:
      return initialState;
    default:
      return state;
  }
};
export default modified;
