import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';

import Avatar from '../../common/Avatar';
import '../../../assets/scss/theme/Footprints/components/_avatar-title.scss';

const AvatarTitle = ({ imgSrc, title = '', description, link = false }) => {
  const renderTitle = () => {
    if (link) {
      return (
        <Link to={link}>
          <span className="fs-0 mb-0 ml-2">{title}</span>
        </Link>
      );
    }
    return <span className="fs-0 mb-0 ml-2">{title}</span>;
  };
  return (
    <Media className="fp-avatar-title align-items-center mb-1 mt-1">
      {imgSrc ? (
        <Avatar size={'l'} rounded={'circle'} src={imgSrc} />
      ) : (
        <div className="avatar avatar-xl">
          <div className={'avatar-name rounded-circle'}>
            <span className={'fs-0'}>{title[0]}</span>
          </div>
        </div>
      )}
      <Media body>
        {renderTitle()}
        <p className="mb-0">{description}</p>
      </Media>
    </Media>
  );
};

AvatarTitle.propTypes = {
  imgSrc: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default AvatarTitle;
