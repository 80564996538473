import React, { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { IMAGES_ROOT } from '../../../config/global-config';
import { setIsFirstLogin } from '../../../helpers/localStorage';
import { getCurrentProjectId } from '../../../selectors/forms';
import { getOrganizationAvatar, getOrganizationName } from '../../../selectors/organizations';
import { getUsersCurrentUserAvatar, getUsersCurrentUserIsAdmin } from '../../../selectors/users';
import Avatar from '../../common/Avatar';
import './ICOProfileDropdown.css';

const ICOProfileDropdown = ({ organizationName, organizationAvatar, userAvatar, userIsAdmin, projectId }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { user, logout } = useAuth0();

  const handleMouseOver = () => {
    let windowWidth = window.innerWidth;
    windowWidth > 992 && setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    let windowWidth = window.innerWidth;
    windowWidth > 992 && setDropdownOpen(false);
  };

  const handleLogout = () => {
    setIsFirstLogin(false);
    logout({
      returnTo: window.location.protocol + '//' + window.location.host + '/authentication/basic/logout',
    });
  };

  const avatarSrc = `${IMAGES_ROOT}${organizationAvatar}`;
  const userAvatarSrc = userAvatar || user.picture;

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={userAvatarSrc} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          {userIsAdmin && (
            <>
              <DropdownItem className="company-info-item" tag={Link} to={`/organization-details/${projectId}`}>
                <img className="svg-inline--fa fa-w-18" src={avatarSrc} alt={organizationName} /> {organizationName}{' '}
                details
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
          <DropdownItem tag={Link} to="/settings">
            Settings
          </DropdownItem>
          <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => ({
  projectId: getCurrentProjectId(state),
  userAvatar: getUsersCurrentUserAvatar(state),
  organizationName: getOrganizationName(state),
  organizationAvatar: getOrganizationAvatar(state),
  userIsAdmin: getUsersCurrentUserIsAdmin(state),
});

ICOProfileDropdown.propTypes = {
  organizationName: PropTypes.string,
  organizationAvatar: PropTypes.string,
  userAvatar: PropTypes.string,
  userIsAdmin: PropTypes.bool,
};

export default connect(mapStateToProps, null)(ICOProfileDropdown);
