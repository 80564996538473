import { useMutation } from 'react-query';

import getToastedNotification from '../../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../../config/global-config';
import { getToken } from '../../../../helpers/getToken';
import translate from '../../../../helpers/translate';

const useSaveVideoMutation = (onSuccess) =>
  useMutation({
    mutationKey: 'save_missing_video',
    mutationFn: async (data) => {
      const url = `${COVERAGE_API_ROOT}/saved_videos/save_missing_video`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: translate('pages.saved_videos.table.save_new_video.notifications.onSuccess'),
      };
      getToastedNotification({ customMessage });
      onSuccess();
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: translate('pages.saved_videos.table.save_new_video.notifications.onError'),
      };
      getToastedNotification({ customMessage });
    },
  });

export default useSaveVideoMutation;
