import React, { useState } from 'react';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { queryBuilderFormatter } from '../../../helpers/queryBuilderFormatter';

const QueryBuilderDropdown = ({
  queryType,
  setQueryType,
  searchQuery,
  handleStateOnDropdownChange,
  isExclude = false,
}) => {
  const [dropdownTitle, setDropdownTitle] = useState('all');
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleChangeDropdown = (value) => {
    setQueryType(value);
    setDropdownTitle(value);

    if (searchQuery) {
      const formattedQuery = queryBuilderFormatter(searchQuery, value, isExclude);
      handleStateOnDropdownChange(formattedQuery);
    }
  };

  return (
    <Dropdown
      id="dropdownMenu"
      isOpen={isDropdownOpen}
      toggle={() => setDropdownOpen(!isDropdownOpen)}
      onChange={() => handleChangeDropdown(queryType)}
      aria-label="dropdownMenu"
    >
      <DropdownToggle caret size="md" color="primary" style={{ width: '60px', paddingLeft: '10px' }}>
        {dropdownTitle}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => handleChangeDropdown('all')}>All</DropdownItem>
        <DropdownItem onClick={() => handleChangeDropdown('any')}>Any</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default QueryBuilderDropdown;
