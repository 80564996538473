import { apiFetch } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const REQUEST_SAVE_AVATAR = 'REQUEST_SAVE_AVATAR';
export const REJECT_SAVE_AVATAR = 'REJECT_SAVE_AVATAR';
export const RECEIVE_SAVE_AVATAR = 'RECEIVE_SAVE_AVATAR';

export const saveAvatar = ({ images, scope }) => {
  const data = new FormData();
  data.append('image', images[0]);
  data.append('scope', scope); //Scope must be in id;type format. Example: 2;project
  data.append('description', 'description || ');
  return apiFetch(
    'f/av',
    {
      request: {
        type: REQUEST_SAVE_AVATAR,
        payload: data,
      },
      receive: RECEIVE_SAVE_AVATAR,
      reject: REJECT_SAVE_AVATAR,
    },
    {
      body: data,
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      privatePath: true,
    },
  );
};
