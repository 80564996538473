import React, { createRef, useState } from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, FormGroup, Row } from 'reactstrap';

import { ArchiveFilterSelect } from './ArchiveFilterSelect';
import ExportCSVButton from './ExportCSVButton/ExportCSVButton';
import translate from '../../../helpers/translate';
import FalconCardHeader from '../../common/FalconCardHeader';
import ButtonIconWithTooltip from '../Buttons/ButtonIconWithTooltip/ButtonIconWithTooltip';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';

const { SearchBar } = Search;

const SelectRowInput = ({ indeterminate, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => {}}
      ref={(input) => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);

const selectRow = (onSelect) => ({
  mode: 'checkbox',
  clickToSelect: false,
  selectionHeaderRenderer: ({ ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
  selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
  headerColumnStyle: { border: 0, verticalAlign: 'middle' },
  selectColumnStyle: { border: 0, verticalAlign: 'middle' },
  onSelect: onSelect,
  onSelectAll: onSelect,
});

const StaticDataTable = ({
  archiveSelection,
  rowsSelectable = false,
  bulkActions,
  columns,
  isTableLoading,
  newButton,
  newButtonClick,
  rows,
  rowsArchived,
  title,
  viewAllBtn,
  currentProjectId,
  newReport = false,
  isNewButtonDisabled = false,
}) => {
  let table = createRef();
  const [useArchive, setUseArchive] = useState(false);
  if (useArchive) {
    rows = rowsArchived;
  }
  const [isSelected, setIsSelected] = useState(false);
  const options = {
    custom: true,
    sizePerPage: 25,
    sizePerPageList: [12, 25, 50, 100],
    totalSize: rows.length,
  };

  const onSelect = () => {
    // eslint-disable-next-line no-undef
    setImmediate(() => {
      setIsSelected(!!table.current.selectionContext.selected.length);
    });
  };

  const history = useHistory();

  const handleButtonClick = () => {
    history.push(`/new-report/${currentProjectId}`);
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = paginationProps.page * paginationProps.sizePerPage;
        const bootTable = (props) =>
          rowsSelectable ? (
            <BootstrapTable
              {...props.baseProps}
              selectRow={selectRow(onSelect)}
              classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger"
              headerClasses="bg-200 text-900"
              ref={table}
              noDataIndication={() => (
                <div className="alert alert-info alert-styled-left table-info">No matching data was found.</div>
              )}
              {...paginationTableProps}
            />
          ) : (
            <BootstrapTable
              {...props.baseProps}
              classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger"
              headerClasses="bg-200 text-900"
              ref={table}
              noDataIndication={() => (
                <div className="alert alert-info alert-styled-left table-info">No matching data was found.</div>
              )}
              {...paginationTableProps}
            />
          );
        return (
          <Card className="mt-3 mb-3">
            <div className="table-responsive fp-table">
              <ToolkitProvider keyField="id" data={rows} columns={columns} bootstrap4 bordered={false} search exportCSV>
                {(props) => (
                  <>
                    <FalconCardHeader title={title} light={false} className="mt-2 mb-2">
                      {isSelected ? (
                        {
                          bulkActions,
                        }
                      ) : (
                        <Row className="mt-3 mt-md-0 mr-md-1">
                          <FormGroup xs={12}>
                            {newButton ? (
                              <ButtonIconWithTooltip
                                text={translate('common.create')}
                                id="create_button"
                                icon={faPlusCircle}
                                disabled={isNewButtonDisabled}
                                onClick={newButtonClick}
                                className="mr-3 form-inline d-none d-md-inline-block text-uppercase"
                              />
                            ) : null}
                            {newReport && (
                              <ButtonIconWithTooltip
                                text={translate('reports.new_report')}
                                icon={faPlusCircle}
                                onClick={handleButtonClick}
                                disabled={isNewButtonDisabled}
                                id="new_report_button"
                                className="mr-3 form-inline d-none d-md-inline-block text-uppercase"
                              />
                            )}
                            <SearchBar className={'rounded-pill'} {...props.searchProps} />
                            {archiveSelection ? (
                              <ArchiveFilterSelect onChange={setUseArchive} useArchive={useArchive} />
                            ) : null}
                            <ExportCSVButton {...props.csvProps} rows={rows} />
                          </FormGroup>
                        </Row>
                      )}
                    </FalconCardHeader>
                    <CardBody className="p-0">
                      {isTableLoading && <Loading pageSize={paginationProps.dataSize} isTable={true} />}
                      {bootTable(props)}
                    </CardBody>
                  </>
                )}
              </ToolkitProvider>
            </div>
            <Pagination
              lastIndex={lastIndex}
              paginationProps={paginationProps}
              rows={rows}
              viewAll={viewAllBtn}
              dropdownType="static"
            />
          </Card>
        );
      }}
    </PaginationProvider>
  );
};

StaticDataTable.propTypes = {
  archiveSelection: PropTypes.bool,
  rowsSelectable: PropTypes.bool,
  bulkActions: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  isTableLoading: PropTypes.bool,
  newButton: PropTypes.bool,
  newButtonClick: PropTypes.func,
  onLoad: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  rowsArchived: PropTypes.array,
  title: PropTypes.string,
  urlParams: PropTypes.shape({
    projectId: PropTypes.string,
  }),
  viewAllBtn: PropTypes.bool,
  csvProps: PropTypes.array,
  newReport: PropTypes.bool,
  currentProjectId: PropTypes.number,
};

export default StaticDataTable;
