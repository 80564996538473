import React, { useState } from 'react';

import { Col, Row } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

import QueryBuilderDropdown from './QueryBuilderProjectsDropdown.jsx';

const QueryBuilderProjectsExcludeInput = ({ searchQuery, setSearchQuery, queryType, setQueryType }) => {
  const [excludeQuery, setExcludeQuery] = useState(null);

  const handleChangeExcludeInput = (event) => {
    if (event.target.value) {
      setExcludeQuery(event.target.value);
      setSearchQuery({ ...searchQuery, excludeQuery: event.target.value });
    } else {
      setSearchQuery({ ...searchQuery, excludeQuery: null });
    }
  };

  const handleChangeDropdown = (value) => {
    setSearchQuery({ ...searchQuery, excludeQuery: value });
  };

  return (
    <>
      <Label for="searchInput" className="mr-sm-2">
        Terms to exclude
      </Label>
      <Row form>
        <Col md={2} xs={3}>
          <QueryBuilderDropdown
            queryType={queryType}
            setQueryType={setQueryType}
            searchQuery={excludeQuery}
            handleStateOnDropdownChange={handleChangeDropdown}
            isExclude
          />
        </Col>
        <Col md={10} xs={9}>
          <Input
            id="searchInput"
            onChange={handleChangeExcludeInput}
            defaultValue={searchQuery.excludeQuery}
            placeholder="Your terms separated by commas"
          />
        </Col>
      </Row>
    </>
  );
};

export default QueryBuilderProjectsExcludeInput;
