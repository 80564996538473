import React from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { fetchYoutubeFeed } from '../../../actions/youtubeFeed';
import translate from '../../../helpers/translate';
import { getYoutubeFeedTableFilters } from '../../../selectors/feeds/youtubeFeed';
import { getOrganizationId } from '../../../selectors/organizations';
import { getCurrentProject } from '../../../selectors/projects';

const YoutubeFeedTableFiltersModalFooter = ({ project, onClose, fetchYoutubeFeed, filters, userOrganizationId }) => {
  const onClick = () => {
    fetchYoutubeFeed({
      youtube_search_id: project['youtube-search-id'],
      filters: {
        ...filters,
        organization_id: userOrganizationId,
      },
    });
    onClose();
  };

  return (
    <>
      <Button size="sm" onClick={onClose} className="fp-button-close">
        {translate('common.close')}
      </Button>
      <Button size="sm" type="submit" onClick={onClick} color="primary" name="saveButton">
        {translate('common.apply')}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  filters: getYoutubeFeedTableFilters(state),
  userOrganizationId: getOrganizationId(state),
});

export default connect(mapStateToProps, { fetchYoutubeFeed })(YoutubeFeedTableFiltersModalFooter);
