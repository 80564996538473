import React, { memo, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { FormGroup, Input, Label } from 'reactstrap';

import NewReportHighlightSection from './Highlights/NewReportHighlightSection.jsx';
import NewReportSavedStreams from './NewReportSavedStreams.jsx';
import NewReportSavedVideos from './NewReportSavedVideos.jsx';
import ReportSimpleListEditor from './ReportSimpleListEditor';
import { changeNewReportForm } from '../../actions/forms';
import ArticlesReportTable from '../../components/ICO/tables/ArticlesReportTable';
import {
  getOrganizationPermissionsHasScreenshot,
  getOrganizationPermissionsHasTwitchFeed,
  getOrganizationPermissionsHasYoutubeFeed,
} from '../../selectors/permissions';
import { getNewReportFormStatus } from '../../selectors/savedCoverage/articles';
import { getSavedStreamsPagination } from '../../selectors/savedCoverage/savedStreams';
import { getSavedVideosPagination } from '../../selectors/savedCoverage/savedVideos';

const NewReportTableSelector = ({
  onChange,
  onSaveTemplate,
  userCanTakeScreenshot,
  userHasYoutubeFeed,
  userHasTwitchFeed,
  formStatus,
}) => {
  const urlParams = useParams();
  const [typeChoice, setTypeChoice] = useState(0);

  const renderTypeChoice = () => {
    switch (typeChoice) {
      case 0:
        return (
          <>
            {userCanTakeScreenshot ? <NewReportHighlightSection /> : null}
            <ArticlesReportTable urlParams={urlParams} />
            {userHasYoutubeFeed ? <NewReportSavedVideos /> : null}
            {userHasTwitchFeed ? <NewReportSavedStreams /> : null}
          </>
        );
      case 1:
        return <ReportSimpleListEditor urlParams={urlParams} onSaveTemplate={onSaveTemplate} />;
      default:
        return (
          <>
            <ArticlesReportTable urlParams={urlParams} />
            {userHasYoutubeFeed ? <NewReportSavedVideos /> : null}
            {userHasTwitchFeed ? <NewReportSavedStreams /> : null}
          </>
        );
    }
  };

  if (formStatus === 'STEP 2 LOADED') {
    return (
      <>
        <FormGroup>
          <Label for="tableTypeSelect">Articles Table Display Select</Label>
          <Input
            onChange={(e) => {
              const value = parseInt(e.target.value);
              onChange(value);
              setTypeChoice(value);
            }}
            type="select"
            name="select"
            id="tableTypeSelect"
          >
            <option value={0}>Standard Articles Table with Pagination and Filters</option>
            <option value={1}>Custom Articles List</option>
          </Input>
        </FormGroup>
        {renderTypeChoice()}
      </>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  formStatus: getNewReportFormStatus(state),
  userCanTakeScreenshot: getOrganizationPermissionsHasScreenshot(state),
  userHasYoutubeFeed: getOrganizationPermissionsHasYoutubeFeed(state),
  userHasTwitchFeed: getOrganizationPermissionsHasTwitchFeed(state),
  savedVideosPagination: getSavedVideosPagination(state),
  savedStreamsPagination: getSavedStreamsPagination(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSaveTemplate: (value) => {
    dispatch(
      changeNewReportForm({
        field: 'customTableTemplate',
        value,
      }),
    );
  },
  onChange: (value) => {
    dispatch(
      changeNewReportForm({
        field: 'tableType',
        value,
      }),
    );
  },
});

NewReportTableSelector.propTypes = {
  onSaveTemplate: PropTypes.func,
  onChange: PropTypes.func,
};

export const NewReportTableSelectorContainer = memo(
  connect(mapStateToProps, mapDispatchToProps)(NewReportTableSelector),
);
