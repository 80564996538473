import React from 'react';

import TwitchFeedTableActionColumns from './TwitchFeedTableActionColumns.jsx';
import TwitchFeedTableVideoPreview from './TwitchFeedTableVideoPreview.jsx';
import { dateFormatter, langFormatter, videosViewFormatter } from '../../../components/ICO/tables/helpers/formatters';
import translate from '../../../helpers/translate';

const renderStreamImage = (image) => {
  if (image.includes('_404')) {
    return (
      <img
        src="https://ico-files.s3.eu-west-2.amazonaws.com/images/Twitch+Thumbnail.png"
        width="150"
        height="80"
        alt="Video thumbnail"
        loading="lazy"
        title="Stream thumbnail not found for this stream"
      />
    );
  } else {
    const imageSrc = image.replace(/%\{width\}/g, '150').replace(/%\{height\}/g, '80');
    return <img src={imageSrc} width="150" height="80" alt="Video thumbnail" loading="lazy" />;
  }
};

const twitchFeedTableColumns = [
  {
    dataField: 'video.thumbnail_url',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
    formatter: (row, data) => renderStreamImage(data.video?.thumbnail_url),
  },
  {
    dataField: 'video.channel.broadcaster_name',
    formatter: (row, rowData) => (
      <a
        href={`https://www.twitch.tv/${rowData?.video?.channel?.broadcaster_name}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {rowData?.video?.channel?.broadcaster_name}
      </a>
    ),
    text: translate('pages.twitch_feed.table.columns.channel'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'video.title',
    formatter: (data, videoData) => <TwitchFeedTableVideoPreview videoData={videoData} />,
    text: translate('pages.twitch_feed.table.columns.title'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'video.channel.followers',
    formatter: videosViewFormatter,
    text: translate('pages.twitch_feed.table.columns.followers'),
    align: 'center',
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0 align-middle',
    headerAlign: 'center',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'video.language',
    formatter: langFormatter,
    text: 'Language',
    align: 'center',
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0 align-middle',
    headerAlign: 'center',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'video.published_at',
    formatter: dateFormatter,
    text: translate('pages.twitch_feed.table.columns.date'),
    align: 'center',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0 align-middle',
    headerAlign: 'center',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'actions',
    formatter: (row, rowData) => <TwitchFeedTableActionColumns videoData={rowData} />,
    text: translate('pages.twitch_feed.table.columns.actions'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
];

export default twitchFeedTableColumns;
