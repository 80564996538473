import React, { useCallback, useState } from 'react';

import { Col, Row } from 'reactstrap';
import { v4 as uuidV4 } from 'uuid';

import translate from '../../../../helpers/translate';
import AddonProduct from '../../AddonProduct';
import SingleSelect from '../../forms/SingleSelect';

const SELECT_OPTIONS = [
  { value: 1, label: translate('modals.upgrade_subscription.addons.select.monthly_starter') },
  { value: 2, label: translate('modals.upgrade_subscription.addons.select.annually_starter') },
  { value: 3, label: translate('modals.upgrade_subscription.addons.select.monthly_pro') },
  { value: 4, label: translate('modals.upgrade_subscription.addons.select.annually_pro') },
];

const YEARLY_PRO_PLAN_ID = 4;
const YEARLY_STARTER_PLAN_ID = 2;

const UpgradeSubscriptionAddons = ({ checkoutCreateSession, customerId, isLoading, proProducts, starterProducts }) => {
  const [selectedPlan, setPlan] = useState(0);

  const handleChangePlan = ({ value }) => {
    setPlan(value);
  };

  const isAnnualPlan = selectedPlan === YEARLY_STARTER_PLAN_ID || selectedPlan === YEARLY_PRO_PLAN_ID;

  const generateProducts = useCallback(
    (products, type) =>
      products.map((product) => (
        <AddonProduct
          key={uuidV4()}
          product={product}
          isLoading={isLoading}
          checkoutCreateSession={checkoutCreateSession}
          isAnnualPlan={isAnnualPlan}
          customerId={customerId}
          type={type}
        />
      )),
    [isAnnualPlan, checkoutCreateSession, isLoading, customerId],
  );

  const getProductsBasedOnSelectedPlan =
    selectedPlan === 1 || selectedPlan === 2
      ? generateProducts(starterProducts, 'starter')
      : generateProducts(proProducts, 'pro');

  return (
    <>
      <Row>
        <Col xs={12} className="mt-4">
          <SingleSelect
            aria-label="selectPlan"
            name="plan-select"
            value={selectedPlan}
            items={SELECT_OPTIONS}
            placeholder={translate('modals.upgrade_subscription.addons.select.placeholder')}
            onChange={handleChangePlan}
          />
        </Col>
      </Row>
      <Row className="mt-5 d-flex flex-center">
        {selectedPlan ? <Col xs={12}>{getProductsBasedOnSelectedPlan}</Col> : null}
      </Row>
    </>
  );
};

export default UpgradeSubscriptionAddons;
