import { API_CALL, FETCH_ALERTS } from './actionTypes';

export const fetchAlerts = () => {
  const endpoint = 'alerts?page[page]=0&page[page_size]=100';
  return {
    type: API_CALL,
    meta: {
      type: FETCH_ALERTS,
      endpoint: endpoint,
      method: 'GET',
    },
  };
};
