import React from 'react';

const MediaFeedAISentimentStatusCircle = ({ sentiment }) => {
  let color;
  switch (sentiment) {
    case 'neutral':
      color = 'orange';
      break;
    case 'positive':
      color = 'green';
      break;
    case 'negative':
      color = 'red';
      break;
    case 'controversial':
      color = 'purple';
      break;
    default:
      color = 'grey';
  }

  const circleStyle = {
    display: 'inline-block',
    backgroundColor: color,
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    marginRight: 10,
  };

  return <span style={circleStyle} title={sentiment} />;
};

export default MediaFeedAISentimentStatusCircle;
