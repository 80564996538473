import React, { useState } from 'react';

import dayjs from 'dayjs';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

import { youtubeFeedTableFiltersSetStartDate, youtubeFeedTableFiltersSetEndDate } from '../../../actions/youtubeFeed';
import RangeDateFilter from '../../../components/ICO/forms/RangeDateFilter';
import translate from '../../../helpers/translate';
import {
  getYoutubeFeedTableFiltersEndDate,
  getYoutubeFeedTableFiltersStartDate,
} from '../../../selectors/feeds/youtubeFeed';

const allTimeDateTimeId = 1;
const specificDateTimeId = 2;

const YoutubeFeedTableFiltersModalBodyDateFilter = ({
  startDate,
  endDate,
  youtubeFeedTableFiltersSetEndDate,
  youtubeFeedTableFiltersSetStartDate,
}) => {
  const defaultChecked = startDate || endDate ? specificDateTimeId : allTimeDateTimeId;

  const [checkedOption, setCheckedOption] = useState(defaultChecked);
  const defaultDateRange = startDate || endDate ? [startDate, endDate] : [moment().startOf('month'), moment()];
  const [localDateRange, setLocalDateRange] = useState(defaultDateRange);

  const onChangeDate = (datePickerData) => {
    const endDate = dayjs(datePickerData.endDate).format('YYYY-MM-DD');
    const startDate = dayjs(datePickerData.startDate).format('YYYY-MM-DD');
    setLocalDateRange([startDate, endDate]);
    youtubeFeedTableFiltersSetStartDate(startDate);
    youtubeFeedTableFiltersSetEndDate(endDate);
  };

  const handleAllTime = () => {
    setLocalDateRange([]);
    setCheckedOption(allTimeDateTimeId);
    youtubeFeedTableFiltersSetStartDate(null);
    youtubeFeedTableFiltersSetEndDate(null);
  };

  return (
    <>
      <Label for="keywordInput">{translate('pages.youtube_feed.table.filters.dates.title')}</Label>
      <FormGroup className="form-check">
        <Input
          type="radio"
          name="radio1"
          onClick={handleAllTime}
          checked={checkedOption === allTimeDateTimeId}
          readOnly
        />
        <Label check>{translate('pages.youtube_feed.table.filters.dates.all_time')}</Label>
      </FormGroup>
      <FormGroup className="form-check">
        <Input
          type="radio"
          name="radio2"
          onClick={() => setCheckedOption(specificDateTimeId)}
          checked={checkedOption === specificDateTimeId}
          readOnly
        />
        <Label check>{translate('pages.youtube_feed.table.filters.dates.specific')}</Label>
        <RangeDateFilter
          className="daterange-predefined"
          disabled={checkedOption !== 2}
          onChange={(e, datePickerData) => onChangeDate(datePickerData)}
          start={localDateRange[0]}
          end={localDateRange[1]}
        />
      </FormGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  startDate: getYoutubeFeedTableFiltersStartDate(state),
  endDate: getYoutubeFeedTableFiltersEndDate(state),
});

export default connect(mapStateToProps, { youtubeFeedTableFiltersSetStartDate, youtubeFeedTableFiltersSetEndDate })(
  YoutubeFeedTableFiltersModalBodyDateFilter,
);
