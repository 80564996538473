import { combineReducers } from 'redux';

import areNotificationsEnabled from './areNotificationsEnabled';
import data from './data';
import discord from './discord';
import filters from './filters/index';
import form from './form';
import slack from './slack';

const twitchFeed = combineReducers({ data, filters, areNotificationsEnabled, slack, discord, form });
export default twitchFeed;
