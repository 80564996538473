import { CHANGE_ORGANIZATION_NAME, RECEIVE_SAVE_LOGO } from '../../../actions/organizationDetails';
import { RECEIVE_USER_PROFILE } from '../../../actions/users';
import { STATUS } from '../../../config/api-service';

const initialState = {
  currentOrg: null,
  currentOrgStatus: STATUS.LOADING,
};

const modified = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ORGANIZATION_NAME:
      return { ...state, currentOrg: { ...state.currentOrg, name: action.payload } };
    case RECEIVE_SAVE_LOGO:
      return { ...state, currentOrg: { ...state.currentOrg, avatar: action.payload.items.url.url } };
    case RECEIVE_USER_PROFILE:
      return {
        ...state,
        currentOrg: Object.values(action.payload.items)[0].organization,
      };
    default:
      return state;
  }
};

export default modified;
