import React from 'react';

import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { savedStreamsTableFiltersSetTags } from '../../../../actions/savedStreams';
import SingleSelect from '../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../helpers/translate';
import { getSavedStreamsFiltersTags } from '../../../../selectors/savedCoverage/savedStreams';
import { getNotLockedTags } from '../../../../selectors/tags';

const SavedStreamsFiltersModalBodyTags = ({ tags, selectedTags, savedStreamsTableFiltersSetTags }) => {
  const handleOnChange = (tags) => {
    if (tags) {
      const tagIds = tags.map(({ value }) => value);
      savedStreamsTableFiltersSetTags(tagIds);
    } else {
      savedStreamsTableFiltersSetTags(null);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <FormGroup>
          <Label for="tags">{translate('common.tags')}</Label>
          <SingleSelect
            aria-label="tag-select"
            name="tags"
            isMulti
            items={tags}
            value={selectedTags ?? []}
            className="sm-select"
            placeholder={translate('common.all_tags')}
            onChange={handleOnChange}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  tags: getNotLockedTags(state),
  selectedTags: getSavedStreamsFiltersTags(state),
});

export default connect(mapStateToProps, { savedStreamsTableFiltersSetTags })(SavedStreamsFiltersModalBodyTags);
