import {
  SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER,
  SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_SUCCESS,
  SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_ERROR,
  SIGNUP_FREE_USER_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: [],
  error: null,
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER:
      return { ...state, isLoading: true };
    case SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_SUCCESS:
      return { ...state, isLoading: false, response: Object.values(action.payload.items)[0] };
    case SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { error: action.payload.response.error.reason, errorCode: action.payload.status },
      };
    case SIGNUP_FREE_USER_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default customer;
