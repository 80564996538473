import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Row } from 'reactstrap';

import OrganizationDetailsFormLogo from './OrganizationDetailsFormLogo';
import OrganizationDetailsFormName from './OrganizationDetailsFormName';
import OrganizationDetailsFormSave from './OrganizationDetailsFormSave';
import OrganizationDetailsSubscriptionButton from './OrganizationDetailsSubscriptionButton';
import { getUsersCurrentUserIsAdmin } from '../../selectors/users';

const OrganizationDetailsForm = ({ userIsAdmin }) => (
  <>
    {userIsAdmin ? <OrganizationDetailsSubscriptionButton /> : null}
    <Form aria-label="form" className="mt-4" onSubmit={(e) => e.preventDefault()}>
      <OrganizationDetailsFormLogo userIsAdmin={userIsAdmin} />
      <OrganizationDetailsFormName userIsAdmin={userIsAdmin} />
    </Form>
    {userIsAdmin && (
      <Row className="justify-content-end">
        <OrganizationDetailsFormSave />
      </Row>
    )}
  </>
);

OrganizationDetailsForm.propTypes = {
  userIsAdmin: PropTypes.bool,
};

OrganizationDetailsForm.defaultProps = {
  userIsAdmin: false,
};

const mapStateToProps = (state) => ({
  userIsAdmin: getUsersCurrentUserIsAdmin(state),
});

export default connect(mapStateToProps)(OrganizationDetailsForm);
