import React from 'react';

import { faTrophy, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { ReactComponent as MetaCriticIcon } from '../../../assets/img/logos/metacritic-icon.svg';
import { colorScore, extractDomain } from '../../../config/url-utils';

import '../../../assets/scss/theme/Footprints/components/_badges.scss';

export const dateRangeFilter = (dateRange) => (row) => {
  if (dateRange.length === 0) {
    return true;
  }
  const startDate = moment(dateRange[0]);
  const endDate = moment(dateRange[1]);
  const insertedAt = moment(row['inserted-at']);
  return insertedAt.isSameOrAfter(startDate, 'day') && insertedAt.isSameOrBefore(endDate, 'day');
};

export const scoreFilter = (scoreRange) => (row) => {
  if (scoreRange.length === 0) {
    return true;
  }
  return row.reach >= scoreRange[0] && row.reach <= scoreRange[1];
};

export const languagesFilter = (language) => (row) => {
  if (language < 0) return true;
  return row.language === language;
};

export const tagFilter = (tag) => (row) => {
  if (tag < 0) return true;
  return row.tag === tag;
};

export const applyFiltersToRows = (rows, filters) =>
  rows
    .filter(dateRangeFilter(filters.date_range))
    .filter(languagesFilter(filters.languages[0]))
    .filter(scoreFilter(filters.score_range))
    .filter(tagFilter(filters.tagId));

export const ScoreFormatter = (score) => (
  <Badge color={colorScore(score)} className="rounded-capsule">
    <FontAwesomeIcon icon={faTrophy} /> {score}
  </Badge>
);

export const metaCriticFormatter = (isMetacritic) =>
  isMetacritic ? (
    <MetaCriticIcon style={{ width: '16px', height: '16px' }} />
  ) : (
    <MetaCriticIcon
      style={{
        width: '16px',
        height: '16px',
        filter: 'grayScale(1)',
        opacity: '0.2',
      }}
    />
  );

export const totalMediaScoreFormatter = (score) => (
  <Badge color="media-score-max" className="rounded-capsule">
    <FontAwesomeIcon icon={faTrophy} /> {score}
  </Badge>
);

export const totalVideosFormatter = (totalVideos) => (
  <Badge color="total-videos-badge" className="rounded-capsule">
    <FontAwesomeIcon icon={faVideo} /> {totalVideos}
  </Badge>
);

export const langFormatter = (lang = '') => (
  <Badge color="lang-badge" className="rounded-capsule">
    {lang ? lang.toUpperCase() : ''}
  </Badge>
);

export const tagFormatter = (tag = '') => (
  <Badge color="tag-badge" className="rounded-capsule">
    {tag ? tag.toUpperCase() : ''}
  </Badge>
);

export const mediaFormatter = (url) => <span>{extractDomain(url)}</span>;
export const mediaWebsiteLinkFormatter = (url) => (
  <Link to={'/websites/' + url}>
    <span>{extractDomain(url)}</span>
  </Link>
);
export const favIconFormatter = (favicon) => {
  if (favicon) {
    return <img src={favicon} alt={'favicon'} />;
  } else return <></>;
};
