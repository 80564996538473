import React from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { savedVideosFetch } from '../../../../actions/savedVideos';
import translate from '../../../../helpers/translate';
import { getCurrentProjectId } from '../../../../selectors/forms';
import { getSavedVideosFilters, getSavedVideosPageSize } from '../../../../selectors/savedCoverage/savedVideos';

const SavedVideosFiltersModalFooter = ({ onClose, savedVideosFetch, filters, projectId, sizePerPage }) => {
  const onClick = () => {
    savedVideosFetch({
      project_id: projectId,
      filters,
      pagination: { page: 1, sizePerPage, sortField: 'published_at' },
    });
    onClose();
  };

  return (
    <>
      <Button size="sm" onClick={onClose} className="fp-button-close">
        {translate('common.close')}
      </Button>
      <Button size="sm" type="submit" onClick={onClick} color="primary" name="saveButton">
        {translate('common.apply')}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  projectId: getCurrentProjectId(state),
  filters: getSavedVideosFilters(state),
  sizePerPage: getSavedVideosPageSize(state),
});

export default connect(mapStateToProps, { savedVideosFetch })(SavedVideosFiltersModalFooter);
