import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';

import NewReportFilterLanguages from './Filters/NewReportFilterLanguages.jsx';
import NewReportFilterTags from './Filters/NewReportFilterTags.jsx';
import NewReportSubmitButton from './NewReportSubmitButton';
import ArticlesDatePicker from '../../components/ICO/forms/ArticlesDatePicker';
import {
  getFormNewReportFormTotalArticlesByTagAndLanguage,
  getFormNewReportFormTotalStreamsByTagAndLanguage,
  getFormNewReportFormTotalVideosByTagAndLanguage,
} from '../../selectors/newReport';

export const maxReportArticles = 10000;

export const isValidLoadedArticles = (loadedArticlesCount) =>
  loadedArticlesCount > 0 && loadedArticlesCount <= maxReportArticles;

const NewReportFiltersForm = ({ formStatus, step, totalArticles, totalVideos, totalStreams }) => {
  const colAlign = formStatus !== 'STEP 3 LOADED' ? 'justify-content-end' : 'justify-content-center';

  const renderFiltersDetails = () => {
    if (totalArticles > 0 && totalStreams === 0 && totalVideos === 0) {
      return `${totalArticles} articles with current filters.`;
    }

    if (totalArticles > 0 && totalVideos > 0 && totalStreams === 0) {
      return `${totalArticles} articles and ${totalVideos} videos with current filters.`;
    }

    if (totalArticles > 0 && totalStreams > 0 && totalVideos === 0) {
      return `${totalArticles} articles and ${totalStreams} streams with current filters.`;
    }

    if (totalArticles > 0 && totalStreams > 0 && totalVideos > 0) {
      return `${totalArticles} articles, ${totalVideos} videos and ${totalStreams} streams with current filters.`;
    }
  };

  const renderByStep = () => {
    switch (step) {
      default:
      case 0:
        return (
          <FormGroup>
            <Row>
              <Col md={12} xs={12}>
                <FormGroup>
                  <div className={formStatus !== 'STEP 3 LOADED' ? '' : 'd-none'}>
                    <ArticlesDatePicker />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
        );
      case 1:
        return (
          <>
            <FormGroup>
              <Row>
                <Col md={6} xs={12}>
                  <FormGroup>
                    <Label for="select-tags">Tags</Label>
                    <NewReportFilterTags />
                  </FormGroup>
                </Col>
                <Col md={6} xs={12}>
                  <FormGroup>
                    <Label for="select-languages">Languages</Label>
                    <NewReportFilterLanguages />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </>
        );
    }
  };

  const renderFooterByStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Col md={6} xs={12}>
              {totalArticles === 0 && (
                <span className="text-bold text-danger">{totalArticles} articles found with these filters!</span>
              )}
              {totalArticles > maxReportArticles && (
                <span className="text-bold">
                  Reports can only have a maximum of {maxReportArticles} articles. Current filters will load
                  {totalArticles.length} articles.
                </span>
              )}
              {totalArticles <= maxReportArticles && totalArticles > 0 && (
                <span className="text-primary text-bold">{renderFiltersDetails()}</span>
              )}
            </Col>
            <Col md={6} xs={12} className={`d-flex ${colAlign}`}>
              <NewReportSubmitButton formStatus={formStatus} totalArticles={totalArticles} />
            </Col>
          </>
        );
      default:
        return (
          <>
            <Col md={12} xs={12} className={`d-flex ${colAlign}`}>
              <NewReportSubmitButton formStatus={formStatus} totalArticles={totalArticles} />
            </Col>
          </>
        );
    }
  };
  return (
    <Form>
      {renderByStep()}
      <Row className="d-flex">{renderFooterByStep()}</Row>
    </Form>
  );
};

NewReportFiltersForm.propTypes = {
  formStatus: PropTypes.string,
  step: PropTypes.number,
};

const mapStateToProps = (state) => ({
  totalArticles: getFormNewReportFormTotalArticlesByTagAndLanguage(state),
  totalVideos: getFormNewReportFormTotalVideosByTagAndLanguage(state),
  totalStreams: getFormNewReportFormTotalStreamsByTagAndLanguage(state),
});

export default memo(connect(mapStateToProps)(NewReportFiltersForm));
