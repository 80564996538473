export const starterFeaturesShowMore = [
  'starter.historical_data',
  'unlimited_archive',
  'unlimited_results',
  'unlimited_users',
  'starter.priority_customer_support',
];

export const proFeaturesShowMore = [
  'unlimited_reports',
  'pro.historical_data',
  'unlimited_archive',
  'unlimited_results',
  'unlimited_users',
  'slack_integration',
  'highlight_feature',
  'pro.shared_projects',
  'pro.priority_customer_support',
  'pro.account_manager',
];

export const agencyFeaturesShowMore = [
  'unlimited_reports',
  'agency.historical_data',
  'unlimited_archive',
  'unlimited_results',
  'unlimited_users',
  'slack_integration',
  'agency.similar_web_data',
  'highlight_feature',
  'agency.priority_customer_support',
  'agency.account_manager',
];

export const agencyComingSoon = ['agency.customised_branding', 'agency.multiple_alerts', 'agency.discord_integration'];
export const proComingSoon = ['discord_integration'];
export const starterDefaultFeatures = ['starter.active_projects', 'unlimited_reports'];
export const proDefaultFeatures = ['pro.active_projects', 'youtube_feed'];
export const agencyDefaultFeatures = ['agency.active_projects', 'youtube_feed'];
