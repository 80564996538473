import React, { useState } from 'react';

import { connect } from 'react-redux';

import SavedStreamsAddVideoButton from './SavedStreamsAddVideoButton.jsx';
import { savedStreamsFetch, savedStreamsTableFiltersClean } from '../../../actions/savedStreams';
import ButtonIcon from '../../../components/common/ButtonIcon';
import translate from '../../../helpers/translate';
import { getCurrentProject } from '../../../selectors/projects';
import { getSavedStreamsFilters, getSavedStreamsPageSize } from '../../../selectors/savedCoverage/savedStreams';
import SavedStreamsFiltersModal from '../Modals/Filters/SavedStreamsFiltersModal.jsx';

const SavedStreamsTableFilters = ({
  savedStreamsTableFiltersClean,
  savedStreamsFetch,
  project,
  filters,
  sizePerPage = 10,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClearFilters = () => {
    savedStreamsTableFiltersClean();
    savedStreamsFetch({ project_id: project.id, pagination: { sizePerPage, page: 1, sortField: 'published_at' } });
  };

  const isClearButtonVisible = Object.values(filters).filter(Boolean);

  return (
    <>
      <SavedStreamsAddVideoButton />
      {isClearButtonVisible.length ? (
        <ButtonIcon
          icon="clear"
          transform="shrink-3 down-2"
          color="secondary"
          size="sm"
          className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
          onClick={handleClearFilters}
        >
          Clear filters
        </ButtonIcon>
      ) : null}
      <ButtonIcon
        icon="filter"
        transform="shrink-3 down-2"
        color="secondary"
        size="sm"
        className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
        onClick={() => setModalOpen(true)}
      >
        {translate('common.filters')}
      </ButtonIcon>
      <SavedStreamsFiltersModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getSavedStreamsFilters(state),
  project: getCurrentProject(state),
  sizePerPage: getSavedStreamsPageSize(state),
});

export default connect(mapStateToProps, { savedStreamsFetch, savedStreamsTableFiltersClean })(SavedStreamsTableFilters);
