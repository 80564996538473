import { createAction } from 'redux-actions';

import {
  REPORTS_CLEAN,
  REPORTS_SCREENSHOT_ADD_HIGHLIGHTED_ARTICLE,
  REPORTS_SCREENSHOT_REMOVE_HIGHLIGHTED_ARTICLE,
  REPORTS_SCREENSHOT_CLEAR,
  API_CALL,
  REPORT_VIDEOS_CLEAN,
  REPORTS_FETCH_VIDEOS,
  REPORTS_STREAMS_CLEAN,
  REPORTS_FETCH_STREAMS,
} from './actionTypes';
import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';
import urlSearchParamsBuilder from '../helpers/urlSearchParamsBuilder';

export const REQUEST_REPORTS = 'REQUEST_REPORTS';
export const REJECT_REPORTS = 'REJECT_REPORTS';
export const RECEIVE_REPORTS = 'RECEIVE_REPORTS';
export const REQUEST_REPORT = 'REQUEST_REPORT';
export const REJECT_REPORT = 'REJECT_REPORT';
export const RECEIVE_REPORT = 'RECEIVE_REPORT';
export const REQUEST_SAVE_REPORT = 'REQUEST_SAVE_REPORT';
export const REJECT_SAVE_REPORT = 'REJECT_SAVE_REPORT';
export const RECEIVE_SAVE_REPORT = 'RECEIVE_SAVE_REPORT';
export const RECEIVE_DELETED_REPORT = 'RECEIVE_DELETED_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const UPDATE_REPORT_RECEIVE = 'UPDATE_REPORT_RECEIVE';
export const UPDATE_REPORT_REJECT = 'UPDATE_REPORT_REJECT';
export const CHANGE_REPORT_NAME = 'CHANGE_REPORT_NAME';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const changeReportName = createAction(CHANGE_REPORT_NAME);
export const setReportData = createAction(SET_REPORT_DATA);

export const NEW_REPORT_SET_SELECTED_TAGS = 'NEW_REPORT_SET_SELECTED_TAGS';
export const NEW_REPORT_SET_SELECTED_LANGUAGES = 'NEW_REPORT@SET_SELECTED_LANGUAGE';
export const NEW_REPORT_SET_VIDEOS = 'NEW_REPORT@SET_VIDEOS';
export const NEW_REPORT_FETCH_SAVED_VIDEOS = 'NEW_REPORT@FETCH_SAVED_VIDEOS';
export const NEW_REPORT_FETCH_SAVED_VIDEOS_SUCCESS = 'NEW_REPORT@FETCH_SAVED_VIDEOS/SUCCESS';
export const NEW_REPORT_FETCH_SAVED_STREAMS = 'NEW_REPORT@FETCH_SAVED_STREAMS';
export const NEW_REPORT_FETCH_SAVED_STREAMS_SUCCESS = 'NEW_REPORT@FETCH_SAVED_STREAMS/SUCCESS';

export const newReportGetSavedVideos = ({ filters, pagination, projectId }) => {
  const endpoint = `saved_videos?project_id=${projectId}`;
  const savedVideosUrl = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: NEW_REPORT_FETCH_SAVED_VIDEOS,
      endpoint: savedVideosUrl,
      method: 'GET',
    },
  };
};

export const newReportGetSavedStreams = ({ filters, pagination, projectId }) => {
  const endpoint = `saved_streams?project_id=${projectId}`;
  const savedStreamsURL = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: NEW_REPORT_FETCH_SAVED_STREAMS,
      endpoint: savedStreamsURL,
      method: 'GET',
    },
  };
};

export const addHighlightedArticle = (articleId) => ({
  type: REPORTS_SCREENSHOT_ADD_HIGHLIGHTED_ARTICLE,
  payload: articleId,
});

export const removeHighlightedArticle = (article_id) => ({
  type: REPORTS_SCREENSHOT_REMOVE_HIGHLIGHTED_ARTICLE,
  payload: article_id,
});

export const clearHighlightedArticles = () => ({
  type: REPORTS_SCREENSHOT_CLEAR,
});

export const fetchReports = (projectId) =>
  apiFetch(
    'reports',
    {
      request: REQUEST_REPORTS,
      receive: RECEIVE_REPORTS,
      reject: REJECT_REPORTS,
    },
    {
      project_id: projectId,
      page: {
        page: 0,
        page_size: 50000,
      },
    },
  );

export const fetchReport = (reportId = 1) =>
  apiFetch(
    `reports/${reportId}`,
    {
      request: REQUEST_REPORT,
      receive: RECEIVE_REPORT,
      reject: REJECT_REPORT,
    },
    {},
  );

export const saveReport = (
  data = {
    name: 'Test Project Test',
    descriptionHTML: '<p></p>',
    section1TitleHTML: 'Key Metrics',
    section1SubtitleHTML: '',
    section2TitleHTML: 'Data Charts',
    section2SubtitleHTML: '',
    tableType: 0,
    customTableTemplate: '{{title}} | {{score}}',
    projectId: 2,
    status: 0,
    date_start: '2020-01-01',
    date_end: '2021-02-01',
    tags_not: [],
    languages_not: [],
    screenshots: [],
    videos: [],
    streams: [],
  },
) =>
  apiFetch(
    'reports',
    {
      request: {
        type: REQUEST_SAVE_REPORT,
        payload: data,
      },
      receive: RECEIVE_SAVE_REPORT,
      reject: REJECT_SAVE_REPORT,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'project',
          attributes: {
            name: data.name,
            descriptionHTML: data.descriptionHTML,
            'section-title-1': data.section1TitleHTML,
            'section-subtitle-1': data.section1SubtitleHTML,
            'section-title-2': data.section2TitleHTML,
            'section-subtitle-2': data.section2SubtitleHTML,
            table_type: data.tableType,
            table_template: data.customTableTemplate,
            status: data.status,
            date_start: data.date_start,
            date_end: data.date_end,
            screenshots: data.screenshots,
            videos: data.videos,
            saved_streams: data.streams,
          },
          relationships: {
            project: {
              data: {
                type: 'project',
                id: data.projectId,
              },
            },
            tag: {
              data: [], //If we include only those tags. Disabled for now (exclude only)
            },
            tags_not: {
              data: data.tags_not.map((tagId) => ({
                type: 'tags_not',
                id: tagId,
              })),
            },
            languages_not: {
              data: data.languages_not.map((langId) => ({
                type: 'languages_not',
                id: langId,
              })),
            },
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const deleteReport = (reportId = -1) => {
  const requestOptions = {
    method: 'DELETE',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };
  return apiFetch(
    'reports/' + reportId,
    {
      request: REQUEST_REPORT,
      receive: RECEIVE_DELETED_REPORT,
      reject: REJECT_REPORT,
    },
    { id: reportId },
    requestOptions,
  );
};

export const updateReportName = (reportId, newReportName) => {
  const requestOptions = {
    method: 'PATCH',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  const endpoint = `reports/${reportId}`;
  const requestParams = { body: JSON.stringify({ type: 'report', data: { attributes: { name: newReportName } } }) };
  const actions = { request: UPDATE_REPORT, receive: UPDATE_REPORT_RECEIVE, reject: UPDATE_REPORT_REJECT };

  return apiFetch(endpoint, actions, requestParams, requestOptions);
};

export const reportDetailsFetchVideos = ({ isPublicRoute, filters, pagination, report_id }) => {
  let endpoint = isPublicRoute ? `p/reports/${report_id}/videos` : `reports/${report_id}/videos`;
  const savedVideosUrl = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: REPORTS_FETCH_VIDEOS,
      endpoint: savedVideosUrl,
      method: 'GET',
    },
  };
};

export const reportDetailsFetchStreams = ({ isPublicRoute, filters, pagination, report_id }) => {
  let endpoint = isPublicRoute ? `p/reports/${report_id}/streams` : `reports/${report_id}/streams`;
  const savedStreamsURL = urlSearchParamsBuilder({ filters, pagination, endpoint });

  return {
    type: API_CALL,
    meta: {
      type: REPORTS_FETCH_STREAMS,
      endpoint: savedStreamsURL,
      method: 'GET',
    },
  };
};

export const reportsVideosClean = () => ({ type: REPORT_VIDEOS_CLEAN });
export const reportsStreamsClean = () => ({ type: REPORTS_STREAMS_CLEAN });

export const reportsClean = () => ({ type: REPORTS_CLEAN });
export const newReportSetSelectedTags = (tags) => ({
  type: NEW_REPORT_SET_SELECTED_TAGS,
  payload: tags,
});

export const newReportSetSelectedLanguages = (languages) => ({
  type: NEW_REPORT_SET_SELECTED_LANGUAGES,
  payload: languages,
});

export const newReportSetVideos = (videoIds) => ({
  type: NEW_REPORT_SET_VIDEOS,
  payload: videoIds,
});
