import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { hideModal } from '../../../actions/forms';
import { getTableFormFieldsById } from '../../../selectors/forms';
import { getCurrentProject } from '../../../selectors/projects';
import { onBulkApply } from '../tables/helpers/actions';

const tableId = 'articlesFeedTable';

const ImportFeedFooter = ({ onClose, onSubmit }) => (
  <Fragment>
    <Button onClick={onClose}>Close</Button>
    <Button onClick={onSubmit} color="primary">
      Start import!
    </Button>
  </Fragment>
);

const mapStateToProps = (state) => {
  const currentGlobalProject = getCurrentProject(state);
  return {
    feedActionForm: state.forms.feedActionForm.fields,
    isOpen: state.forms.modal.isVisible,
    contentId: state.forms.modal.contentId,
    alertId: currentGlobalProject['alert-id'] || -1,
    content: state.forms.modal.content,
    nonFiltersFields: getTableFormFieldsById(state, tableId),
  };
};
const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(hideModal());
  },
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: () => {
    onBulkApply(stateProps, dispatchProps, { tableId })[tableId]();
  },
});

ImportFeedFooter.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ImportFeedFooter);
