import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';

import { superSearchSetSearchTerm } from '../../../actions/superSearch';
import translate from '../../../helpers/translate';

const normalizeQuotes = (text) => text.replace(/[“”]/g, '"').replace(/[‘’]/g, '\'');

const SuperSearchFiltersSearchterm = ({ superSearchSetSearchTerm }) => {
  const handleInputChange = (event) => {
    const normalizedValue = normalizeQuotes(event.target.value);
    superSearchSetSearchTerm(normalizedValue);
  };

  return (
    <>
      <Label>{translate('pages.supersearch.filters.search_term')}</Label>
      <Input
        aria-label="search-term-input"
        placeholder={translate('pages.supersearch.filters.search_term_example')}
        type="text"
        onChange={handleInputChange}
      />
    </>
  );
};

SuperSearchFiltersSearchterm.propTypes = {
  superSearchSetSearchTerm: PropTypes.func,
};

export default connect(null, {
  superSearchSetSearchTerm,
})(SuperSearchFiltersSearchterm);
