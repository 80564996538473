// Constantes para strings repetidos
const QUERY_TYPE = {
  ALL: 'all',
  ANY: 'any',
};

// Expresiones regulares pre-compiladas
const COMPLEX_PATTERNS = [
  /(\+\s*\([^)]+\)\s*){2,}/,
  /(-\s*\([^)]+\)\s*){2,}/,
  /\+("[^"]+"|\S+)\s*\+\s*\(([^)]+)\)/,
  /-\(.*OR.*\)/,
  /\+\s*"[^"]+"\s*-\s*\([^)]+\)\s*\+\s*\(/,
  /(\sOR\s.*\sAND\s)|(\sAND\s.*\sOR\s)/,
];
const TERM_PATTERN = /([+-])?\s*"([^"]+)"|([+-])\(\s*"([^"]+)"(?:\s*,\s*"([^"]+)")*\s*\)/g;
const INDIVIDUAL_TERM_PATTERN = /([+-])"([^"]+)"/g;
const GROUPED_TERM_PATTERN = /([+-])\(([^)]+)\)/g;

/**
 * Processes search query terms by trimming whitespace.
 * @param {string|string[]} searchQuery - The search query or array of query terms.
 * @returns {string[]} An array of processed terms.
 * @throws {Error} If the input is neither a string nor an array.
 */
const processTerms = (searchQuery) => {
  if (typeof searchQuery === 'string') {
    return searchQuery.split(',').map((term) => term.trim());
  }
  if (Array.isArray(searchQuery)) {
    return searchQuery.map((term) => term.trim());
  }
  throw new Error('Invalid input: searchQuery must be a string or an array');
};

/**
 * Creates a query string where all terms are either required or excluded.
 * @param {string|string[]} searchQuery - The search query or array of query terms.
 * @param {boolean} isExclude - Flag indicating whether the terms should be excluded.
 * @returns {string} A formatted query string.
 */
const createAllQuery = (searchQuery, isExclude) => {
  const terms = processTerms(searchQuery);

  if (terms.length === 0) {
    return '';
  }

  if (terms.length === 1) {
    return `${isExclude ? '-' : '+'}${quoteTermIfNeeded(terms[0])}`;
  }

  const prefix = isExclude ? '-' : '+';
  return terms.map((term) => `${prefix}${quoteTermIfNeeded(term)}`).join(' ');
};

/**
 * Creates a query where any of the specified search terms are sufficient for a match.
 * @param {string|string[]} searchQuery - The search query or array of search terms.
 * @param {boolean} isExclude - Indicates if the terms should be excluded from the search results.
 * @returns {string} A formatted query string.
 */
const createAnyQuery = (searchQuery, isExclude) => {
  const terms = processTerms(searchQuery);

  if (terms.length === 0) {
    return '';
  }

  if (terms.length === 1) {
    return `${isExclude ? '-' : '+'}${quoteTermIfNeeded(terms[0])}`;
  }

  const quotedTerms = terms.map(quoteTermIfNeeded);
  const prefix = isExclude ? '-' : '+';
  return `${prefix}(${quotedTerms.join(', ')})`;
};

/**
 * Automatically wraps each term from the input search query in double quotes if needed.
 * @param {string} searchQuery - The input search query.
 * @returns {string} A new string with properly quoted terms.
 */
const autoQuoteTerms = (searchQuery) => {
  const terms = processTerms(searchQuery);
  return terms.map(quoteTermIfNeeded).join(', ');
};

/**
 * Quotes a term if it's not already quoted.
 * @param {string} term - The term to quote.
 * @returns {string} The quoted term.
 */
const quoteTermIfNeeded = (term) => (term.startsWith('"') && term.endsWith('"') ? term : `"${term}"`);

/**
 * Formats a search query based on specified criteria.
 * @param {string|string[]} searchQuery - The original search query or an array of search terms.
 * @param {string|null} type - The type of query to create ('all', 'any', or null).
 * @param {boolean} isExclude - Indicates whether the terms should be formatted for exclusion.
 * @returns {string} A formatted search query string.
 */
export const queryBuilderFormatter = (searchQuery, type = null, isExclude = false) => {
  if (!searchQuery || (Array.isArray(searchQuery) && searchQuery.length === 0)) {
    return '';
  }

  switch (type) {
    case QUERY_TYPE.ALL:
      return createAllQuery(searchQuery, isExclude);
    case QUERY_TYPE.ANY:
      return createAnyQuery(searchQuery, isExclude);
    default:
      return autoQuoteTerms(searchQuery);
  }
};

/**
 * Processes grouped terms by splitting, trimming, and removing quotes.
 * @param {string} terms - Comma-separated terms.
 * @returns {string[]} An array of processed terms.
 */
const processGroupedTerms = (terms) => {
  if (!terms) return [];
  return terms
    .split(',')
    .map((term) => term.trim())
    .map((term) => term.replace(/^"|"$/g, ''));
};

/**
 * Determines if a query is advanced based on complex patterns.
 * @param {string} str - The query string.
 * @returns {boolean} True if the query is advanced, false otherwise.
 */
const getIsAdvancedQuery = (str) => COMPLEX_PATTERNS.some((pattern) => pattern.test(str));

/**
 * Extracts terms from a simple query.
 * @param {string} str - The query string.
 * @returns {string[]} An array of terms.
 */
const getTermsFromSimpleQuery = (str) => {
  const matches = str.match(/"([^"]+)"/g);
  return matches ? matches.map((term) => term.replace(/^"|"$/g, '')) : [];
};

/**
 * Extracts terms from an OR query.
 * @param {string} str - The query string.
 * @returns {string[]} An array of terms.
 */
const getTermsFromORQuery = (str) =>
  str.split(/\s+OR\s+/).flatMap((term) => term.split(/"\s*"\s*/).map((t) => t.replace(/^"|"$/g, '').trim()));

/**
 * Extracts terms from an AND query.
 * @param {string} str - The query string.
 * @returns {string[]} An array of terms.
 */
const getTermsFromANDQuery = (str) => str.split(/\s+AND\s+/).map((term) => term.replace(/^"|"$/g, ''));

/**
 * Reverses a query string to an object representation.
 * @param {string} str - The query string to reverse.
 * @returns {Object} An object representation of the query.
 */
export const reverseQueryToObject = (str) => {
  if (!str) {
    return {
      include: [],
      exclude: [],
      includeType: QUERY_TYPE.ALL,
      excludeType: QUERY_TYPE.ALL,
      isAdvanced: false,
    };
  }

  const hasGroupedTermsWithPlus = /\+\s*\(/.test(str);
  const multiplePlusesOutsideGroups = (str.match(/\+/g) || []).length > 1;

  const isAdvanced = getIsAdvancedQuery(str) || (hasGroupedTermsWithPlus && multiplePlusesOutsideGroups);
  const hasBothOrAnd = str.includes(' OR ') && str.includes(' AND ');
  const hasOrAndSymbols = str.includes(' OR ') && (str.includes('+(') || str.includes('-('));

  if (isAdvanced || hasBothOrAnd || hasOrAndSymbols) {
    return {
      include: [],
      exclude: [],
      includeType: QUERY_TYPE.ALL,
      excludeType: QUERY_TYPE.ALL,
      isAdvanced: true,
    };
  }

  let includeTerms = [];
  let excludeTerms = [];
  let includeType = QUERY_TYPE.ALL;
  let excludeType = QUERY_TYPE.ALL;

  // Extract terms using regex
  Array.from(str.matchAll(TERM_PATTERN)).forEach((match) => {
    const [, individualPrefix, individualTerm, groupedPrefix, ...groupedTerms] = match.filter((m) => m !== undefined);

    if (individualTerm) {
      (individualPrefix === '-' ? excludeTerms : includeTerms).push(individualTerm);
    } else if (groupedTerms.length) {
      const processedTerms = groupedTerms.map((term) => term.replace(/^"|"$/g, ''));
      (groupedPrefix === '-' ? excludeTerms : includeTerms).push(...processedTerms);
    }
  });

  // Determine query type
  if (/^"([^"]+)"(\s+"([^"]+)")*$/.test(str)) {
    includeTerms = getTermsFromSimpleQuery(str);
  } else if (str.includes(' OR ')) {
    includeTerms = getTermsFromORQuery(str);
    includeType = QUERY_TYPE.ANY;
  } else if (str.includes(' AND ')) {
    includeTerms = getTermsFromANDQuery(str);
    includeType = QUERY_TYPE.ALL;
  }

  // Process individual terms
  str.replace(INDIVIDUAL_TERM_PATTERN, (_, prefix, term) => {
    (prefix === '+' ? includeTerms : excludeTerms).push(term);
  });

  // Process grouped terms
  str.replace(GROUPED_TERM_PATTERN, (_, prefix, terms) => {
    const reversedTerms = processGroupedTerms(terms);
    if (prefix === '+') {
      includeTerms = includeTerms.concat(reversedTerms);
      includeType = QUERY_TYPE.ANY;
    } else {
      excludeTerms = excludeTerms.concat(reversedTerms);
      excludeType = QUERY_TYPE.ANY;
    }
  });

  return {
    include: [...new Set(includeTerms)],
    exclude: [...new Set(excludeTerms)],
    includeType,
    excludeType,
    isAdvanced,
  };
};
