import { connect } from 'react-redux';

import BarChart from './BarChart';
import { changeChart } from '../../../actions/modules';
import { getArticleLangChart } from '../../../selectors/modules';
import { getArticlesLangChart, getArticlesReportLangChart } from '../../../selectors/savedCoverage/articles';

const mapStateToProps = (state, ownProps) => {
  const groups = state.articles.groups;
  const reachValues = getArticleLangChart(state).reach_values;
  const visitsValues = getArticleLangChart(state).visits_values;
  const articlesValues = getArticleLangChart(state).articles_values;

  const renderPointFormat = () => {
    if (reachValues) {
      return '{point.y:.1f} score<br/>';
    }
    if (visitsValues) {
      return '{point.y:,.0f} visits<br/>';
    }
    return '{point.y:.0f} articles<br/>';
  };
  return {
    title: 'Languages',
    reachValues,
    visitsValues,
    articlesValues,
    isLoading: groups.isFetching,
    config: {
      chart: {
        type: 'column',
      },
      title: {
        align: 'left',
        text: 'Languages',
        style: {
          fontSize: '1.563rem',
        },
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Nº of articles',
          style: {
            fontFamily: 'Jost Medium',
            fontWeight: '600',
            fontSize: '0.75rem',
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: reachValues ? '{point.y:.1f}' : '{point.y:,.0f}',
          },
        },
      },
      tooltip: {
        pointFormat: renderPointFormat(),
        style: {
          color: 'white',
        },
      },

      series: [
        {
          name: 'Languages',
          colorByPoint: true,
          data: ownProps.reportId ? getArticlesReportLangChart(state, ownProps) : getArticlesLangChart(state),
        },
      ],
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  reachToggle: () => {
    dispatch(changeChart('articlesLangChart', { reach_values: true, articles_values: false, visits_values: false }));
  },
  articlesToggle: () => {
    dispatch(changeChart('articlesLangChart', { reach_values: false, articles_values: true, visits_values: false }));
  },
  visitsToggle: () => {
    dispatch(changeChart('articlesLangChart', { reach_values: false, articles_values: false, visits_values: true }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BarChart);
