import { RECEIVE_TAGS, REQUEST_TAGS, REJECT_TAGS, REQUEST_SAVE_TAG, RECEIVE_SAVE_TAG } from '../../actions/tags';
import { apiReceive, apiReceiveSaved, apiRequest, apiReject } from '../../config/api-service';

const initialState = {
  items: {},
  itemsStatus: {},
  lists: {},
  listsStatus: {},
  stats: {},
};

const newTags = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TAGS:
    case REQUEST_SAVE_TAG:
      return apiRequest(state, action);
    case RECEIVE_TAGS:
      return apiReceive(state, action);
    case REJECT_TAGS:
      return apiReject(state, action);
    case RECEIVE_SAVE_TAG:
      return apiReceiveSaved(state, action);
    default:
      return Object.assign({}, state);
  }
};

export default newTags;
