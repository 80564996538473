import { YOUTUBE_FEED_CLEAN, YOUTUBE_FEED_SAVE_VIDEOS_SHOW_MODAL } from '../../../actions/actionTypes';

const defaultState = false;

const showModal = (state = defaultState, action) => {
  switch (action.type) {
    case YOUTUBE_FEED_SAVE_VIDEOS_SHOW_MODAL:
      return action.payload;
    case YOUTUBE_FEED_CLEAN:
      return state;
    default:
      return defaultState;
  }
};

export default showModal;
