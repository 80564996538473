import React from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { websitesOpenModal } from '../../../../actions/websites';
import translate from '../../../../helpers/translate';
import ButtonIcon from '../../../common/ButtonIcon';

const AddWebsiteButton = ({ websitesOpenModal }) => {
  const handleClick = () => {
    websitesOpenModal();
  };

  return (
    <ButtonIcon
      color="primary"
      icon={faPlusCircle}
      size="sm"
      className="mr-3 form-inline d-none d-md-inline-block text-uppercase"
      onClick={handleClick}
    >
      {translate('websites.add_website')}
    </ButtonIcon>
  );
};

AddWebsiteButton.propTypes = {
  websitesOpenModal: PropTypes.func,
};

AddWebsiteButton.propTypes = {
  websitesOpenModal: () => {},
};

export default connect(null, { websitesOpenModal })(AddWebsiteButton);
