import React from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import uuid from 'uuid/v4';

import ArticlesReportTable from '../tables/ArticlesReportTable';
import SimpleArticlesReportTable from '../tables/SimpleArticlesReportTable';

const ReportTableDisplay = ({ reportId, currentReport }) => {
  const urlParams = useParams();

  if (currentReport['table-type'] === 0) {
    return <ArticlesReportTable urlParams={urlParams} reportId={reportId} key={uuid()} />;
  }

  if (currentReport['table-type'] === 1) {
    return (
      <SimpleArticlesReportTable urlParams={urlParams} reportId={reportId} template={currentReport['table-template']} />
    );
  }

  return <ArticlesReportTable urlParams={urlParams} reportId={reportId} key={uuid()} />;
};

ReportTableDisplay.propTypes = {
  reportId: PropTypes.string,
  currentReport: PropTypes.shape({
    'table-template': PropTypes.string,
    'table-type': PropTypes.string,
  }),
};

export default ReportTableDisplay;
