import React from 'react';

import { Col, Row } from 'reactstrap';

import {
  dateFormatter,
  formatHTMLSymbols,
  youtubeChannelFormatter,
} from '../../../../components/ICO/tables/helpers/formatters';
import { largeNumberFormat } from '../../../../config/url-utils';
import translate from '../../../../helpers/translate';

const YoutubeFeedTableVideoPreviewModalBody = ({ videoData }) => (
  <>
    <Row>
      <Col>
        <iframe
          width="100%"
          height="450"
          src={`https://www.youtube.com/embed/${videoData.youtube_video_id}`}
          allowFullScreen
          title="Embedded youtube"
          className="border-0"
        />
      </Col>
    </Row>
    <Row>
      <Col className="mt-2">
        <h5 className="text-bold">{formatHTMLSymbols(videoData?.title, true)}</h5>
      </Col>
    </Row>
    <Row>
      <Col className="text-gray">
        <span>{dateFormatter(videoData?.published_at)}</span>
      </Col>
    </Row>
    <Row>
      <Col className="mt-3">
        {videoData.channel ? (
          <div className="d-flex flex-row">
            <div className="mr-2">
              <a href={`https://youtube.com/${videoData?.channel?.custom_url}`}>
                {youtubeChannelFormatter(
                  videoData.channel?.title,
                  videoData.channel?.thumbnail,
                  videoData.channel?.custom_url,
                )}
              </a>
            </div>
            <span className="text-gray">
              {largeNumberFormat(videoData?.channel?.stats[0]?.subscribers)} subscribers
            </span>
          </div>
        ) : (
          translate('pages.youtube_feed.modals.preview.information_not_found')
        )}
      </Col>
    </Row>
    <hr className="divider" />
    <Row>
      <Col className="mt-2">
        <p>{videoData?.description ?? translate('pages.youtube_feed.modals.preview.information_not_found')}</p>
      </Col>
    </Row>
  </>
);

export default YoutubeFeedTableVideoPreviewModalBody;
