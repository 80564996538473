import React, { useState } from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import TwitchFeedTableVideoPreviewModal from './Modals/Preview/TwitchFeedTableVideoPreviewModal.jsx';
import { formatHTMLSymbols } from '../../../components/ICO/tables/helpers/formatters';

const TwitchFeedTableVideoPreview = ({ videoData }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const tooltipId = `tooltip_${videoData.id}`;

  return (
    <>
      <div className="cursor-pointer text-truncate" onClick={() => setModalOpen(true)} style={{ width: '25vw' }}>
        <span id={tooltipId}>{formatHTMLSymbols(videoData?.video?.title)}</span>
      </div>
      <UncontrolledTooltip placement="bottom" target={tooltipId}>
        {videoData?.video?.title}
      </UncontrolledTooltip>
      <TwitchFeedTableVideoPreviewModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        videoData={videoData}
      />
    </>
  );
};

export default TwitchFeedTableVideoPreview;
