import {
  MEDIATYPES_FETCH_MEDIATYPES_FAILURE,
  MEDIATYPES_FETCH_MEDIATYPES_SUCCESS,
  MEDIATYPES_FETCH_MEDIATYPES_ERROR,
  MEDIATYPES_FETCH_MEDIATYPES,
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: [],
  error: null,
};

const mediaTypes = (state = initialState, action) => {
  switch (action.type) {
    case MEDIATYPES_FETCH_MEDIATYPES:
      return { ...state, isLoading: true };
    case MEDIATYPES_FETCH_MEDIATYPES_SUCCESS: {
      const response = action.payload.data.map((item) => item.attributes);
      return { ...state, isLoading: false, response };
    }
    case MEDIATYPES_FETCH_MEDIATYPES_FAILURE:
    case MEDIATYPES_FETCH_MEDIATYPES_ERROR:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default mediaTypes;
