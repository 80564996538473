import React from 'react';

import ceil from 'lodash/ceil';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';

import ButtonIcon from '../../common/ButtonIcon';
import { CustomDropDownTotal } from '../tables/CustomDropDownTotal';
import { CustomDropDownTotalStatic } from '../tables/CustomDropDownTotalStatic';
import '../../../assets/scss/theme/Footprints/components/_pagination.scss';

const Pagination = ({
  lastIndex,
  paginationProps,
  rows,
  viewAll,
  dropdownType,
  onChange,
  rowsPerPageList,
  rowsPerPage,
}) => {
  const handleFirstPage =
    ({ onPageChange }) =>
      () => {
        onPageChange(1);
      };

  const handleLastPage =
    ({ totalSize, sizePerPage, onPageChange }) =>
      () => {
        const lastPage = ceil(totalSize / sizePerPage);
        onPageChange(lastPage);
      };

  const handleNextPage =
    ({ page, onPageChange }) =>
      () => {
        onPageChange(page + 1);
      };

  const handlePrevPage =
    ({ page, onPageChange }) =>
      () => {
        onPageChange(page - 1);
      };

  const handleViewAll = ({ onSizePerPageChange }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, 1);
  };

  const renderDropdown = () =>
    dropdownType === 'static' ? (
      <CustomDropDownTotalStatic {...paginationProps} />
    ) : (
      <CustomDropDownTotal
        {...paginationProps}
        lastIndex={lastIndex}
        rowsPerPage={rowsPerPage}
        rowsPerPageList={rowsPerPageList}
        onChange={onChange}
      />
    );

  const isButtonDisabled =
    lastIndex >= paginationProps.totalSize || paginationProps.dataSize < paginationProps.totalSize;

  return (
    <Row noGutters className="px-1 py-3 fp-pagination">
      <Col className="pl-3 fs--1">
        {renderDropdown()}
        {viewAll && (
          <>
            —{' '}
            <ButtonIcon
              aria-label="view-all-button"
              color="link"
              size="sm"
              icon="chevron-right"
              iconAlign="right"
              transform="down-1 shrink-4"
              className="px-0 font-weight-semi-bold"
              onClick={() => handleViewAll(paginationProps, rows.length)}
            >
              view all
            </ButtonIcon>
          </>
        )}
      </Col>
      <Col xs="auto" className="pr-3">
        <Button
          aria-label="first-page-button"
          color="secondary"
          size="sm"
          onClick={handleFirstPage(paginationProps)}
          disabled={paginationProps.page === 1}
          className="fp-pagination-first-page"
        >
          First
        </Button>
        {paginationProps.page === 1 ? (
          <></>
        ) : (
          <Button
            aria-label="prev-page-button"
            color="primary"
            size="sm"
            onClick={handlePrevPage(paginationProps)}
            className="fp-pagination-prev-button ml-2"
          >
            {paginationProps.page - 1}
          </Button>
        )}
        <Button color="primary" size="sm" disabled={false} className="fp-pagination-active-button ml-2">
          {paginationProps.page}
        </Button>
        {paginationProps.page === paginationProps.totalSize ? (
          <></>
        ) : (
          <Button
            aria-label="next-page-button"
            size="sm"
            color="primary"
            onClick={handleNextPage(paginationProps)}
            disabled={isButtonDisabled}
            className="fp-pagination-next-page ml-2"
          >
            {paginationProps.page + 1}
          </Button>
        )}
        <Button
          aria-label="last-page-button"
          color="secondary"
          size="sm"
          onClick={handleLastPage(paginationProps)}
          disabled={isButtonDisabled}
          className="fp-pagination-last-page px-4 ml-2"
        >
          Last
        </Button>
      </Col>
    </Row>
  );
};

Pagination.propTypes = {
  paginationProps: PropTypes.shape({
    alwaysShowAllBtns: PropTypes.bool,
    bootstrap4: PropTypes.bool,
    custom: PropTypes.bool,
    dataSize: PropTypes.number,
    firstPageText: PropTypes.string,
    firstPageTitle: PropTypes.string,
    hidePageListOnlyOnePage: PropTypes.bool,
    hideSizePerPage: PropTypes.bool,
    lastPageText: PropTypes.string,
    lastPageTitle: PropTypes.string,
    nextPageText: PropTypes.string,
    nextPageTitle: PropTypes.string,
    onPageChange: PropTypes.func,
    onSizePerPageChange: PropTypes.func,
    page: PropTypes.number,
    pageButtonRenderer: PropTypes.func,
    pageListRenderer: PropTypes.func,
    pageStartIndex: PropTypes.number,
    paginationSize: PropTypes.number,
    paginationTotalRenderer: PropTypes.func,
    prePageText: PropTypes.string,
    prePageTitle: PropTypes.string,
    showTotal: PropTypes.bool,
    sizePerPage: PropTypes.number,
    sizePerPageList: PropTypes.arrayOf(PropTypes.number),
    sizePerPageOptionRenderer: PropTypes.func,
    sizePerPageRenderer: PropTypes.func,
    tableId: PropTypes.number,
    totalSize: PropTypes.number,
    withFirstAndLast: PropTypes.bool,
  }).isRequired,
  lastIndex: PropTypes.number.isRequired,
  viewAll: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      actions: PropTypes.element,
      clientName: PropTypes.string,
      date: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      image: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  dropdownType: PropTypes.oneOf(['static', 'dynamic']).isRequired,
  onPageSizeChange: PropTypes.func,
  rowsPerPageList: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;
