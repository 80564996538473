import React from 'react';

import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, CardImg, CardImgOverlay, UncontrolledTooltip } from 'reactstrap';

import { colorScore, largeNumberFormat } from '../../../config/url-utils';
import translate from '../../../helpers/translate';
import ImagePreviewer from '../ImagePreviewer/ImagePreviewer';
import '../../../assets/scss/theme/Footprints/components/_badges.scss';
import { langFormatter } from '../tables/ArticlesFeedTable';

const HighlightedArticleImage = ({ screenshot, articleData }) => {
  const reachTooltipId = `reachTooltip${articleData.id}`;
  const umvTooltipId = `umvTooltip${articleData.id}`;
  return screenshot ? (
    <ImagePreviewer image={screenshot} isBlob>
      <div className="position-relative overflow-hidden img-fluid rounded-top">
        <CardImg top className="card-img-top" src={`data:image/png;base64,${screenshot}`} alt="Article screenshot" />
        <CardImgOverlay className="card-img-overlay d-flex align-items-end justify-content-end p-3">
          {articleData['website-visits'] && (
            <>
              <UncontrolledTooltip placement="bottom" target={umvTooltipId}>
                {translate('common.umv_explained')}
              </UncontrolledTooltip>
              <Badge id={umvTooltipId} className="website-visits mr-1 rounded-capsule">
                {largeNumberFormat(articleData['website-visits'])}
              </Badge>
            </>
          )}
          <UncontrolledTooltip placement="bottom" target={reachTooltipId}>
            {translate('common.media_score_explained')}
          </UncontrolledTooltip>
          <Badge id={reachTooltipId} color={colorScore(articleData.reach)} className="mr-1 rounded-capsule">
            <FontAwesomeIcon icon={faTrophy} /> {articleData.reach}
          </Badge>
          {articleData.language && langFormatter(articleData.language)}
        </CardImgOverlay>
      </div>
    </ImagePreviewer>
  ) : null;
};

export default HighlightedArticleImage;
