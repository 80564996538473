import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import SavedStreamsFiltersModalBody from './SavedStreamsFiltersModalBody.jsx';
import SavedStreamsFiltersModalFooter from './SavedStreamsFiltersModalFooter.jsx';
import translate from '../../../../helpers/translate';

const SavedStreamsFiltersModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
    <ModalHeader className="fp-modal-header--small">{translate('pages.saved_streams.table.filters.title')}</ModalHeader>
    <ModalBody className="fp-modal-body">
      <SavedStreamsFiltersModalBody />
    </ModalBody>
    <ModalFooter>
      <SavedStreamsFiltersModalFooter onClose={onClose} />
    </ModalFooter>
  </Modal>
);

export default SavedStreamsFiltersModal;
