import React from 'react';

import { Col, Row } from 'reactstrap';

import SavedStreamsFiltersModalFilterDates from './SavedStreamsFiltersModalBodyDates.jsx';
import SavedStreamsFiltersModalBodyLanguages from './SavedStreamsFiltersModalBodyLanguages.jsx';
import SavedStreamsFiltersModalBodyMaxFollowers from './SavedStreamsFiltersModalBodyMaxFollowers.jsx';
import SavedStreamsFiltersModalBodyMinFollowers from './SavedStreamsFiltersModalBodyMinFollowers.jsx';
import SavedStreamsFiltersModalFilterTags from './SavedStreamsFiltersModalBodyTags.jsx';

const SavedStreamsFiltersModalBody = () => (
  <>
    <Row>
      <Col xs={12}>
        <SavedStreamsFiltersModalBodyLanguages />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <SavedStreamsFiltersModalFilterTags />
      </Col>
    </Row>
    <Row>
      <Col xs={6}>
        <SavedStreamsFiltersModalBodyMinFollowers />
      </Col>
      <Col xs={6}>
        <SavedStreamsFiltersModalBodyMaxFollowers />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <SavedStreamsFiltersModalFilterDates />
      </Col>
    </Row>
  </>
);

export default SavedStreamsFiltersModalBody;
