import React from 'react';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import PropTypes from 'prop-types';

import { STATUS_LOADING } from '../../../../actions/forms';
import ButtonIcon from '../../../common/ButtonIcon';

const ExportCSVButton = ({ onExport, status, rows }) => {
  const handleClick = () => {
    onExport();
  };

  const isLoading = status === STATUS_LOADING;
  const displayText = isLoading ? 'Exporting...' : 'Export';
  const isDisabled = status === STATUS_LOADING || !rows.length;

  return (
    <ButtonIcon
      disabled={isDisabled}
      aria-label="export-button"
      className="ml-3 text-uppercase fp-button"
      onClick={handleClick}
      icon={faExternalLinkAlt}
      transform="shrink-3 down-2"
      color="secondary"
      size="sm"
    >
      {displayText}
    </ButtonIcon>
  );
};

ExportCSVButton.propTypes = {
  onExport: PropTypes.func,
  status: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape()),
};

ExportCSVButton.defaultProps = {
  onExport: () => {},
  status: '',
  rows: [],
};

export default ExportCSVButton;
