import React, { memo, useState } from 'react';

import { Col, FormGroup, Row } from 'reactstrap';

import { useGetArticlesCount } from './useGetArticlesCount';
import { queryBuilderFormatter } from '../../../helpers/queryBuilderFormatter';
import QueryBuilderExcludeInput from '../QueryBuilderProjects/QueryBuilderProjectsExcludeInput.jsx';
import QueryBuilderIncludeInput from '../QueryBuilderProjects/QueryBuilderProjectsIncludeInput.jsx';

const QueryBuilderProjects = ({ translatedKeyword, stateAction, currentInput }) => {
  const { mutate, data } = useGetArticlesCount();

  const [searchQuery, setSearchQuery] = useState({
    includeQuery: translatedKeyword?.include ?? null,
    excludeQuery: translatedKeyword?.exclude ?? null,
  });

  const [includeQueryType, setIncludeQueryType] = useState(translatedKeyword.includeType ?? 'all');
  const [excludeQueryType, setExcludeQueryType] = useState(translatedKeyword.excludeType ?? 'all');
  const [isAdvancedMode, setIsAdvancedMode] = useState(translatedKeyword.isAdvanced ?? false);

  const updateSearchQueries = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    handleQueryChange(newSearchQuery, includeQueryType, excludeQueryType);
  };

  const handleQueryChange = (searchQuery, includeQueryType, excludeQueryType) => {
    if (searchQuery.includeQuery && !searchQuery.excludeQuery) {
      const formattedIncluded = queryBuilderFormatter(searchQuery.includeQuery, includeQueryType);
      stateAction(formattedIncluded);
      mutate(formattedIncluded);
    } else if (!searchQuery.includeQuery && searchQuery.excludeQuery) {
      const formattedExcluded = queryBuilderFormatter(searchQuery.excludeQuery, excludeQueryType, true);
      stateAction(formattedExcluded);
      mutate(formattedExcluded);
    } else if (searchQuery.includeQuery && searchQuery.excludeQuery) {
      const formattedIncluded = queryBuilderFormatter(searchQuery.includeQuery, includeQueryType);
      const formattedExcluded = queryBuilderFormatter(searchQuery.excludeQuery, excludeQueryType, true);
      const formattedQuery = `${formattedIncluded} ${formattedExcluded}`;
      stateAction(formattedQuery);
      mutate(formattedQuery);
    }
  };

  return (
    <FormGroup>
      <Row>
        <Col xs={12} md={12}>
          <div className="d-flex justify-content-end">
            <span onClick={() => setIsAdvancedMode(!isAdvancedMode)} className="fs--1 cursor-pointer text-primary ">
              Toggle {isAdvancedMode ? 'simple' : 'advanced'} query mode
            </span>
          </div>
        </Col>
      </Row>
      {!isAdvancedMode ? (
        <>
          <Row>
            <Col xs={12} md={6}>
              <QueryBuilderIncludeInput
                searchQuery={searchQuery}
                setSearchQuery={updateSearchQueries}
                queryType={includeQueryType}
                setQueryType={(newType) => {
                  setIncludeQueryType(newType);
                  handleQueryChange(searchQuery, newType, excludeQueryType);
                }}
              />
            </Col>
            <Col xs={12} md={6}>
              <QueryBuilderExcludeInput
                searchQuery={searchQuery}
                setSearchQuery={updateSearchQueries}
                queryType={excludeQueryType}
                setQueryType={(newType) => {
                  setExcludeQueryType(newType);
                  handleQueryChange(searchQuery, includeQueryType, newType);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="text-gray fs--1">
                {data?.data ? (
                  <>
                    Based on our data from the last two months, you can expect to find approximately{' '}
                    <strong>{data.data.count} articles</strong> related to your search.
                  </>
                ) : null}
              </span>
            </Col>
          </Row>
        </>
      ) : (
        currentInput
      )}
    </FormGroup>
  );
};

export default memo(QueryBuilderProjects);
