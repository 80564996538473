import React, { useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import YoutubeFeedKeywordModalBody from './YoutubeFeedKeywordModalBody.jsx';
import YoutubeFeedKeywordModalFooter from './YoutubeFeedKeywordModalFooter.jsx';
import translate from '../../../../helpers/translate';

const YoutubeFeedKeywordModal = ({ isOpen, onClose, project }) => {
  const [keyword, setKeyword] = useState(null);

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
      <ModalHeader className="fp-modal-header--small">
        {project['youtube-search-id']
          ? translate('pages.youtube_feed.edit_feed')
          : translate('pages.youtube_feed.create_feed')}
      </ModalHeader>
      <ModalBody className="fp-modal-body">
        <YoutubeFeedKeywordModalBody keyword={keyword} setKeyword={setKeyword} />
      </ModalBody>
      <ModalFooter>
        <YoutubeFeedKeywordModalFooter onClose={onClose} keyword={keyword} />
      </ModalFooter>
    </Modal>
  );
};

export default YoutubeFeedKeywordModal;
