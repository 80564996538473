import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import uuid from 'uuid/v1';

import HighlightedArticle from './HighlightedArticle';
import translate from '../../../helpers/translate';
import '../../../assets/scss/theme/Footprints/components/_highlighted-articles-section.scss';
import { getHighlightedArticlesSortedByReach } from '../../../selectors/reports';

const maximumCardsPerRow = 5;

const addPlaceholders = () => (
  <div className="margin-wrapper">
    <div className="highlighted-article-placeholder mt-3" key={uuid()} />
  </div>
);

const HighlightedArticlesSection = ({ isLoading, screenshots, highlightedArticlesSortedByReach }) => {
  const [cardsPerRow, setCardsPerRow] = useState(maximumCardsPerRow);
  const [showSecondRow, setShowSecondRow] = useState(false);
  const containerRef = useRef(null);

  const updateCardsPerRow = useCallback(() => {
    const screenWidth = window.innerWidth;
    const containerWidth = containerRef.current.offsetWidth;
    let cardWidth = 232;
    if (screenWidth > 1800) {
      cardWidth = 312;
    }

    const maxCardsPerRow = Math.min(maximumCardsPerRow, Math.floor(containerWidth / cardWidth));
    setCardsPerRow(maxCardsPerRow);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateCardsPerRow);
    updateCardsPerRow();
    return () => {
      window.removeEventListener('resize', updateCardsPerRow);
    };
  }, [updateCardsPerRow]);

  const generatePlaceholdersInRow = (rowsArray, cardsPerRow) =>
    rowsArray.map((row) => {
      if (row.length < cardsPerRow) {
        while (row.length < cardsPerRow) {
          row.push(null);
        }
      }
      return row;
    });

  const generateRows = useMemo(() => {
    const rowsWithoutPlaceholders = highlightedArticlesSortedByReach.reduce((rowsArray, currentElement, index) => {
      const rowIndex = Math.floor(index / cardsPerRow);
      if (!rowsArray[rowIndex]) {
        rowsArray[rowIndex] = [];
      }
      rowsArray[rowIndex].push(currentElement);
      return rowsArray;
    }, []);
    const rowsWithPlaceholders = generatePlaceholdersInRow(rowsWithoutPlaceholders, cardsPerRow);
    return showSecondRow ? rowsWithPlaceholders : rowsWithPlaceholders.slice(0, 1);
  }, [cardsPerRow, highlightedArticlesSortedByReach, showSecondRow]);

  const renderRows = () =>
    generateRows.map((row) => (
      <div className="card-row" key={uuid()}>
        {row.map((article) =>
          article ? (
            <HighlightedArticle
              isLoading={isLoading}
              highlightedArticle={article}
              screenshots={screenshots}
              key={uuid()}
            />
          ) : (
            addPlaceholders()
          ),
        )}
      </div>
    ));

  return (
    <>
      <h2 className="mb-3 title-style">{translate('reports.highlights')}</h2>
      <div className="card-wrapper" ref={containerRef}>
        {renderRows()}
      </div>
      <div>
        {cardsPerRow < highlightedArticlesSortedByReach.length && (
          <Container
            className="d-flex flex-column align-items-center justify-content-center text-primary"
            onClick={() => setShowSecondRow((prevState) => !prevState)}
            aria-label="container"
          >
            <Row>
              <Col className="d-flex flex-center justify-content-center">
                <div className="arrow-container mt-2 cursor-pointer">
                  <FontAwesomeIcon
                    className="chevron"
                    aria-label="icon"
                    icon={showSecondRow ? faChevronUp : faChevronDown}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>{showSecondRow ? translate('common.see_less') : translate('common.see_more')}</Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state, { highlightedArticles }) => ({
  highlightedArticlesSortedByReach: getHighlightedArticlesSortedByReach(state, highlightedArticles),
});

export default connect(mapStateToProps)(HighlightedArticlesSection);
