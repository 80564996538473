import React, { Component } from 'react';

import PropTypes from 'prop-types';

import highmapsWorldData from './worldmap';

// eslint-disable-next-line no-undef
const ReactHighmaps = require('react-highcharts/ReactHighmaps');

let maps = highmapsWorldData;
let data = [];
let data2 = [];
highmapsWorldData.features.map((feature) =>
  data.push({
    key: feature.properties['hc-key'],
    value: 0,
  }),
);
highmapsWorldData.features.map((feature, index) =>
  data2.push({
    key: feature.properties['hc-key'],
    value: index,
  }),
);

class MapChart extends Component {
  render() {
    let config = {
      chart: {
        spacingBottom: 20,
      },
      title: {
        text: this.props.title,
      },

      legend: {
        enabled: true,
        legendColor: 'red',
      },

      plotOptions: {
        map: {
          allAreas: false,
          joinBy: ['iso-a2', 'code'],
          dataLabels: {
            enabled: false,
            color: 'white',
            style: {
              fontWeight: 'bold',
            },
          },
          mapData: maps,
          tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}: <b>{point.value}</b> monthly visits',
          },
        },
      },
      colorAxis: {
        tickPixelInterval: 100,
      },
      series: [this.props.series],
    };
    return (
      <div>
        {/* eslint-disable-next-line react/no-string-refs */}
        <ReactHighmaps ref="linechart" config={config} />
      </div>
    );
  }
}

MapChart.propTypes = {
  title: PropTypes.string,
  isDatetime: PropTypes.bool,
  isLoading: PropTypes.bool,
  config: PropTypes.object,
  height: PropTypes.number,
  series: PropTypes.shape({}),
};

export default MapChart;
