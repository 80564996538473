import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import TwitchFeedTableFiltersModalBody from './TwitchFeedTableFiltersModalBody.jsx';
import TwitchFeedTableFiltersModalFooter from './TwitchFeedTableFiltersModalFooter.jsx';
import translate from '../../../../helpers/translate';

const TwitchFeedTableFiltersModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
    <ModalHeader className="fp-modal-header--small">{translate('pages.twitch_feed.table.filters.title')}</ModalHeader>
    <ModalBody className="fp-modal-body">
      <TwitchFeedTableFiltersModalBody />
    </ModalBody>
    <ModalFooter>
      <TwitchFeedTableFiltersModalFooter onClose={onClose} />
    </ModalFooter>
  </Modal>
);
export default TwitchFeedTableFiltersModal;
