import { API_CALL, FETCH_REGIONS } from './actionTypes';

export const fetchRegions = () => {
  const endpoint = 'regions?page[page]=0&page[page_size]=150';
  return {
    type: API_CALL,
    meta: {
      type: FETCH_REGIONS,
      endpoint,
      method: 'GET',
    },
  };
};
