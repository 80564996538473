import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import AddWebsiteModalBody from './AddWebsiteModalBody';
import { fetchWebsites, websitesAddWebsite, websitesAddWebsiteFeed, websitesCloseModal } from '../../actions/websites';
import ModalFooterSaveClose from '../../components/ICO/modals/Footers/ModalFooterSaveClose';
import {
  getWebsitesAddWebsiteIsSaveDisabled,
  getWebsitesAddWebsiteLanguages,
  getWebsitesAddWebsiteModalDomain,
  getWebsitesAddWebsiteModalFeed,
  getWebsitesAddWebsiteModalIsOpen,
  getWebsitesAddWebsiteReach,
  getWebsitesAddWebsiteSelectedLanguage,
  getWebsitesAddWebsiteData,
} from '../../selectors/websites';

const AddWebsiteModal = ({
  domain,
  websiteData,
  feed,
  isOpen,
  isSaveButtonDisabled,
  language,
  languages,
  reach,
  websitesAddWebsite,
  websitesCloseModal,
  websitesAddWebsiteFeed,
}) => {
  useEffect(() => {
    if (websiteData.length) {
      const { id, reach, language } = websiteData[0];
      websitesAddWebsiteFeed({ id, reach, language, feed });
    }
  }, [websiteData, websiteData.length, websitesAddWebsiteFeed, feed]);

  const handleClose = () => {
    websitesCloseModal();
  };

  const handleSave = useCallback(() => {
    websitesAddWebsite({ domain, language, reach });
  }, [websitesAddWebsite, reach, language, domain]);

  return (
    <Modal isOpen={isOpen} toggle={handleClose} onExit={handleClose} onClosed={handleClose} className="fp-modal">
      <ModalHeader className="fp-modal-header--small d-flex align-items-center">
        <Row>
          <Col md={12}>
            <h5 className="text-bold">Add website</h5>
          </Col>
        </Row>
      </ModalHeader>
      <Form onSubmit={(e) => e.preventDefault()}>
        <ModalBody>
          <AddWebsiteModalBody reach={reach} language={language} domain={domain} languages={languages} feed={feed} />
        </ModalBody>
        <ModalFooter>
          <ModalFooterSaveClose handleOnClose={handleClose} isDisabled={isSaveButtonDisabled} onSave={handleSave} />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

AddWebsiteModal.propTypes = {
  domain: PropTypes.string,
  fetchWebsites: PropTypes.func,
  isOpen: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  language: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  reach: PropTypes.number,
  responseStatusCode: PropTypes.number,
  websitesAddWebsite: PropTypes.func,
  websitesCloseModal: PropTypes.func,
  websiteData: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, reach: PropTypes.number, language: PropTypes.string }),
  ),
  websitesAddWebsiteFeed: PropTypes.func,
  feed: PropTypes.string,
};

AddWebsiteModal.defaultProps = {
  fetchWebsites: () => {},
  isOpen: false,
  isSaveButtonDisabled: true,
  websitesAddWebsite: () => {},
  websitesCloseModal: () => {},
};

const mapStateToProps = (state) => ({
  domain: getWebsitesAddWebsiteModalDomain(state),
  isOpen: getWebsitesAddWebsiteModalIsOpen(state),
  isSaveButtonDisabled: getWebsitesAddWebsiteIsSaveDisabled(state),
  language: getWebsitesAddWebsiteSelectedLanguage(state),
  languages: getWebsitesAddWebsiteLanguages(state),
  reach: getWebsitesAddWebsiteReach(state),
  feed: getWebsitesAddWebsiteModalFeed(state),
  websiteData: getWebsitesAddWebsiteData(state),
});

export default connect(mapStateToProps, {
  websitesCloseModal,
  websitesAddWebsite,
  fetchWebsites,
  websitesAddWebsiteFeed,
})(AddWebsiteModal);
