import React, { memo, useState } from 'react';

import { uniq } from 'lodash';
import { connect } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { changeNewReportForm } from '../../../actions/forms';
import { newReportSetSelectedTags } from '../../../actions/reports';
import MultiSelect from '../../../components/ICO/forms/MultiSelect';
import { getArticles } from '../../../selectors/savedCoverage/articles';
import { getSavedStreams } from '../../../selectors/savedCoverage/savedStreams';
import { getSavedVideos } from '../../../selectors/savedCoverage/savedVideos';
import { getNotLockedTags } from '../../../selectors/tags';

const NewReportFilterTags = ({
  articles,
  savedVideos,
  savedStreams,
  tags,
  newReportSetSelectedTags,
  selectedTags,
  changeNewReportForm,
}) => {
  const [filteredTags, setFilteredTags] = useState([]);

  useDeepCompareEffect(() => {
    const articlesTags = Object.values(articles)?.map((article) => article?.tag_id) ?? [];
    const videosTags = savedVideos?.map((video) => video?.tag_id?.toString()) ?? [];
    const streamTags = savedStreams?.map((stream) => stream?.tag_id?.toString()) ?? [];
    const reportTags = uniq([...articlesTags, ...videosTags, ...streamTags]);

    const tagsToSet = tags.filter((tag) => reportTags?.some((rt) => tag.value === rt));
    setFilteredTags(tagsToSet);
    newReportSetSelectedTags(tagsToSet);
  }, [savedStreams, savedVideos, articles, newReportSetSelectedTags]);

  const onChange = (selectedTag) => {
    const unselectedTags = filteredTags.filter(
      (filteredTags) => !selectedTag.some((selectedTag) => selectedTag.value === filteredTags.value),
    );

    changeNewReportForm({
      field: 'tags',
      value: unselectedTags,
    });
    newReportSetSelectedTags(selectedTag);
  };

  return (
    <MultiSelect
      items={filteredTags}
      value={selectedTags}
      placeholder="No tags"
      name="selected-tags"
      onChange={onChange}
    />
  );
};

const mapStateToProps = (state) => ({
  articles: getArticles(state),
  savedVideos: getSavedVideos(state),
  savedStreams: getSavedStreams(state),
  tags: getNotLockedTags(state),
  selectedTags: state.reports.selectedTags,
});

export default memo(connect(mapStateToProps, { newReportSetSelectedTags, changeNewReportForm })(NewReportFilterTags));
