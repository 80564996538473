import dayjs from 'dayjs';
import { connect } from 'react-redux';

import { isValidLoadedArticles } from './NewReportFiltersForm';
import { parseStepFromFormStatus } from './NewReportForm';
import { fetchArticles, fetchArticlesGroups } from '../../actions/articles';
import { previousReportForm, STATUS_DISABLED, submitForm } from '../../actions/forms';
import { changeModule } from '../../actions/modules';
import { saveReport } from '../../actions/reports';
import { savedStreamsFetch } from '../../actions/savedStreams';
import { savedVideosFetch } from '../../actions/savedVideos';
import { fetchWebsites } from '../../actions/websites';
import StepButtons from '../../components/ICO/forms/StepButtons';
import {
  getCurrentProjectId,
  getNewReportFormFields,
  getNewReportParams,
  getNewReportParamsByDate,
  getNewReportParamsByLang,
  getNewReportParamsByTag,
  getNewReportParamsByWebsite,
  getNewReportStep0Params,
  getNewReportTitle,
} from '../../selectors/forms';
import { getNewReportModule } from '../../selectors/modules';
import { getCurrentProject } from '../../selectors/projects';
import { getReportPublicLink } from '../../selectors/reports';
import { getReportSaveStatus } from '../../selectors/savedCoverage/articles';

const mapStateToProps = (state, ownProps) => ({
  label1: 'Filters',
  label2: 'Preview',
  label3: 'Publish',
  label4: 'Published !',
  form: getNewReportFormFields(state),
  currentProjectId: getCurrentProjectId(state),
  saveReportStatus: getReportSaveStatus(state),
  project: getCurrentProject(state),
  disabled: state.forms.newReportForm.fieldsStatus.from === STATUS_DISABLED,
  disabledPreviewButton: !isValidLoadedArticles(ownProps.totalArticles),
  generatedLink: getNewReportModule(state).generatedLink,
  screenshots: state.reports.highlightedArticles,
  videos: state?.reports?.savedVideosReport ?? null,
  streams: state?.reports?.savedStreamsReport ?? null,
  newReportTitle: getNewReportTitle(state),
  selectedTags: state.reports.selectedTags,
  selectedLanguages: state.reports.selectedLanguages,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onClickStep1() {
    const { dispatch } = dispatchProps;
    const { form, currentProjectId } = stateProps;
    const mergedForm = { ...form, project: currentProjectId };
    const reqParamsGroup = getNewReportStep0Params(mergedForm);
    dispatch(fetchArticles(reqParamsGroup)).then(() => {
      dispatch(submitForm({ status: 'STEP 1' }));
    });
    dispatch(
      savedVideosFetch({
        project_id: currentProjectId,
        filters: {
          startDate: dayjs(form.from).format('YYYY-MM-DD'),
          endDate: dayjs(form.to).format('YYYY-MM-DD'),
        },
        pagination: {
          sizePerPage: 5000,
        },
      }),
    );
    dispatch(
      savedStreamsFetch({
        project_id: currentProjectId,
        filters: {
          startDate: dayjs(form.from).format('YYYY-MM-DD'),
          endDate: dayjs(form.to).format('YYYY-MM-DD'),
        },
        pagination: {
          sizePerPage: 5000,
        },
      }),
    );
  },
  onClickStep2() {
    const { dispatch } = dispatchProps;
    const { form, currentProjectId, selectedTags, selectedLanguages } = stateProps;
    const mergedForm = { ...form, project: currentProjectId };
    const reqParamsGroup = getNewReportParams(mergedForm);
    const reqParamsGroupByWebsite = getNewReportParamsByWebsite(mergedForm);
    const reqParamsGroupByLang = getNewReportParamsByLang(mergedForm);
    const reqParamsGroupByTag = getNewReportParamsByTag(mergedForm);
    const reqParamsGroupByDate = getNewReportParamsByDate(mergedForm);

    dispatch(submitForm({ status: 'STEP 2' }));
    dispatch(
      changeModule({
        moduleId: 'newReportForm',
        value: {
          previewGenerated: true,
        },
      }),
    );
    dispatch(fetchArticles(reqParamsGroup));
    dispatch(fetchArticlesGroups(reqParamsGroupByWebsite));
    dispatch(fetchArticlesGroups(reqParamsGroupByLang));
    dispatch(fetchArticlesGroups(reqParamsGroupByTag));
    dispatch(fetchArticlesGroups(reqParamsGroupByDate));
    dispatch(fetchWebsites(reqParamsGroup));
    dispatch(
      savedVideosFetch({
        project_id: currentProjectId,
        filters: {
          startDate: dayjs(form.from).format('YYYY-MM-DD'),
          endDate: dayjs(form.to).format('YYYY-MM-DD'),
          tags: selectedTags.map((tag) => tag.value),
          languages: selectedLanguages.map((lang) => lang.value),
        },
        pagination: {
          page: 1,
          sizePerPage: 10,
          sortField: 'published_at',
        },
      }),
    );
    dispatch(
      savedStreamsFetch({
        project_id: currentProjectId,
        filters: {
          startDate: dayjs(form.from).format('YYYY-MM-DD'),
          endDate: dayjs(form.to).format('YYYY-MM-DD'),
          tags: selectedTags.map((tag) => tag.value),
          languages: selectedLanguages.map((lang) => lang.value),
        },
        pagination: {
          page: 1,
          sizePerPage: 10,
          sortField: 'published_at',
        },
      }),
    );
  },
  onClickStep3() {
    const { dispatch } = dispatchProps;
    const { form, currentProjectId, project } = stateProps;
    const mergedForm = { ...form, project: currentProjectId };
    const reqParams = getNewReportParams(mergedForm);
    const defaultTitle = 'Coverage Report ' + project.name;
    dispatch(submitForm({ status: 'STEP 3' }));
    dispatch(
      saveReport({
        name: mergedForm.titleHTML || defaultTitle,
        descriptionHTML: mergedForm.descriptionHTML,
        section1TitleHTML: mergedForm.section1TitleHTML,
        section1SubtitleHTML: mergedForm.section1SubtitleHTML,
        section2TitleHTML: mergedForm.section2TitleHTML,
        section2SubtitleHTML: mergedForm.section2SubtitleHTML,
        tableType: mergedForm.tableType,
        customTableTemplate: mergedForm.customTableTemplate,
        projectId: reqParams.filter.project_id,
        status: 0,
        date_start: reqParams.filter.date_range[0],
        date_end: reqParams.filter.date_range[1],
        tags_not: reqParams.filter.tags_not,
        languages_not: reqParams.filter.languages_not,
        screenshots: stateProps.screenshots,
        videos: stateProps.videos,
        streams: stateProps.streams,
      }),
    ).then((report) => {
      dispatch(
        changeModule({
          moduleId: 'newReportForm',
          value: {
            generatedLink: getReportPublicLink(report.payload.items[Object.keys(report.payload.items)[0]].id),
          },
        }),
      );
    });
  },
  onClickPrevious() {
    const { formStatus } = ownProps;
    const { dispatch } = dispatchProps;
    const step = parseStepFromFormStatus(formStatus);
    dispatch(previousReportForm(step - 1));
  },
});

const NewReportSubmitButton = connect(mapStateToProps, null, mergeProps)(StepButtons);

export default NewReportSubmitButton;
