import React from 'react';

import { values } from 'lodash';
import { connect } from 'react-redux';

import ClientActions from '../../components/ICO/actions/BrandActions';
import { brandsTableColumns } from '../../components/ICO/tables/helpers/columns';
import { tableRows } from '../../components/ICO/tables/helpers/selectors';
import StaticDataTable from '../../components/ICO/tables/StaticDataTable';
import { getClientsTableListStatusIsLoading } from '../../selectors/clients';

const mapStateToProps = (state, ownProps) => {
  const listStatusLoading = getClientsTableListStatusIsLoading(state);

  const clientsTableFormatter = (rows) =>
    values(rows).map((client) => ({
      id: client.id,
      image: client.image || client.avatar,
      name: client.name || 'No Name #' + client.id,
      actions: <ClientActions id={client.id} client={client} />,
      date: client['inserted-at'],
    }));

  const rowsData = tableRows(state, { locked: false })[ownProps.tableId]();

  return {
    state,
    rowsSelectable: false,
    viewAllBtn: false,
    columns: brandsTableColumns,
    rows: clientsTableFormatter(tableRows(state, { locked: false })[ownProps.tableId]()),
    rowsArchived: clientsTableFormatter(tableRows(state, { locked: true })[ownProps.tableId]()),
    ownProps: ownProps,
    isTableLoading: listStatusLoading && !rowsData.length,
    isNewButtonDisabled: false,
  };
};

const mapDispatchToProps = () => ({
  onLoad: () => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(StaticDataTable);
