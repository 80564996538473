import React, { memo } from 'react';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import ChartButtons from './ChartButtons';
// eslint-disable-next-line no-undef
require('highcharts/modules/exporting')(Highcharts);
// eslint-disable-next-line no-undef
require('highcharts/modules/export-data')(Highcharts);

const BarChart = ({
  config,
  title,
  subtitle,
  height,
  articlesToggle,
  articlesValues,
  reachToggle,
  reachValues,
  visitsToggle,
  visitsValues,
  isLoading,
}) => {
  const finalConfig = Object.assign(
    { ...config },
    {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      colors: [
        '#DD1452',
        '#FFDA00',
        '#519F67',
        '#B1CA5E',
        '#B167C9',
        '#EA86B2',
        '#BD343E',
        '#E08A32',
        '#51899F',
        '#80B4B0',
      ],
      title: {
        align: 'left',
        text: title,
        x: 70,
      },
      subtitle: {
        text: subtitle,
      },
    },
    { ...config },
  );
  return (
    <div style={{ height: `${height}px` }}>
      <ChartButtons
        reachValues={reachValues}
        articlesValues={articlesValues}
        visitsValues={visitsValues}
        reachToggle={reachToggle}
        visitsToggle={visitsToggle}
        articlesToggle={articlesToggle}
      />
      <div className={'block-loader ' + (isLoading && 'loading')}>
        <i className={'fa fa-circle-o-notch fa-spin block-loader-spinner'} />
      </div>
      <HighchartsReact options={finalConfig} highcharts={Highcharts} />
    </div>
  );
};

BarChart.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  config: PropTypes.object,
  height: PropTypes.number,
  subtitle: PropTypes.string,
  articlesToggle: PropTypes.func,
  articlesValues: PropTypes.bool,
  reachToggle: PropTypes.func,
  reachValues: PropTypes.bool,
  visitsToggle: PropTypes.func,
  visitsValues: PropTypes.bool,
};

export default memo(BarChart);
