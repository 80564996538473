import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import translate from '../../../../../helpers/translate';

const ArticlesFeedDeleteArticlesModal = ({ isOpen, onClose, selectedRows, onClickDelete }) => (
  <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
    <ModalHeader className="fp-modal-header--small">Delete {selectedRows.length} article(s)</ModalHeader>
    <ModalBody className="fp-modal-body">
      <p>Are you sure you want to delete those articles?</p>
    </ModalBody>
    <ModalFooter>
      <Button size="sm" onClick={onClose} className="fp-button-close">
        {translate('common.close')}
      </Button>
      <Button
        size="sm"
        type="submit"
        onClick={onClickDelete}
        color="primary"
        name="deleteButton"
        aria-label="deleteButtonModal"
      >
        {translate('common.delete')}
      </Button>
    </ModalFooter>
  </Modal>
);

export default ArticlesFeedDeleteArticlesModal;
