import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, FormGroup, Input, Label } from 'reactstrap';

import SimpleArticlesReportTable from '../../components/ICO/tables/SimpleArticlesReportTable';

const ReportSimpleListEditor = ({ urlParams, onSaveTemplate }) => {
  const [template, setTemplate] = useState('{{title}} | {{score}}');
  const [textarea, setTextArea] = useState('{{title}} | {{score}}');

  useEffect(() => {
    onSaveTemplate(template);
  }, [onSaveTemplate, template]);

  const onAddTextAreaField = (field) => {
    setTextArea(textarea + ' {{' + field + '}}');
  };
  return (
    <>
      <FormGroup>
        <Label for="custom_rows">Customize rows</Label>
        <br />
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            onAddTextAreaField('url');
          }}
          className="mr-2"
        >
          Title with Link
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            onAddTextAreaField('date');
          }}
          className="mr-2"
        >
          date
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            onAddTextAreaField('domain');
          }}
          className="mr-2"
        >
          domain
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            onAddTextAreaField('full_url');
          }}
          className="mr-2"
        >
          Full URL
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            onAddTextAreaField('score');
          }}
          className="mr-2"
        >
          Score
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            onAddTextAreaField('tag_name');
          }}
          className="mr-2"
        >
          Tag Name
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            onAddTextAreaField('title');
          }}
          className="mr-2"
        >
          Title
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            onAddTextAreaField('website_visits');
          }}
          className="mr-2"
        >
          Visits
        </Button>
      </FormGroup>
      <FormGroup>
        <Input
          onChange={(e) => {
            setTextArea(e.target.value);
          }}
          type="textarea"
          name="template"
          id="custom_rows"
          value={textarea}
          placeholder="Enter layout here"
        />
        <Button
          color="primary"
          onClick={() => {
            setTemplate(textarea);
          }}
          className="mr-2 mt-3"
        >
          Save and preview
        </Button>
      </FormGroup>
      <SimpleArticlesReportTable urlParams={urlParams} template={template} />
    </>
  );
};

ReportSimpleListEditor.propTypes = {
  urlParams: PropTypes.shape({}),
  onSaveTemplate: PropTypes.func,
};

export default ReportSimpleListEditor;
