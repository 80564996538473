import React, { useEffect } from 'react';

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';

import ArticlesCreateFeed from './ArticlesCreateFeed.jsx';
import ArticlesFeedTable from './Table/ArticlesFeedTable.jsx';
import { fetchAlerts } from '../../../actions/alerts_new';
import { fetchLanguages } from '../../../actions/languages';
import { fetchTags } from '../../../actions/tags';
import { tourSetTourIsRunning, tourSetTourNextStep } from '../../../actions/tour';
import { setIsFirstLogin } from '../../../helpers/localStorage';
import { mediaFeedPageSteps, style } from '../../../helpers/userTour';
import { getCurrentProject } from '../../../selectors/projects';
import {
  getTourHasCompletedProjectsSteps,
  getTourHasSkipped,
  getTourIsRunning,
  getTourStep,
} from '../../../selectors/tour';

const ArticlesFeed = ({
  project,
  fetchAlerts,
  fetchLanguages,
  fetchTags,
  isTourRunning,
  tourStep,
  tourSetTourNextStep,
  tourSetTourIsRunning,
  hasCompletedProjectsSteps,
  hasSkippedTour,
}) => {
  useEffect(() => {
    if (hasCompletedProjectsSteps && !hasSkippedTour) {
      tourSetTourIsRunning(true);
      tourSetTourNextStep(0);
    }
    fetchAlerts();
    fetchLanguages();
    fetchTags(project.id);
  }, [
    fetchTags,
    fetchLanguages,
    hasSkippedTour,
    tourSetTourIsRunning,
    tourSetTourNextStep,
    hasCompletedProjectsSteps,
    fetchAlerts,
    project.id
  ]);

  const handleJoyrideCallback = ({ action, index, status, type }) => {
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      tourSetTourNextStep(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      tourSetTourIsRunning(false);
      setIsFirstLogin(false);
    }
  };

  const alertId = project['alert-id'];

  if (alertId) {
    return (
      <>
        {isTourRunning ? (
          <Joyride
            steps={mediaFeedPageSteps}
            run={isTourRunning}
            callback={handleJoyrideCallback}
            continuous
            showSkipButton
            disableCloseOnEsc
            hideCloseButton
            stepIndex={tourStep}
            styles={style}
          />
        ) : null}
        <ArticlesFeedTable project={project} />
      </>
    );
  } else {
    return <ArticlesCreateFeed project={project} />;
  }
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  isTourRunning: getTourIsRunning(state),
  tourStep: getTourStep(state),
  hasCompletedProjectsSteps: getTourHasCompletedProjectsSteps(state),
  hasSkippedTour: getTourHasSkipped(state),
});

export default connect(mapStateToProps, {
  tourSetTourIsRunning,
  tourSetTourNextStep,
  fetchAlerts,
  fetchLanguages,
  fetchTags,
})(ArticlesFeed);
