import { createAction } from 'redux-actions';

export const CHANGE_FORM = 'CHANGE_FORM';
export const CHANGE_NEW_REPORT_FORM = 'CHANGE_NEW_REPORT_FORM';
export const CHANGE_ARTICLE_BULK_FORM = 'CHANGE_ARTICLE_BULK_FORM';
export const CHANGE_ARTICLE_BULK_FORM_STATUS = 'CHANGE_ARTICLE_BULK_FORM_STATUS';
export const RESET_FEED_FORM = 'RESET_FEED_FORM';
export const RESET_CHECKED_COUNT = 'RESET_CHECKED_COUNT';
export const CHECK_RSS_ARTICLE = 'CHECK_RSS_ARTICLE';
export const ENABLE_ALERT_FORM = 'ENABLE_ALERT_FORM';
export const ENABLE_FORM = 'ENABLE_FORM';
export const RESET_FORM = 'RESET_FORM';
export const RESET_TABLE = 'RESET_TABLE';
export const RESET_TABLE_FILTERS = 'RESET_TABLE_FILTERS';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const PREVIOUS_REPORT_FORM = 'PREVIOUS_REPORT_FORM';
export const STATUS_DISABLED = 'STATUS_DISABLED';
export const STATUS_ENABLED = 'STATUS_ENABLED';
export const STATUS_LOADING = 'STATUS_LOADING';
export const STATUS_ERROR = 'STATUS_ERROR';
export const STATUS_LOADED = 'LOADED';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_SIDE_MODAL = 'SHOW_SIDE_MODAL';
export const CHANGE_GLOBAL_FORM = 'CHANGE_GLOBAL_FORM';
export const CHANGE_PROJECT_FORM = 'CHANGE_PROJECT_FORM';
export const CHANGE_FEED_FORM = 'CHANGE_FEED_FORM';
export const CHANGE_NEW_FEED_FORM = 'CHANGE_NEW_FEED_FORM';
export const CHANGE_ARTICLE_FORM = 'CHANGE_ARTICLE_FORM';
export const CHANGE_CLIENT_FORM = 'CHANGE_CLIENT_FORM';
export const CHANGE_TAG_FORM = 'CHANGE_TAG_FORM';
export const CHANGE_NEW_ALERT_FORM = 'CHANGE_NEW_ALERT_FORM';
export const CHANGE_ALERT_FORM = 'CHANGE_ALERT_FORM';
export const CHANGE_ALERT_FORM_STATUS = 'CHANGE_ALERT_FORM_STATUS';
export const CHECK_CHECKBOX = 'CHECK_CHECKBOX';
export const UNCHECK_CHECKBOX = 'UNCHECK_CHECKBOX';
export const CHANGE_ARTICLES_WEBSITE_TABLE_FILTER_FORM = 'CHANGE_ARTICLES_WEBSITE_TABLE_FILTER_FORM';
export const CHANGE_USER_FORM = 'CHANGE_USER_FORM';

export const changeForm = createAction(CHANGE_FORM);
export const changeNewReportForm = createAction(CHANGE_NEW_REPORT_FORM);
export const changeFeedForm = createAction(CHANGE_FEED_FORM);
export const changeNewFeedForm = createAction(CHANGE_NEW_FEED_FORM);
export const resetCheckedCount = createAction(RESET_CHECKED_COUNT);
export const submitForm = createAction(SUBMIT_FORM);
export const resetForm = createAction(RESET_FORM);
export const resetTableFilters = createAction(RESET_TABLE_FILTERS);
export const previousReportForm = createAction(PREVIOUS_REPORT_FORM);
export const showModal = createAction(SHOW_MODAL);
export const showSideModal = createAction(SHOW_SIDE_MODAL);
export const hideModal = createAction(HIDE_MODAL);
export const changeProjectForm = createAction(CHANGE_PROJECT_FORM);
export const changeArticleForm = createAction(CHANGE_ARTICLE_FORM);
export const changeArticleBulkForm = createAction(CHANGE_ARTICLE_BULK_FORM);
export const changeArticleBulkFormStatus = createAction(CHANGE_ARTICLE_BULK_FORM_STATUS);
export const changeGlobalForm = createAction(CHANGE_GLOBAL_FORM);
export const changeTagForm = createAction(CHANGE_TAG_FORM);
export const changeNewAlertForm = createAction(CHANGE_NEW_ALERT_FORM);
export const changeClientForm = createAction(CHANGE_CLIENT_FORM);
export const changeUserForm = createAction(CHANGE_USER_FORM);

export const camelCaseToActionCase = (string) => string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1_$2').toUpperCase();
export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const createTableActions = (tableId) => ({
  changeForm: createAction(camelCaseToActionCase('change' + capitalize(tableId) + 'Form')),
  changeFilterForm: createAction(camelCaseToActionCase('change' + capitalize(tableId) + 'FilterForm')),
  changeStatusForm: createAction(camelCaseToActionCase('change' + capitalize(tableId) + 'StatusForm')),
  resetFilterForm: createAction(camelCaseToActionCase('reset' + capitalize(tableId) + 'FilterForm')),
  clearState: createAction(camelCaseToActionCase('clear' + capitalize(tableId) + 'State')),
});

export const tableActions = {
  articlesTable: createTableActions('articlesTable'),
  articlesFeedTable: createTableActions('articlesFeedTable'),
  websitesTable: createTableActions('websitesTable'),
};
