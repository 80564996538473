import React from 'react';

import SavedStreamsTableActionColumn from './SavedStreamsTableActionColumn.jsx';
import SavedStreamsTablePreviewStream from './SavedStreamsTablePreviewStream.jsx';
import { tagFormatter } from '../../../components/ICO/tables/ArticlesFeedTable';
import { dateFormatter, langFormatter, videosViewFormatter } from '../../../components/ICO/tables/helpers/formatters';
import translate from '../../../helpers/translate';

const renderStreamImage = (image) => {
  if (image.includes('_404')) {
    return (
      <img
        src="https://ico-files.s3.eu-west-2.amazonaws.com/images/Twitch+Thumbnail.png"
        width="150"
        height="80"
        alt="Video thumbnail"
        loading="lazy"
        title="Stream thumbnail not found for this stream"
      />
    );
  } else {
    const imageSrc = image.replace(/%\{width\}/g, '150').replace(/%\{height\}/g, '80');
    return <img src={imageSrc} width="150" height="80" alt="Video thumbnail" loading="lazy" />;
  }
};

const savedStreamsTableColumns = [
  {
    dataField: 'thumbnail_url',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
    formatter: (row, data) => renderStreamImage(data?.thumbnail_url),
  },
  {
    dataField: 'broadcaster_name',
    formatter: (row, rowData) => (
      <a href={`https://www.twitch.tv/${rowData?.broadcaster_name}`} target="_blank" rel="noopener noreferrer">
        {rowData?.broadcaster_name}
      </a>
    ),
    text: translate('pages.saved_streams.table.columns.channel'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0 align-middle',
    sort: true,
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'title',
    formatter: (row, rowData) => <SavedStreamsTablePreviewStream videoData={rowData} />,
    text: translate('pages.saved_streams.table.columns.title'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0 align-middle',
    sort: true,
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'followers',
    formatter: videosViewFormatter,
    text: translate('pages.saved_streams.table.columns.followers'),
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0 align-middle',
    headerAlign: 'center',
    align: 'center',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'language',
    formatter: langFormatter,
    text: 'Language',
    align: 'center',
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0 align-middle',
    headerAlign: 'center',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'tag',
    formatter: (row) => tagFormatter(row.name),
    text: 'Tag',
    align: 'center',
    classes: 'border-0 align-middle text-light fs-0',
    headerClasses: 'border-0 align-middle',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'published_at',
    formatter: dateFormatter,
    text: translate('pages.saved_streams.table.columns.date'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    headerAlign: 'center',
    align: 'center',
    sort: true,
    csvExport: false,
  },
  {
    dataField: 'actions',
    formatter: (row, rowData) => <SavedStreamsTableActionColumn videoData={rowData} />,
    text: translate('pages.saved_streams.table.columns.actions'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    csvExport: false,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
];

export default savedStreamsTableColumns;
