import { connect } from 'react-redux';

import EditActions from './EditActions';
import { changeNewAlertForm, changeProjectForm, showModal } from '../../../actions/forms';
import { editProject } from '../../../actions/projects';
import { getAlert } from '../../../selectors/projects';
import { toastifyResult } from '../modals/ModalRoot';

export const onProjectEdit = (dispatch, project) => {
  dispatch(showModal({ content: 'editProject' }));
  dispatch(changeProjectForm({ field: 'id', value: project.id }));
  dispatch(changeProjectForm({ field: 'name', value: project.name }));
  dispatch(changeProjectForm({ field: 'clientId', value: project.client_id }));
  dispatch(changeProjectForm({ field: 'avatar', value: project.avatar }));
};

export const onProjectAlertEdit = (dispatch, alert, project) => {
  dispatch(showModal({ content: 'editAlert' }));
  dispatch(changeNewAlertForm({ field: 'id', value: alert?.id || null }));
  dispatch(changeNewAlertForm({ field: 'projectId', value: project?.id || null }));
  dispatch(changeNewAlertForm({ field: 'projectName', value: project?.name || null }));
  dispatch(changeNewAlertForm({ field: 'keywords', value: alert?.keywords || null }));
  dispatch(changeNewAlertForm({ field: 'minReach', value: alert?.['min-reach'] || 0 }));
  dispatch(changeNewAlertForm({ field: 'slackTracking', value: alert?.['slack-tracking'] || false }));
  dispatch(changeNewAlertForm({ field: 'slackChannel', value: alert?.['slack-channel'] || '' }));
  dispatch(changeNewAlertForm({ field: 'slackChannelLabel', value: alert?.['slack-channel-name'] || '' }));
};

const mapStateToProps = (state, ownProps) => {
  const currentProject = ownProps.project;
  const currentProjectAlert = getAlert(state, currentProject['alert-id']);
  return {
    id: ownProps.id,
    data: currentProject,
    alert: currentProjectAlert,
    canManageAlert: true,
    canLock: true,
    lockLabel: 'archive',
    type: 'project',
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onEdit: () => {
    const { dispatch } = dispatchProps;
    const { data } = stateProps;
    onProjectEdit(dispatch, data);
  },
  onLock: () => {
    const { dispatch } = dispatchProps;
    dispatch(
      editProject({
        id: ownProps.id,
        locked: true,
      }),
    ).then((res) => {
      toastifyResult(res, 'Project has been archived.');
    });
  },
  onUnlock: () => {
    const { dispatch } = dispatchProps;
    dispatch(
      editProject({
        id: ownProps.id,
        locked: false,
      }),
    ).then((res) => {
      toastifyResult(res, 'Project has been re-activated.');
    });
  },
  onAlertEdit: () => {
    const { dispatch } = dispatchProps;
    const { alert, data } = stateProps;

    onProjectAlertEdit(dispatch, alert, data);
  },
});

const ProjectActions = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditActions);

export default ProjectActions;
