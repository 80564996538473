import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import LoadingLayout from './LoadingLayout';
import NoAuthLoadingLayout from './NoAuthLoadingLayout';
import PublicLayout from './PublicLayout';
import { CloseButton, Fade } from '../components/common/Toast';
import SubscriptionUpgradeSuccess from '../components/ICO/SubscriptionUpgradeSuccess/SubscriptionUpgradeSuccess';
import Signup from '../pages/Signup/Signup';
import Welcome from '../pages/Welcome/Welcome';

const Layout = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return (
      <Switch>
        <Route path="/signup" exact component={NoAuthLoadingLayout} />
        <Route path="/signup/:inviteId" exact component={NoAuthLoadingLayout} />
        <Route component={LoadingLayout} />
      </Switch>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/loading/:page" exact component={LoadingLayout} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/signup/:inviteId" exact component={Signup} />
        <Route path="/p" component={PublicLayout} />
        {!isAuthenticated && !isLoading && (
          <>
            <Route path="/welcome" exact component={Welcome} />
            <Redirect
              to={{
                pathname: '/welcome',
              }}
            />
          </>
        )}
        {isAuthenticated && (
          <Switch>
            <Redirect from="/welcome" to="/" />
            <Route path="/errors" component={ErrorLayout} />
            <Route path="/subscription-upgrade-success" exact component={SubscriptionUpgradeSuccess} />
            <Route component={DashboardLayout} />
          </Switch>
        )}
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </>
  );
};

export default Layout;
