import React, { useContext } from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showSideModal } from '../../../actions/forms';
import { IMAGES_ROOT } from '../../../config/global-config';
import AppContext from '../../../context/Context';
import { getCurrentProject } from '../../../selectors/projects';
import Avatar from '../../common/Avatar';

const TitleLogo = ({ project, showSideModal }) => {
  const { toggleModal, isOpenSidePanel } = useContext(AppContext);

  const handleClickLogo = () => {
    showSideModal('SelectProject');
    toggleModal();
  };

  const icon = isOpenSidePanel ? faChevronRight : faChevronDown;

  return (
    <div className="text-decoration-none navbar-brand text-left">
      <div aria-label="logo" className="d-flex align-items-center py-3 cursor-pointer" onClick={handleClickLogo}>
        {project.avatar ? (
          <Avatar size="2xl" className="mr-2 status-online" src={IMAGES_ROOT + project.avatar} />
        ) : (
          <div className="avatar avatar-2xl mr-2 status-online">
            <div className={'avatar-name rounded-circle bg-soft-primary'}>
              <span className={'fs-0 text-primary'}>{project.name[0]}</span>
            </div>
          </div>
        )}
        <span className="text-medium">{project.name}</span>
        <FontAwesomeIcon icon={icon} color="white" style={{ width: '15px', height: '15px' }} className="ml-1" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
});

TitleLogo.propTypes = {
  project: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  showSideModal: PropTypes.func,
};

export default connect(mapStateToProps, { showSideModal })(TitleLogo);
