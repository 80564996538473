import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const REQUEST_ALERTS = 'REQUEST_ALERTS';
export const RECEIVE_ALERTS = 'RECEIVE_ALERTS';
export const REJECT_ALERTS = 'REJECT_ALERTS';

export const REQUEST_SAVE_ALERT = 'REQUEST_SAVE_ALERT';
export const REJECT_SAVE_ALERT = 'REJECT_SAVE_ALERT';
export const RECEIVE_SAVE_ALERT = 'RECEIVE_SAVE_ALERT';

export const fetchAlerts = (
  params = {
    page: {
      page: 0,
      page_size: 50,
    },
  },
) =>
  apiFetch(
    'alerts',
    {
      request: REQUEST_ALERTS,
      receive: RECEIVE_ALERTS,
      reject: REJECT_ALERTS,
    },
    params,
  );

export const saveAlert = (
  data = {
    keywords: '',
    projectName: '',
    minReach: 0,
    slackTracking: false,
    slackChannel: '',
  },
) =>
  apiFetch(
    'alerts',
    {
      request: {
        type: REQUEST_SAVE_ALERT,
        payload: data,
      },
      receive: RECEIVE_SAVE_ALERT,
      reject: REJECT_SAVE_ALERT,
    },
    {
      body: JSON.stringify({
        project_id: data.projectId,
        name: data.projectName,
        slack_tracking: data.slackTracking,
        slack_channel_id: data.slackChannel,
        slack_channel_name: data.slackChannelLabel,
        min_reach: data.minReach,
        keywords: data.keywords,
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const editAlert = (
  data = {
    id: -1,
    projectName: '',
    keywords: '',
    minReach: 0,
    slackTracking: false,
    slackChannel: '',
  },
) =>
  apiFetch(
    `alerts/${data.id}`,
    {
      request: {
        type: REQUEST_SAVE_ALERT,
        payload: data,
      },
      receive: RECEIVE_SAVE_ALERT,
      reject: REJECT_SAVE_ALERT,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'alert',
          attributes: {
            name: data.projectName,
            slack_tracking: data.slackTracking,
            slack_channel_id: data.slackChannel,
            slack_channel_name: data.slackChannelLabel,
            min_reach: data.minReach,
            keywords: data.keywords,
          },
        },
      }),
    },
    {
      method: 'PATCH',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
