import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, Tooltip } from 'reactstrap';

import Background from '../../common/Background';

const getImage = (color) => {
  switch (color) {
    case 'media-score':
      return 'https://ico-files.s3.eu-west-2.amazonaws.com/images/FireBush-Circle-Report.png';
    case 'muv':
    case 'articles':
      return 'https://ico-files.s3.eu-west-2.amazonaws.com/images/WellRead-Circle-Report.png';
    case 'mediatype':
    case 'mediascore':
    case 'websites':
      return 'https://ico-files.s3.eu-west-2.amazonaws.com/images/Wedgewood-Circle-Report.png';
    default:
      return 'https://ico-files.s3.eu-west-2.amazonaws.com/images/FireBush-Circle-Report.png';
  }
};

const getContentClassNames = (color) => {
  const contentClassNames = 'display-4 fs-8 mb-2 text-sans-serif';
  if (color === 'success') return contentClassNames;
  return `${contentClassNames} text-${color}`;
};

const CardSummary = ({ title, rate, linkText, to, color, children, hasLinkText, tooltip }) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem', minHeight: '11rem' }}>
      <Background image={getImage(color)} className="bg-card" />
      <CardBody className="position-relative justify-content-center d-flex flex-column">
        <div className="d-flex justify-content-between">
          <span className="mb-3">{title}</span>
          <span className="fs-7">{rate}</span>

          {tooltip && tooltip.visible && (
            <>
              <FontAwesomeIcon icon={tooltip.icon} id={tooltip.id} />
              <Tooltip
                delay={{
                  show: tooltip.delay.show,
                  hide: tooltip.delay.hide,
                }}
                placement="top"
                isOpen={isTooltipOpen}
                target="media_score"
                toggle={() => setTooltipOpen(!isTooltipOpen)}
              >
                {tooltip.text}
              </Tooltip>
            </>
          )}
        </div>
        <div className={getContentClassNames(color)}>{children}</div>
        {hasLinkText && (
          <Link className="font-weight-semi-bold fs--1 text-nowrap" to={to}>
            {linkText}
            <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
          </Link>
        )}
      </CardBody>
    </Card>
  );
};

CardSummary.propTypes = {
  title: PropTypes.string.isRequired,
  rate: PropTypes.string,
  linkText: PropTypes.string,
  hasLinkText: PropTypes.bool,
  to: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  tooltip: PropTypes.shape({
    visible: PropTypes.bool,
    icon: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.string,
    delay: PropTypes.shape({
      show: PropTypes.number,
      delay: PropTypes.number,
      hide: PropTypes.number,
    }),
  }),
};

CardSummary.defaultProps = {
  linkText: 'See all',
  hasLinkText: true,
  to: '#!',
  color: 'primary',
  tooltip: null,
};

export default CardSummary;
