import React, { useState } from 'react';

import { Col, Row } from 'reactstrap';

import QueryBuilderExcludeInput from './QueryBuilderExcludeInput.jsx';
import QueryBuilderIncludeInput from './QueryBuilderIncludeInput.jsx';

const QueryBuilder = ({ currentInput, stateAction, translatedKeyword = null }) => {
  const [searchQuery, setSearchQuery] = useState({
    includeQuery: translatedKeyword?.include ?? null,
    excludeQuery: translatedKeyword?.exclude ?? null,
  });

  const [isAdvancedMode, setIsAdvancedMode] = useState(false);

  const handleSearchQueryChange = (newValues) => {
    const newSearchQuery = { ...searchQuery, ...newValues };
    setSearchQuery(newSearchQuery);

    const { includeQuery, excludeQuery } = newSearchQuery;
    let query = '';

    if (includeQuery) {
      query += includeQuery;
    }

    if (excludeQuery) {
      query += (query ? ' ' : '') + excludeQuery;
    }

    if (!includeQuery && !excludeQuery) {
      stateAction(null);
    }

    stateAction(query);
  };

  return (
    <>
      <Row>
        {isAdvancedMode ? (
          <Col xs={12}>
            {currentInput}
            <span onClick={() => setIsAdvancedMode(!isAdvancedMode)} className="fs--1 cursor-pointer text-primary">
              Toggle simple query mode
            </span>
          </Col>
        ) : (
          <>
            <Col xs={12} md={6}>
              <QueryBuilderIncludeInput
                searchQuery={searchQuery}
                setSearchQuery={(value) => handleSearchQueryChange(value)}
              />
              <span onClick={() => setIsAdvancedMode(!isAdvancedMode)} className="fs--1 cursor-pointer text-primary">
                Toggle advanced query mode
              </span>
            </Col>
            <Col xs={12} md={6}>
              <QueryBuilderExcludeInput
                searchQuery={searchQuery}
                setSearchQuery={(value) => handleSearchQueryChange(value)}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default QueryBuilder;
