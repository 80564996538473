const urlSearchParamsBuilder = ({ filters = null, pagination = {}, endpoint = '' }) => {
  let searchParams = new URLSearchParams();

  if (filters) {
    for (const key in filters) {
      if (filters[key] !== null && filters[key] !== undefined) {
        searchParams.append(key, filters[key]);
      }
    }
  }

  if (pagination) {
    for (const key in pagination) {
      if (pagination[key] !== null && pagination[key] !== undefined) {
        searchParams.append(key, pagination[key]);
      }
    }
  }

  const queryString = searchParams.toString();
  return queryString ? `${endpoint}${endpoint.includes('?') ? '&' : '?'}${queryString}` : endpoint;
};

export default urlSearchParamsBuilder;
