import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const CustomDropDownTotal = ({ onChange, totalSize, rowsPerPage = 25, rowsPerPageList }) => {
  const [currentPageSize, setCurrentPageSize] = useState(rowsPerPage);
  const [isOpen, setIsOpen] = useState(false);

  const handleChangePageSize = (value) => {
    setCurrentPageSize(value);
    onChange(value);
  };

  const renderDropdowns = () =>
    rowsPerPageList.map((value, id) => (
      <DropdownItem key={id} id={id} onClick={() => handleChangePageSize(value)} active={rowsPerPage === value}>
        {value}
      </DropdownItem>
    ));

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} aria-label="dropdown-dynamic">
      Showing
      <DropdownToggle caret size="sm" className="ml-2 mr-3 btn btn-falcon-default">
        {currentPageSize}
      </DropdownToggle>
      of <strong>{totalSize}</strong>
      <DropdownMenu>{renderDropdowns()}</DropdownMenu>
    </Dropdown>
  );
};

CustomDropDownTotal.propTypes = {
  onChange: PropTypes.func,
  totalSize: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageList: PropTypes.arrayOf(PropTypes.number),
};
