import React from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ImageUploader from '../../components/ICO/uploads/ImageUploader';
import '../../assets/scss/theme/Footprints/components/_modal.scss';

const SettingsAvatarModal = ({ onChange, imageUploaderData, isOpen, onClose, onSave, isSaveButtonDisabled }) => (
  <Modal isOpen={isOpen} toggle={onClose} size="md" className="fp-modal">
    <ModalHeader className="fp-modal-header--small d-flex align-items-center">
      <h5 className="text-bold">New profile picture</h5>
    </ModalHeader>
    <Form onSubmit={(e) => e.preventDefault()}>
      <ModalBody className="fp-modal-body">
        <ImageUploader urlRoot={''} onSelectImage={onChange} multiple={false} data={imageUploaderData} />
      </ModalBody>
      <ModalFooter>
        <>
          <Button aria-label="close-button" size="sm" onClick={onClose} color="secondary" className="fp-button">
            Close
          </Button>
          <Button
            disabled={isSaveButtonDisabled}
            aria-label="save-button"
            size="sm"
            type="submit"
            onClick={onSave}
            color="primary"
            className="fp-button"
          >
            Save
          </Button>
        </>
      </ModalFooter>
    </Form>
  </Modal>
);

SettingsAvatarModal.propTypes = {
  onChange: PropTypes.func,
  imageUploaderData: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isSaveButtonDisabled: PropTypes.bool,
};

export default SettingsAvatarModal;
