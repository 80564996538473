import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { showModal } from '../../../actions/forms';
import { deleteReport } from '../../../actions/reports';

const ReportTableActions = ({ report, onDeleteClick, onRenameReport }) => (
  <UncontrolledDropdown className="fp-action">
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right positionFixed className="border py-2">
      <Link target="_blank" rel="noopener noreferrer" to={'/p/r/' + report.id}>
        <DropdownItem>View Public Report</DropdownItem>
      </Link>
      <DropdownItem onClick={onRenameReport}>Rename report</DropdownItem>
      <DropdownItem divider />
      <DropdownItem onClick={onDeleteClick} className="text-danger">
        Delete
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDeleteClick: () => {
    dispatch(
      showModal({
        content: 'confirmAction',
        contentId: ownProps.report.id,
        contentData: { resource: 'report', data: ownProps.report.name, action: deleteReport(ownProps.report.id) },
      }),
    );
  },
  onClick: () => {
    dispatch(
      showModal({
        content: 'previewArticle',
        contentId: ownProps.article.id,
        contentData: ownProps.article,
      }),
    );
  },
  onRenameReport: () => {
    dispatch(
      showModal({
        content: 'renameReport',
        contentId: ownProps.report.id,
        contentData: { resource: 'report', data: ownProps.report },
      }),
    );
  },
});

ReportTableActions.propTypes = {
  report: PropTypes.shape({
    'date-end': PropTypes.string,
    'date-start': PropTypes.string,
    descriptionHTML: PropTypes.string,
    id: PropTypes.string,
    'inserted-at': PropTypes.string,
    name: PropTypes.string,
    project_id: PropTypes.string,
    'section-subtitle-1': PropTypes.string,
    'section-subtitle-2': PropTypes.string,
    'section-title-1': PropTypes.string,
    'section-title-2': PropTypes.string,
    status: PropTypes.number,
    'table-template': PropTypes.string,
    'table-type': PropTypes.number,
    tags_id: PropTypes.number,
    'total-articles': PropTypes.number,
    'total-reach': PropTypes.number,
  }),
  onDeleteClick: PropTypes.func,
  onRenameReport: PropTypes.func,
};

ReportTableActions.defaultProps = {
  report: {},
  onDeleteClick: () => {},
};

export default connect(null, mapDispatchToProps)(ReportTableActions);
