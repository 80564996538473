import React from 'react';

import { faChartArea, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Label, Row, UncontrolledTooltip, Button } from 'reactstrap';

import translate from '../../../helpers/translate';
import ButtonWithTooltip from '../Buttons/ButtonWithTooltip/ButtonWithTooltip';

const StepButtons = ({
  saveReportStatus,
  formStatus,
  generatedLink,
  label1,
  label2,
  label3,
  onClickPrevious,
  onClickStep1,
  onClickStep2,
  onClickStep3,
  disabledPreviewButton,
  newReportTitle,
}) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(generatedLink);
  };

  if (formStatus === 'STEP 3 LOADED') {
    return (
      <div className="stepy-navigator form-group">
        <Row>
          <mark className="alert alert-success alert-bordered alert-styled-left alert-arrow-left">
            <span className="col-lg-12">
              {translate('newReports.report_published') + ' '}
              <a target="_new" href={generatedLink}>
                {translate('newReports.new_report_link')}
              </a>
            </span>
          </mark>
        </Row>
        <Row className="justify-content-center">
          <Label>{generatedLink}</Label>
          <FontAwesomeIcon
            onClick={handleCopyToClipboard}
            icon={faCopy}
            className="ml-1 cursor-pointer"
            id="copyLink"
          />
          <UncontrolledTooltip
            placement="bottom"
            target="copyLink"
            className="outline: none;
            box-shadow: none;"
          >
            {translate('newReports.new_report_share')}
          </UncontrolledTooltip>
        </Row>
      </div>
    );
  }
  if (['STEP 2', 'STEP 2 LOADED'].indexOf(formStatus) >= 0) {
    const isPublishButtonDisabled =
      saveReportStatus === 'LOADING' ||
      formStatus === 'STEP 2' ||
      (newReportTitle.length > 0 && newReportTitle.length <= 2);
    return (
      <>
        <Button onClick={onClickPrevious} className="mr-2 mb-2" disabled={saveReportStatus === 'LOADING'}>
          Back
        </Button>
        <ButtonWithTooltip
          onClick={onClickStep3}
          label={label3}
          isDisabled={isPublishButtonDisabled}
          tooltipText={translate('newReports.title_restraint')}
        />
      </>
    );
  }
  if (['STEP 1', 'STEP 1 LOADED'].indexOf(formStatus) >= 0) {
    return (
      <>
        <Button onClick={onClickPrevious} className="mr-2 mb-2" disabled={saveReportStatus === 'LOADING'}>
          Back
        </Button>
        <Button
          color="primary"
          icon={faChartArea}
          onClick={onClickStep2}
          className="mr-2 mb-2"
          disabled={saveReportStatus === 'LOADING' || formStatus === 'STEP 2' || disabledPreviewButton}
        >
          {label2}
        </Button>
      </>
    );
  }
  if (formStatus === 'STEP 3') {
    return (
      <>
        <Button onClick={onClickPrevious} className="mr-2 mb-2 btn-secondary" disabled={saveReportStatus === 'LOADING'}>
          Back
        </Button>
        <Button
          color="primary"
          onClick={onClickStep3}
          className="mr-2 mb-2"
          disabled={saveReportStatus === 'LOADING' || formStatus === 'STEP 3'}
        >
          {label3}
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          color="primary"
          onClick={onClickStep1}
          className="mr-2 mb-2"
          disabled={saveReportStatus === 'LOADING' || formStatus === 'STEP 1'}
        >
          {label1}
        </Button>
      </>
    );
  }
};

StepButtons.propTypes = {
  label1: PropTypes.string,
  label2: PropTypes.string,
  onClickStep1: PropTypes.func,
  onClickStep2: PropTypes.func,
  disabled: PropTypes.bool,
};

export default StepButtons;
