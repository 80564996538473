import { getArticles } from './savedCoverage/articles';
import { getSavedStreams } from './savedCoverage/savedStreams';
import { getSavedVideos } from './savedCoverage/savedVideos';

export const getNewReportSelectedTags = (state) => state?.reports?.selectedTags ?? [];
export const getNewReportSelectedLanguages = (state) => state?.reports?.selectedLanguages ?? [];

export const getFormNewReportFormTotalVideosByTagAndLanguage = (state) => {
  const savedVideos = getSavedVideos(state) ?? [];
  const newReportFormTags = getNewReportSelectedTags(state);
  const newReportFormLanguages = getNewReportSelectedLanguages(state);

  return savedVideos.filter(
    (video) =>
      newReportFormTags?.some((tag) => tag.value === video?.tag_id.toString()) &&
      newReportFormLanguages?.some((language) => language.value === video?.channel_language?.toLowerCase()),
  ).length;
};

export const getFormNewReportFormTotalStreamsByTagAndLanguage = (state) => {
  const savedStreams = getSavedStreams(state) ?? [];
  const newReportFormTags = getNewReportSelectedTags(state);
  const newReportFormLanguages = getNewReportSelectedLanguages(state);

  return savedStreams.filter(
    (stream) =>
      newReportFormTags?.some((tag) => tag.value === stream.tag_id.toString()) &&
      newReportFormLanguages?.some((language) => language.value === stream.language.toString()),
  ).length;
};

export const getFormNewReportFormTotalArticlesByTagAndLanguage = (state) => {
  const articles = Object.values(getArticles(state)) ?? [];
  const newReportFormTags = state.forms.newReportForm?.fields?.tags?.map((tag) => tag.value);
  const newReportFormLanguages = state.forms.newReportForm?.fields?.languages?.map((lang) => lang.value);

  return articles.filter(
    (article) => !newReportFormTags.includes(article.tag_id) && !newReportFormLanguages.includes(article.language),
  ).length;
};

export const getNewReportScreenshots = (state) => state.reports.highlightedArticles;
