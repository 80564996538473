import React from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { fetchTwitchFeed } from '../../../../actions/twitchFeed';
import translate from '../../../../helpers/translate';
import { getTwitchFeedTableFilters } from '../../../../selectors/feeds/twitchFeed';
import { getCurrentProject } from '../../../../selectors/projects';

const TwitchFeedTableFiltersModalFooter = ({ project, onClose, fetchTwitchFeed, filters }) => {
  const onClick = () => {
    fetchTwitchFeed({ twitch_search_id: project['twitch-search-id'], filters });
    onClose();
  };

  return (
    <>
      <Button size="sm" onClick={onClose} className="fp-button-close" aria-label="closeFiltersButton">
        {translate('common.close')}
      </Button>
      <Button
        size="sm"
        type="submit"
        onClick={onClick}
        color="primary"
        name="saveButton"
        aria-label="applyFiltersButton"
      >
        {translate('common.apply')}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  filters: getTwitchFeedTableFilters(state),
});

export default connect(mapStateToProps, { fetchTwitchFeed })(TwitchFeedTableFiltersModalFooter);
