import {
  API_CALL,
  SETTINGS_ADD_ORGANISATION,
  SETTINGS_CLEAR,
  SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS,
} from './actionTypes';

export const settingsAddOrganisation = (organizationName) => ({
  type: API_CALL,
  meta: {
    type: SETTINGS_ADD_ORGANISATION,
    endpoint: 'settings',
    method: 'POST',
    payload: {
      data: {
        type: SETTINGS_ADD_ORGANISATION,
        attributes: {
          name: organizationName,
        },
      },
    },
  },
});

export const settingsFetchCustomerOrganizations = (customerId) => ({
  type: API_CALL,
  meta: {
    type: SETTINGS_FETCH_CUSTOMER_ORGANIZATIONS,
    endpoint: 'settings/customer-organiztions',
    method: 'POST',
    payload: {
      data: {
        type: '',
        attributes: {
          customer_id: customerId,
        },
      },
    },
  },
});

export const settingsClear = () => ({
  type: SETTINGS_CLEAR,
});
