import React, { memo, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import AdvancedQueryInput from './AdvancedQueryInput.jsx';
import SlackTracking from './SlackTracking.jsx';
import { fetchProjects } from '../../../../../actions/projects';
import QueryBuilder from '../../../../../components/ICO/QueryBuilder/QueryBuilder.jsx';
import translate from '../../../../../helpers/translate';
import { getOrganizationIsSlackEnabled, getOrganizationSlackChannels } from '../../../../../selectors/organizations';
import { getOrganizationPermissionsHasSlack } from '../../../../../selectors/permissions';
import { useGetArticlesCount } from '../../../../Projects/QueryBuilderProjects/useGetArticlesCount';
import { useSaveArticlesFeedAlert } from '../../hooks/useArticlesFeed';

const ArticlesFeedModalCreateKeyword = ({
  isOpen,
  onClose,
  project,
  userHasSlack,
  organizationSlackEnabled,
  organizationSlackChannels,
  fetchProjects,
}) => {
  const [alertConfig, setAlertConfig] = useState({
    name: project.name,
    project_id: project.id,
    slack_tracking: false,
    slack_channel_id: '',
    slack_channel_name: '',
    min_reach: 0,
    keywords: null,
  });

  useEffect(
    () => () => {
      setAlertConfig({
        name: null,
        slack_tracking: null,
        slack_channel_id: null,
        slack_channel_name: null,
        min_reach: null,
        keywords: null,
      });
    },
    [setAlertConfig],
  );

  const onSuccess = () => {
    onClose();
    fetchProjects();
  };

  const { mutate, isLoading } = useSaveArticlesFeedAlert(onSuccess);
  const mutation = useGetArticlesCount();

  const handleClickSave = () => {
    mutate(alertConfig);
  };

  const isSaveDisabled = !alertConfig.keywords || alertConfig.keywords.length < 5;

  const onChange = (value) => {
    setAlertConfig({ ...alertConfig, keywords: value });
    mutation.mutate(value);
  };

  const debounceInput = debounce(onChange, 250);

  const slackConfig = () => {
    if (userHasSlack) {
      if (organizationSlackEnabled) {
        return (
          <SlackTracking
            setAlertConfig={setAlertConfig}
            alertConfig={alertConfig}
            organizationSlackChannels={organizationSlackChannels}
            slackChannelValue={alertConfig.slack_channel_name}
          />
        );
      }

      return <div>{translate('modals.alert.enable_slack')}</div>;
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
      <ModalHeader className="fp-modal-header--small">
        {!project['alert-id']
          ? translate('pages.articles_feed.create_feed')
          : translate('pages.articles_feed.edit_feed')}
      </ModalHeader>
      <ModalBody className="fp-modal-body">
        <QueryBuilder stateAction={debounceInput} currentInput={<AdvancedQueryInput stateAction={debounceInput} />} />
        <Row>
          <Col>
            <span className="text-gray fs--1">
              {mutation?.isSuccess && alertConfig?.keywords?.length ? (
                <>
                  Based on our data from the last two months, you can expect to find approximately{' '}
                  <strong>{mutation.data.data.count} articles</strong> related to your search.
                </>
              ) : null}
            </span>
          </Col>
        </Row>
        {slackConfig()}
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button
          size="sm"
          type="submit"
          onClick={handleClickSave}
          aria-label="save-button"
          color="primary"
          name="saveButton"
          disabled={isSaveDisabled}
        >
          {isLoading ? translate('common.loading') : translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  organizationSlackEnabled: getOrganizationIsSlackEnabled(state),
  organizationSlackChannels: getOrganizationSlackChannels(state),
  userHasSlack: getOrganizationPermissionsHasSlack(state),
});

export default memo(connect(mapStateToProps, { fetchProjects })(ArticlesFeedModalCreateKeyword));
