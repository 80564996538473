import { createSelector } from 'reselect';

import { getReportsHighlightedArticles } from './reports';

export const getOrganizationPermissions = (state) => state.organizations?.currentOrg?.permissions;

export const getOrganizationPermissionsHasData = createSelector([getOrganizationPermissions], (permissions) =>
  permissions ? permissions.data : false,
);

export const getOrganizationPermissionsHasOrganizations = createSelector([getOrganizationPermissions], (permissions) =>
  permissions ? permissions.organizations : false,
);

export const getOrganizationPermissionsHasProjects = createSelector([getOrganizationPermissions], (permissions) =>
  permissions ? permissions.projects : false,
);

export const getOrganizationPermissionsHasSimilarWeb = createSelector([getOrganizationPermissions], (permissions) =>
  permissions ? permissions.similarweb : false,
);

export const getOrganizationPermissionsHasScreenshot = createSelector([getOrganizationPermissions], (permissions) =>
  permissions ? permissions.screenshot : false,
);

export const getOrganizationPermissionsHasSlack = createSelector([getOrganizationPermissions], (permissions) =>
  permissions ? permissions.slack : false,
);

export const getOrganizationPermissionsHasReports = createSelector([getOrganizationPermissions], (permissions) =>
  permissions ? permissions.reports : false,
);

export const getOrganizationPermissionsMaxReports = (state) =>
  getOrganizationPermissionsHasReports(state) && getOrganizationPermissions(state).max_reports;
export const getOrganizationPermissionsMaxProjects = (state) =>
  getOrganizationPermissionsHasProjects(state) && getOrganizationPermissions(state).max_projects;
export const getOrganizationPermissionsMaxData = (state) =>
  getOrganizationPermissionsHasData(state) && getOrganizationPermissions(state).max_data;
export const getOrganizationPermissionsMaxOrganizations = (state) =>
  getOrganizationPermissionsHasOrganizations(state) && getOrganizationPermissions(state).max_organizations;
export const getOrganizationPermissionsMaxScreenshots = (state) => getOrganizationPermissions(state).max_screenshots;
export const getOrganizationPermissionsCanTakeScreenshot = (state) => {
  if (getOrganizationPermissionsHasScreenshot(state)) {
    return getReportsHighlightedArticles(state).length < getOrganizationPermissions(state).max_screenshots;
  }

  return false;
};

export const getOrganizationPermissionsHasYoutubeFeed = (state) => getOrganizationPermissions(state).youtube_feed;
export const getOrganizationPermissionsHasDiscord = (state) => getOrganizationPermissions(state).discord;
export const getOrganizationPermissionsHasTwitchFeed = (state) => getOrganizationPermissions(state).twitch_feed;
