import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import AddWebsiteModal from './AddWebsiteModal';
import { fetchMediaTypes } from '../../actions/mediaTypes';
import { fetchWebsites, websitesCloseModal } from '../../actions/websites';
import getToastedNotification from '../../components/ICO/helpers/toaster.helper';
import DynamicDataTable from '../../components/ICO/tables/DynamicDataTable';
import { websitesTableColumn } from '../../components/ICO/tables/helpers/columns';
import { CREATED_STATUS } from '../../constants';
import { getOrganizationPermissionsHasSimilarWeb } from '../../selectors/permissions';
import { getWebsitesAddWebsiteFeedStatus, getWebsitesAddWebsiteStatus } from '../../selectors/websites';

const Websites = ({
  fetchWebsites,
  fetchMediaTypes,
  websiteStatusCode,
  feedStatusCode,
  websitesCloseModal,
  userHasSimilarWeb,
}) => {
  useEffect(() => {
    fetchWebsites();
    fetchMediaTypes(0, 150);
  }, [fetchMediaTypes, fetchWebsites]);

  useEffect(() => {
    if (websiteStatusCode) {
      if (websiteStatusCode === CREATED_STATUS) {
        const customMessage = { type: 'success', message: 'Website was added correctly!' };
        getToastedNotification({ customMessage });
      } else {
        getToastedNotification({ responseStatusCode: websiteStatusCode });
      }
    }
  }, [websiteStatusCode]);

  useEffect(() => {
    if (feedStatusCode) {
      if (feedStatusCode === CREATED_STATUS) {
        const customMessage = { type: 'success', message: 'Website feed was added correctly!' };
        getToastedNotification({ customMessage });
      } else {
        getToastedNotification({ responseStatusCode: feedStatusCode });
      }
    }
  });

  useEffect(() => {
    if (feedStatusCode === CREATED_STATUS && websiteStatusCode === CREATED_STATUS) {
      websitesCloseModal();
    }
  }, [feedStatusCode, websiteStatusCode, websitesCloseModal]);

  const urlParams = useParams();

  return (
    <>
      <DynamicDataTable
        urlParams={urlParams}
        title="Websites"
        tableId="websitesTable"
        columns={websitesTableColumn(userHasSimilarWeb)}
        rowsType="websites"
        rowsSelectable={false}
        canSaveArticles={false}
        canSaveWebsite={true}
      />
      <AddWebsiteModal />
    </>
  );
};

Websites.propTypes = {
  fetchWebsites: PropTypes.func,
  websiteStatusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  feedStatusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  websitesCloseModal: PropTypes.func,
};

Websites.defaultProps = {
  fetchWebsites: () => {},
  websiteStatusCode: null,
  feedStatusCode: null,
  websitesCloseModal: () => {},
};

const mapStateToProps = (state) => ({
  websiteStatusCode: getWebsitesAddWebsiteStatus(state),
  feedStatusCode: getWebsitesAddWebsiteFeedStatus(state),
  userHasSimilarWeb: getOrganizationPermissionsHasSimilarWeb(state),
});

export default connect(mapStateToProps, { fetchWebsites, websitesCloseModal, fetchMediaTypes })(Websites);
