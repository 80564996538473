import { combineReducers } from 'redux';

import data from './data';
import filters from './filters/index';
import sortField from './sortField';

const articlesFeed = combineReducers({
  data,
  filters,
  sortField,
});

export default articlesFeed;
