import React, { useRef } from 'react';

import * as Highcharts from 'highcharts';
import AnnotationsFactory from 'highcharts/modules/annotations';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import '../../../assets/scss/theme/Footprints/components/_report.scss';

import ChartButtons from './ChartButtons';
AnnotationsFactory(Highcharts);
// eslint-disable-next-line no-undef
require('highcharts/modules/exporting')(Highcharts);
// eslint-disable-next-line no-undef
require('highcharts/modules/export-data')(Highcharts);

const LineHChart = ({
  config,
  title,
  height,
  isLoading,
  reachValues,
  articlesValues,
  visitsValues,
  reachToggle,
  articlesToggle,
  visitsToggle,
}) => {
  const finalConfig = Object.assign(
    {
      tooltip: {
        style: {
          color: 'white',
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          // don't display the dummy year
          month: '%e. %b',
          year: '%b',
        },
        title: {
          text: 'Date',
        },
        gridLineWidth: 1,
        gridLineColor: '#E6E6E6',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1,
      },
      yAxis: {
        title: {
          text: 'Nº of articles',
          style: {
            fontFamily: 'Jost Medium',
            fontWeight: '600',
            fontSize: '0.75rem',
          },
        },
        gridLineColor: '#E6E6E6',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1,
      },
      legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
      background2: '#505053',
      dataLabelsColor: '#B0B0B3',
      textColor: '#C0C0C0',
      contrastTextColor: '#F0F0F3',
      maskColor: 'rgba(255,255,255,0.3)',
      colors: [
        '#DD1452',
        '#FFDA00',
        '#519F67',
        '#B1CA5E',
        '#B167C9',
        '#EA86B2',
        '#BD343E',
        '#E08A32',
        '#51899F',
        '#80B4B0',
      ],
      credits: {
        enabled: false,
      },
      title: {
        text: title,
        style: {
          fontFamily: 'Jost SemiBold',
          fontWeight: '100',
        },
      },
      subtitle: {
        style: {
          fontFamily: 'Jost SemiBold',
          fontWeight: '100',
        },
      },
      chart: {
        type: 'areaspline',
        style: {
          color: '#333',
          fontFamily: 'Open Sans',
        },
        spacingBottom: 0,
        spacingTop: 20,
        spacingLeft: 10,
        spacingRight: 10,
        height: height - 20,
      },
    },
    { ...config },
  );
  const chart = useRef();

  const handleReachToggle = () => {
    reachToggle(articlesValues, reachValues, visitsValues);
  };

  const handleArticlesToggle = () => {
    articlesToggle(articlesValues, reachValues, visitsValues);
  };

  const handleVisitsToggle = () => {
    visitsToggle(articlesValues, reachValues, visitsValues);
  };

  return (
    <div>
      <div className="line-chart-buttons-container">
        <ChartButtons
          reachValues={reachValues}
          articlesValues={articlesValues}
          visitsValues={visitsValues}
          reachToggle={handleReachToggle}
          articlesToggle={handleArticlesToggle}
          visitsToggle={handleVisitsToggle}
        />
      </div>
      <div className={'block-loader ' + (isLoading && 'loading')}>
        <i className="fa fa-circle-o-notch fa-spin block-loader-spinner" />
      </div>
      <HighchartsReact ref={chart} highcharts={Highcharts} constructorType={'chart'} options={finalConfig} />
    </div>
  );
};

LineHChart.propTypes = {
  title: PropTypes.string,
  isDatetime: PropTypes.bool,
  isLoading: PropTypes.bool,
  config: PropTypes.object,
  height: PropTypes.number,
  reachValues: PropTypes.bool,
  articlesValues: PropTypes.bool,
  visitsValues: PropTypes.bool,
  reachToggle: PropTypes.func,
  articlesToggle: PropTypes.func,
  visitsToggle: PropTypes.func,
};

export default LineHChart;
