import React from 'react';

import { Col, Row } from 'reactstrap';
import uuid from 'uuid/v4';

import { dateFormatter, formatHTMLSymbols } from '../../../../components/ICO/tables/helpers/formatters';

const YoutubeFeedKeywordModalBodyPreview = ({ previewData }) => (
  <Row>
    <Col className="mb-3">
      {previewData?.data.map((video) => (
        <Row key={uuid()} className="mb-3 d-flex flex-row">
          <Col xs={3}>
            <img src={video.thumbnails.url} loading="lazy" width="175" height="100" alt={video.channel_title} />
          </Col>
          <Col xs={9}>
            <Row>
              <Col>
                <a href={`https://www.youtube.com/watch?v=${video.video_id}`}>{formatHTMLSymbols(video.title, true)}</a>
              </Col>
            </Row>
            <Row className="w-25">
              <Col>
                <span className="text-gray text-truncate">{video.channel_title}</span>
              </Col>
            </Row>
            <Row className="d-flex align-self-end">
              <Col xs={3}>
                <span className="text-gray fs--1">{dateFormatter(video.published_at)}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </Col>
  </Row>
);

export default YoutubeFeedKeywordModalBodyPreview;
