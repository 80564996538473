import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

import { InputSelect } from '../../../../components/ICO/forms/InputSelect';
import SimpleDatePicker from '../../../../components/ICO/forms/SimpleDatePicker';
import SingleSelect from '../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../helpers/translate';
import { getLanguagesValueAndLabel } from '../../../../selectors/languages';
import { getNotLockedTags } from '../../../../selectors/tags';

const SavedStreamsModalsEditModalBody = ({ tags, languages, videoData, selectedFields, setSelectedFields }) => {
  useEffect(
    () => () =>
      setSelectedFields({
        tag: videoData.tag,
        language: videoData.language,
        title: videoData.title,
        description: videoData.description,
        followers: videoData.followers,
        published_at: videoData.published_at,
        view_count: videoData.view_count,
      }),
    [setSelectedFields, videoData],
  );

  const handleChangeSelectedField = (field, value) => {
    setSelectedFields({ ...selectedFields, [field]: value });
  };

  const selectedTagValue = selectedFields.tag
    ? { value: selectedFields.tag.id, label: selectedFields.tag.name }
    : tags.filter((tag) => tag.value === videoData.tag.id.toString()) ?? null;

  const selectedLanguageValue = selectedFields.language ? selectedFields.language : videoData.channel_language ?? null;

  const sanitizedText = () => {
    const parser = new DOMParser();
    return parser.parseFromString(`<!doctype html><body>${videoData.title}`, 'text/html').body.textContent;
  };

  return (
    <>
      <Row>
        <Col xs={6}>
          <Label for="tag-select">{translate('common.tag')}</Label>
          <InputSelect
            id="tag-select"
            aria-label="tag-select"
            name="tag-select"
            items={tags}
            placeholder={translate('common.all_tags')}
            disabled={false}
            value={selectedTagValue}
            onChange={(tag) => handleChangeSelectedField('tag', tag ? { value: tag.value, name: tag.label } : null)}
          />
        </Col>
        <Col xs={6}>
          <Label for="languages">{translate('common.language')}</Label>
          <SingleSelect
            id="languages"
            name="languages"
            items={languages}
            value={selectedLanguageValue ?? []}
            onChange={(language) => handleChangeSelectedField('language', language)}
            className="sm-select"
            placeholder={translate('common.all_languages')}
            aria-label="lang-select"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form>
            <FormGroup>
              <Label for="titleInput">{translate('pages.saved_streams.modals.edit_stream.form_labels.title')}</Label>
              <Input
                id="titleInput"
                placeholder={sanitizedText()}
                onChange={(e) => handleChangeSelectedField('title', e.target.value)}
                value={selectedFields.title}
              />
            </FormGroup>
            <FormGroup>
              <Label for="descriptionTextArea">
                {translate('pages.saved_streams.modals.edit_stream.form_labels.description')}
              </Label>
              <Input
                type="textarea"
                id="descriptionTextArea"
                placeholder={videoData.description}
                onChange={(e) => handleChangeSelectedField('description', e.target.value)}
                value={selectedFields.description}
              />
            </FormGroup>
            <FormGroup row title="Stats">
              <Col xs={4}>
                <Label for="views">{translate('pages.saved_streams.modals.edit_stream.form_labels.views')}</Label>
                <Input
                  id="views"
                  placeholder={videoData.view_count}
                  onChange={(e) => handleChangeSelectedField('view_count', e.target.value)}
                  value={selectedFields.view_count}
                  type="number"
                />
              </Col>
              <Col xs={4}>
                <Label for="subscribers">
                  {translate('pages.saved_streams.modals.edit_stream.form_labels.followers')}
                </Label>
                <Input
                  id="subscribers"
                  placeholder={videoData.followers}
                  onChange={(e) => handleChangeSelectedField('followers', e.target.value)}
                  value={selectedFields.followers}
                  type="number"
                />
              </Col>
              <Col xs={4}>
                <Label id="publisheatAt">
                  {translate('pages.saved_streams.modals.edit_stream.form_labels.published_at')}
                </Label>
                <SimpleDatePicker
                  value={selectedFields.published_at}
                  onChange={(e) => handleChangeSelectedField('published_at', e)}
                  id="publishedAt"
                />
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  tags: getNotLockedTags(state),
  languages: getLanguagesValueAndLabel(state),
});

export default connect(mapStateToProps, null)(SavedStreamsModalsEditModalBody);
