/* eslint-disable indent */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import ArticlesBulkForm from './ArticleBulkForm';
import { ArticlesFormContainer } from './ArticleForm';
import BulkDeleteFeed from './BulkDeleteFeed';
import BulkDeleteFeedFooter from './BulkDeleteFeedFooter';
import ClientForm from './ClientForm';
import ConfirmAction from './ConfirmAction';
import { ConfirmKickAction } from './ConfirmKickAction';
import { ConfirmPromoteAction } from './ConfirmPromoteAction';
import { FeedForm } from './FeedForm';
import ImportFeedContainer from './ImportFeedContainer';
import ImportFeedFooter from './ImportFeedFooter';
import InviteOrg from './InviteOrg';
import PreviewModal from './PreviewModal';
import RenameReport from './RenameReport';
import TagForm from './TagForm';
import { editAlert, saveAlert } from '../../../actions/alerts_old';
import { cleanArticleFormData, editArticle, saveArticle, saveArticlesBulk } from '../../../actions/articles';
import { editClient, saveClient } from '../../../actions/clients';
import { editFeed, saveFeed } from '../../../actions/feeds';
import { hideModal, resetForm } from '../../../actions/forms';
import { editProject } from '../../../actions/projects';
import { updateReportName } from '../../../actions/reports';
import { editTag, saveTag } from '../../../actions/tags';
import { tourSetCompletedProjectsSteps } from '../../../actions/tour';
import { fetchCurrentUser } from '../../../actions/users';
import { fetchWebsite } from '../../../actions/websites';
import ArticleTableFilters from '../../../containers/forms/ArticleTableFilters';
import { getIsFirstLogin } from '../../../helpers/localStorage';
import translate from '../../../helpers/translate';
import AlertForm from '../../../pages/Projects/Modals/AlertForm';
import ProjectForm from '../../../pages/Projects/Modals/ProjectForm';
import { getCurrentProjectId } from '../../../selectors/forms';
import { getTourHasSkipped, getTourIsRunning } from '../../../selectors/tour';
import { getModalTitle } from '../helpers/modalRoot.helper';
import getToastedNotification from '../helpers/toaster.helper';

import '../../../assets/scss/theme/Footprints/components/_modal.scss';
import '../../../assets/scss/theme/Footprints/components/_button.scss';

const formModalList = [
  'newFeed',
  'editFeed',
  'editArticle',
  'newArticle',
  'newArticleBulk',
  'importFeed',
  'rejectFeed',
  'newClient',
  'editClient',
  'newProject',
  'editProject',
  'newProject',
  'editAlert',
  'newTag',
  'editTag',
  'renameReport',
];
const LARGE_MODALS = ['previewArticle', 'editAlert'];

const ModalRoot = ({
  formFields,
  isOpen,
  onClose,
  content,
  contentId,
  contentData,
  onConfirm,
  onSave,
  formModalList,
  hasSkippedTour,
}) => {
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const renderModalSize = () => {
    if (LARGE_MODALS.includes(content)) {
      return 'lg';
    }
  };

  const getModalHeaderSize = () => {
    if (LARGE_MODALS.includes(content)) {
      return 'fp-modal-header';
    } else {
      return 'fp-modal-header--small';
    }
  };

  const handleClickOpen = (url) => {
    window.open(url, '_blank');
  };

  const handleOnClose = () => {
    setSaveButtonDisabled(true);
    onClose(hasSkippedTour);
  };

  const renderModalBody = () => {
    if (content === 'previewArticle') {
      return <PreviewModal article={contentData} isFeed={contentData.isFeed} contentId={contentId} />;
    } else if (content === 'articlesTableFilters') {
      return <ArticleTableFilters targetTableId="articlesTable" />;
    } else if (content === 'editTag' || content === 'newTag') {
      return <TagForm setSaveButtonDisabled={setSaveButtonDisabled} />;
    } else if (content === 'editProject') {
      return <ProjectForm />;
    } else if (content === 'editArticle') {
      return (
        <ArticlesFormContainer
          mode="edit"
          setSaveButtonDisabled={setSaveButtonDisabled}
          isSaveButtonDisabled={isSaveButtonDisabled}
        />
      );
    } else if (content === 'newArticle') {
      return (
        <ArticlesFormContainer
          mode="new"
          setSaveButtonDisabled={setSaveButtonDisabled}
          isSaveButtonDisabled={isSaveButtonDisabled}
        />
      );
    } else if (content === 'newArticleBulk') {
      return <ArticlesBulkForm setSaveButtonDisabled={setSaveButtonDisabled} />;
    } else if (content === 'newFeed') {
      return <FeedForm />;
    } else if (content === 'editFeed') {
      return <FeedForm mode={'edit'} />;
    } else if (content === 'editClient' || content === 'newClient') {
      return <ClientForm />;
    } else if (content === 'editAlert') {
      return <AlertForm />;
    } else if (content === 'inviteOrg') {
      return <InviteOrg />;
    } else if (content === 'websitesTableFilters') {
      return (
        <ArticleTableFilters
          targetTableId="websitesTable"
          tagFiltersEnabled={false}
          dateFiltersEnabled={false}
          mtFiltersEnabled={true}
        />
      );
    } else if (content === 'articlesFeedTableFilters') {
      return <ArticleTableFilters targetTableId="articlesFeedTable" tagFiltersEnabled={false} />;
    } else if (content === 'articlesFeedTableBulk') {
      return <ImportFeedContainer />;
    } else if (content === 'articlesFeedTableBulkDelete') {
      return <BulkDeleteFeed />;
    } else if (content === 'confirmAction') {
      return <ConfirmAction name={contentData.data} />;
    } else if (content === 'confirmKickAction') {
      return <ConfirmKickAction username={contentData.data.name} />;
    } else if (content === 'confirmPromoteAction') {
      return <ConfirmPromoteAction username={contentData.data.name} />;
    } else if (content === 'renameReport') {
      return <RenameReport contentData={contentData.data} currentReportName={contentData.data.name} />;
    }
  };
  const renderModalFooter = () => {
    if (content === 'articlesFeedTableBulk') {
      return <ImportFeedFooter />;
    } else if (content === 'articlesFeedTableBulkDelete') {
      return <BulkDeleteFeedFooter />;
    } else if (content === 'confirmAction' || content === 'confirmKickAction' || content === 'confirmPromoteAction') {
      return (
        <>
          <Button size="sm" onClick={onClose} className="fp-button-close">
            Close
          </Button>
          <Button size="sm" onClick={onConfirm} color="primary">
            Confirm
          </Button>
        </>
      );
    } else if (content === 'newArticleBulk' || content === 'newArticle' || content === 'newTag') {
      return (
        <>
          <Button size="sm" onClick={handleOnClose} className="fp-button-close">
            Close
          </Button>
          <Button
            size="sm"
            type="submit"
            onClick={() => {
              onSave(formFields);
            }}
            color="primary"
            disabled={isSaveButtonDisabled}
          >
            Save
          </Button>
        </>
      );
    } else if (formModalList.includes(content)) {
      return (
        <>
          <Button size="sm" onClick={handleOnClose} className="fp-button-close">
            Close
          </Button>
          <Button
            size="sm"
            type="submit"
            onClick={() => {
              onSave(formFields);
            }}
            color="primary"
          >
            Save
          </Button>
        </>
      );
    }
    if (content === 'previewArticle') {
      const url = contentData.fullUrl || contentData.url;
      return (
        <>
          <Button size="sm" onClick={onClose} className="fp-button-close">
            Close
          </Button>
          <Button size="sm" color="primary" onClick={() => handleClickOpen(url)}>
            Open
          </Button>
        </>
      );
    }
    return (
      <Button onClick={onClose} className="fp-button-close">
        Close
      </Button>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      size={renderModalSize()}
      onExit={handleOnClose}
      onClosed={handleOnClose}
      className="fp-modal"
    >
      <ModalHeader className={getModalHeaderSize()}>
        <Row>
          <Col md={3}>
            {LARGE_MODALS.includes(content) && (
              <span className="text-medium fs--1 text-gray">{translate('modals.articles.title')}</span>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h5 className="text-bold">{getModalTitle(content, contentData)}</h5>
          </Col>
        </Row>
      </ModalHeader>
      <Form onSubmit={(e) => e.preventDefault()}>
        <ModalBody className="fp-modal-body">{renderModalBody()}</ModalBody>
        <ModalFooter>{renderModalFooter()}</ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const content = state.forms.modal.content;
  const formFields = () => {
    if (content === 'newArticleBulk') {
      return state.forms.newArticleBulkForm ? state.forms.newArticleBulkForm.fields : {};
    }
    if (content === 'newArticle' || content === 'editArticle') {
      return state.forms.newArticleForm ? state.forms.newArticleForm.fields : {};
    }
    if (content === 'editProject') {
      return state.forms.newProjectForm ? state.forms.newProjectForm.fields : {};
    }
    if (content === 'newTag' || content === 'editTag') {
      return state.forms.newTagForm ? state.forms.newTagForm.fields : {};
    }
    if (content === 'newClient' || content === 'editClient') {
      return state.forms.newClientForm ? state.forms.newClientForm.fields : {};
    }
    if (content === 'newFeed' || content === 'editFeed') {
      return state.forms.newFeedForm ? state.forms.newFeedForm.fields : {};
    }
    if (content === 'editAlert') {
      return state.forms.newAlertForm ? state.forms.newAlertForm.fields : {};
    }
    if (content === 'renameReport') {
      return state.forms.renameReport;
    }

    return state.forms.newArticleForm ? state.forms.newArticleForm.fields : {};
  };
  return {
    formModalList,
    formFields: formFields(),
    currentProjectId: getCurrentProjectId(state),
    isOpen: state.forms.modal.isVisible,
    contentId: state.forms.modal.contentId,
    contentData: state.forms.modal.contentData,
    content,
    isModalForm: formModalList.includes(state.forms.modal.content),
    isTourRunning: getTourIsRunning(state),
    hasSkippedTour: getTourHasSkipped(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onClose: (hasSkippedTour) => {
    dispatch(hideModal());
    if (getIsFirstLogin() && !hasSkippedTour) {
      dispatch(tourSetCompletedProjectsSteps());
    }
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSave: ownProps.onSave
    ? ownProps.onSave
    : (data) => {
        const { dispatch } = dispatchProps;
        const { content, contentData, currentProjectId, hasSkippedTour } = stateProps;

        if (content === 'editAlert') {
          if (data.id) {
            dispatch(editAlert(data));
            dispatch(fetchCurrentUser());
          } else {
            dispatch(saveAlert(data)).then((savedAlertRequest) => {
              if (savedAlertRequest.meta.result.status === 201) {
                const newAlert = Object.values(savedAlertRequest.payload.items)[0];
                dispatch(editProject({ id: data.projectId, alertId: newAlert.id }));
                const customMessage = { type: 'success', message: `Successfully created ${newAlert.name} alert.` };
                getToastedNotification({ customMessage });
                dispatch(fetchCurrentUser());
              }
            });
          }
          if (getIsFirstLogin() && !hasSkippedTour) {
            dispatch(tourSetCompletedProjectsSteps());
          }
          dispatch(hideModal());
          setTimeout(() => dispatch(resetForm()), 300);
        } else if (content === 'newArticleBulk') {
          dispatch(resetForm());
          dispatch(saveArticlesBulk(data)).then((savedBulkRequest) => {
            dispatch(resetForm());
            dispatch(hideModal());
            let statusCode = savedBulkRequest.meta.result.status;
            if (statusCode === 201) {
              let result = savedBulkRequest.payload.items[''];
              const customMessage = { type: 'success', message: `Successfully imported ${result.success} articles.` };
              getToastedNotification({ customMessage });
            } else {
              getToastedNotification({ responseStatusCode: statusCode });
            }
          });
        } else if (content === 'newClient' || content === 'editClient') {
          if (data.id) {
            dispatch(editClient(data));
          } else {
            dispatch(saveClient(data));
          }
          dispatch(hideModal());
          setTimeout(() => dispatch(resetForm()), 300);
        } else if (content === 'newFeed' || content === 'editFeed') {
          if (data.id) {
            dispatch(editFeed(data)).then((res) => {
              dispatch(fetchWebsite(contentData.domain));
              toastifyResult(res, 'Feed has been edited.');
            });
          } else {
            dispatch(
              saveFeed({
                feed: data.feed,
                websiteId: contentData.id,
                reach: contentData.reach,
                muv: contentData.muv,
                language: contentData.language,
                region: contentData.region,
              }),
            ).then((res) => {
              dispatch(fetchWebsite(contentData.domain));
              toastifyResult(res, 'New Feed has been saved.');
            });
          }
          dispatch(hideModal());
          setTimeout(() => dispatch(resetForm()), 300);
        } else if (content === 'editProject') {
          if (data.id) {
            dispatch(editProject(data)).then((res) => toastifyResult(res, 'Project has been edited.'));
          }
          dispatch(hideModal());
          setTimeout(() => dispatch(resetForm()), 300);
        } else if (content === 'newTag' || content === 'editTag') {
          if (data.id) {
            dispatch(editTag(data)).then((res) => toastifyResult(res, 'Tag has been edited.'));
          } else {
            dispatch(saveTag({ ...data, projectId: currentProjectId })).then((res) =>
              toastifyResult(res, 'New Tag has been saved.'),
            );
          }
          dispatch(hideModal());
          setTimeout(() => dispatch(resetForm()), 300);
        } else if (content === 'newArticle' || content === 'editArticle') {
          if (data.id) {
            dispatch(editArticle(data)).then((res) => toastifyResult(res, 'Article has been edited.'));
          } else {
            dispatch(saveArticle(cleanArticleFormData(data))).then((res) =>
              toastifyResult(res, 'New Article has been saved.'),
            );
          }
          dispatch(hideModal());
          setTimeout(() => dispatch(resetForm()), 300); //Timeout to not display the default form values when modal is closing
        } else if (content === 'renameReport') {
          const reportId = stateProps.formFields.id;
          const newReportName = stateProps.formFields.name;
          dispatch(updateReportName(reportId, newReportName)).then((res) => {
            toastifyResult(res, 'Report name updated correctly');
            dispatch(hideModal());
          });
        }
      },
  onConfirm: () => {
    const { contentData } = stateProps;
    const { dispatch } = dispatchProps;
    dispatch(contentData.action).then((res) => {
      if (res.meta.result.status === 200) {
        getToastedNotification({ responseStatusCode: 200 });
      } else if (res.meta.result.status === 204) {
        const customMessage = {
          type: 'success',
          message: `Successfully deleted ${contentData.resource}.`,
        };
        getToastedNotification({ customMessage });
      } else {
        getToastedNotification({ responseStatusCode: res.meta.result.status });
      }
      dispatch(hideModal());
    });
  },
});

export const toastifyResult = (res, message) => {
  if (res.meta.result.status === 204 || res.meta.result.status === 201 || res.meta.result.status === 200) {
    const customMessage = { type: 'success', message };
    getToastedNotification({ customMessage });
  } else {
    getToastedNotification({ responseStatusCode: res.meta.result.status });
  }

  if (res.payload.response && res.payload.response.errors.url) {
    const customMessage = { type: 'error', message: res.payload.response.errors.url };
    getToastedNotification({ customMessage });
  }
};

ModalRoot.propTypes = {
  formFields: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.string,
  contentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contentData: PropTypes.shape({
    isFeed: PropTypes.bool,
    fullUrl: PropTypes.string,
    url: PropTypes.string,
    data: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onConfirm: PropTypes.func,
  onSave: PropTypes.func,
  formModalList: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ModalRoot);
