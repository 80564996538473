import { ARTICLES_FEED_CLEAN, ARTICLES_FEED_SET_SORT_FIELD } from '../../actions/actionTypes';

const initialState = 'pub_date';

const sortField = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLES_FEED_SET_SORT_FIELD:
      return action.payload;
    case ARTICLES_FEED_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default sortField;
