import { combineReducers } from 'redux';

import endDate from './endDate';
import maxViews from './maxViews';
import minViews from './minViews';
import startDate from './startDate';

const filters = combineReducers({ startDate, endDate, minViews, maxViews });

export default filters;
