import React from 'react';

import { connect } from 'react-redux';
import { Button, CustomInput, InputGroup } from 'reactstrap';

import { savedVideosDeleteVideos } from '../../../actions/savedVideos';
import translate from '../../../helpers/translate';

const SavedVideosTableActions = ({ selectedRows, setSelectedRows, savedVideosDeleteVideos }) => {
  const handleClickDelete = () => {
    savedVideosDeleteVideos(selectedRows);
    setSelectedRows([]);
  };

  return (
    <InputGroup size="md" className="input-group flex-center justify-content-around">
      <span className="mr-3">
        {selectedRows.length} {translate('pages.saved_videos.table.actions.rows_selected')}
      </span>
      <CustomInput type="select" id="bulk-select" className="mr-2 text-uppercase">
        <option value="delete">{translate('pages.saved_videos.table.actions.delete_videos')}</option>
      </CustomInput>
      <Button color="primary" onClick={handleClickDelete}>
        {translate('common.apply')}
      </Button>
    </InputGroup>
  );
};

export default connect(null, { savedVideosDeleteVideos })(SavedVideosTableActions);
