import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, CustomInput, InputGroup } from 'reactstrap';

import { fetchTwitchFeed } from '../../../../actions/twitchFeed';
import translate from '../../../../helpers/translate';
import { getCurrentProject } from '../../../../selectors/projects';
import useDeleteVideo from '../../hooks/useDeleteVideo';
import TwitchFeedModalsSaveStreams from '../../Modals/SaveStreams/TwitchFeedModalsSaveStreams.jsx';

const TwitchFeedTableBulkActions = ({ selectedRows = 0, setSelectedRows, project, fetchTwitchFeed }) => {
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const onSuccess = () => {
    fetchTwitchFeed({ twitch_search_id: project['twitch-search-id'], pagination: { page: 1 } });
    setSelectedRows([]);
  };

  const mutation = useDeleteVideo(onSuccess);
  const handleApplyButtonClick = async () => {
    const selectedOption = document.getElementById('bulk-select').value;
    if (selectedOption === 'save') {
      setSaveModalOpen(true);
    }
    if (selectedOption === 'delete') {
      mutation.mutate({ videoData: selectedRows, twitchSearchId: project['twitch-search-id'].toString() });
    }
  };

  return (
    <>
      <InputGroup size="md" className="input-group flex-center justify-content-around">
        {selectedRows.length ? (
          <span className="mr-3">
            {selectedRows.length} {translate('pages.twitch_feed.table.actions.rows_selected')}
          </span>
        ) : null}
        <CustomInput type="select" id="bulk-select" className="mr-2 text-uppercase">
          <option value="save">{translate('common.move_to_saved_coverage')}</option>
          <option value="delete">{translate('pages.twitch_feed.table.actions.delete_videos')}</option>
        </CustomInput>
        <Button color="primary" onClick={handleApplyButtonClick}>
          {translate('common.apply')}
        </Button>
      </InputGroup>
      <TwitchFeedModalsSaveStreams
        selectedRows={selectedRows}
        isOpen={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
});

export default connect(mapStateToProps, { fetchTwitchFeed })(TwitchFeedTableBulkActions);
