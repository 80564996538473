import { useMutation } from 'react-query';

import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';

const useCreateCustomerPortal = () =>
  useMutation({
    mutationKey: 'create_customer_portal',
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/customer_portal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: (data) => data,
    onError: () => {
      const customMessage = {
        type: 'error',
        message: 'There was an error communicating with the payment platform. Please try again or contact support.',
      };

      getToastedNotification({ customMessage });
    },
  });

export default useCreateCustomerPortal;
