import {
  REPORT_VIDEOS_CLEAN,
  REPORTS_FETCH_STREAMS,
  REPORTS_FETCH_STREAMS_ERROR,
  REPORTS_FETCH_STREAMS_FAILURE,
  REPORTS_FETCH_STREAMS_SUCCESS,
  REPORTS_FETCH_VIDEOS,
  REPORTS_FETCH_VIDEOS_ERROR,
  REPORTS_FETCH_VIDEOS_FAILURE,
  REPORTS_FETCH_VIDEOS_SUCCESS,
  REPORTS_SCREENSHOT_ADD_HIGHLIGHTED_ARTICLE,
  REPORTS_SCREENSHOT_CLEAR,
  REPORTS_SCREENSHOT_REMOVE_HIGHLIGHTED_ARTICLE,
  REPORTS_STREAMS_CLEAN,
} from '../actions/actionTypes';
import {
  REQUEST_REPORTS,
  RECEIVE_REPORTS,
  REJECT_REPORTS,
  REQUEST_REPORT,
  RECEIVE_REPORT,
  REJECT_REPORT,
  REQUEST_SAVE_REPORT,
  RECEIVE_SAVE_REPORT,
  REJECT_SAVE_REPORT,
  RECEIVE_DELETED_REPORT,
  UPDATE_REPORT,
  UPDATE_REPORT_RECEIVE,
  UPDATE_REPORT_REJECT,
  NEW_REPORT_SET_SELECTED_TAGS,
  NEW_REPORT_FETCH_SAVED_VIDEOS_SUCCESS,
  NEW_REPORT_SET_SELECTED_LANGUAGES,
  NEW_REPORT_FETCH_SAVED_STREAMS_SUCCESS,
} from '../actions/reports';
import {
  apiReceive,
  apiReceiveSaved,
  apiRequest,
  apiRequestSave,
  apiReject,
  apiRequestOne,
  apiReceiveOne,
  apiRejectOne,
  apiReceiveDeleted,
} from '../config/api-service';

const initialState = {
  items: {},
  itemsStatus: {},
  lists: {},
  listsStatus: {},
  stats: {},
  highlightedArticles: [],
  selectedTags: [],
  selectedLanguages: [],
  videos: [],
  savedVideosReport: [],
  savedStreamsReport: [],
  reportVideos: {
    isLoading: false,
    response: [],
    error: null,
  },
  reportStreams: {
    isLoading: false,
    response: [],
    error: null,
  },
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_REPORTS:
      return apiRequest(state, action);
    case RECEIVE_REPORTS:
      return apiReceive(state, action);
    case REJECT_REPORTS:
      return apiReject(state, action);
    case REQUEST_REPORT:
      return apiRequestOne(state, action);
    case RECEIVE_REPORT:
      return apiReceiveOne(state, action);
    case REJECT_REPORT:
      return apiRejectOne(state, action);
    case REQUEST_SAVE_REPORT:
      return apiRequestSave(state, action);
    case RECEIVE_SAVE_REPORT:
      return apiReceiveSaved(state, action);
    case RECEIVE_DELETED_REPORT:
      return apiReceiveDeleted(state, action);
    case REJECT_SAVE_REPORT:
      return apiReject(state, action);
    case UPDATE_REPORT:
      return apiRequest(state, action);
    case UPDATE_REPORT_RECEIVE:
      return apiReceive(state, action);
    case UPDATE_REPORT_REJECT:
      return apiReject(state, action);
    case REPORTS_SCREENSHOT_ADD_HIGHLIGHTED_ARTICLE:
      return { ...state, highlightedArticles: [...state.highlightedArticles, action.payload] };
    case REPORTS_SCREENSHOT_REMOVE_HIGHLIGHTED_ARTICLE:
      return {
        ...state,
        highlightedArticles: state.highlightedArticles.filter((articleId) => articleId !== action.payload),
      };
    case NEW_REPORT_SET_SELECTED_TAGS:
      return { ...state, selectedTags: action.payload };
    case NEW_REPORT_SET_SELECTED_LANGUAGES:
      return { ...state, selectedLanguages: action.payload };
    case NEW_REPORT_FETCH_SAVED_VIDEOS_SUCCESS: {
      if (action.payload && action.payload.videos) {
        return { ...state, savedVideosReport: action.payload.videos.map((videos) => videos.id) };
      } else {
        return state;
      }
    }
    case NEW_REPORT_FETCH_SAVED_STREAMS_SUCCESS: {
      if (action.payload && action.payload.streams) {
        return { ...state, savedStreamsReport: action.payload.streams.map((streams) => streams.id) };
      } else {
        return state;
      }
    }
    case REPORT_VIDEOS_CLEAN:
      return { ...state, reportVideos: { isLoading: false, response: [], error: null } };
    case REPORTS_STREAMS_CLEAN:
      return { ...state, reportStreams: { isLoading: false, response: [], error: null } };
    case REPORTS_SCREENSHOT_CLEAR:
      return initialState;
    case REPORTS_FETCH_VIDEOS:
      return { ...state, reportVideos: { ...state.reportVideos, isLoading: true } };
    case REPORTS_FETCH_VIDEOS_SUCCESS:
      return { ...state, reportVideos: { ...state.reportVideos, isLoading: false, response: action.payload } };
    case REPORTS_FETCH_VIDEOS_FAILURE:
    case REPORTS_FETCH_VIDEOS_ERROR:
      return { ...state, reportVideos: { ...state.reportVideos, error: action.payload } };
    case REPORTS_FETCH_STREAMS:
      return { ...state, reportStreams: { ...state.reportStreams, isLoading: true } };
    case REPORTS_FETCH_STREAMS_SUCCESS:
      return { ...state, reportStreams: { ...state.reportStreams, isLoading: false, response: action.payload } };
    case REPORTS_FETCH_STREAMS_FAILURE:
    case REPORTS_FETCH_STREAMS_ERROR:
      return { ...state, reportStreams: { ...state.reportStreams, error: action.payload } };
    default:
      return state;
  }
};

export default reports;
