import React, { useState } from 'react';

import dayjs from 'dayjs';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

import { twitchFeedTableFiltersSetStartDate, twitchFeedTableFiltersSetEndDate } from '../../../../actions/twitchFeed';
import RangeDateFilter from '../../../../components/ICO/forms/RangeDateFilter';
import translate from '../../../../helpers/translate';
import {
  getTwitchFeedTableFiltersEndDate,
  getTwitchFeedTableFiltersStartDate,
} from '../../../../selectors/feeds/twitchFeed';

const allTimeDateTimeId = 1;
const specificDateTimeId = 2;

const TwitchFeedTableFiltersModalBodyDateFilter = ({
  startDate,
  endDate,
  twitchFeedTableFiltersSetEndDate,
  twitchFeedTableFiltersSetStartDate,
}) => {
  const defaultChecked = startDate || endDate ? specificDateTimeId : allTimeDateTimeId;

  const [checkedOption, setCheckedOption] = useState(defaultChecked);
  const defaultDateRange = startDate || endDate ? [startDate, endDate] : [moment().startOf('month'), moment()];
  const [localDateRange, setLocalDateRange] = useState(defaultDateRange);

  const onChangeDate = (datePickerData) => {
    const endDate = dayjs(datePickerData.endDate).format('YYYY-MM-DD');
    const startDate = dayjs(datePickerData.startDate).format('YYYY-MM-DD');
    setLocalDateRange([startDate, endDate]);
    twitchFeedTableFiltersSetStartDate(startDate);
    twitchFeedTableFiltersSetEndDate(endDate);
  };

  const handleAllTime = () => {
    setLocalDateRange([]);
    setCheckedOption(allTimeDateTimeId);
    twitchFeedTableFiltersSetStartDate(null);
    twitchFeedTableFiltersSetEndDate(null);
  };

  return (
    <>
      <Label for="keywordInput">{translate('pages.twitch_feed.table.filters.dates.title')}</Label>
      <FormGroup className="form-check">
        <Input
          aria-label="allTimeCheck"
          type="radio"
          name="radio1"
          role="radio"
          onClick={handleAllTime}
          checked={checkedOption === allTimeDateTimeId}
          readOnly
        />
        <Label check>{translate('pages.twitch_feed.table.filters.dates.all_time')}</Label>
      </FormGroup>
      <FormGroup className="form-check">
        <Input
          aria-label="specificTimeCheck"
          type="radio"
          name="radio2"
          role="radio"
          onClick={() => setCheckedOption(specificDateTimeId)}
          checked={checkedOption === specificDateTimeId}
          readOnly
        />
        <Label check>{translate('pages.twitch_feed.table.filters.dates.specific')}</Label>
        <RangeDateFilter
          aria-label="rangeFilter"
          className="daterange-predefined"
          disabled={checkedOption !== 2}
          onChange={(e, datePickerData) => onChangeDate(datePickerData)}
          start={localDateRange[0]}
          end={localDateRange[1]}
        />
      </FormGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  startDate: getTwitchFeedTableFiltersStartDate(state),
  endDate: getTwitchFeedTableFiltersEndDate(state),
});

export default connect(mapStateToProps, { twitchFeedTableFiltersSetStartDate, twitchFeedTableFiltersSetEndDate })(
  TwitchFeedTableFiltersModalBodyDateFilter,
);
