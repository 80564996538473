import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { FootprintsContext } from '../../../context/Context';
import ModalRoot from '../modals/ModalRoot';

const FootprintsProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const value = {
    modalContent,
    setModalContent,
    modalOpen,
    setModalOpen,
  };

  return (
    <FootprintsContext.Provider value={value}>
      {children}
      <ModalRoot />
    </FootprintsContext.Provider>
  );
};

FootprintsProvider.propTypes = {
  children: PropTypes.node,
};

export default FootprintsProvider;
