import { useMutation } from 'react-query';

import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';

const useCreateKeyword = (onClose) =>
  useMutation({
    mutationKey: 'create_keyword',
    mutationFn: async ({
      keyword,
      projectId,
      slack_min_subs_notification,
      slack_webhook_url,
      discord_webhook_url,
      discord_min_subs_notification,
    }) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/youtube_feed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          keyword,
          project_id: projectId,
          slack_min_subs_notification,
          slack_webhook_url,
          discord_webhook_url,
          discord_min_subs_notification,
        }),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: translate('pages.youtube_feed.notifications.keyword_created'),
      };
      getToastedNotification({ customMessage });
      onClose();
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: translate('pages.youtube_feed.notifications.keyword_error'),
      };
      getToastedNotification({ customMessage });
    },
  });

export default useCreateKeyword;
