import { apiFetch } from '../config/api-service';

export const REQUEST_LANGUAGES = 'REQUEST_LANGUAGES';
export const REJECT_LANGUAGES = 'REJECT_LANGUAGES';
export const RECEIVE_LANGUAGES = 'RECEIVE_LANGUAGES';

export const fetchLanguages = (
  params = {
    page: {
      page: 0,
      page_size: 150,
    },
  },
) =>
  apiFetch(
    'languages',
    {
      request: REQUEST_LANGUAGES,
      receive: RECEIVE_LANGUAGES,
      reject: REJECT_LANGUAGES,
    },
    params,
  );
