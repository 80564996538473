import { useMutation } from 'react-query';

import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';

const keys = {
  export: '/supersearch/export_csv',
  save: '/articles',
};

export const useExport = () =>
  useMutation({
    mutationKey: keys.export,
    mutationFn: async (data) => {
      const url = `${COVERAGE_API_ROOT}${keys.export}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: 'Your export is being processed. You will receive it on your email in a few minutes!',
      };
      getToastedNotification({ customMessage });
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: 'There was an error processing your export. Try again or contact support.',
      };
      getToastedNotification({ customMessage });
    },
  });

export const useSaveSupersearchArticle = (onSuccess) =>
  useMutation({
    mutationKey: keys.save,
    mutationFn: async (data) => {
      const url = `${COVERAGE_API_ROOT}${keys.save}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message: 'Article saved on your Saved coverage!',
      };
      getToastedNotification({ customMessage });
      onSuccess();
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: 'There was an error saving the article. Try again or contact support.',
      };
      getToastedNotification({ customMessage });
    },
  });
