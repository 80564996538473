import {
  TOUR_CLEAR,
  TOUR_SET_HAS_COMPLETED_PROJECTS,
  TOUR_SET_HAS_SKIPPED,
  TOUR_SET_IS_RUNNING,
  TOUR_SET_NEXT_STEP,
} from '../actions/actionTypes';

const defaultState = {
  isRunning: false,
  step: 0,
  hasCompletedProjectsSteps: false,
  hasSkipped: false,
};

const tour = (state = defaultState, action) => {
  switch (action.type) {
    case TOUR_SET_IS_RUNNING:
      return { ...state, isRunning: action.payload };
    case TOUR_SET_NEXT_STEP:
      return { ...state, step: action.payload };
    case TOUR_SET_HAS_COMPLETED_PROJECTS:
      return { ...state, hasCompletedProjectsSteps: true };
    case TOUR_SET_HAS_SKIPPED:
      return { ...state, hasSkipped: true };
    case TOUR_CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export default tour;
