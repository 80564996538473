import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Button, CustomInput, InputGroup } from 'reactstrap';

import { fetchYoutubeFeed } from '../../../actions/youtubeFeed';
import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';
import { getOrganizationId } from '../../../selectors/organizations';
import { getCurrentProject } from '../../../selectors/projects';
import YoutubeFeedSaveVideo from '../Modals/BulkActions/YoutubeFeedSaveVideo.jsx';

const YoutubeFeedTableActions = ({
  selectedRows = 0,
  setSelectedRows,
  project,
  fetchYoutubeFeed,
  userOrganizationId,
}) => {
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);

  const handleOnCloseModal = () => {
    setSaveModalOpen(false);
    setSelectedRows([]);
  };

  const handleApplyButtonClick = async () => {
    const selectedOption = document.getElementById('bulk-select').value;
    if (selectedOption === 'save') {
      setSaveModalOpen(true);
    } else if (selectedOption === 'delete') {
      const url = `${COVERAGE_API_ROOT}/youtube_feed`;
      const requestConfig = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          videos: selectedRows,
          keyword_id: project['youtube-search-id'].toString(),
        }),
      };

      const request = await fetch(url, requestConfig);

      if (request.ok) {
        const customMessage = {
          type: 'success',
          message: translate('pages.youtube_feed.notifications.delete_videos_success'),
        };
        getToastedNotification({ customMessage });
        fetchYoutubeFeed({
          youtube_search_id: project['youtube-search-id'],
          pagination: { page: 1 },
          filters: {
            organization_id: userOrganizationId,
          },
        });
      } else {
        getToastedNotification({ responseStatusCode: request.status });
      }
      setSelectedRows([]);
    }
  };

  return (
    <>
      <InputGroup size="md" className="input-group flex-center justify-content-around">
        {selectedRows.length ? (
          <span className="mr-3">
            {selectedRows.length} {translate('pages.youtube_feed.table.actions.rows_selected')}
          </span>
        ) : null}
        <CustomInput type="select" id="bulk-select" className="mr-2 text-uppercase">
          <option value="save">{translate('common.move_to_saved_coverage')}</option>
          <option value="delete">{translate('pages.youtube_feed.table.actions.delete_videos')}</option>
        </CustomInput>
        <Button color="primary" onClick={handleApplyButtonClick}>
          {translate('common.apply')}
        </Button>
      </InputGroup>
      <YoutubeFeedSaveVideo
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        isOpen={isSaveModalOpen}
        onClose={handleOnCloseModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  userOrganizationId: getOrganizationId(state),
});

export default connect(mapStateToProps, { fetchYoutubeFeed })(YoutubeFeedTableActions);
