import React, { Component } from 'react';

import PropTypes from 'prop-types';

class ModuleGrid extends Component {
  render() {
    return <div id={this.props.id}>{this.props.children}</div>;
  }
}

ModuleGrid.propTypes = {
  id: PropTypes.any,
  children: PropTypes.any,
};

export default ModuleGrid;
