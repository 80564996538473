import { useQuery } from 'react-query';

import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';

const useGetTwitchGames = (searchTerm) =>
  useQuery({
    queryKey: ['get_twitch_games', searchTerm],
    queryFn: async (params) => {
      const url = `${COVERAGE_API_ROOT}/twitch_feed/preview?searchTerm=${params.queryKey[1]}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    enabled: !!searchTerm && searchTerm.length > 2,
    retry: false,
    refetchOnWindowFocus: true,
  });

export default useGetTwitchGames;
