import dayjs from 'dayjs';

import { getOrganizationPermissionsHasData } from './permissions';
export const getSuperSearchSearchTerm = (state) => state.superSearch.filters.searchTerm;
export const getSuperSearchFromDate = (state) => state.superSearch.filters.fromDate;
export const getSuperSearchToDate = (state) => state.superSearch.filters.toDate;
export const getSuperSearchSearchButtonIsDisabled = (state) =>
  !getSuperSearchSearchTerm(state) ||
  !getSuperSearchFromDate(state) ||
  !getSuperSearchToDate(state) ||
  !getOrganizationPermissionsHasData(state);

export const getSuperSearchVolumeResponse = (state) => {
  if (state.superSearch.volume.response) {
    return state.superSearch.volume.response.map((data) => data.articles);
  }
};

export const getSuperSearchVolumeSeriesName = (state) => {
  if (state.superSearch.volume.response) {
    return state.superSearch.volume.response.map((data) => dayjs(data.inserted_at).format('YYYY-MM-DD'));
  }
};

export const getSuperSearchTotalResults = (state) => {
  if (state.superSearch.volume.response) {
    let sum = 0;
    state.superSearch.volume.response.forEach((items) => (sum = sum + items.articles));
    return sum;
  }
};

export const getSuperSearchVolumeIsLoading = (state) => state.superSearch.volume.isLoading;
export const getSuperSearchFiltersMinScore = (state) => state.superSearch.filters.minScore;
export const getSuperSearchFiltersMaxScore = (state) => state.superSearch.filters.maxScore;
export const getSuperSearchFiltersLanguages = (state) => state.superSearch.filters.languages;
export const getSuperSearchData = (state) =>
  state.superSearch.data?.response?.data?.map((items) => ({
    ...items,
    media: items.url,
  }));
export const getSuperSearchDataPagination = (state) => {
  if (state.superSearch.data?.response.pagination) {
    return {
      totalItems: state.superSearch.data.response.pagination.total,
      lastPage: state.superSearch.data.response.pagination.lastPage,
      page: state.superSearch.data.response.pagination.page,
      nextPage: state.superSearch.data.response.pagination.nextPage,
    };
  }
};

export const getSuperSearchIsLoading = (state) =>
  state?.superSearch?.data?.isLoading || state?.superSearch?.volume?.isLoading;
export const getSuperSearchError = (state) =>
  state?.superSearch?.data?.error?.length || state?.superSearch?.volume?.error;
