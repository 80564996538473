import { PROJECTS_SET_QUERY } from './actionTypes';
import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const REQUEST_PROJECTS = 'REQUEST_PROJECTS';
export const REJECT_PROJECTS = 'REJECT_PROJECTS';
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS';

export const REQUEST_SAVE_PROJECT = 'REQUEST_SAVE_PROJECT';
export const REJECT_SAVE_PROJECT = 'REJECT_SAVE_PROJECT';
export const RECEIVE_SAVE_PROJECT = 'RECEIVE_SAVE_PROJECT';

export const fetchProjects = (
  params = {
    page: {
      page: 0,
      page_size: 500,
    },
  },
) =>
  apiFetch(
    'projects',
    {
      request: REQUEST_PROJECTS,
      receive: RECEIVE_PROJECTS,
      reject: REJECT_PROJECTS,
    },
    params,
  );

export const saveProject = (
  data = {
    name: 'Test Project Test',
    clientId: 2,
    alertId: 0,
    avatar: 'default.png',
  },
) =>
  apiFetch(
    'projects',
    {
      request: {
        type: REQUEST_SAVE_PROJECT,
        payload: data,
      },
      receive: RECEIVE_SAVE_PROJECT,
      reject: REJECT_SAVE_PROJECT,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'project',
          attributes: {
            name: data.name,
            avatar: data.avatar,
            alert_id: data.alertId,
          },
          relationships: {
            client: {
              data: {
                type: 'client',
                id: data.clientId,
              },
            },
          },
        },
      }),
    },
    {
      method: 'POST',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );

export const editProject = (
  data = {
    name: 'Test Project Test',
    clientId: 2,
    alertId: 0,
    avatar: 'default.png',
    locked: false,
  },
) => {
  let relationshipsPart;

  if (data.clientId) {
    relationshipsPart = {
      relationships: {
        client: {
          data: {
            type: 'client',
            id: data.clientId,
          },
        },
      },
    };
  } else {
    relationshipsPart = null;
  }

  return apiFetch(
    `projects/${data.id}`,
    {
      request: {
        type: REQUEST_SAVE_PROJECT,
        payload: data,
      },
      receive: RECEIVE_SAVE_PROJECT,
      reject: REJECT_SAVE_PROJECT,
    },
    {
      body: JSON.stringify({
        data: {
          type: 'project',
          attributes: {
            ...(data.name && { name: data.name }),
            ...(data.avatar && { avatar: data.avatar }),
            ...(data.alertId && { alert_id: data.alertId }),
            ...(data.locked != null && { locked: data.locked }),
          },
          ...relationshipsPart,
        },
      }),
    },
    {
      method: 'PATCH',
      urlRoot: COVERAGE_API_ROOT,
      headers: defaultRequestOptions().headers,
    },
  );
};

export const projectsSetQuery = (query) => ({
  type: PROJECTS_SET_QUERY,
  payload: query,
});
