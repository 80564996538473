import { useMutation } from 'react-query';

import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';

const usePreview = () =>
  useMutation({
    mutationKey: 'get_feed_preview',
    mutationFn: async (keyword) => {
      if (keyword) {
        const response = await fetch(`${COVERAGE_API_ROOT}/youtube_feed/preview`, {
          method: 'POST',
          urlRoot: COVERAGE_API_ROOT,
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ keyword: keyword }),
        });

        if (!response.ok) {
          throw new Error('Something went wrong.');
        } else {
          return await response.json();
        }
      }
    },
  });

export default usePreview;
