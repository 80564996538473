import {
  FETCH_REGIONS,
  FETCH_REGIONS_ERROR,
  FETCH_REGIONS_FAILURE,
  FETCH_REGIONS_SUCCESS,
} from '../actions/actionTypes';

const defaultState = {
  isLoading: false,
  response: [],
  error: null,
};

const regions = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REGIONS:
      return { ...state, isLoading: true };
    case FETCH_REGIONS_SUCCESS:
      return { ...state, isLoading: false, response: action.payload.data };
    case FETCH_REGIONS_ERROR:
    case FETCH_REGIONS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default regions;
