import React from 'react';

import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

import translate from '../../../../helpers/translate';
import YoutubeFeedKeywordModalBodyNotifications from '../Keyword/YoutubeFeedKeywordModalBodyNotifications.jsx';

const YoutubeFeedEditFeedModalBody = ({ keyword, setKeyword, previousKeyword }) => (
  <>
    <Row>
      <Col>
        <Form onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Label for="keywordInput">{translate('pages.youtube_feed.keyword_input.label')}</Label>
            <Input
              placeholder={previousKeyword}
              name="keywordInput"
              onChange={(event) => setKeyword(event.target.value)}
              invalid={keyword && keyword.length < 3}
              defaultValue={previousKeyword}
            />
            <FormFeedback>{translate('pages.youtube_feed.keyword_input.feedback')}</FormFeedback>
          </FormGroup>
          <YoutubeFeedKeywordModalBodyNotifications />
        </Form>
      </Col>
    </Row>
  </>
);

export default YoutubeFeedEditFeedModalBody;
