import React from 'react';

import { Col, FormGroup, Row } from 'reactstrap';
import Input from 'reactstrap/lib/Input';

import translate from '../../../../../helpers/translate';

const AdvancedQueryInput = ({ stateAction, value = null }) => {
  const onChange = (event) => {
    stateAction(event.target.value);
  };

  return (
    <FormGroup>
      <Row>
        <Col xs={12}>
          <label>{translate('modals.alert.keywords')}</label>
          <Input
            aria-label="alert-input"
            type="text"
            onChange={onChange}
            placeholder="project name +company ..."
            className="form-control"
            defaultValue={value}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default AdvancedQueryInput;
