import {
  RECEIVE_SLACK_AUTH,
  REQUEST_SLACK_AUTH,
  RECEIVE_USER_PROFILE,
  REJECT_SLACK_AUTH,
} from '../../../actions/users';
import { STATUS } from '../../../config/api-service';

const initialState = {
  currentOrg: null,
  currentOrgStatus: STATUS.LOADING,
};

const original = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_USER_PROFILE:
      return {
        ...state,
        currentOrg: Object.values(action.payload.items)[0].organization,
        currentOrgStatus: STATUS.OK,
      };
    case REQUEST_SLACK_AUTH:
      return { ...state, currentOrgStatus: STATUS.LOADING };
    case REJECT_SLACK_AUTH:
      return { ...state, currentOrgStatus: STATUS.KO };
    case RECEIVE_SLACK_AUTH:
      return {
        ...state,
        currentOrg: action.payload.items[1],
        currentOrgStatus: STATUS.OK,
      };
    default:
      return state;
  }
};

export default original;
