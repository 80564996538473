import React from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import translate from '../../../../helpers/translate';
import ButtonIcon from '../../../common/ButtonIcon';

const ButtonIconWithTooltip = ({
  text,
  id,
  icon,
  disabled = false,
  className,
  onClick,
  disabledText = 'tiers.feature_disabled',
  ...props
}) => {
  const style = disabled ? { pointerEvents: 'none' } : {};

  return (
    <div id={id} style={{ display: 'inline-block', cursor: 'not-allowed' }}>
      <ButtonIcon
        className={className}
        style={style}
        icon={icon}
        disabled={disabled}
        color="primary"
        size="sm"
        onClick={onClick}
        {...props}
      >
        {text}
      </ButtonIcon>
      {disabled ? (
        <UncontrolledTooltip target={id} placement="bottom">
          {translate(disabledText)}
        </UncontrolledTooltip>
      ) : null}
    </div>
  );
};

export default ButtonIconWithTooltip;
