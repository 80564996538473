import debounce from 'lodash/debounce';
import { connect } from 'react-redux';

import OneLineEditor from './OneLineEditor';
import { changeNewReportForm } from '../../actions/forms';

const mapDispatchToProps = (dispatch, ownProps) => {
  const onChange = (field, value) => {
    dispatch(
      changeNewReportForm({
        field,
        value,
      }),
    );
  };

  let onChangeHTML = (HTML) => onChange(ownProps.titleField, HTML);
  onChangeHTML = debounce(onChangeHTML, 250);

  return {
    onChangeHTML,
  };
};

export default connect(null, mapDispatchToProps)(OneLineEditor);
