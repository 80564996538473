import $ from 'jquery';

import { RECEIVE_WEBSITES, REJECT_WEBSITES, REQUEST_WEBSITES } from '../actions/websites';
import { setItemsStatus } from '../config/api-service';

const initialState = {
  items: {},
  itemsStatus: {},
  lists: {},
  listsStatus: {},
  stats: {},
};

const STATUS = {
  NOT_FOUND: 'NOT_FOUND',
  OK: 'OK',
  KO: 'KO',
  SAVED: 'SAVED',
  DELETING: 'DELETING',
  LOADING: 'LOADING',
  DELETED: 'DELETED',
};

const genGetParams = (params) => decodeURIComponent($.param(params));

const websites = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_WEBSITES: {
      const listKey = genGetParams(action.meta.request.params);
      return { ...state, listsStatus: { ...state.listsStatus, [listKey]: STATUS.LOADING } };
    }
    case RECEIVE_WEBSITES: {
      const listKey = genGetParams(action.meta.request.params);
      return {
        items: Object.assign({}, state.items, action.payload.items),
        itemsStatus: Object.assign({}, state.itemsStatus, setItemsStatus(STATUS.OK, action.payload.items)),
        lists: { ...state.lists, [listKey]: Object.keys(action.payload.items).map((itemId) => itemId) },
        stats: { ...state.stats, [listKey]: action.payload.pagination },
        listsStatus: { ...state.listsStatus, [listKey]: STATUS.OK },
      };
    }
    case REJECT_WEBSITES: {
      const listKey = genGetParams(action.meta.request.params);
      return { ...state, listsStatus: { ...state.listsStatus, [listKey]: STATUS.KO } };
    }
    default:
      return state;
  }
};

export default websites;
