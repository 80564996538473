import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const ChartButton = ({ onClick, className, title, glyph }) => {
  const [ttClass, setTTClass] = useState('');
  const [ttDisplay, setTTDisplay] = useState({ display: 'none', top: '31px', left: '-16px' });

  const handleMouseOver = () => {
    setTTClass('in');
    setTTDisplay({ display: 'block', top: '31px', left: '-16px' });
  };

  const handleMouseOut = () => {
    setTTClass('');
    setTTDisplay({ display: 'none', top: '0px', left: '-16px' });
  };

  return (
    <div>
      <button
        onClick={onClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={className}
        title={title}
      >
        <FontAwesomeIcon icon={glyph} />
        <div className={`tooltip fade bottom ${ttClass}`} style={ttDisplay} onMouseOver={handleMouseOut}>
          <div className="tooltip-arrow" style={{ left: '50%' }} />
          <div className="tooltip-inner" style={{ maxWidth: '500px' }}>
            {title}
          </div>
        </div>
      </button>
    </div>
  );
};

ChartButton.propTypes = {
  active: PropTypes.bool,
  glyph: PropTypes.string,
  title: PropTypes.string,
  tooltipContent: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ChartButton;
