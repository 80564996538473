import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';

import { saveAvatar } from '../../../actions/files';
import { changeClientForm } from '../../../actions/forms';
import { IMAGES_ROOT } from '../../../config/global-config';
import translate from '../../../helpers/translate';
import ImageUploader from '../uploads/ImageUploader';

const ClientFormComp = ({ onChange, formFields, onSelectImage }) => {
  const handleTextInputChange = (event) => {
    onChange({ field: 'name', value: event.target.value });
  };

  return (
    <>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <input
              type="text"
              onChange={(e) => handleTextInputChange(e, 'name')}
              placeholder={translate('common.brand')}
              value={formFields.name}
              className="form-control"
            />
            <input
              type="hidden"
              onChange={(e) => handleTextInputChange(e, 'id')}
              value={formFields.id ? formFields.id : ''}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <ImageUploader urlRoot={IMAGES_ROOT} onSelectImage={onSelectImage} multiple={false} data={formFields} />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  formTitle: state.forms.newClientForm ? state.forms.newClientForm.title : '',
  formFields: state.forms.newClientForm ? state.forms.newClientForm.fields : {},
  showModal: state.forms.modal.isVisible,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (data) => dispatch(changeClientForm(data)),
  onSelectImage: (data) => {
    dispatch(changeClientForm({ field: 'avatar', value: data[0].name }));
    dispatch(saveAvatar({ images: data, scope: '0;client' }));
  },
});

ClientFormComp.propTypes = {
  onChange: PropTypes.func,
  formFields: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
  onSelectImage: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientFormComp);
