import React, { useState } from 'react';

import dayjs from 'dayjs';
import moment from 'moment';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import {
  savedVideosTableFiltersSetStartDate,
  savedVideosTableFiltersSetEndDate,
} from '../../../../actions/savedVideos';
import RangeDateFilter from '../../../../components/ICO/forms/RangeDateFilter';
import translate from '../../../../helpers/translate';
import {
  getSavedVideosFiltersStartDate,
  getSavedVideosFiltersEndDate,
} from '../../../../selectors/savedCoverage/savedVideos';

const allTimeDateTimeId = 1;
const specificDateTimeId = 2;

const SavedVideosFiltersDates = ({
  startDate,
  endDate,
  savedVideosTableFiltersSetStartDate,
  savedVideosTableFiltersSetEndDate,
}) => {
  const [checkedOption, setCheckedOption] = useState(allTimeDateTimeId);
  const defaultDateRange = startDate || endDate ? [startDate, endDate] : [moment().startOf('month'), moment()];
  const [localDateRange, setLocalDateRange] = useState(defaultDateRange);

  const onChangeDate = (datePickerData) => {
    const endDate = dayjs(datePickerData.endDate).format('YYYY-MM-DD');
    const startDate = dayjs(datePickerData.startDate).format('YYYY-MM-DD');
    setLocalDateRange([startDate, endDate]);
    savedVideosTableFiltersSetStartDate(startDate);
    savedVideosTableFiltersSetEndDate(endDate);
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Label for="keywordInput">{translate('pages.saved_videos.table.filters.dates.title')}</Label>
      <FormGroup className="form-check">
        <Input
          type="radio"
          name="radio1"
          onClick={() => setCheckedOption(allTimeDateTimeId)}
          checked={checkedOption === allTimeDateTimeId}
          readOnly
        />
        <Label check>{translate('pages.saved_videos.table.filters.dates.all_time')}</Label>
      </FormGroup>
      <FormGroup className="form-check">
        <Input
          type="radio"
          name="radio2"
          onClick={() => setCheckedOption(specificDateTimeId)}
          checked={checkedOption === specificDateTimeId}
          readOnly
        />
        <Label check>{translate('pages.saved_videos.table.filters.dates.specific')}</Label>
        <RangeDateFilter
          className="daterange-predefined"
          disabled={checkedOption !== 2}
          onChange={(e, datePickerData) => onChangeDate(datePickerData)}
          start={localDateRange[0]}
          end={localDateRange[1]}
        />
      </FormGroup>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  startDate: getSavedVideosFiltersStartDate(state),
  endDate: getSavedVideosFiltersEndDate(state),
});

export default connect(mapStateToProps, { savedVideosTableFiltersSetStartDate, savedVideosTableFiltersSetEndDate })(
  SavedVideosFiltersDates,
);
