import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Row, Input, Label, FormFeedback } from 'reactstrap';

import {
  websitesAddLanguage,
  websitesAddReach,
  websitesAddDomain,
  websitesAddDomainFeed,
} from '../../actions/websites';
import SingleSelect from '../../components/ICO/forms/SingleSelect';
import { getWebsitesAddWebsiteDomainIsValid, getWebsitesAddWebsiteFeedIsValid } from '../../selectors/websites';

const REACH = [0.1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const websiteReach = REACH.map((reach) => ({
  value: reach,
  label: reach,
}));

const AddWebsiteModalBody = ({
  domain,
  isDomainValid,
  isFeedValid,
  feed,
  language,
  languages,
  reach,
  websitesAddLanguage,
  websitesAddReach,
  websitesAddDomain,
  websitesAddDomainFeed,
}) => {
  const handleChangeURL = (event) => {
    websitesAddDomain(event.target.value);
  };

  const handleChangeReach = ({ value }) => {
    websitesAddReach(value);
  };

  const handleChangeLanguage = ({ value }) => {
    websitesAddLanguage(value);
  };

  const handleChangeFeed = (event) => {
    websitesAddDomainFeed(event.target.value);
  };

  return (
    <>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <Label>URL</Label>
            <Input
              type="text"
              aria-label="url-input"
              onChange={handleChangeURL}
              invalid={domain && !isDomainValid}
              valid={isDomainValid}
            />
            <FormFeedback>Ups! Website URL is not valid</FormFeedback>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <Label>Feed URL</Label>
            <Input
              type="text"
              aria-label="feed-input"
              onChange={handleChangeFeed}
              invalid={feed && !isFeedValid}
              valid={isFeedValid}
            />
            <FormFeedback>Ups! Feed URL is not valid</FormFeedback>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <Label>Media Score</Label>
            <SingleSelect
              aria-label="reach-select"
              name="reach"
              value={reach || ''}
              items={websiteReach}
              onChange={handleChangeReach}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <Label>Language</Label>
            <SingleSelect
              aria-label="language-select"
              name="languages"
              value={language || ''}
              items={languages}
              onChange={handleChangeLanguage}
            />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

AddWebsiteModalBody.propTypes = {
  websitesAddDomain: PropTypes.func,
  websitesAddReach: PropTypes.func,
  reach: PropTypes.number,
  websitesAddLanguage: PropTypes.func,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  selectedLanguage: PropTypes.string,
  isSaveButtonDisabled: PropTypes.bool,
  setSaveButtonDisabled: PropTypes.func,
  language: PropTypes.string,
  domain: PropTypes.string,
  isDomainValid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isFeedValid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  websitesAddDomainFeed: PropTypes.func,
  feed: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isDomainValid: getWebsitesAddWebsiteDomainIsValid(state),
  isFeedValid: getWebsitesAddWebsiteFeedIsValid(state),
});

export default connect(mapStateToProps, {
  websitesAddDomain,
  websitesAddReach,
  websitesAddLanguage,
  websitesAddDomainFeed,
})(AddWebsiteModalBody);
