import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import Flex from '../../common/Flex';

const ICONavbarVerticalMenuItem = ({ route }) => (
  <Flex align="center">
    {route.icon && (
      <span className="nav-link-icon" aria-label="route-span">
        <FontAwesomeIcon icon={route.icon} />
      </span>
    )}
    <span className="nav-link-text">{route.name}</span>
    {!!route.badge && (
      <Badge color={route.badge.color || 'soft-success'} pill className="ml-2">
        {route.badge.text}
      </Badge>
    )}
  </Flex>
);

ICONavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.any,
    badge: PropTypes.shape({
      color: PropTypes.string,
      text: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(ICONavbarVerticalMenuItem);
