import { toast } from 'react-toastify';

const SUCCESS_CODE_MAP = {
  200: 'Action successful!',
  201: 'Item was added correctly!',
  204: 'Update successfully done!',
};

const ERROR_CODES_MAP = {
  400: 'There was a problem with your request (bad request). Please, check your filters and try again.',
  404: 'There was a problem with your request (not found). Please, try again in a few minutes or contact support.',
  403: 'There was a problem with your request (forbidden). Please, try again in a few minutes or contact support.',
  408: 'Timeout from the server. Please, try again in a few minutes or contact support.',
  409: 'There was a conflict with your request. Please, try again in a few minutes or contact support.',
  422: 'There was a problem adding the item.',
  500: 'Server error. Please, try again in a few minutes or contact support.',
  502: 'Bad gateway. Please, try again in a few minutes or contact support.',
  503: 'Service is currently unavailable. Please, try again in a few minutes or contacts support.',
  504: 'Server timeout. Please, try again in a few minutes or contact support.',
};

const autoClose = 5000;

const getToastedNotification = ({ customMessage = null, responseStatusCode = null }) => {
  if (!customMessage) {
    if (SUCCESS_CODE_MAP[responseStatusCode]) {
      toast.success(SUCCESS_CODE_MAP[responseStatusCode], { autoClose });
    }

    if (ERROR_CODES_MAP[responseStatusCode]) {
      toast.error(ERROR_CODES_MAP[responseStatusCode], { autoClose });
    }
  }

  if (customMessage && customMessage.type) {
    toast[customMessage.type](customMessage.message, { autoClose });
  }
};

export default getToastedNotification;
