import {
  SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_SUCCESS,
  SIGNUP_FREE_USER_CREATE_USER_ACCOUNT,
  SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_ERROR,
  SIGNUP_FREE_USER_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: [],
  error: null,
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_FREE_USER_CREATE_USER_ACCOUNT:
      return { ...state, isLoading: true };
    case SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_SUCCESS: {
      return { ...state, isLoading: false, response: Object.values(action.payload.items)[0] };
    }
    case SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { reason: action.payload?.response?.error?.reason, status: action?.payload?.status },
      };
    case SIGNUP_FREE_USER_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default account;
