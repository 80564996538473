import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';

import WebsiteDetailsMonthlyDesktopVisits from './WebsiteDetailsMonthlyDesktopVisits';
import WebsiteDetailsSocialData from './WebsiteDetailsSocialData';
import { changeNewFeedForm, showModal } from '../../../actions/forms';
import { fetchLanguages } from '../../../actions/languages';
import { fetchWebsite } from '../../../actions/websites';
import metacriticLogo from '../../../assets/img/logos/metacritic-icon.svg';
import Avatar from '../../../components/common/Avatar';
import { langFormatter } from '../../../components/ICO/tables/ArticlesFeedTable';
import ArticlesWebsiteTable from '../../../components/ICO/tables/ArticlesWebsiteTable';
import CategoriesWebsiteTable from '../../../components/ICO/tables/CategoriesWebsiteTable';
import SingleMetric from '../../../components/ICO/text-display/SingleMetric';
import { extractFavicon } from '../../../config/url-utils';
import { getLanguages } from '../../../selectors/languages';
import { getOrganizationPermissionsHasSimilarWeb } from '../../../selectors/permissions';
import { getWebsiteByDomain } from '../../../selectors/websites';

const WebsitePanelComp = ({ onLoad, website, onEditFeed, favicon, onNewFeed, language, userHasSimilarWeb }) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <Row md={12} className="mb-3">
        <Col sm={12} xs={12} md={12} className="mb-3">
          <Card>
            <CardBody className="h-13 d-flex flex-column justify-content-center">
              <Row className="mt-3 mb-3">
                <Col className="d-flex flex-row align-items-center">
                  <Avatar src={favicon} rounded="circle" size="2xl" className="mr-2 fp-avatar-website" />
                  <h1 className="mb-0">{website.domain}</h1>
                  {website.is_metacritic && (
                    <img
                      className="website-panel-metacritic img-thumbnail bg-soft-gray mr-3"
                      width={60}
                      src={metacriticLogo}
                      alt="metacritic website"
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="fs-0">{langFormatter(language)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        {userHasSimilarWeb ? (
          <Col sm={12} xl={4}>
            <SingleMetric count={website.muv} icon="users4" title="Monthly Unique Visitors" color="muv" />
          </Col>
        ) : null}
        <Col sm={12} xl={userHasSimilarWeb ? 4 : 6}>
          <SingleMetric count={website['mediatype-name']} icon="folder" title="Mediatype" color="mediatype" />
        </Col>
        <Col sm={12} xl={userHasSimilarWeb ? 4 : 6}>
          <SingleMetric count={website.reach} icon="station" title="Media Score" color="media-score" />
        </Col>
      </Row>
      <WebsiteDetailsSocialData
        onNewFeed={onNewFeed}
        onEditFeed={onEditFeed}
        authors={website['authors']}
        twitterAccounts={website['twitter-accounts']}
        feeds={website['feeds']}
      />
      <WebsiteDetailsMonthlyDesktopVisits websiteVisitsMapData={website['geography']} />
      <Row className="mt-4">
        <Col md={12} sm={12}>
          {website.id ? <ArticlesWebsiteTable websiteId={website.id} urlParams={{}} /> : <>Loading...</>}
        </Col>
      </Row>
      {website.categories && (
        <Row className="mt-3">
          <Col md={12} sm={12}>
            <CategoriesWebsiteTable categories={website.categories} />
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const website = getWebsiteByDomain(state, ownProps.domain);
  const languages = getLanguages(state);
  return {
    website,
    language: website.language && languages[website.language] ? languages[website.language].name : 'Unknown',
    favicon: extractFavicon(ownProps.domain),
    userHasSimilarWeb: getOrganizationPermissionsHasSimilarWeb(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onLoad: () => {
    dispatch(fetchLanguages());
    dispatch(fetchWebsite(ownProps.domain));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onNewFeed: () => {
    const { dispatch } = dispatchProps;
    const { website } = stateProps;
    dispatch(
      showModal({
        content: 'newFeed',
        contentId: website.id,
        contentData: website,
      }),
    );
  },
  onEditFeed: (id, feed, disabled) => {
    const { dispatch } = dispatchProps;
    const { website } = stateProps;
    dispatch(changeNewFeedForm({ field: 'feed', value: feed }));
    dispatch(changeNewFeedForm({ field: 'disabled', value: disabled }));
    dispatch(changeNewFeedForm({ field: 'id', value: id }));
    dispatch(
      showModal({
        content: 'editFeed',
        contentId: website.id,
        contentData: website,
      }),
    );
  },
});

WebsitePanelComp.propTypes = {
  onLoad: PropTypes.func,
  onEditFeed: PropTypes.func,
  onNewFeed: PropTypes.func,
  favicon: PropTypes.node,
  language: PropTypes.string,
  website: PropTypes.shape({
    'twitter-accounts': PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    domain: PropTypes.string,
    is_metacritic: PropTypes.bool,
    'mediatype-name': PropTypes.string,
    muv: PropTypes.number,
    reach: PropTypes.number,
    geography: PropTypes.array,
    authors: PropTypes.arrayOf(PropTypes.string),
    feeds: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number,
        month: PropTypes.string,
        id: PropTypes.number,
        category: PropTypes.string,
      }),
    ),
  }),
};

const WebsiteDetails = connect(mapStateToProps, mapDispatchToProps, mergeProps)(WebsitePanelComp);

export default WebsiteDetails;
