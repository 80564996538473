/* eslint-disable */
import { createSelector } from 'reselect';

export const getProducts = (state) => state.products.response;

const getFilteredProducts = (products, productsToSearch) =>
  products.filter(
    (product) => product.name.includes(productsToSearch) && !product.name.includes('(Rare Subscription)'),
  );

export const getProductsProPlan = createSelector([getProducts], (productsResponse) =>
  productsResponse?.data
    ? productsResponse.data.filter(
        (product) =>
          product.name.includes('Pro plan') &&
          !product.name.includes('(Rare Subscription)') &&
          !product.name.includes('12 active projects') &&
          !product.name.includes('6 Months Historical Supersearch data'),
      )
    : null,
);

export const getProductsStarterPlan = createSelector([getProducts], (productsResponse) =>
  productsResponse?.data ? getFilteredProducts(productsResponse.data, 'Starter plan') : null,
);
