import React from 'react';

import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { faEye, faTrophy } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import ChartButton from './ChartButton';

const ChartButtons = ({ articlesToggle, articlesValues, reachToggle, reachValues, visitsToggle, visitsValues }) => (
  <>
    <ChartButton
      onClick={articlesToggle}
      glyph={faNewspaper}
      title="Articles"
      className={
        articlesValues
          ? 'graph-button-left btn btn-primary btn-icon btn-rounded'
          : 'graph-button-left btn btn-soft-gray btn-icon btn-rounded'
      }
      tooltipContent="Articles"
    />
    <ChartButton
      onClick={reachToggle}
      glyph={faTrophy}
      title="Media Score"
      className={
        reachValues
          ? 'graph-button btn btn-primary btn-icon btn-rounded'
          : 'graph-button btn btn-soft-gray btn-icon btn-rounded'
      }
    />
    <ChartButton
      onClick={visitsToggle}
      glyph={faEye}
      title="Visits"
      className={
        visitsValues
          ? 'graph-button-right btn btn-primary btn-icon btn-rounded'
          : 'graph-button-right btn btn-soft-gray btn-icon btn-rounded'
      }
    />
  </>
);

ChartButtons.propTypes = {
  active: PropTypes.bool,
  articlesToggle: PropTypes.func,
  articlesValues: PropTypes.bool,
  reachToggle: PropTypes.func,
  reachValues: PropTypes.bool,
  visitsToggle: PropTypes.func,
  visitsValues: PropTypes.bool,
};

export default ChartButtons;
