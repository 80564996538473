import React from 'react';

import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';

import { youtubeFeedTableFiltersSetMinViews } from '../../../actions/youtubeFeed';
import translate from '../../../helpers/translate';
import { getYoutubeFeedTableFilters } from '../../../selectors/feeds/youtubeFeed';

const YoutubeFeedTableFiltersModalBodyMinViews = ({ youtubeFeedTableFiltersSetMinViews, filters }) => (
  <>
    <Label for="minViews">{translate('pages.youtube_feed.table.filters.min_views')}</Label>
    <Input
      type="number"
      onChange={(e) => youtubeFeedTableFiltersSetMinViews(e.target.value)}
      name="minViews"
      id="minViews"
      placeholder="0"
      value={filters.minViews ?? ''}
    />
  </>
);

const mapStateToProps = (state) => ({
  filters: getYoutubeFeedTableFilters(state),
});

export default connect(mapStateToProps, { youtubeFeedTableFiltersSetMinViews })(
  YoutubeFeedTableFiltersModalBodyMinViews,
);
