import React from 'react';

import values from 'lodash/values';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';

import { saveAvatar } from '../../../actions/files';
import { changeProjectForm } from '../../../actions/forms';
import SingleSelect from '../../../components/ICO/forms/SingleSelect';
import ImageUploader from '../../../components/ICO/uploads/ImageUploader';
import { IMAGES_ROOT } from '../../../config/global-config';
import translate from '../../../helpers/translate';
import { getClients } from '../../../selectors/clients';

const ProjectFormComp = ({ onChange, formFields, clientOptions, onSelectImage }) => {
  const handleTextInputChange = (name, event) => {
    onChange({ field: 'name', value: event.target.value });
  };

  const handleChange = (name, value) => {
    onChange({ field: 'clientId', value: value.value });
  };

  return (
    <>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <input
              type="text"
              onChange={handleTextInputChange.bind(this, 'name')}
              placeholder="Project Name"
              value={formFields.name}
              className="form-control"
            />
            <input
              type="hidden"
              onChange={handleTextInputChange.bind(this, 'id')}
              value={formFields.id ? formFields.id : ''}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <SingleSelect
              name="client-select"
              value={formFields.clientId}
              items={clientOptions}
              placeholder={translate('common.brand')}
              onChange={handleChange.bind(this, 'client')}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md={12} xs={12}>
            <ImageUploader urlRoot={IMAGES_ROOT} onSelectImage={onSelectImage} multiple={false} data={formFields} />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

ProjectFormComp.propTypes = {
  onChange: PropTypes.func,
  formFields: PropTypes.shape({
    name: PropTypes.string,
    clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  clientOptions: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectImage: PropTypes.func,
};

const getClientsOptions = (clients) => {
  let options = [];
  values(clients).map((client) => options.push({ value: client.id, label: client.name }));
  return options;
};

const mapStateToProps = (state) => ({
  formTitle: state.forms.newProjectForm ? state.forms.newProjectForm.title : '',
  formFields: state.forms.newProjectForm ? state.forms.newProjectForm.fields : {},
  clientOptions: getClientsOptions(getClients(state)),
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (data) => dispatch(changeProjectForm(data)),
  onSelectImage: (data) => {
    dispatch(changeProjectForm({ field: 'avatar', value: data[0].name }));
    dispatch(saveAvatar({ images: data, scope: '0;project' }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFormComp);
