import { connect } from 'react-redux';

import LineHChart from './LineHChart';
import { changeChart } from '../../../actions/modules';
import { getArticlesChart } from '../../../selectors/modules';
import { getCurrentProject, getProject } from '../../../selectors/projects';
import { getReport } from '../../../selectors/reports';
import {
  getArticlesChartData,
  getArticlesReachChartData,
  getArticlesReportChartData,
  getArticlesReportReachChartData,
  getArticlesReportVisitsChartData,
  getArticlesVisitsChartData,
  getArticlesReportsTagListToDisplay,
} from '../../../selectors/savedCoverage/articles';

const mapStateToProps = (state, ownProps) => {
  const tagsToDisplay = getArticlesReportsTagListToDisplay(state, ownProps);
  const groups = state.articles.groups;
  const articlesChartState = getArticlesChart(state);
  const reachValues = articlesChartState.reach_values;
  const articlesValues = articlesChartState.articles_values;
  const visitsValues = articlesChartState.visits_values;
  const projectName = ownProps.reportId
    ? getProject(state, getReport(state, ownProps.reportId).project_id).name
    : getCurrentProject(state).name;

  const getProjectChartName = (entity) => {
    let endName = 'project';
    if (projectName) {
      endName = projectName;
    }
    return `${entity} for ${endName}`;
  };

  let series = [];
  let annotations = [];

  if (articlesChartState.articles_values) {
    series.push({
      name: getProjectChartName('Articles count'),
      data: ownProps.reportId ? getArticlesReportChartData(state, ownProps) : getArticlesChartData(state),
    });
  }

  if (articlesChartState.reach_values) {
    series.push({
      name: getProjectChartName('Media score'),
      data: ownProps.reportId ? getArticlesReportReachChartData(state, ownProps) : getArticlesReachChartData(state),
    });
  }

  if (visitsValues) {
    series.push({
      name: getProjectChartName('Visits'),
      data: ownProps.reportId ? getArticlesReportVisitsChartData(state, ownProps) : getArticlesVisitsChartData(state),
    });
  }

  const seriesExists = series.length && series[0] && series[0].data && series[0].data.length > 0;

  if (tagsToDisplay.length && seriesExists) {
    const annotationsToCreate = tagsToDisplay.map((tag) => {
      const tagDate = Date.parse(tag['highlight-date']);
      const seriesDate = series[0].data.filter((item) => item[0] === tagDate);
      return (
        seriesDate && seriesDate.length && seriesDate[0] && { x: seriesDate[0][0], y: seriesDate[0][1], text: tag.name }
      );
    });

    if (annotationsToCreate && annotationsToCreate.length) {
      annotations = annotationsToCreate.map(
        (annotation) =>
          annotation && {
            labels: [
              {
                point: {
                  x: annotation.x,
                  y: annotation.y,
                  xAxis: 0,
                  yAxis: 0,
                },
                text: annotation.text,
              },
            ],
          },
      );
    }
  }

  return {
    reachValues,
    visitsValues,
    articlesValues,
    title: 'Trend',
    isDatetime: true,
    isLoading: groups.isFetching,
    height: 470,
    config: {
      title: {
        align: 'left',
        text: 'Trend',
        style: {
          fontSize: '1.563rem',
        },
      },
      series,
      annotations,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  reachToggle: (articlesValues, reachValues, visitsValues) => {
    if (!(articlesValues || !reachValues || visitsValues)) return false;
    dispatch(changeChart('articlesChart', { reach_values: !reachValues }));
  },
  articlesToggle: (articlesValues, reachValues, visitsValues) => {
    if (!(!articlesValues || reachValues || visitsValues)) return false;
    dispatch(changeChart('articlesChart', { articles_values: !articlesValues }));
  },
  visitsToggle: (articlesValues, reachValues, visitsValues) => {
    if (!(articlesValues || reachValues || !visitsValues)) return false;
    dispatch(changeChart('articlesChart', { visits_values: !visitsValues }));
  },
});

const ArticlesChart = connect(mapStateToProps, mapDispatchToProps)(LineHChart);

export default ArticlesChart;
