import React from 'react';

import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Badge } from 'reactstrap';

import { renderWarning, dateFormatter, MUVFormatter } from './formatters';
import { IMAGES_ROOT } from '../../../../config/global-config';
import { mediumNumberFormat } from '../../../../config/url-utils';
import translate from '../../../../helpers/translate';
import MediaFeedAITranslation from '../../../../pages/MediaFeed/MediaFeedAI.jsx';
import HighlightArticleIcon from '../../reports/HighlightArticleIcon';
import AvatarTitle from '../../text-display/AvatarTitle';
import {
  favIconFormatter,
  mediaFormatter,
  mediaWebsiteLinkFormatter,
  langFormatter,
  ScoreFormatter,
  tagFormatter,
  totalMediaScoreFormatter,
  totalVideosFormatter,
} from '../ArticlesFeedTable';

const TABLES_ACTION_COLUMN = {
  dataField: 'actions',
  text: '',
  classes: 'border-0',
  headerClasses: 'border-0',
  headerAlign: 'right',
  align: 'right',
  csvExport: false,
};

export const articlesTableColumns = (userHasSimilarWeb) => [
  {
    dataField: 'missing',
    formatter: (name, row) => (row.isSomethingMissing ? renderWarning() : null),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
  },
  {
    dataField: 'favIcon',
    formatter: favIconFormatter,
    text: '',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
  },
  {
    dataField: 'domain',
    formatter: mediaWebsiteLinkFormatter,
    text: translate('tables.domain'),
    classes: 'border-0 align-middle d-none',
    headerClasses: 'd-none',
    sort: true,
  },
  {
    dataField: 'url',
    formatter: mediaFormatter,
    text: 'Media',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'title',
    text: 'Title',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvFormatter: (cell) => cell.props.article.title,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'muv',
    text: translate('common.UMV'),
    formatter: MUVFormatter,
    classes: userHasSimilarWeb ? 'border-0 align-middle fs-0 text-light' : 'd-none',
    headerClasses: userHasSimilarWeb ? 'border-0' : 'd-none',
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'reach',
    text: translate('common.media_score'),
    formatter: ScoreFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'tag',
    text: 'Tag',
    formatter: tagFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'language',
    text: translate('common.language'),
    formatter: langFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'date',
    text: translate('common.date'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'action',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    text: '',
    csvExport: false,
  },
];

export const reportDetailsArticlesTableColumn = (userHasSimilarWeb) => [
  {
    dataField: 'missing',
    formatter: (name, row) => (row.isSomethingMissing ? renderWarning() : null),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
  },
  {
    dataField: 'favIcon',
    formatter: favIconFormatter,
    text: '',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
  },
  {
    dataField: 'domain',
    formatter: mediaWebsiteLinkFormatter,
    text: translate('tables.domain'),
    classes: 'border-0 align-middle d-none',
    headerClasses: 'd-none',
    sort: true,
  },
  {
    dataField: 'url',
    formatter: mediaFormatter,
    text: 'Media',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'title',
    text: 'Title',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvFormatter: (cell) => cell.props.article.title,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'muv',
    text: translate('common.UMV'),
    formatter: MUVFormatter,
    classes: userHasSimilarWeb ? 'border-0 align-middle fs-0 text-light' : 'd-none',
    headerClasses: userHasSimilarWeb ? 'border-0' : 'd-none',
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'reach',
    text: translate('common.media_score'),
    formatter: ScoreFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'tag',
    text: 'Tag',
    formatter: tagFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'language',
    text: translate('common.language'),
    formatter: langFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'date',
    text: translate('common.date'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'action',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    text: '',
    csvExport: false,
  },
];

export const reportCreationArticlesTableColumns = (userHasSimilarWeb = false, userCanTakeScreenshot = false) => [
  {
    formatter: (cell, row) => <HighlightArticleIcon article_id={row.id} article_url={row.fullUrl} />,
    classes: userCanTakeScreenshot ? 'border-0 align-middle' : 'd-none',
    headerClasses: userCanTakeScreenshot ? 'border-0' : 'd-none',
  },
  {
    dataField: 'missing',
    formatter: (name, row) => (row.isSomethingMissing ? renderWarning() : null),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
  },
  {
    dataField: 'favIcon',
    formatter: favIconFormatter,
    text: '',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
  },
  {
    dataField: 'domain',
    formatter: mediaWebsiteLinkFormatter,
    text: translate('tables.domain'),
    classes: 'border-0 align-middle d-none',
    headerClasses: 'd-none',
    sort: true,
  },
  {
    dataField: 'url',
    formatter: mediaFormatter,
    text: 'Media',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'title',
    text: 'Title',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvFormatter: (cell) => cell.props.article.title,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'muv',
    text: translate('common.UMV'),
    formatter: MUVFormatter,
    classes: userHasSimilarWeb ? 'border-0 align-middle fs-0 text-light' : 'd-none',
    headerClasses: userHasSimilarWeb ? 'border-0' : 'd-none',
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'reach',
    text: translate('common.media_score'),
    formatter: ScoreFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'tag',
    text: 'Tag',
    formatter: tagFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'language',
    text: translate('common.language'),
    formatter: langFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'date',
    text: translate('common.date'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'action',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    text: '',
    csvExport: false,
  },
];

export const mediaFeedTableColumns = (userHasSimilarWeb) => [
  {
    dataField: 'favIcon',
    formatter: favIconFormatter,
    text: '',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
  },
  {
    dataField: 'source',
    text: '',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
  },
  {
    dataField: 'media',
    formatter: mediaFormatter,
    text: 'Media',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'title',
    text: 'Title',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
    formatter: (data, row) => <MediaFeedAITranslation row={row} />,
    events: {
      onClick: (e) => {
        e.stopPropagation();
      },
    },
  },
  {
    dataField: 'muv',
    text: translate('common.UMV'),
    formatter: MUVFormatter,
    classes: userHasSimilarWeb ? 'border-0 align-middle fs-0 text-light' : 'd-none',
    headerClasses: userHasSimilarWeb ? 'border-0' : 'd-none',
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'reach',
    text: translate('common.media_score'),
    formatter: ScoreFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'language',
    text: translate('common.language'),
    formatter: langFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'date',
    text: translate('common.date'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'action',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    text: '',
    csvExport: false,
  },
];

export const tagsTableColumns = [
  {
    dataField: 'name',
    text: translate('common.name'),
    formatter: (name, row) => (row ? <h6 className="fs-0 mb-0">{name}</h6> : <></>),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'date',
    text: translate('common.date_created'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'updated_at',
    text: 'Last Used',
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  TABLES_ACTION_COLUMN,
];

export const websitesTableColumn = (userHasSimilarWeb) => [
  {
    dataField: 'favIcon',
    formatter: favIconFormatter,
    text: '',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
  },
  {
    dataField: 'domain',
    formatter: mediaWebsiteLinkFormatter,
    text: 'Media',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'articlesCount',
    text: 'Articles',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
  },
  {
    dataField: 'mediatype',
    text: 'Type',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'muv',
    text: translate('common.UMV'),
    formatter: MUVFormatter,
    classes: userHasSimilarWeb ? 'border-0 align-middle fs-0 text-light' : 'd-none',
    headerClasses: userHasSimilarWeb ? 'border-0' : 'd-none',
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'reach',
    text: translate('common.media_score'),
    formatter: ScoreFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'language',
    text: translate('common.language'),
    formatter: langFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'date',
    text: translate('common.date'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
];

export const reportsTableColumns = (userHasYoutubeFeed) => [
  {
    dataField: 'name',
    text: 'Title',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    formatter: (name, row) => (
      <AvatarTitle
        id={row.id}
        title={name}
        imgSrc={row.imageUrl}
        link={`/reports/${row.project.id}/${row.id}`}
        subtitle={
          ' ' +
          moment(row['date-start']).format('MMMM DD, YYYY') +
          ' - ' +
          moment(row['date-end']).format('MMMM DD, YYYY')
        }
      />
    ),
    sort: true,
  },
  {
    dataField: 'total-articles',
    text: translate('common.articles'),
    align: 'center',
    classes: 'border-0 align-middle fs-0',
    headerClasses: 'border-0  align-middle',
    headerAlign: 'center',
    formatter: (articles) => (
      <Badge color="article-badge" className="rounded-capsule">
        <FontAwesomeIcon icon={faNewspaper} /> {articles}
      </Badge>
    ),
    sort: true,
  },
  {
    dataField: 'total-reach',
    text: translate('common.total_media_score'),
    align: 'center',
    classes: 'border-0 fs-0 align-middle',
    headerClasses: 'border-0 align-middle',
    headerAlign: 'center',
    formatter: (score) => totalMediaScoreFormatter(mediumNumberFormat(score)),
    sort: true,
  },
  {
    dataField: 'reports-videos',
    text: translate('common.total_videos'),
    align: 'center',
    classes: userHasYoutubeFeed ? 'border-0 fs-0 align-middle' : 'd-none',
    headerClasses: userHasYoutubeFeed ? 'border-0 align-middle' : 'd-none',
    headerAlign: 'center',
    formatter: (videos) => totalVideosFormatter(videos ? videos.length : 0),
    sort: true,
  },
  {
    dataField: 'inserted-at',
    text: translate('common.date_created'),
    align: 'center',
    headerAlign: 'center',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0 align-middle',
    formatter: dateFormatter,
    sort: true,
  },
  TABLES_ACTION_COLUMN,
];

export const projectsTableColumns = [
  {
    dataField: 'name',
    text: translate('common.name'),
    formatter: (name, row) =>
      row ? <AvatarTitle id={row.id} title={name} imgSrc={row.image ? IMAGES_ROOT + row.image : null} /> : <></>,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'clientName',
    text: translate('common.brand'),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    align: 'middle',
    headerAlign: 'middle',
    sort: true,
  },
  {
    dataField: 'date',
    text: translate('common.date_created'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  TABLES_ACTION_COLUMN,
];

export const brandsTableColumns = [
  {
    dataField: 'name',
    text: translate('common.name'),
    formatter: (name, row) => (
      <AvatarTitle id={row.id} title={name} imgSrc={row.image ? IMAGES_ROOT + row.image : null} />
    ),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'date',
    text: translate('common.date_created'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  TABLES_ACTION_COLUMN,
];

export const columnsArticlesWebsite = (userHasSimilarWeb) => [
  {
    dataField: 'missing',
    formatter: (name, row) => (row.isSomethingMissing ? renderWarning() : null),
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
  },
  {
    dataField: 'favIcon',
    formatter: favIconFormatter,
    text: '',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: false,
    csvExport: false,
  },
  {
    dataField: 'domain',
    formatter: mediaWebsiteLinkFormatter,
    text: translate('tables.domain'),
    classes: 'border-0 align-middle d-none',
    headerClasses: 'd-none',
    sort: true,
  },
  {
    dataField: 'url',
    formatter: mediaFormatter,
    text: 'Media',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'title',
    text: 'Title',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    csvFormatter: (cell) => cell.props.article.title,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'muv',
    text: translate('common.UMV'),
    formatter: MUVFormatter,
    classes: userHasSimilarWeb ? 'border-0 align-middle fs-0 text-light' : 'd-none',
    headerClasses: userHasSimilarWeb ? 'border-0' : 'd-none',
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'reach',
    text: translate('common.media_score'),
    formatter: ScoreFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'tag',
    text: 'Tag',
    formatter: tagFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
  {
    dataField: 'language',
    text: translate('common.language'),
    formatter: langFormatter,
    classes: 'border-0 align-middle fs-0 text-light',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ('' + b).localeCompare(a);
      }
      return ('' + a).localeCompare(b); // desc
    },
  },
  {
    dataField: 'date',
    text: translate('common.date'),
    formatter: dateFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
];

export const usersTableColumns = [
  {
    dataField: 'name',
    text: translate('common.name'),
    formatter: (name, row) =>
      row ? <AvatarTitle id={row.id} title={name} imgSrc={row.image ? row.image : null} /> : <></>,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true,
  },
  {
    dataField: 'roles',
    text: 'Role',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    align: 'center',
    headerAlign: 'center',
    sort: true,
  },
  {
    dataField: 'date',
    text: 'Joined',
    formatter: dateFormatter,
    classes: 'border-0',
    headerClasses: 'border-0',
    sort: true,
    align: 'center',
    headerAlign: 'center',
  },
];

export const usersAdminColumns = [...usersTableColumns, TABLES_ACTION_COLUMN];
