import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import { changeFeedForm, resetForm } from '../../../actions/forms';
import { convertItemsForSelect, STATUS } from '../../../config/api-service';
import { getRssArticles } from '../../../selectors/feeds';
import { getTagOptions } from '../../../selectors/tags';

const BulkDeleteFeed = ({ formStatus, form }) => {
  if (formStatus === STATUS.LOADING) {
    return (
      <div className="alert alert-warning col-md-12">
        <FontAwesomeIcon icon={'spinner2'} /> Deleting {form.importIdsCount} articles...
      </div>
    );
  }
  if (formStatus === STATUS.KO) {
    return (
      <Fragment>
        <Alert color="warning">
          <FontAwesomeIcon glyph={'folder-check'} />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Error: {form.importIdsFail} articles weren't deleted.
        </Alert>
        {form.importIdsSuccess > 0 && (
          <Alert color="success">
            <FontAwesomeIcon glyph={'folder-check'} />
            {form.importIdsSuccess} articles successfully deleted!
          </Alert>
        )}
      </Fragment>
    );
  }
  if (formStatus === STATUS.SAVED) {
    return (
      <div className="alert alert-success col-md-12">
        <FontAwesomeIcon glyph={'folder-check'} /> {form.importIdsSuccess} articles successfully deleted!
        <br />
      </div>
    );
  }
  return <div>Are you sure you want to delete those articles?</div>;
};

const mapStateToProps = (state) => {
  const checkedIds = state.forms.feedActionForm.fields.checkedIds;
  return {
    checkedIds,
    form: state.forms.feedActionForm.fields,
    toImportIds: Object.keys(checkedIds).filter((artId) => checkedIds[artId]),
    allArticles: getRssArticles(state),
    formStatus: state.forms.feedActionForm.fields.status,
    tagOptions: convertItemsForSelect(getTagOptions(state)),
    state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeTag: ({ value: newTag, label: newTagLabel }) => {
    dispatch(
      changeFeedForm({
        value: newTag,
        field: 'tagId',
      }),
    );
    dispatch(
      changeFeedForm({
        value: newTagLabel,
        field: 'tagLabel',
      }),
    );
  },
  onSubmit: () => {
    dispatch(resetForm());
  },
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

BulkDeleteFeed.propTypes = {
  formStatus: PropTypes.string,
  form: PropTypes.shape({
    importIdsSuccess: PropTypes.number,
    importIdsFail: PropTypes.number,
    importIdsCount: PropTypes.number,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BulkDeleteFeed);
