import { connect } from 'react-redux';

import { getNewReportModule } from '../../../selectors/modules';
import {
  getArticlesGroupsStatusByLang,
  getArticlesGroupsStatusByTag,
  getNewReportFormStatus,
} from '../../../selectors/savedCoverage/articles';
import ModulesWrapper from '../layouts/ModulesWrapper';

const mapStateToProps = (state) => {
  const formStatus = getNewReportFormStatus(state);
  const moduleNewReport = getNewReportModule(state);
  const statusGroupByLang = getArticlesGroupsStatusByLang(state);
  const statusGroupByTag = getArticlesGroupsStatusByTag(state);
  const getStyle = () => {
    if (
      formStatus === 'STEP 2 LOADED' ||
      (['STEP 0', 'STEP 1'].indexOf(formStatus) === -1 &&
        moduleNewReport.previewGenerated &&
        statusGroupByLang === 'OK' &&
        statusGroupByTag === 'OK')
    ) {
      // Only show preview on step 0 if
      // Preview has been generated before
      // Group By Filters have not been modifier
      return 'active';
    }
    return 'hidden';
  };
  const getLoaderStyle = () => {
    if (['STEP 2'].indexOf(formStatus) >= 0) return 'active';
    else return 'hidden';
  };

  return {
    style: getStyle(),
    loaderStyle: getLoaderStyle(),
  };
};

export default connect(mapStateToProps)(ModulesWrapper);
