import { UPGRADE_SUBSCRIPTION_SET_PRODUCT_ID, UPGRADE_SUBSCRIPTION_CLEAR } from '../../actions/actionTypes';

const defaultState = null;

const selectedProduct = (state = defaultState, action) => {
  switch (action.type) {
    case UPGRADE_SUBSCRIPTION_SET_PRODUCT_ID:
      return action.payload;
    case UPGRADE_SUBSCRIPTION_CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export default selectedProduct;
