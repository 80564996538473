import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { fetchArticlesFeed } from '../../../../actions/articlesFeed';
import { getArticlesFeedTableFilters } from '../../../../selectors/feeds/articlesFeed';
import { getCurrentProject } from '../../../../selectors/projects';
import { useDeleteArticles } from '../hooks/useArticlesFeed';
import ArticlesFeedDeleteArticlesModal from '../Modals/DeleteArticles/ArticlesFeedDeleteArticlesModal.jsx';
import ArticlesFeedModalsPreview from '../Modals/Preview/ArticlesFeedModalsPreview.jsx';
import ArticlesFeedSaveArticleModal from '../Modals/SaveArticle/ArticlesFeedSaveArticleModal.jsx';

const ArticlesFeedTableActionsColumn = ({ articleData, project, filters, fetchArticlesFeed }) => {
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

  const onDeleteSuccess = () => {
    fetchArticlesFeed({
      alert_id: project['alert-id'],
      pagination: { sizePerPage: 25, page: 1, sortField: 'pub_date' },
      filters,
    });
    setDeleteModalOpen(false);
  };

  const { mutate } = useDeleteArticles(onDeleteSuccess);

  const handleClickDeleteArticle = () => {
    mutate({
      data: {
        type: 'delete_articles_rss_ids',
        attributes: {
          ids: [articleData.id],
        },
        relationships: {
          alert: {
            data: {
              type: 'alert',
              id: project['alert-id'],
            },
          },
        },
      },
    });
  };

  return (
    <>
      <UncontrolledDropdown className="fp-action">
        <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
          <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
        </DropdownToggle>
        <DropdownMenu right positionFixed className="border py-2">
          <DropdownItem onClick={() => setPreviewModalOpen(true)}>Preview article</DropdownItem>
          <DropdownItem onClick={() => setSaveModalOpen(true)} className="text-primary">
            Save article
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => setDeleteModalOpen(true)} className="text-danger">
            Delete article
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <ArticlesFeedSaveArticleModal
        onClose={() => setSaveModalOpen(false)}
        isOpen={isSaveModalOpen}
        selectedRows={[articleData]}
      />
      <ArticlesFeedDeleteArticlesModal
        selectedRows={articleData}
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onClickDelete={handleClickDeleteArticle}
      />
      <ArticlesFeedModalsPreview
        articleData={articleData}
        isOpen={isPreviewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        withSave={true}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  filters: getArticlesFeedTableFilters(state),
  project: getCurrentProject(state),
});

export default connect(mapStateToProps, { fetchArticlesFeed })(ArticlesFeedTableActionsColumn);
