import { useMutation } from 'react-query';

import getToastedNotification from '../../../components/ICO/helpers/toaster.helper';
import { COVERAGE_API_ROOT } from '../../../config/global-config';
import { getToken } from '../../../helpers/getToken';
import translate from '../../../helpers/translate';

const useCreateUpdateProject = ({ onClose, method }) =>
  useMutation({
    mutationKey: 'create_update_project',
    mutationFn: async (data) => {
      const response = await fetch(`${COVERAGE_API_ROOT}/projects`, {
        method,
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
    onSuccess: () => {
      const customMessage = {
        type: 'success',
        message:
          method === 'POST'
            ? translate('pages.projects.notifications.create_project_success')
            : translate('pages.projects.notifications.update_project_success'),
      };
      getToastedNotification({ customMessage });
      onClose();
    },
    onError: () => {
      const customMessage = {
        type: 'error',
        message: translate('pages.projects.notifications.create_project_error'),
      };
      getToastedNotification({ customMessage });
    },
  });

export default useCreateUpdateProject;
