import React from 'react';

import { values } from 'lodash';
import { connect } from 'react-redux';

import OrganizationDetailsUsersTableActions from './OrganizationDetailsUsersTableActions';
import { fetchCurrentUser } from '../../actions/users';
import { usersAdminColumns, usersTableColumns } from '../../components/ICO/tables/helpers/columns';
import { tableRows } from '../../components/ICO/tables/helpers/selectors';
import StaticDataTable from '../../components/ICO/tables/StaticDataTable';
import { ADMIN_ROLE_ID, USER_ROLE_ID } from '../../constants';
import { getOrganizationId } from '../../selectors/organizations';
import { getUsersCurrentUserIsAdmin } from '../../selectors/users';

const translateUserRoleId = (userRoleId) => {
  if (userRoleId === ADMIN_ROLE_ID) {
    return 'Admin';
  }

  if (userRoleId === USER_ROLE_ID) {
    return 'User';
  }

  return 'Kicked';
};

const usersTableFormatter = (rows, organizationId) =>
  values(rows).map((user) => {
    const userWithOrganizationId = { ...user, organizationId };
    return {
      id: user.id,
      image: user.image || user.avatar,
      roles: translateUserRoleId(user.role_id),
      name: user.name || 'No Name #' + user.id,
      actions: <OrganizationDetailsUsersTableActions id={user.id} user={userWithOrganizationId} />,
      date: user['inserted_at'],
    };
  });

const mapStateToProps = (state, ownProps) => ({
  rowsSelectable: false,
  viewAllBtn: false,
  columns: getUsersCurrentUserIsAdmin(state) ? usersAdminColumns : usersTableColumns,
  rows: usersTableFormatter(tableRows(state, { locked: false })[ownProps.tableId](), getOrganizationId(state)),
  ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    dispatch(fetchCurrentUser());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StaticDataTable);
