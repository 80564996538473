import React, { memo, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';

import AlertFormKeywords from './AlertFormKeywords';
import { changeNewAlertForm } from '../../../actions/forms';
import { projectsSetQuery } from '../../../actions/projects';
import { tourSetTourNextStep, tourSetTourIsRunning } from '../../../actions/tour';
import CheckboxSwitch from '../../../components/ICO/forms/CheckboxSwitch';
import { InputSelect } from '../../../components/ICO/forms/InputSelect';
import SingleSelect from '../../../components/ICO/forms/SingleSelect';
import { getIsFirstLogin } from '../../../helpers/localStorage';
import { reverseQueryToObject } from '../../../helpers/queryBuilderFormatter';
import translate from '../../../helpers/translate';
import { getOrganizationIsSlackEnabled, getOrganizationSlackChannels } from '../../../selectors/organizations';
import { getOrganizationPermissionsHasSlack } from '../../../selectors/permissions';
import { getTourHasSkipped } from '../../../selectors/tour';
import QueryBuilder from '../QueryBuilderProjects/QueryBuilderProjects.jsx';

const REACH = [0.1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const reach = REACH.map((reach) => ({
  value: reach,
  label: reach,
}));

const AlertForm = ({
  formFields,
  organizationSlackEnabled,
  organizationSlackChannels,
  userHasSlack,
  changeNewAlertForm,
  tourSetTourIsRunning,
  tourSetTourNextStep,
  hasSkippedTour,
  projectsSetQuery,
}) => {
  let timeout;
  useEffect(() => {
    //setOriginalFormFields(formFields);
    if (getIsFirstLogin() && !hasSkippedTour) {
      //eslint-disable-next-line
      timeout = setTimeout(() => {
        tourSetTourIsRunning(true);
        tourSetTourNextStep(2);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [tourSetTourIsRunning, tourSetTourNextStep]);

  const handleInputSelectChange = (event, newValue) => {
    changeNewAlertForm({ field: event, value: newValue ? newValue.value : '' });
    changeNewAlertForm({ field: event + 'Label', value: newValue ? newValue.label : '' });
  };

  const handleChangeSwitch = (field, newValue) => {
    changeNewAlertForm({ field: 'slackTracking', value: newValue });
  };

  const slackChannelValue = { value: formFields.slackChannel, label: formFields.slackChannelLabel };
  const reachValue = { value: formFields.minReach, label: formFields.minReach };

  const renderSlackChannelForm = () => {
    if (userHasSlack) {
      if (organizationSlackEnabled) {
        return (
          <>
            <FormGroup>
              <Row>
                <Col md={12} xs={12}>
                  <div htmlFor="feed-tracking-switch" className="align-items-center d-flex mt-2 mb-2">
                    <CheckboxSwitch
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      checked={formFields.slackTracking}
                      onChange={(e) => handleChangeSwitch('slackTracking', e)}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={15}
                      width={32}
                      className="feed-switch"
                      id="feed-tracking-switch"
                    />
                    <span className="text-soft-gray ml-2">{translate('modals.alert.slack_switch')}</span>
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className={!formFields.slackTracking && 'd-none'}>
              <Row>
                <Col lg={12}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <label>{translate('modals.alert.slack_channel')}</label>
                  <InputSelect
                    name="slack-channel"
                    onChange={(e) => handleInputSelectChange('slackChannel', e)}
                    value={slackChannelValue}
                    items={organizationSlackChannels}
                    disabled={!formFields.slackTracking}
                    placeholder={'#channel-name'}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className={!formFields.slackTracking && 'd-none'}>
              <Row>
                <Col md={12} xs={12}>
                  <label>{translate('modals.alert.media_score')}</label>
                  <SingleSelect
                    name="reach"
                    value={reachValue}
                    items={reach}
                    disabled={!formFields.slackTracking}
                    onChange={(e) => handleInputSelectChange('minReach', e)}
                  />
                </Col>
              </Row>
            </FormGroup>
          </>
        );
      } else {
        return <div>{translate('modals.alert.enable_slack')}</div>;
      }
    }
  };

  const translatedKeyword = reverseQueryToObject(formFields.keywords);

  return (
    <>
      <QueryBuilder
        currentInput={<AlertFormKeywords formFields={formFields} onChange={changeNewAlertForm} />}
        translatedKeyword={translatedKeyword}
        stateAction={projectsSetQuery}
      />
      {renderSlackChannelForm()}
    </>
  );
};

AlertForm.propTypes = {
  onChange: PropTypes.func,
  formFields: PropTypes.shape(),
  organizationSlackEnabled: PropTypes.bool,
  organizationSlackChannels: PropTypes.arrayOf(PropTypes.string),
  userHasSlack: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  formTitle: state.forms.newAlertForm ? state.forms.newAlertForm.title : '',
  formFields: state.forms.newAlertForm ? state.forms.newAlertForm.fields : {},
  organizationSlackEnabled: getOrganizationIsSlackEnabled(state),
  organizationSlackChannels: getOrganizationSlackChannels(state),
  userHasSlack: getOrganizationPermissionsHasSlack(state),
  hasSkippedTour: getTourHasSkipped(state),
});

export default memo(
  connect(mapStateToProps, {
    changeNewAlertForm,
    tourSetTourIsRunning,
    tourSetTourNextStep,
    projectsSetQuery,
  })(AlertForm),
);
