export const getYoutubeFeedKeywordCreationStatus = (state) => {
  if (!state.youtubeFeed.keyword.error && state.youtubeFeed.keyword?.response?.data) {
    return 'Created';
  }

  if (state.youtubeFeed.keyword.error && !state.youtubeFeed.keyword?.response?.data) {
    return 'Error';
  }

  return null;
};

export const getYoutubeFeed = (state) => state.youtubeFeed.data?.response?.data?.map((videoData) => videoData.video);
export const getYoutubeFeedPagination = (state) => {
  if (state.youtubeFeed.data.response) {
    return {
      totalItems: state.youtubeFeed.data.response.total,
      lastPage: state.youtubeFeed.data.response.lastPage,
      page: state.youtubeFeed.data.response.page,
    };
  }

  return [];
};

export const getYoutubeFeedIsLoading = (state) => state.youtubeFeed.data.isLoading;

export const getYoutubeFeedTableFiltersStartDate = (state) => state.youtubeFeed.filters.startDate;
export const getYoutubeFeedTableFiltersEndDate = (state) => state.youtubeFeed.filters.endDate;
export const getYoutubeFeedTableFilters = (state) => state.youtubeFeed.filters;

export const getYoutubeFeedSaveVideosModalIsOpen = (state) => state.youtubeFeed.saveVideos.showModal;
export const getYoutubeFeedCurrentKeyword = (state) => state.youtubeFeed.data.response.keyword ?? null;

export const getYoutubeFeedSlackWebhookURL = (state) => state.youtubeFeed.slack.slack_webhook_url;
export const getYoutubeFeedSlackWebhookMinSubscribers = (state) => state.youtubeFeed.slack.slack_min_subs_notification;
export const getYoutubeFeedDiscordWebhookURL = (state) => state.youtubeFeed.discord.discord_webhook_url;
export const getYoutubeFeedDiscordWebhookMinSubscribers = (state) =>
  state.youtubeFeed.discord.discord_min_subs_notification;

export const getYoutubeFeedUserHasConfiguredWebhook = (state) => !!state.youtubeFeed?.data?.response?.webhook;
export const getYoutubeFeedConfiguredWebhook = (state) => state.youtubeFeed?.data?.response?.webhook;

export const getYoutubeFeedAreNotificationsEnabled = (state) => state.youtubeFeed?.areNotificationsEnabled;
