import {
  ARTICLES_FEED_FETCH_FEED_SUCCESS,
  ARTICLES_FEED_FETCH_FEED,
  ARTICLES_FEED_FETCH_FEED_ERROR,
  ARTICLES_FEED_FETCH_FEED_FAILURE,
  ARTICLES_FEED_CLEAN,
} from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  response: [],
  error: null,
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLES_FEED_FETCH_FEED:
      return { ...state, isLoading: true };
    case ARTICLES_FEED_FETCH_FEED_SUCCESS:
      return { ...state, isLoading: false, response: action.payload };
    case ARTICLES_FEED_FETCH_FEED_ERROR:
    case ARTICLES_FEED_FETCH_FEED_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case ARTICLES_FEED_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default data;
