import { combineReducers } from 'redux';

import selectedProduct from './selectedProduct';
import stripeCheckout from './stripeCheckout';

const upgradeSubscription = combineReducers({
  selectedProduct,
  stripeCheckout,
});

export default upgradeSubscription;
