import { useMutation } from 'react-query';

import { AI_ROOT } from '../../../../config/global-config';

export const useTranslation = () =>
  useMutation({
    mutationKey: 'ai_translation',
    mutationFn: async ({ article_url, article_title }) => {
      const response = await fetch(`${AI_ROOT}/translations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({ article_title, article_url }),
      });

      if (!response.ok) {
        throw Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
  });

export const useSentimentAnalysis = () =>
  useMutation({
    mutationKey: 'ai_sentiment_analysis',
    mutationFn: async (data) => {
      const response = await fetch(`${AI_ROOT}/sentiment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw Error('Something went wrong');
      } else {
        return await response.json();
      }
    },
  });
