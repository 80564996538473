import React from 'react';

import orderBy from 'lodash/orderBy';
import unionBy from 'lodash/unionBy';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import MapChart from '../../../components/ICO/charts/MapChart';
import highmapsWorldData from '../../../components/ICO/charts/worldmap';
import translate from '../../../helpers/translate';

const loadMapSerieData = (websiteVisitsMapData) => {
  let data = [];
  highmapsWorldData.features.map((feature) =>
    data.push({
      key: feature.properties['hc-key'],
      value: 0,
    }),
  );
  websiteVisitsMapData.map((geoData) => {
    if (geoData.country_code_iso) {
      return data.push({
        key: geoData.country_code_iso.toLowerCase(),
        value: Math.round(geoData.visits),
      });
    } else {
      return -1;
    }
  });
  data = orderBy(data, ['value'], ['desc']);
  data = unionBy(data, 'key');
  return data;
};

const mapData = highmapsWorldData;

const WebsiteDetailsMonthlyDesktopVisits = ({ websiteVisitsMapData }) => (
  <Row className="mt-3 mb-3">
    <Col sm={12}>
      <Card>
        <CardHeader>
          <h3 className="text-regular">{translate('websites.details.monthly_desktop_visits')}</h3>
        </CardHeader>
        <CardBody>
          <MapChart
            series={{
              data: websiteVisitsMapData ? loadMapSerieData(websiteVisitsMapData) : [],
              mapData,
              joinBy: ['hc-key', 'key'],
              name: 'Random data',
              color: 'red',
            }}
          />
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default WebsiteDetailsMonthlyDesktopVisits;
