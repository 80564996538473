import React from 'react';

import { PropTypes } from 'prop-types';
import { Col, Row } from 'reactstrap';

import translate from '../../../helpers/translate';

const ConfirmAction = ({ name }) => (
  <Row>
    <Col>
      <p>
        {translate('modals.delete_modal.confirm')}&nbsp;<strong className="text-primary">{name}</strong>?
      </p>
    </Col>
  </Row>
);

ConfirmAction.propTypes = {
  name: PropTypes.string,
};

export default ConfirmAction;
