import React, { useEffect } from 'react';

import PropTypes, { number } from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { fetchArticles } from '../../actions/articles';
import { tableActions } from '../../actions/forms';
import { fetchTags } from '../../actions/tags';
import DynamicDataTable from '../../components/ICO/tables/DynamicDataTable';
import { articlesTableColumns } from '../../components/ICO/tables/helpers/columns';
import { getModuleArticlesTable } from '../../selectors/modules';
import { getOrganizationPermissionsHasSimilarWeb } from '../../selectors/permissions';
import { getCurrentProject } from '../../selectors/projects';

const Articles = ({ currentProject, module, onLoad, onClear, userHasSimilarWeb }) => {
  useDeepCompareEffect(() => {
    const page = { page: module.pagination.current_page, page_size: module.pagination.page_size };
    const filter = {
      project_id: currentProject.id || 0,
    };

    onLoad(page, filter);
  }, [onLoad]);

  useEffect(() => () => onClear(), [onClear]);

  const urlParams = useParams();

  return (
    <>
      <DynamicDataTable
        urlParams={urlParams}
        title="Saved Articles"
        tableId="articlesTable"
        rowsSelectable={false}
        columns={articlesTableColumns(userHasSimilarWeb)}
        rowsType="articles"
        canSaveArticles={true}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  currentProject: getCurrentProject(state),
  module: getModuleArticlesTable(state),
  userHasSimilarWeb: getOrganizationPermissionsHasSimilarWeb(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (page, filter) => {
    dispatch(fetchTags(filter.project_id));
    dispatch(fetchArticles({ page, filter }));
  },
  onClear: () => dispatch(tableActions['articlesTable'].clearState()),
});

Articles.propTypes = {
  currentProject: PropTypes.shape({
    id: PropTypes.string,
    pagination: PropTypes.shape({
      current_page: number,
      page_size: number,
    }),
  }),
  module: PropTypes.shape({
    'alert-id': PropTypes.number,
    avatar: PropTypes.string,
    client_id: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    'inserted-at': PropTypes.string,
    locked: PropTypes.bool,
    name: PropTypes.string,
    'total-articles': PropTypes.number,
    'total-reports': PropTypes.number,
    'updated-at': PropTypes.string,
    pagination: PropTypes.shape({
      current_page: PropTypes.number,
      page_size: PropTypes.number,
    }),
  }),
  onLoad: PropTypes.func,
  onClear: PropTypes.func,
};

Articles.defaultProps = {
  currentProject: {
    pagination: {
      current_page: 0,
      page_size: 0,
    },
  },
  module: {
    'alert-id': 0,
    avatar: '',
    client_id: '',
    id: '',
    image: '',
    'inserted-at': '',
    locked: false,
    name: '',
    'total-articles': 0,
    'total-reports': 0,
    'updated-at': '',
  },
  fetchArticles: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
