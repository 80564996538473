import { combineReducers } from 'redux';

import fromDate from './fromDate';
import languages from './languages';
import maxScore from './maxScore';
import minScore from './minScore';
import searchTerm from './searchTerm';
import toDate from './toDate';

const filters = combineReducers({ fromDate, toDate, languages, minScore, maxScore, searchTerm });

export default filters;
